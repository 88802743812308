import { request } from "../helpers/axios.new";


export const CANCEL_SUBSCRIPTION = (body) => {
  return request({
    url: '/subscription/cancel-subscription',
    method: "PATCH",
    data: body,
  });
};
