import { makeStyles } from "@mui/styles";

export const loadingScreenStyles = makeStyles({
  gmbGifContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "800px",
    // height: "100vh",
    margin: "0 auto",
    padding: "0 2.5rem",
  },

  logoGifStyle: {
    maxWidth: "100%",
    // width: "auto",
    // height: "auto",
  },
});
