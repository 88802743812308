import React from "react";
import { useNavigate } from "react-router-dom";

import CompanyProvider from "../../context/createCompanyContext";
import CompanyInfo from "../../components/CompanyInfo";
import { UseUserId } from "../../hooks/auth";

import { Grid } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

const CreateCompany = () => {
	const navigate = useNavigate();
  const userId = UseUserId();

  const handleBack = () => navigate("/signout");
  const handleNext = () => navigate("/home");

  return (
    <CompanyProvider>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
        sx={(theme) => ({
          padding: 1,
          [theme.breakpoints.up("sm")]: {
            padding: 3,
          },
        })}
      >
        <Grid item xs={12} sm={9} md={8}>
          <SoftBox borderRadius="xxl" shadow="xl" padding="2rem"
            mt={6} mb={1} 
          >
            <SoftTypography variant="h5" textAlign="center" mb={4}>
              Enter your Company information and select your Industry
            </SoftTypography>
            <CompanyInfo
              userId={userId}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </CompanyProvider>
  );
};

export default CreateCompany;
