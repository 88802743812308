import { request } from "../helpers/axios.new";

export const GET_JETPACKS = () => {
  return request({
    url: `/jetpacks`,
    method: "GET",
  });
};

export const GET_JETPACK = (id) => {
  return request({
    url: `/jetpacks/item?id=${id}`,
    method: "GET",
  });
};