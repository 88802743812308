import React from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  disableWorkspaceKpi,
  updateWorkspaceKpi,
} from "../../../redux/kpi/actions";
import { UseSelectedWorkspace } from "../../../hooks/company";
import ConfirmationModal from "../../Modals/ConfirmationModal";

import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import startCase from "lodash/startCase";

import "../configs/styles.css";
import popNotification from "../../Modals/popNotification";

const MetricItem = ({ disabled, metric, isMetricExists, onAddMetric }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id: groupId } = UseSelectedWorkspace();

  const handleSave = () => {
    const integrationMeta = metric.isZapier
      ? {
          id: metric.id,
          code: metric.code,
          status: "disconnected",
        }
      : null;

    const displayMetricPayload = {
      target: 0,
      pointScale: 0,
      metricType: "display",
      direction: 1
    };

    dispatch(
      updateWorkspaceKpi({
        groupId,
        kpiModelId: metric.kpiModelId,
        type: metric.type,
        sourceId: metric.sourceId,
        ...displayMetricPayload, // 20240423: Create Integrated Metric GroupKPIs as a "Display" metric by default, then offer to morph them to "Progressive" or "Anchor" only after dispatch
        ...(metric.integrationId && { integrationId: metric.integrationId }), // Not all KPIs have integrationId (like Manual-input KPIs)
        ...(integrationMeta && { integrationMeta }),
      }),
    );

    const metricData = {
      ...metric,
      ...(integrationMeta && { integrationMeta })
    }

    onAddMetric?.(metricData)

    /* 20240423: Defer Zapier setup after morphing the Display Metric into a Progressive or Anchor metric
    if (metric.isZapier) {
      navigate(`/zapier-setup?id=${metric.id}`);
    }
    */
  };

  const handleDelete = () => {
    dispatch(disableWorkspaceKpi(isMetricExists._id));

    setTimeout(
      () =>
        popNotification({
          title: "Metric Deleted",
          text: `You've successfully deactivated ${isMetricExists.title.toUpperCase()} metric.`,
        }),
      1500,
    );
  };

  return (
    <SoftBox className="content-box">
      <SoftTypography variant="h6" color="white">
        {startCase(metric.type)}
      </SoftTypography>
      <SoftBox className="grow w-full flex h-full justify-between items-start">
        <SoftTypography variant="body2" color="white" className="!text-sm">
          {metric.description ?? `Missing ${metric.type} description.`}
        </SoftTypography>
        <SoftBox className="self-end">
          <ConfirmationModal
            title={metric.app}
            question={`${isMetricExists ? "Remove" : "Actvate"} ${startCase(
              metric.type,
            )} KPI?`}
            yesText={isMetricExists ? "Remove" : "Save"}
            noText="Cancel"
            onSuccess={() => (isMetricExists ? handleDelete() : handleSave())}
          >
            {isMetricExists ? (
              <SoftButton
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                  "&.Mui-disabled": {
                    color: "#CED4DA !important",
                  },
                }}
                // disabled={disabled || !groupId}
                onClick={handleDelete}
              >
                Remove Metric
              </SoftButton>
            ) : (
              <SoftButton
                variant="outlined"
                sx={{
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                  "&.Mui-disabled": {
                    color: "#CED4DA !important",
                  },
                }}
                disabled={disabled || !groupId}
                onClick={handleSave}
              >
                Add Metric
              </SoftButton>
            )}
          </ConfirmationModal>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default MetricItem;
