import React from "react";
import { Grid } from "@mui/material";

import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";
import SoftButton from "../../../SoftButton";
import ProgressiveCover from "../../../../img/progressive-tracking.jpg";
import AnchorCover from "../../../../img/anchor-tracking.svg";
import DisplayCover from "../../../../img/display-metric.svg";
import VideoDialog from "./Dialogs/VideoDialog";
import ExampleOfUseDialog from "./Dialogs/ExampleOfUseDialog";
import MetricDialog from "./Dialogs/MetricDialog";

import { UseGroupKpis } from "../../../../hooks/kpi";

const ExploreTypes = () => {
  const groupKpis = UseGroupKpis();
  const MAX_WORKSPACE_KPI = 5;

  const metricTypes = [
    {
      id: "progressive",
      cover: ProgressiveCover,
      metricTitle: "Progressive Metrics",
      metricDescription:
        "Monitor your team's daily progress with this dynamic metric.",
      exampleDescription:
        "Use a progressive metric to track daily sales targets for your sales team.",
      buttonTitle: "Progressive Metric",
      videoUrl:
        "https://www.youtube.com/watch?v=mxT233EdY5c&ab_channel=AmazonWebServices",
      steps: [
        "Daily Sales Target: Set a progressive metric to track daily sales targets for your team. Each day, aim to reach a specific sales goal, and the system will automatically track your progress.",
        "Fitness Goals: Use progressive metrics to monitor your fitness journey. Set daily exercise targets or step goals, and strive to achieve them consistently.",
        "Project Milestones: For project management, establish progressive metrics to track milestone completion. Set daily or weekly targets for tasks completed or project stages reached.",
      ],
    },
    {
      id: "anchor",
      cover: AnchorCover,
      metricTitle: "Anchor Metrics",
      metricDescription:
        "Set and conquer one-time targets with ease with anchor metric",
      exampleDescription:
        "Utilize an anchor metric to track the completion of a major project milestone.",
      buttonTitle: "Anchor Metric",
      videoUrl:
        "https://www.youtube.com/watch?v=a9__D53WsUs&ab_channel=AmazonWebServices",
      steps: [
        "Daily Sales Target: Set a progressive metric to track daily sales targets for your team. Each day, aim to reach a specific sales goal, and the system will automatically track your progress.",
        "Fitness Goals: Use progressive metrics to monitor your fitness journey. Set daily exercise targets or step goals, and strive to achieve them consistently.",
        "Project Milestones: For project management, establish progressive metrics to track milestone completion. Set daily or weekly targets for tasks completed or project stages reached.",
      ],
    },
    {
      id: "display",
      cover: DisplayCover,
      metricTitle: "Display Metrics",
      metricDescription:
        "Transform raw data into visually engaging insights with this display metric.",
      exampleDescription:
        "Create a display metric to visualize website traffic and user engagement metrics.",
      buttonTitle: "Display Metric",
      videoUrl:
        "https://www.youtube.com/watch?v=RPis5mbM8c8&ab_channel=AmazonWebServices",
      steps: [
        "Daily Sales Target: Set a progressive metric to track daily sales targets for your team. Each day, aim to reach a specific sales goal, and the system will automatically track your progress.",
        "Fitness Goals: Use progressive metrics to monitor your fitness journey. Set daily exercise targets or step goals, and strive to achieve them consistently.",
        "Project Milestones: For project management, establish progressive metrics to track milestone completion. Set daily or weekly targets for tasks completed or project stages reached.",
      ],
    },
  ];

  return (
    <SoftBox
      sx={{
        width: "80%",
        margin: "0 auto",
        marginY: "60px",
      }}
    >
      <Grid container spacing={3}>
        {metricTypes.map((metric) => (
          <Grid item sm={12} md={6} lg={4} key={metric.id}>
            <SoftBox
              sx={{
                background: "#F2F4F7",
                border: "1px solid #EAECF0",
                boxShadow: "0px 4px 6px -2px #10182808",
                borderRadius: "12px",
                padding: "5px",
              }}
            >
              <SoftBox sx={{ width: "100%" }}>
                <img src={metric.cover} alt={metric.metricTitle} width="100%" />
              </SoftBox>
              <SoftBox sx={{ paddingY: "20px", paddingX: "10px" }}>
                <SoftTypography
                  variant="h3"
                  sx={{ fontSize: "22px", fontWeight: 700, color: "#000" }}
                >
                  {metric.metricTitle}
                </SoftTypography>
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "16px", fontWeight: 400, color: "#344054" }}
                >
                  {metric.metricDescription}
                </SoftTypography>
                <SoftBox
                  sx={{
                    background: "#F9FAFB",
                    border: "1.1px solid #EAECF0",
                    padding: "15px",
                    borderRadius: "12px",
                    marginY: "10px",
                  }}
                >
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <SoftTypography
                      variant="p"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#667085",
                      }}
                    >
                      Example of use:
                    </SoftTypography>
                    <ExampleOfUseDialog metric={metric} />
                  </SoftBox>
                  <SoftTypography
                    variant="p"
                    sx={{ fontSize: "14px", fontWeight: 400, color: "#344054" }}
                  >
                    {metric.exampleDescription}
                  </SoftTypography>
                </SoftBox>
                <MetricDialog
                  metric={metric}
                  isMax={groupKpis && groupKpis.length >= MAX_WORKSPACE_KPI} 
                />
                <SoftBox display="flex" justifyContent="center">
                  <VideoDialog metric={metric} />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
        ))}
      </Grid>
    </SoftBox>
  );
};

export default ExploreTypes;
