import useShouldShowPrompt from "./useShouldShowPrompt";

const iosInstallPromptedAt = "iosInstallPromptedAt";

const isIOS = () => {
  if (navigator.standalone) {
    return false;
  }

  const platform = navigator.platform.toLowerCase();
  console.log("platform", platform);
  console.log(/iphone|ipad|macintel/.test(platform));
  return /iphone|ipad|macintel/.test(platform);
};

const useIosInstallPrompt = () => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt);

  return [
    isIOS() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
export default useIosInstallPrompt;
