export const initState = {
  masterBadges: [],
  isLoading: false,
};

export const types = {
  GET_ALL_MASTER_BADGES: "GET_ALL_MASTER_BADGES",
  GET_ALL_MASTER_BADGES_SUCCESS: "GET_ALL_MASTER_BADGES_SUCCESS",
  GET_ALL_MASTER_BADGES_FAILED: "GET_ALL_MASTER_BADGES_FAILED",
};
