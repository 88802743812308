import React, { useState, useEffect } from "react";
import moment from "moment";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";

import { useDispatch } from "react-redux";
import { getCompanyClaims, claimItem } from "../../redux/claims/actions";

import { GET_CLAIM_ITEMS, PUT_CLAIM } from "../../api/claims";
import { UseCompanyClaims } from "../../hooks/claims";
import { UseUserDetails } from "../../hooks/auth";
import { FETCH_JOBS } from "../../api/jobs";

import ClaimNewCard from "./ClaimNewCard";
import NoClaims from "./NoClaims";

import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PageHeader from "../../components/PageHeader";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <SoftBox
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      height="100%"
    >
      {value === index && <SoftBox sx={{ height: "100%" }}>{children}</SoftBox>}
    </SoftBox>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const ClaimsPage = () => {
  const user = UseUserDetails();
  const items = UseCompanyClaims();
  const [filteredItems, setFilteredItems] = useState(false);
  const [unclaimedOnly, setUnclaimedOnly] = useState(true);
  const [jobOptions, setJobOptions] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  const setClaimsFilter = (value) => {
    setTabValue(value);
    setUnclaimedOnly(!value);
  };

  useEffect(() => {
    dispatch(getCompanyClaims());
  }, []);

  useEffect(() => {
    if (user.companyId) {
      const JOB_PAGES_LIMIT = 100;
      let jobPage = 1;

      const getJobs = (page) => {
        FETCH_JOBS(user.companyId, page, user._id).then((jobData) => {
          const { jobs } = jobData.data;
          const options = jobs.map((item) => ({
            key: item._id,
            id: item._id,
            header1: item.metadata?.customerName,
            header2: moment(item.extRefDate).format("MMM Do YYYY"),
            subheader: item.metadata?.location,
            text: item.metadata?.title || item.metadata?.description,
          }));
          setJobOptions((prevOptions) => [...prevOptions, ...options]);

          if (jobData.data.hasMore && jobPage <= JOB_PAGES_LIMIT) {
            jobPage++;
            getJobs(jobPage);
          }
        });
      };

      getJobs(jobPage);
    }
  }, [user._id, user.companyId, dispatch]);

  useEffect(() => {
    if (items.length) {
      if (!unclaimedOnly) {
        setFilteredItems(items);
      } else {
        const tempItems = items.filter((item) => !item.kpi?.ownerId);
        setFilteredItems(tempItems);
      }
    }
  }, [items, unclaimedOnly]);

  const handleClaim = debounce((id, claimId, type) => {
    dispatch(claimItem(id, claimId, type));
    const claiming = toast.loading("Processing claim...");

    // Fake processing delay
    setTimeout(() => {
      const tempItems = [...items];
      const index = tempItems.findIndex((item) => item._id === id);
      if (index >= 0) {
        tempItems[index].kpi = {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
          },
        };
        toast.success("Claimed Successfully", { id: claiming });
      }
    }, 1250);
  }, 500);

  const textBp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const NavTabs = [{ label: "Unclaimed" }, { label: "All" }];

  return (
    <>
      <PageHeader
        headerText="Claims"
        subheaderText="Claim unclaimed reviews and showcase positive customer experiences"
      />

      <SoftBox
        sx={{
          margin: {
            mini: "0.5rem 1rem 1rem 1rem",
            sm: "1rem 1.5rem 1.5rem 1.5rem",
          },
        }}
      >
        {items && items.length ? (
          <>
            <SoftBox height="100%" display="flex" flexDirection="column">
              <SoftBox
                flexGrow={1}
                alignItems="end"
                sx={{ mb: { mini: "0.5rem", sm: "1rem" } }}
              >
                <Tabs
                  orientation="horizontal"
                  value={tabValue}
                  onChange={(_, newValue) => setClaimsFilter(newValue)}
                  sx={{
                    background: "rgba(249, 249, 255, 0.80)",
                    borderRadius: "10px",
                    padding: "8px",
                    boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.12)",
                    width: "max-content",
                    "& .MuiTabs-indicator": {
                      borderRadius: "0.5rem",
                      boxShadow: "none",
                    },
                  }}
                >
                  {NavTabs.map((nav) => (
                    <Tab
                      label={nav.label}
                      sx={{
                        color: "#343A40",
                        fontSize: "0.85rem",
                      }}
                    />
                  ))}
                </Tabs>
              </SoftBox>
            </SoftBox>

            <Grid container spacing={3}>
              {filteredItems &&
                filteredItems.length > 0 &&
                filteredItems.map((item) => {
                  return (
                    <Grid key={item._id} item mini={12} sm={6} md={4}>
                      <SoftBox mb={3}>
                        <ClaimNewCard
                          industry="review"
                          handleClaim={handleClaim}
                          item={item}
                          jobOptions={jobOptions}
                        />
                      </SoftBox>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        ) : (
          <NoClaims />
        )}
      </SoftBox>
    </>
  );
};

export default ClaimsPage;
