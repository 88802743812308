import React from "react";
import PropTypes from "prop-types";

// Components
import ImageBox from "./ImageBox";
import SoftBox from "../SoftBox";

const Image = ({
  imageSrc,
  blurImageSrc,
  alt = "picture",
  height = 150,
  fadeSingleImage = true,
  handleClick = null,
  // Override the main element container styles
  containerStyles = null,
  // Override the local <img /> styles
  imageStyles = null
}) => {
  // Appends a ".loaded" class name to the <ImageBox /> container classList after the image is fully loaded
  // The ".loaded" class triggers transitioning the inner loaded image from opacity 0 to 1
  // Alternatively, apply the ".loaded" class containing the "imageLoadedStyle" definitions the <Image /> parent container to batch fade-in multiple <Image /> elements
  const handleImageLoad = (e) => {
    const imageParent = e.target.parentNode;
    imageParent.classList.add("loaded");
  }

  const imageLoadedStyle = {
    "& .loaded": {
      "& img": {
        opacity: 1
      }
    }
  }  

  // <ImageBox /> parent container styles.
  const fadeImageLocally = () => {
    return (fadeSingleImage)
      ? imageLoadedStyle
      : null
  }

  const handleOnClick = () => {
    if (handleClick) {
      handleClick();
    }
  }

  return (
    <SoftBox
      sx={{
        ...fadeImageLocally(),
        ...containerStyles
      }}
      onClick={handleOnClick}
    >
      <ImageBox
        sx={{
          height: `${height}px`,
          ...(imageStyles && ({
            overflow: "hidden",
            ...imageStyles
          }))
        }}
        style={{ backgroundImage: `url(${blurImageSrc})` }}
      >
        <img
          loading="lazy"
          src={imageSrc}
          alt={alt}
          onLoad={handleImageLoad}
        />
      </ImageBox>
    </SoftBox>
  )
};

Image.defaultProps = {
  imageSrc: "https://google.com",
  blurImageSrc: "https://google.com",
  alt: "picture",
  fadeSingleImage: true,
  height: 150,
  handleClick: null
};

Image.propTypes = {
  imageSrc: PropTypes.string,
  /** 20x20 pixel "blurry version" of the original image */
  blurImageSrc: PropTypes.string,
  alt: PropTypes.string,
  fadeSingleImage: PropTypes.bool,
  height: PropTypes.number,
  handleClick: PropTypes.func
}

export default Image;
