/* eslint camelcase: 0 */
import React from "react";
import Selector from "../../../components/Integrations/Apideck/Selector";

const Integrations = () => {
  return (
    <Selector />
  );
};

export default Integrations;
