import React from "react";
import { UseTopPlayers, UseGroupKpis } from "../../../hooks/kpi";
import { useDispatch } from "react-redux";
import { setToLiveKpis } from "../../../redux/kpi/actions";

import LiveLeaderboardComponent from "./LiveLeaderboard";

const LiveLeaderboard = ({ periodIdx, isAdmin = false }) => {
  const [topList, setTopList] = React.useState([]);
  const groupKpis = UseGroupKpis();
  const dispatch = useDispatch();

  const topPlayers = [
    UseTopPlayers("yesterday"),
    UseTopPlayers("today"),
    UseTopPlayers("week"),
    UseTopPlayers("month"),
  ];

  React.useEffect(() => {
    dispatch(setToLiveKpis());
  }, []);

  React.useEffect(() => {
    if (topPlayers.length >= 0) {
      if (topPlayers[periodIdx]) {
        setTopList(topPlayers[periodIdx]);
      }
    }
  }, [topPlayers, periodIdx]);

  return (
    <LiveLeaderboardComponent
      topList={topList}
      groupKpis={groupKpis}
      isAdmin={isAdmin}
      periodIdx={periodIdx}
    />
  );
};

export default LiveLeaderboard;
