/* eslint react/no-unknown-property: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useGLTF, useFBX, useAnimations } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { Color } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";
import { GET_IMAGE } from "../../helpers/images";
import { lighten } from "@mui/material/styles";

import TOOLS from "./toolsDef";
import { OVERALL_OUTFIT, SHIRT, OVERALL_MODEL } from "./outfitDef";
import { IDLE1_ANIM, IDLE_TOOL_ANIM } from "./animsDef";

const DEFAULT_BONE = "RightHandMiddle1";

const prevTool = {}; // static storage of industry tool configuration

const AvatarLoader = forwardRef(({ url, logo, industry, gender, fixed, recolor, idle, animation, onAnimEnd, disableAnim, isOwn, onAvatarLoad, yeti, ...rest }, ref) => {
    const toolDef = TOOLS[industry];
    const toolSrc = useGLTF(toolDef?.asset || TOOLS.DEFAULT.asset);
    const tool = useMemo(() => isOwn? clone(toolSrc.scene): toolSrc.scene, [isOwn, toolSrc]);

    const avatarSrc = useGLTF(url);
    // If avatar is from current account, use the same cached model to avoid reloading
    const avatar = useMemo(() => isOwn? clone(avatarSrc.scene): avatarSrc.scene, [isOwn, avatarSrc]);
    const { nodes: nodesAvatar, materials: materialsAvatar } = useGraph(avatar);

    const overallSrc = useGLTF(OVERALL_MODEL(gender));
    const overall = useMemo(() => clone(overallSrc.scene), [overallSrc]);
    const { nodes: nodesOverall, materials: materialsOverall } = useGraph(overall);

    const { animations: idleAnims } = useFBX(toolDef ? IDLE_TOOL_ANIM : idle || IDLE1_ANIM);
    const { mixer } = useAnimations(idleAnims);
    const { animations: activeAnims } = useFBX(animation || idle || IDLE1_ANIM);
    const [actions, setActions] = useState([]);
    const [avatarLoaded, setAvatarLoaded] = useState(false);

    useImperativeHandle(ref, () => ({
      playAnimation() {
        if (!disableAnim && actions.length > 1) {
          mixer.addEventListener("finished", () => {
            actions[1].fadeOut(0.5);
            actions[0].reset().setEffectiveWeight(1).fadeIn(0.5).play();
            onAnimEnd?.();
            mixer.removeEventListener("finished");
          });
          actions[0].fadeOut(0.5);
          actions[1].reset().setEffectiveWeight(1).fadeIn(0.5).play();
        }
      },
    }));

    useEffect(() => {
      const handleResize = () => {
        mixer?.uncacheRoot(avatar);
      };
      
      // Window resize listener to refresh (and resize) avatar
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    useEffect(() => {
      setAvatarLoaded(false);
    }, [url]);

    useEffect(() => {
      if (avatar && nodesAvatar && !yeti && !fixed) {
        // Swap outfit for non-atlased models (textures are separated)
        if (nodesAvatar.Wolf3D_Outfit_Top && nodesOverall && materialsOverall) {
          const overallTop = clone(nodesOverall.Wolf3D_Outfit_Top);
          const overallBottom = clone(nodesOverall.Wolf3D_Outfit_Bottom);
          const topMaterial = materialsOverall.Wolf3D_Outfit_Top.clone();
          const bottomMaterial = materialsOverall.Wolf3D_Outfit_Bottom.clone();

          if (recolor) {
            const lighterColor = lighten(recolor, 0.10);
            topMaterial.color = new Color(lighterColor);
            overallTop.material = topMaterial;
            bottomMaterial.color = new Color(lighterColor);
            overallBottom.material = bottomMaterial;
          }
          
          overallTop.bind(nodesAvatar.Wolf3D_Outfit_Top.skeleton);
          nodesAvatar.Armature.remove(nodesAvatar.Wolf3D_Outfit_Top);
          nodesAvatar.Wolf3D_Outfit_Top = overallTop;
          nodesAvatar.Armature.add(overallTop);

          overallBottom.bind(nodesAvatar.Wolf3D_Outfit_Bottom.skeleton);
          nodesAvatar.Armature.remove(nodesAvatar.Wolf3D_Outfit_Bottom);
          nodesAvatar.Armature.add(overallBottom);
        }
      }
      avatar.traverse((obj) => {
        obj.frustumCulled = false;
      });
      setAvatarLoaded(true);
      onAvatarLoad?.();
    }, [avatar, yeti, nodesAvatar, nodesOverall, fixed, materialsOverall]);

    useEffect(() => {
      if (avatarLoaded && fixed && recolor) {
        // No outfit swap for 'fixed' avatars but allow recolor
        const lighterColor = lighten(recolor, 0.10);
        nodesAvatar.Wolf3D_Outfit_Top.material.color = new Color(lighterColor);
        nodesAvatar.Wolf3D_Outfit_Bottom.material.color = new Color(lighterColor);
      }
    }, [recolor, fixed, nodesAvatar, avatarLoaded]);
    
    useEffect(() => {
      if (logo && avatarLoaded && nodesAvatar && materialsAvatar && !yeti) {
        // Update logo branding only after avatar has been loaded
        const atlased = materialsAvatar.Wolf3D_Avatar != null;
        const config = atlased ? SHIRT : OVERALL_OUTFIT(gender);
        const newMaterial = atlased? materialsAvatar.Wolf3D_Avatar: materialsAvatar.Wolf3D_Outfit_Top;
        
        const outfitImg = new Image();
        const logoImg = new Image();

        outfitImg.onload = () => {
          // Prepare canvas and draw base texture image
          const canvas = document.createElement("canvas");
          canvas.width = outfitImg.width;
          canvas.height = outfitImg.height;
          const ctx = canvas.getContext("2d");

          if (atlased) {
            // Draw atlased texture as base
            ctx.drawImage(newMaterial.map.image, 0, 0);
          }

          logoImg.onload = () => {
            // Draw new outfit; to overwrite base texture atlas' clothing section
            ctx.drawImage(outfitImg, 0, 0);

            // Draw and position logo on outfit
            config.branding.forEach((brand) => {
              const logoScale = brand.wScale / logoImg.width;
              ctx.drawImage(
                logoImg,
                brand.x,
                brand.y,
                brand.wScale,
                logoImg.height * logoScale,
              );
            });

            // Uncomment to debug constructed texture (shows texture in new tab)
            // Make sure that your browser allow popups from localhost
            // const debugImage = new Image();
            // debugImage.src = canvas.toDataURL();
            // window.open("").document.write(debugImage.outerHTML);

            // Convert new texture to bitmap and assign to 3d avatar
            createImageBitmap(canvas).then((newTexture) => {
              newMaterial.map.image = newTexture;
              newMaterial.map.needsUpdate = true;

              if (atlased) {
                materialsAvatar.Wolf3D_Avatar = newMaterial;
              } else {
                nodesAvatar.Wolf3D_Outfit_Top.material = newMaterial;
              }
            });
          };
          logoImg.crossOrigin = "anonymous";
          logoImg.src = GET_IMAGE(logo);
        };
        outfitImg.crossOrigin = "anonymous";
        outfitImg.src = config.texture;
      }
    }, [nodesAvatar, materialsAvatar, avatarLoaded, logo, gender, yeti]);

    useEffect(() => {
      if (
        industry &&
        toolDef &&
        nodesAvatar &&
        tool.children.length
      ) {
        if (prevTool.asset && prevTool.bone) {
          // Remove previous 3D tool object
          nodesAvatar[prevTool.bone].remove(prevTool.object);
        }
        const toolObj = tool.children[0];
        toolObj.rotateX(toolDef.rotate.x);
        toolObj.rotateY(toolDef.rotate.y);
        toolObj.rotateZ(toolDef.rotate.z);
        toolObj.translateX(toolDef.translate.x);
        toolObj.translateY(toolDef.translate.y);
        toolObj.translateZ(toolDef.translate.z);
        nodesAvatar[toolDef.bone || DEFAULT_BONE].add(toolObj);

        // Record details of current tool (so we could swap it out later if needed)
        prevTool.asset = toolDef.asset;
        prevTool.bone = toolDef.bone || DEFAULT_BONE;
        prevTool.object = toolObj;
      }
    }, [nodesAvatar, industry, tool, toolDef]);

    useEffect(() => {
      if (!disableAnim && avatar && idleAnims) {
        const idleAction = mixer.clipAction(idleAnims[0], avatar);
        idleAction.play();
        setActions([idleAction]);
      }

      return () => {
        mixer?.uncacheRoot(avatar);
      };
    }, [avatar, mixer, idleAnims, disableAnim]);

    useEffect(() => {
      if (!disableAnim && animation && avatar && activeAnims && mixer) {
        const activeAction = mixer.clipAction(activeAnims[0], avatar);
        activeAction.repetitions = 1;
        activeAction.clampWhenFinished = true;
        setActions((a) => [...a, activeAction]);
      }
    }, [avatar, animation, activeAnims, mixer, disableAnim]);

    return <primitive object={avatar} {...rest} />;
  },
);

export default AvatarLoader;
