import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setSelectedChat, createChat } from "../../../redux/chats/actions";
import { useStyles } from "./ChatListStyles";
import ListElement from "../ListElement";
import SoftInput from "../../SoftInput";
import SoftBox from "../../SoftBox";
import SoftButton from "../../SoftButton";
import SoftTypography from "../../SoftTypography";
import SearchedUserList from "../SearchedUserList/index";
import { UseUsersDictionary } from "../../../hooks/company";
import { UseUserDetails } from "../../../hooks/auth";
import GroupChatModal from "../GroupChatModal";
import { getReceiverImage, getReceiverName } from "../helpers";
import LoadingScreen from "../../LoadingScreen";
import blankPic from "../../../img/blankPic.png";
import { useTheme } from "@mui/material";
import moment from "moment";

const ChatList = ({ chats, onSelected, selected, fetchingChats }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [searchedUser, setSearchedUser] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
  } = theme.palette;

  const usersDict = UseUsersDictionary();
  const userDetails = UseUserDetails();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const values = Object.keys(usersDict).map((key) => ({
      _id: key,
      value: key,
      label: `${usersDict[key].firstName} ${usersDict[key].lastName}`,
      ...usersDict[key],
    }));

    const filteredUsers = values.filter((item) => userDetails._id !== item._id);
    setUsers(filteredUsers);
  }, []);

  const onChangeTrigger = async (e) => {
    setSearchKeyword(e.target.value);

    const filterList = users.filter((item) => {
      const userName = `${item.firstName} ${item.lastName}`;
      return userName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    });

    setSearchedUser(filterList);
  };

  const createChatTrigger = async (userId) => {
    try {
      const payload = {
        userId,
      };
      dispatch(createChat(payload));
      setSearchKeyword("");
    } catch (err) {
      console.log(err.response);
    }
  };

  const CreateGroupIcon = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667L2 11.5ZM3.94667 12.6667H3.33333V12.0533L9.37333 6.01333L9.98667 6.62667L3.94667 12.6667ZM13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2C11.6 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C13.8685 4.63166 13.9175 4.5584 13.951 4.47775C13.9844 4.3971 14.0016 4.31065 14.0016 4.22333C14.0016 4.13602 13.9844 4.04957 13.951 3.96892C13.9175 3.88827 13.8685 3.81501 13.8067 3.75333Z"
        fill="url(#paint0_linear_4950_25349)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4950_25349"
          x1="11.7975"
          y1="42.1957"
          x2="-1.06363"
          y2="41.1903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={secondaryColor} />
          <stop offset="1" stopColor={primaryColor} />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <>
      {openModal && (
        <GroupChatModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          users={users}
        />
      )}
      <div className={classes.chatListContainer}>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <SoftTypography
            display="block"
            variant="h6"
            sx={{
              fontSize: "16px",
            }}
          >
            Players Chat
          </SoftTypography>
          <SoftButton
            size="small"
            variant="outlined"
            color="primary"
            sx={{
              padding: "8px 12px !important",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => setOpenModal(true)}
          >
            {CreateGroupIcon}
            <SoftTypography
              variant="button"
              color="primary"
              textGradient
              sx={{ marginLeft: "0.25rem" }}
            >
              Create Group Chat
            </SoftTypography>
          </SoftButton>
        </SoftBox>
        <SoftInput
          placeholder="Search Players"
          value={searchKeyword}
          onChange={onChangeTrigger}
        />
        {/* eslint-disable */}
        <div className={classes.chatList}>
          {searchKeyword === "" ? (
            fetchingChats ? (
              <LoadingScreen height="200px" logoHeight="200px" />
            ) : (
              chats.map((item, index) => (
                <ListElement
                  key={item._id}
                  selected={selected === index ? true : false} // eslint-disable-line
                  id={index}
                  name={
                    item.isGroupChat
                      ? item.chatName
                      : getReceiverName(item.users, userDetails)
                  }
                  latestMessage={item?.latestMessage?.message}
                  image={
                    getReceiverImage(item.users, userDetails) === null
                      ? null
                      : getReceiverImage(item.users, userDetails)
                  }
                  isGroupChat={item?.isGroupChat}
                  onSelected={() => onSelected(index)}
                  setSelectedChat={() => dispatch(setSelectedChat(item))}
                  messageTime={moment(item?.latestMessage?.createdAt).fromNow()}
                />
              ))
            )
          ) : (
            searchedUser.map((item) => (
              <SearchedUserList
                key={item._id}
                image={item.image}
                name={`${item.firstName} ${item.lastName}`}
                userId={item._id}
                createChatTrigger={createChatTrigger}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ChatList;
