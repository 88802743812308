import React from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { Grid } from "@mui/material";
import FirstStepIcon from "./FirstStepIcon";
import SecondStepIcon from "./SecondStepIcon";
import ThirdStepIcon from "./ThirdStepIcon";

const stepsInfo = [
  {
    icon: <FirstStepIcon />,
    step: "1. Congrats! You now have your very OWN Challenge Arena",
    value: "FREE",
  },
  {
    icon: <SecondStepIcon />,
    step: "2. For every invites subscribed to Tiered Plans, you can get",
    value: "30%",
  },
  {
    icon: <ThirdStepIcon />,
    step: "3. Invite at least 10 subscribers within 3 months, to have",
    value: "Free Access to GMB for a Year",
  },
];

const ReferralSteps = () => {
  return (
    <SoftBox py={3}>
      <Grid container spacing={3}>
        {stepsInfo?.map((item) => (
          <Grid item mini={4}>
            <SoftBox
              boxShadow={2}
              borderRadius="0.5rem"
              width="48px"
              height="48px"
              marginBottom="1rem"
              marginX="auto"
            >
              {item.icon}
            </SoftBox>
            <SoftTypography
              variant="body2"
              textAlign="center"
              color="text"
              sx={{ marginBottom: "1rem" }}
            >
              {item.step}
            </SoftTypography>
            <SoftTypography variant="h5" textAlign="center" color="dark">
              {item.value}
            </SoftTypography>
          </Grid>
        ))}
      </Grid>
    </SoftBox>
  );
};

export default ReferralSteps;
