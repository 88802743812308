let counter = 1;
let deferredPrompt;

const popUpCall = () => {
    if (counter === 1 && window.location.pathname.includes('/home')) {
        const popUp = document.getElementById('addToHomeClick');
        popUp?.click();
    }
}

export const homePropmt = () => {

    if ("serviceWorker" in navigator) {
        // window.addEventListener("load", () => {
        console.log("service wordker");
        navigator.serviceWorker
            .register(`${window.location.origin}/sw.js`)
            .then(
                (registration) => {
                    // Registration was successful
                    console.log(
                        "ServiceWorker registration successful with scope: ",
                        registration.scope,
                    );
                },
                (err) => {
                    // registration failed :(
                    console.log("ServiceWorker registration failed: ", err);
                },
            );
        // });
    }

    if (deferredPrompt) {
        popUpCall();
    } else {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            deferredPrompt = e;
            popUpCall();
        });
    }
}

export const installApp = () => {
    const button = document.querySelector('#ConfirmationModelSuccessBtn');
    if (button) {
        button.addEventListener('click', () => {
            deferredPrompt.prompt();
            deferredPrompt.userChoice
                .then((choiceResult) => {
                    counter++;
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accept the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    deferredPrompt = null;
                });
        });
    }
}