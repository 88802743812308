import React from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
// import headerChart from "../../img/header-chart.png";
// import headerGrid from "../../img/header-grid.png";
import PageHeaderBg from "../../img/gamesettings/settings_goal_pursuit_plaza_1920.png";
import { useTheme } from "@mui/material";

/**
 * Page Header Component
 * @param {*} headerText main title
 * @param {*} subheaderText sub-title
 * @returns
 */
const PageHeader = ({ headerText, subheaderText, tabs }) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  return (
    <SoftBox>
      {/** Header and subtitle text */}
      <SoftBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          marginX: { mini: "1rem", md: "1.5rem" },
          marginTop: { mini: "72px", md: "70px" }, // offset for appbar?
          padding: "0 32px 0 32px",
          height: "100px",
          width: "100%",
          top: 0,
          zIndex: 2
        }}
      >
        <SoftBox>
          <SoftTypography
            sx={{
              color: "#343A40",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "32px",
              fontSize: () => ({
                mini: theme.typography.size.xl,
                md: "24px",
              }),
            }}
          >
            {headerText}
          </SoftTypography>
          <SoftTypography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              color: "#6C757D",
              lineHeight: "19px"
            }}
          >
            {subheaderText}
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      {/** Header background graphic */}
      <SoftBox
        bgColor="primary"
        sx={{
          height: "100px",
          padding: "0 32px 0 32px",
          marginBottom: { mini: "1rem", md: "1.5rem" },
          marginX: { mini: "1rem", md: "1.5rem" },
          marginTop: { mini: "72px", md: "70px" }, // offset for appbar?
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          overflow: { mini: "hidden", md: "visible" },
          background: `url(${PageHeaderBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 54%",
          borderRadius: "8px",
          filter: "brightness(115%) opacity(0.8)"
        }}
      >

        {/* * Header graphic for large screens
        <SoftBox
          sx={{
            transform: "translateY(-20%)",
            height: "8rem",
            display: { mini: "none", md: "block" },
          }}
        >
          <img src={headerChart} alt="" style={{ maxHeight: "100%" }} />
        </SoftBox> */}

        {/** Header graphic for small screens */}
        {/* <SoftBox
          sx={{
            transform: "translateY(-50%) translateX(25%)",
            width: "100%",
            display: { mini: "block", md: "none" },
            position: "absolute", // Position as absolute to avoid pushing down header content
          }}
        >
          <img src={headerGrid} alt="" style={{ maxWidth: "100%" }} />
        </SoftBox> */}

        {/** Transparent color overlay on header graphic */}
        {/**
        <SoftBox
          sx={{
            background: `linear-gradient(90deg, rgba(224, 224, 224, 1), ${primaryColor})`,
            position: "absolute",
            height: "7rem",
            borderRadius: "16px",
            width: "100%",
            top: 0,
            left: 0,
            opacity: 0.6,
          }}
        />  */}
      </SoftBox>
    </SoftBox>
  );
};

export default PageHeader;
