import { types } from "./types";

export const getJobs = (payload) => ({
  type: types.GET_ALL_JOBS,
  payload,
});

export const getJob = (payload) => ({
  type: types.GET_A_JOB,
  payload,
});

export const resetJobs = () => ({
  type: types.RESET_JOBS
});

export const addComment = (payload) => ({
  type: types.ADD_COMMENT,
  ...payload,
});
