import React from "react";

import { menuImage } from "./style";
import { GET_IMAGE } from "../../../helpers/images";
import blankPic from "../../../img/blankPic.png";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

const SearchedUserList = ({ image, name, userId, createChatTrigger }) => {
  return (
    <SoftBox
      display="flex"
      alignItems="start"
      sx={{
        "&:hover": {
          backgroundImage: "linear-gradient(310deg,#7928ca,#ff0080)",
          color: "#FFF !important",
          cursor: "pointer",
        },
        color: "#344767",
        padding: "0.5rem",
        borderRadius: "0.75rem",
      }}
      onClick={() => {
        createChatTrigger(userId);
      }}
    >
      <SoftBox
        width="2.25rem"
        height="2.25rem"
        mt={0.25}
        mb={0.25}
        borderRadius="lg"
        sx={(theme) => menuImage(theme)}
      >
        <img src={image ? GET_IMAGE(image) : blankPic} alt={name} />
      </SoftBox>
      <SoftBox ml={2} lineHeight={1}>
        <SoftTypography
          display="block"
          variant="h6"
          sx={{
            fontSize: "16px",
          }}
        >
          {name}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default SearchedUserList;
