import React, { useState, useEffect } from "react";
import { GET_CONNECTION_URL } from "../../api/integration";
import SoftButton from "../SoftButton";
import { GOOGLE_BUSINESS } from "../../constants/integrations";

const GoogleAuthButton = ({
  mode,
  label = null,
  onClick = null,
  width = null,
}) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    GET_CONNECTION_URL(GOOGLE_BUSINESS, { mode }).then(({ data }) =>
      setUrl(data),
    );
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {url && (
        <SoftButton
          onClick={() => {
            onClick?.();
            window.open(url, "_blank");
          }}
          color="primary"
          variant="contained"
          sx={{ color: "primary.contrastText", width: width ?? "auto" }}
        >
          {label ?? "Authorize Google My Business Access"}
        </SoftButton>
      )}
    </>
  );
};

export default GoogleAuthButton;
