import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftInput from "../../../SoftInput";
import SoftTypography from "../../../SoftTypography";

// ChallengeArena Form Components
import FormButton from "../Layout/FormButton";
import FormPageLayout from "../Layout/FormPageLayout";
import FormTextSummary from "../Layout/FormTextSummary";
import SvgIconWrapper from "../Layout/SvgIconWrapper";
import ChallengeBox from "../../ChallengeBox";

// Icons
import { ReactComponent as CompanyVsCompanyIcon } from "../../../../img/createchallenge-compvscomp-dark.svg";
import { ReactComponent as CompanyVsGroupIcon } from "../../../../img/createchallenge-companygroup-dark.svg";
import { ReactComponent as EmployeeVsIcon } from "../../../../img/createchallenge-empvsemp-dark.svg";

const CHALLENGE_CATEGORIES = {
  COMPANY_VS: "CompanyVsCompany",
  EMPLOYEE_VS: "EmployeeVsEmployee",
  COMPANY_GROUPS: "CompanyGroups"
}

const challengeIcons = {
  [CHALLENGE_CATEGORIES.COMPANY_VS]: CompanyVsCompanyIcon,
  [CHALLENGE_CATEGORIES.EMPLOYEE_VS]: EmployeeVsIcon,
  [CHALLENGE_CATEGORIES.COMPANY_GROUPS]: CompanyVsGroupIcon
};

const PreviewSubmitForm = ({ pagesFormData }) => {
  const prizes = [
    pagesFormData?.prizeForWinner ?? "",
    pagesFormData?.prizeforSecondPlace ?? "",
    pagesFormData?.prizeforThirdPlace ?? "",
    pagesFormData?.prizeforParticipants ?? ""
  ].filter(prize => prize !== "");

  return (
      <FormPageLayout 
        sx={{ 
          padding: { 
            mini: "1rem", 
            md: "1.5rem" 
          }, 
          "& .MuiGrid-item": { 
            width: "100%" 
          } 
        }}>
        <Grid container spacing={4}>
          {/** 1ST COLUMN */}
          <Grid item xs={12} sm={6}>
            {/** Challenge title */}
            <SoftBox>
              <SoftTypography variant="body2">
                Challenge Title
              </SoftTypography>
              <FormTextSummary id="challenge">
                {pagesFormData?.title ?? "-"}
              </FormTextSummary>
            </SoftBox>

            {/** Challenge Category */}
            <SoftBox
              sx={{
                "& .MuiButtonBase-root": {
                  boxShadow: "none !important",
                  color: "#344767"
                }
              }}
            >
              <SoftTypography variant="body2">
                Challenge Category
              </SoftTypography>

              <FormButton
                startIcon={SvgIconWrapper(challengeIcons[pagesFormData?.category] ?? CompanyVsCompanyIcon, "24px", "24px")}
                disabled
              >
                {pagesFormData?.category ?? "-"}
              </FormButton>
            </SoftBox>

            {/** Start/End Date */}
            <SoftBox
              sx={{
                display: "flex",
                gap: "16px",
                flexDirection: {
                  sm: "row",
                  xs: "column"
                },
                "& .MuiBox-root": {
                  width: "100%"
                },
                "& .MuiInputBase-root": {
                  backgroundColor: "transparent !important"
                }
              }}
            >
              <SoftBox>
                <SoftTypography variant="body2">
                  Start Date
                </SoftTypography>
                <SoftInput
                  placeholder="Start date"
                  type="date"
                  name="startDate"
                  defaultValue={pagesFormData?.startDate ?? null}
                  disabled
                />
              </SoftBox>
              <SoftBox>
                <SoftTypography variant="body2">
                  End Date
                </SoftTypography>
                <SoftInput
                  placeholder="End date"
                  type="date"
                  name="endDate"
                  defaultValue={pagesFormData?.endDate ?? null}
                  disabled
                />
              </SoftBox>
            </SoftBox>

            {/** Prizes */}
            <SoftBox>
              <SoftTypography variant="body2">
                Prizes
              </SoftTypography>
              <FormTextSummary
                sx={{ padding: "1rem" }}>
                {(prizes.length > 0)
                  ? (prizes?.map((prize) => (
                      <FormTextSummary key={crypto?.randomUUID() ?? prize}>
                        {prize}
                      </FormTextSummary>
                    )))
                  : <SoftTypography variant="body2">-</SoftTypography>
                }
              </FormTextSummary>
            </SoftBox>

            <SoftBox>
              <SoftTypography variant="body2">
                Description
              </SoftTypography>
              <FormTextSummary sx={{ minHeight: "100px" }}>
                {pagesFormData?.description ?? "-"}
              </FormTextSummary>
            </SoftBox>
          </Grid>

          {/** 2ND COLUMN */}
          <Grid item xs={12} sm={6}
          >
            <SoftBox>
              <SoftTypography variant="body2" sx={{ fontWeight: "bold" }}>
                Selected Theme for the Arena
              </SoftTypography>
              <ChallengeBox
                challenge={pagesFormData}
                setFetch={() => {}}
                fetch={0}
                isCountdownEnded
              />
            </SoftBox>
          </Grid>
        </Grid>
      </FormPageLayout>
  )
};

export default PreviewSubmitForm;
