import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftButton from "../../../SoftButton";
import SoftInput from "../../../SoftInput";
import SoftProfile from "../../../ProfilePictures/SoftProfile";
import SoftTypography from "../../../SoftTypography";

// ChallengeArena Form Components
import FormButton from "../Layout/FormButton";
import FormTextSummary from "../Layout/FormTextSummary";

// Hooks
import useDebounce from "../../../../hooks/useDebounce";
import {
  UseSelectedWorkspace,
  UseCompanyWorkspaceUsers
} from "../../../../hooks/company";
import { UseUserId } from "../../../../hooks/auth";

// Services
import { GET_IMAGE } from "../../../../helpers/images";

const InvitePlayers = ({
  usersToInvite,
  handleInviteUsers,
  isClose
}) => {
  const [openInvite, toggleInviteWindow] = useState(false);
  const [users, setUsers] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const userId =  UseUserId();
  const { _id: groupId } = UseSelectedWorkspace();
  const usersData = UseCompanyWorkspaceUsers(groupId);
  const debouncedText = useDebounce(searchText, 400) ?? null;

  useEffect(() => {
    toggleInviteWindow(false);
  }, [isClose]);

  const formattedPlayers = useMemo(() => {
    return usersData
      .filter(user => usersToInvite.includes(user.userId))
      .map(user => `${user.firstName} ${user.lastName}`)
      .reduce((allUsers, user, index) => {
        const comma = (index < usersToInvite.length - 1)
          ? ", " : "";

        allUsers += `${user}${comma}`
        return allUsers
      }, "");
  }, [usersToInvite, usersData]);

  useEffect(() => {
    if (usersData.length === 0) return;
    if (users !== null) return;

    // Initialize the players invite list
    setUsers(usersData?.filter(user => user?.userId !== userId));
  }, [usersData, userId, users]);

  useEffect(() => {
    if (debouncedText === null) return;

    const filteredUsers = usersData?.filter(item => {
      const username = `${item.firstName} ${item.lastName}`;

      return (
        username.toLowerCase().search(debouncedText.toLowerCase()) !== -1 && 
        item._id !== userId
      );
    });

    setUsers(filteredUsers);
  }, [debouncedText, userId, usersData]);

  const addOrRemoveUserToInviteList = (id) => {
    const index = usersToInvite.findIndex((user) => user === id);

    if (index !== -1) {
      const updatedList = usersToInvite.filter((user) => user !== id);
      handleInviteUsers(updatedList);
    } else {
      const copy = [...usersToInvite, id];
      handleInviteUsers(copy);
    }
  };

  const checkIfUserExist = (id) => {
    const userExist = usersToInvite.findIndex((user) => user === id);
    return (userExist !== -1)
  };

  return (
    <SoftBox>
      <FormButton 
        onClick={() => toggleInviteWindow(prev => !prev)}
        disabled={!users || usersData?.length === 0}
      >
        Invite Players to this challenge
      </FormButton>

      {/** Selected users list */}
      {(usersToInvite?.length > 0) &&
        <FormTextSummary sx={{ marginTop: "1rem", marginBottom: "0 !important" }}>
          {formattedPlayers}
        </FormTextSummary>
      }

      {/** Users search form */}
      <Collapse in={openInvite}>
        <SoftBox
          sx={{
            padding: "1rem 0 1rem 0"
          }}
        >
          <SoftInput
            placeholder="Search user by name"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <SoftBox
            marginY="1rem"
            sx={{
            borderRadius: "10px",
            border: "1px solid rgba(73, 80, 87, 0.50)",
            padding: "1rem",
            height: "250px",
            overflowY: "auto",
          }}
          >
            {(users ?? [])?.map((user) => (
              <SoftBox
                key={user.userId}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <SoftProfile
                    src={GET_IMAGE(user.image) || ""}
                    alt=""
                    size="md"
                  />
                  <SoftTypography variant="h6" sx={{ fontSize: "14px" }}>
                    {user.firstName} {user.lastName}
                  </SoftTypography>
                </SoftBox>
                <SoftBox>
                  <SoftButton
                    onClick={() => addOrRemoveUserToInviteList(user.userId)}
                    sx={{
                      textTransform: "none",
                      minWidth: "max-content",
                      minHeight: "max-content",
                      padding: "0.5rem 0.75rem",
                      borderRadius: "32px !important",
                      border: "1px solid gray !important",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "primary.lighter",
                        background:
                        "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)"
                      },
                    }}
                  >
                    {checkIfUserExist(user.userId) ? "Unsend" : "Send"}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            ))}
          </SoftBox>
        </SoftBox>
      </Collapse>
    </SoftBox>
  );
}

export default InvitePlayers;
