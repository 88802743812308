import { styled } from "@mui/material/styles";

import SoftButton from "../../../../components/SoftButton";

const AccountButton = styled(SoftButton)(({ theme }) => ({
  fontFamily: "DM Sans",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  color: "#000",
  textTransform: "capitalize",
  padding: "9px 20px",
  border: "1px solid #D0D5DD",
  borderRadius: "4px"
}))

export default AccountButton;
