import React, { useMemo } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateWorkspaceKpi } from "../../../redux/kpi/actions";
import { UseSelectedWorkspace } from "../../../hooks/company";

import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import HighlightKeyword from "../../TextEffects/HighlightKeyword";
import startCase from "lodash/startCase";

import zapierLogo from "../../../img/brands/zapier-logo_black.png";
import { ReactComponent as MetricIcon } from "../../../img/metric_card_icon.svg";
import { ReactComponent as DataCubeIcon } from "../../../img/datacube-icon.svg";
import BoltIcon from "@mui/icons-material/Bolt";
import ConfirmationModal from "../../Modals/ConfirmationModal";

import CARD_STYLES from "../configs/cardStyles";

const MetricCard = ({ disabled, metric, keyword, onAddMetric }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id: groupId } = UseSelectedWorkspace();

  const handleSave = () => {
    const integrationMeta = metric.isZapier
      ? {
          id: metric.id,
          code: metric.code,
          status: "disconnected",
        }
      : null;

    const displayMetricPayload = {
      target: 0,
      pointScale: 0,
      metricType: "display",
      direction: 1
    };

    dispatch(
      updateWorkspaceKpi({
        groupId,
        kpiModelId: metric.kpiModelId,
        type: metric.type,
        sourceId: metric.sourceId,
        ...displayMetricPayload, // 20240423: Create Integrated Metric GroupKPIs as a "Display" metric by default, then offer to morph them to "Progressive" or "Anchor" only after dispatch
        ...(metric.integrationId && { integrationId: metric.integrationId }), // Not all KPIs have integrationId (like Manual-input KPIs)
        ...(integrationMeta && { integrationMeta }),
      }),
    );

    const metricData = {
      ...metric,
      ...(integrationMeta && { integrationMeta })
    }

    onAddMetric?.(metricData)

    /* 20240423: Defer Zapier setup after morphing the Display Metric into a Progressive or Anchor metric
    if (metric.isZapier) {
      navigate(`/zapier-setup?id=${metric.id}`);
    }
    */
  };

  const style = useMemo(() => {
    if (metric.isZapier) {
      return CARD_STYLES.zapier;
    }
    return metric.isConnected ? CARD_STYLES.connected : CARD_STYLES.default;
  }, [metric]);

  return (
    <SoftBox
      variant="contained"
      borderRadius="md"
      className="flex flex-col justify-between items-start gap-2"
      sx={{
        minHeight: "135px",
        border: `1px solid ${style.borderColor}`,
        background: style.bgColor,
        paddingX: { xs: "1rem", xxl: "1.5rem" },
        paddingY: { xs: "0.75rem", xxl: "1rem" },
      }}
    >
      <SoftBox className="w-full flex justify-between items-center">
        <SoftBox className="flex items-center gap-2">
          <MetricIcon width="1.5rem" height="1.5rem" />
          <SoftTypography
            variant="body2"
            sx={{
              color: "#6C757D",
              fontSize: { xs: "0.6rem", xxl: "0.8rem" },
              fontWeight: 500,
            }}
          >
            <HighlightKeyword
              text={metric.app}
              keyword={keyword}
              color="#04549C"
            />
          </SoftTypography>
        </SoftBox>
        <SoftBox className="flex gap-0 items-center">
          {metric.isZapier && (
            <img
              src={zapierLogo}
              alt="Zapier"
              style={{
                height: "1rem",
                width: "auto",
                filter: !metric.isConnected ? "grayscale(80%)" : "none",
              }}
            />
          )}
          {metric.isConnected && !metric.isManual && (
            <BoltIcon
              sx={{
                color: style.borderColor,
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          )}
          {metric.isManual && (
            <>
              <DataCubeIcon width="1.5rem" height="1.5rem" />
              <SoftTypography
                sx={{
                  marginLeft: 1,
                  color: "#343A40",
                  fontSize: { xs: "0.6rem", xxl: "0.8rem" },
                  fontWeight: 600,
                }}
              >
                Data Cube
              </SoftTypography>
            </>
          )}
        </SoftBox>
      </SoftBox>
      <SoftBox className="flex flex-col gap-1 mt-2">
        <SoftTypography
          variant="h5"
          sx={{
            color: "#000",
            lineHeight: "normal",
          }}
        >
          <HighlightKeyword
            text={startCase(metric.type)}
            keyword={keyword}
            color="#04549C"
          />
        </SoftTypography>
        <SoftTypography
          variant="body2"
          className="truncate text-ellipsis"
          sx={{
            color: "#6C757D",
            fontSize: "0.65rem",
            fontWeight: 400,
            maxWidth: { xs: "160px", xxl: "240px" },
          }}
        >
          {metric.description ?? `${startCase(metric.type)} metric`}
        </SoftTypography>
      </SoftBox>
      <SoftBox className="w-full flex justify-end items-center">
        <ConfirmationModal
          title={metric.app}
          question={`Activate ${startCase(metric.type)} KPI?`}
          yesText="Save"
          noText="Cancel"
          onSuccess={() => handleSave()}
        >
          <SoftButton
            variant="outlined"
            color="primary"
            size="small"
            className="!normal-case !py-0 !px-2 !font-['Inter'] !text-[10px]"
            disabled={disabled}
          >
            Add Metric
          </SoftButton>
        </ConfirmationModal>
      </SoftBox>
    </SoftBox>
  );
};

export default MetricCard;
