import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import Select from "react-select";
import toast from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";

import SoftInput from "../SoftInput";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import { UseUsersDictionary } from "../../hooks/company";
import { UseUserDetails } from "../../hooks/auth";
import { renameGroup, updateGroupUsers } from "../../redux/chats/actions";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
  border: "none",
};

const UpdateGroupChatModal = ({ openModal, setOpenModal, selectedChat }) => {
  const [searchKeyword, setSearchKeyword] = useState(selectedChat.chatName);
  const [selectedUsers, setSelectedUsers] = useState(openModal.users);
  const [users, setUsers] = useState([]);

  const usersDict = UseUsersDictionary();
  const userDetails = UseUserDetails();
  const dispatch = useDispatch();
  const { updatingGroup } = useSelector((state) => state.chatsReducer);

  const onChangeTrigger = async (e) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    const values = Object.keys(usersDict).map((key) => ({
      _id: key,
      value: key,
      label: `${usersDict[key].firstName} ${usersDict[key].lastName}`,
      ...usersDict[key],
    }));

    const updatedUsers = values.filter((item) => userDetails._id !== item._id);
    setUsers(updatedUsers);
  }, []);

  const updateGroupChatTrigger = async () => {
    if (searchKeyword === "" || !selectedUsers.length) {
      toast.error("Fields can't be empty");
    } else if (selectedUsers.length <= 2) {
      toast.error("More than 2 users are required to form a group chat");
    } else {
      try {
        if (searchKeyword !== selectedChat.chatName) {
          const payload = {
            chatId: selectedChat._id,
            chatName: searchKeyword,
          };

          dispatch(renameGroup(payload));
        }
        if (selectedUsers.length !== selectedChat.users.length) {
          const userIds = selectedUsers.map((item) => item._id);
          const payload = {
            chatId: selectedChat._id,
            users: userIds,
          };

          dispatch(updateGroupUsers(payload));
        }

        setOpenModal({ open: false, users: selectedUsers });
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <Modal
      open={openModal.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftTypography
            component="h6"
            className="text-black mb-3"
            sx={{ fontSize: "16px", fontWeight: "500" }}
          >
            {selectedChat.chatName}
          </SoftTypography>
          <IconButton
            sx={{
              fontWeight: "fontWeightBold",
              stroke: "dark.main",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal({ open: false, users: selectedUsers })}
          >
            <CloseIcon />
          </IconButton>
        </SoftBox>
        <SoftInput
          placeholder="Group name"
          value={searchKeyword}
          onChange={onChangeTrigger}
          required
          className="mb-3"
        />
        {userDetails._id === selectedChat.groupAdmin._id ? (
          <Select
            isLoading={false}
            options={users}
            isMulti
            name="kpimodels"
            value={selectedUsers}
            onChange={(selectedOption) => {
              setSelectedUsers(selectedOption);
            }}
            className="mb-5"
          />
        ) : (
          <Select
            isLoading={false}
            options={[]}
            isMulti
            name="kpimodels"
            value={selectedUsers}
            // onChange={(selectedOption) => {
            //   setSelectedUsers(selectedOption);
            // }}
            isClearable
            isDisabled
            className="mb-5"
          />
        )}
        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
          <SoftButton
            // onClick={createGroupChatTrigger}
            sx={{
              color: "primary.contrastText",
              backgroundColor: "#DC3545",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "#DC3545",
              },
            }}
            size="small"
            loading={updatingGroup}
            disabled={updatingGroup}
          >
            Leave Group
          </SoftButton>
          <SoftButton
            onClick={updateGroupChatTrigger}
            color="primary"
            sx={{ color: "primary.contrastText" }}
            size="small"
            loading={updatingGroup}
            disabled={updatingGroup}
          >
            Update
          </SoftButton>
        </SoftBox>
      </div>
    </Modal>
  );
};

export default UpdateGroupChatModal;
