import React from "react";
import { styled } from "@mui/material/styles";
import SoftBox from "../../../SoftBox";

const FormSection = styled(SoftBox)(({ theme }) => ({
  "& div": {  // Internal SoftBox styles
    "& .Mui-focused": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0rem 0rem 0rem 0.125rem ${theme.palette.primary.main}`
    }
  }
}))

export default FormSection;
