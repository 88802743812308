import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Box, Grid, Tooltip } from '@mui/material';
import { makeStyles } from "@mui/styles";

import { UseIsAdmin, UseUserDetails, UseIsPrime } from "../../hooks/auth";
import {
  UseCompanyLogo,
  UseCompanyName,
  UseCompanyIndustry,
  UseSelectedWorkspace,
} from "../../hooks/company";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import "./heroStyles.css";
import Avatar from "../Avatar";
import DynamicComponent, { Components } from "../../pages/Widgets/components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as BookIcon } from "../../img/playbook-icon.svg";
import SoftButton from "../SoftButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PageHeaderBg from "../../img/gamesettings/mainSection-min.png";
import PageHeaderBg2 from "../../img/gamesettings/truckBg.png";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import { SUBMIT_BGIMAGE, GET_BACKGROUND } from "../../api/group";

const Hero = () => {
  const user = UseUserDetails();
  const logo = UseCompanyLogo();
  const companyName = UseCompanyName();
  const industry = UseCompanyIndustry();
  const isAdmin = UseIsAdmin();
  const isPrime = UseIsPrime();
  const workspace = UseSelectedWorkspace();
  const navigate = useNavigate();
  const [reloadKey, setReloadKey] = useState(0);

  const [userWidget, setUserWidget] = useState([]);
  const [quoteWidget, setQuoteWidget] = useState({});
  const [time, setTime] = useState(null);
  const xsBp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMidsizeScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [openModal, setOpenModal] = useState(false);

  const handleImageClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (imageName) => {
    setSelectedImage(imageName);
  };

  const images = [
    { name: 'PageHeaderBg', src: PageHeaderBg },
    { name: 'PageHeaderBg2', src: PageHeaderBg2 },
  ];

  const { _id: groupId } = UseSelectedWorkspace();
  const [workspaceData, setWorkspaceData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await GET_BACKGROUND(groupId);
      setWorkspaceData(response.data);  
    } catch (error) {
      console.error("Error fetching the award data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [groupId]);

  const submitImage = async () => {
    if (selectedImage) {
      try {
        const body = {
          groupId,
          backgroundImage: selectedImage,
        };
        await SUBMIT_BGIMAGE(body);
        fetchData();
        setOpenModal(false);
        setSelectedImage(null);
      } catch (error) {
        console.error("Error submitting background image:", error);
      }
    } else {
      console.warn("No image selected");
    }
  };

  const workspaceBg = images.find(img => img.name === workspaceData?.backgroundImage)?.src || PageHeaderBg;

  const styleModal = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    backdropFilter: 'blur(25px)'
  };

  const DefaultWidgets = [
    { Widget: Components.ranking, index: 0 }, // RankingComponent
    { Widget: Components.top_kpi, index: 1 }, // TopKPIComponent
    { Widget: Components.level, index: 2 } // LevelComponent
  ];

  useEffect(() => {
    // Real-time clock update
    const currentTime = new Date();
    const interval = setInterval(() => setTime(new Date()), 60 * 1000); // Update every minute only
    setTime(currentTime);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setReloadKey(Math.round(Math.random() * 1000));
    };

    // Window resize listener to refresh (and resize) avatar
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user && user.widgets && user.widgets.length) {
      const userWidgets = [];
      let userQuote = {};
      let randomNumber = 0;

      user.widgets.forEach((widget) => {
        if (widget.key === "quote_of_the_day") {
          const quotes = widget.multilineUserInput.map((quote) => {
            const splittedQuote = quote.split("-");

            return {
              quote: splittedQuote[0].trim(),
              author: splittedQuote[1].trim(),
            };
          });

          const currentDate = moment();
          const dayOfYear = currentDate.dayOfYear();

          const indexOfTodaysQuote = dayOfYear % (quotes.length - 1);

          if (quotes[indexOfTodaysQuote]) {
            randomNumber = indexOfTodaysQuote;
          }

          userQuote = quotes[randomNumber];
        } else {
          userWidgets.push(widget);
        }
      });

      setUserWidget(userWidgets);
      setQuoteWidget(userQuote);
    }
  }, []);

  const useStyles = makeStyles(() => ({
    topBoxbg: {
      filter: "drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06))",
      paddingTop: { mini: "101.25px", xs: "109.25px", md: "75px" },
      // paddingBottom: "2rem",
      borderRadius: "12px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      maxHeight: { mini: "960px", xxs: "1000px", md: "850px" },
      animation: "$bgAnimations 45s infinite alternate",
    },
    "@keyframes bgAnimations": {
      "0%": {
        backgroundPosition: "left",
      },
      "50%": {
        backgroundPosition: "right",
      },
      "100%": {
        backgroundPosition: "center center",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      <Grid container>
        <Grid item mini={12} md={12} lg={9}>
          <SoftBox
            sx={{
              height: { mini: "736px", xxs: "736px", md: "625px" },
              padding: { mini: "0", md: "1.5rem" },
              paddingRight: { mini: "0", md: "32px" },
              paddingTop: { mini: "0", md: "32px" },
              ...(isSmallScreen && ({
                minHeight: "85vh",
                height: "100%",
                marginBottom: "16px",
              })),
            }}
          >
            <SoftBox className={classes.topBoxbg}
              sx={{
                background: `url(${workspaceBg})`,backgroundRepeat: "no-repeat",
                borderRadius: "12px",
                ...(isSmallScreen && ({ filter: "none !important", borderRadius: "0px" })),
                ...(!workspaceBg.includes("mainSection-min") && {
                  backgroundSize: isSmallScreen ? "cover" : "100%",
                }) 
              }}
            >
              <div style={{
                background: `linear-gradient(${isSmallScreen ? 155.85 : 100.85}deg, #F9FAFB 25.18%, rgba(247, 248, 249, 0.74) 40.61%, rgba(238, 239, 240, 0.47) 59.75%, rgba(249, 250, 252, 0) 94.73%)`,
                borderRadius: "12px",
                height: "inherit",
                ...(isSmallScreen && {
                    borderRadius: "0px",
                    display: "flex",
                    flexDirection: "column",
                  }),
                }}
              >
                <SoftBox sx={{ paddingLeft: "2rem" }}>
                  <SoftTypography
                    sx={{
                      background:
                        "radial-gradient(100% 784.37% at 0% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.9) 25%, #FFF 50%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0.3) 100%, rgba(255, 255, 255, 0.2) 100%)",
                      backgroundClip: "text",
                      textFillColor: "primary.main",
                      fontWeight: "bold",
                      fontSize: { mini: "24px", sm: "2rem", md: "2rem" },
                      marginTop: {
                        mini: "1rem",
                        md: "4.5rem",
                      },
                      lineHeight: "32px",
                    }}
                  >
                    Good Morning, {user?.firstName ?? "Player"}
                  </SoftTypography>
                  {(!isSmallScreen)
                    ? <>
                        <SoftTypography sx={{ textFillColor: "primary.main", marginTop: "1rem", marginBottom: "1rem", fontSize: "15px", fontWeight: '500' }}>
                          <span style={{cursor: "pointer"}} onClick={() => {
                            if (isAdmin) {
                              navigate("/playbook-editor");
                            } else {
                              navigate("/playbook-viewer");
                            }
                          }}>{isAdmin ? "Upload Playbook" : "View Playbook"}</span>{isAdmin && (<> | <span style={{cursor: "pointer"}} onClick={() => navigate("/settings/players")}>Manage Players</span></>)}
                        </SoftTypography>
                        <SoftTypography sx={{ textFillColor: 'primary.main', fontSize: '22px', lineHeight: '24px', fontWeight: '500' }}>
                          Hello Player
                        </SoftTypography>
                      </>
                    :  <SoftTypography
                          sx={{ textFillColor: "primary.main", fontWeight: '500', fontSize: "14px", color: "#667085" }}
                          variant="body2"
                        >
                          {time &&
                            time.toLocaleString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }) + " "}
                      </SoftTypography>
                  }
                </SoftBox>
                <SoftBox sx={{ position: "relative", ...(isSmallScreen && ({ marginTop: "20px" })) }}>
                  <SoftBox
                    sx={{
                      position: "absolute",
                      width: {
                        mini: "100vw",
                        md: "50vw",
                        lg: "50vw",
                        xl: "50vw"
                      },
                      marginTop: {
                        md: "-10.5rem",
                      },
                      height: {
                        mini: "58vh",
                        md: "515px",
                      },
                      right: 0,
                    }}
                  >
                    <Avatar
                      key={`Avatar${reloadKey}`}
                      url={user.avatar}
                      logo={logo}
                      industry={industry}
                      noControls={true}
                    />

                {(isSmallScreen && quoteWidget?.quote) &&
                  <SoftBox sx={{ position: "relative", padding: "20px", bottom: 0,
                      top: 0,
                      left: 0,
                      // transform: "translate(0, 50%)"
                  }}>
                    <SoftTypography
                      variant="body1"
                      sx={{
                        color: "#1D2939",
                        maxWidth: "600px",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "20px",
                        marginBottom: "8px"
                      }}
                    >
                      "{quoteWidget.quote}"
                    </SoftTypography>
                    <SoftTypography
                      sx={{ textFillColor: "#1D2939", fontSize: "16px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "16px" }}
                      variant="body2"
                    >
                      {quoteWidget.author}
                    </SoftTypography>
                  </SoftBox>
                }
                  </SoftBox>
                </SoftBox>
                <Box
                  sx={{
                    marginX: "auto",
                    width: "100%",
                    paddingX: { mini: "0.5rem", md: "1rem", xl: "2rem" },
                    flex: 1,
                    display: "grid",
                    alignItems: "center",
                    bottom: 25,
                    position: "relative",
                  }}
                >
                  <SoftBox
                    sx={{
                      alignSelf: "end",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "13.7rem",
                        marginBottom: "2rem",
                        ...(isSmallScreen && ({
                          maxWidth: "100%",
                          display: "unset",
                          marginTop: "unset",
                          marginBottom: "unset"
                        }))
                      }}
                    >
                      {quoteWidget && quoteWidget.quote ? (
                        !isSmallScreen &&
                        <SoftBox sx={{maxWidth: "45%"}}>
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: { mini: "0.25rem", md: "0.5rem" },
                            }}
                          >
                            <SoftTypography
                              variant={xsBp ? "h1" : "h2"}
                              sx={{
                                textFillColor: "primary.main",
                                maxWidth: "600px",
                                fontSize: "18px",
                                fontWeight: "500",
                                fontStyle: "normal",
                                lineHeight: "28px",
                              }}
                            >
                              "{quoteWidget.quote}"
                            </SoftTypography>
                          </SoftBox>
                          <SoftTypography
                            sx={{ textFillColor: "#98A2B3", fontSize: "18px", fontStyle: "italic", marginBottom: "1.5rem" }}
                            variant="body2"
                          >
                            {quoteWidget.author}
                          </SoftTypography>
                          {(!isSmallScreen) &&
                            <SoftTypography
                              sx={{ textFillColor: "primary.main", fontWeight: '500', fontSize: "15px" }}
                              variant="body2"
                            >
                              {time &&
                                time.toLocaleString("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }) + " "}
                            </SoftTypography>
                          }
                        </SoftBox>
                      ) : (
                        <SoftBox sx={{marginTop: "4.65rem", marginBottom: "2rem"}}>
                          <SoftTypography
                            sx={{ fillColor: "primary.main", fontWeight: '500', fontSize: "15px" }}
                            variant="body2"
                          >
                            {(!isSmallScreen && time) &&
                              time.toLocaleString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }) + " "
                            }
                            {workspace?.name ?? companyName}
                          </SoftTypography>
                        </SoftBox>
                      )}

                      {(!isSmallScreen) && (
                        <>
                          <SoftButton
                            variant="contained"
                            sx={{
                              background: (theme) => theme.palette.tertiary.dark,
                              height: "max-content",
                              width: "max-content",
                              minWidth: "max-content",
                              minHeight: "max-content",
                              borderRadius: "50%",
                              padding: "0.5rem",
                              marginTop: "4rem",
                              "&:hover": {
                                background: (theme) => theme.palette.tertiary.dark,
                              },
                              "&:focus:not(:hover)": {
                                background: (theme) => theme.palette.tertiary.dark,
                              },
                            }}
                            onClick={() => {
                              if (isAdmin) {
                                navigate("/playbook-editor");
                              } else {
                                navigate("/playbook-viewer");
                              }
                            }}
                          >
                            <BookIcon />
                          </SoftButton>
                          {(isAdmin) && (
                          <>
                          <SoftButton
                            variant="contained"
                            sx={{
                              background: 'linear-gradient(180deg, rgba(52, 64, 84, 0.25) 0%, rgba(115, 142, 186, 0.25) 100%)',
                              border: '1px solid rgba(102, 112, 133, 0.2)',
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16), inset 4px 0px 4px rgba(83, 81, 81, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              height: "max-content",
                              width: "max-content",
                              minWidth: "max-content",
                              minHeight: "max-content",
                              borderRadius: "50%",
                              padding: "0.5rem",
                              top: "-8rem!important",
                              position: 'absolute',
                              right: '2rem!important',
                              "&:hover": {
                                background: (theme) => theme.palette.tertiary.dark,
                              },
                              "&:focus:not(:hover)": {
                                background: (theme) => theme.palette.tertiary.dark,
                              },
                            }}
                            onClick={isAdmin ? handleImageClick : undefined}
                            >
                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_35_4281)">
                              <path d="M7.42498 24.3999C6.66248 24.3999 5.94998 24.1374 5.43748 23.6499C4.78748 23.0374 4.47498 22.1124 4.58748 21.1124L5.04998 17.0624C5.13748 16.2999 5.59998 15.2874 6.13748 14.7374L16.4 3.87494C18.9625 1.16244 21.6375 1.08744 24.35 3.64994C27.0625 6.21244 27.1375 8.88744 24.575 11.5999L14.3125 22.4624C13.7875 23.0249 12.8125 23.5499 12.05 23.6749L8.02498 24.3624C7.81248 24.3749 7.62498 24.3999 7.42498 24.3999ZM20.4125 3.63744C19.45 3.63744 18.6125 4.23744 17.7625 5.13744L7.49998 16.0124C7.24998 16.2749 6.96248 16.8999 6.91248 17.2624L6.44998 21.3124C6.39998 21.7249 6.49998 22.0624 6.72498 22.2749C6.94998 22.4874 7.28748 22.5624 7.69998 22.4999L11.725 21.8124C12.0875 21.7499 12.6875 21.4249 12.9375 21.1624L23.2 10.2999C24.75 8.64994 25.3125 7.12494 23.05 4.99994C22.05 4.03744 21.1875 3.63744 20.4125 3.63744Z" fill="#EAECF0"/>
                              <path d="M22.1751 13.6876C22.1501 13.6876 22.1126 13.6876 22.0876 13.6876C18.1876 13.3001 15.0501 10.3376 14.4501 6.46256C14.3751 5.95006 14.7251 5.47506 15.2376 5.38756C15.7501 5.31256 16.2251 5.66256 16.3126 6.17506C16.7876 9.20006 19.2376 11.5251 22.2876 11.8251C22.8001 11.8751 23.1751 12.3376 23.1251 12.8501C23.0626 13.3251 22.6501 13.6876 22.1751 13.6876Z" fill="#EAECF0"/>
                              <path d="M26.75 28.4375H4.25C3.7375 28.4375 3.3125 28.0125 3.3125 27.5C3.3125 26.9875 3.7375 26.5625 4.25 26.5625H26.75C27.2625 26.5625 27.6875 26.9875 27.6875 27.5C27.6875 28.0125 27.2625 28.4375 26.75 28.4375Z" fill="#EAECF0"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_35_4281">
                              <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
                              </clipPath>
                              </defs>
                            </svg>
                          </SoftButton>
                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={openModal}>
                              <SoftBox sx={styleModal}>
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                                    Select Your Gameboard Theme 
                                  </SoftTypography>
                                  <SoftButton
                                    variant="outlined"
                                    color="white"
                                    onClick={handleCloseModal}
                                    sx={{
                                      "& svg": { fontSize: "1.25rem !important" },
                                      borderRadius: "50%",
                                      minWidth: "max-content",
                                      minHeight: "max-content",
                                      padding: "0.25rem",
                                    }}
                                  >
                                    <CloseIcon />
                                  </SoftButton>
                                </SoftBox>
                                <hr style={{marginTop: '1rem'}}/>
                                <SoftBox sx={{marginTop: '1rem'}}>
                                  <form>
                                    <Grid container>
                                      {images.map((image) => (
                                        <Grid item mini={12} md={6} lg={6} key={image.name} style={{padding: '.25rem'}}>
                                          <label style={{
                                            border: selectedImage === image.name ? '2px solid #FFFFFF' : 'none',
                                            display: 'block',
                                            cursor: 'pointer',
                                            borderRadius: '8px'
                                          }}>
                                            <input
                                              type="radio"
                                              name="imageselect"
                                              value={image.name}
                                              style={{display: 'none'}}
                                              checked={selectedImage === image.name}
                                              onChange={() => handleImageSelect(image.name)}
                                            />
                                            <img src={image.src} alt="" style={{width: '100%', borderRadius: '8px'}}/>
                                          </label>
                                        </Grid>
                                      ))}
                                    </Grid>
                                    <SoftBox sx={{marginTop: '1.5rem', marginBottom: '4rem'}}>
                                      <SoftButton
                                        variant="outlined"
                                        color="white"
                                        onClick={submitImage}
                                        sx={{
                                          background: (theme) => theme.palette.tertiary.dark,
                                          position: 'absolute',
                                          right: '2rem!important',
                                        }}
                                      >
                                        Select
                                      </SoftButton>
                                    </SoftBox>
                                  </form>
                                </SoftBox>
                              </SoftBox>
                            </Fade>
                          </Modal>
                          </>
                          )}
                        </>
                    )}
                    </SoftBox>
                  </SoftBox>
                </Box>
              </div>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item mini={12} md={12} lg={3}>
          <SoftBox
            sx={{
              height: { mini: "835px", xxs: "875px", md: "625px" },
              padding: { mini: "0.5rem", xxs: "1.5rem 1.5rem 1.5rem 0rem" },
              paddingTop: { md: "32px" },
              ...(isMidsizeScreen && ({
                padding: "1.5rem 1.5rem 0 1.5rem !important",
                minHeight: "1px !important",
                height: "unset"
              }))
            }}
          >
            <SoftBox
              sx={{
                background: "#fff",
                paddingTop: { mini: "101.25px", xs: "109.25px", md: "75px" },
                paddingBottom: "2rem",
                overflowX: "hidden",
                borderRadius: "12px",
                height: "100%",
                maxHeight: { mini: "960px", xxs: "1000px", md: "850px" },
                ...(isMidsizeScreen && ({ maxHeight: "unset" }))
              }}
            >
              <SoftBox sx={{ padding: { mini: "8px 16px 0 16px", sm: "0 2rem 0 2rem" }, marginTop: { mini: "-6rem", sm: "-5rem", md: "-3rem" } }}>
                <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <SoftTypography
                    sx={{
                      textFillColor: "primary.main",
                      color: "#000000",
                      fontSize: { mini: "14px", sm: "20px" },
                      lineHeight: { mini: "18px", sm: "24px" },
                      fontWeight: { mini: 500, sm: 600 }
                    }}
                  >
                    My Widgets
                  </SoftTypography>
                  <AddIcon sx={{height: "20px", width: "20px", cursor: 'pointer'}} onClick={() => navigate("/widgets")} />
                </SoftBox>
                {!isMidsizeScreen && <hr className="my-3 divide-slate-300" />}
              </SoftBox>

              {isMidsizeScreen && <hr className="my-3 divide-slate-300" />}

              <Box
                sx={{
                  marginX: "auto",
                  width: "100%",
                  paddingX: { mini: "0.5rem", md: "1rem", xl: "2rem" },
                  ...(isMidsizeScreen && ({ paddingY: "16px" })),
                  flex: 1,
                  // display: "grid",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {userWidget && userWidget.length ? (
                  userWidget.map((widget) => (
                    <div
                      key={widget.key}
                      style={{ ...(isMidsizeScreen && ({ padding: "0 1rem 1.5rem 1rem" })) }}
                    >
                      <DynamicComponent
                        widget={widget}
                        showPerformBox={true}
                        key={widget.key}
                        showGraph={false}
                        isSmallScreen={isMidsizeScreen}
                      />
                    </div>
                  ))
                ) : (
                  DefaultWidgets.map((DefaultWidget) => (
                    <div
                      key={DefaultWidget.index}
                      style={{ ...(isMidsizeScreen && ({ padding: "0 1rem 1.5rem 1rem" })) }}
                    >
                      <DefaultWidget.Widget showPerformBox={true} isSmallScreen={isMidsizeScreen} />
                    </div>
                  ))
                )}
                <Grid container sx={{ marginTop: { mini: "1rem", lg: "3rem" } }}>
                  <Grid item mini={12} style={{ ...(isMidsizeScreen && ({ paddingRight: "20px" })),
                  display: 'flex', 
                  justifyContent: 'flex-end'
                   }}>
                    <Tooltip title="Boost your company's performance with our custom widgets, designed to streamline operations and enhance efficiency." placement="bottom-end">
                      <SoftTypography sx={{ color: "#000000", fontSize: "12px", lineHeight: "24px", fontWeight: "500" }}>
                        {isMidsizeScreen ? <span>&nbsp;</span> : "What is a widget?"}
                      </SoftTypography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;


