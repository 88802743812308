import React, { useState } from "react";
import { Dialog } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReactPlayer from "react-player/youtube";

import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";
import SoftButton from "../../../../SoftButton";
import "./style.css";

const VideoDialog = ({ metric }) => {
  const [videoDialog, setVideoDialog] = useState(false);

  const openVideoDialog = () => setVideoDialog(true);
  const closeVideoDialog = () => setVideoDialog(false);

  return (
    <>
      <SoftButton
        variant="text"
        onClick={openVideoDialog}
        sx={{
          marginTop: "10px",
          textTransform: "none",
          color: "#000",
          fontWeight: 500,
          "&:hover": {
            color: "#000",
            textDecoration: "underline",
          },
        }}
      >
        <YouTubeIcon fontSize="large" />
        &nbsp;Start with a short Video
      </SoftButton>
      {videoDialog && (
        <Dialog
          open={videoDialog}
          onClose={closeVideoDialog}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              height: "665px",
            },
          }}
        >
          <SoftBox>
            <SoftBox
              sx={{ height: "200px", background: "#000", position: "relative" }}
            >
              <SoftButton
                variant="outlined"
                color="white"
                onClick={closeVideoDialog}
                sx={{
                  "& svg": { fontSize: "1.25rem !important" },
                  borderRadius: "50%",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  padding: "0.25rem",
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                }}
              >
                <CloseRoundedIcon />
              </SoftButton>
              <SoftBox
                sx={{
                  width: "80%",
                  position: "absolute",
                  left: "0px",
                  right: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  top: "80px",
                }}
              >
                <ReactPlayer url={metric.videoUrl} />
                <SoftBox sx={{ paddingTop: "20px" }}>
                  <SoftTypography
                    variant="p"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#495057",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Explore the step-by-step guide to creating custom metrics
                    for your workspace. This video provides a comprehensive
                    overview of the metric creation process, covering essential
                    inputs such as KPI name, KPI type, target setting, and point
                    scale. Whether you're new to metric creation or looking for
                    a refresher, this tutorial will help you navigate through
                    the customization options seamlessly.
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  sx={{ marginY: "15px" }}
                >
                  <SoftButton
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: "#344054",
                      fontWeight: 600,
                      fontSize: "18px",
                      fontFamily: "DM Sans",
                      border: "1px solid #344054",
                      borderRadius: "4px",
                      "&:hover": {
                        color: "#344054",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={closeVideoDialog}
                  >
                    Got it
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Dialog>
      )}
    </>
  );
};

export default VideoDialog;
