import React, { useState, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import debounce from "lodash/debounce";
import SoftBox from "../SoftBox";

const ListSelectionDialog = ({
  title,
  items,
  onSelect,
  onCancel,
  searchTags = [],
  autoOpen = false,
  ...props
}) => {
  const [open, setOpen] = useState(autoOpen);
  const [selected, setSelected] = useState(
    items?.length === 1 ? items[0] : null,
  ); // Auto-select if it's just one item
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);

  const handleClose = () => {
    setSearch("");
    setOpen(false);
    onCancel?.();
  };

  const handleSelect = () => {
    if (selected) {
      onSelect(selected);
    }
    setOpen(false);
  };

  const handleSearch = useMemo(
    () =>
      debounce((searchKey) => {
        if (searchKey) {
          const key = searchKey.trim().toLowerCase();
          const tempItems = [...items];
          const searchFields = ["header1", "header2", "subheader", "text"];
          const matches = [];
          searchFields.forEach((field) => {
            for (let i = 0; i < tempItems.length; i++) {
              const item = tempItems[i];
              if (item[field]?.toLowerCase().indexOf(key) >= 0) {
                matches.push(item);
                tempItems.splice(i, 1);
                i--;
              }
            }
          });
          setFilteredItems(matches);
        } else {
          setFilteredItems(items);
        }
      }, 1000),
    [items],
  );

  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open, handleSearch]);

  return (
    <>
      <SoftBox
        sx={{ cursor: "pointer", width: "100%" }}
        onClick={() => {
          if (items.length) {
            setOpen(true);
            props.onClick?.();
          }
        }}
      >
        {props.children}
      </SoftBox>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "w-full !rounded-2xl text-center px-4 md:px-12 py-10",
        }}
        sx={{
          margin: "auto",
          width: { mini: "100vw", sm: "90vw" },
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
        >
          {title}
        </Typography>
        <TextField
          sx={{
            mt: 2,
            mb: 2,
            "& label": { paddingLeft: 2 },
            "& input": { paddingLeft: 4 },
            "& fieldset": {
              paddingLeft: 2,
              borderRadius: 10,
            },
          }}
          value={search}
          onChange={(e) => {
            const key = e.target.value.trim();
            setSearch(key);
            if (key) {
              handleSearch(key);
            }
          }}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Enter search keyword"
          hiddenLabel
          fullWidth
        />
        <Stack
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          sx={{ px: 2, mb: 2 }}
        >
          {searchTags.length ? (
            <>
              <Typography sx={{ color: "primary.light" }}>
                Quick search:
              </Typography>
              {searchTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  color="primary"
                  onClick={() => {
                    setSearch(tag);
                    handleSearch(tag);
                  }}
                />
              ))}
              <Chip
                label="Clear"
                color="primary"
                onClick={() => {
                  setSearch("");
                  handleSearch();
                }}
                onDelete={() => {
                  setSearch("");
                  handleSearch();
                }}
              />
            </>
          ) : null}
        </Stack>
        <Box sx={{ height: "80%", overflowY: "auto" }}>
          <List sx={{ width: "100%" }}>
            {filteredItems.length ? (
              filteredItems.map((item) => {
                const isSelected = selected && selected.id === item.id;
                return (
                  <ListItemButton
                    key={item.id}
                    divider
                    selected={isSelected}
                    onClick={() => setSelected(item)}
                    sx={{
                      display: "block",
                      pt: 2,
                      pb: 2,
                      borderColor: isSelected
                        ? "primary.main"
                        : "text.secondary",
                      borderWidth: isSelected ? "2px" : "1px",
                    }}
                  >
                    <Box className="flex justify-between">
                      <Typography
                        variant="h6"
                        sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
                      >
                        {item.header1}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="font-semibold"
                        sx={{ fontSize: { xs: "0.75rem", md: "1rem" } }}
                      >
                        {item.header2}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className="font-semibold text-[#9B9B9B]"
                      sx={{ mt: 1 }}
                    >
                      {item.subheader}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className="font-semibold"
                      sx={{ fontSize: { xs: "0.75rem", md: "1rem" }, mt: 2 }}
                    >
                      {item.text}
                    </Typography>
                  </ListItemButton>
                );
              })
            ) : (
              <Typography sx={{ mt: 2 }}>No items found</Typography>
            )}
          </List>
        </Box>
        <Box className="flex justify-center gap-5 mt-10">
          <Button onClick={handleClose} variant="outlined">
            {props.no || "Cancel"}
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            disabled={selected === null}
          >
            {props.yes || "Save"}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ListSelectionDialog;
