import React from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import AddIcon from "@mui/icons-material/Add";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecialOutlined";

// This modal displays buttons for Managing Links and Adding New Links
const ManageLinks = ({
  kpiLinksData,
  onManageLinksClick,
  onAddLinkButtonClick,
  _id,
}) => {
  return (
    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
      {kpiLinksData && kpiLinksData?.kpiLinks?.length > 0 && (
        <SoftBox mb="10px">
          <SoftTypography
            component="p"
            mb="10px"
            sx={{
              color: "#000",
              fontSize: "15px",
              textAlign: "left",
            }}
          >
            Links
          </SoftTypography>
          <SoftBox className="link-box">
            <SoftBox className="link-box-child">
              <a
                href={kpiLinksData?.kpiLinks[0].link}
                target="_blank"
                rel="noreferrer"
              >
                {kpiLinksData?.kpiLinks[0].text}
              </a>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      )}

      <SoftButton
        variant="outlined"
        sx={{
          width: "100%",
          color: "#E9ECEF",
          borderRadius: "5px",
          border: "1px solid #E9ECEF",
          background: "rgba(255, 255, 255, 0.07)",
          marginBottom: "0.5rem",
          fontSize: "15px",
          boxShadow: "0px 2.46305px 3.69458px 0px rgba(0, 0, 0, 0.05)",
          textTransform: "none",
          fontWeight: "normal",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          "&:hover": {
            border: "1px solid #E9ECEF",
          },
        }}
        onClick={() =>
          onManageLinksClick({ kpiId: _id, kpiLinks: kpiLinksData?.kpiLinks })
        }
      >
        <FolderSpecialIcon sx={{ marginRight: "0.25rem", fill: "#E9ECEF" }} />{" "}
        Manage links
      </SoftButton>

      <SoftButton
        variant="outlined"
        sx={{
          width: "100%",
          color: "#E9ECEF",
          borderRadius: "5px",
          border: "1px solid #E9ECEF",
          background: "rgba(255, 255, 255, 0.07)",
          fontSize: "15px",
          boxShadow: "0px 2.46305px 3.69458px 0px rgba(0, 0, 0, 0.05)",
          textTransform: "none",
          fontWeight: "normal",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          "&:hover": {
            border: "1px solid #E9ECEF",
          },
        }}
        onClick={() =>
          onAddLinkButtonClick({
            kpiId: _id,
            kpiLinks: kpiLinksData?.kpiLinks,
          })
        }
      >
        <AddIcon sx={{ marginRight: "0.25rem", fill: "#E9ECEF" }} /> Add Link
      </SoftButton>
    </SoftBox>
  );
};

export default ManageLinks;
