import React, { useState } from "react";
import { Dialog, Grid } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import ActivateModal from "./components/SetupModal/ActivateModal";
import RankModal from "./components/SetupModal/Rank";
import LevelModal from "./components/SetupModal/Level";
import TopKPIModal from "./components/SetupModal/TopKPI";
import PersonalGoalsModal from "./components/SetupModal/PersonalGoals";
import AdminBulletin from "./components/SetupModal/AdminBulletin";
import CompanyValues from "./components/SetupModal/CompanyValues";
import PlayerMemo from "./components/SetupModal/PlayerMemo";
import QuoteOfTheDay from "./components/SetupModal/QuoteOfTheDay";
import QuoteOfTheDayEdit from "./components/EditModal/QuoteOfTheDayEdit";
import PersonalGoalEdit from "./components/EditModal/PersonalGoalEdit";
import CompanyValuesEdit from "./components/EditModal/CompanyValuesEdit";
import AdminBulletinEdit from "./components/EditModal/AdminBulletinEdit";
import EditModal from "./components/EditModal/EditModal";
import { refetchUserData } from "../../redux/auth/actions";
import {
  UseCompanyId,
  UseIsAdmin,
  UseUserDetails,
  UseUserId,
} from "../../hooks/auth";
import {
  DELETE_WIDGET_FROM_LIST,
  TOGGLE_OFF_WIDGET,
  TOGGLE_ON_WIDGET,
} from "../../api/userWidgets";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { textAlign } from "@mui/system";

export const Components = {
  ranking: RankModal,
  level: LevelModal,
  top_kpi: TopKPIModal,
  personal_goals: PersonalGoalsModal,
  admin_bulletin: AdminBulletin,
  company_values: CompanyValues,
  player_memo: PlayerMemo,
  quote_of_the_day: QuoteOfTheDay,
};

export const EditComponents = {
  personal_goals: PersonalGoalEdit,
  admin_bulletin: AdminBulletinEdit,
  company_values: CompanyValuesEdit,
  player_memo: PlayerMemo,
  quote_of_the_day: QuoteOfTheDayEdit,
};

const widgetsByAdmin = {
  admin_bulletin: "admin_bulletin",
  company_values: "company_values",
  player_memo: "player_memo",
  quote_of_the_day: "quote_of_the_day",
};

const WidgetBox = ({ widget, setReFetch, widgetTitle, widgetMetrics }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(
    widget.toggled ? "Deactivate" : "Activate",
  );

  const open = Boolean(anchorEl);
  const Component = Components[widget.key];
  const EditComponent = EditComponents[widget.key];

  const userDetails = UseUserDetails();
  const userId = UseUserId();
  const isAdmin = UseIsAdmin();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const openEditModal = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);

  const triggerHandler = async () => {
    // Toggled means - Widget already activated
    if (widget.toggled || buttonLabel !== "Activate") {
      // Remove widget
      const widgetToRemove = userDetails.widgets.find(
        (item) => item.key === widget.key,
      );

      try {
        if (widgetsByAdmin[widget.key] && !isAdmin && !userDetails.isAdmin) {
          await TOGGLE_OFF_WIDGET(widgetToRemove._id, userId);
        } else {
          await DELETE_WIDGET_FROM_LIST(widgetToRemove._id, userId);
        }

        dispatch(refetchUserData(userId));
        setTimeout(() => {
          setButtonLabel("Activate");
          toast.success("Widget deactivated successfully");
          setReFetch();
        }, 1000);
      } catch (error) {
        console.log("Error while deactivating the widget", error);
      }
    } else {
      // Alow user to activate the widget

      // Check the widget key, and see if it was setup by the company admin
      if (widgetsByAdmin[widget.key] && !isAdmin && !userDetails.isAdmin) {
        try {
          const payload = {
            userId,
            companyId,
            key: widget.key,
          };

          // TOGGLE_ON_WIDGET will see if the widget is activated, if yes than the widget will be added to the users list of widgets
          await TOGGLE_ON_WIDGET(payload);
          dispatch(refetchUserData(userId));
          setTimeout(() => {
            setButtonLabel("Deactivate");
            toast.success("Widget activated successfully");
            setReFetch();
          }, 1000);
        } catch (error) {
          console.log("Error while calling TOGGLE_ON_WIDGET", error.response);
          toast.error(
            error.response?.data?.error?.message || "Something went wrong!",
          );
        }
        return;
      }
      openModal(true);
    }
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={closeModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <ActivateModal closeModal={closeModal}>
          <Component
            widget={widget}
            closeModal={closeModal}
            setButtonLabel={setButtonLabel}
            setReFetch={setReFetch}
          />
        </ActivateModal>
      </Dialog>
      <Dialog
        open={editModal}
        onClose={closeEditModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <EditModal closeModal={closeEditModal}>
          <EditComponent
            widget={widget}
            closeModal={closeEditModal}
            setButtonLabel={setButtonLabel}
          />
        </EditModal>
      </Dialog>
        <SoftBox
          sx={{
            borderRadius: "16px",
            background: "#F3F7FA",
            marginBottom: "20px !important",
            backdropFilter: "blur(15px)",
            backgroundBlendMode: "overlay, normal",
            border: "1px solid #EAECF0"
          }}
        >
        <SoftBox container style={{padding: "14px"}}>
          <SoftBox
            sx={{
              borderRadius: "16px",
              backdropFilter: "blur(15px)",
              backgroundBlendMode: "overlay, normal",
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0.39%, rgba(255, 255, 255, 0) 100.39%), radial-gradient(77.42% 100% at 50% 0.39%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.09) 17.75%), linear-gradient(0deg, #FFFFFF, #FFFFFF);`,
              padding: 1,
              minHeight: "78px",
            }}
          >
            <SoftBox sx={{ paddingLeft: "10px" }}>
              <SoftTypography variant="body2">{widget.title}</SoftTypography>
              <SoftTypography variant="h4">{widgetMetrics}</SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          sx={{
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            backdropFilter: "blur(15px)",
            backgroundBlendMode: "overlay, normal",
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0.39%, rgba(255, 255, 255, 0) 100.39%), radial-gradient(77.42% 100% at 50% 0.39%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.09) 17.75%), linear-gradient(0deg, #FFFFFF, #FFFFFF);`,
          }}
        >
          <Grid container style={{padding: "14px"}}>
            <Grid 
                item mini={3} 
                display="flex"
                alignItems="center">
              <svg width="64" height="64" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_118_5018)">
                <rect x="20" width="48" height="48" rx="4" fill="url(#paint0_linear_118_5018)"/>
                <path d="M54.36 23.0001H48.9733C46.2933 23.0001 45 21.6934 45 19.0267V13.6401C45 10.9601 46.3067 9.66675 48.9733 9.66675H54.36C57.04 9.66675 58.3333 10.9734 58.3333 13.6401V19.0267C58.3333 21.6934 57.0267 23.0001 54.36 23.0001ZM48.9733 11.6667C47.4 11.6667 47 12.0667 47 13.6401V19.0267C47 20.6001 47.4 21.0001 48.9733 21.0001H54.36C55.9333 21.0001 56.3333 20.6001 56.3333 19.0267V13.6401C56.3333 12.0667 55.9333 11.6667 54.36 11.6667H48.9733V11.6667Z" fill="#F9FAFB"/>
                <path d="M39.0265 23.0001H33.6398C30.9598 23.0001 29.6665 21.8134 29.6665 19.3601V13.3067C29.6665 10.8534 30.9732 9.66675 33.6398 9.66675H39.0265C41.7065 9.66675 42.9998 10.8534 42.9998 13.3067V19.3467C42.9998 21.8134 41.6932 23.0001 39.0265 23.0001ZM33.6398 11.6667C31.8532 11.6667 31.6665 12.1734 31.6665 13.3067V19.3467C31.6665 20.4934 31.8532 20.9867 33.6398 20.9867H39.0265C40.8132 20.9867 40.9998 20.4801 40.9998 19.3467V13.3067C40.9998 12.1601 40.8132 11.6667 39.0265 11.6667H33.6398Z" fill="#F9FAFB"/>
                <path d="M39.0265 38.3333H33.6398C30.9598 38.3333 29.6665 37.0267 29.6665 34.36V28.9733C29.6665 26.2933 30.9732 25 33.6398 25H39.0265C41.7065 25 42.9998 26.3067 42.9998 28.9733V34.36C42.9998 37.0267 41.6932 38.3333 39.0265 38.3333ZM33.6398 27C32.0665 27 31.6665 27.4 31.6665 28.9733V34.36C31.6665 35.9333 32.0665 36.3333 33.6398 36.3333H39.0265C40.5998 36.3333 40.9998 35.9333 40.9998 34.36V28.9733C40.9998 27.4 40.5998 27 39.0265 27H33.6398Z" fill="#F9FAFB"/>
                <path d="M56 29.6667H48C47.4533 29.6667 47 29.2134 47 28.6667C47 28.1201 47.4533 27.6667 48 27.6667H56C56.5467 27.6667 57 28.1201 57 28.6667C57 29.2134 56.5467 29.6667 56 29.6667Z" fill="#F9FAFB"/>
                <path d="M56 35H48C47.4533 35 47 34.5467 47 34C47 33.4533 47.4533 33 48 33H56C56.5467 33 57 33.4533 57 34C57 34.5467 56.5467 35 56 35Z" fill="#F9FAFB"/>
                </g>
                <defs>
                <filter id="filter0_dd_118_5018" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_118_5018"/>
                <feOffset dy="8"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_118_5018"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_118_5018"/>
                <feOffset dy="20"/>
                <feGaussianBlur stdDeviation="12"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_118_5018" result="effect2_dropShadow_118_5018"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_118_5018" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_118_5018" x1="58.9767" y1="190.327" x2="8.13788" y2="187.104" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7928CA"/>
                <stop offset="1" stopColor="#FF0080"/>
                </linearGradient>
                </defs>
              </svg>
            </Grid>
            <Grid item mini={9}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                  >
                    {widgetTitle}
                  </SoftTypography>
                  <SoftTypography sx={{ fontSize: "14px", color: "#6C757D" }}>
                    {widget?.description}
                  </SoftTypography>
                </SoftBox>
                {widget.editable && buttonLabel !== "Activate" && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={openEditModal}>
                    <SettingsIcon fontSize="small" mr={2} />
                    Edit Widget
                  </MenuItem>
                </Menu>
              </SoftBox>
            </Grid>
          </Grid>
          <SoftBox
            display="flex"
            justifyContent="center"
            pb={2}
            alignItems="center"
          >
            <SoftButton
              color="primary"
              size="small"
              sx={{
                textAlign: 'center',
                background: "#FFFFFF",
                border: "1px solid #EAECF0;",
                borderRadius: "4px",
                textTransform: "capitalize",
                color: "#1D2939",
              }}
              onClick={triggerHandler}
            >
              {buttonLabel}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default WidgetBox;
