import * as Yup from "yup";

const FreeTrialSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Enter a valid e-mail address"),
  businessName: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  industry: Yup.string().required("Industry is required"),
  zipcode: Yup.string().required("Postal code is required"),
  companySize: Yup.string().required("Company size is required"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions",
  ),
});

export default FreeTrialSchema;
