import React, { useState, useEffect } from "react";
import { Dialog, IconButton, useTheme, Divider, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CopyToClipboard } from "react-copy-to-clipboard";

import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import { toast } from "react-hot-toast";

// This modal displays the list of all KPI links
// It has buttons for COPY and DELETE per KPI link
const ViewAllLinksModal = ({
  isOpen,
  kpiLinksData,
  onModalClose,
  triggerAddLink,
}) => {
  const theme = useTheme();
  const [links, setLinks] = useState([]);

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  useEffect(() => {
    if (kpiLinksData && kpiLinksData?.kpiLinks?.length) {
      setLinks(kpiLinksData?.kpiLinks)
    }
  }, [kpiLinksData]);

  const [quickLink, setQuickLink] = useState("");

  const handleClose = async () => {
    const kpiLinksLength = kpiLinksData?.kpiLinks?.length ?? 0;

    if (
      kpiLinksData &&
      kpiLinksLength !== links.length
    ) {
      const payload = {
        links,
      };
      triggerAddLink(kpiLinksData.kpiId, payload);
    }

    onModalClose();
  };

  const handleDelete = (index) => {
    const copy = [...links];
    copy.splice(index, 1);
    setLinks([...copy]);
  };

  const handleAdd = () => {
    if (quickLink !== "") {
      const payload = {
        text: quickLink,
        link: quickLink,
        type: "web-address",
      };

      if (quickLink.includes("@") && quickLink.includes(".com")) {
        payload.type = "e-mail";
        payload.link = `mailto:${quickLink}`;
      } else if (!quickLink.includes("https://www.")) {
        payload.link = `https://${quickLink}`;
      }

      setLinks([payload, ...links]);
      setQuickLink("");
    }
  };

  return (
    <Dialog
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={isOpen}
      onClose={handleClose}
      sx={{
        zIndex: "99999999999999999",
        margin: "auto",
        width: { xs: "100vw", sm: "60vw" },
        ".MuiPaper-root": {
          borderRadius: "10px",
          width: "100%",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-15.85294px -15.85294px 26.42157px 0px rgba(0, 0, 0, 0.20) inset, 15.85294px 15.85294px 31.70588px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(20px)",
          padding: "3rem 2rem",
        },
      }}
    >
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <SoftTypography component="h4" sx={{ color: "#CED4DA" }}>
          Manage My Links
        </SoftTypography>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: "#CED4DA" }} />
        </IconButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
          "&:not([size])": {
            height: "2px",
          },
        }}
      />
      <SoftBox
        sx={{
          border: "1px solid #E9ECEF",
          borderRadius: "8px",
          padding: "20px",
          marginBottom: "20px",
          background: "rgba(255, 255, 255, 0.10)",
        }}
      >
        <SoftTypography
          component="p"
          mb="5px"
          sx={{
            color: "#CED4DA",
            fontSize: "15px",
          }}
        >
          Added Link
        </SoftTypography>
        {links?.map((link, index) => (
          <SoftBox display="flex" mb="5px" key={link.text}>
            <Link
              href={link.link}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              sx={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                width: "100%",
                padding: "5px 10px",
                marginRight: "5px",
                color: "#ced4da",
                "&:hover": {
                  color: primaryColor,
                },
              }}
            >
              {link.text}
            </Link>
            <CopyToClipboard
              text={link.link}
              onCopy={() => {
                toast.success("Link copied to clipboard!");
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  padding: "5px",
                  fontSize: "10px",
                  marginRight: "5px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  color: "white !important",
                }}
              >
                <ContentCopyIcon />
                Copy
              </SoftBox>
            </CopyToClipboard>

            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                padding: "5px",
                fontSize: "10px",
                "&:hover": {
                  cursor: "pointer",
                },
                color: "white !important",
              }}
              onClick={() => handleDelete(index)}
            >
              <DeleteOutlineIcon />
              Delete
            </SoftBox>
          </SoftBox>
        ))}
      </SoftBox>
      <SoftBox
        sx={{
          border: "1px solid #E9ECEF",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <SoftTypography
          component="p"
          mb="5px"
          sx={{
            color: "#CED4DA",
            fontSize: "15px",
          }}
        >
          Add a Link to this KPI Metric
        </SoftTypography>
        <SoftInput
          type="text"
          value={quickLink}
          onChange={(e) => setQuickLink(e.target.value)}
          sx={{
            backgroundColor: "transparent !important",
            color: "#ffffffb3 !important",
            "& .MuiInputBase-input::placeholder": {
              color: "white !important",
              opacity: "0.7",
            },
            "&.Mui-focused": {
              borderColor: primaryColor,
              boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
            },
          }}
        />
        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "2px",
            },
          }}
        />
        <SoftBox display="flex" justifyContent="end" alignItems="center">
          <SoftButton
            variant="gradient"
            sx={{ textTransform: "none" }}
            color="primary"
            onClick={handleAdd}
          >
            Add
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <Divider
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
          "&:not([size])": {
            height: "2px",
          },
        }}
      />
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="end"
        marginTop="1rem"
      >
        <SoftButton
          variant="outlined"
          sx={{
            textTransform: "none",
            color: "#6C757D",
            "&:hover": {
              textDecoration: "underline",
              color: "#6C757D",
            },
            width: "200px",
          }}
          onClick={() => handleClose()}
        >
          Close
        </SoftButton>
      </SoftBox>
    </Dialog>
  );
};

export default ViewAllLinksModal;
