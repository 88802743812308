import React, { useState, useEffect } from "react";
import SoftBox from "../../components/SoftBox";
import ReferralProgram from "../../components/Affiliate/ReferralProgram";
import Promotional from "../../components/Affiliate/Promotional";
import { Divider } from "@mui/material";
import ReferralTable from "../../components/Affiliate/ReferralTable";
import { GET_AFFILIATE_DATA } from "../../api/affiliate";

const Affiliate = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    GET_AFFILIATE_DATA().then(({ data: affiliateData }) => {
      setData(affiliateData);
    });

  }, []);

  return (
    <>
      <SoftBox
        sx={{
          height: { mini: "72px", xs: "58px", md: "54px" },
        }}
      />

      {/* Page Content */}
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <SoftBox
          bgColor="white"
          sx={{
            boxShadow: 2,
            padding: "1.25rem",
            borderRadius: "1rem",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <ReferralProgram
            code={data?.code}
            challengeUrl={data?.arena?.[0]?.url}
            referralCount={data?.referrals?.length}
          />
          <Divider />
          <Promotional />
        </SoftBox>
        <SoftBox
          bgColor="white"
          sx={{
            boxShadow: 2,
            borderRadius: "1rem",
            width: "100%",
          }}
        >
          <ReferralTable data={data?.referrals} />
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default Affiliate;
