import React from "react";
import SoftBox from "../SoftBox";
import iconsDictionary from "./iconsDictionary";

const Icon = ({
  iconKey,
  iconColor = "#fff",
  size = 5,
  round = false,
  transparent = false,
  bgColor,
  boxShadow = 0,
  ...props
}) => {
  const key = iconKey?.toLowerCase();
  const xsSize = size * 0.75;
  const boxSize = { xs: `${xsSize}rem`, sm: `${size}rem` };
  const iconSize = { xs: `${xsSize * 0.75}rem`, sm: `${size * 0.75}rem` };
  const IconSrc = iconsDictionary[key]
    ? iconsDictionary[key]
    : iconsDictionary.default;

  const getBgColor = () => {
    if (transparent && !bgColor) {
      return "transparent";
    } else if (!transparent && bgColor) {
      return bgColor;
    } else if (!transparent && !bgColor) {
      return "primary.main";
    } else {
      return "primary.main";
    }
  };
  return (
    <SoftBox
      sx={{
        boxShadow,
        bgcolor: getBgColor,
        width: boxSize,
        height: boxSize,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: round ? "50%" : "20%",
        ...props.sx,
      }}
    >
      <IconSrc sx={{ width: iconSize, height: iconSize, color: iconColor }} />
    </SoftBox>
  );
};

export default Icon;
