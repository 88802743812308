/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

// Soft UI Dashboard PRO React components
import SoftTypography from "../SoftTypography/index";
import SoftBox from "../SoftBox/index";

// custom styles for the DefaultItem
import { defaultItemIconBox, defaultItemIcon } from "./EventItemstyles";

const DefaultItem = forwardRef(
  ({ color, title, description, ...rest }, ref) => (
    <SoftBox {...rest} ref={ref} display="flex" alignItems="center">
      <SoftBox sx={(theme) => defaultItemIconBox(theme, { color })}>
        <Icon
          fontSize="default"
          sx={(theme) => defaultItemIcon(theme, { color })}
        >
          <AutoAwesomeIcon color={color} size="12" />
        </Icon>
      </SoftBox>
      <SoftBox ml={2} lineHeight={1}>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {title}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          {description}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  ),
);

// Setting default values for the props of DefaultItem
DefaultItem.defaultProps = {
  color: "info",
};

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DefaultItem;
