import React, { useEffect, useRef, useState } from "react";

import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftProfile from "../../../components/ProfilePictures/SoftProfile";

import AccountButton from "./components/AccountButton";

import { GET_IMAGE } from "../../../helpers/images";

const ProfilePicture = ({
  fileSelectedCallback,
  disabled = false,
  userDetails
}) => {
  const [imageURL, setImageURL] = useState(null);
  const fileRef = useRef();

  useEffect(() => {
    if (userDetails?.image) {
      const imgURL = GET_IMAGE(userDetails?.image);
      setImageURL(imgURL);
    }
  }, [userDetails]);

  useEffect(() => {
    // Cleanup
    return () => {
      URL.revokeObjectURL(imageURL);
    }
  }, [imageURL]);

  const setSelectedFile = async (e) => {
    const pictureBlob = URL.createObjectURL(e.target.files[0]);

    setImageURL(pictureBlob);
    fileSelectedCallback?.(e.target.files[0]);
  }

  const clearFile = (e) => {
    e.preventDefault();

    URL.revokeObjectURL(imageURL);
    setImageURL(null);
    fileRef.current.value = null;

    fileSelectedCallback?.(null, true);
  }

  return (
    <SoftBox>
      <SoftBox sx={{
        marginTop: "40px",
        display: "flex",
        flexDirection: {
          mini: "column",
          sm: "row"
        },
        alignItems: {
          mini: "left",
          sm: "center"
        },
        gap: {
          mini: "24px",
          sm: "72px",
        }
      }}>
        {/** Profile Picture section */}
        <SoftBox
          sx={{
            width: "300px",
            display: "flex",
            alignItems: "center",
            gap: "24px"
          }}>
          <SoftProfile
            src={imageURL}
            name={userDetails?.firstName}
            size="xl"
            fullCover
            sx={{
              height: "100px",
              width: "100px"
            }}
          />

          <SoftBox>
            <SoftTypography variant="h3">
              Profile Picture
            </SoftTypography>
            <SoftTypography variant="p"
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#98A2B3"
              }}
            >
              PNG, JPEG Under 10mb
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        {/** Profile Picture buttons */}
        <SoftBox sx={{ display: "flex", gap: "8px" }}>
          <AccountButton
            variant="outline"
            disabled={disabled}
            onClick={() => fileRef.current.click()}
          >
            Change picture
          </AccountButton>

          <input
            type="file"
            hidden
            ref={fileRef}
            accept="image/png, image/jpeg"
            name="fileforupload"
            onChange={setSelectedFile}
          />

          <AccountButton
            variant="outline"
            disabled={disabled}
            onClick={clearFile}
          >
            Delete
          </AccountButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default ProfilePicture;
