/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./levelUpStyles.css";

import Add from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { v4 as uuidv4 } from "uuid";
import { Save } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import NoReportsImage from "../../img/no-reports.png";
import AddBadgePopup from "./AddBadgePopup";
import EditBadgeLevel from "./EditBadgeLevel";
import CongratsPopup from "./CongratsPopup";
import BadgeRequirementPreview from "./BadgeRequirementPreview";
import Levels from "../../components/LevelUp/Levels";
import { GET_IMAGE } from "../../helpers/images";
import SoftAvatar from "../../components/SoftAvatar";
import { CREATE_LEVEL, GET_LEVEL } from "../../api/level-up";
import { GET_USER_BADGE } from "../../api/userBadge";
import { getMasterBadges } from "../../redux/badges/actions";
import { UseUserDetails, UseIsAdmin } from "../../hooks/auth";
import LoadingScreen from "../../components/LoadingScreen";
import ConfettiFireworks from "../../components/ParticleEffects/ConfettiFireworks";
import { dataLevels } from "./data";

const ColumnAdder = ({ adder }) => {
  return (
    <div
      className="border-2 bg-transparent rounded-md p-4 w-64 h-104 flex flex-col items-center justify-center hover:cursor-pointer hover:bg-gray-300 transition hover:duration-300 dashed-container ml-3 mr-3"
      style={{ width: "380px", height: "600px" }}
      onClick={() => adder()}
    >
      <div className="relative">
        <img
          src={NoReportsImage}
          alt="no-reports"
          className="object-cover w-full h-full"
        />
        <div
          className="absolute bottom-0 left-0 right-0 ml-auto mr-auto bg-blue-500 rounded-full w-12 h-12 flex items-center justify-center mb-2"
          style={{ bottom: "10px !important" }}
        >
          <Add className="text-white" fontSize="medium" />
        </div>
      </div>
      <div className="mt-5 flex flex-col justify-center">
        <SoftTypography variant="h5" className="text-center text-black">
          No Data for this Level Yet!
        </SoftTypography>
        <SoftTypography variant="p" className="text-center">
          Activate this column as your FIRST Level and add badges here
        </SoftTypography>
      </div>
    </div>
  );
};

const LevelUp = () => {
  const [popUp, setPopUp] = useState({ open: false, index: 0, badgesCopy: [] });
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [openPreview, setOpenPreview] = useState({
    open: false,
    id: null,
    status: 0,
    template: null,
    add: null,
    onAdd: null,
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [levelsData, setLevelsData] = useState(dataLevels);
  const [editPopUp, setEditPopUp] = useState({ open: false, index: 0 });
  const [board, setBoard] = useState({
    columns: [],
  });
  const dispatch = useDispatch();
  const user = UseUserDetails();
  const isAdmin = UseIsAdmin();
  const { state } = useLocation();

  const { isLoading, masterBadges } = useSelector(
    (state) => state.masterBadgeReducer,
  );

  useEffect(() => {
    dispatch(getMasterBadges());
  }, []);

  const handleColumnAdded = (newBoard, newColumn) => {
    setBoard(newBoard);
  };

  const adder = () => {
    if (board.columns.length < 10) {
      setBoard({
        columns: [
          ...board.columns,
          {
            id: uuidv4(),
            title: `Level ${board.columns.length + 1}`,
            levelName: dataLevels[board.columns.length].lvlName,
            levelInfo: dataLevels[board.columns.length].lvlInfo,
            cards: [],
          },
        ],
      });
    } else {
      toast.error("Cannot add more than 10 levels");
    }
  };

  const handleCardMove = (_card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
  };

  const triggerSave = async () => {
    setSaving(true);
    try {
      if (board.columns.length) {
        const result = await Promise.all(
          board.columns.map((item) => {
            const badges = item.cards.map((card) => ({ _id: card.id }));
            const data = {
              name: item.title,
              levelName: item.levelName,
              levelInfo: item.levelInfo,
              badges,
            };

            return CREATE_LEVEL(data);
          }),
        );

        if (result) {
          toast.success("Levels saved successfully");
        }
      } else {
        toast.error("Please add levels");
      }
    } catch (err) {
      console.log(err.response);
    }
    setSaving(false);
  };

  const fetchLevels = async () => {
    setFetching(true);
    try {
      const { data } = await GET_LEVEL(user.companyId, true);
      if (isAdmin || user.isAdmin) {
        const result = data.map((el) => {
          const cards = el.badges.map((badge) => ({
            id: badge._id,
            template: { ...badge },
          }));

          const item = {
            id: el._id,
            title: el.name,
            levelName: el.levelName,
            levelInfo: el.levelInfo,
            cards,
          };

          return item;
        });
        setBoard({ columns: result });
      } else {
        let result = data.map(async (el) => {
          let badges = [];
          for (const curr of el.badges) {
            let status = 0;
            const response = await GET_USER_BADGE({
              userId: user._id,
              badgeId: curr._id,
            });

            if (response.data) {
              status = response.data.status;
            }

            badges.push({
              template: { ...curr },
              id: curr._id,
              status,
            });
          }
          const item = {
            id: el._id,
            title: el.name,
            levelName: el.levelName,
            levelInfo: el.levelInfo,
            cards: badges,
          };

          return item;
        });
        result = await Promise.all(result);
        setBoard({ columns: result });
      }
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  return (
    <>
      {popUp.open && (
        <AddBadgePopup
          popUp={popUp}
          setPopUp={setPopUp}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          board={board}
          setBoard={setBoard}
          isLoading={isLoading}
          masterBadges={masterBadges}
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
        />
      )}
      {openPreview.open && (
        <BadgeRequirementPreview
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          board={board}
          setBoard={setBoard}
          isAdmin={isAdmin}
          setPopUp={setPopUp}
        />
      )}
      {editPopUp.open && (
        <EditBadgeLevel
          editPopUp={editPopUp}
          setEditPopUp={setEditPopUp}
          dataLevels={levelsData}
          setLevelsData={setLevelsData}
          board={board}
          setBoard={setBoard}
        />
      )}
      {state && state.showPopup && (
        <CongratsPopup item={state.item} showPopup={state.showPopup} />
      )}
      {state && state.showPopup && <ConfettiFireworks />}

      <SoftBox
        sx={{
          height: { mini: "101.25px", xs: "109.25px", md: "75px" },
        }}
      />
      <div className="p-4 mb-4 flex justify-between">
        <SoftBox>
          <SoftTypography variant="h3" className="text-black">
            Levels
          </SoftTypography>
          <SoftTypography variant="p" className="text-gray-400">
            Get the rewards you deserve each time you move up to one level.
          </SoftTypography>
        </SoftBox>
        {isAdmin ? (
          <SoftButton
            // size="small"
            variant="gradient"
            color="info"
            onClick={triggerSave}
            disabled={saving}
            loading={saving}
            sx={{
              background: "linear-gradient(135deg, #FF0080 0%, #7928CA 100%)",
            }}
          >
            <Save style={{ fontSize: "24px" }} />
          </SoftButton>
        ) : null}
      </div>
      <SoftBox
        position="relative"
        my={4}
        sx={({
          palette: { light },
          functions: { pxToRem },
          borders: { borderRadius },
        }) => ({
          "& .react-kanban-column": {
            backgroundColor: "#F3F3F3",
            width: pxToRem(450),
            margin: `0 ${pxToRem(10)}`,
            padding: pxToRem(20),
            borderRadius: borderRadius.lg,
          },
        })}
      >
        {fetching ? (
          <LoadingScreen />
        ) : (
          <Board
            allowAddCard
            allowAddColumn
            disableColumnDrag
            disableCardDrag={!isAdmin}
            onCardDragEnd={handleCardMove}
            renderColumnAdder={
              isAdmin ? () => <ColumnAdder adder={adder} /> : () => <div />
            }
            onColumnNew={handleColumnAdded}
            renderColumnHeader={({ id, title }) => {
              const index = board.columns.findIndex((item) => item.id === id);

              return (
                <SoftBox display="flex" flexDirection="column">
                  {isAdmin ? (
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={3}
                    >
                      <SoftTypography variant="h6">{title}</SoftTypography>
                      <SoftButton
                        size="small"
                        onClick={() => {
                          let badgesCopy = [...masterBadges];
                          badgesCopy = badgesCopy.filter(
                            (item) =>
                              !board.columns[index].cards.find(
                                (card) => card.id === item._id,
                              ),
                          );

                          setPopUp({
                            index,
                            open: true,
                            badgesCopy,
                          });
                        }}
                      >
                        <Add />
                      </SoftButton>
                    </SoftBox>
                  ) : (
                    <SoftBox mb={3}>
                      <SoftTypography variant="h6">{title}</SoftTypography>
                    </SoftBox>
                  )}

                  <div
                    style={{ width: "100%" }}
                    onClick={() => {
                      if (isAdmin || user.isAdmin) {
                        setEditPopUp({
                          open: true,
                          index,
                        });
                      }
                    }}
                  >
                    <Levels
                      index={index}
                      dataLevels={levelsData}
                      board={board}
                    />
                  </div>
                </SoftBox>
              );
            }}
            renderCard={({ id, template, status }, { dragging }) => (
              <SoftBox
                key={id}
                dragging={dragging.toString() || undefined}
                display="block"
                width="calc(450px - 40px)"
                bgColor={status < 1 ? "#D9D9D9" : "white"}
                color="text"
                borderRadius="md"
                mt={2.5}
                py={1.875}
                px={1.875}
                lineHeight={1.5}
                sx={{
                  fontSize: ({ typography: { size } }) => size.md,
                }}
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    setOpenPreview({
                      open: true,
                      id,
                      template,
                      status,
                      add: false,
                      onAdd: null,
                    })
                  }
                >
                  <div className="flex items-center">
                    <SoftAvatar
                      src={GET_IMAGE(template.image)}
                      className={status < 1 ? "grayscale-image" : ""}
                      alt={template.title}
                      size="xs"
                      sx={{
                        ml: -1,
                      }}
                    />
                    <div className="ml-2" />
                    <SoftTypography
                      component="p"
                      className="capitalize"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        margin: "0px !important",
                        padding: "0px !important",
                        color: "#172B4D",
                      }}
                    >
                      {template.title}
                    </SoftTypography>
                  </div>
                  {status >= 1 ? (
                    <CheckCircleIcon
                      sx={{
                        color: "#00FF0A !important",
                        fontSize: "24px !important",
                      }}
                    />
                  ) : null}
                </div>
              </SoftBox>
            )}
            onCardNew={() => null}
          >
            {board}
          </Board>
        )}
      </SoftBox>
    </>
  );
};

export default LevelUp;
