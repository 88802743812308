import React, { useState } from "react";
import { Modal } from "@material-ui/core";

import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import SoftInput from "../SoftInput";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
  border: "none",
};

const UpdateEventModal = ({ onClose, onDelete, onUpdate, clicked }) => {
  const [title, setTitle] = useState(clicked.item.title);

  return (
    <Modal
      open={clicked.popUp}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftTypography
          component="h6"
          className="text-black mb-3"
          sx={{ fontSize: "16px", fontWeight: "500" }}
        >
          Add New Event
        </SoftTypography>

        <SoftInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Event Title"
          sx={{ marginBottom: "20px" }}
        />

        <SoftButton
          onClick={onDelete}
          color="primary"
          sx={{ color: "primary.contrastText", marginRight: "10px" }}
          size="small"
        >
          Delete
        </SoftButton>
        <SoftButton
          onClick={() => {
            if (title) {
              onUpdate(title);
            }
          }}
          color="primary"
          sx={{ color: "primary.contrastText", marginRight: "10px" }}
          size="small"
        >
          Update
        </SoftButton>
        <SoftButton
          onClick={onClose}
          color="primary"
          sx={{ color: "primary.contrastText" }}
          size="small"
        >
          Cancel
        </SoftButton>
      </div>
    </Modal>
  );
};

export default UpdateEventModal;
