import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import Select from "react-select";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { createGroupChat } from "../../redux/chats/actions";
import SoftInput from "../SoftInput";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import SoftSelect from "../SoftSelect";
import { useTheme, Divider, Dialog } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const GroupChatModal = ({ openModal, setOpenModal, users }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const closeDialog = () => setOpenModal(false);

  const onChangeTrigger = async (e) => {
    setSearchKeyword(e.target.value);
  };

  const createGroupChatTrigger = async () => {
    setLoading(true);

    try {
      if (searchKeyword === "" || !selectedUsers.length) {
        toast.error("Please fill both fields");
      } else if (selectedUsers.length < 2) {
        toast.error("More than 2 users are required to form a group chat");
      } else {
        const userIds = selectedUsers.map((item) => item.value);
        const payload = {
          name: searchKeyword,
          users: userIds,
        };

        dispatch(createGroupChat(payload));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeDialog}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1.5rem 1.7rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "600px",
          borderWidth: "1px",
          borderColor: "#FFF",
          "::-webkit-scrollbar": {
            display: "none",
            height: 0,
            width: 0,
          },
        },
      }}
    >
      <SoftBox display="flex" justifyContent="space-between">
        <SoftBox>
          <SoftTypography variant="h4" color="white" sx={{ fontSize: "20px" }}>
            Create Group Chat
          </SoftTypography>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeDialog}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            width: "max-content",
            height: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <SoftBox>
        <SoftInput
          placeholder="Group name"
          value={searchKeyword}
          onChange={onChangeTrigger}
          required
          sx={{
            marginBottom: 3,
            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
            borderColor: "rgba(233, 236, 239, 0.10)",
            color: "rgba(206, 212, 218, 1) !important",
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(206, 212, 218, 1) !important",
              opacity: "0.7",
            },
            "&.Mui-focused": {
              borderColor: primaryColor,
              boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
            },
          }}
        />
        <SoftSelect
          isLoading={false}
          options={users}
          isMulti
          name="kpimodels"
          value={selectedUsers}
          onChange={(selectedOption) => {
            setSelectedUsers(selectedOption);
          }}
          placeholder="Select group member"
          darkTheme={true}
          styles={{
            control: {
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              height: "100%",
              minWidth: "2.5rem",
            },
            placeholder: {
              color: "rgba(206, 212, 218, 1) !important",
              opacity: "0.7",
            },
            input: {
              color: "rgba(206, 212, 218, 1) !important",
              opacity: "0.7",
            },
            singleValue: {
              color: "white !important",
              opacity: "0.7",
            },
            valueContainer: {
              flexFlow: "row wrap",
              gap: "0.25rem",
              padding: "0.5rem 0.75rem",
            },
            indicatorsContainer: {
              alignItems: "start",
            },
          }}
        />
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <SoftBox
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <SoftButton
          onClick={createGroupChatTrigger}
          color="primary"
          sx={{
            color: "primary.contrastText",
            textTransform: "none",
            borderRadius: "4px",
            width: "max-content",
          }}
          size="small"
          loading={loading}
          disabled={loading}
          variant="gradient"
        >
          Create
        </SoftButton>
      </SoftBox>
    </Dialog>
  );
};

export default GroupChatModal;
