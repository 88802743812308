import React, { useState, useEffect, useRef } from "react";

import { Popover } from "@mui/material";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";

const AllocationDialog = ({
  isOpen,
  id = "simple-popover",
  handleCancel,
  handleConfirm,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const linkRef = useRef();
  const open = Boolean(anchorEl);

  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1C2024"
  };

  const buttonStyles = {
    height: "32px",
    width: "71px",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontWeight: 500,
    padding: "0 12px",
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => linkRef.current.click(), 500);
    } else {
      setAnchorEl(null);
    }
  }, [isOpen]);

  const handleClick = (e) => {
    setAnchorEl(e.target);
  }

  const handleClose = (callOnClose = true) => {
    setAnchorEl(null);

    if (callOnClose) {
      handleCancel?.();
    }
  }

  return (
    <>
      <span ref={linkRef} onClick={handleClick}>
       &nbsp;
      </span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          backgroundColor: "rgba(9, 11, 14, 0.08)",
          "& .MuiPaper-root": {
            maxWidth: "400px",
            minHeight: "200px",
            width: "100%",
            border: "1px solid rgba(0, 0, 59, 0.051)",
            borderRadius: "4px",
            boxShadow: "0px 16px 36px -20px rgba(1, 6, 47, 0.173), 0px 16px 64px rgba(5, 5, 88, 0.024), 0px 12px 60px rgba(0, 0, 0, 0.055)",
            padding: "16px 14px",
            backgroundColor: "#FCFAFF",
            zIndex: 1
          }
        }}
      >
        {/** Title and text content */}
        <SoftBox>
          <SoftTypography
            variant="subtitle1"
            sx={{
              ...fontStyles,
              fontWeight: 700,
              fontSize: "16px",
              letterSpacing: "-0.08px",
              lineHeight: "28px",
              marginBottom: "16px"
            }}
          >
            Metrics point allocation
          </SoftTypography>

          <SoftTypography
            variant="subtitle1"
            sx={{
              ...fontStyles,
              fontWeight: 500,
            }}
          >
            Congratulations on adding a metric.
          </SoftTypography>

          <SoftTypography
            variant="body2"
            sx={{
              ...fontStyles,
              fontWeight: 400,
              fontSize: "12px"
            }}
          >
            Do you want to set up the metrics, and add a target for your team? Click the set up below
          </SoftTypography>
        </SoftBox>

        {/** Control buttons */}
        <SoftBox sx={{
          display: "flex",
          gap: "8px",
          float: "right",
          marginTop: "24px"
        }}>
          <SoftButton
            variant="outline"
            onClick={() => handleClose(true)}
            sx={{
              ...buttonStyles,
              ...fontStyles,
              width: "71px",
              background: "rgba(0, 0, 59, 0.051)",
              color: "#60646C",
            }}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="outline"
            onClick={handleConfirm}
            sx={{
              ...buttonStyles,
              ...fontStyles,
              width: "118px",
              background: "#7928CA",
              color: "#FFF",
              "&:hover": {
                background: "#7928CA"
              }
            }}
          >
            Setup metrics
          </SoftButton>
        </SoftBox>
      </Popover>
    </>
  );
};

export default AllocationDialog;
