import React, { createContext, useContext, useState, useMemo } from "react";

const AudioContext = createContext();

export function AudioProvider({ children }) {
  const [audios, setAudios] = useState({});

  /**
   * Creates an audio element
   * @param {string} key unique identifier of the audio element
   * @param {*} src audio file source
   * @returns
   */
  const createAudio = (key, src) => {
    const audio = new Audio(src);
    setAudios((prevAudios) => ({ ...prevAudios, [key]: audio }));
    return audio;
  };

  /**
   * Pauses an audio element at its current play time; play can be resumed later
   * @param {string} key unique identifier of the audio element
   * @returns true if audio has been paused
   */
  const pauseAudio = (key) => {
    if (audios[key]) {
      audios[key].pause();
      return true;
    }
    return false;
  };

  /**
   * Stops an audio element and resets play time
   * @param {string} key unique identifier of the audio element
   * @returns true if audio has been stopped
   */
  const stopAudio = (key) => {
    if (audios[key]) {
      audios[key].pause();
      audios[key].currentTime = 0;
      return true;
    }
    return false;
  };

  /**
   * Removes a specific audio element, referenced by its key
   * @param {string} key unique identifier of the audio element
   */
  const clearAudio = (key) => {
    if (audios[key]) {
      audios[key].pause();
      audios[key].currentTime = 0;
      const updatedAudios = { ...audios };
      delete updatedAudios[key];
      setAudios(updatedAudios);
    }
  };

  /**
   * Removes all registered audio elements
   */
  const clearAll = () => {
    Object.keys(audios).forEach((key) => {
      audios[key].pause();
      audios[key].currentTime = 0;
    });
    setAudios({});
  };

  /**
   * Plays an audio element based on key identifier
   * @param {string} key unique identifier of the audio element
   * @param {*} duration nullable, if it has value, audio would stop after given duration passes
   * @returns true if audio has been played
   */
  const playAudio = async (key, duration = null) => {
    if (audios[key]) {
      audios[key].play();
      if (duration) {
        setTimeout(() => stopAudio(key), duration);
      }
      return true;
    }
    return false;
  };

  /**
   * Plays an audio element based on key identifier, then deletes it
   * @param {string} key unique identifier of the audio element
   * @param {*} duration nullable, if it has value, audio would stop after given duration passes
   * @returns true if audio has been played
   */
  const playAudioOnce = async (key, duration = null) => {
    const audio = audios[key];
    if (audio) {
      audio.play();
      if (duration) {
        setTimeout(() => clearAudio(key), duration);
      }
      audio.ended(() => {
        clearAudio(key);
      });
      return true;
    }
    return false;
  };

  const audioContextValue = useMemo(
    () => ({
      createAudio,
      playAudio,
      playAudioOnce,
      pauseAudio,
      stopAudio,
      clearAudio,
      clearAll,
    }),
    [audios],
  );

  return (
    <AudioContext.Provider value={audioContextValue}>
      {children}
    </AudioContext.Provider>
  );
}

export function useAudio() {
  return useContext(AudioContext);
}
