import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.0867C7.55333 15.0867 7.1 14.9867 6.74667 14.78L3.08 12.6667C1.58667 11.66 1.49333 11.5067 1.49333 9.92667V6.07334C1.49333 4.49334 1.58 4.34001 3.04667 3.34667L6.74 1.21334C7.44 0.806674 8.54 0.806674 9.24 1.21334L12.92 3.33334C14.4133 4.34001 14.5067 4.49334 14.5067 6.07334V9.92001C14.5067 11.5 14.42 11.6533 12.9533 12.6467L9.26 14.78C8.9 14.9867 8.44667 15.0867 8 15.0867ZM8 1.91334C7.72 1.91334 7.44667 1.96667 7.25333 2.08001L3.58667 4.20001C2.5 4.93334 2.5 4.93334 2.5 6.07334V9.92001C2.5 11.06 2.5 11.06 3.61333 11.8133L7.25333 13.9133C7.64667 14.14 8.36 14.14 8.75333 13.9133L12.42 11.7933C13.5 11.06 13.5 11.06 13.5 9.92001V6.07334C13.5 4.93334 13.5 4.93334 12.3867 4.18001L8.74667 2.08001C8.55333 1.96667 8.28 1.91334 8 1.91334Z"
        fill="#F9FAFB"
      />
      <path
        d="M8 10.5C6.62 10.5 5.5 9.38 5.5 8C5.5 6.62 6.62 5.5 8 5.5C9.38 5.5 10.5 6.62 10.5 8C10.5 9.38 9.38 10.5 8 10.5ZM8 6.5C7.17333 6.5 6.5 7.17333 6.5 8C6.5 8.82667 7.17333 9.5 8 9.5C8.82667 9.5 9.5 8.82667 9.5 8C9.5 7.17333 8.82667 6.5 8 6.5Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default SettingsIcon;
