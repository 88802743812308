import { useState, useEffect } from 'react'

export default function useIsTopVisible (value = false, elementId) {
  const [isVisible, setIsVisible] = useState(value);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(elementId);
      const { top } = element.getBoundingClientRect();
      const visible = (top > 0);
      setIsVisible(visible);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementId]);

  return isVisible;
}
