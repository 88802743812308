import React, { useState } from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import SoftBox from "../SoftBox";

import { UseIsAdmin } from "../../hooks/auth";
import { toggleViewAsUser } from "../../redux/user/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PsychologyIcon from "@mui/icons-material/Psychology";

const AdminViewToggle = ({ light }) => {
  const isAdmin = UseIsAdmin();
  const [tabValue, setTabValue] = useState(isAdmin ? 1 : 0);
  const dispatch = useDispatch();

  const handleChange = (e, value) => {
    setTabValue(value);
    dispatch(toggleViewAsUser(!value));
  };

  const route = useLocation().pathname.split("/").slice(1);

  return (
    <SoftBox
      color={light ? "white" : "inherit"}
      mr={2}
      sx={{ display: route[route.length - 1] === "arena" ? "none" : "block" }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{ py: "2px", px: "4px" }}
      >
        <Tab
          icon={<SportsEsportsIcon />}
          label="Player"
          sx={{ fontSize: "0.75rem" }}
        />
        <Tab
          icon={<PsychologyIcon />}
          label="Admin"
          sx={{ fontSize: "0.75rem" }}
        />
      </Tabs>
    </SoftBox>
  );
};

export default AdminViewToggle;
