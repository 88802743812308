import { request } from "../helpers/axios.new";
import { useQuery } from "@tanstack/react-query";

export const GET_GOOGLE_KPI = (groupId) => {
  return request({
    url: `/integration/google/${groupId}`,
  });
};

export const SUBMIT_REQUEST = (body) => {
  return request({
    url: '/integration/submit-request',
    method: "PATCH",
    data: body,
  });
};

export const ADD_INTEGRATION = (data) => {
  return request({
    method: "POST",
    url: `/integration/`,
    data,
  });
};

export const GET_CONNECTION_URL = (code, data = null) => {
  const params = data ? new URLSearchParams(data).toString() : "";
  return request({
    method: "GET",
    url: `/integration/connect/${code}?${params}`,
    data,
  });
};

export const DISABLE_INTEGRATION = (data) => {
  return request({
    method: "PATCH",
    url: `/integration/`,
    data,
  });
};

export const FETCH_INTEGRATIONCONFIG = () =>
  useQuery(["fetchIntegrationConfig"], () =>
    request({ url: `/integration/configs` }),
  );

export const GET_COMPANY_INTEGRATIONS = () => {
  return request({
    method: "GET",
    url: `/integration/company`,
  });
};

export const GET_APP_INTEGRATIONS = () => {
  return request({
    method: "GET",
    url: `/integration/apps`,
  });
};

export const GET_KPI_INTEGRATIONS_BY_DATASET = () => {
  return request({
    method: "GET",
    url: `/kpi/integrations-set`,
  });
};

export const GET_CAMPAIGNS = (companyId) => {
  return request({
    method: "GET",
    url: `/integration/campaigns?companyId=${companyId}`,
  });
};

export const UPDATE_CAMPAIGN = (companyId, data) => {
  return request({
    method: "PATCH",
    url: `/integration/campaigns?companyId=${companyId}`,
    data,
  });
};
