import * as Yup from "yup";
import { KPI_IMAGE_PARAMS } from "../constants/kpi";

const KpiSettingsSchema = Yup.object().shape({
  pointScale: Yup.lazy((value) => {
    if (value) {
      return Yup.number()
        .typeError("Point scale must be a number")
        .positive("Point scale must be a positive number");
    }
    return Yup.mixed().nullable();
  }),
  requiredInputs: Yup.array()
    .of(Yup.object())
    .test(
      "single-image-only",
      "Only one image parameter is allowed.",
      (values) => {
        // Check count of image-type parameters
        const imageCount = values.reduce((count, { value }) => {
          const isImage = KPI_IMAGE_PARAMS.some((paramKey) =>
            value.toLowerCase().includes(paramKey),
          );
          return isImage ? count + 1 : count;
        }, 0);

        // Verify if it's 1 or none; If it's more than that, raise an error
        return imageCount <= 1;
      },
    )
    .test("no-duplicates", "No duplicate parameters allowed.", (rawValues) => {
      const values = rawValues.map(({ value }) => value.trim().toLowerCase());

      // Check for duplicates
      const duplicates = values.filter(
        (item, index) => values.indexOf(item) !== index,
      );

      // Verify if there are no duplicates
      return duplicates.length === 0;
    }),
});

export default KpiSettingsSchema;
