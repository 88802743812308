import React from "react";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from "@iconify/react";


const Favorite = ({ reacts }) => {

  const handleClick = () => {

  };

  const tip = reacts?.map(react => react.users[0].firstName + " " + react.users[0].lastName).join("\n");

  return (
    <Tooltip title={
      <div style={{ whiteSpace: "pre-line" }}>{tip}</div>
    }>
      <Chip
        onClick={handleClick}
        size="small"
        sx={{
          background: "white",
          "&:hover": {
            background: "white",
          }
        }}
        label={
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              icon="ant-design:heart-filled"
              className="!text-[20px] text-pink-500"
            />
            <Typography sx={{ fontWeight: "bold", color: "#1A73E8" }}>{reacts?.length}</Typography>
          </Stack>
        }
      />
    </Tooltip>
  );
};

export default Favorite;
