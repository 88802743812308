/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// prop-type is a library for typechecking of props
import { forwardRef } from "react";
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";

const FormField = forwardRef(({ label, ...rest }, ref) => (
  <>
    {label && (
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
    )}
    <SoftInput {...rest} inputRef={ref} />
  </>
));

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FormField;
