import React, { useEffect, useState } from "react";
import { UseCompanyId, UseUserId } from "../../hooks/auth";
import FeedItem from "./FeedItem";
import { useDispatch, useSelector } from "react-redux";
import { getJobs, resetJobs } from "../../redux/jobs/actions";
import LoadingScreen from "../../components/LoadingScreen";
import Stack from "@mui/material/Stack";
import { orderBy } from "lodash";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import FeedTabPanel from "./FeedTabPanel";
import HubNavBg from "../../img/hub-navbg.jpg";

// Imported Styles
import "./feedStyles.css";
import InfiniteScroll from "react-infinite-scroll-component";

// Imported Images and Icons
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Typography, Grid, Tabs, Tab, useMediaQuery } from "@mui/material";
import Icon from "../../components/Icons/Icon";
import PageHeader from "../../components/PageHeader";

const FeedPage = () => {
  const dispatch = useDispatch();
  const { isLoading, jobs, currentPage, hasMore } = useSelector(
    (state) => state.jobsReducer,
  );
  const userId = UseUserId();
  const [page, setPage] = useState(1);
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    dispatch(resetJobs());
    setPage(1);
    setChecked(event.target.checked);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // eslint-disable-next-line no-shadow
  const fetchJobs = (companyId, page, checked) => {
    dispatch(getJobs({ companyId, page, userId: checked ? userId : "" }));
  };

  const companyId = UseCompanyId();

  useEffect(() => {
    dispatch(resetJobs());
    setPage(1);
  }, [companyId, dispatch]);

  useEffect(
    () => {
      if (companyId) {
        fetchJobs(companyId, page, checked);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, dispatch, page, checked],
  );

  const jobsByRecentDate = orderBy(jobs, ["extRefDate"], "desc");

  const textBp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const NavTabs = [{ label: "All" }, { label: "My Jobs" }];

  return (
    <>
      <PageHeader
        headerText="Team Feed"
        subheaderText="The Feed is where you get to see the result of your players output!"
      />

      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <Grid container>
          <Grid item mini={12}>
            <SoftBox
              sx={{
                margin: "1.5rem 0rem 1rem",
                borderRadius: "16px",
                height: { mini: "max-content", md: "240px" },
                backgroundImage: `url("${HubNavBg}")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                padding: { mini: "1rem", md: "1.5rem" },
              }}
            >
              <Grid container spacing={2} height="100%" marginTop={0}>
                <Grid
                  item
                  md={9}
                  lg={6}
                  height="100%"
                  paddingTop="0 !important"
                >
                  <SoftBox height="100%" display="flex" flexDirection="column">
                    <SoftTypography
                      variant={textBp ? "h4" : "h5"}
                      sx={{
                        color: {
                          mini: "white !important",
                          lg: "black !important",
                        },
                      }}
                    >
                      Welcome to our Feed
                    </SoftTypography>
                    <SoftTypography
                      variant={textBp ? "button" : "caption"}
                      sx={{
                        color: {
                          mini: "white !important",
                          lg: "rgba(0, 0, 0, 0.6) !important",
                        },
                        width: "420px",
                        fontWeight: 400,
                      }}
                    >
                      Get the real-time updates from your teammates with this
                      private social media network built by Game My Biz for you.
                      Give slow claps, share pictures and more.
                    </SoftTypography>
                    <SoftBox
                      mt="0.5rem"
                      flexGrow={1}
                      alignItems="end"
                      sx={{ display: { mini: "none", md: "flex" } }}
                    >
                      <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={(event, newValue) => setTabValue(newValue)}
                        sx={{
                          background: "rgba(249, 249, 255, 0.80)",
                          borderRadius: "10px",
                          padding: "8px",
                          boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.12)",
                          width: "max-content",
                          "& .MuiTabs-indicator": {
                            borderRadius: "0.5rem",
                            boxShadow: "none",
                          },
                        }}
                      >
                        {NavTabs.map((nav, index) => (
                          <Tab
                            label={nav.label}
                            icon={nav.icon}
                            iconPosition="start"
                            sx={{
                              color: "#343A40",
                              fontSize: "0.85rem",
                            }}
                          />
                        ))}
                      </Tabs>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>

          <Grid item mini={12}>
            <SoftBox
              sx={{
                padding: "1rem",
              }}
            >
              <SoftBox className="flex items-center gap-x-[1rem] pb-[1rem]">
                <SoftBox
                  sx={{
                    border: "1px dashed rgba(0, 0, 0, 0.20)",
                    borderRadius: "16px",
                    background: "rgba(0, 0, 0, 0.02)",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "240px",
                    height: "auto",
                  }}
                >
                  <SoftButton
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "block",
                      borderRadius: "1rem",
                      padding: "24px",
                    }}
                    disabled
                  >
                    <SoftTypography
                      variant="caption"
                      color="black"
                      sx={{
                        textTransform: "normal",
                        display: "block",
                      }}
                    >
                      Add Story
                    </SoftTypography>
                    <SoftTypography
                      variant="button"
                      color="black"
                      sx={{
                        textTransform: "normal",
                      }}
                    >
                      Coming Soon
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "max-content",
                        height: "max-content",
                        borderRadius: "50%",
                        margin: "auto",
                        "& svg": {
                          fontSize: "2rem !important",
                          color: "primary.main",
                        },
                      }}
                    >
                      <AddCircleRoundedIcon />
                    </SoftBox>
                  </SoftButton>
                </SoftBox>
              </SoftBox>

              <hr className="w-full h-[2px] rounded-md bg-gray my-[0.75rem] mx-auto" />
            </SoftBox>
          </Grid>

          <Grid item mini={12}>
            <FeedTabPanel value={tabValue} index={0}>
              {(!isLoading || hasMore) && !!jobsByRecentDate?.length && (
                <InfiniteScroll
                  style={{ overflow: "hidden", padding: 4 }}
                  dataLength={jobs.length} // This is important field to render the next data
                  next={() => {
                    setPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader=""
                >
                  <Stack className="flex items-center">
                    {jobsByRecentDate.map((job) => (
                      <FeedItem key={job.id} job={job} />
                    ))}
                  </Stack>
                </InfiniteScroll>
              )}
            </FeedTabPanel>

            <FeedTabPanel value={tabValue} index={1}>
              {!isLoading && !jobsByRecentDate?.length && (
                <div className="p-[1rem] sm:p-[1.5rem] md:p-[2rem] bg-[#ffffff] feedBoxShadow rounded-2xl mb-[2rem] w-full">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Icon iconKey="broken" size={5} />
                    <Typography>No jobs found</Typography>
                  </Stack>
                </div>
              )}
            </FeedTabPanel>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default FeedPage;
