import React from "react";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import HelpCenterImage from "./HelpCenterImage";
import HelpCenterBackground from "../../img/HelpCenter.svg";
import QuestionMarkIcon from "./QuestionMark";

const HelpCenter = () => {
  const handleButtonClick = () => {
    window.open("https://helpcenter.gamemybiz.com/", "_blank");
  };
  return (
    <SoftBox
      py={2}
      px={2}
      sx={{ bgcolor: "primary.main", position: "relative" }}
    >
      <SoftBox sx={{ width: "100%", height: "177px" }}>
        <SoftBox
          sx={{
            position: "absolute",
            marginRight: "auto",
            marginLeft: "auto",
            bgcolor: "#141522",
            left: 0,
            right: 0,
            top: "-3px",
            textAlign: "center",
            border: "3.23px solid #FFFFFF",
            width: "62px",
            height: "38px",
            borderRadius: "38px",
            boxShadow:
              "rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px",
            lineHeight: "38px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QuestionMarkIcon />
        </SoftBox>
        <SoftBox
          sx={{
            backgroundImage: `url(${HelpCenterBackground})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            borderRadius: "10px",
            // bgcolor: "primary.main",
          }}
        >
          <SoftBox
            sx={{
              height: "100%",
              width: "80%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <SoftTypography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                textAlign: "center",
                marginTop: "20px",
                color: "#fff",
                marginBottom: "10px",
              }}
            >
              Help Center
            </SoftTypography>
            <SoftTypography
              variant="p"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                textAlign: "center",
                color: "#fff",
                marginBottom: "10px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              Having Trouble in Tracking? Please contact us for more questions
            </SoftTypography>
            <SoftButton
              color="white"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "4px",
                color: "#141522",
              }}
              // disabled={processing}
              // onClick={() => handleSubmit(handleRegister)}
              onClick={handleButtonClick}
              type="submit"
            >
              Go To Help Center
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default HelpCenter;
