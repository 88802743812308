import moment from "moment";

import { call, takeEvery, put, select, all, fork } from "redux-saga/effects";
import { types } from "./types";
import { types as workspaceTypes } from "../workspace/types";
import { types as claimsType } from "../claims/types";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";

import {
  GET_GROUP_KPIS,
  UPDATE_GROUP_KPI,
  CREATE_CUSTOM_KPI,
  DISABLE_GROUP_KPI,
  GET_ALL_USER_KPIS,
  ADD_USER_KPI,
  UPDATE_USER_KPI,
  DELETE_USER_KPI,
} from "../../api/kpi";
import { ADD_INTEGRATION } from "../../api/integration";
import { GET_LINKS } from "../../api/links";

const getCompanyId = (state) => state.authReducer.userDetails.companyId;
const getUserId = (state) => state.authReducer.userDetails._id;
const getIsAdmin = (state) => state.authReducer.userDetails.isAdmin;
const getSelectedWorkspace = (state) =>
  state.workspacesReducer.selectedWorkspace;
const getKpiMonthRef = (state) => state.kpiReducer.monthRef;
const getHistoricKpis = (state) => state.kpiReducer.historicKpis;
const getMyKpis = (state) => state.kpiReducer.myKpis;
const getUsersKpis = (state) => state.kpiReducer.usersKpis;

const addKpiObjects = (kpiSum, plusKpi) => {
  Object.keys(plusKpi).forEach((key) => {
    if (kpiSum[key]) {
      kpiSum[key].pts += plusKpi[key]?.pts;
      kpiSum[key].amt += plusKpi[key]?.amt;
    } else {
      kpiSum[key] = { ...plusKpi[key] };
    }
    kpiSum.total += plusKpi[key]?.pts ?? 0;
  });
  return kpiSum;
};

function* RefreshKpis() {
  yield put({ type: types.REFRESH_KPIS });
}

function* GetKpis() {
  try {
    const companyId = yield select(getCompanyId);
    const userId = yield select(getUserId);
    const isAdmin = yield select(getIsAdmin);
    const workspace = yield select(getSelectedWorkspace);
    const targetKpis = yield call(() => GET_GROUP_KPIS(companyId));

    // Skip Score processing if there are no Group KPIs or no selected workspace
    if ((targetKpis.data && !targetKpis.data.length) || !workspace?._id) {
      yield put({ type: types.GET_KPI_SCORE_EMPTY });
      return;
    }

    const allCompanyKpis = targetKpis.data
      .map(
        ({
          _id,
          name,
          pointScale,
          target,
          requiredInputs,
          model,
          groupId,
          parameters,
          sourceId,
          source,
          integration,
          integrationId,
          integrationMeta,
          isAdminAccess,
          metricType,
          optional,
        }) => ({
          _id,
          groupId,
          metricType,
          optional,
          pointScale: pointScale ?? model.defaultPointScale,
          defaultPointScale: model.defaultPointScale,
          target: target ?? model.defaultTarget,
          title: startCase(name ?? model.type),
          kind: model.kind,
          type: model.type,
          kpiModelId: model._id,
          direction: model.direction,
          isManual: !sourceId,
          isShared: !!(parameters?.level || source?.parameters?.level), // KPI that doesn't belong to a specific user, rather to a workspace
          isCustom: model.isTemplate === true, // based from a template that is customized
          isAdminAccess: isAdminAccess ?? false,
          integrationId,
          ...(integrationMeta && { integrationMeta }),
          sourceId,
          source: integration?.code,
          sourceName: integration?.name,
          wholeMonth:
            source?.parameters?.calc && source?.parameters?.calc !== "sum",
          calc: source?.parameters?.calc,
          gap: source?.parameters?.gap,
          minimum: parameters?.minimum ?? 0,
          parameters: parameters ?? null,
          requiredInputs: requiredInputs && requiredInputs.length > 0 ? requiredInputs : (model.defaultInputs ?? null),
        }),
      )
      .sort((a, b) => b.pointScale * b.target - a.pointScale * a.target);

    const companyKpis = {};
    allCompanyKpis.forEach((groupKpi) => {
      if (companyKpis[groupKpi.groupId]) {
        companyKpis[groupKpi.groupId].push(groupKpi);
      } else {
        companyKpis[groupKpi.groupId] = [groupKpi];
      }
    });

    let groupKpis = [];
    if (isAdmin) {
      const links = yield call(() => GET_LINKS(companyId));
      groupKpis = (companyKpis[workspace._id] ?? []).map((groupKpi) => {
        const index = links.data.data.findIndex(
          (link) => link.kpiId === groupKpi._id,
        );

        if (index !== -1) {
          return {
            ...groupKpi,
            kpiLinks: links.data.data[index],
          };
        } else {
          return groupKpi;
        }
      });
    } else {
      groupKpis = companyKpis[workspace._id] ?? [];
    }

    const scoreKpis = yield call(() =>
      GET_ALL_USER_KPIS(companyId, workspace._id),
    );

    let myKpis = null;
    const userScores = [];
    const sharedScores = [];

    scoreKpis.data.scores.forEach((score) => {
      if (score.scope === "User") {
        userScores.push(score);
      } else {
        sharedScores.push(...score.days);
      }
    });

    if (sharedScores.length) {
      scoreKpis.data.users.forEach((user) => {
        const idx = userScores.findIndex((u) => u.ownerId === user);
        if (idx >= 0) {
          userScores[idx].days.push(...sharedScores);
        } else {
          userScores.push({
            ownerId: user,
            days: [...sharedScores],
          });
        }
      });
    }

    const day = moment().startOf("day");
    const yesterday = moment().startOf("day").subtract(1, "days");
    const week = moment().startOf("week");
    const month = moment().startOf("month");

    const usersKpis = userScores?.map((userScore) => {
      const summary = {
        userId: userScore.ownerId,
        yesterday: { total: 0 },
        today: { total: 0 },
        week: { total: 0 },
        month: { total: 0 },
      };

      for (let d = 0; d < userScore.days.length; d++) {
        const dayData = userScore.days[d];
        const dateRef = moment(dayData.day);

        if (dateRef >= day) {
          addKpiObjects(summary.today, dayData.kpis);
        } else if (dateRef >= yesterday) {
          addKpiObjects(summary.yesterday, dayData.kpis);
        }

        if (dateRef >= week) {
          addKpiObjects(summary.week, dayData.kpis);
        }
        if (dateRef >= month) {
          addKpiObjects(summary.month, dayData.kpis);
        }
      }

      if (summary.userId === userId) {
        myKpis = summary;
      }

      return summary;
    });

    // Average scores for admin view
    const totalKpis = { total: 0 };
    const teamScores = [];
    usersKpis.forEach((scores) => {
      const { total, ...rest } = scores.month;
      if (total) {
        teamScores.push(rest);
      }
    });
    teamScores.forEach((scores) => {
      addKpiObjects(totalKpis, scores);
    });

    Object.keys(totalKpis).forEach((key) => {
      if (key !== "total") {
        const initialTotal = 0;
        const scorersCount = teamScores.reduce(
          (count, score) => count + (score[key] ? 1 : 0),
          initialTotal,
        );
        totalKpis[key].amt =
          Math.round((totalKpis[key].amt / scorersCount) * 100) / 100;
        totalKpis[key].pts =
          Math.round((totalKpis[key].pts / scorersCount) * 100) / 100;
      } else {
        totalKpis.total =
          Math.round((totalKpis.total / teamScores.length) * 100) / 100;
      }
    });

    yield put({
      type: types.GET_KPI_SCORE_SUCCESS,
      companyKpis,
      groupKpis,
      // Sort by monthly default, so that month's top users remain on top even if they tie on today's ranking with zero scores
      usersKpis: usersKpis.sort((a, b) => b.month.total - a.month.total),
      myKpis,
      adminKpis: { month: totalKpis },
    });
  } catch (err) {
    yield put({ type: types.GET_KPI_SCORE_FAILED, error: err.message });
  }
}

function* GetPreviousKpis(action) {
  try {
    const { month, refresh } = action;
    let dateRef;
    if (!month) {
      dateRef = yield select(getKpiMonthRef);
    } else {
      dateRef = moment(month).format("YYYY-MM");
    }

    const prevKpis = yield select(getHistoricKpis);

    if (!refresh && prevKpis?.[dateRef]) {
      yield put({
        type: types.SET_TO_HISTORIC_KPIS,
        monthRef: dateRef,
      });
    } else {
      const companyId = yield select(getCompanyId);
      const workspace = yield select(getSelectedWorkspace);

      const scoreKpis = yield call(() =>
        GET_ALL_USER_KPIS(companyId, workspace._id, dateRef),
      );

      const userScores = [];
      const sharedScores = [];

      scoreKpis.data.scores.forEach((score) => {
        if (score.scope === "User") {
          userScores.push(score);
        } else {
          sharedScores.push(...score.days);
        }
      });

      if (sharedScores.length) {
        scoreKpis.data.users.forEach((user) => {
          const idx = userScores.findIndex((u) => u.ownerId === user);
          if (idx >= 0) {
            userScores[idx].days.push(...sharedScores);
          } else {
            userScores.push({
              ownerId: user,
              days: [...sharedScores],
            });
          }
        });
      }

      const usersKpis = userScores.map((userData) => {
        const summary = {
          userId: userData.ownerId,
          month: { total: 0 },
        };

        for (let d = 0; d < userData.days.length; d++) {
          const dayData = userData.days[d];
          addKpiObjects(summary.month, dayData.kpis);
        }

        return summary;
      });

      const newKpiHistory = {};
      newKpiHistory[dateRef] = usersKpis.sort(
        (a, b) => b.month.total - a.month.total,
      );

      yield put({
        type: types.GET_PREVIOUS_KPIS_SUCCESS,
        historicKpis: newKpiHistory,
        monthRef: dateRef,
      });
    }
  } catch (err) {
    yield put({ type: types.GET_PREVIOUS_KPIS_FAILED, error: err.message });
  }
}

function* refreshKpisSaga() {
  yield takeEvery(types.REFRESH_KPIS, GetKpis);
  yield takeEvery(types.DELETE_USER_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.UPDATE_USER_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.ADD_USER_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.UPDATE_GROUP_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.DISABLE_GROUP_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.CREATE_CUSTOM_GROUP_KPI_SUCCESS, RefreshKpis);
  yield takeEvery(types.ADD_INTEGRATION_SUCCESS, RefreshKpis);
  yield takeEvery(workspaceTypes.GET_ALL_WORKSPACES_SUCCESS, RefreshKpis);
  yield takeEvery(workspaceTypes.SET_SELECTED_WORKSPACE_SUCCESS, RefreshKpis);
  yield takeEvery(claimsType.PUT_USER_CLAIMS_SUCCESS, RefreshKpis);

  yield takeEvery(types.GET_PREVIOUS_KPIS_REQUEST, GetPreviousKpis);
  yield takeEvery(types.ADD_OLD_USER_KPI_SUCCESS, GetPreviousKpis, {
    refresh: true,
  });
}

function* AddKpi(action) {
  const { kpiId, userId, month, data } = action;
  const companyId = yield select(getCompanyId);
  const monthFormatted = month ? moment(month).format("YYYY-MM") : null;

  try {
    const result = yield call(() =>
      ADD_USER_KPI({
        kpiId,
        companyId,
        userId,
        data,
        month: monthFormatted,
      }),
    );

    if (result.error) {
      yield put({
        type: monthFormatted
          ? types.ADD_OLD_USER_KPI_FAILED
          : types.ADD_USER_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      // Fetch current KPI scores so that we can instantly add new score
      const currentUsersKpis = yield select(getUsersKpis);
      const currentMyKpis = yield select(getMyKpis);

      // Deep copy KPI array of objects even if it's just the month parameter
      const usersKpis = cloneDeep(currentUsersKpis);
      let myKpis =  cloneDeep(currentMyKpis);
      const { amount, points } = result.data;

      const newScore = {};
      newScore[kpiId] = { amt: amount, pts: points };

      if(!myKpis) {
        myKpis = {
          userId,
          month: newScore,
        }
      } else if (myKpis.userId === userId) {
        addKpiObjects(myKpis.month, newScore);
      }

      const userIdx = usersKpis.findIndex((item) => item.userId === userId);
      if (userIdx >= 0) {
        addKpiObjects(usersKpis[userIdx].month, newScore);
      } else {
        usersKpis.push({
          userId,
          total: amount,
          month: newScore,
        });
      }

      yield put({
        type: monthFormatted
          ? types.ADD_OLD_USER_KPI_SUCCESS
          : types.ADD_USER_KPI_SUCCESS,
        usersKpis,
        myKpis,
      });
    }
  } catch (err) {
    yield put({ type: types.ADD_USER_KPI_FAILED, error: err.message });
  }
}

function* UpdateKpi(action) {
  const { id, kpiId, amount } = action;
  try {
    const result = yield call(() => UPDATE_USER_KPI(id, kpiId, amount));
    if (result.error) {
      yield put({
        type: types.UPDATE_USER_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      yield put({ type: types.UPDATE_USER_KPI_SUCCESS });
    }
  } catch (err) {
    yield put({ type: types.UPDATE_USER_KPI_FAILED, error: err.message });
  }
}

function* DeleteKpi(action) {
  const { kpiId } = action;
  try {
    const result = yield call(() => DELETE_USER_KPI(kpiId));
    if (result.error) {
      yield put({
        type: types.DELETE_USER_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      yield put({ type: types.DELETE_USER_KPI_SUCCESS });
    }
  } catch (err) {
    yield put({ type: types.DELETE_USER_KPI_FAILED, error: err.message });
  }
}

function* userKpiSaga() {
  yield takeEvery(types.ADD_USER_KPI_REQUEST, AddKpi);
  yield takeEvery(types.UPDATE_USER_KPI_REQUEST, UpdateKpi);
  yield takeEvery(types.DELETE_USER_KPI_REQUEST, DeleteKpi);
}

function* updateWorkspaceKpi(action) {
  const { groupKpi } = action;
  groupKpi.name = groupKpi.name ?? groupKpi.type;

  try {
    const result = yield call(() =>
      UPDATE_GROUP_KPI(groupKpi.groupId, groupKpi),
    );
    if (result.error) {
      yield put({
        type: types.UPDATE_GROUP_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      yield put({ type: types.UPDATE_GROUP_KPI_SUCCESS });
    }
  } catch (err) {
    yield put({ type: types.UPDATE_GROUP_KPI_FAILED, error: err.message });
  }
}

function* createCustomWorkspaceKpi(action) {
  const { groupKpi } = action;

  try {
    const result = yield call(() => CREATE_CUSTOM_KPI(groupKpi));
    if (result.error) {
      yield put({
        type: types.CREATE_CUSTOM_GROUP_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      yield put({ type: types.CREATE_CUSTOM_GROUP_KPI_SUCCESS });
    }
  } catch (err) {
    yield put({
      type: types.CREATE_CUSTOM_GROUP_KPI_FAILED,
      error: err.response?.message ?? err.message,
    });
  }
}

function* disableWorkspaceKpi(action) {
  const { id } = action;

  try {
    const result = yield call(() => DISABLE_GROUP_KPI(id));
    if (result.error) {
      yield put({
        type: types.DISABLE_GROUP_KPI_FAILED,
        error: result.error.message,
      });
    } else {
      yield put({ type: types.DISABLE_GROUP_KPI_SUCCESS });
    }
  } catch (err) {
    yield put({ type: types.DISABLE_GROUP_KPI_FAILED, error: err.message });
  }
}

function* addIntegration(action) {
  const { integration } = action;
  try {
    yield call(() => ADD_INTEGRATION(integration));
    yield put({ type: types.ADD_INTEGRATION_SUCCESS });
  } catch (err) {
    yield put({ type: types.ADD_INTEGRATION_FAILED, error: err.message });
  }
}

function* companyKpiSaga() {
  yield takeEvery(types.UPDATE_GROUP_KPI_REQUEST, updateWorkspaceKpi);
  yield takeEvery(types.DISABLE_GROUP_KPI_REQUEST, disableWorkspaceKpi);
  yield takeEvery(
    types.CREATE_CUSTOM_GROUP_KPI_REQUEST,
    createCustomWorkspaceKpi,
  );
  yield takeEvery(types.ADD_INTEGRATION_REQUEST, addIntegration);
}

function* rootSaga() {
  yield all([fork(refreshKpisSaga), fork(userKpiSaga), fork(companyKpiSaga)]);
}

export default rootSaga;
