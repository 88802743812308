/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteKpiRecord, updateKpiRecord } from "../../redux/kpi/actions";

import {
  Edit as EditIcon,
  DeleteOutline,
  BoltRounded,
  SwitchAccessShortcut,
} from "@mui/icons-material";
import SoftButton from "../../components/SoftButton";
import ConfirmatonModal from "../../components/Modals/ConfirmationModal";
import InputModal from "../../components/Modals/InputModal";

import toast from "react-hot-toast";
import { formatNumber } from "../../helpers/formatter";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

// @mui material components
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "../../assets/theme/base/typography";
import borders from "../../assets/theme/base/borders";

import { KPI_IMAGE_PARAMS, KPI_KINDS, KPI_TYPES } from "../../constants/kpi";

const SOURCE_ICONS = {
  INTEGRATED: (
    <BoltRounded
      style={{ cursor: "pointer", color: "white", marginRight: "0.25rem" }}
    />
  ),
  MANUAL: (
    <EditIcon
      style={{ cursor: "pointer", color: "white", marginRight: "0.25rem" }}
    />
  ),
  OTHER: (
    <SwitchAccessShortcut
      style={{ cursor: "pointer", color: "white", marginRight: "0.25rem" }}
    />
  ),
};

function ScoreTable({
  employeeKpis,
  isAdmin,
  viewItem,
  message = null,
  light = false,
  isSmallScreen = false,
  getGroupKpi,
}) {
  const [kpiScores, setKpiScores] = useState(employeeKpis);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const dispatch = useDispatch();

  useEffect(() => {
    setKpiScores(employeeKpis);
  }, [employeeKpis]);

  useEffect(() => {
    const columns = [
      { name: "kpi", align: "center" },
      ...(isAdmin ? [{ name: "source", align: "center" }] : []),
      { name: isSmallScreen ? "amt" : "amount", align: "center" },
      { name: "date", align: "center" },
      { name: isSmallScreen ? "ref" : "reference", align: "center" },
      ...(isAdmin ? [{ name: "actions", align: "center" }] : []),
    ];

    const handleDelete = (id) => {
      dispatch(deleteKpiRecord(id));
      const ekpis = [...kpiScores];
      const index = ekpis.findIndex((item) => item._id === id);
      if (index >= 0) {
        ekpis.splice(index, 1);
        setKpiScores(ekpis);
      }
      toast.success("KPI Record Deleted");
    };

    const handleUpdate = (id, kpiId, amount) => {
      if (amount) {
        const groupKpi = getGroupKpi(kpiId);

      // Divide value by 100 if "%" kind an is andchor metric
      const valueAmount = (groupKpi?.kind === KPI_KINDS[2] && groupKpi?.metricType === KPI_TYPES[1])
        ? parseFloat(amount) / 100
        : amount;


        dispatch(updateKpiRecord(id, kpiId, valueAmount));
        const ekpis = [...kpiScores];
        const index = ekpis.findIndex((item) => item._id === id);
        if (index >= 0) {
          ekpis[index].amount = valueAmount;
          setKpiScores(ekpis);
        }
        toast.success("KPI Record Updated");
      }
    };

    const rowsData = kpiScores.map((score) => {
      const rowContainer = {};
      // Use shorthand initials of the KPI for mobile if the names is too long
      rowContainer.kpi =
        isSmallScreen && score.kpiName?.length > 8
          ? score.kpiName?.substr(0, 8)
          : score.kpiName;
      rowContainer.source = (
        <SoftBox sx={{ display: "flex", alignItems: "center" }}>
          {SOURCE_ICONS[score.dataSource] ?? SOURCE_ICONS.OTHER}
          <SoftTypography variant="button" color="white">
            {score.dataSource}
          </SoftTypography>
        </SoftBox>
      );

      const formattedAmt = formatNumber(score.amount, score.kind);
      rowContainer[isSmallScreen ? "amt" : "amount"] = (
        <SoftTypography variant="button" sx={{ color: "#6AFFAF" }}>
          {formattedAmt}
        </SoftTypography>
      );
      rowContainer.rawAmount = (
        <SoftTypography variant="button" sx={{ color: "#6AFFAF" }}>
          {score.amount}
        </SoftTypography>
      );
      rowContainer.date = (
        <SoftTypography variant="button" color="white">
          {moment(score.createdAt).format(
            isSmallScreen ? "MMM DD" : "MMM D,YYYY",
          )}
        </SoftTypography>
      );

      const jobId =
        score.refModel === "Job"
          ? score.refId
          : score.claimModel === "Job"
          ? score.claimId
          : null;

      let refItem = null;
      if (jobId) {
        // Show Job Feed item
        refItem = (
          <SoftButton
            variant="text"
            size="small"
            color="primary"
            onClick={() => viewItem({ jobId })}
            sx={{ paddingX: 2 }}
          >
            View Job{" "}
            <ArrowForwardIosRoundedIcon
              sx={{ marginLeft: "0.25rem", color: "primary.main" }}
            />
          </SoftButton>
        );
      } else {
        // Find if an image item exists
        const imageItem =
          score.metadata &&
          Object.keys(score.metadata).find((item) =>
            KPI_IMAGE_PARAMS.some((key) => item.includes(key)),
          );
        // Compile input parameters, but skip the image item
        const notes =
          score.metadata &&
          Object.values(score.metadata)
            .filter((item) => item !== score.metadata[imageItem])
            .join("\n");

        if (imageItem) {
          refItem = (
            <SoftButton
              variant="text"
              size="small"
              color="primary"
              onClick={() =>
                viewItem({ imageKey: score.metadata[imageItem], notes })
              }
              sx={{ paddingX: 2 }}
            >
              View Image{" "}
              <ArrowForwardIosRoundedIcon
                sx={{ marginLeft: "0.25rem", color: "primary.main" }}
              />
            </SoftButton>
          );
        } else {
          refItem = (
            <SoftBox
              className="truncate text-ellipsis"
              sx={{
                maxWidth: { mini: "4rem", md: "12rem", lg: "15rem" },
                "& a": { color: "#fff", textDecoration: "underline" },
                "& a:hover": { color: "#fff", opacity: "0.7" }
              }}
            >
              <SoftTypography variant="button" color="white">
                {(notes?.includes("http"))
                  ? <a
                    href={notes}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {notes}
                  </a>
                  : notes ?? "None"
                }
              </SoftTypography>
            </SoftBox>
          );
        }
      }
      rowContainer[isSmallScreen ? "ref" : "reference"] = refItem;

      if (isAdmin) {
        rowContainer.actions = (
          <SoftBox className="flex flex-row gap-1">
            <InputModal
              title={`Update ${score.kpiName} Score`}
              question={`Do you want to update ${score.name}'s  ${score.kpiName} KPI record? Current amount: ${formattedAmt}`}
              yesText="Yes, update"
              noText="No, cancel"
              label="New Value"
              isNumericInput={true}
              onsuccess={(value) => handleUpdate(score._id, score.kpiId, value)}
            >
              <SoftButton
                variant="outlined"
                iconOnly={true}
                color={light ? "dark" : "white"}
                sx={{ fontSize: "1rem" }}
              >
                <EditIcon />
              </SoftButton>
            </InputModal>
            <ConfirmatonModal
              title={`Delete ${score.kpiName} Score`}
              question={`Are you sure you want to delete \n ${score.name}'s ${formattedAmt} ${score.kpiName} KPI record?`}
              yesText="Yes, delete"
              noText="No, cancel"
              onSuccess={() => handleDelete(score._id)}
            >
              <SoftButton
                variant="outlined"
                iconOnly={true}
                color={light ? "dark" : "white"}
                sx={{ fontSize: "1rem" }}
              >
                <DeleteOutline />
              </SoftButton>
            </ConfirmatonModal>
          </SoftBox>
        );
      }
      return rowContainer;
    });

    const renderColumns = columns.map(({ name, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 3;
        pr = 3;
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.sm}
          fontWeight={fontWeightBold}
          color="white"
          opacity={0.7}
        >
          {name === "source" ? "SOURCE" : name.toUpperCase()}
        </SoftBox>
      );
    });

    const renderRows = rowsData.map((row, key) => {
      const rowKey = `row-${key}`;

      const tableRow = columns.map(({ name, align }) => {
        return (
          <SoftBox
            key={`${rowKey}-${name}`}
            component="td"
            p={1}
            textAlign={align}
          >
            <SoftTypography
              color="white"
              variant="button"
              fontWeight="regular"
              sx={{
                display: "inline-block",
                width: "max-content",
                verticalAlign: "middle",
              }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      });

      return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });

    setRows(renderRows);
    setCols(renderColumns);
  }, [kpiScores, isAdmin, dispatch, borderWidth, light, size, fontWeightBold]);

  const tableComponent = useMemo(
    () => (
      <TableContainer
        className="bg-transparent max-h-[580px] overflow-y-scroll no-scrollbar relative"
        sx={{ boxShadow: "none", borderRadius: 0 }}
      >
        <Table stickyHeader={true}>
          <SoftBox component="thead">
            <TableRow>{cols}</TableRow>
          </SoftBox>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    ),
    [rows, cols],
  );

  return (
    <>
      {tableComponent}
      {message && (
        <SoftTypography
          variant="outline"
          color="white"
          sx={{ marginY: message ? 4 : 0 }}
        >
          {message}
        </SoftTypography>
      )}
    </>
  );
}

export default ScoreTable;
