import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TAWK_TO_PROP_ID = process.env.REACT_APP_TAWK_TO_PROPERTY_ID;
const TAWK_TO_WIDGET_ID = process.env.REACT_APP_TAWK_TO_WIDGET_ID;

const TawkToChat = () => {
  return (
    <TawkMessengerReact
      propertyId={TAWK_TO_PROP_ID}
      widgetId={TAWK_TO_WIDGET_ID}
    />
  );
};

export default TawkToChat;
