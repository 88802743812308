import React, { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { toast } from "react-hot-toast";

// Components
import NoteList from "./NoteList";

// Hooks
import { ADD_COMMENT, FETCH_COMMENTS } from "../../api/comments";
import { UseUserId } from "../../hooks/auth";

const Notes = ({
  eKpidId,
  onAddNote,
  isVisible,
  handleClickAway
}) => {
  const [notes, setNotes] = useState(null);
  const userId = UseUserId();

  useEffect(() => {
    if (eKpidId) {
      FETCH_COMMENTS(eKpidId, 1)
      .then(response => {
        console.log(response.data)
        setNotes(orderBy(response?.data?.comments, ["createdAt"], ["desc"]));
      })
      .catch(error => {
        toast.error(error.message);
      })
    }
  }, [eKpidId]);


  const sendMessage = async (message) => {
    if (message === "") return;

    const payload = {
      jobId: eKpidId,
      data: {
        userId,
        content: message,
        propertyModel: "EmployeeKpi"
      }
    }

    try {
      const response = await ADD_COMMENT(payload);
      const currentNotes = [...notes];
      currentNotes.push(response?.data);

      setNotes(orderBy(currentNotes, ["createdAt"], ["desc"]));
      onAddNote?.(eKpidId);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <NoteList
      notes={notes}
      isVisible={isVisible}
      onSendMessage={sendMessage}
      handleClickAway={handleClickAway}
    />
  )
}

export default Notes;
