import React from "react";

// Imported MUI and Styles
import { Box, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";

import GoldenBadge from "../../img/level_golden_badge.png";

const Levels = ({ index, dataLevels, board }) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { mini: "300px", xs: "360px", md: "420px" },
        height: {
          mini: "140px",
          sm: "160px",
        },
        transition: "height 0.2s ease-out",
        background: `url(${dataLevels[index].lvlBackground})`,
        marginBottom: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "25px 45px 45px 25px",
        display: "grid",
        gridTemplateColumns: "25px 1fr",
        cursor: "pointer",
        backdropFilter: "blur(7.5px)",
      }}
    >
      <Box
        sx={{
          width: "25px",
          height: "100%",
          backgroundColor: darken(dataLevels[index].lvlColor, 0.25),
          borderRadius: "25px 0px 0px 25px",
        }}
      />
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "0.75fr 1.25fr",
          borderRadius: "0px 45px 45px 0px",
          background: `linear-gradient(90deg, ${dataLevels[index].lvlBGColor} 1.91%, rgba(255, 255, 255, 0.34) 19.12%, ${dataLevels[index].lvlBGColor} 35.98%)`,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(12.5px)",
        }}
      >
        <Box
          sx={{
            maxWidth: "130px",
            transition: "max-width 0.2s ease-out",
            justifySelf: "center",
          }}
        >
          <img src={GoldenBadge} alt="" className="w-full" />
        </Box>
        <Box sx={{ justifyContent: "center", paddingX: "1rem" }}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.05)",
              borderRadius: "3rem",
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                color: `${index === 9 ? "#807200" : "#FFF"}`,
                fontWeight: 700,
                fontFamily: "DM Sans, sans-serif",
                fontSize: {
                  mini: "1.25rem",
                  sm: "1.5rem",
                  xl: "1.75rem",
                },
              }}
            >
              {board.columns[index].levelName}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: `${index === 9 ? "#807200" : "#FFF"}`,
              fontFamily: "DM Sans, sans-serif",
              fontSize: { mini: "0.75rem", sm: "0.875rem" },
              marginBottom: "0.25rem",
              lineHeight: "1.2rem",
              whiteSpace: "normal",
            }}
          >
            {board.columns[index].levelInfo}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Levels;
