import * as Yup from "yup";

const CreateChallengeArenaSchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  isPrivate: Yup.boolean().required("Required"),
  category: Yup.string().required("Required"),
  startDate: Yup.string().required("Field is required"),
  endDate: Yup.string().required("Field is required"),
  prizeForWinner: Yup.string().nullable(),
  prizeforSecondPlace: Yup.string().nullable(),
  prizeforThirdPlace: Yup.string().nullable(),
  prizeforParticipants: Yup.string().nullable(),
  description: Yup.string().required("Field is required"),
});

export default CreateChallengeArenaSchema;
