import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from "@iconify/react";
import { GET_IMAGE } from "../../helpers/images";
import defaultAvatar from "../../img/avatar2.png";
import {
  REACTION_ICON_TYPE,
  REACTION_PROPERTY_TYPE,
} from "../../constants/reactions";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";

const AVATAR_LIMIT = 4;

const SlowClap = ({
  jobId,
  currentUserId,
  reacts,
  onToggle,
  showDetails,
  setToggleClaps,
  toggleClaps,
}) => {
  const avatarsToShow = reacts.slice(0, AVATAR_LIMIT);
  const [active, setActive] = useState(false);
  const [currentReact, setCurrentReact] = useState({});

  useEffect(() => {
    const currentReactFilter = reacts.find(
      ({ users }) => users[0]._id.toString() === currentUserId,
    );
    setCurrentReact(currentReactFilter);
    setActive(!!currentReactFilter);
  }, [currentUserId, reacts]);

  const handleToggle = () => {
    if (!showDetails) {
      setToggleClaps(!toggleClaps);
    }
    const isActive = !active;
    setActive(isActive);
    onToggle(
      jobId,
      currentUserId,
      isActive,
      currentReact,
      REACTION_ICON_TYPE.SLOW_CLAP,
      REACTION_PROPERTY_TYPE.JOB,
    );
  };

  return (
    <SoftBox>
      <SoftBox sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <SoftTypography variant="h6" color="black">
          {reacts.length}
        </SoftTypography>
        <SoftButton
          type="button"
          onClick={handleToggle}
          color="primary"
          variant={active ? "contained" : "text"}
          sx={{
            minWidth: "max-content",
            minHeight: "max-content",
            borderRadius: "50%",
            padding: active ? "0.5rem" : 0,
          }}
          // className={
          //   active
          //     ? "text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 h-max"
          //     : "text-black focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-white-800 mr-2 h-max"
          // }
        >
          <Icon icon="fa6-solid:hands-clapping" className="!text-[20px]" />
        </SoftButton>
      </SoftBox>

      {/* {showDetails || toggleClaps ? (
        <div className="flex gap-x-[0.5rem] mt-2 items-center">
          <AvatarGroup>
            {avatarsToShow.map(({ users }) => (
              <Avatar
                key={users[0]?._id}
                className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800"
                src={
                  users[0]?.image ? GET_IMAGE(users[0].image) : defaultAvatar
                }
                alt=""
                max={4}
              />
            ))} */}
      {/* {reacts.length > AVATAR_LIMIT && (
              <a
                className="flex justify-center items-center w-10 h-10 text-xs font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800"
                href="#"
              >
                +{reacts?.length - AVATAR_LIMIT}
              </a>
            )} */}
      {/* </AvatarGroup>
          {toggleClaps ? (
            <p className="text-xs sm:text-sm md:text-base font-semibold content-center mt-1">
              {reacts.length} SLOW CLAP
            </p>
          ) : null}
        </div>
      ) : null} */}
    </SoftBox>
  );
};

export default SlowClap;
