import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, TableRow, TableBody, TableCell } from "@mui/material";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import { GET_ALL_INVOICES } from "../../api/invoice";
import LoadingScreen from "../LoadingScreen";
import { GET_PRICING } from "../../api/payment";
import { useSearchParams } from "react-router-dom";
import { formatCurrency } from "../../helpers/formatter";

const InvoiceTable = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pricingPlan, setPricingPlan] = useState(null);

  const [params] = useSearchParams();
  const tier = params.get("tier");

  const columns = [
    { name: "Invoice Number" },
    { name: "Plan" },
    { name: "Billing Date" },
    { name: "Status" },
    { name: "Due Amount" },
    // { name: "Action" },
  ];

  const fecthAllInvoices = async () => {
    setLoading(true);
    try {
      const { data } = await GET_ALL_INVOICES();
      const response = await GET_PRICING();

      const currentTier = tier ?? "1";
      const matchingTier = response.data.find(
        (z) => z.interval === "month" && z.level === currentTier,
      );

      if (matchingTier) {
        setPricingPlan(matchingTier);
      }
      setInvoices(data);
    } catch (error) {
      console.log("Error fetching cards", error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fecthAllInvoices();
  }, []);

  return (
    <SoftBox style={{ width: "100%" }}>
      {loading ? (
        <LoadingScreen height="200px" logoHeight="200px" />
      ) : (
        <Table>
          <SoftBox component="thead">
            <TableRow>
              {columns.map((column, i) => (
                <TableCell>{column.name}</TableCell>
              ))}
            </TableRow>
          </SoftBox>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{pricingPlan?.name ?? "Standard"}</TableCell>
                <TableCell>
                  {moment(invoice.period_end).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  <SoftTypography
                    sx={{
                      background: invoice.paid ? "#ECFDF5" : "#FEF2F2",
                      color: invoice.paid ? "#064E3B" : "#991B1B",
                      padding: "5px 15px",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "4px",
                      width: "min-content",
                    }}
                  >
                    {invoice.paid ? "Paid" : "UnPaid"}
                  </SoftTypography>
                </TableCell>
                <TableCell>{formatCurrency((invoice?.amount_due ?? 0) / 100, 2)}</TableCell>
                {/* <TableCell>
                  <SoftButton
                    onClick={() => {}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isDownloading}
                    sx={{
                      color: "primary.contrastText",
                      textTransform: "none",
                      borderRadius: "4px",
                    }}
                  >
                    Download
            {isDownloading && (
            <CircularProgress color="inherit" size="1rem" className="ml-2" />
          )}
                  </SoftButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </SoftBox>
  );
};

export default InvoiceTable;
