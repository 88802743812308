import React from "react";
import SoftButton from "../SoftButton";
import { useNavigate } from "react-router-dom";

// Imported Styles
import "./errorStyles.css";

// Imported Images and Icons
import errorImg from "../../img/error.png";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen flex items-center">
      <div className="container errorContainer">
        <div className="p-8 textContainer">
          <p className="text-6xl md:text-7xl lg:text-8xl xl:text-9xl font-semibold mb-12 text-[#FF007E]">
            Error 404
          </p>
          <p className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold mb-6 text-[#344767]">
            Erm. Page not found
          </p>
          <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-10 text-[#8392ab]">
            We suggest you to go to the homepage while we solve this issue.
          </p>
          <SoftButton
            onClick={() => navigate("/")}
            variant="gradient"
            color="primary"
            size="large"
          >
            GO TO HOMEPAGE
          </SoftButton>
        </div>
        <div className="max-w-full flex items-center imgContainer">
          <img src={errorImg} alt="" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
