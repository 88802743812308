import { request } from "../helpers/axios.new";

export const CREATE_CHAT = (data) => {
  return request({
    url: `/chat`,
    method: "POST",
    data,
  });
};

export const FETCH_CHATS = () => {
  return request({
    url: `/chat`,
    method: "GET",
  });
};

export const CREATE_GROUP_CHAT = (data) => {
  return request({
    url: `/chat/group`,
    method: "POST",
    data,
  });
};

export const RENAME_GROUP_CHAT = (data) => {
  return request({
    url: `/chat/group`,
    method: "PUT",
    data,
  });
};

export const ADD_PARTICIPANTS_TO_OR_REMOVE_FROM_GROUP_CHAT = (data) => {
  return request({
    url: `/chat/group/add-participant`,
    method: "PUT",
    data,
  });
};
