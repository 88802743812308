import React from "react";

const MessageIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 15.2067C7.53998 15.2067 7.10665 14.9733 6.79998 14.5667L5.79998 13.2333C5.77998 13.2067 5.69998 13.1667 5.65998 13.1667H5.33331C2.55331 13.1667 0.833313 12.4133 0.833313 8.66668V5.33334C0.833313 2.38668 2.38665 0.833344 5.33331 0.833344H7.99998C8.27331 0.833344 8.49998 1.06001 8.49998 1.33334C8.49998 1.60668 8.27331 1.83334 7.99998 1.83334H5.33331C2.94665 1.83334 1.83331 2.94668 1.83331 5.33334V8.66668C1.83331 11.68 2.86665 12.1667 5.33331 12.1667H5.66665C6.00665 12.1667 6.39331 12.36 6.59998 12.6333L7.59998 13.9667C7.83331 14.2733 8.16665 14.2733 8.39998 13.9667L9.39998 12.6333C9.61998 12.34 9.96665 12.1667 10.3333 12.1667H10.6666C13.0533 12.1667 14.1666 11.0533 14.1666 8.66668V5.33334C14.1666 5.06001 14.3933 4.83334 14.6666 4.83334C14.94 4.83334 15.1666 5.06001 15.1666 5.33334V8.66668C15.1666 11.6133 13.6133 13.1667 10.6666 13.1667H10.3333C10.28 13.1667 10.2333 13.1933 10.2 13.2333L9.19998 14.5667C8.89331 14.9733 8.45998 15.2067 7.99998 15.2067Z"
        fill="#F9FAFB"
      />
      <path
        d="M7.99998 7.99999C7.62665 7.99999 7.33331 7.69999 7.33331 7.33332C7.33331 6.96666 7.63331 6.66666 7.99998 6.66666C8.36665 6.66666 8.66665 6.96666 8.66665 7.33332C8.66665 7.69999 8.37331 7.99999 7.99998 7.99999Z"
        fill="#F9FAFB"
      />
      <path
        d="M10.6667 7.99999C10.2933 7.99999 10 7.69999 10 7.33332C10 6.96666 10.3 6.66666 10.6667 6.66666C11.0333 6.66666 11.3333 6.96666 11.3333 7.33332C11.3333 7.69999 11.04 7.99999 10.6667 7.99999Z"
        fill="#F9FAFB"
      />
      <path
        d="M5.33335 7.99999C4.96002 7.99999 4.66669 7.69999 4.66669 7.33332C4.66669 6.96666 4.96669 6.66666 5.33335 6.66666C5.70002 6.66666 6.00002 6.96666 6.00002 7.33332C6.00002 7.69999 5.70669 7.99999 5.33335 7.99999Z"
        fill="#F9FAFB"
      />
      <path
        d="M12 5.16667C11.4267 5.16667 10.0733 4.48667 9.65999 3.24C9.49332 2.74667 9.53332 2.18 9.76665 1.73334C9.97332 1.33334 10.32 1.04 10.7467 0.90667C11.18 0.773337 11.64 0.833337 12 1.05334C12.36 0.833337 12.82 0.773337 13.26 0.90667C13.6867 1.04 14.0333 1.33334 14.24 1.73334C14.4733 2.18 14.5133 2.74667 14.3467 3.24C13.9267 4.50667 12.5133 5.16667 12 5.16667ZM10.6067 2.92C10.8867 3.76 11.84 4.14667 12.0133 4.16667C12.2067 4.14667 13.14 3.71334 13.4 2.92667C13.48 2.68667 13.46 2.4 13.3533 2.19334C13.2667 2.02667 13.14 1.92 12.9667 1.86667C12.78 1.8 12.5267 1.83334 12.4133 2C12.32 2.13334 12.1733 2.21334 12.0133 2.22C11.8533 2.22 11.7 2.14667 11.6 2.02C11.46 1.84 11.2133 1.81334 11.0333 1.86667C10.86 1.92 10.7333 2.02667 10.6467 2.19334C10.5467 2.4 10.5267 2.68 10.6067 2.92Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default MessageIcon;
