import React from "react";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export const FORM_CONFIGS = {
  text: {
    icon: (
      <NotesOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#CED4DA" }}
      />
    ),
    name: "Text Submission",
    desc: "If you'd like players to submit a specific text information.",
    placeholder: "Enter Text Input Label",
    initValue: [{ value: "", type: "text" }],
    index: 0,
    type: "text",
  },
  image: {
    icon: (
      <ImageOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#CED4DA" }}
      />
    ),
    name: "Image Submission",
    desc: "If you'd like players to submit a photo as evidence of task completion.",
    placeholder: "Enter Image Upload Label",
    initValue: [{ value: "", type: "image" }],
    index: 1,
    type: "image",
  },
  email: {
    icon: (
      <EmailOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#CED4DA" }}
      />
    ),
    name: "Email Submission",
    desc: "Participants can report their results via email, making it easy to track accomplishments.",
    placeholder: "Enter Email Input",
    initValue: [{ value: "", type: "email" }],
    index: 2,
    type: "email",
  },
  link: {
    icon: (
      <InsertLinkIcon
        sx={{ height: "1.25rem", width: "auto", color: "#CED4DA" }}
      />
    ),
    name: "Link Submission",
    desc: "Allow participants to share URLs as proof of meeting specific goals.",
    placeholder: "Enter Link Submission Label",
    initValue: [{ value: "", type: "link" }],
    index: 3,
    type: "link",
  },
  custom: {
    icon: (
      <DynamicFormOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#CED4DA" }}
      />
    ),
    name: "Form Submission",
    desc: "For users to fill out custom forms, questionnaires, or surveys to record their achievements.",
    initValue: [],
    index: 4,
    type: "text",
  },
};

export const FORM_OPTIONS = [
  FORM_CONFIGS.text,
  FORM_CONFIGS.image,
  FORM_CONFIGS.custom,
];

export const FORM_OPTIONS_ANCHOR = [
  FORM_CONFIGS.text,
  FORM_CONFIGS.image,
  FORM_CONFIGS.email,
  FORM_CONFIGS.link,
  FORM_CONFIGS.custom,
];
