import React from "react";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import OutlinedCounterCard from "../../examples/Cards/CounterCards/OutlinedCounterCard";
import { Grid } from "@mui/material";
import CustomOutlineCard from "./CustomOutlineCard";
import ReferralSteps from "./ReferralSteps";

const ReferralProgram = ({code, challengeUrl, referralCount}) => {

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ gap: "1rem" }}
      >
        <SoftBox>
          <SoftTypography variant="h4" sx={{ marginBottom: "0.75rem" }}>
            Referral Program
          </SoftTypography>
          <SoftTypography variant="body2" color="text">
            Track and find all the details about our referral program, your
            stats and revenues.
          </SoftTypography>
        </SoftBox>
        <SoftButton
          color="primary"
          variant="outlined"
          target="_blank"
          href={`${process.env.REACT_APP_FRONTEND}/arena/${challengeUrl}`}
          sx={{
            textTransform: "none",
            padding: "0.5rem 0.75rem",
            minHeight: "2rem",
          }}
          disabled={!challengeUrl}
        >
          ENTER THE ARENA
        </SoftButton>
      </SoftBox>

      <SoftBox sx={{ marginY: "2rem" }}>
        <Grid container spacing={4}>
          <Grid item mini={12} md={6}>
            <OutlinedCounterCard
              count={0}
              prefix="$"
              title="earnings"
              color="primary"
            />
          </Grid>
          <Grid item mini={12} md={6}>
            <OutlinedCounterCard
              count={referralCount ?? 0}
              title="referrals"
              color="primary"
            />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox sx={{ marginY: "2rem" }}>
        <SoftBox sx={{ marginY: "2rem" }}>
          <Grid container spacing={4}>
            <Grid item mini={12} md={6}>
              <SoftBox marginBottom="2rem">
                <SoftTypography color="dark" variant="h5">
                  Referral Code
                </SoftTypography>
                <SoftTypography color="text">
                  Copy the code bellow to your registered provider.
                </SoftTypography>
              </SoftBox>
              <CustomOutlineCard code={code} challengeUrl={challengeUrl}/>
            </Grid>

            <Grid item mini={12} md={6}>
              <SoftBox marginBottom="2rem">
                <SoftTypography color="dark" variant="h5">
                  Challenge your Followers
                </SoftTypography>
                <SoftTypography color="text">
                  Integrate your referral code using 3 easy steps.
                </SoftTypography>
              </SoftBox>
              <ReferralSteps />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default ReferralProgram;
