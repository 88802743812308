import * as Yup from "yup";

// ["progressive", "anchor", "display"];
const CustomKpiEditMetricSchema = [
  Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(24, "Metric name must be at most 24 characters"),
    target: Yup.number()
      .required("Target is required")
      .typeError("Not a valid number")
      .min(0.01, "Target must be a positive number"),
    pointScale: Yup.number()
      .required("Points is required")
      .typeError("Not a valid number")
      .min(0, "Point scale must be zero or a positive number"),
  }),
  Yup.object().shape({
    kind: Yup.string().required(),
    name: Yup.string()
      .required("Name is required")
      .max(24, "Metric name must be at most 24 characters"),
    unit: Yup.number()
      .required("Unit is required")
      .when("kind", {
        is: "%",  // Anchor percentage type
        then: Yup.number()
          .typeError("Not a valid number")
          .min(0.01, "Value must be greater than 0%"),
        otherwise: Yup.number() // Anchor dollar|item type
          .typeError("Not a valid number")
          .min(0.01, "Unit must be a positive number"),
      }),
    target: Yup.number()
      .required("Target is required")
      .when("kind", {
        is: "%",  // Anchor percentage type
        then: Yup.number()
          .typeError("Not a valid number")
          .min(0, "Value must be 0 - 100")
          .max(100, "Value must be less or equal to 100"),
        otherwise: Yup.number() // Anchor dollar|item type
          .typeError("Not a valid number")
          .min(0, "Value must be zero or a positive number")
      }),
    pointScale: Yup.number()  // This translates to "point" in outgoing payload
      .required("Points is required")
      .typeError("Not a valid number")
      .min(0, "Value must be zero or a positive number"),
  }),
  Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(24, "Metric name must be at most 24 characters"),
  }),
];

export default CustomKpiEditMetricSchema;
