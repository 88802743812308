import SHIRT_TEXTURE from "../../img/avatars/outfits/WhiteShirtTexture.png";
import OVERALL_MALE_TEXTURE from "../../img/avatars/outfits/WhiteOverallTopTexture_x512_masculine.jpg";
import OVERALL_FEMALE_TEXTURE from "../../img/avatars/outfits/WhiteOverallTopTexture_x512_feminine.jpg";

export const TEXTURE_DIMENSION = 1024;
export const OVERALL_MODEL = (gender) => `https://gmb-public.s3.us-east-2.amazonaws.com/avatars/WhiteOverall_${gender || 'masculine'}.glb`;

const OVERALL_MALE = {
  texture:  OVERALL_MALE_TEXTURE,
  branding: [
    {
      wScale: 90,
      x: 284,
      y: 102
    },
    {
      wScale: 45,
      x: 55,
      y: 105
    }, 
  ]
}

const OVERALL_FEMALE = {
  texture:  OVERALL_FEMALE_TEXTURE,
  branding: [
    {
      wScale: 40,
      x: 65,
      y: 120
    },
    {
      wScale: 80,
      x: 285,
      y: 125
    }, 
  ]
}
export const OVERALL_OUTFIT = (gender) => gender === 'masculine' ? OVERALL_MALE: OVERALL_FEMALE;

export const SHIRT = {
  texture: SHIRT_TEXTURE,
  branding: [
    {
      wScale: 120,
      x: 80,
      y: 196
    },
  ]
}


