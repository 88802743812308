/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// Soft UI Dashboard PRO React icons

import LogoutIcon from "@mui/icons-material/Logout";
import TrendingUp from "@mui/icons-material/TrendingUp";
import CastleIcon from "@mui/icons-material/Castle";
import Grid3x3OutlinedIcon from "@mui/icons-material/Grid3x3Outlined";
import DashboardIcon from "../components/NavIcons/DashboardIcon";
import HubIcon from "../components/NavIcons/HubIcon";
import ScorecardIcon from "../components/NavIcons/ScorecardIcon";
import ClaimsIcon from "../components/NavIcons/ClaimsIcon";
import FeedIcon from "../components/NavIcons/FeedIcon";
import ChallengeArenaIcon from "../components/NavIcons/ChallengeArenaIcon";
import MessageIcon from "../components/NavIcons/MessageIcon";

const sidenavRoutes = [
  {
    type: "collapse",
    name: "Player Dashboard",
    key: "home",
    route: "/home",
    icon: <DashboardIcon />,
    noCollapse: true,
  },
  { type: "title", title: "Company", key: "company-pages" },
  {
    type: "collapse",
    name: "Hub",
    key: "hub",
    route: "/hub",
    icon: <HubIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Scorecard",
    key: "scorecard",
    route: "/scorecard",
    icon: <ScorecardIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Claims",
    key: "claims",
    route: "/claims",
    icon: <ClaimsIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Level Up",
    key: "levelup",
    route: "/levelup",
    icon: <TrendingUp />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "title",
    title: "Activities",
    key: "activity-pages",
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Feed",
    key: "feed",
    route: "/feed",
    icon: <FeedIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    route: "/messages",
    icon: <MessageIcon />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Challenge Arena",
    key: "challenge-arena",
    route: "/challenge-arena",
    icon: <ChallengeArenaIcon />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Manage Widgets",
    key: "manage-widgets",
    route: "/widgets",
    icon: <Grid3x3OutlinedIcon />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "title",
    title: "System",
    key: "system-pages",
    affiliateOnly: true,
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Affiliate Hub",
    key: "affiliates",
    route: "/affiliates",
    icon: <CastleIcon />,
    noCollapse: true,
    primeOnly: true,
    affiliateOnly: true,
  },
];

export default sidenavRoutes;
