import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Hub from "@mui/icons-material/Hub";
import SettingsIcon from "@mui/icons-material/Settings";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LyricsIcon from "@mui/icons-material/Lyrics";
import GroupIcon from "@mui/icons-material/Group";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ViewListIcon from "@mui/icons-material/ViewList";
import CloseIcon from "@mui/icons-material/Close";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AddTaskIcon from "@mui/icons-material/AddTask";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PhoneIcon from "@mui/icons-material/Phone";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import FenceIcon from '@mui/icons-material/Fence';

export default {
  /* KPI Icons
   *  Must match actual KPI title; enclose in quotes for titles with spaces
   */
  revenue: TrendingUpIcon,
  upsell: AttachMoneyIcon,
  review: StarOutlinedIcon,
  callback: PhoneCallbackIcon,
  callbacks: PhoneCallbackIcon,
  "mx plan": ShoppingCartOutlinedIcon,
  "sq feet": SquareFootIcon,
  "square feet": SquareFootIcon,
  "sq foot": SquareFootIcon,
  "square foot": SquareFootIcon,
  area: SquareFootIcon,
  "service area": SquareFootIcon,

  "total production": HomeRepairServiceIcon,
  production: HomeRepairServiceIcon,
  "service item": HomeRepairServiceIcon,
  service: HomeRepairServiceIcon,

  cancellations: BackspaceIcon,
  "canceled jobs": BackspaceIcon,

  calls: PhoneIcon,
  "answered calls": PhoneIcon,

  reply: QuickreplyIcon,
  replies: QuickreplyIcon,
  "social responses": QuickreplyIcon,

  schedule: CalendarMonthIcon,
  "schedule capacity": CalendarMonthIcon,

  balance: AccountBalanceIcon,
  "accounts receivable": AccountBalanceIcon,

  closed: HandshakeIcon,
  "close rate": HandshakeIcon,
  "closed rate": HandshakeIcon,

  ticket: ConfirmationNumberIcon,
  "average ticket": ConfirmationNumberIcon,

  lead: PersonPinIcon,
  leads: PersonPinIcon,
  "cold lead": PersonPinIcon,
  "cold leads": PersonPinIcon,

  "control point": ControlPointIcon,
  "yard signs": FenceIcon,
  "yard sign": FenceIcon,

  /* Leaderboard Icons */
  rankup: KeyboardDoubleArrowUpIcon,
  rocket: RocketLaunchIcon,
  leaderboard: LeaderboardIcon,
  star: StarIcon,
  level: WhatshotIcon,

  /* Pages Icons */
  feed: NewspaperIcon,
  hub: Hub,
  settings: SettingsIcon,
  kpi: DisplaySettingsIcon,
  users: GroupIcon,
  score: TrendingUpIcon,
  claim: LocalActivityIcon,
  scorekeeper: AddTaskIcon,

  /* General Icons */
  play: PlayArrowIcon,
  arrowback: ArrowBackRoundedIcon,
  sound: LyricsIcon,
  add: AddCircleOutlineIcon,
  list: ViewListIcon,
  exit: CloseIcon,
  default: SportsEsportsIcon,
  broken: BrokenImageIcon,
  feedback: FeedbackIcon,
  accountsettings: ManageAccountsIcon,
  calendar: CalendarMonthOutlinedIcon,
};
