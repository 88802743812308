/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
import React from "react";
import { UseIsAdmin } from "../../hooks/auth";

import ItemBox from "./ItemBox";
import AddKpiBox from "./AddKpiBox";
import PopUpModal from "./PopUp";
import ConfettiFireworks from "../ParticleEffects/ConfettiFireworks";
import Grid from "@mui/material/Grid";
import SoftBox from "../SoftBox";
import { ForkLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const YetiKpiBox = ({ kpis }) => {
  const navigate = useNavigate();
  const [fxComponent, setFxComponent] = React.useState(null);
  const isAdmin = UseIsAdmin();

  const popConfetti = (kpi) => {
    if (kpi.direction > 0) {
      setFxComponent(<ConfettiFireworks count={5} />);
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100%",
        width: "100%",
        rowGap: 2,
        "& > div:nth-child(odd)": {
          paddingRight: { mini: 0, md: 1 },
        },
        "& > div:nth-child(even)": {
          paddingLeft: { mini: 0, md: 1 },
        },
      }}
    >
      {kpis.map((kpi) => (
        <Grid
          key={kpi._id}
          item
          xs={12}
          md={6}
          sx={{
            flexBasis: "100%",
            maxHeight: "50%",
            maxWidth: "100%",
          }}
        >
          <PopUpModal
            kpi={kpi}
            onSave={()=>popConfetti(kpi)}
            onOpen={() => setFxComponent(null)}
          >
            <ItemBox kpi={kpi} amount={kpi.amount} points={kpi.points} />
          </PopUpModal>
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          flexBasis: "100%",
          maxHeight: "50%",
          maxWidth: "100%",
        }}
      >
        <SoftBox
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          borderRadius: "1vw",
          background: "#FCFCFD",
          textAlign: "center",
          border: "2px dotted #EAECF0",
          cursor: "pointer"
        }}
        onClick={() => navigate("/gameboards/workspace-kpi/custom-metric-types")}
        >
          <SoftBox
            lineHeight={1}
            className="h-full"
            sx={{ px: "8%", py: "5%" }}
          >
          <svg className="mt-2 text-center mx-auto" width="71" height="28" viewBox="0 0 71 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dd_4643_3702)">
          <g filter="url(#filter1_dd_4643_3702)">
          <path d="M57.3793 2H13.6207C12.7256 2 12 2.72561 12 3.62069V11.7241C12 12.6192 12.7256 13.3448 13.6207 13.3448H57.3793C58.2744 13.3448 59 12.6192 59 11.7241V3.62069C59 2.72561 58.2744 2 57.3793 2Z" fill="white"/>
          </g>
          <path d="M34.3658 4.5918H25.9382C25.4012 4.5918 24.9658 5.02716 24.9658 5.56421C24.9658 6.10126 25.4012 6.53662 25.9382 6.53662H34.3658C34.9029 6.53662 35.3382 6.10126 35.3382 5.56421C35.3382 5.02716 34.9029 4.5918 34.3658 4.5918Z" fill="url(#paint0_linear_4643_3702)"/>
          <path d="M40.2001 8.80566H25.938C25.4009 8.80566 24.9656 9.24103 24.9656 9.77808C24.9656 10.3151 25.4009 10.7505 25.938 10.7505H40.2001C40.7371 10.7505 41.1725 10.3151 41.1725 9.77808C41.1725 9.24103 40.7371 8.80566 40.2001 8.80566Z" fill="url(#paint1_linear_4643_3702)" fillOpacity="0.1"/>
          </g>
          <defs>
          <filter id="filter0_dd_4643_3702" x="0" y="2" width="71" height="35.3447" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_4643_3702"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4643_3702"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_4643_3702"/>
          <feOffset dy="12"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_4643_3702" result="effect2_dropShadow_4643_3702"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4643_3702" result="shape"/>
          </filter>
          <filter id="filter1_dd_4643_3702" x="10.4103" y="0.94021" width="50.1794" height="14.5241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.529895"/>
          <feGaussianBlur stdDeviation="0.529895"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4643_3702"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.529895"/>
          <feGaussianBlur stdDeviation="0.794843"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_4643_3702" result="effect2_dropShadow_4643_3702"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4643_3702" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_4643_3702" x1="33.3884" y1="12.3033" x2="23.49" y2="8.95643" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7928CA"/>
          <stop offset="1" stopColor="#FF0080"/>
          </linearGradient>
          <linearGradient id="paint1_linear_4643_3702" x1="38.1258" y1="16.5172" x2="24.6522" y2="9.39879" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7928CA"/>
          <stop offset="1" stopColor="#FF0080"/>
          </linearGradient>
          </defs>
          </svg>
          <h5 style={{
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "18px",
              }}>Add Metrics</h5>
            <p style={{fontSize: "11.32px",lineHeight: "14px",}}>You have ({5 - kpis.length}) more metrics</p>
          </SoftBox>
        </SoftBox>
      </Grid>
      {fxComponent}
    </Grid>
  );
};

export default YetiKpiBox;
