import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Dialog, Grid } from "@mui/material";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import SoftSelect from "../../components/SoftSelect";
import ChallengeBox from "../../components/ChallengeArena/ChallengeBox";
import CreateChallenge from "../../components/ChallengeArena/CreateChallenge";
import ChallengeConfirmation from "../../components/ChallengeArena/ChallengeConfirmation";
import { GET_ONE_VS_ONE_CHALLENGE } from "../../api/challenge";
import { UseCompanyId, UseUserDetails, UseUserId } from "../../hooks/auth";
import LoadingScreen from "../../components/LoadingScreen";
import GoogleBusinessModal from "../../components/ChallengeArena/GoogleBusinessModal";
import {
  GET_APP_INTEGRATIONS,
  GET_COMPANY_INTEGRATIONS,
  GET_KPI_INTEGRATIONS_BY_DATASET,
} from "../../api/integration";
import { GOOGLE_BUSINESS } from "../../constants/integrations";
import { updateWorkspaceKpi } from "../../redux/kpi/actions";
import { UseSelectedWorkspace } from "../../hooks/company";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const challengeOptions = [
  {
    value: "all",
    label: "All challenges",
  },
  { value: "current", label: "Current challenges" },
  { value: "new", label: "New challenges" },
];

const filters = [
  {
    key: "my-challenges",
    label: "My Challenges",
    icon: (
      <ContactPageIcon
        sx={{
          marginRight: "0.25rem",
          fontSize: { mini: "1rem !important", sm: "1.5rem !important" },
        }}
      />
    ),
  },
  {
    key: "all-challenges",
    label: "Browse Challenges",
    icon: (
      <SearchIcon
        sx={{
          marginRight: "0.25rem",
          fontSize: { mini: "1rem !important", sm: "1.5rem !important" },
        }}
      />
    ),
  },
  {
    key: "completed-challenges",
    label: "Past Challenges",
    icon: (
      <AssignmentTurnedInIcon
        sx={{
          marginRight: "0.25rem",
          fontSize: { mini: "1rem !important", sm: "1.5rem !important" },
        }}
      />
    ),
  },
];

const ChallengeArena = () => {
  const [sortChallenge, setSortChallenge] = useState(challengeOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [openGoogleModal, setOpenGoogleModal] = useState(false);
  const [fetch, setFetch] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [allChallenges, setAllChallenges] = useState([]);
  const [keys, setKeys] = useState([]);

  const userId = UseUserId();
  const userDetails = UseUserDetails();
  const dispatch = useDispatch();
  const { _id: groupId } = UseSelectedWorkspace();
  const xsBp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    userID: userId,
    allCompleted: false,
    allChallengeFilter: false,
  });

  const handleSortChallenge = (sort) => {
    setSortChallenge(sort);
  };

  const fetchCompanyIntegrations = async () => {
    try {
      let flag = 0;
      let integrationId = "";
      const { data } = await GET_COMPANY_INTEGRATIONS();
      const { data: appData } = await GET_APP_INTEGRATIONS();
      const { data: integData } = await GET_KPI_INTEGRATIONS_BY_DATASET();

      data.forEach((integrations) => {
        if (integrations.code === GOOGLE_BUSINESS) {
          flag = 1;
        }
      });
      appData.forEach((integrations) => {
        if (integrations.code === GOOGLE_BUSINESS) {
          integrationId = integrations._id;
        }
      });

      if (flag === 0) {
        setOpenGoogleModal(true);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (userDetails.isAdmin) {
      fetchCompanyIntegrations();
    }
  }, []);

  const triggerCloseBusinessModal = () => {
    setOpenGoogleModal(false);
  };

  const handleOpen = () => {
    navigate("/challenge-arena/create-challenge");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirm = () => {
    setOpen(false);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const fetchChallenges = async () => {
    setLoading(true);
    try {
      const { data } = await GET_ONE_VS_ONE_CHALLENGE({
        page,
        companyId: userDetails.companyId,
        ...filter,
        playerId: userId,
      });

      if (data) {
        setPage(data.page);
        setHasNextPage(data.hasNextPage);
        setHasPrevPage(data.hasPrevPage);
        setTotalPages(data.totalPages);
        setAllChallenges(data.docs);
        setKeys(Object.keys(data.docs));
      }
    } catch (error) {
      console.log("Challenge Arena Error", error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChallenges();
  }, [page, filter]);

  const triggerUpdateFilter = async (index) => {
    setSelectedFilter(index);
    if (index === 0) {
      setFilter({
        userID: userId,
        allCompleted: false,
        allChallengeFilter: false,
      });
    } else if (index === 1) {
      setFilter({
        allChallengeFilter: true,
        userID: null,
        allCompleted: false,
      });
    } else {
      setFilter({
        allCompleted: true,
        allChallengeFilter: false,
        userID: null,
      });
    }
  };

  const fetchChallengesWithoutLoading = async () => {
    try {
      const { data } = await GET_ONE_VS_ONE_CHALLENGE({
        page,
        userId,
        companyId: userDetails.companyId,
        ...filter,
        playerId: userId,
      });

      if (data) {
        setPage(data.page);
        setHasNextPage(data.hasNextPage);
        setHasPrevPage(data.hasPrevPage);
        setTotalPages(data.totalPages);
        setAllChallenges(data.docs);
        setKeys(Object.keys(data.docs));
      }
    } catch (error) {
      console.log("Challenge Arena Error", error.response);
    }
  };

  useEffect(() => {
    if (fetch !== 0) {
      fetchChallengesWithoutLoading();
    }
  }, [fetch]);

  return (
    <>
      {/* {userDetails.isAdmin ? (
        <Dialog
          open={openGoogleModal}
          onClose={triggerCloseBusinessModal}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              background:
                "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
              boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
              backdropFilter: "blur(18px)",
            },
          }}
        >
          <GoogleBusinessModal
            triggerCloseBusinessModal={triggerCloseBusinessModal}
          />
        </Dialog>
      ) : null} */}

      <SoftBox
        sx={{
          height: { mini: "72px", xs: "58px", md: "60px" },
        }}
      />
      {/* Page Header */}
      <SoftBox
        sx={{
          padding: "1.1rem 1.5rem",
          background: (theme) => theme.palette.primary.main,
          borderRadius: "20px",
          marginY: { mini: "0rem", xxs: "1rem", sm: "1.5rem" },
          marginX: { mini: "1rem", sm: "1.5rem" },
        }}
      >
        <SoftTypography
          variant="h2"
          color="white"
          sx={{ marginBottom: "0.25rem", fontSize: "23px" }}
        >
          Challenge Arena
        </SoftTypography>
        <SoftTypography
          variant="body2"
          sx={{ color: "rgba(255, 255, 255, 0.6)" }}
        >
          Challenge and get rewarded
        </SoftTypography>
      </SoftBox>

      {/* Page Content */}
      <SoftBox
        sx={{
          padding: "1.1rem 1.5rem",
          background: "white",
          borderRadius: "8px",
          marginY: { mini: "2rem", sm: "1.5rem" },
          marginX: { mini: "1rem", sm: "1.5rem" },
          display: "flex",
          justifyContent: { mini: "center", sm: "space-between" },
          alignItems: { mini: "start", sm: "center" },
          flexDirection: { mini: "column", sm: "row" },
          gap: "1rem",
        }}
      >
        <SoftBox>
          <SoftTypography
            variant="subtitle2"
            sx={{ marginBottom: "0.25rem", color: "#051923" }}
          >
            Choose Your Challenge and Let the Games Begin!
          </SoftTypography>
          <SoftTypography
            variant="button"
            sx={{ color: "#6C757D", fontWeight: 400 }}
          >
            Welcome to the Challenge Arena! Test your skills and compete with
            your colleagues in various exciting challenges.
          </SoftTypography>
        </SoftBox>
        <SoftButton
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            padding: "13px 32px",
          }}
          onClick={handleOpen}
        >
          Create a challenge
        </SoftButton>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              background:
                "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
              boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
              backdropFilter: "blur(18px)",
            },
          }}
        >
          <CreateChallenge
            handleOpen={handleOpen}
            open={open}
            handleClose={handleClose}
            handleOpenConfirm={handleOpenConfirm}
            setFetch={setFetch}
            fetch={fetch}
          />
        </Dialog>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              background:
                "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
              boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
              backdropFilter: "blur(15px)",
              padding: { mini: "1.5rem", md: "2rem" },
            },
          }}
        >
          <ChallengeConfirmation />
        </Dialog>
      </SoftBox>

      <SoftBox
        sx={{
          background: "white",
          borderRadius: "8px",
          paddingY: "1rem",
          paddingX: "0.25rem",
          display: { mini: "flex", sm: "none" },
          justifyContent: "center",
          margin: "0.5rem",
          alignItems: "center",
        }}
      >
        {filters.map((item, index) => (
          <SoftButton
            key={item.key}
            variant={selectedFilter === index ? "contained" : "text"}
            color={selectedFilter === index ? "primary" : null}
            size={xsBp ? "medium" : "small"}
            sx={{
              borderColor: selectedFilter === index ? "primary" : "#CED4DA",
              color:
                selectedFilter === index
                  ? "#FFF !important"
                  : "#6C757D !important",
              textTransform: "none",
              padding: "0.25rem 0.5rem",
              fontSize: {
                mini: "0.65rem !important",
                xxs: "0.75rem !important",
                xs: "0.875rem !important",
              },
              "&:hover": {
                borderColor: selectedFilter === index ? "primary" : "#CED4DA",
              },
              "&:focus:not(:hover)": {
                borderColor: selectedFilter === index ? "primary" : "#CED4DA",
              },
            }}
            onClick={() => triggerUpdateFilter(index)}
          >
            {item.icon}
            {item.label}
          </SoftButton>
        ))}
      </SoftBox>

      <SoftBox
        sx={{
          background: "white",
          borderRadius: "8px",
          margin: { mini: "1rem", sm: "1.5rem" },
          paddingY: "2rem",
        }}
      >
        <SoftBox
          sx={{
            background: "rgba(248, 249, 250, 1)",
            paddingY: "1rem",
            paddingX: "1.5rem",
            display: { mini: "none", sm: "flex" },
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {filters.map((item, index) => (
            <SoftButton
              key={item.key}
              variant={selectedFilter === index ? "contained" : "text"}
              color={selectedFilter === index ? "primary" : null}
              size={xsBp ? "medium" : "small"}
              sx={{
                borderColor: selectedFilter === index ? "primary" : "#CED4DA",
                color:
                  selectedFilter === index
                    ? "#FFF !important"
                    : "#6C757D !important",
                textTransform: "none",
                padding: { sm: "9px 28px", md: "13px 32px" },
                "&:hover": {
                  borderColor: selectedFilter === index ? "primary" : "#CED4DA",
                },
                "&:focus:not(:hover)": {
                  borderColor: selectedFilter === index ? "primary" : "#CED4DA",
                },
              }}
              onClick={() => triggerUpdateFilter(index)}
            >
              {item.icon}
              {item.label}
            </SoftButton>
          ))}
        </SoftBox>
        {/* // Commented out this block since it has no clear functionality atm
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
            // Make the justifyContent - space between if soft select under is enabled/commented out
            justifyContent: "end",
          }}
        >
          <SoftSelect
            name="challenge"
            id="challenge"
            options={challengeOptions}
            defaultValue={{
              value: sortChallenge.value,
              label: sortChallenge.label,
            }}
            onChange={handleSortChallenge}
          />
          <SoftButton
            variant="outlined"
            sx={{
              borderColor: "#CED4DA",
              color: "#6C757D !important",
              textTransform: "none",
              padding: "13px 32px",
              "&:hover": { borderColor: "#CED4DA" },
              "&:focus:not(:hover)": {
                borderColor: "#CED4DA",
              },
            }}
          >
            View Past challenges
          </SoftButton>
        </SoftBox> */}

        <Divider
          sx={{ marginY: "2rem", display: { mini: "none", sm: "auto" } }}
        />

        <SoftBox sx={{ paddingX: "1.5rem" }}>
          {loading ? (
            <LoadingScreen />
          ) : (
            <SoftBox
              sx={{
                borderRadius: "8px",
                marginBottom: "10px",
              }}
            >
              {keys.map((key) => (
                <SoftBox key={key}>
                  <SoftTypography
                    sx={{
                      color: "rgba(52, 58, 64, 1)",
                      fontWeight: "400",
                      fontSize: "16px",
                      marginBottom: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {key}
                  </SoftTypography>
                  <Grid container spacing={3}>
                    {allChallenges[key].map((challenge) => (
                      <Grid
                        item
                        mini={12}
                        lg={4}
                        xxl={3}
                        key={challenge._id}
                        className="flex justify-center"
                      >
                        <ChallengeBox
                          challenge={challenge}
                          setFetch={setFetch}
                          fetch={fetch}
                          handleOpenConfirm={handleOpenConfirm}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </SoftBox>
              ))}
            </SoftBox>
          )}
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SoftTypography sx={{ color: "#6C757D", fontSize: "14px" }}>
              Page{" "}
              <span
                style={{
                  border: "1px solid #6C757D",
                  padding: "5px 10px",
                  borderRadius: "4px",
                }}
              >
                {page}
              </span>{" "}
              of {totalPages}
            </SoftTypography>
            <SoftButton
              variant="outlined"
              size="small"
              disabled={!hasPrevPage}
              onClick={() => setPage(page - 1)}
              sx={{
                marginRight: "10px",
                marginLeft: "20px",
                border: "0.7px solid #6C757D !important",
                color: "#051923 !important",
              }}
            >
              Prev
            </SoftButton>
            <SoftButton
              variant="outlined"
              size="small"
              disabled={!hasNextPage}
              onClick={() => setPage(page + 1)}
              sx={{
                border: "0.7px solid #6C757D !important",
                color: "#051923 !important",
              }}
            >
              Next
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default ChallengeArena;
