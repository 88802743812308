import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Link,
  Stack,
  Switch,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { loginUser, removeError } from "../../redux/auth/actions";
import {
  UseAuth,
  UseAuthErrorMessage,
  UseUserDetails,
  UseIsAdmin,
  UseLoading,
} from "../../hooks/auth";
import { useNavigate } from "react-router-dom";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStyles } from "./loginStyles";
import {
  UseIsCompanyUpToDate,
  UseSubscriptionLevel,
} from "../../hooks/company";

const LoginForm = ({ authMode, coldLeads = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginStatus = UseAuth();
  const companyStatus = UseIsCompanyUpToDate();
  const authLoading = UseLoading();
  const user = UseUserDetails();
  const isAdmin = UseIsAdmin();
  const errorMessage = UseAuthErrorMessage();
  const navigate = useNavigate();
  const isSubscribed = UseSubscriptionLevel();
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [visible, setVisibility] = useState(false);
  const inputType = visible ? "text" : "password";
  const allowedCompanyViewSubscription = 2;

  const handleLogin = () => {
    credentials.username = credentials.username.trim().toLowerCase();
    setIsLoading(true);
    dispatch(loginUser(credentials));
  };

  const handleUsernameChange = (e) => {
    setCredentials({
      ...credentials,
      username: e.target.value,
    });
    dispatch(removeError());
  };

  const handlePasswordChange = (e) => {
    setCredentials({
      ...credentials,
      password: e.target.value,
    });
    dispatch(removeError());
  };

  useEffect(() => {
    dispatch(removeError());
  }, [dispatch]);

  useEffect(() => {
    if (loginStatus && companyStatus && user && !authMode) {
      // No redirects on auth mode
      if (user.companyId) {
        let adminOrPlayerHomeRoute = "/home";

        // Redirect to Admin dashboard only for admin accounts with subscription lvl >= 2
        if (isAdmin && isSubscribed >= allowedCompanyViewSubscription) {
          adminOrPlayerHomeRoute = "/company-view";
        }

        navigate(adminOrPlayerHomeRoute);
      } else {
        navigate(`/company`);
      }
    }

    if (window.ReactNativeWebView && user) {
      const data = {
        ...user,
        login_state: "success",
      };

      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }, [loginStatus, companyStatus, user, authMode, navigate, isSubscribed]);

  useEffect(() => {
    if (!authLoading) {
      setIsLoading(false);
    }
  }, [authLoading]);

  return (
    <Box>
      <p className={classes.formText}>
        {authMode
          ? "Login to your account to authorize access"
          : "Enter your username and password to sign in"}
      </p>
      <Box className="w-full">
        <Box component="form" noValidate autoComplete="off">
          <div className={classes.inputWrapper}>
            <input
              name="username"
              placeholder="Username"
              value={credentials.username}
              onChange={(e) => handleUsernameChange(e)}
            />
          </div>

          <div className={classes.inputWrapper}>
            <input
              name="password"
              type={inputType}
              placeholder="Password"
              value={credentials.password}
              onChange={(e) => handlePasswordChange(e)}
            />
            {visible ? (
              <VisibilityOffIcon
                className={classes.visibilityIcon}
                onClick={() => setVisibility((visibility) => !visibility)}
              />
            ) : (
              <VisibilityIcon
                className={classes.visibilityIcon}
                onClick={() => setVisibility((visibility) => !visibility)}
              />
            )}
          </div>
          {errorMessage && (
            <p className="text-orange-600 italic">{errorMessage}</p>
          )}
          {!coldLeads ? (
            <Stack
              direction={{ mini: "column", sm: "row" }}
              className="flex justify-between mt-4"
            >
              <Link
                href="/forgot-password"
                sx={{ textDecoration: "none", fontSize: "1rem" }}
              >
                Forgot Password?
              </Link>
              {!authMode && (
                <FormControlLabel
                  className={classes.toggleStyle}
                  control={<Switch defaultChecked />}
                  label="Remember Me"
                />
              )}
            </Stack>
          ) : null}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button onClick={handleLogin} className={classes.buttonStyle}>
            {!isLoading ? (
              "SIGN IN"
            ) : (
              <CircularProgress style={{ color: "white " }} size={30} />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
