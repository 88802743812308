import { types } from "./types";

export const toggleViewAsUser = (payload) => ({
  type: types.TOGGLE_VIEW_AS_USER,
  payload,
});


export const getSocket = (payload) => ({
  type: types.GET_SOCKET_SUCCESS,
  payload,
});

export const getPageNumber = (payload) => ({
  type: types.GET_PAGE_NUMBER,
  payload,
});
