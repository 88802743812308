import React, { useState, useMemo, useEffect } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Gameboard from "../../components/gameboard";
import KpiTreemap from "./KpiTreemap";
import KpiHistory from "./KpiHistory";
import LoadingScreen from "../../components/LoadingScreen";

import { UseHolidays, UseCompanyWorkspaces } from "../../hooks/company";
import { UseCompanyKpis, UseTopPlayers, UseGroupKpis } from "../../hooks/kpi";
import { UseIsAdmin } from "../../hooks/auth";
import { GET_SETTING } from "../../api/settings";
import { GET_ALL_COMPANY_SCORES } from "../../api/kpi";

import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftSelect from "../../components/SoftSelect";
import SoftTypography from "../../components/SoftTypography";

import LiveLeaderboardComponent from "../../components/LeaderBoard/LiveLeaderboard/LiveLeaderboard";

const CompanyView = () => {
  const workspaces = UseCompanyWorkspaces(true);
  const [currentWorksace, setCurrentWorkspace] = useState(workspaces?.[0]?._id);
  const workspaceOptions = useMemo(
    () => workspaces.map((w) => ({ value: w._id, label: w.name, image: w.image })),
    [workspaces],
  );
  const topPlayers = UseTopPlayers("today");
  const isAdmin = UseIsAdmin();
  const yetiKPIs = UseGroupKpis();
  const [ad, setAd] = useState(null);
  const [topList, setTopList] = useState([]);
  const [groupKpis, setGroupKpis] = useState([]);

  // Score calculations is completed here so multiple child components can share it
  const [scoreData, setScoreData] = useState(null);
  const [scores, setScores] = useState(null);
  const [companyTotal, setCompanyTotal] = useState(null);
  const companyKpis = UseCompanyKpis();
  const today = moment();
  const holidays = UseHolidays(today.format("YYYY-MM"));

  const breakpointTheme = useTheme();
  const isBigScreen = useMediaQuery(breakpointTheme.breakpoints.up("lg"));

  useEffect(() => {
    GET_SETTING("company_ad").then(({ data: { value } }) => setAd(value));

    GET_ALL_COMPANY_SCORES().then(({ data }) => {
      if (Object.values(data.scores).length) {
        setScoreData(data.scores);
      }
    });
  }, []);

  useEffect(() => {
    if (!scoreData) return;
    if (workspaces?.length <= 0) return;
    if (!companyKpis || Object.values(companyKpis).length <= 0) return;

    const workspaceList = [...workspaces];
    const scoreSummary = [];

    workspaceList.forEach((workspace) => {
      const currentKpis = [];
      // Acquire specific workspace KPIs
      const { _id, name, parentId } = workspace;
      companyKpis[_id]?.forEach((kpi) => {
        currentKpis.push({
          ...kpi,
          workspace: {
            id: _id,
            name,
            parentId,
          },
        });
      });
      

      // Calculate current yeti days
      const dayCount = moment().daysInMonth();
      let yetiMonth = 0;
      for (let d = 0; d < dayCount; d++) {
        if (holidays.indexOf(d + 1) < 0) {
          const t = moment().startOf("month").add(d, "days");
          // no weekends
          if (t.day() > 0 && t.day() < 6) {
            if (t <= today) {
              yetiMonth += 1;
            }
          }
        }
      }

      const workspaceScores = currentKpis
        .map((kpi) => {
          // Map scores for treemap chart
          const score = scoreData[kpi._id];
          if (!score) return null;

          const target = kpi.target * (kpi.wholeMonth ? 1 : yetiMonth);
          const gap = (score - target) * kpi.direction;
          const ratio = gap / target;

          return {
            score,
            ratio,
            kpiId: kpi._id,
            title: kpi.title,
            workspace: kpi.workspace,
            kind: kpi.kind,
          };
        })
        .filter((kpi) => !!kpi); // Filter KPIs that have scores

      if (workspaceScores.length) {
        scoreSummary.push({
          workspace: workspace?.name,
          workspaceId: workspace?._id,
          image: workspace?.image,
          scores: workspaceScores,
          total:
            workspaceScores.reduce((t, s) => t + (1 + s.ratio), 0) /
            workspaceScores.length,
        });
      }
    });

    // Get average performance across all workspaces with scores
    const average = Math.round(
      (scoreSummary.reduce((t, s) => t + s.total, 0) / scoreSummary.length) *
        100,
    );
    setCompanyTotal(average);
    setScores(scoreSummary);
  }, [companyKpis, workspaces, scoreData, holidays]);

  useEffect(() => {
    if (!scores) return;
    if (!topPlayers) return;
    if (!yetiKPIs) return;
    if (groupKpis.length > 0) return;

    const yetiPlayer = topPlayers.find(player => player.isYeti)
    if (!yetiPlayer) return;

    // Yeti score is always 100 (%)
    yetiPlayer.scores.total = 100;

    // Shape data for Leaderboard use using KPI ratio normalized to 100 (%)
    const companyTopList = [yetiPlayer, ...scores
      .filter(item => item.workspaceId !== undefined)
      .map((item) => {
        const leaderboardItem = {
          userId: item.workspaceId,
          scores: {
            total: item?.total * 100
          },
          image: item.image,
          firstName: item.workspace,
          lastName: ""
        }

        item.scores.forEach((kpiScore) => {
          leaderboardItem.scores[kpiScore.kpiId] = {
            amt: kpiScore.score,
            pts: (kpiScore.ratio + 1) * 100
          }
        })

        return leaderboardItem
    })]

    // Workspace and Yeti KPI info
    let companyGroupKpis = [];

    if (scores.length > 0) {
      companyGroupKpis = scores[0].scores.map(item => ({
        _id: item.kpiId,
        title: item.title,
        kind: item.kind
      }))
    }

    Object.keys(yetiPlayer.scores).forEach(key => {
      const yetiKpi = yetiKPIs.find(item => item._id === key)

      if (yetiKpi &&
        (yetiPlayer.scores[key].amt !== 0) &&
        (companyGroupKpis.findIndex(kpi => kpi._id === key) === -1)
      ) {
        companyGroupKpis.push({
          _id: key,
          title: yetiKpi.title,
          kind: yetiKpi.kind
        })
      }
    })

    setTopList(companyTopList.sort((a, b) => (b.scores.total - a.scores.total)))
    setGroupKpis(companyGroupKpis)
  }, [scores, topPlayers, groupKpis, yetiKPIs])

  useEffect(() => {
    if(workspaceOptions?.length) {
      setCurrentWorkspace(workspaceOptions[0].value)
    }
  }, [workspaceOptions]);

  return (
    <div className="w-full">
      <PageHeader
        headerText="Company View"
        subheaderText="All metrics from all workspaces"
      />
      <Grid
        container
        spacing={4}
        sx={{
          paddingX: { mini: "0.5rem", xxs: "1.5rem" },
        }}
      >
        <Grid item mini={12} lg={6}>
          <SoftTypography variant="h6" sx={{ color: "#6C757D", mb: 2 }}>
            Overall Company Performance
          </SoftTypography>
          <SoftBox
            className="flex justify-center items-center"
            sx={{ height: { xs: "350px", md: "420px", lg: "550px" } }}
          >
            {companyTotal !== null ? (
              <Gameboard
                mode="company"
                playerScore={companyTotal}
                yetiScore={100}
                maxPoint={companyTotal > 100 ? companyTotal : 100}
              />
            ) : (
              <LoadingScreen height="200px" logoHeight="200px" />
            )}
          </SoftBox>
        </Grid>
        <Grid item mini={12} lg={6} className="h-full">
          <div className="flex justify-between">
            <SoftTypography variant="h6" sx={{ color: "#6C757D", mb: 2 }}>
              Workspace Performance Overview
            </SoftTypography>
            {workspaceOptions?.length && (
              <SoftSelect
                size="small"
                options={workspaceOptions}
                defaultValue={workspaceOptions[0]}
                onChange={(option) => setCurrentWorkspace(option.value)}
              />
            )}
          </div>
          <SoftBox sx={{ height: { xs: "350px", md: "420px", lg: "550px" } }}>
            <KpiHistory currentWorkspace={currentWorksace} scores={scores} />
          </SoftBox>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        sx={{ mt: 1, mb: 6, paddingX: { mini: "0.5rem", xxs: "1.5rem" } }}
      >
        <Grid item mini={12} xs={7} sm={6} md={6} lg={7}>
          <KpiTreemap scoreData={scores} />
        </Grid>
        <Grid item mini={12} xs={6} lg={5}>
          <SoftBox
            shadow="sm"
            borderRadius="xxl"
            className="p-4 h-full w-full flex justify-center items-center"
            sx={{ height: { xs: "400px", md: "460px" } }}
          >
            {ad?.redirect ? (
              <a
                href={ad.redirect}
                target="_blank"
                rel="noreferrer"
                style={{ display: "block", height: "100%" }}
              >
                <img
                  src={ad.image}
                  alt="Company Ad"
                  className="!h-full !w-auto object-contain"
                />
              </a>
            ) : null}
          </SoftBox>
        </Grid>
        <Grid item mini={12}>
          <SoftBox
            sx={{
              paddingBottom: "50px",
              marginTop: "1.5rem",
            }}
          >
            <SoftBox
              shadow="sm"
              borderRadius="xl"
              sx={{
                width: "100%",
                minHeight: "25vh",
              }}
            >
              <LiveLeaderboardComponent
                topList={topList}
                allKpis={companyKpis}
                isAdmin={isAdmin}
                isViewOnly
                isPointsPercent
                bigMode={isBigScreen}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyView;
