import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./redux/reducer";
import rootSaga from "./redux/saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-key",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const saga = createSagaMiddleware();
// const store = compose(applyMiddleware(saga))(createStore)(persistedReducer);
const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(saga))
);

saga.run(rootSaga);

export const persistor = persistStore(store);
export default store;
