/* eslint camelcase: 0 */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GET_ZAP, LINK_KPIS } from "../../../api/integrations/zapier";
import toast from "react-hot-toast";
import { refreshKpis } from "../../../redux/kpi/actions";
import LoadingScreen from "../../LoadingScreen";

const Editor = () => {
  const [loading, setLoading] = useState(true);
  const [zappedUrl, setZappedUrl] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();

  useEffect(() => {
    if(id){
      GET_ZAP(id)
      .then(({data}) => {
        if(data.length) {
          const editorUrl = data[0].create_url;
          setZappedUrl(editorUrl);
          setLoading(false);
          window.addEventListener('message', ({data: {event}}) => {
            switch(event) {
              case "zap:unpause:done": // Zap turned on / published (success)
              case "zap:unpause:fail": // Zap turned on / published (failure)
              case "zap:pause:done": // Zap turned off (success)
              case "zap:pause:fail": // Zap turned off (failure)
                setLoading(true);
                LINK_KPIS()
                  .then(({data: kpiLink}) => {
                    dispatch(refreshKpis());
                    navigate("/gameboards/workspace-kpi");
                    toast.success(kpiLink || "Updated KPI configurations.");
                  })
                  .catch(err => {
                    navigate("/gameboards/workspace-kpi");
                    toast.error(err.message || err);
                  })
                break;
              default:
                break;
            }
           });
        } else {
          toast.error("Zapier integration ID has no match.");
        }
      })
    }
    return(()=> {
      window.removeEventListener('message');
    });
  }, [id, navigate])

  return (
    <>
      {
        loading && <LoadingScreen />
      }
      {
        zappedUrl && 
          <div className="p-16">
            <iframe 
              title="Zapier Editor"
              src={zappedUrl}
              height="800px"
              width="85%"
              onLoad={()=>setLoading(false)}
            />
          </div>
      }
    </>
  );
};

export default Editor;
