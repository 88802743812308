import React, { useState } from "react";
import { useTheme } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftInput from "../../../../components/SoftInput";
import { AdminBulletinSchema } from "../../../../form-validations";
import { UseCompanyId, UseUserId } from "../../../../hooks/auth";
import { ADD_WIDGETS_TO_LIST } from "../../../../api/userWidgets";
import { refetchUserData } from "../../../../redux/auth/actions";

const AdminBulletin = ({ widget, closeModal, setButtonLabel, setReFetch }) => {
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const userId = UseUserId();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const validationOpt = {
    resolver: yupResolver(AdminBulletinSchema),
    shouldUnregister: false,
    defaultValues: {
      title: widget.title,
      bulletin: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationOpt);

  const triggerSave = async (formData) => {
    const { title, bulletin } = formData;
    const multilineUserInput = bulletin.split("\n");

    setLoading(true);
    const payload = {
      userId,
      key: widget.key,
      category: widget.category,
      title,
      multilineUserInput,
      companyId,
    };

    try {
      await ADD_WIDGETS_TO_LIST(payload);
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        setButtonLabel("Deactivate");
        toast.success("Widget activated successfully");
        closeModal();
        setReFetch();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(triggerSave)}>
      <SoftInput
        {...register("title")}
        placeholder="Enter Title"
        error={!!errors?.title}
        helperText={errors?.title?.message}
        mb="30px"
        className="text-black mb-3"
        sx={{
          border: "1px solid rgba(233, 236, 239, 0.10)",
          backgroundColor: "rgba(255, 255, 255, 0.10) !important",
          color: "#ffffffb3 !important",
          "& .MuiInputBase-input::placeholder": {
            color: "white !important",
            opacity: "0.7",
          },
          "&.Mui-focused": {
            borderColor: primaryColor,
            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
          },
        }}
      />
      <SoftInput
        variant="standard"
        fullWidth
        multiline
        minRows={5}
        size="small"
        {...register("bulletin")}
        error={!!errors?.bulletin}
        helperText={errors?.bulletin?.message}
        placeholder={`Enter ${widget?.title.toLowerCase()}`}
        InputProps={{ disableUnderline: true }}
        sx={{
          border: "1px solid rgba(233, 236, 239, 0.10)",
          backgroundColor: "rgba(255, 255, 255, 0.10) !important",
          color: "#ffffffb3 !important",
          "& .MuiInputBase-input::placeholder": {
            color: "white !important",
            opacity: "0.7",
          },
          "&.Mui-focused": {
            borderColor: primaryColor,
            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
          },
        }}
      />
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.10)",
            color: "#CED4DA",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          type="submit"
          color="primary"
          sx={{
            width: "100%",
            textTransform: "none",
            borderRadius: "4px",
            color: "#E9ECEF",
          }}
          disabled={loading}
          loading={loading}
        >
          Activate
        </SoftButton>
      </SoftBox>
    </form>
  );
};

export default AdminBulletin;
