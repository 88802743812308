import { request } from "../helpers/axios.new";

export const SEND_MESSAGE = (data) => {
  return request({
    url: `/message`,
    method: "POST",
    data,
  });
};

export const FETCH_MESSAGES = (chatId, page) => {
  return request({
    url: `/message/${chatId}?page=${page}`,
    method: "GET",
  });
};
