import React from "react";
import { useMediaQuery } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import BadGuyCard from "./BadGuyCard";

import badGuyBg from "../../img/gamesettings/badguy-bg.png";
import badGuyBee from "../../img/gamesettings/badguy_futuristic-style-bee.png";
import badGuyWolfie from "../../img/gamesettings/badguy_ferocious-robotic-tiger.png";
import badGuyBear from "../../img/gamesettings/badguy_bear-blue-suit.png";
import badGuyYeti from "../../img/gamesettings/badguy_yeti_shades.jfif";

const BAD_GUYS = [
  {
    image: badGuyYeti,
    title: "The Yeti",
    labelSettings: { height: "130px" },
    full: true,
    info: {
      title: "The Yeti",
      description: "The Yeti, a towering figure cloaked in mystery and ice, prowls the frozen depths of the Gamemybiz realm"
    }
  },
  {
    image: badGuyBee,
    title: "The Mechanic Monstrosity",
    labelSettings: { height: "250px", slant: "75%" }
  },
  {
    image: badGuyWolfie,
    title: "The First Wolf",
    labelSettings: { height: "200px", slant: "73%" }
  },
  {
    image: badGuyBear,
    title: "The Bear",
    labelSettings: { height: "160px", slant: "67%" }
  }
];

const BadGuySelector = () => {
  const xxlBreak = useMediaQuery((theme) => theme.breakpoints.down("xxl"));

  return (
    <SoftBox
      sx={{
        backgroundImage: `url(${badGuyBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "576px",
        borderRadius: "8px",
        padding: "32px",
        display: "flex",
        justifyContent: "center",
        gap: "16px"
      }}
    >
      {BAD_GUYS.map(({ title, image, labelSettings, full, info }, index) => (
        <BadGuyCard
          key={title}
          title={title}
          image={image}
          verticalLabel={labelSettings}
          isLocked={index > 0}
          shouldReCenter={xxlBreak}
          {...full && ({ fullCover: full })}
          {...info && ({ info })}
        />
      ))}
    </SoftBox>
  );
}

export default BadGuySelector;
