import React, { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import GMGLogo from "../../img/GMB_Logo.svg";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import { useStyles } from "./ForgotPassword.styles";
import { ForgetPasswordSchema } from "../../form-validations";
import { SEND_RESET_PASSWORD_LINK, VERIFY_USER_EMAIL } from "../../api/user";

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmailMsg, setisValidEmailMsg] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const { mutateAsync: sendResetPasswordLink } = SEND_RESET_PASSWORD_LINK();
  const { mutateAsync: verifyUserEmail } = VERIFY_USER_EMAIL();

  const validationOptions = {
    resolver: yupResolver(ForgetPasswordSchema),
    shouldUnregister: false,
  };

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
    clearErrors
  } = useForm(validationOptions);

  const handleDebounceFn = async () => {
    const result = await trigger("email");
    if (!result) return;

    const email = getValues("email");
    if (!email) return;

    try {
      setIsLoading(true);
      const { data } = await verifyUserEmail({ email });

      setisValidEmailMsg(data?.errorMessage ?? null);

      if (data?.errorMessage) {
        setError("email", { type: "custom", message: data.errorMessage });
      } else {
        clearErrors("email");
      }
    } catch (err) {
      const errMsg = err.response.data ?? err.message
      setError("email", { type: "custom", message: errMsg });
      setisValidEmailMsg(errMsg ?? null);
    }

      setIsLoading(false);
  };

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 1000), []);

  const forgetPasswordHandler = async (formData) => {
    try {
      if (isValidEmailMsg) return;

      setIsLoading(true);
      const { data } = await sendResetPasswordLink(formData);

      if (data?.code === "SUCCESS") {
        toast.success(data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(
        error.response.data.message ?? "Unable to send reset password details",
      );
    }
  };

  return (
    <SoftBox className={classes.resetContainer}>
      <SoftBox className={classes.logoBox}>
        <img src={GMGLogo} alt="Game My Biz" />
        <span
          style={{
            color: "#051923",
            fontSize: "20px",
            fontWeight: "500",
            marginLeft: "10px",
          }}
        >
          {" "}
          Game My Biz
        </span>
      </SoftBox>
      <SoftBox borderRadius="xl" shadow="lg" className={classes.leftPanel}>
        <SoftTypography
          sx={{
            fontSize: "32px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "5px",
            color: "#475467",
            "@media screen and (max-width: 700px)": {
              lineHeight: "35px !important",
            },
          }}
        >
          Password Reset
        </SoftTypography>
        <SoftTypography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
            color: "#98A2B3",
            marginBottom: "15px",
          }}
        >
          Please enter the email address associated with your account below. We
          will send you an email with instructions on how to reset your password
        </SoftTypography>
        <form onSubmit={handleSubmit(forgetPasswordHandler)}>
          <SoftInput
            placeholder="Email"
            label="Email"
            {...register("email", {
              onChange: () => {
                debounceFn()
              }
            })}
            error={!!errors?.email || isValidEmailMsg !== null}
            helperText={errors?.email?.message ?? isValidEmailMsg}
          />

          <SoftBox className={classes.buttonContainer}>
            <SoftButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.buttonStyle}
              disabled={isLoading}
            >
              Reset Password
              {isLoading && (
                <CircularProgress
                  style={{ color: "white" }}
                  size="1rem"
                  className="ml-2"
                />
              )}
            </SoftButton>
            <SoftButton
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}
              onClick={() => navigate("/login")}
            >
              Cancel
            </SoftButton>
          </SoftBox>
        </form>
        <SoftTypography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#98A2B3",
            marginTop: "5px",
          }}
        >
          Note: If you don't receive an email within a few minutes, please check
          your spam folder
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export default ForgotPassword;
