import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftButton from "../../../SoftButton";

// ChallengeArena Form Components
import WithDarkModal from "../Layout/WithDarkModal";
import Image from "../../../Image";

const ArenaThemeSelector = ({ componentCallbackHandler }) => {
  const [picturesData, setpicturesData] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [imageIndex, setImageIndex] = useState(null);
  const [category, setCategory] = useState(null);

  const { getValues } = useFormContext();

  const pictureRootURL = "https://gmb-public.s3.us-east-2.amazonaws.com/arena";

  const IMG_NAMES = {
    ABSTRACT: {
      filename: "abstract",
      count: 2
    },
    COSMOS: {
      filename: "cosmos",
      count: 6
    },
    MOUNTAIN: {
      filename: "mountain",
      count: 9
    },
    PEOPLE: {
      filename: "people",
      count: 1
    }
  }

  useEffect(() => {
    const fileUrls = [];

    // Build the theme image URLs
    Object.keys(IMG_NAMES).forEach((categoryLabel) => {
      const { filename, count: numImages } = IMG_NAMES[categoryLabel];

      for (let i = 1; i <= numImages; i += 1) {
        fileUrls.push(`${pictureRootURL}/${filename}${i}.jpg`);
      }
    })

    setpicturesData(fileUrls);
    setPictures(fileUrls);
    
    // Restore the borders of the selected theme
    const theme = getValues("image");
    const themeIndex = fileUrls.indexOf(theme);

    if (themeIndex >= 0) {
      setImageIndex(theme);
    }
  }, []);

  const handleSelectPicture = (imageSrc) => {
    if (componentCallbackHandler) {
      componentCallbackHandler(imageSrc);
    }

    setImageIndex(imageSrc);
  }

  const filterPictures = (selectedCategory) => {
    setPictures([]);
    setTimeout(() => {}, 500);

    const keyword = (selectedCategory === "mountains")
      ? "mountain"
      : selectedCategory;

    // Filter picture URLs by category name that matches withe the picture file name
    const filtered = picturesData.filter(url => url.indexOf(keyword) >= 0);
    setCategory(selectedCategory);
    setPictures(filtered);
  }

  const selectedCategoryBtnStyle = (theme) =>
    `0px 0px 32px 0px ${theme.palette.primary.focus}, 0px 0px 48px 0px ${theme.palette.primary.focus} inset`

  const getFilenameRoot = (url = '') => {
    return url.substring(url.lastIndexOf('/') + 1, url.length - 4)
  }

  return (
    <SoftBox>
      {/** Category buttons */}
      <SoftBox 
        sx={{ 
          "& button": {
            fontSize: "12px",
            fontWeight: "normal",
            textTransform: "capitalize",
            marginRight: "16px",
            borderRadius: "4px",
            minWidth: "100px",
            padding: "8px 24px 8px 24px",
            border: "1px solid rgba(108, 117, 125, 0.50)",
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.10)"
          }
        }}>
          {["abstract", "mountains", "people", "cosmos"].map(text => (
            <SoftButton
              variant="outlined"
              color="white"
              size="small"
              key={crypto?.randomUUID() ?? text}
              onClick={() => filterPictures(text)}
              sx={{
                boxShadow: (theme) => (category === text)
                  ? selectedCategoryBtnStyle(theme)
                  : "none",
                textTransform: "capitalize",
                "&:focus:not(:hover)": {
                  borderColor: "white",
                  boxShadow: (theme) => selectedCategoryBtnStyle(theme)
                }
              }}
            >
              {text}
            </SoftButton>
          ))}
      </SoftBox>

      {/** Arena Theme Gallery */}
      <SoftBox 
        sx={{ 
          margin: "40px 0 32px 0",
          maxHeight: "300px",
          minHeight: "300px",
          overflow: "auto"
        }}
      >
        <SoftBox
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="20px"
          sx={{
            "& .loaded": {
              "& img": {
                opacity: 1
              }
            }
          }}
        >
          {pictures?.map((url) => (
            <Image
              key={url}
              imageSrc={url}
              blurImageSrc={`${pictureRootURL}/${getFilenameRoot(url)}_small.jpg`}
              height={164}
              fadeSingleImage={false}
              handleClick={() => handleSelectPicture(url)}
              isSelected={(url === imageIndex)}
              containerStyles={{
                "& div": {
                  border: (theme) => (url === imageIndex)
                    ? `2px solid ${theme.palette.primary.focus}`
                    : "none"
                }
              }}
            />
          ))}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  )
};

export default WithDarkModal(ArenaThemeSelector);
