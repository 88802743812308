export const initState = {
  company: {},
  holidays: [],
  usersDictionary: {}, // Dictionary of company users using userId as key
  usersList: {}, // Array list of all userIds from the company
  isLoading: false,
  error: null,
  updated: false,
};

export const types = {
  RESET_COMPANY_INFO: "RESET_COMPANY_INFO",

  // get company information
  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILED: "GET_COMPANY_FAILED",

  // get company users list
  GET_COMPANY_USERS_REQUEST: "GET_COMPANY_USERS_REQUEST",
  GET_COMPANY_USERS_SUCCESS: "GET_COMPANY_USERS_SUCCESS",
  GET_COMPANY_USERS_FAILED: "GET_COMPANY_USERS_FAILED",

  GET_COMPANY_HOLIDAYS_REQUEST: "GET_COMPANY_HOLIDAYS_REQUEST",
  GET_COMPANY_HOLIDAYS_SUCCESS: "GET_COMPANY_HOLIDAYS_SUCCESS",
  GET_COMPANY_HOLIDAYS_FAILED: "GET_COMPANY_HOLIDAYS_FAILED",

  UPDATE_MY_COMPANY_PROFILE: "UPDATE_MY_COMPANY_PROFILE",
  
  SET_COMPANY_REQUEST: "SET_COMPANY_REQUEST",
  SET_COMPANY_SUCCESS: "SET_COMPANY_SUCCESS",
  SET_COMPANY_FAILED: "SET_COMPANY_FAILED",
};
