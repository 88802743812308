import axios from "axios";
import AxiosConfigs from "../configs/axios";

const { REACT_APP_LINK_HOST: apiUrl } = AxiosConfigs;

export const instance = axios.create({ baseURL: apiUrl + "/api" });

/*
  axios.defaults.withCredentials = true;
*/

export const request = async ({ ...options }) => {
  const authToken = localStorage.getItem("token");

  instance.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
    ...options.headers,
  };

  return new Promise((resolve, reject) => {
    instance(options)
      .then((response) => resolve(response))
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          window.location.replace("/signout");
        }

        reject(error);
      });
  });
};
