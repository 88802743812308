/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================
ORIGINATES FROM SOFT UI's CONFIGURATOR COMPONENT
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { boxShadows, functions, transitions } = theme;
  const { shadow, dark} = ownerState;

  const configuratorWidth = 360;
  const { pxToRem } = functions;

  return {
    "& .MuiDrawer-paper": {
      width: configuratorWidth,
      height: "100%",
      margin: 0,
      padding: `0 ${pxToRem(10)}`,
      borderRadius: 0,
      boxShadow: boxShadows[shadow],
      overflowY: "auto",
      transition: transitions.create("all", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.short,
      }),
      backdropFilter: "blur(80px)",
      // if dark mode, set background to transparent white, otherwise use default
      ...dark && { background: "rgba(255, 255, 255, 0.04)"},
    },
  };
});
