import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyUsers } from "../redux/company/actions";
import { UseAuth, UseIsDemo } from "./auth";

export const UseCompany = () => {
  const company = useSelector((state) => state.companyReducer.company);
  return company;
};

export const UseYetiBadguy = () => {
  const company = useSelector((state) => state.companyReducer.company);

  return company?.gameSettings?.badGuy;
};

export const UseSubscriptionLevel = () => {
  const subscriptionLevel = useSelector(
    (state) => state.companyReducer.company.subscription?.level || 0,
  );
  return subscriptionLevel;
};

export const UseCompanyLoading = () => {
  const isLoading = useSelector((state) => state.companyReducer.isLoading);
  // Wrap core state in useMemo so the state change takes effect on next render
  // This prevents some components to render based on incorrect states, and allow the correct states to be propagated
  // The incorrect-state bug happens like in the case when the app flashes the anauthorized page briefly after logging in
  const companyLoading = useMemo(() => isLoading, [isLoading]);
  return companyLoading;
};

export const UseIsCompanyUpToDate = () => {
  const updated = useSelector((state) => state.companyReducer.updated ?? false);
  return updated;
};

export const UseCompanyName = () => {
  const companyName = useSelector(
    (state) =>
      state.authReducer.userDetails?.companyName ||
      state.companyReducer.company.businessName,
  );
  return companyName;
};

export const UseCompanyLogo = () => {
  const isDemo = UseIsDemo();
  const logo = useSelector((state) =>
    isDemo
      ? state.authReducer.userDetails?.companyLogo
      : state.companyReducer.company.logo,
  );
  return logo;
};

export const UseCompanyIndustry = () => {
  const isDemo = UseIsDemo();
  const industry = useSelector((state) =>
    isDemo
      ? state.authReducer.userDetails?.industry
      : state.companyReducer.company.industry,
  );
  return industry;
};

export const UseCompanyColors = () => {
  const isDemo = UseIsDemo();
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const companyDetails = useSelector((state) => state.companyReducer.company);

  if (isDemo) {
    return {
      primaryColor: userDetails.primaryColor,
      secondaryColor: userDetails.secondaryColor,
    };
  } else {
    return {
      primaryColor: companyDetails.primaryColor,
      secondaryColor: companyDetails.secondaryColor,
    };
  }
};

export const UseCompanyUsersRefresh = () => {
  const authenticated = UseAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      dispatch(getCompanyUsers());
    }
  }, [dispatch, authenticated]);
};

export const UseUsersDictionary = () => {
  const companyUsersDict = useSelector(
    (state) => state.companyReducer.usersDictionary,
  );
  return companyUsersDict;
};

/**
 * 
 * @param {*} all include both workspaces and subworkspaces if true; else include main workspaces only
 * @returns array of workspace objects 
 */
export const UseCompanyWorkspaces = (all = false) => {
  const allWorkspaces = useSelector((state) => state.workspacesReducer.workspaces);
  const workspaces = useMemo(() => {
    if(all) {
      return allWorkspaces;
    } else {
      return allWorkspaces.filter((workspace) => !workspace.parentId);
    }
  }, [allWorkspaces, all]);

  return workspaces;
};

export const UseCompanySubWorkspaces = () => {
  const workspaces = useSelector((state) => state.workspacesReducer.workspaces);

  const subworkspaces = useMemo(() => {
    const subs = {};
    workspaces?.forEach((w) => {
      if (w.parentId) {
        (subs[w.parentId] ??= []).push(w);
      }
    });
    return subs;
  }, [workspaces]);

  return subworkspaces;
};

export const UseSelectedWorkspace = () => {
  const workspaces = useSelector(
    (state) => state.workspacesReducer.selectedWorkspace ?? {},
  );
  return workspaces;
};

export const UseUserIdList = () => {
  const companyUsersList = useSelector(
    (state) => state.companyReducer.usersList,
  );
  return companyUsersList;
};

export const UseUsersFullData = () => {
  const [companyUsers, setCompanyUsers] = useState([]);

  const companyUsersDict = useSelector(
    (state) => state.companyReducer.usersDictionary,
  );
  const companyUsersList = useSelector(
    (state) => state.companyReducer.usersList,
  );

  useEffect(() => {
    if (companyUsersList?.length) {
      const users = companyUsersList.map((userId) => ({
        ...companyUsersDict[userId],
        userId,
      }));
      setCompanyUsers(users);
    } else {
      setCompanyUsers([]);
    }
  }, [companyUsersList, companyUsersDict]);

  return companyUsers;
};

export const UseActivePlayers = () => {
  const [activePlayers, setActivePlayers] = useState([]);
  const scorers = useSelector((state) => state.kpiReducer.usersKpis);

  useEffect(() => {
    setActivePlayers(scorers?.map((p) => p.userId) ?? []);
  }, [scorers]);
  return activePlayers;
};

export const UseHolidays = (month = null) => {
  const allHolidays = useSelector((state) => state.companyReducer.holidays);
  const holidays = useMemo(() => {
    if (month) {
      const monthRef = moment(month).format("YYYY-MM");
      const monthHolidays =
        allHolidays
          ?.filter((item) => item.date.startsWith(monthRef))
          .map((item) => moment.utc(item.date).date()) || [];
      return monthHolidays;
    }
    return allHolidays;
  }, [allHolidays, month])

  return holidays;
};

export const UseCompanyWorkspaceUsers = (groupId) => {
  const allCompanyUsers = UseUsersFullData();

  const companyWorkspaceUsers = useMemo(() => {
    return (groupId)
      ? allCompanyUsers?.filter(user => user?.groupId === groupId)
      : allCompanyUsers
  }, [allCompanyUsers, groupId]);

  return companyWorkspaceUsers;
;}

export const UseYetiStats = (liveMode = true) => {
  const [stats, setStats] = useState(null);
  const holidays = useSelector((state) => state.companyReducer.holidays);
  const kpis = useSelector((state) => state.kpiReducer.groupKpis);
  const monthRef = useSelector((state) => state.kpiReducer.monthRef);
  const currentDay = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (kpis && kpis.length) {
      const month = monthRef && !liveMode ? monthRef : moment().format("YYYY-MM");
      const today = moment();
      const weekStart = moment().startOf("week");
      const dayCount = moment(month).daysInMonth();
      const workbreaks =
        holidays
          ?.filter((item) => item.date.startsWith(month))
          .map((item) => moment.utc(item.date).date()) || [];

      let yetiWeek = 0;
      let yetiMonth = 0;
      let totalDays = 0;

      for (let d = 0; d < dayCount; d++) {
        if (workbreaks.indexOf(d + 1) < 0) {
          const t = moment(month).startOf("month").add(d, "days");
          if (t.day() > 0 && t.day() < 6) {
            // no weekends
            if (t <= today) {
              if (t >= weekStart) {
                yetiWeek += 1;
              }
              yetiMonth += 1;
            }
            totalDays += 1;
          }
        }
      }

      const yetiScore = {
        yesterday: { total: 0 },
        today: { total: 0 },
        week: { total: 0 },
        month: { total: 0 },
        last31: { total: 0 },
        maxTotal: 0,
      };

      kpis.forEach((kpi) => {
        const target = kpi.minimum || kpi.target;
        // If KPI is sum for the month, divide it daily
        const dailyTarget =
          kpi.calc === "sum"
            ? Math.round((target / totalDays) * 100) / 100
            : target;
        const dailyPts = kpi.pointScale * dailyTarget;

        yetiScore.today[kpi._id] = { pts: dailyPts, amt: dailyTarget };
        yetiScore.today.total += dailyPts;

        yetiScore.yesterday[kpi._id] = { pts: dailyPts, amt: dailyTarget };
        yetiScore.yesterday.total += dailyPts;

        yetiScore.week[kpi._id] = {
          pts: dailyPts * (kpi.wholeMonth ? 1 : yetiWeek),
          amt: dailyTarget * (kpi.wholeMonth ? 1 : yetiWeek),
        };
        yetiScore.week.total += dailyPts * (kpi.wholeMonth ? 1 : yetiWeek);

        yetiScore.month[kpi._id] = {
          pts: dailyPts * (kpi.wholeMonth ? 1 : yetiMonth),
          amt: dailyTarget * (kpi.wholeMonth ? 1 : yetiMonth),
        };
        yetiScore.month.total += dailyPts * (kpi.wholeMonth ? 1 : yetiMonth);

        yetiScore.maxTotal += dailyPts * (kpi.wholeMonth ? 1 : totalDays);
      });

      setStats({ ...yetiScore, totalDays });
    }
  }, [kpis, holidays, liveMode, monthRef, currentDay]);

  return stats;
};