import React from "react";
import { useLocation } from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

import startCase from "lodash/startCase";
import { UseSelectedWorkspace } from "../../hooks/company";
import "react-quill/dist/quill.core.css";

const JetpackContent = () => {
  const { state: { data } = {} } = useLocation();
  const { name: workspace } = UseSelectedWorkspace();

  return (
    <div>
      <PageHeader
        headerText={`${startCase(workspace)} Hub`}
        subheaderText="Manage Employee Scores and Performance"
      />
      <SoftBox
        borderRadius="lg"
        shadow="xs"
        bgColor="white"
        className="mx-4"
        sx={{
          padding: "15px 32px",
        }}
      >
        <SoftTypography
          variant="h2"
          sx={{
            fontSize: "24px",
            fontWeight: 500,
            color: "#16192C",
            marginBottom: "10px",
          }}
        >
          {startCase(data.name || "Jetpack")}
        </SoftTypography>
        <SoftTypography
          variant="p"
          sx={{
            width: "100%",
            fontWeight: 400,
            color: "#667085",
            marginBottom: "40px",
          }}
        >
          {data.desc || "Missing Jetpack description"}
        </SoftTypography>
        {data && data.page && (
          <div
            className="w-full h-auto"
            dangerouslySetInnerHTML={{ __html: data.page }}
          />
        )}
      </SoftBox>
    </div>
  );
};

export default JetpackContent;
