import React, { useState } from "react";
import { Modal } from "@material-ui/core";

import SoftTypography from "../../components/SoftTypography";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  borderRadius: "16px",
  backgroundColor: "#FFF",
  background: "rgba(255, 255, 255, 0.5)",
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(12.5px)",
};

const buttonStyle = {
  background: "linear-gradient(135deg, #FF0080 0%, #7928CA 100%)",
  backgroundClip: "text",
  textFillColor: "transparent",
  webkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 600,
  fontSize: "20px",
};

const CongratsPopup = ({ showPopup = false, item = {} }) => {
  const [showModal, setShowModal] = useState(showPopup);

  return (
    <Modal
      open={showModal}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
      disableEnforceFocus
    >
      <div style={modalStyle}>
        <div
          style={{
            background: "linear-gradient(135deg, #FF0080 0%, #7928CA 100%)",
            borderRadius: "16px 16px 0px 0px",
          }}
          className="py-2"
        >
          <SoftTypography
            variant="h5"
            sx={{ color: "#FFF", textAlign: "center", fontWeight: 800 }}
          >
            CONGRATULATIONS!
          </SoftTypography>
        </div>
        <div className="flex flex-col items-center py-5">
          <div
            style={{
              height: 200,
              width: 200,
            }}
            className="my-2"
          >
            <img
              src={item.image}
              alt=""
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <SoftTypography
            variant="h5"
            sx={{
              color: "#252F40",
              marginBottom: "15px",
              marginTop: "20px",
              fontWeight: 600,
              fontSize: 20,
              textTransform: "capitalize",
            }}
          >
            {item.sender}
          </SoftTypography>
          <SoftTypography
            variant="p"
            sx={{ textAlign: "center", color: "#252F40" }}
          >
            Get your handbook from the HR and
            <br />
            prepare to review the JT Values
          </SoftTypography>
        </div>
        <div
          style={{
            borderRadius: "0px 0px 16px 16px",
            background: "rgba(255, 255, 255, 0.5)",
          }}
          className="py-2 flex justify-center"
        >
          <button onClick={() => setShowModal(false)} style={buttonStyle}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CongratsPopup;
