import React from "react";
import GroupsIcon from "@mui/icons-material/Groups";

import blankPic from "../../../img/blankPic.png";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import { Avatar, useTheme, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  MarkUnreadChatAltOutlined,
  PushPinOutlined,
  ExitToAppOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

const ListElement = ({
  id,
  image,
  name,
  latestMessage,
  selected,
  onSelected,
  setSelectedChat,
  isGroupChat = false,
  messageTime,
}) => {
  const theme = useTheme();
  const {
    primary: { focus: primaryFocus, lightest: primaryLightest },
  } = theme.palette;

  return (
    <SoftBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        "&:hover": {
          background: primaryLightest,
          color: "primary.contrastText",
          cursor: "pointer",
        },
        color: "primary.contrastText",
        borderRadius: "0.75rem",
        background: selected ? primaryFocus : "",
      }}
    >
      <SoftBox
        display="flex"
        alignItems="start"
        width="100%"
        padding="0.5rem 0 0.5rem 0.75rem"
        onClick={() => {
          onSelected();
          setSelectedChat();
        }}
      >
        {isGroupChat ? (
          <Avatar
            variant="square"
            sx={{
              width: "2.25rem",
              height: "2.25rem",
              borderRadius: "12px",
              "& .MuiAvatar-img": {
                borderRadius: "12px",
              },
            }}
          >
            <GroupsIcon fontSize="small" />
          </Avatar>
        ) : (
          <Avatar
            variant="square"
            sx={{
              width: "2.25rem",
              height: "2.25rem",
              borderRadius: "12px",
              "& .MuiAvatar-img": {
                borderRadius: "12px",
              },
            }}
            src={image === null ? "" : image}
          >
            <span>{name[0]}</span>
          </Avatar>
        )}
        <SoftBox ml={2} lineHeight={1}>
          <SoftTypography
            display="block"
            variant="h6"
            sx={{
              fontSize: "16px",
              color: selected ? "rgba(5, 25, 35, 1)" : "rgba(52, 71, 103, 1)",
            }}
          >
            {name}
          </SoftTypography>
          {messageTime && (
            <SoftTypography
              variant="button"
              color="text"
              sx={{ fontWeight: 400, fontSize: "11px" }}
            >
              {messageTime}
            </SoftTypography>
          )}
          {latestMessage && (
            <SoftTypography
              display="block"
              variant="h6"
              sx={{
                fontSize: "13px",
                color: "rgba(52, 71, 103, 1)",
              }}
            >
              {latestMessage.length > 50
                ? `${latestMessage.substring(0, 35)}....`
                : latestMessage}
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>

      {/* <SoftBox>
        <PopupState variant="popover" popupId={id}>
          {(popupState) => (
            <>
              <SoftButton
                {...bindTrigger(popupState)}
                variant="text"
                sx={{
                  minWidth: "max-content",
                  minHeight: "max-content",
                  width: "max-content",
                  height: "max-content",
                  padding: "0.25rem",
                  "& svg": {
                    fontSize: "2rem !important",
                  },
                }}
              >
                <MoreVertIcon
                  sx={{
                    color: "rgba(108, 117, 125, 1) !important",
                  }}
                />
              </SoftButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#FFF",
                    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.06)",
                    border: "1px solid #CED4DA",
                    borderRadius: "10px",
                  },
                }}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  gap="0.25rem"
                  alignItems="center"
                >
                  <SoftButton
                    variant="text"
                    sx={{
                      justifyContent: "start",
                      color: "#6C757D",
                      textTransform: "none",
                      width: "100%",
                      "&:hover": {
                        color: "#6C757D",
                      },
                      "&:focus:not(:hover)": {
                        color: "#6C757D",
                      },
                    }}
                  >
                    <MarkUnreadChatAltOutlined
                      style={{ marginRight: "0.25rem" }}
                    />{" "}
                    Mark as unread
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    sx={{
                      justifyContent: "start",
                      color: "#6C757D",
                      textTransform: "none",
                      width: "100%",
                      "&:hover": {
                        color: "#6C757D",
                      },
                      "&:focus:not(:hover)": {
                        color: "#6C757D",
                      },
                    }}
                  >
                    <PushPinOutlined style={{ marginRight: "0.25rem" }} /> Pin
                    to the Top
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    sx={{
                      justifyContent: "start",
                      color: "#6C757D",
                      textTransform: "none",
                      width: "100%",
                      "&:hover": {
                        color: "#6C757D",
                      },
                      "&:focus:not(:hover)": {
                        color: "#6C757D",
                      },
                    }}
                  >
                    <ExitToAppOutlined style={{ marginRight: "0.25rem" }} />{" "}
                    Leave Group chat
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    sx={{
                      justifyContent: "start",
                      color: "#6C757D",
                      textTransform: "none",
                      width: "100%",
                      "&:hover": {
                        color: "#6C757D",
                      },
                      "&:focus:not(:hover)": {
                        color: "#6C757D",
                      },
                    }}
                  >
                    <DeleteOutlineOutlined style={{ marginRight: "0.25rem" }} />{" "}
                    Delete chat
                  </SoftButton>
                </SoftBox>
              </Popover>
            </>
          )}
        </PopupState>
      </SoftBox> */}
    </SoftBox>
  );
};

export default ListElement;
