/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useMemo } from "react";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";

// Soft UI Dashboard PRO React example components
import ReportsDoughnutChartItem from "./ReportsDoughnutChartItem";

// ReportsDoughnutChart configurations
import configs from "./configs";
import { useNavigate } from "react-router-dom";

function ReportsDoughnutChart({ title, count, chart, link, userId, tooltip }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const navigate = useNavigate();

  const renderItems =
    chart.labels && chart.datasets
      ? chart.labels.map((label, key) => (
          <ReportsDoughnutChartItem
            color={
              options.backgroundColors ? options.backgroundColors[key] : "dark"
            }
            title={label}
            key={label}
            item={chart.labelItems?.[key]}
            percentage={`${
              chart.datasets.data ? chart.datasets.data[key] : 0
            }%`}
            hasBorder={key !== chart.labels.length - 1}
          />
        ))
      : null;

  return (
    <Card>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={{ mini: 0, xxs: 1, sm: 2 }}
      >
        <SoftTypography variant="h6">{title}</SoftTypography>
        {link && (
          <Tooltip title={tooltip} placement="bottom" arrow>
            <SoftButton
              variant="outlined"
              color="secondary"
              size="small"
              circular
              iconOnly
              onClick={() => navigate(`/scorecard?user=${userId}`)}
            >
              <PriorityHighRoundedIcon />
            </SoftButton>
          </Tooltip>
        )}
      </SoftBox>
      <SoftBox
        sx={{
          paddingX: { mini: 0, xxs: 1, sm: 2 },
          paddingY: { mini: 1, xxs: 2 },
        }}
      >
        {useMemo(
          () => (
            <Grid container spacing={{ mini: 1, xxs: 2 }}>
              <Grid item mini={12} sm={5}>
                <SoftBox height="100%" textAlign="center" position="relative">
                  <SoftBox
                    height={{ mini: "70%", sm: "100%" }}
                    mt={{ mini: 6, sm: 0 }}
                    width={{ mini: "260px", xxs: "auto" }}
                    margin={{ mini: "0 auto" }}
                  >
                    <Doughnut data={data} options={options} />
                  </SoftBox>
                  <SoftBox
                    sx={{
                      position: { mini: "relative", sm: "absolute" },
                      top: { mini: "-5.8rem", sm: 0 },
                      bottom: 0,
                      left: 0,
                      right: 0,
                      margin: "auto",
                      height: "max-content",
                    }}
                  >
                    <SoftTypography variant="h4" fontWeight="medium" sx={{marginTop: { mini: "-.35rem"}}}>
                      {count.number}
                    </SoftTypography>
                    <SoftTypography
                      variant="button"
                      color="text"
                      textTransform="uppercase"
                      fontWeight="medium"
                    >
                      {count.text}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item mini={12} sm={7}>
                {renderItems}
              </Grid>
            </Grid>
          ),
          [chart],
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ReportsDoughnutChart
ReportsDoughnutChart.defaultProps = {
  link: false,
};

// Typechecking props for the ReportsDoughnutChart
ReportsDoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string.isRequired,
  }).isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
      ]),
    ).isRequired,
  }).isRequired,
  link: PropTypes.bool,
};

export default ReportsDoughnutChart;
