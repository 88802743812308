/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Custom styles for SoftInput
import SoftInputRoot from "./SoftInputRoot";
import SoftInputWithIconRoot from "./SoftInputWithIconRoot";
import SoftInputIconBoxRoot from "./SoftInputIconBoxRoot";
import SoftInputIconRoot from "./SoftInputIconRoot";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "../../context/softui";

const SoftInput = forwardRef(
  (
    { size, icon, error, success, disabled, helperText, className, ...rest },
    ref,
  ) => {
    let template;
    const [controller] = useSoftUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    if (icon.component && icon.direction === "left") {
      template = (
        <div className={className}>
          <SoftInputWithIconRoot
            ref={ref}
            ownerState={{ error, success, disabled }}
            className={className}
          >
            <SoftInputIconBoxRoot ownerState={{ size }}>
              <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
                {icon.component}
              </SoftInputIconRoot>
            </SoftInputIconBoxRoot>
            <SoftInputRoot
              {...rest}
              helpertext={helperText}
              ownerState={{
                size,
                error,
                success,
                iconDirection,
                direction,
                disabled,
              }}
            />
          </SoftInputWithIconRoot>
          {helperText && (
            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          )}
        </div>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <div className={className}>
          <SoftInputWithIconRoot
            ref={ref}
            ownerState={{ error, success, disabled }}
            className={className}
          >
            <SoftInputRoot
              {...rest}
              helpertext={helperText}
              ownerState={{
                size,
                error,
                success,
                iconDirection,
                direction,
                disabled,
              }}
            />
            <SoftInputIconBoxRoot ownerState={{ size }}>
              <SoftInputIconRoot
                onClick={icon.handler && icon.handler}
                fontSize="small"
                ownerState={{ size }}
              >
                {icon.component}
              </SoftInputIconRoot>
            </SoftInputIconBoxRoot>
          </SoftInputWithIconRoot>
          {helperText && (
            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          )}
        </div>
      );
    } else {
      template = (
        <div className={className}>
          <SoftInputRoot
            {...rest}
            ref={ref}
            helpertext={helperText}
            ownerState={{ size, error, success, disabled }}
          />
          {helperText && (
            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          )}
        </div>
      );
    }

    return template;
  },
);

// Setting default values for the props of SoftInput
SoftInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    // direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the SoftInput
SoftInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    // direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SoftInput;
