import { useSelector } from "react-redux";
import { CLAIMABLE_ITEMS } from "../constants/claims";

export const UseCompanyClaims = () => {
  const items = useSelector((state) => state.claimsReducer.claims);
  return items;
};

export const UseUnclaimedCount = () => {
  const items = useSelector((state) => state.claimsReducer.claims);
  const kpis = useSelector((state) => state.kpiReducer.groupKpis);

  if(kpis?.length) {
    const hasClaimableItems = kpis.reduce((has, item) => CLAIMABLE_ITEMS.includes(item.type) || has, false);
    if(hasClaimableItems) {
      const unclaimedItems = items.filter((item) => !item.kpi?.ownerId);
      return unclaimedItems?.length || 0;
    }
  }
  return 0;

}