import React, { useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import Avatar from "../../Avatar";
import { AVATAR_URLS } from "../freemiumAvatarsDef";
import {
  VICTORY1_ANIM,
  VICTORY2_ANIM,
  VICTORY3_ANIM,
  IDLE1_ANIM,
  IDLE2_ANIM,
  IDLE3_ANIM,
} from "../../Avatar/animsDef";
import useMediaQuery from "@mui/material/useMediaQuery";

const AVATAR_LEADERS = [
  {
    x: 0,
    y: "370px",
    minix: 0,
    miniy: "250px",
    xxsx: 0,
    xxsy: "260px",
    xsx: 0,
    xsy: "280px",
    smx: 0,
    smy: "320px",
    lgx: 0,
    xlx: 0,
    xxlx: 0,
    xxxlx: 0,
    widex: "",
    widey: "340px",
    key: "top1",
    anim: VICTORY1_ANIM,
    idle: IDLE1_ANIM,
  },
  {
    x: "-29%",
    y: "290px",
    minix: "-53%",
    miniy: "150px",
    xxsx: "",
    xxsy: "190px",
    xsy: "190px",
    xsx: "",
    smx: "-53%",
    smy: "220px",
    lgx: "",
    xlx: "",
    xxlx: "",
    xxxlx: "",
    widex: "",
    widey: "260px",
    key: "top2",
    anim: VICTORY2_ANIM,
    idle: IDLE2_ANIM,
  },
  {
    x: "29%",
    y: "250px",
    minix: "54%",
    miniy: "130px",
    xxsx: "",
    xxsy: "150px",
    xsx: "",
    xsy: "150px",
    smx: "54%",
    smy: "190px",
    lgx: "",
    xlx: "",
    xxlx: "",
    xxxlx: "",
    widex: "",
    widey: "220px",
    key: "top3",
    anim: VICTORY3_ANIM,
    idle: IDLE3_ANIM,
  },
];
const ANIM_DELAY = 10000;

const AvatarLeaders = ({ data, companyLogo }) => {
  const avatarsRef = useRef([]);
  useEffect(() => {
    AVATAR_LEADERS.forEach((item, idx) => {
      setTimeout(() => {
        setInterval(() => {
          avatarsRef.current[idx]?.playAnimation();
        }, ANIM_DELAY * AVATAR_LEADERS.length);
      }, idx * ANIM_DELAY);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const avatarBp = useMediaQuery((theme) => theme.breakpoints.up("xxxl"));

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100%",
        marginX: "auto",
      }}
    >
      {AVATAR_LEADERS.map((item, idx) => {
        if (idx < data.length) {
          return (
            <Grid
              item
              mini={4}
              key={item.key}
              // className="absolute h-[58%] w-[40%] md:h-[55%] md:w-[35%] mx-auto"
              sx={{
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                height: avatarBp ? "600px" : "480px",
                width: avatarBp ? "600px" : "auto",
                bottom: {
                  mini: item.miniy,
                  xxs: item.xxsy,
                  xs: item.xsy,
                  sm: item.smy,
                  md: avatarBp ? item.widey : item.y,
                },
                right: 0,
                left: {
                  mini: item.minix,
                  xxs: item.xxsx,
                  xs: item.xsx,
                  sm: item.smx,
                  md: item.x,
                  lg: item.lgx,
                  xl: item.xlx,
                  xxl: item.xxlx,
                  xxxl: item.xxxlx,
                  wide: item.widex,
                },
              }}
            >
              <Avatar
                ref={(el) => {
                  avatarsRef.current[idx] = el;
                }}
                url={data[idx].avatar || AVATAR_URLS[idx]}
                fixed={false}
                margin={1.35}
                isOwn={false} // always false to avoid referencing cached models
                recolor={data[idx].ownCompany ? null : data[idx].color}
                logo={data[idx].ownCompany ? companyLogo : null}
                animation={item.anim}
                idle={item.idle}
                yeti={!!data[idx].isYeti}
                noControls={true}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default AvatarLeaders;
