import { request } from "../helpers/axios.new";

export const GET_INVITE_CODE = () => {
  return request({
    url: '/affiliate/code', 
    method: "GET"
  });
};

export const GET_CHALLENGE_REFERRAL = (code) => {
  return request({
    url: `/challenge/referral/${code}`, 
    method: "GET"
  });
};

export const GET_AFFILIATE_DATA = () => {
  return request({
    url: `/affiliate/data`, 
    method: "GET"
  });
};