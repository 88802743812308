import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Grid from "@mui/material/Grid";
import toast from "react-hot-toast";
import { Editor } from "@tinymce/tinymce-react";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import PageHeader from "../../components/PageHeader";
import { CREATE_UPDATE_HANDBOOK, GET_HANDBOOK } from "../../api/handbook";
import { UseSelectedWorkspace } from "../../hooks/company";
import { UseUserId } from "../../hooks/auth";
import LoadingScreen from "../../components/LoadingScreen";
// import "./RichTextEditor.css";

const PlaybookEditor = () => {
  const [TOC, setTOC] = useState([]);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);

  const { _id: groupId } = UseSelectedWorkspace();
  const userId = UseUserId();

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const generateTOC = () => {
    const headings =
      content.match(/<h[1-2]\s*id="toc-\d+">.*?<\/h[1-2]>/g) || [];

    const toc = headings
      .filter((heading) => {
        const text = heading.replace(/<\/?[^>]+(>|$)/g, "");

        if (text !== "") return heading;
      })
      .map((heading, index) => {
        const text = heading.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
        const level = parseInt(heading.charAt(2), 10);

        return {
          id: `toc-${index}`,
          text,
          level,
        };
      });
    return toc;
  };

  useEffect(() => {
    const toc = generateTOC();
    setTOC(toc);
  }, [content]);

  const saveHandler = async () => {
    const editor = editorRef?.current?.editor;
    const editorContent = editor?.getContent();

    try {
      const payload = {
        content: editorContent,
        workspaceId: groupId,
        updatedBy: userId,
      };

      console.log(editorContent);

      await CREATE_UPDATE_HANDBOOK(payload);

      toast.success("Playbook saved successfully");
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchHandbook = async () => {
    setLoading(true);
    try {
      const { data } = await GET_HANDBOOK({ workspaceId: groupId });
      if (data) {
        setContent(data?.content);
      }
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHandbook();
  }, []);

  return (
    <>
      <PageHeader
        headerText="Resource Center"
        subheaderText="Learn Just about everything on the resource center"
      />
      {loading ? (
        <LoadingScreen />
      ) : (
        <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
          <Grid container spacing={2}>
            <Grid item mini={12} xs={12} md={9} lg={9} xl={9} xxl={9} xxxl={9}>
              <SoftBox
                sx={{
                  backgroundColor: "#FFF",
                  padding: "20px",
                  borderRadius: "16px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
                  minHeight: "600px",
                }}
              >
                <Editor
                  ref={editorRef}
                  apiKey="1biyw631gwxyz9spyrl7knedlmrk6utkmbzxt7rm7mj4b0h3"
                  onEditorChange={handleEditorChange}
                  value={content}
                  init={{
                    height: "900px",
                    menubar: false,
                    // menu: {
                    //   file: {
                    //     title: "File",
                    //     items: "fileSave | export | print",
                    //   },
                    // },
                    automatic_uploads: true,
                    images_upload_url: `${process.env.REACT_APP_LINK_HOST}/upload`,
                    media_alt_source: false,
                    image_advtab: false,
                    file_picker_types: "file image media",
                    plugins:
                      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code",
                    toolbar: true,
                    toolbar1:
                      "undo redo blocks fontfamily fontsize bold italic underline strikethrough link image media table mergetags align lineheight tinycomments checklist numlist bullist indent outdent emoticons charmap removeformat code fileSave ",
                    content_css: "RichTextEditor.css",
                    content_style:
                      "body { padding: 8px; font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: (editor) => {
                      editor.ui.registry.addButton("fileSave", {
                        text: "Save",
                        icon: "save",
                        tooltip: "Save the Playbook",
                        onAction: () => {
                          saveHandler();
                        },
                      });

                      editor.on("change", () => {
                        const headings = editor
                          .getBody()
                          .querySelectorAll("h1, h2");
                        for (let i = 0; i < headings.length; i++) {
                          if (
                            !headings[i].id &&
                            headings[i].textContent.trim() !== ""
                          ) {
                            headings[i].id = `toc-${i}`;
                          }
                        }
                      });

                      editor.on("BeforeSetContent", (e) => {
                        if (e.content && e.content.includes("blob:")) {
                          const s = e.content
                            .substr(e.content.indexOf("blob"), e.content.length)
                            .replace("/>", "")
                            .replace(">", "")
                            .replace('"', "")
                            .trim();

                          const trimmedUrl = s.split(" ")[0];

                          if (
                            e.target.editorUpload.blobCache.getByUri(trimmedUrl)
                          ) {
                            let { size } = e.target.editorUpload.blobCache
                              .getByUri(trimmedUrl)
                              .blob();
                            const allowedSize = 2000; // 2MB
                            size /= 1024; // KB

                            if (size > allowedSize) {
                              toast.error("Image must be less than 2MB");
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }
                        }
                      });
                    },
                  }}
                />
              </SoftBox>
            </Grid>
            <Grid item mini={12} xs={12} md={3} lg={3} xl={3} xxl={3} xxxl={3}>
              <SoftBox
                sx={{
                  backgroundColor: "#FFF",
                  padding: "20px",
                  borderRadius: "16px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
                  minHeight: "600px",
                }}
              >
                <SoftTypography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: "#000",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Table of Contents
                </SoftTypography>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {TOC.map((item) => (
                    <li
                      key={item.text}
                      style={{
                        display: "flex",
                        width: "100%",
                        minHeight: "80px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: 8,
                        border: "2px solid #04549C",
                        background: "rgba(4, 84, 156, 0.10)",
                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.08)",
                        padding: "0.5rem",
                        overflow: "hidden",
                      }}
                    >
                      <a
                        href={`#${item.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          document.querySelector(`#${item.id}`).scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                        style={{
                          lineHeight: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      )}
    </>
  );
};

export default PlaybookEditor;
