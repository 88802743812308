import React from "react";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import TenMpOutlinedIcon from '@mui/icons-material/TenMpOutlined';
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';

const FORM_ELEMENTS = [
  {
    id: "text",
    label: "Text",
    icon: (
      <NotesOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
  {
    id: "date",
    label: "Date",
    icon: (
      <CalendarTodayOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
  {
    id: "checkbox",
    label: "Checkbox",
    icon: (
      <CheckBoxOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
  {
    id: "number",
    label: "Number",
    icon: (
      <TenMpOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
  {
    id: "image",
    label: "Image",
    icon: (
      <ImageOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
  {
    id: "picture",
    label: "Take a Picture",
    icon: (
      <PhotoCameraOutlinedIcon
        sx={{ height: "1.25rem", width: "auto", color: "#6C757D" }}
      />
    ),
  },
];

export default FORM_ELEMENTS;