import * as React from "react";
import { useDispatch } from "react-redux";
import { UseUserDetails } from "../../hooks/auth";
import { updateProfile } from "../../redux/auth/actions";
import "./readyPlayerMeStyles.css";
import { useNavigate } from "react-router-dom";
import SoftBox from "../SoftBox";

const ReadyPlayerMe = () => {
  const subdomain = "gamemybiz";
  const iFrameRef = React.useRef(null);
  const dispatch = useDispatch();
  const user = UseUserDetails();
  const navigate = useNavigate();

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  async function subscribe(event) {
    const json = parse(event);
    if (json?.source !== "readyplayerme") {
      return;
    }
    // Subscribe to all events sent from Ready Player Me
    // once frame is ready
    if (json.eventName === "v1.frame.ready") {
      const iFrame = iFrameRef.current;
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*",
        );
      }
    }

    // Get avatar GLB URL
    if (json.eventName === "v1.avatar.exported") {
      dispatch(
        updateProfile(user._id, {
          avatar: json.data.url,
        }),
      );
      navigate("/");
    }
    // Get user id
    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set:
      ${JSON.stringify(json)}`);
    }
  }

  React.useEffect(() => {
    const iFrame = iFrameRef.current;
    if (iFrame) {
      iFrame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  });

  return (
    <SoftBox sx={{ marginTop: { mini: "4.25rem", sm: "4rem" } }}>
      <iframe
        allow="camera *; microphone *"
        className="w-full h-[85vh] md:h-[90vh]"
        id="frame"
        ref={iFrameRef}
        title="Ready Player Me"
      />
    </SoftBox>
  );
};

export default ReadyPlayerMe;
