import { request } from "../helpers/axios.new";

export const ADD_WIDGETS_TO_LIST = (data) => {
  return request({
    url: `user-widgets`,
    method: "POST",
    data,
  });
};

export const GET_WIDGETS_LIST = ({ userId, key, companyId }) => {
  return request({
    url: `user-widgets?userId=${userId}&key=${key}&companyId=${companyId}`,
    method: "GET",
  });
};

export const DELETE_WIDGET_FROM_LIST = (widgetId, userId) => {
  return request({
    url: `user-widgets?widgetId=${widgetId}&userId=${userId}`,
    method: "DELETE",
  });
};

export const TOGGLE_ON_WIDGET = (data) => {
  return request({
    url: `user-widgets/toggle-on`,
    method: "POST",
    data,
  });
};

export const TOGGLE_OFF_WIDGET = (widgetId, userId) => {
  return request({
    url: `user-widgets/toggle-off?widgetId=${widgetId}&userId=${userId}`,
    method: "DELETE",
  });
};
