import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const UseAuth = () => {
  const loginCredentials = useSelector(
    (state) => state.authReducer.authenticated,
  );
  return loginCredentials;
};

export const UseAuthError = () => {
  const loginCredentials = useSelector(
    (state) =>
      state.authReducer.status === "error" || state.authReducer.errorMessage,
  );
  return loginCredentials;
};

export const UseAuthStatus = () => {
  const status = useSelector((state) => state.authReducer.status);
  return status;
};

export const UseAuthErrorMessage = () => {
  const loginCredentials = useSelector(
    (state) => state.authReducer.errorMessage,
  );
  return loginCredentials;
};

export const UseUserDetails = () => {
  const getUserDetails = useSelector((state) => state.authReducer.userDetails ?? {});
  return getUserDetails;
};

export const UseCompanyName = () => {
  const getCompanyName = useSelector(
    (state) => state.authReducer.userDetails?.company,
  );
  return getCompanyName;
};

export const UseCompanyId = () => {
  const getCompanyId = useSelector(
    (state) => state.authReducer.userDetails?.companyId,
  );
  return getCompanyId;
};

export const UseUserId = () => {
  const getUserId = useSelector((state) => state.authReducer.userDetails?._id);
  return getUserId;
};

export const UseIsAdmin = () => {
  const theme = useTheme();
  // Force player mode for non desktop devices
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const isAdmin = useSelector(
    (state) => !isSmallScreen && state.authReducer.userDetails?.isAdmin && !(state.usersReducer.viewAsUser),
  );
  return isAdmin;
};

export const UseIsAffiliate = () => {
  const isAffiliate = useSelector(
    // Only officially registered Affiliate Partners with level > 0
    (state) => state.authReducer.userDetails?.affiliate?.level ?? false,
  );
  return isAffiliate;
};

export const UseLoading = () => {
  const loading = useSelector((state) => state.authReducer.isLoading);
  return loading;
};

export const UseIsPrime = () => {
  const isPrime = useSelector((state) => state.authReducer.isPrime);
  return isPrime;
};

export const UseIsDemo = () => {
  const isDemo = useSelector((state) => state.authReducer.isDemo);
  return isDemo;
};

export const UseAccess = () => {
  const access = useSelector((state) => state.authReducer.access);
  return access;
};
