import React from "react";

const DashboardIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.36 15.16H4.64C2.81333 15.16 1.33333 13.6733 1.33333 11.8467V6.90667C1.33333 6 1.89333 4.86 2.61333 4.3L6.20666 1.5C7.28666 0.659999 9.01333 0.619999 10.1333 1.40667L14.2533 4.29333C15.0467 4.84667 15.6667 6.03333 15.6667 7V11.8533C15.6667 13.6733 14.1867 15.16 12.36 15.16ZM6.81999 2.28667L3.22666 5.08667C2.75333 5.46 2.33333 6.30667 2.33333 6.90667V11.8467C2.33333 13.12 3.36666 14.16 4.64 14.16H12.36C13.6333 14.16 14.6667 13.1267 14.6667 11.8533V7C14.6667 6.36 14.2067 5.47333 13.68 5.11333L9.56 2.22667C8.8 1.69333 7.54666 1.72 6.81999 2.28667Z"
        fill="#FCFCFD"
      />
      <path
        d="M5.5 11.5C5.37333 11.5 5.24666 11.4533 5.14666 11.3533C4.95333 11.16 4.95333 10.84 5.14666 10.6467L7.28 8.51333C7.38666 8.40667 7.52666 8.35333 7.68 8.36667C7.82666 8.38 7.96 8.46 8.04666 8.58667L8.77333 9.68L11.14 7.31333C11.3333 7.12 11.6533 7.12 11.8467 7.31333C12.04 7.50667 12.04 7.82667 11.8467 8.02L9.04666 10.82C8.94 10.9267 8.8 10.98 8.64666 10.9667C8.5 10.9533 8.36666 10.8733 8.28 10.7467L7.55333 9.65333L5.85333 11.3533C5.75333 11.4533 5.62666 11.5 5.5 11.5Z"
        fill="#FCFCFD"
      />
      <path
        d="M11.5 9.5C11.2267 9.5 11 9.27333 11 9V8.16667H10.1667C9.89334 8.16667 9.66667 7.94 9.66667 7.66667C9.66667 7.39333 9.89334 7.16667 10.1667 7.16667H11.5C11.7733 7.16667 12 7.39333 12 7.66667V9C12 9.27333 11.7733 9.5 11.5 9.5Z"
        fill="#FCFCFD"
      />
    </svg>
  );
};

export default DashboardIcon;
