import blankPic from "../../img/blankPic.png";
import { initState, types } from "./types";
import { GET_IMAGE } from "../../helpers/images";

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_NOTIFICATION:
      return {
        ...state,
        error: null,
      };
    case types.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.CREATE_NOTIFICATION_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_USER_NOTIFICATION:
      return {
        ...state,
        fetchingNotification: true,
        error: null,
      };
    case types.GET_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        unreadNotifications: action.unreadNotifications,
        fetchingNotification: false,
        error: null,
      };
    case types.GET_USER_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: [],
        unreadNotifications: [],
        fetchingNotification: false,
        error: action.error,
      };
    case types.ADD_NOTIFICATION:
      /* eslint-disable */
      const data = {
        ...action.payload,
        image: action.payload.image
          ? GET_IMAGE(action.payload.image)
          : blankPic,
        timeElapsed: "Just now",
      };
      return {
        ...state,
        notifications: [data, ...state.notifications],
        unreadNotifications: [data, ...state.unreadNotifications],
      };
    case types.UPDATE_ALL_NOTIFICATION_STATUS_UI_SUCCESS:
      const notis = state.notifications.map((item) => ({
        ...item,
        is_read: true,
      }));
      return {
        ...state,
        notifications: [...notis],
        unreadNotifications: [],
        error: null,
      };
    case types.UPDATE_ALL_NOTIFICATION_STATUS:
      return {
        ...state,
        error: null,
      };
    case types.UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.UPDATE_ALL_NOTIFICATION_STATUS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case types.UPDATE_NOTIFICATION_READ_STATUS_UI_SUCCESS:
      const notiUnread = state.notifications.map((item) => ({
        ...item,
        is_read: false,
      }));
      return {
        ...state,
        notifications: [...notiUnread],
        unreadNotifications: [...notiUnread],
        error: null,
      };
    case types.UPDATE_NOTIFICATION_READ_STATUS:
      return {
        ...state,
        error: null,
      };
    case types.UPDATE_NOTIFICATION_READ_STATUS_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.UPDATE_NOTIFICATION_READ_STATUS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case types.READ_NOTIFICATION:
      const updateNotifications = state.notifications.map((noti) => {
        if (noti.notiId === action.payload) {
          return {
            ...noti,
            is_read: true,
          };
        } else {
          return noti;
        }
      });
      const updatedUnreadNotifications = state.unreadNotifications.filter(
        (noti) => noti.notiId !== action.payload,
      );
      return {
        ...state,
        notifications: [...updateNotifications],
        unreadNotifications: [...updatedUnreadNotifications],
        error: null,
      };
    case types.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.READ_NOTIFICATION_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case types.DELETE_NOTIFICATION:
      const updatedNotifications = state.notifications.filter(
        (noti) => noti.notiId !== action.payload,
      );
      const updatedUnreadNotification = state.unreadNotifications.filter(
        (noti) => noti.notiId !== action.payload,
      );

      return {
        ...state,
        notifications: [...updatedNotifications],
        unreadNotifications: [...updatedUnreadNotification],
        error: null,
      };
    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
