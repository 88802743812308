/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { Dialog, Divider, Grid } from "@mui/material";
import { UseUserDetails } from "../../hooks/auth";
import SoftProfile from "../ProfilePictures/SoftProfile";
import { useTheme } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SoftButton from "../SoftButton";
import GoldCrown from "../../img/arena/GoldCrown.svg";
import ChallengeCountdown from "../ChallengeArena/ChallengeCountdown";
import useMediaQuery from "@mui/material/useMediaQuery";

const ChallengeInfo = ({
  children,
  isOpponent,
  isChallenge,
  challenge,
  challengeScore,
  countdownEnded,
  setCountdownEnded,
}) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const user = UseUserDetails();
  const theme = useTheme();

  const handleOpen = (event) => {
    if (!event.target.disabled) {
      setOpenInfo(true);
    }
  };

  const handleClose = () => {
    setOpenInfo(false);
  };

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const mdBp = useMediaQuery(() => theme.breakpoints.up("md"));
  const xsBp = useMediaQuery(() => theme.breakpoints.up("xs"));

  useEffect(() => {
    if (challengeScore) {
      challengeScore.reduce((prev, current) => {
        // eslint-disable-next-line no-unused-expressions
        prev.total > current.total
          ? setCurrentLead(prev)
          : setCurrentLead(current);
      });
    }
  }, [challengeScore]);

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleOpen,
        });
      })}
      <Dialog
        open={openInfo}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
            boxShadow:
              "-24px 24px 64px 0px rgba(6, 41, 64, 0.60), -48px 48px 128px 0px rgba(6, 41, 64, 0.60)",
            backdropFilter: "blur(80px)",
            "-webkit-backdrop-filter": "blur(80px)",
            background: "rgba(255, 255, 255, 0.04)",
            border: "1px solid rgba(255, 255, 255, 0.10)",
            borderRadius: "16px",
            padding: { mini: "1rem", sm: "2rem" },
            margin: { mini: "12px", md: "32px" },
          },
        }}
      >
        <SoftBox
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: { mini: "center", md: "start" },
            alignItems: "center",
          }}
        >
          <SoftTypography variant={mdBp ? "h3" : "h5"} color="white">
            {challenge?.title ?? "Test Challenge"}
          </SoftTypography>
          <SoftButton
            variant="outlined"
            color="white"
            onClick={handleClose}
            sx={{
              "& svg": { fontSize: "1.25rem !important" },
              borderRadius: "50%",
              minWidth: "max-content",
              minHeight: "max-content",
              padding: "0.25rem",
              position: "absolute",
              top: { mini: "1.3rem", md: "2.3rem" },
              right: { mini: "1rem", md: "2rem" },
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>
        <Grid container spacing={2}>
          <Grid item mini={12} md={6}>
            <SoftBox sx={{ textAlign: { mini: "center", md: "start" } }}>
              <SoftTypography
                variant="button"
                color="white"
                sx={{
                  maxWidth: "max-content",
                  background: "rgba(255, 255, 255, 0.10)",
                  border: "1px solid rgba(255, 255, 255, 0.20)",
                  borderRadius: "6px",
                  padding: { mini: "0.15rem 0.5rem", md: "0.25rem 0.75rem" },
                  marginBottom: "1rem",
                  display: "block",
                  marginX: { mini: "auto", md: "0" },
                }}
              >
                RULES
              </SoftTypography>
              <SoftTypography
                variant="body2"
                color="white"
                sx={{ display: "block", fontSize: "0.9rem" }}
              >
                {challenge?.description ?? "Description not available"}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item mini={12} md={6}>
            <SoftBox>
              <ChallengeCountdown
                challenge={challenge}
                countdownEnded={countdownEnded}
                setCountdownEnded={setCountdownEnded}
                isWord={false}
                isCentered={false}
                isBigWord
                isMarginCentered={false}
                noBorder={false}
              />
            </SoftBox>
          </Grid>
        </Grid>
        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "3px",
            },
          }}
        />

        <Grid container spacing={2}>
          <Grid item mini={6}>
            <SoftBox sx={{ margin: "auto", textAlign: "center" }}>
              <SoftProfile
                src={
                  challenge?.category === "EmployeeVsEmployee"
                    ? user.image
                    : challengeScore
                        ?.filter((comp) => comp.companyId === user.companyId)
                        .map((comp) => comp.image)
                }
                alt=""
                size={mdBp ? "xl" : xsBp ? "lg" : "md"}
                sx={{
                  border: "3px solid white",
                  margin: "auto",
                  marginBottom: "0.5rem",
                }}
              />
              <SoftTypography
                variant={mdBp ? "h4" : xsBp ? "h5" : "h6"}
                color="white"
              >
                {challenge?.category === "EmployeeVsEmployee"
                  ? `${user.firstName} ${user.lastName}`
                  : challengeScore
                      ?.filter((comp) => comp.companyId === user.companyId)
                      .map((comp) => comp.company)}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item mini={6}>
            <SoftBox sx={{ margin: "auto", textAlign: "center" }}>
              <SoftProfile
                src={
                  challenge?.category === "EmployeeVsEmployee"
                    ? challengeScore.image
                    : challengeScore
                        ?.filter((comp) => comp.companyId !== user.companyId)
                        .map((comp) => comp.image)
                }
                alt=""
                size={mdBp ? "xl" : xsBp ? "lg" : "md"}
                sx={{
                  border: "3px solid white",
                  margin: "auto",
                  marginBottom: "0.5rem",
                }}
              />
              {isOpponent ? (
                <SoftTypography
                  variant={mdBp ? "h4" : xsBp ? "h5" : "h6"}
                  color="white"
                >
                  {challenge?.category === "EmployeeVsEmployee"
                    ? challengeScore
                        ?.filter((comp) => comp.companyId !== user._id)
                        .map(
                          (comp) =>
                            `${comp.firstName ? comp.firstName : ""} 
                            ${comp.lastName ? comp.lastName : ""}
                            `,
                        )
                    : challengeScore
                        ?.filter((comp) => comp.companyId !== user.companyId)
                        .map((comp) => comp.company)}
                </SoftTypography>
              ) : (
                <SoftTypography
                  variant={mdBp ? "h3" : xsBp ? "h5" : "h6"}
                  color="white"
                >
                  -
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
        </Grid>

        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "3px",
            },
          }}
        />
        <SoftBox sx={{ margin: "auto", textAlign: "center" }}>
          {isChallenge ? (
            <SoftTypography
              variant={mdBp ? "h6" : "button"}
              color="white"
              marginBottom="0.5rem"
            >
              WINNER
            </SoftTypography>
          ) : (
            <SoftTypography
              variant={mdBp ? "h6" : "button"}
              color="white"
              marginBottom="0.5rem"
            >
              CURRENTLY IN THE LEAD
            </SoftTypography>
          )}
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              marginBottom: isOpponent ? "0.5rem" : 0,
            }}
          >
            <img src={GoldCrown} alt="" />
            <SoftTypography variant={isOpponent ? "h4" : "h1"} color="white">
              {isOpponent ? currentLead?.company : "-"}
            </SoftTypography>
          </SoftBox>
          {isOpponent && (
            <SoftTypography
              color="white"
              variant={mdBp ? "h5" : "h6"}
              sx={{
                borderRadius: "30px",
                background: "rgba(0, 0, 0, 0.16)",
                padding: "4.5px 9px",
              }}
            >
              {currentLead?.total} Points
            </SoftTypography>
          )}
        </SoftBox>
      </Dialog>
    </>
  );
};

export default ChallengeInfo;
