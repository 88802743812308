import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";

const ActivateModal = ({ closeModal, children }) => {
  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography
          variant="h4"
          color="white"
          sx={{ fontSize: "20px", fontWeight: 500 }}
        >
          Activate Widget
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeModal}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      {children}
    </>
  );
};

export default ActivateModal;
