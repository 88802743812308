import React, { useMemo, useState, useEffect } from "react";
import toast from "react-hot-toast";

// Soft UI Components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, Divider, useTheme } from "@mui/material";
import SwitchModal from "../AppSwitch/SwitchModal";
import popNotification from "../Modals/popNotification";

// API services
import { SET_GROUP_KPI_ACCESS } from "../../api/kpi";
import { SET_KPI_PREFERENCES } from "../../api/preference";

const metricScoreData = [
  {
    group: "Default score entry mode",
    title: "Total Score Entry Mode",
    content: "This mode is useful when you want to update the metric to an exact or total value",
    key: "isTotalScore",
    isCheck: false
  },
  {
    group: "Default score entry mode",
    title: "Incremental Score Entry Mode",
    content: "This mode is beneficial when you want to track changes over time. For instance, if you want to add or deduct revenue for a specific event or transaction",
    key: "isIncremental",
    isCheck: false
  },
  {
    group: "Scoring Access",
    title: "Admin-only score entry",
    content: "Switching off this option prevents metric input from Players",
    key: "isAdminAccess",
    isCheck: false
  }
];

const MetricScoreConfig = ({
  workspaceId,
  kpiId,
  data,
  openConfig,
  handleClose,
  handleOnSuccess
}) => {
  const theme = useTheme();
  const [helperText, setHelperText] = useState(null);
  const [localSettings, setLocalSettings] = useState(null);

  useEffect(() => {
    if (localSettings) return;

    const settings = metricScoreData
      .map(x => x.key)
      .reduce((list, key) => ({
        ...list, [key]: data[key]
      }), {});

    setLocalSettings(settings);
  }, [localSettings, data]);

  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const isOpen = useMemo(() => openConfig, [openConfig]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const scoreEntryMode = () => {
      if (localSettings.isTotalScore && localSettings.isIncremental) {
        return "All";
      } else if (localSettings.isTotalScore) {
        return "TotalScore";
      } else if (localSettings.isIncremental) {
        return "Incremental";
      } else {
        return "None";
      }
    }

    const payloadRestriction = {
      kpiId,
      isAdminAccess: localSettings.isAdminAccess
    }

    const scoreEntryPayload = {
      kpiId,
      groupId: workspaceId,
      entryMode: scoreEntryMode()
    }

    if (scoreEntryPayload.entryMode === "None") {
      setHelperText("Please select a Default score entry mode.");
      return;
    }

    try {
      await SET_GROUP_KPI_ACCESS(workspaceId, payloadRestriction);
      await SET_KPI_PREFERENCES(scoreEntryPayload);

      handleOnSuccess?.({
        kpiId,
        scoreEntryMode: scoreEntryPayload.entryMode,
        isAdminAccess: localSettings.isAdminAccess
      });

      popNotification({
        title: "Success",
        text: "Your metric score configuration was saved."
      });
    } catch (error) {
      toast.error("There was an error saving data.");
    }

    handleClose?.();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        margin: "auto",
        width: { xs: "100vw", sm: "60vw" },
        ".MuiPaper-root": {
          borderRadius: "10px",
          width: "100%",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-15.85294px -15.85294px 26.42157px 0px rgba(0, 0, 0, 0.20) inset, 15.85294px 15.85294px 31.70588px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(20px)",
          padding: { mini: "1rem", md: "1.5rem" },
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h4" color="white">
          Metric Score Configuration
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
          "&:not([size])": {
            height: "2px",
          },
        }}
      />
      <form onSubmit={handleSubmit}>
        <SoftBox marginTop="1.5rem" marginBottom="0.5rem">
          {[...new Set(metricScoreData.map(x => x.group))]
            .map((group) => (
            <div key={crypto.randomUUID()}>
              <SoftTypography
                variant="body2"
                mb="0.5rem"
                sx={{
                  color: "#CED4DA",
                }}
              >
                {group}
              </SoftTypography>

              {metricScoreData.map((item) => {
                if (item.group === group) {
                  return <SoftBox
                    key={crypto.randomUUID()}
                    marginBottom="1rem"
                    borderRadius="4px"
                    sx={{
                      padding: "1rem",
                      background: "rgba(255, 255, 255, 0.07)",
                      border: "1px solid rgba(233, 236, 239, 0.10)",
                    }}
                  >
                    <SoftBox
                      marginBottom="0.25rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <SoftTypography variant="h6" color="white">
                        {item.title}
                      </SoftTypography>

                      <SwitchModal
                        id={item.key}
                        name={item.key}
                        defaultChecked={localSettings?.[item?.key]}
                        onChange={(e) => {
                          const key = e.target.name;
                          const update = {};

                          if (key === "isAdminAccess") {
                            update[key] = e.target.checked;
                          }

                          if (key === "isTotalScore") {
                            update[key] = e.target.checked;
                            update.isIncremental = !e.target.checked;
                          } else if (key === "isIncremental") {
                            update[key] = e.target.checked;
                            update.isTotalScore = !e.target.checked;
                          }

                          setLocalSettings(prev => ({ ...prev, ...update }));
                        }}
                      />
                    </SoftBox>
                    <SoftTypography
                      variant="button"
                      sx={{ color: "#CED4DA", fontWeight: 400 }}
                    >
                      {item.content}
                    </SoftTypography>
                  </SoftBox>
                }
              })}
            </div>
          ))}

          <SoftBox sx={{ color: "#ea0606", fontSize: "12px" }}>
            {helperText}
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="2.5rem"
        >
          <SoftButton
            size="large"
            variant="gradient"
            sx={{ textTransform: "none" }}
            color="primary"
            type="submit"
          >
            Update
          </SoftButton>
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default MetricScoreConfig;
