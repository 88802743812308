import React from "react";

const ScorekeeperIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 15.1667H2C1.36 15.1667 0.833336 14.64 0.833336 14V5.33334C0.833336 2.38668 2.38667 0.833344 5.33334 0.833344H10.6667C13.6133 0.833344 15.1667 2.38668 15.1667 5.33334V10.6667C15.1667 13.6133 13.6133 15.1667 10.6667 15.1667ZM5.33334 1.83334C2.94667 1.83334 1.83334 2.94668 1.83334 5.33334V14C1.83334 14.0933 1.90667 14.1667 2 14.1667H10.6667C13.0533 14.1667 14.1667 13.0533 14.1667 10.6667V5.33334C14.1667 2.94668 13.0533 1.83334 10.6667 1.83334H5.33334Z"
        fill="#F9FAFB"
      />
      <path
        d="M5.29983 11.8333C4.9865 11.8333 4.69983 11.72 4.4865 11.5133C4.23316 11.26 4.11982 10.9 4.17982 10.5133L4.36648 9.19334C4.40648 8.90667 4.58649 8.54 4.79316 8.33334L8.25316 4.87334C9.43983 3.68667 10.4732 4.22 11.1265 4.87334C11.6398 5.38667 11.8732 5.92667 11.8198 6.46667C11.7798 6.90667 11.5465 7.32 11.1265 7.74667L7.66649 11.2067C7.45982 11.4133 7.09982 11.5933 6.80648 11.64L5.4865 11.8267C5.4265 11.8267 5.35983 11.8333 5.29983 11.8333ZM9.68649 5.16667C9.43982 5.16667 9.21983 5.32667 8.9665 5.57334L5.5065 9.03334C5.45316 9.08667 5.37316 9.25334 5.35983 9.32667L5.17316 10.6467C5.1665 10.7133 5.17315 10.7733 5.19981 10.8C5.22648 10.8267 5.28649 10.8333 5.35316 10.8267L6.67316 10.64C6.75316 10.6267 6.91316 10.5467 6.9665 10.4933L10.4265 7.03334C10.6798 6.78 10.8132 6.56 10.8332 6.36C10.8532 6.13334 10.7198 5.86667 10.4265 5.57334C10.1332 5.29334 9.89982 5.16667 9.68649 5.16667Z"
        fill="#F9FAFB"
      />
      <path
        d="M10.2799 8.38664C10.2332 8.38664 10.1865 8.37997 10.1465 8.36664C8.93987 8.02664 7.97321 7.06664 7.63321 5.8533C7.55988 5.58664 7.71321 5.3133 7.97987 5.2333C8.24654 5.15997 8.51988 5.3133 8.59321 5.57997C8.83988 6.4533 9.53987 7.1533 10.4132 7.39997C10.6799 7.4733 10.8332 7.7533 10.7599 8.01997C10.6999 8.23997 10.4999 8.38664 10.2799 8.38664Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ScorekeeperIcon;
