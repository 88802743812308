import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(160deg, #7928ca, #ff0080)",

    "@media screen and (max-width: 767px)": {
      height: "100%",
      background: "#fff",
    },
  },

  loginContainer: {
    background: "white",
    padding: "20px",
    borderRadius: "4rem",
    "@media screen and (max-width: 575px)": {
      paddingLeft: "30px",
      paddingRight: "30px",
    },

    "@media screen and (max-width: 767px)": {
      padding: "40px 20px",
    },
  },

  responsiveContainer: {
    width: "100%",
    marginLeft: "50px",
    marginRight: "50px",
    padding: "2rem",
    "@media screen and (max-width: 1400px)": {
      maxWidth: "1320px",
    },

    "@media screen and (max-width: 1200px)": {
      maxWidth: "1140px",
    },

    "@media screen and (max-width: 992px)": {
      maxWidth: "960px",
    },

    "@media screen and (max-width: 768px)": {
      maxWidth: "720px",
      margin: "50px",
      padding: "1rem",
    },

    "@media screen and (max-width: 576px)": {
      maxWidth: "540px",
      margin: "50px",
      padding: "1rem",
    },
  },

  gridContainer: {
    "@media screen and (min-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "1fr 1.5fr",
    },
  },

  signInBoxContainer: {
    display: "grid",
    alignItems: "center",
    borderRight: "1px solid rgba(210, 210, 210, 0.57)",
    paddingBottom: 0,
    "@media screen and (max-width: 991px)": {
      borderRight: "none",
    },

    "@media screen and (max-width: 768px)": {
      borderBottom: "1px solid rgba(210, 210, 210, 0.57)",
      paddingBottom: "50px",
    },
  },

  logoStyle: {
    width: "95%",
    marginBottom: "10px",
  },

  formContainer: {
    maxWidth: "380px",
    margin: "0 auto",
  },

  formHeading: {
    color: "#12498e",
    fontWeight: "bold",
  },

  formText: {
    color: "#ACACAC",
    marginTop: "1rem",
  },

  textBold: {
    fontWeight: "700 !important",
  },

  textCentered: {
    textAlign: "center",
  },

  leftPane: {
    height: "100vh",
  },
  rightPane: {
    backgroundColor: "#7928CA !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "20px",
    left: "50px",
  },
  sliderItemOne: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media screen and (max-width: 768px)": {
      width: "90%",
    },
  },
  loginGridContainer: {
    height: "100vh !important",
  },
  sliderItemTwo: {
    width: "60%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media screen and (max-width: 768px)": {
      width: "90%",
    },
  },
  gameboardContainer: {
    background:
      "linear-gradient(181.36deg, rgba(255, 255, 255, 0.27) 1.16%, rgba(205, 183, 235, 0.04) 83.38%, rgba(227, 224, 230, 0.06) 98.84%) !important",
    border: "1px solid",
    borderImageSource:
      "linear-gradient(180deg, rgba(236, 239, 244, 0.51) 0%, rgba(208, 213, 221, 0) 100%)",
    boxShadow: "0px 12px 16px -4px #10182814",
    borderRadius: "8px",
    width: "max-content",
    padding: "140px 60px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    color: "#FFF",
    marginBottom: "20px",
  },
  title: {
    color: "#FFF !important",
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "15px",
  },
  subText: {
    color: "#FFF !important",
    fontWeight: 500,
  },
  bottomTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
});
