import React from "react";
import PaymentPage from "../../components/Billing/Payment";

const Payment = () => {
  return (
    <div>
      <div>
        {/* Page Content */}
        <div className="container mt-[2.5rem] mb-[2.5rem] pl-[12px] md:pl-[72px]">
          <PaymentPage newUser={false} />
        </div>
      </div>
    </div>
  );
};

export default Payment;
