import { styled } from "@mui/material/styles";

export const GlowEffect = styled("div")(({ theme, borderRadius, disabled = false }) => {
  const { borders } = theme;
  const { borderRadius: radii } = borders;

  return {
    animation: disabled ? "none" : "glowAnim 3500ms infinite",
    animationDelay: "2500ms",
    "@keyframes glowAnim": {
      "0%": {
        boxShadow: `0 0 5px 2px ${theme.palette.primary.main}`,
      },
      "50%": {
        boxShadow: `0 0 20px 4px ${theme.palette.primary.light}`,
      },
      "100%": {
        boxShadow: `0 0 2px 0px ${theme.palette.primary.lighter}`,
      },
    },
    borderRadius: radii[borderRadius] ?? radii.section,
  };
});
