import React from "react";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import Image from "../../components/Image";
import lockedGif from "../../img/locked-gradient.gif";
import LockedOverlay from "./LockedOverlay";

const GameBoardCardV2 = ({
  title = "Gameboard Card",
  buttonLabel = "Locked",
  footerLabel = "Footer",
  description = "Text content here",
  isLocked = true,
  isSelected = false,
  cardImageURL,
  cardImageBlurURL,
  imageStyles = null
}) => {
  return (
    <SoftBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "431px",
        minHeight: "469px",
        borderRadius: "8px",
        border: (isSelected)
          ? "2px solid #7928CA"
          : "1.5px solid #F2F4F7"
      }}
    >
      {/** Card Title and Label */}
      <SoftBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily: "DM Sans",
          fontStyle: "normal",
          backgroundColor: "#FCFCFD",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          padding: "19px 24px",
          paddingBottom: "20px"
        }}
      >
        <SoftTypography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "#000",
          }}
        >
          {title}
        </SoftTypography>

        <SoftBox
          sx={{
            fontWeight: 700,
            fontSize: "15px",
            padding: "4px 10px",
            borderRadius: "3px",
            color: "#FFF",
            height: "fit-content",
            backgroundColor: (isSelected)
              ? "#7928CA"
              : "#344054"
          }}
        >
          {buttonLabel}
        </SoftBox>
      </SoftBox>

      {/** Card description and picture area */}
      <SoftBox sx={{
        backgroundColor: "#FFF",
        padding: "12px 24px",
        paddingBottom: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        <SoftTypography
          sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#667085",
            maxWidth: "353px",
            paddingBottom: "32px"
          }}
        >
          {description}
        </SoftTypography>

        {/** Locked GIF overlay */}
        <SoftBox sx={{ position: "relative" }} >
          {(isLocked) && <LockedOverlay /> }

          {/** Picture */}
          <Image
            imageSrc={cardImageURL}
            blurImageSrc={cardImageBlurURL}
            height={244}
            imageStyles={imageStyles}
            containerStyles={{
              borderRadius: "8px",
              filter: "brightness(112%) opacity(0.8)",
              ...(isSelected && ({
                border: "1px solid #F2F4F7",
                boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)"
              }))
            }}
          />
        </SoftBox>
      </SoftBox>

      {/** Card Footer */}
      <SoftBox
        sx={{
          padding: "12px 24px",
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "30px",
          color: "#475467"
        }}
      >
        {footerLabel}
      </SoftBox>
    </SoftBox>
  );
};

export default GameBoardCardV2;
