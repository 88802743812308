
/* eslint camelcase: 0 */
import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import LoginForm from "../../components/Login/LoginForm";
import { UseAuth, UseUserDetails } from "../../hooks/auth";
import { AUTH_ZAPIER } from "../../api/auth";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../redux/action";
import { capitalize } from "lodash";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import loginLogo from "../../img/GameMyBiz.png";
import { CircularProgress } from "@mui/material";

const AuthWindow = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const authStatus = UseAuth();
  const authUser = UseUserDetails();

  const [searchParams] = useSearchParams();
  const {type} = useParams();

  const client_id = searchParams.get("client_id");
  const state = searchParams.get("state");
  const redirect_uri = searchParams.get("redirect_uri");

  const logoutUser = () => {
    dispatch(signOutUser());
    setIsLogged(false);
    setError(null);
  };

  const handleAuth = useCallback(() => {
    if(client_id && redirect_uri && state && authUser) {
      setIsLoading(true);
      AUTH_ZAPIER({client_id, redirect_uri, companyId: authUser.companyId })
        .then(({data: code})=> {
          window.location.replace(`${redirect_uri}?state=${state}&code=${code}`);
        })
        .catch((err) => {
          const {data: {message}} = err.response;
          setError(message);
          setIsLoading(false);
        });
    }
  }, [client_id, state, redirect_uri, authUser]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    if(user.userId) {
      setIsLogged(true);
      setIsAdmin(user.isAdmin);
      setUsername(user.username);
    }
  }, [])
  
  useEffect(() => { // Hook when a new user logs in
    if(!isLogged && authStatus) {
      setUsername(authUser.username);
      setIsAdmin(authUser.isAdmin);
      setIsLogged(true);
    }
  }, [isLogged, authStatus, authUser, handleAuth])
  

  return (
    <Box className="flex items-center justify-center h-[100vh] w-[100vw] items-center px-16 py-16">
      <Stack className="flex items-center" spacing={2}>
        <img src={loginLogo} alt="Game My Biz" />
        <Typography variant="body1" sx={{ color: "#12498e", fontWeight: "bold", mt: 8}}>
          {capitalize(type)} wants to access your data
        </Typography>
        {
          isLogged && isAdmin &&
            <>
              <Button size="large" variant="contained" onClick={handleAuth} sx={{mt: 8, minWidth: "80%"}}>
                {isLoading? <CircularProgress color="inherit" size="1.75rem" /> : `Authorize as ${username}`}
              </Button>
              <Typography sx={{mt: 8, textDecoration: "underline", cursor: "pointer", color: "blue"}} onClick={logoutUser}>
                Or sign is as a different user
              </Typography>
            </>
        }
        {
          isLogged && !isAdmin &&
            <>
              <Typography variant="body1" sx={{mt: 4, color: "#FF0000"}}>
                {username} is not an Admin.
              </Typography>
              <Typography variant="body2">
                Admin access is required.
              </Typography>
              <Button size="large" variant="contained" onClick={logoutUser}>
                Sign is as a different user
              </Button>
            </>
        }
        {
          !isLogged && !error &&
            <Box sx={{minWidth: {mini: "280px", sm: "400px"}}}>
              <LoginForm authMode={true} />
            </Box>
        }
        {
          error &&
            <Typography variant="body1" sx={{mt: 2, color: "#FF0000"}}>
              {error}
            </Typography>
        }
      </Stack>
    </Box>
  );
}

export default AuthWindow;