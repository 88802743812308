export const initState = {
  workspaces: [],
  selectedWorkspace: null,
  isLoading: false
};

export const types = {
  GET_ALL_WORKSPACES: "GET_ALL_WORKSPACES",
  GET_ALL_WORKSPACES_SUCCESS: "GET_ALL_WORKSPACES_SUCCESS",
  GET_ALL_WORKSPACES_FAILED: "GET_ALL_WORKSPACES_FAILED",
  SET_SELECTED_WORKSPACE: "SET_SELECTED_WORKSPACE",
  SET_SELECTED_WORKSPACE_SUCCESS: "SET_SELECTED_WORKSPACE_SUCCESS",
  SET_SELECTED_WORKSPACE_FAILED: "SET_SELECTED_WORKSPACE_SUCCESS",
  SET_WORKSPACES: "SET_WORKSPACES",
};
