import React from "react";

const ChallengeArenaIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.09998 12.9C7.82664 12.9 7.59998 12.6733 7.59998 12.4V11C7.59998 10.7267 7.82664 10.5 8.09998 10.5C8.37331 10.5 8.59998 10.7267 8.59998 11V12.4C8.59998 12.6733 8.37331 12.9 8.09998 12.9Z"
        fill="#F9FAFB"
      />
      <path
        d="M11.9333 15.1667H4.26666V14C4.26666 12.9867 5.08666 12.1667 6.1 12.1667H10.1C11.1133 12.1667 11.9333 12.9867 11.9333 14V15.1667ZM5.26666 14.1667H10.9333V14C10.9333 13.54 10.56 13.1667 10.1 13.1667H6.1C5.64 13.1667 5.26666 13.54 5.26666 14V14.1667Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.1 15.1667H4.09998C3.82664 15.1667 3.59998 14.94 3.59998 14.6667C3.59998 14.3933 3.82664 14.1667 4.09998 14.1667H12.1C12.3733 14.1667 12.6 14.3933 12.6 14.6667C12.6 14.94 12.3733 15.1667 12.1 15.1667Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.2867 8.29332C12.1467 8.29332 12.0067 8.23332 11.9067 8.11999C11.78 7.97332 11.7467 7.76666 11.8267 7.59332C12.0533 7.07332 12.1667 6.51999 12.1667 5.93999V3.93999C12.1667 3.70665 12.1267 3.47999 12.0467 3.23999C12.04 3.21999 12.0333 3.19332 12.0267 3.16666C12.0067 3.06665 12 2.96665 12 2.87332C12 2.59999 12.2267 2.37332 12.5 2.37332H12.9C14.0933 2.37332 15.0667 3.37332 15.0667 4.60666C15.0667 5.62666 14.6467 6.63332 13.92 7.35999C13.9067 7.37332 13.8667 7.40666 13.86 7.41332C13.4667 7.73999 13.02 8.10666 12.42 8.27332C12.3733 8.28666 12.3333 8.29332 12.2867 8.29332ZM13.12 3.39332C13.1533 3.57332 13.1667 3.75999 13.1667 3.93999V5.93999C13.1667 6.21332 13.1467 6.47332 13.1067 6.73999C13.1467 6.70666 13.18 6.67999 13.22 6.64666C13.7533 6.11332 14.0667 5.36666 14.0667 4.60666C14.0667 4.00665 13.66 3.49999 13.12 3.39332Z"
        fill="#F9FAFB"
      />
      <path
        d="M3.72002 8.26666C3.66668 8.26666 3.62002 8.25999 3.56668 8.23999C3.02002 8.06666 2.50668 7.74666 2.08002 7.31999C1.31335 6.47333 0.93335 5.54666 0.93335 4.56666C0.93335 3.35333 1.88668 2.39999 3.10002 2.39999H3.53335C3.70002 2.39999 3.86002 2.48666 3.95335 2.62666C4.04668 2.76666 4.06002 2.94666 3.99335 3.09999C3.88668 3.33999 3.83335 3.61333 3.83335 3.89999V5.89999C3.83335 6.47333 3.94668 7.03333 4.18002 7.56666C4.26002 7.74666 4.22002 7.95333 4.09335 8.09999C3.99335 8.20666 3.86002 8.26666 3.72002 8.26666ZM2.86668 3.41999C2.32668 3.52666 1.93335 3.99333 1.93335 4.56666C1.93335 5.29333 2.22668 5.99333 2.80668 6.63333C2.83335 6.66666 2.86668 6.69333 2.90002 6.71999C2.85335 6.44666 2.83335 6.17333 2.83335 5.89999V3.89999C2.83335 3.73999 2.84668 3.57999 2.86668 3.41999Z"
        fill="#F9FAFB"
      />
      <path
        d="M7.99998 11.1667C5.15331 11.1667 2.83331 8.84668 2.83331 6.00001V4.00001C2.83331 2.25334 4.25331 0.833344 5.99998 0.833344H9.99998C11.7466 0.833344 13.1666 2.25334 13.1666 4.00001V6.00001C13.1666 8.84668 10.8466 11.1667 7.99998 11.1667ZM5.99998 1.83334C4.80665 1.83334 3.83331 2.80668 3.83331 4.00001V6.00001C3.83331 8.30001 5.69998 10.1667 7.99998 10.1667C10.3 10.1667 12.1666 8.30001 12.1666 6.00001V4.00001C12.1666 2.80668 11.1933 1.83334 9.99998 1.83334H5.99998Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ChallengeArenaIcon;
