import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import LinearProgress from "@mui/material/LinearProgress";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import { VERIFY_PAYMENT } from "../../api/payment";
import { formatCurrency } from "../../helpers/formatter";
import stripeimg from "../../img/stripeimg.png";

const PaymentForm = ({
  newUser,
  subscription,
  trialDays,
  coupon,
  handleResult,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [hasTrial, setHasTrial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setHasTrial(newUser && !!trialDays);
  }, [newUser, trialDays]);

  useEffect(() => {
    if (subscription) {
      let currentPrice = subscription.amount;
      if (coupon?.amount_off) {
        currentPrice -= coupon.amount_off * 1;
      } else if (coupon?.percent_off) {
        currentPrice *= 1 - coupon.percent_off / 100;
      }
      setPrice(currentPrice);
    }
  }, [subscription, coupon]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let result = null;
    if (hasTrial) {
      result = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href, // If redirect happens, reload the page
        },
      });
    } else {
      result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href, // If redirect happens, reload the page
        },
      });
    }

    const { paymentIntent, setupIntent, error: stripeError } = result;
    const intent = hasTrial ? setupIntent : paymentIntent;
    const action = hasTrial ? "Setup" : "Payment";

    if (intent?.status === "requires_action") {
      handleResult(
        "Kindly complete the action requested by the payment provider.",
        "info",
      );
    } else if (intent?.status === "processing") {
      handleResult(
        `Your ${action} is being processed. Your account will be updated once completed.`,
        "info",
      );
    } else if (stripeError) {
      setError(stripeError.message);
      handleResult(stripeError.message, "error");
      setIsLoading(false);
    } else {
      VERIFY_PAYMENT(intent.id, hasTrial)
        .then(({ data: { success } }) => {
          if (success) {
            handleResult(`${action} successfully processed.`, "success");
          } else {
            const errorMsg = `${action} unverified.`;
            setError(errorMsg);
            handleResult(errorMsg, "error");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMsg = err.response.data ?? err.message ?? err;
          setError(errorMsg);
          handleResult(errorMsg, "error");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="p-8">
      <form id="payment-form">
        <PaymentElement id="payment-element" />
        <img src={stripeimg} alt="Powered by Stripe" />
        <SoftButton
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
          variant="contained"
          className="my-2"
          style={{
            borderRadius: "8px",
            background:
              "linear-gradient(120deg, rgba(0, 36, 224, 0.65) 0%, rgba(166, 0, 224, 0.85) 100%)",
            color: "#FFFFFF",
            width: "100%",
          }}
        >
          {hasTrial ? "ACTIVATE MY BETA ACCOUNT" : "Pay Now"}
        </SoftButton>
        {error && <p className="text-danger text-xs font-semibold">{error}</p>}
        {isLoading ? <LinearProgress /> : null}
      </form>
    </div>
  );
};

export default PaymentForm;
