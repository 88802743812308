import React, { useEffect, useState } from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { Dialog } from "@mui/material";
import { UseUserId } from "../../hooks/auth";
import SoftProfile from "../ProfilePictures/SoftProfile";
import { useTheme } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SoftButton from "../SoftButton";
import SearchBar from "../SearchBar";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import { UseSelectedWorkspace } from "../../hooks/company";
import { GET_USER_BY_GROUPID } from "../../api/user";
import { GET_IMAGE } from "../../helpers/images";

const ChallengeInvite = ({ children }) => {
  const [openInvite, setOpenInvite] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [fetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [previousUsers, setPreviousUsers] = useState([]);

  const { _id: groupId } = UseSelectedWorkspace();
  const userId = UseUserId();
  const theme = useTheme();

  const fetchUsersByGroupId = async () => {
    setFetching(true);
    try {
      const { data } = await GET_USER_BY_GROUPID(groupId);
      const filteredUsers = data.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setPreviousUsers(filteredUsers);
    } catch (error) {
      console.log(error);
    }
    setFetching(false);
  };

  const filterUser = () => {
    const filteredUsers = previousUsers.filter((item) => {
      const username = `${item.firstName} ${item.lastName}`;
      return username.toLowerCase().search(searchText.toLowerCase()) !== -1;
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    fetchUsersByGroupId();
  }, []);

  useEffect(() => {
    filterUser();
  }, [searchText]);

  const handleOpen = (event) => {
    if (!event.target.disabled) {
      setOpenInvite(true);
    }
  };

  const handleClose = () => {
    setOpenInvite(false);
  };

  const {
    primary: {
      main: primaryColor,
      lighter: brighterPrimaryColor,
      focus: lightPrimaryColor,
      darker: darkerPrimaryColor,
    },
  } = theme.palette;

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleOpen,
        });
      })}
      <Dialog
        open={openInvite}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
            boxShadow:
              "-24px 24px 64px 0px rgba(6, 41, 64, 0.60), -48px 48px 128px 0px rgba(6, 41, 64, 0.60)",
            backdropFilter: "blur(80px)",
            "-webkit-backdrop-filter": "blur(80px)",
            background: "rgba(255, 255, 255, 0.04)",
            border: "1px solid rgba(255, 255, 255, 0.10)",
            borderRadius: "16px",
            padding: { mini: "1rem", sm: "2rem" },
          },
        }}
      >
        <SoftBox
          sx={{
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SoftBox sx={{ textAlign: "center" }}>
            <SoftTypography variant="h3" color="white">
              Challenge your team members!
            </SoftTypography>
            <SoftTypography
              variant="body2"
              sx={{ display: "block", color: "rgba(255, 255, 255, 0.60)" }}
            >
              Challenge your friends!
            </SoftTypography>
          </SoftBox>

          <SoftButton
            variant="outlined"
            color="white"
            onClick={handleClose}
            sx={{
              "& svg": { fontSize: "1.25rem !important" },
              borderRadius: "50%",
              minWidth: "max-content",
              minHeight: "max-content",
              padding: "0.25rem",
              position: "absolute",
              right: { mini: "1rem", sm: "2rem" },
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>

        <SoftBox sx={{ marginBottom: "2rem" }}>
          <SearchBar
            placeholder="Search for team members..."
            size="large"
            icon={{
              component: <SearchSvg />,
              direction: "right",
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.04) !important",
              border: "1px solid rgba(255, 255, 255, 0.10)",
              "& .MuiInputBase-root": {
                background: "transparent !important",
                color: "white !important",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "white",
              },
              "& > div": {
                backgroundColor: "transparent !important",
              },
            }}
          />
        </SoftBox>

        <SoftBox
          sx={{
            borderRadius: "12px",
            border: "1px solid rgba(255, 255, 255, 0.10)",
            background: "rgba(255, 255, 255, 0.04)",
            padding: "1rem",
          }}
        >
          {users.map((user) => (
            <SoftBox
              key={user._id}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <SoftBox
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <SoftProfile
                  src={GET_IMAGE(user.image) || ""}
                  alt=""
                  size="md"
                />
                <SoftTypography variant="h6" color="white">
                  {user.firstName} {user.lastName}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  sx={{
                    textTransform: "none",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    padding: "0.5rem 0.75rem",
                    borderRadius: "32px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    background: "transparent",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor: "transparent",
                      borderColor: "primary.lighter",
                      boxShadow: `0px 0px 32px 0px ${brighterPrimaryColor}, 0px 0px 48px 0px ${lightPrimaryColor} inset`,
                      // background: `radial-gradient(50.00% 100.00% at 50.21% 100.00%, ${darkerPrimaryColor} 0%, rgba(28, 132, 207, 0.00) 100%), ${darkerPrimaryColor}`,
                      background:
                        "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)",
                      color: "white !important",
                    },
                  }}
                >
                  Challenge
                </SoftButton>
              </SoftBox>
            </SoftBox>
          ))}
        </SoftBox>
      </Dialog>
    </>
  );
};

export default ChallengeInvite;
