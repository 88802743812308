import React, { useState } from "react";
import { Modal, Box } from "@material-ui/core";
import { darken } from "@mui/material/styles";
import Swal from "sweetalert2";

import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components/SoftInput";
import GoldenBadge from "../../img/level_golden_badge.png";
import "../../components/Modals/swal-globals.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
};

const EditBadgeLevel = ({
  editPopUp,
  setEditPopUp,
  dataLevels,
  setLevelsData,
  board,
  setBoard,
}) => {
  const [values, setValues] = useState({
    levelName: board.columns[editPopUp.index].levelName,
    levelInfo: board.columns[editPopUp.index].levelInfo,
  });

  const cancelTrigger = () => {
    setEditPopUp({ open: false, index: 0 });
  };

  const saveTrigger = () => {
    const copy = [...board.columns];
    copy[editPopUp.index].levelName = values.levelName;
    copy[editPopUp.index].levelInfo = values.levelInfo;

    setBoard({
      columns: [...copy],
    });
    cancelTrigger();
  };

  const onChangeTrigger = (e) => {
    const { name, value } = e.target;
    const copy = { ...values };
    copy[name] = value;
    setValues(copy);
  };

  const triggerRemoveLevel = () => {
    Swal.fire({
      title: board.columns[editPopUp.index].cards.length
        ? "Are you really sure?"
        : "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: { container: "swal-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        cancelTrigger();
        const copy = [...board.columns];
        copy.splice(editPopUp.index, 1);

        for (let i = editPopUp.index; i < copy.length; i++) {
          copy[i].title = `Level ${i + 1}`;
        }

        setBoard({
          columns: [...copy],
        });

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <Modal
      open={editPopUp.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <SoftTypography
            variant="h6"
            sx={{
              fontSize: "18px",
              color: "#000",
              margin: 0,
              padding: 0,
              fontWeight: "500",
            }}
          >
            Level {editPopUp.index + 1} Details
          </SoftTypography>
          <SoftButton size="small" onClick={triggerRemoveLevel}>
            Remove Level
          </SoftButton>
        </SoftBox>
        <hr />
        <SoftTypography
          component="h3"
          sx={{
            fontSize: "18px",
            color: "#000",
            margin: 0,
            padding: 0,
            fontWeight: "500",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          Level Icon Preview
        </SoftTypography>
        <Box
          sx={{
            width: "100%",
            maxWidth: { mini: "300px", xs: "360px", md: "420px" },
            height: {
              mini: "140px",
              sm: "160px",
            },
            transition: "height 0.2s ease-out",
            background: `url(${dataLevels[editPopUp.index].lvlBackground})`,
            marginBottom: "20px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "25px 45px 45px 25px",
            display: "grid",
            gridTemplateColumns: "25px 1fr",
            cursor: "pointer",
            backdropFilter: "blur(7.5px)",
          }}
        >
          <Box
            sx={{
              width: "25px",
              height: "100%",
              backgroundColor: darken(
                dataLevels[editPopUp.index].lvlColor,
                0.25,
              ),
              borderRadius: "25px 0px 0px 25px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gridTemplateColumns: "0.75fr 1.25fr",
              borderRadius: "0px 45px 45px 0px",
              background: `linear-gradient(90deg, ${
                dataLevels[editPopUp.index].lvlBGColor
              } 15.37%, rgba(255, 255, 255, 0.34) 50.49%, ${
                dataLevels[editPopUp.index].lvlBGColor
              } 82.68%)`,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(12.5px)",
            }}
          >
            <Box
              sx={{
                maxWidth: "150px",
                transition: "max-width 0.2s ease-out",
                justifySelf: "center",
              }}
            >
              <img src={GoldenBadge} alt="" className="w-full" />
            </Box>
          </Box>
        </Box>
        <SoftInput
          type="text"
          name="levelName"
          value={values.levelName}
          sx={{
            marginBottom: "20px",
          }}
          onChange={onChangeTrigger}
        />
        <textarea
          className="form-control"
          name="levelInfo"
          id="exampleFormControlTextarea1"
          rows="4"
          value={values.levelInfo}
          onChange={onChangeTrigger}
        />
        <div className="my-3" style={{ display: "flex" }}>
          <SoftButton
            size="small"
            variant="gradient"
            onClick={saveTrigger}
            className="addBtn"
          >
            Save Changes
          </SoftButton>
          <SoftButton
            size="small"
            variant="gradient"
            onClick={cancelTrigger}
            className="cancelBtn"
          >
            Cancel
          </SoftButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditBadgeLevel;
