function menuImage(theme) {
  const { functions, palette, borders } = theme;

  const { borderRadius } = borders;

  return {
    display: "grid",
    placeItems: "center",

    "& img": {
      width: "100%",
      borderRadius: borderRadius.lg,
    },
  };
}

export { menuImage };
