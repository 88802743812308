import React from "react";
import UmTable from "./UmTable";
import SoftBox from "../../components/SoftBox";
import InfoBar from "../../components/InfoBar";
import SoftTypography from "../../components/SoftTypography";
import PageHeader from "../../components/PageHeader";
import { Grid } from "@mui/material";

// Imported Styles
import "./userManagementStyles.css";

const UserManagement = () => {
  return (
    <>
      <PageHeader
        headerText="Players"
        subheaderText="Manage players in the workspace"
      />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <Grid container>
          <Grid item mini={12}>
            <UmTable />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default UserManagement;
