import React from "react";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import { Divider, Grid, useTheme } from "@mui/material";
import OfferBox from "./OfferBox";

const Offers = () => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  return (
    <SoftBox borderRadius="16px" bgColor="white">
      <SoftBox
        padding={{
          mini: "1rem 1rem 0rem 1rem",
          md: "1.5rem 1.5rem 0rem 1.5rem",
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox>
            <SoftTypography variant="h5" sx={{ color: "#343A40" }}>
              Unlock Exclusive Benefits for Your Cleaning Company
            </SoftTypography>
            <SoftTypography variant="body2" sx={{ color: "#6C757D" }}>
              Welcome to our exclusive Offers category, tailored specifically
              for cleaning companies like yours
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      <Divider />

      <SoftBox
        bgColor="transparent"
        sx={{
          padding: {
            mini: "0.5rem 1rem 1rem 1rem",
            md: "0.75rem 1.5rem 1.5rem 1.5rem",
          },
        }}
      >
        <SoftTypography color="black" variant="">No offers are available for now.</SoftTypography>
        {/* <Grid container spacing={2}>
          <Grid item mini={12} sm={6} lg={3} xxxl={2}>
            <OfferBox
              offerTitle="Special Offer"
              offerSubtitle="Two months Free Subscription"
              offerDescription="Subscribe for One year, and get Two months free subscription"
            />
          </Grid>
        </Grid> */}
      </SoftBox>
    </SoftBox>
  );
};

export default Offers;
