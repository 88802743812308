import React, { useState } from "react";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import { useTheme, Collapse } from "@mui/material";
import { GET_CONNECTION_URL } from "../../api/integration";
import { GET_ZAPIER_AUTH_URL } from "../../api/integrations/zapier";
import { UseSelectedWorkspace } from "../../hooks/company";
import { UseCompanyId } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateWorkspaceKpi } from "../../redux/kpi/actions";
import { GET_IMAGE } from "../../helpers/images";
import AppSwitch from "../AppSwitch";
import { startCase } from "lodash";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import AppSettings from "../WorkspaceKpi/apps/AppSettings";

const IntegrationApp = ({
  key,
  integrationId,
  currentIntegrations,
  name,
  code,
  logo,
  integrations,
  parameters,
  isConnected,
  description,
  config,
  maxWorkspaceKpis,
  setAdding,
  groupKpis,
}) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const [showApp, setShowApp] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const { _id: groupId } = UseSelectedWorkspace();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConnect = () => {
    if (!isConnected) {
      if (config.isOauth || config.isApideck) {
        const connecting = toast.loading("Initializing connection ...");
        const params = config.isApideck
          ? { unifiedApi: config.unifiedApi, serviceId: config.serviceId }
          : null;

        GET_CONNECTION_URL(code, params)
          .then(({ data: url }) => {
            if (url) {
              toast.success("Connecting...", { id: connecting });
              const windowRef = window.open("", "_self");
              windowRef.location.href = url;
            } else {
              toast.error("Unable to establish connection.", {
                id: connecting,
              });
            }
          })
          .catch((err) => {
            toast.error(err.response?.data ?? "Server error", {
              id: connecting,
            });
          });
      } else if (config.isZapier) {
        window.location = GET_ZAPIER_AUTH_URL(companyId);
      } else {
        setShowSettings(true);
      }
    }
  };

  const handleSelect = (activated, integration) => {
    if (activated && (groupKpis?.length ?? 0) < maxWorkspaceKpis) {
      if (isConnected) {
        const integrationMeta = integration.isZapier
          ? {
              id: integration.id,
              code,
              status: "disconnected",
            }
          : null;

        dispatch(
          updateWorkspaceKpi({
            groupId,
            // Use integrationId assigned to specific KPI integration; else use the general app's integrationId
            integrationId: integration.integrationId ?? integrationId,
            kpiModelId: integration.kpiModelId,
            type: integration.type,
            sourceId: integration.sourceId,
            ...(integrationMeta && { integrationMeta }),
          }),
        );

        if (integration.isZapier) {
          navigate(`/zapier-setup?id=${integration.id}`);
        }
      } else {
        handleConnect();
      }
    } else {
      toast.error("Max numbers of KPIs reached.");
      setAdding(false);
    }
  };

  return (
    <SoftBox
      sx={{
        display: "flex",
        borderRadius: "16px",
        background: "white",
        border: "1px solid #E9ECEF",
        boxShadow: " 0px 2px 3px 0px rgba(0, 0, 0, 0.05)",
        width: "100%",
        overflow: "hidden",
        flexDirection: "column",
        padding: "24px",
        height: "100%",
      }}
    >
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          alignItems: "start",
          zIndex: 3,
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SoftBox
            sx={{
              width: "56px",
              height: "56px",
              background: "#fff",
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #E9ECEF",
              boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.05)",
              padding: "8px",
            }}
          >
            {logo ? (
              <img src={GET_IMAGE(logo)} alt="" className="w-full" />
            ) : (
              <SoftTypography variant="h3" sx={{ color: "primary.main" }}>
                {name && name[0]}
              </SoftTypography>
            )}
          </SoftBox>
          {code !== "manual" && (
            <SoftBox
              sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
            >
              <SoftButton
                sx={{
                  border: isConnected
                    ? `1px solid ${primaryColor}`
                    : "1px solid rgba(0, 0, 0, 0.2)",
                  color: isConnected ? primaryColor : "rgba(0, 0, 0, 0.4)",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: isConnected
                      ? primaryColor
                      : "rgba(0, 0, 0, 0.4)",
                  },
                }}
                variant="outlined"
                onClick={handleConnect}
              >
                {isConnected ? "Connected" : "Connect"}
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
        <SoftTypography variant="h5" color="black">
          {name}
        </SoftTypography>
        <SoftTypography
          variant="button"
          sx={{ color: "#6C757D", fontWeight: 400, lineHeight: 1.25 }}
        >
          {description}
        </SoftTypography>
      </SoftBox>

      <SoftBox flex={1} display="flex" justifyContent="end" marginTop="1rem">
        {!config.isApideck && (
          <Dialog open={showSettings} onClose={() => setShowSettings(false)}>
            <SoftBox className="w-[620px] p-8">
              <AppSettings
                integrationId={integrationId}
                name={name}
                code={code}
                logo={logo}
                parameters={parameters}
                handleClose={() => setShowSettings(false)}
              />
            </SoftBox>
          </Dialog>
        )}

        <Collapse
          in={showApp}
          timeout="auto"
          unmountOnExit
          sx={{ marginTop: "0.5rem" }}
        >
          {integrations?.map((integration) => (
            <SoftBox
              key={integration.description ?? integration.type}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: 2,
              }}
            >
              <SoftBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  px: 1,
                }}
              >
                <SoftBox sx={{ maxWidth: "25px" }}>
                  {logo ? (
                    <img
                      src={GET_IMAGE(logo)}
                      alt={name}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <SoftTypography variant="h3" sx={{ color: "primary.main" }}>
                      {name && name[0]}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftTypography sx={{ color: "#000000" }} variant="body2">
                  {integration.description ||
                    `${startCase(integration.type)} KPI`}
                </SoftTypography>
              </SoftBox>
              <SoftBox sx={{ mr: 2 }}>
                <AppSwitch
                  onChange={(e) => handleSelect(e.target.checked, integration)}
                />
              </SoftBox>
            </SoftBox>
          ))}
        </Collapse>
      </SoftBox>
    </SoftBox>
  );
};

export default IntegrationApp;
