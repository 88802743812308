import React, { useState } from "react";
import { Dialog } from "@mui/material";

import SoftDialog from "../../../../SoftConfirmationDialog";
import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";

import SoftButton from "../../../../SoftButton";
import AnchorMetricDialog from "./AnchorMetricDialog";
import ProgressiveMetricDialog from "./ProgressiveMetricDialog";
import DisplayMetricDialog from "./DisplayMetricDialog";
import CheckIcon from "@mui/icons-material/Check";

const MetricComponent = {
  progressive: ProgressiveMetricDialog,
  anchor: AnchorMetricDialog,
  display: DisplayMetricDialog,
};

const MetricDialog = ({ metric, isMax = false }) => {
  const [metricDialog, setMetricDialog] = useState(false);
  const [dialog, setDialog] = useState(false);

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);

  const openMetricDialog = () => setMetricDialog(true);
  const closeMetricDialog = () => setMetricDialog(false);

  const MetricComponentRedering = MetricComponent[metric.id];

  return (
    <>
      <SoftButton
        variant="outlined"
        fullWidth
        onClick={openMetricDialog}
        disabled={isMax}
        sx={{
          textTransform: "none",
          fontWeight: 500,
          border: "1.1px solid #344054",
          color: "#344054",
          "&.Mui-disabled": {
            background: "#FCFCFD !important",
            border: "1.1px solid #98A2B3 !important",
            color: "#344054 !important",
          },
        }}
      >
        {`Create a ${metric.buttonTitle}`}
      </SoftButton>
      {metricDialog && (
        <Dialog
          open={metricDialog}
          onClose={closeMetricDialog}
          sx={{
            ".MuiPaper-root": {
              padding: { mini: "1rem", md: "1.5rem 1.7rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "700px",
              borderWidth: "1px",
              borderColor: "#FFF",
              "::-webkit-scrollbar": {
                display: "none",
                height: 0,
                width: 0,
              },
            },
          }}
        >
          <MetricComponentRedering
            metric={metric}
            closeMetricDialog={closeMetricDialog}
            openDialog={openDialog}
          />
        </Dialog>
      )}
      {dialog && (
        <SoftDialog
          open={dialog}
          onClose={closeDialog}
          title={`${metric.buttonTitle} Created`}
          type="success"
          icon={<CheckIcon fontSize="large" sx={{ color: "#299E47" }} />}
          description={
            <SoftBox display="flex" justifyContent="center" mt="25px" mb="35px">
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                You have created a new {`${metric.buttonTitle}`}.{" "}
                {metric.id === "display"
                  ? "This will help you visualize and analyze your performance data more effectively"
                  : "You are now ready to track your progress and level up your performance."}
              </SoftTypography>
            </SoftBox>
          }
          footer={
            <SoftBox display="flex" gap="10px" justifyContent="center">
              <SoftButton
                size="small"
                color="white"
                onClick={closeDialog}
                sx={{
                  width: "min-content",
                  borderRadius: "4px",
                  textTransform: "none",
                  color: "#344054",
                }}
              >
                Okay
              </SoftButton>
            </SoftBox>
          }
        />
      )}
    </>
  );
};

export default MetricDialog;
