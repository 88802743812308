export const initState = {
  claims: [],
  error: null,
  isLoading: false,
};

export const types = {
  GET_COMPANY_CLAIMS_REQUEST: "GET_COMPANY_CLAIMS_REQUEST",
  GET_COMPANY_CLAIMS_SUCCESS: "GET_COMPANY_CLAIMS_SUCCESS",
  GET_COMPANY_CLAIMS_FAILED: "GET_COMPANY_CLAIMS_FAILED",

  PUT_USER_CLAIMS_REQUEST: "PUT_USER_CLAIMS_REQUEST",
  PUT_USER_CLAIMS_SUCCESS: "PUT_USER_CLAIMS_SUCCESS",
  PUT_USER_CLAIMS_FAILED: "PUT_USER_CLAIMS_FAILED",
};
