import React, { useEffect, useState, useMemo } from "react";
import messages from "../../utils/messages";
import SoftInput from "../../components/SoftInput";
import { Backdrop, style, StyledModal, useStyles } from "./registerStyles";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { UseAuthErrorMessage, UseAuthStatus } from "../../hooks/auth";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "../../form-validations";
import { useForm } from "react-hook-form";
import { CHECK_UNIQUE_CREDENTIALS } from "../../api/user";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import gmbLogo from "../../img/main_logo.png";

function Register() {
  const [isTermAndConditionsChecked, setIsTermAndConditionsChecked] =
    useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [customError, setCustomError] = useState("");
  const status = UseAuthStatus();
  const authError = UseAuthErrorMessage();
 
  const validationOpt = {
    resolver: yupResolver(RegisterSchema),
    shouldUnregister: false,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm(validationOpt);

  const checkUniqueEmail = async () => {
    const email = getValues("email");
    if (email) {
      return CHECK_UNIQUE_CREDENTIALS({ email })
        .then(() => {
          clearErrors("email");
          return true;
        })
        .catch(() => {
          toast.error(`Invalid email. ${email} is already taken.`);
          setError("email", {
            type: "custom",
            message: `Invalid email: Email is already taken`,
          });
          return false;
        });
    }
  };

  const checkEmail = useMemo(
    () =>
      debounce(async () => {
        checkUniqueEmail();
      }, 2000),
    [],
  );

  const handleRegister = async (formData) => {
    if (!isTermAndConditionsChecked) {
      setCustomError(messages.registerTCerror);
      return;
    }
    setCustomError("");
    dispatch(registerUser(formData));
  };

  useEffect(() => {
    if(status === "registered") { 
      setIsShow(true);
    }
  }, [status]);
  
  useEffect(() => {
    if(authError) { 
      setCustomError(authError);
    }
  }, [authError]);

  return (
    <>
      <div className={classes.signUpContainer}>
        <form
          onSubmit={handleSubmit(handleRegister)}
          className={classes.formContainer}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={gmbLogo}
              className={classes.imgLogo}
              style={{ marginBottom: 20 }}
              alt="GameMyBiz"
            />

            <h2 className={classes.h2}>Unlock Your Business Potential: Sign Up Now and Dominate Your Industry!</h2>
            <p className={classes.form_P}>
              Fill in fields below to create you account.
            </p>
          </div>
          <Grid container spacing={2} marginBottom={5}>
          <Grid item mini={12}>
              <SoftInput
                className="mb-4"
                placeholder="Email"
                label="Email"
                {...register("email", {
                  onChange: () => {
                    checkEmail();
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </Grid>
            <Grid item mini={12} sm={6}>
              <SoftInput
                className="mb-4"
                placeholder="First Name"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                {...register("firstName")}
                error={!!errors?.firstName}
                helperText={errors?.firstName?.message}
              />
            </Grid>
            <Grid item mini={12} sm={6}>
              <SoftInput
                className="mb-4"
                placeholder="Last Name"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                {...register("lastName")}
                error={!!errors?.lastName}
                helperText={errors?.lastName?.message}
              />
            </Grid>
            <Grid item mini={12} sm={6}>
              <SoftInput
                className="mb-4"
                placeholder="Password"
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                {...register("password")}
                error={!!errors?.password}
                helperText={errors?.password?.message}
              />
            </Grid>
            <Grid item mini={12} sm={6}>
              <SoftInput
                className="mb-4"
                id="outlined-basic"
                placeholder="Confirm Password"
                label="Confirm Password"
                variant="outlined"
                type="password"
                {...register("cPassword")}
                error={!!errors?.cPassword}
                helperText={errors?.cPassword?.message}
              />
            </Grid>
          </Grid>
          <div className="flex">
            <FormControlLabel
              required
              value="start"
              control={
                <Checkbox
                  checked={isTermAndConditionsChecked}
                  onChange={() => {
                    if (customError) {
                      setCustomError("");
                    }
                    setIsTermAndConditionsChecked((prev) => !prev);
                  }}
                />
              }
            />
            <span className="align-middle">
              I accept the 
                <a href="https://gamemybiz.com/terms-condition" target="_blank" rel="noreferrer" 
                  className="my-auto px-1 text-blue-600 dark:text-blue-500 hover:underline">
                  Terms and Conditions
                </a>
            </span>
          </div>
          {customError && <p className="text-danger italic">{customError}</p>}
          <Button
            type="submit"
            variant="contained"
            size="large"
            className="fw-bold text-white"
            sx={{
              width: "100%",
              padding: "10px 40px 10px 40px",
              fontSize: "1.1rem",
              background: "linear-gradient(90deg, #EE238A 0%, #922790 93.85%);",
              display: "block",
              margin: "0 auto",
              marginTop: "30px",
            }}
          >
            Sign Up
          </Button>
        </form>

        <div>
          <div className={classes.registerBgContainer}>
            <div className={classes.registerBackground}>
              <div className={classes.bgHeadingContainer}>
                <h3 className="text-xl text-center text-white">
                  It's a
                  <br />
                  game!
                  <br />
                  It's a
                  <br />
                  business!
                  <br />
                  It's
                  <br />
                  Game
                  <br />
                  My Biz!
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isShow}
        BackdropComponent={Backdrop}
      >
        <div style={style}>
          <div className="mt-7">
            <h1 className="fw-bold text-xl md:text-3xl text-center">
              Succesfully Registered
            </h1>
            <p className="text-center mt-3">
              In order to log in you must first verify your account
            </p>
            <p className="text-center mt-3">
              Verification link is sent to your email {getValues("email")}
            </p>
            <Button
              onClick={() => {
                setIsShow(false);
                navigate("/login");
              }}
              sx={{
                padding: "10px 40px 10px 40px",
                fontSize: "1.1rem",
                background:
                  "linear-gradient(90deg, #EE238A 0%, #922790 93.85%);",
                display: "block",
                margin: "0 auto",
                marginTop: "30px",
                color: "#ffffff",
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
      </StyledModal>
    </>
  );
}

export default Register;
