import { types, initState } from "./types";

const getApp = (state = initState, action) => {
  switch (action.type) {
    case types.PUT_VERSION:
      return {
        ...state,
        version: action.version,
        synced: action.synced,
      };
    default:
      return state;
  }
};

export default getApp;