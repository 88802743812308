import { request } from "../helpers/axios.new";
import { useMutation, useQuery } from "@tanstack/react-query";

export const SEND_RESET_PASSWORD_LINK = () =>
  useMutation((data) =>
    request({
      url: `/auth/sendResetPasswordLink`,
      method: "POST",
      data,
    }),
  );

export const VERIFY_USER_EMAIL = () =>
  useMutation((data) =>
    request({
      url: `/auth/verifyUserEmail`,
      method: "POST",
      data,
    }),
  );

export const VERIFY_USER_PASSWORD_RESET = (token) =>
  useQuery(["verifyUserPasswordReset"], () =>
    request({ url: `/auth/verifyUserPasswordReset/${token}` }),
  );

export const RESET_USER_PASSWORD = () =>
  useMutation((data) =>
    request({
      url: `/auth/resetUserPassword`,
      method: "POST",
      data,
    }),
  );

export const UPDATE_USER = () =>
  useMutation(({ userId, data }) =>
    request({
      url: `/user/${userId}`,
      method: "PUT",
      data: {
        ...data,
        updateMe: true,
      },
    }),
  );

export const PUT_USER = (userId, data, updateMe = true) =>
  request({
    url: `/user/${userId}`,
    method: "PUT",
    data: { ...data, updateMe },
  });

export const GET_USER_BY_GROUPID = (groupId) =>
  request({
    url: `/user/group?groupId=${groupId}`,
    method: "GET",
  });

export const UPDATE_USER_IMAGE = (userId, data) =>
  request({
    url: `/user/image/${userId}`,
    method: "PUT",
    data,
  });

export const DELETE_USER_IMAGE = (data) =>
  request({
    url: '/user/image',
    method: "DELETE",
    data,
  });

export const UPDATE_USER_LOGO = (data) =>
  request({
    url: `/user/logo`,
    method: "PUT",
    data,
  });

export const CHECK_UNIQUE_CREDENTIALS = ({ username = null, email = null }) => {
  const query = {
    ...(username && { username }),
    ...(email && { email }),
  };
  if (query !== {}) {
    return request({
      url: `/user/check`,
      method: "POST",
      data: query,
    });
  }
};

export const GET_USER = (userId) => {
  return request({
    url: `/user/${userId}`,
  });
};

export const GET_USERS = () =>
  request({
    url: `/user`,
  });

export const GET_COMPANY_USERS = (companyId) =>
  request({
    url: `/user/company/${companyId}`,
  });

export const ADD_COLLEAGUE = (data) =>
  request({
    url: `/auth/colleague`,
    method: "POST",
    data,
  });

export const DELETE_COLLEAGUE = (userId) => 
  request({
    url: `/user/${userId}`,
    method: "DELETE",
  });


export const DELETE_TEMPUSER = (body) => {
    return request({
      url: '/user/delete-tempuser',
      method: "PATCH",
      data: body,
    });
  };

export const ARCHIVE_COLLEAGUE = (userId, isArchived = true) =>
  request({
    url: `/user/archive/${userId}`,
    method: "PATCH",
    data: {isArchived},
  });

export const SET_AS_ADMIN = (userId) =>
  request({
    url: `/user/admin/${userId}`,
    method: "PUT",
    data: {},
  });

export const GET_ORG_USERS = (companyId, query) =>
  request({
    url: `/user/org?companyId=${companyId}&query=${query}`,
    method: "GET",
  });
