import { request } from "../helpers/axios.new";

export const ADD_REACT = (requestData) => {
  return request({
    url: `/react/${requestData.propertyId}`,
    method: "POST",
    data: requestData?.data
  });
};


export const DELETE_REACT = (reactId) =>
  request({
    url: `/react/${reactId}`,
    method: "DELETE"
  });

export const FETCH_REACT = (propertyId) => request({ url: `/react/${propertyId}` });

