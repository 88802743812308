import React, { useState } from "react";
import { GET_IMAGE } from "../../helpers/images";
import defaultAvatar from "../../img/avatar2.png";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from "@iconify/react";
import Favorite from "./Favorite";
import {
  REACTION_ICON_TYPE,
  REACTION_PROPERTY_TYPE,
} from "../../constants/reactions";
import CommentAction from "./CommentAction";
import { Typography } from "@mui/material";
import moment from "moment";

const Comment = ({
  comment,
  jobId,
  currentUser,
  onToggleFavorite = () => {},
  onDeleteComment = () => {},
}) => {
  const [toggleFavorite, setToggleFavorite] = useState(false);
  const [currentReact, setCurrentReact] = useState(null);
  const { users, content, reacts, createdAt } = comment;
  const user = users[0];
  const isUserOwnComment = user._id === currentUser._id;
  const [actionVisible, setActionVisible] = useState(false);

  const handleToggleFavorite = () => {
    const isActive = !toggleFavorite;
    setToggleFavorite(isActive);

    onToggleFavorite(
      comment._id,
      currentUser._id,
      isActive,
      currentReact,
      REACTION_ICON_TYPE.FAVORITE,
      REACTION_PROPERTY_TYPE.COMMENT,
    );
  };

  React.useEffect(() => {
    const userReactFavorite = reacts?.find(
      (react) => react.users[0]._id === currentUser._id,
    );
    setCurrentReact(userReactFavorite);
    setToggleFavorite(!!userReactFavorite);
  }, [reacts, currentUser]);
  
  return (
    <div
      className="flex flex-row"
      onMouseEnter={() => setActionVisible(true)}
      onMouseLeave={() => setActionVisible(false)}
    >
      <div className="flex-none w-14">
        <img
          key={user?._id}
          className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800"
          src={user?.image ? GET_IMAGE(user.image) : defaultAvatar}
          alt=""
        />
      </div>
      <div className="relative bg-[#F5F5F5] rounded-lg p-3 ">
        <p className="font-bold"> {user.firstName + " " + user.lastName}</p>
        <div className="flex gap-5">
          <div className="grow text-black-600">
            <div className="mb-2">{content}</div>
          </div>
          <button
            type="button"
            className=" font-medium rounded-full"
            onClick={handleToggleFavorite}
          >
            <Icon
              icon={
                toggleFavorite
                  ? "ant-design:heart-filled"
                  : "ant-design:heart-outlined"
              }
              className={
                toggleFavorite ? "!text-[20px] text-pink-500" : "!text-[20px]"
              }
            />
          </button>
          {!!reacts?.length && (
            <div className="absolute -bottom-3 right-1">
              <Favorite reacts={reacts} />
            </div>
          )}
        </div>
        <Typography
          sx={{
            fontSize: { mini: "0.65rem", md: "0.75rem" },
            color: "#787878",
          }}
        >
          {`${moment(createdAt).format("ddd, MM-DD-YYYY")} at ${moment(
            createdAt,
          ).format("h:mm:ss a")}`}
        </Typography>
      </div>

      <div className="grid place-items-center">
        {actionVisible && isUserOwnComment && (
          <CommentAction
            commentId={comment._id}
            jobId={jobId}
            onDeleteComment={onDeleteComment}
          />
        )}
      </div>
    </div>
  );
};

export default Comment;
