import { PUT_JOB_COMPOSED_IMAGE } from "../../api/jobs";
import { PRIMARY_DEFAULT } from "../../theme";
import { GET_IMAGE } from "../../helpers/images";

const FB_WIDTH = 1200;
const FB_HEIGHT = 630;
const PADDING = { x: 20, y: 10 };
const FONT_SIZE = 55;
const HOST =
  process.env.REACT_APP_SOCIAL_HOST ||
  process.env.REACT_APP_LINK_HOST;

const upload = async (id, canvas) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("image", blob);

      PUT_JOB_COMPOSED_IMAGE(id, formData)
        .then((result) => {
          resolve(
            `${HOST}/images/${result.data.key}`
          );
        })
        .catch(() => reject());
    });
  });
};

export const getSocialUrl = (key) => `${HOST}/images/${key}`;

export const getFacebookShareable = async (
  id,
  gallery,
  logo = null,
  color = PRIMARY_DEFAULT,
) => {
  if (gallery.length) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      canvas.width = FB_WIDTH;
      canvas.height = FB_HEIGHT;
      const halfW = canvas.width / 2;

      const context = canvas.getContext("2d");
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.beginPath();
      context.roundRect(0, 0, canvas.width, canvas.height, [135, 0, 135, 0]);
      context.clip();

      let imgIndex = 0;

      gallery.forEach((image, index) => {
        const imageObj = new Image();
        imageObj.src = image;
        imageObj.crossOrigin = "anonymous";
        imageObj.onload = () => {
          imgIndex += 1;

          const imgX =
            imageObj.width / 2 > halfW ? imageObj.width / 2 - halfW : 0; // Image offsets to recenter
          const imgY = canvas.height - imageObj.height / 2;
          const posX = index === 0 ? 0 : halfW; // Position before and after on left and right
          context.drawImage(
            imageObj,
            imgX,
            imgY,
            halfW,
            canvas.height,
            posX,
            0,
            halfW,
            canvas.height,
          );

          if (index === 0) {
            // Slightly darken "Before" image
            context.globalAlpha = 0.35;
            context.fillStyle = "black";
            context.fillRect(0, 0, halfW, canvas.height);
            context.globalAlpha = 1.0;
          }

          if (imgIndex === 2) {
            context.font = `${FONT_SIZE}px Roboto`;
            context.textAlign = "left";
            const textY = canvas.height - (FONT_SIZE + PADDING.y) / 2;

            // Draw text backgrounds
            context.fillStyle = color;
            context.fillRect(
              0,
              textY - FONT_SIZE + PADDING.y / 2,
              halfW * 0.45,
              FONT_SIZE + PADDING.y,
            );
            context.fillRect(
              halfW,
              textY - FONT_SIZE + PADDING.y / 2,
              halfW * 0.45,
              FONT_SIZE + PADDING.y,
            );

            // Draw text
            context.fillStyle = "white";
            context.fillText("BEFORE", PADDING.x * 2, textY);
            context.fillText("AFTER", halfW + PADDING.x, textY);

            if (logo) {
              const logoImgObj = new Image();
              logoImgObj.src = GET_IMAGE(logo);
              logoImgObj.crossOrigin = "anonymous";
              logoImgObj.onload = () => {
                context.drawImage(
                  logoImgObj,
                  canvas.width - logoImgObj.width - PADDING.x,
                  PADDING.y,
                );
                return upload(id, canvas)
                  .then((result) => resolve(result))
                  .catch(() => reject());
              };
            } else {
              return upload(id, canvas)
                .then((result) => resolve(result))
                .catch(() => reject());
            }
          }
        };
      });
    });
  } else {
    return "none";
  }
};
