/* eslint camelcase: 0 */

import React from "react";
import Editor from "../../../components/Integrations/Zapier/Editor";

const Setup = () => {
  return (
    <Editor />
  );
};

export default Setup;
