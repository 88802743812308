import io from "socket.io-client";
import AxiosConfigs from "./axios";

const { REACT_APP_LINK_HOST: apiUrl } = AxiosConfigs;

const socket = io.connect(apiUrl, {
  transports: ["websocket", "polling"],
});

export default socket;
