import { initState, types } from "./types";
import _ from "lodash";

const messageReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ALL_MESSAGES:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case types.GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: _({})
          .merge(
            _(state.messages).groupBy("_id").value(),
            _(action.messages).groupBy("_id").value(),
          )
          .values()
          .flatten()
          .value(),
        isLoading: false,
        hasMore: action.hasMore,
        currentPage: action.currentPage,
      };
    case types.GET_ALL_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.message,
      };
    case types.ADD_MESSAGE:
      return {
        ...state,
        messages: [action.comment, ...state.messages],
      };
    // return {
    //   ...state,
    //   messages: _({})
    //     .merge(
    //       _(action.comment).groupBy("_id").value(),
    //       _(state.messages).groupBy("_id").value(),
    //     )
    //     .values()
    //     .flatten()
    //     .value(),
    // };
    default:
      return state;
  }
};

export default messageReducer;
