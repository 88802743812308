import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { GET_IMAGE_URL } from "../../helpers/images";
import ArenaBg from "../../img/ca-bg.png";
import { ReactComponent as ThreeDots } from "../../img/threedots.svg";
import LinkGoogle from "./LinkGoogle";
import ChallengeDetails from "./ChallengeDetails";
import { UseCompanyId, UseUserDetails } from "../../hooks/auth";
import { JOIN_ONE_VS_ONE_CHALLENGE } from "../../api/challenge";
import { useAudio } from "../../context/audioContext";
import FinalCountdown from "../../assets/audio/FinalCountdownCA.wav";
import EventIcon from "@mui/icons-material/Event";

const INTRO_SFX_KEY = "challenge_intro";

const ChallengeBox = ({ challenge, setFetch, fetch, isCountdownEnded = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [countdownEnded, setCountdownEnded] = useState(isCountdownEnded);
  const [countdownText, setCountdownText] = useState("");

  const navigate = useNavigate();
  const companyId = UseCompanyId();
  const userDetails = UseUserDetails();
  const { createAudio, playAudioOnce } = useAudio();

  useEffect(() => {
    createAudio(INTRO_SFX_KEY, FinalCountdown);
  }, []);

  useEffect(() => {
    const processCountdown = () => {
      const now = moment();
      const start = moment(challenge.startDate);

      if (now < start) {
        const diff = moment(start).from(now);
        setCountdownText(`STARTS ${diff.toUpperCase()}`);
        return;
      }

      const end = moment(challenge.endDate);
      if (now < end) {
        const diff = moment(end).from(now);
        setCountdownText(`ENDS ${diff.toUpperCase()}`);
      } else {
        setCountdownText("CHALLENGE ENDED");
        setCountdownEnded(true);
      }
    };

    processCountdown();

    // Update countdown text every X minutes
    const cdown = setInterval(processCountdown, 5 * 60 * 1000);

    return () => clearInterval(cdown);
  }, [challenge]);

  const handleOpen = async () => {
    if (challenge.category === "CompanyVsCompany" && !userDetails.isAdmin) {
      toast.error("Only admin of the company can join this challenge");
    } else {
      const payload = {
        userId: userDetails._id,
        category: challenge.category,
      };

      if (challenge.category === "EmployeeVsEmployee") {
        payload.playerId = userDetails._id;
      } else {
        payload.companyId = companyId;
      }

      setIsLoading(true);
      try {
        const { data } = await JOIN_ONE_VS_ONE_CHALLENGE(
          challenge._id,
          payload,
        );

        if (data && data.success) {
          toast.success(data.message);
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.error?.message || "Something went wrong",
        );
        console.log(error.response);
      }
      setIsLoading(false);
      setFetch(fetch + 1);
    }
  };

  const handleCloseLink = () => {
    setOpenLink(false);
  };

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleViewChallenge = () => {
    playAudioOnce(INTRO_SFX_KEY, 5750);
    if (challenge.category === "CompanyVsCompany") {
      navigate(`/1v1-challenge/${challenge.url}`);
    } else if (challenge.category === "EmployeeVsEmployee") {
      navigate(`/1v1-challenge/${challenge.url}`);
    } else {
      navigate(`/company-arena/${challenge.url}`);
    }
  };

  return (
    <SoftBox
      shadow="sm"
      borderRadius="lg"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        aspectRatio: "8 / 10",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/** Challenge card header */}
      <SoftBox
        sx={{
          height: "33%",
          width: "100%",
        }}
      >
        <img
          src={challenge?.image ?? ArenaBg}
          alt="Challenge Arena"
          className="object-cover max-w-full max-h-full w-full h-auto"
        />
      </SoftBox>
      <SoftBox
        sx={{
          height: "33%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          background:
            "radial-gradient(94.66% 94.66% at 50% 0%, rgba(255, 255, 255, 0.00) 0%, rgba(7, 9, 20, 0.14) 69.61%, #FFF 100%)",
        }}
      />

      <SoftBox
        className="flex justify-between items-center"
        sx={{
          position: "absolute",
          top: "5%",
          width: "100%",
          paddingX: "7%",
        }}
      >
        {/** Countdown */}
        <SoftBox
          borderRadius="sm"
          sx={{
            border: "0.1rem solid rgba(206, 212, 218, 0.20)",
            background:
              "linear-gradient(180deg, rgba(14, 13, 13, 0.54) 0%, rgba(16, 14, 14, 0.46) 100%)",
            backdropFilter: "blur(5px)",
            minWidth: "35%",
            textAlign: "center",
            paddingX: "0.75rem",
            cursor: "default",
          }}
        >
          <SoftTypography
            variant="body2"
            color="white"
            sx={{
              fontWeight: 400,
              fontSize: "0.75rem",
            }}
          >
            {countdownText}
          </SoftTypography>
        </SoftBox>
        {/** Details Button */}
        <SoftBox
          sx={{
            cursor: "pointer",
            maxWidth: "1.75rem",
            borderRadius: "2rem",
            "&:hover": {
              bgcolor: "#BDBDBD",
              boxShadow: "0 0 0.75 0.25rem #F5F5F5",
            },
          }}
          onClick={handleOpenDetails}
        >
          <ThreeDots />
        </SoftBox>
      </SoftBox>

      {/** Challenge card main content */}
      <SoftBox className="w-full grow flex flex-col justify-between items-center pb-[12%]">
        {/** Title section  */}
        <SoftBox className="w-full flex flex-col items-center">
          <SoftBox
            sx={{
              mt: "0.5rem",
              mb: "0.25rem",
              maxWidth: "220px"
            }}
          >
            <SoftTypography
              variant="h5"
              className="truncate"
              sx={{
                color: "#051923",
                fontWeight: "700",
                lineHeight: "normal",
                textAlign: "center",
              }}
            >
              {challenge.title} Challenge
            </SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              maxWidth: "220px",
            }}
          >
            <SoftTypography
              variant="body2"
              color="white"
              sx={{
                color: "#6C757D",
                fontSize: "0.75rem",
                textAlign: "center",
              }}
              className="text-ellipsis"
            >
              {challenge.description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        {/** Date range and Player count row */}
        <SoftBox className="flex justify-between w-[80%]">
          <SoftBox className="flex items-center gap-1">
            <EventIcon
              sx={{ color: "#6C757D", fontSize: "1.25rem", mt: "-2%" }}
            />
            <SoftTypography
              variant="body2"
              color="black"
              sx={{ fontSize: "0.75rem", fontWeight: 500 }}
            >
              {moment(challenge.startDate).format("MMM DD")} -{" "}
              {moment(challenge.endDate).format("MMM DD")}
            </SoftTypography>
          </SoftBox>
          <SoftBox className="flex items-center gap-1">
            <SoftBox>
              {challenge.players?.length ? (
                <AvatarGroup max={3}
                  sx={{
                    "& .MuiAvatar-root": {
                      width: "1.25rem",
                      height: "auto",
                      fontSize: "10px",
                      fontWeight: 500,
                      textAlign: "end",
                      border: "2px solid #CED4DA",
                      background: "rgba(255, 255, 255, 1.0)",
                      color: "#616161",
                    }
                  }}
                >
                  {challenge.players.map((challenger) => (
                    <Avatar
                      alt={
                        challenger.company?.businessName ??
                        challenger.player.firstName
                      }
                      src={GET_IMAGE_URL(
                        challenger.company?.logo ?? challenger.player?.image,
                      )}
                    />
                  ))}
                </AvatarGroup>
              ) : null}
            </SoftBox>
            <SoftTypography
              variant="body2"
              sx={{ fontSize: "0.75rem", fontWeight: 500, color: "#6C757D" }}
            >
              {challenge.players?.length || "0"} Joined
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        {/** Primary action buttons */}
        <SoftBox className="flex flex-col gap-1 w-[80%]">
          {(challenge.companyId === companyId &&
            (challenge.category === "CompanyVsCompany" ||
              challenge.category === "CompanyGroups")) ||
          (challenge.playerId === userDetails._id &&
            challenge.category === "EmployeeVsEmployee") ? (
            <SoftButton
              variant="contained"
              size="small"
              disabled={isLoading}
              color="primary"
              onClick={handleViewChallenge}
              sx={{ width: "100%", textTransform: "none" }}
            >
              View Challenge
            </SoftButton>
          ) : (
            <SoftButton
              variant="contained"
              size="small"
              disabled={isLoading || !!countdownEnded}
              color="primary"
              onClick={countdownEnded ? null : handleOpen}
              sx={{ width: "100%", textTransform: "none" }}
            >
              Join Challenge
            </SoftButton>
          )}
          <SoftBox
            sx={{
              cursor: "pointer",
              color: "#343A40",
              textAlign: "center",
            }}
          >
            <SoftTypography
              sx={{ fontWeight: 600, fontSize: "0.75rem", mt: "0.25rem" }}
            >
              Add Challenge to Homepage
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {/** Dialogs */}
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Dialog
          open={openLink}
          onClose={handleCloseLink}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              background:
                "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
              boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
              backdropFilter: "blur(15px)",
              padding: { mini: "1.5rem", md: "2rem" },
            },
          }}
        >
          <LinkGoogle handleClose={handleCloseLink} />
        </Dialog>
        <Dialog
          open={openDetails}
          onClose={handleCloseDetails}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              background:
                "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
              boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
              backdropFilter: "blur(15px)",
            },
          }}
        >
          <ChallengeDetails
            handleClose={handleCloseDetails}
            challenge={challenge}
            countdownEnded={countdownEnded}
            handleOpen={handleOpen}
            handleViewChallenge={handleViewChallenge}
            companyId={companyId}
          />
        </Dialog>
      </SoftBox>
    </SoftBox>
  );
};

export default ChallengeBox;
