import React from "react";
import Grid from "@mui/material/Grid";

import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";
import ExploreInfo from "../../components/WorkspaceKpi/metrics/CustomerMetricsType/ExploreInfo";
import ExploreTypes from "../../components/WorkspaceKpi/metrics/CustomerMetricsType/ExploreTypes";
import UnlockFullPotential from "../../components/WorkspaceKpi/metrics/CustomerMetricsType/UnlockFullPotential";

const ExploreCustomMetricsTypes = () => {
  return (
    <>
      <PageHeader
        headerText="Workspace KPI"
        subheaderText="Manage players in the workspace"
      />
      <SoftBox
        sx={{
          margin: { mini: "1rem", sm: "1.5rem" },
          background: "#fff",
          borderRadius: "16px",
        }}
      >
        <Grid container>
          <Grid item mini={12}>
            <ExploreInfo />
          </Grid>
          <Grid item mini={12}>
            <ExploreTypes />
          </Grid>
          <Grid item mini={12}>
            <UnlockFullPotential />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default ExploreCustomMetricsTypes;
