import React, { useEffect, useState } from "react";
import { Collapse, useTheme, Grid } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftInput from "../../../../components/SoftInput";
import SoftSelect from "../../../../components/SoftSelect";
import { PlayerMemoSchema } from "../../../../form-validations";
import { UseUserId } from "../../../../hooks/auth";
import { refetchUserData } from "../../../../redux/auth/actions";
import { UseSelectedWorkspace } from "../../../../hooks/company";
import { GET_USER_BY_GROUPID } from "../../../../api/user";
import {
  ADD_WIDGETS_TO_LIST,
  GET_WIDGETS_LIST,
} from "../../../../api/userWidgets";

const PlayerMemo = ({ widget, closeModal, setButtonLabel, setReFetch }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);

  const { _id: groupId } = UseSelectedWorkspace();
  const theme = useTheme();
  const userId = UseUserId();
  const dispatch = useDispatch();

  const fetchUsersByGroupId = async () => {
    setLoading(true);
    try {
      let filteredUsers = [];

      const { data } = await GET_USER_BY_GROUPID(groupId);
      const result = await GET_WIDGETS_LIST({
        key: widget.key,
        companyId: null,
        userId: null,
      });

      filteredUsers = data.filter((user) => user._id !== userId);

      if (result.data.length) {
        const optionElements = [];
        const existingUsers = [];

        filteredUsers.forEach((user) => {
          const isUserExist = result.data.find(
            (item) => item.userId === user._id,
          );

          if (isUserExist) {
            const username = `${user.firstName} ${user.lastName}`;

            const payload = {
              ...user,
              value: user._id,
              label: username,
              assignedValue: isUserExist.userInput,
            };

            existingUsers.push(payload);
          } else {
            const username = `${user.firstName} ${user.lastName}`;

            const payload = {
              ...user,
              value: user._id,
              label: username,
            };

            optionElements.push(payload);
          }
        });
        setOptions(optionElements);
        setUsers(existingUsers);
      } else {
        const userOptions = filteredUsers.map((item) => {
          const username = `${item.firstName} ${item.lastName}`;

          return {
            ...item,
            value: item._id,
            label: username,
          };
        });

        setOptions([...userOptions]);
      }
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsersByGroupId();
  }, []);

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const handleChange = (e, index) => {
    const { value } = e.target;
    const list = [...users];
    list[index].assignedValue = value;
    setUsers([...list]);
  };

  const validationOpt = {
    resolver: yupResolver(PlayerMemoSchema),
    shouldUnregister: false,
    defaultValues: {
      title: widget.title,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationOpt);

  const triggerSave = async (formData) => {
    const { title } = formData;
    setLoading(true);
    try {
      await Promise.all(
        users.map((user) => {
          const payload = {
            userId: user._id,
            key: widget.key,
            category: widget.category,
            title,
            userInput: user.assignedValue,
          };
          return ADD_WIDGETS_TO_LIST(payload);
        }),
      );
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        toast.success("Widget activated successfully");
        closeModal();
        setReFetch();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(triggerSave)}>
      <SoftInput
        {...register("title")}
        placeholder="Enter Title"
        error={!!errors?.title}
        helperText={errors?.title?.message}
        mb="30px"
        className="text-black mb-3"
        sx={{
          border: "1px solid rgba(233, 236, 239, 0.10)",
          backgroundColor: "rgba(255, 255, 255, 0.10) !important",
          color: "#ffffffb3 !important",
          "& .MuiInputBase-input::placeholder": {
            color: "white !important",
            opacity: "0.7",
          },
          "&.Mui-focused": {
            borderColor: primaryColor,
            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
          },
        }}
      />
      <SoftSelect
        name="isType"
        id="category"
        className="custom-select-catgory"
        options={options}
        isMulti
        onChange={(option) => {
          const list = [...option];
          setUsers(list);
        }}
        isLoading={loading}
        value={users}
        placeholder="Select player"
        styles={{
          color: "white !important",
          height: "fit-content !important",
          placeholder: {
            color: "white !important",
            opacity: "0.7",
          },
          input: {
            color: "white !important",
            opacity: "0.7",
          },
          singleValue: {
            color: "white !important",
            opacity: "0.7",
          },
        }}
      />

      <Collapse in={users.length}>
        <SoftBox
          marginY="1rem"
          sx={{
            borderRadius: "4px",
            border: "1px solid rgba(73, 80, 87, 0.50)",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
            padding: "1rem",
          }}
        >
          {users.map((user, index) => (
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: "1rem" }}
              key={user._id}
            >
              <Grid item md={6}>
                <SoftInput
                  placeholder="Player Name"
                  type="text"
                  name="playerName"
                  value={user.label}
                  disabled
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <SoftInput
                  placeholder={`Something for ${user.label}`}
                  type="text"
                  name="playerValue"
                  onChange={(e) => handleChange(e, index)}
                  value={user.assignedValue}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </SoftBox>
      </Collapse>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.10)",
            color: "#CED4DA",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          type="submit"
          sx={{
            width: "100%",
            textTransform: "none",
            borderRadius: "4px",
            color: "#E9ECEF",
          }}
          disabled={loading}
          loading={loading}
        >
          Activate
        </SoftButton>
      </SoftBox>
    </form>
  );
};

export default PlayerMemo;
