import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// MUI/SoftUI
import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

// Components
import SetupFormInputField from "../layout/SetupFormInputField";
import AnchorPointForm from "./AnchorPointForm";

// Constants
import { ANCHOR_BUTTON_STATE } from "../constants";

const SetupAnchorForm = ({ metric }) => {
  const ANCHOR_BTN_VISIBILITY_FIELD = "anchorSetup";

  const {
    register,
    setValue,
    getValues,
    clearErrors,
    trigger,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue(ANCHOR_BTN_VISIBILITY_FIELD, ANCHOR_BUTTON_STATE.IDLE);

    // Show (target * 100) for %-anchor metrics, since we save them as decimal percentages on server
    const target = metric?.kind === "%"
      ? (metric?.defaultTarget ?? 0) * 100
      : metric.defaultTarget ?? 0;

    setValue("target", target);
  }, [setValue, metric]);

  const validateSaveClick = (value) => {
    clearErrors();
    setValue(ANCHOR_BTN_VISIBILITY_FIELD, value);
  }

  return (
    <SoftBox sx={{
      padding: "0 32px",
    }}>
      {/** Anchor target value */}
      <AnchorPointForm
        kind={metric?.kind}
        errors={errors}
        handleRegister={register}
        handleSetValue={(value) => validateSaveClick(value)}
        handleTrigger={trigger}
      />

      {/** Unit value */}
      <SetupFormInputField
        label="Set Units"
        placeholder="Enter Units  (1 for defaults)"
        description="Enter the measurement scale or granularity for your anchor point"
        name="unit"
        handleRegister={register}
        errors={errors}
        isFakeTooltip
      />

      {/** Point value */}
      <SetupFormInputField
        label="Set a point Value"
        placeholder="Set a point Value"
        description="Allocate point to each Unit. This shows the value of the players progress or regression."
        name="point"
        handleRegister={register}
        errors={errors}
        isFakeTooltip
      />

      {/** Footer text */}
      <SoftBox
        sx={{
          width: "80%",
          margin: "46px 0"
        }}
      >
        <SoftTypography
          variant="body1"
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "12px !important",
            lineHeight: "15.62px !important",
            color: "#D0D5DD",
          }}
        >
          The progressive metrics and the anchor metrics will enable you to allocate and setup a target for your players.
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default SetupAnchorForm;
