import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  buttonContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    "& > :not(style)": {
      borderRadius: "32px",
      paddingTop: "16px",
      paddingBottom: "16px",
    },
  },

  buttonStyle: {
    paddingRight: "48px !important",
    paddingLeft: "48px !important",
    fontSize: "1rem !important",
    textTransform: "capitalize !important",
    fontWeight: "700",
    width: "100%",
    color: "#fff !important",
    background: "linear-gradient(90deg, #EE238A 0%, #922790 93.85%) !important",
    borderRadius: "8px !important",
  },

  buttonOutlined: {
    color: "#667085 !important",
    textTransform: "capitalize !important",
    textAlign: "center !important",
  },

  leftPanel: {
    padding: "50px",
    width: "28%",
    margin: "80px 0px 40px 0px",
    "@media screen and (max-width: 1200px)": {
      width: "60% !important",
    },
    "@media screen and (max-width: 1000px)": {
      width: "90% !important",
    },
  },
  resetContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100vh",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "20px",
    left: "50px",
  },
});
