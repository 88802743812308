import { all, fork } from "redux-saga/effects";
import app from "./app/saga";
import auth from "./auth/saga";
import companyUser from "./user/saga";
import kpi from "./kpi/saga";
import company from "./company/saga";
import jobs from "./jobs/saga";
import messages from "./message/saga";
import workspaces from "./workspace/saga";
import masterBadges from "./badges/saga";
import chats from "./chats/saga";
import notifications from "./notifications/saga";
import challengeArena from "./arena/saga";
import claimsSaga from "./claims/saga";

function* rootSaga() {
  yield all([
    fork(app),
    fork(auth),
    fork(companyUser),
    fork(company),
    fork(jobs),
    fork(messages),
    fork(workspaces),
    fork(masterBadges),
    fork(kpi),
    fork(chats),
    fork(notifications),
    fork(challengeArena),
    fork(claimsSaga),
  ]);
}

export default rootSaga;
