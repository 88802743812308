import React from "react";

/** Generic demo page: edit as needed */
const DemoPage = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      Demo Page
    </div>
  )
}

export default DemoPage;