import React, { useState, useRef, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import gsap from "gsap";
import AudioBite from "./AudioBite";

import HearingIcon from "@mui/icons-material/Hearing";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";

import { UseButtonLongPress } from "../../hooks/utils";
import { UseUserDetails } from "../../hooks/auth";
import { PUT_FEEDBACK } from "../../api/feedback";
import { useReactMediaRecorder } from "react-media-recorder";

import "./recorderStyles.css";

export default function FeedbackRecorder({ open, closeDialog }) {
  const [audioBlob, setAudioBlob] = useState(null);

  const { status, error, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      mediaRecorderOptions: {
        mimeType: "audio/wav",
      },
      onStop: (blobUrl, blob) => {
        setAudioBlob(blob);
      },
    });

  const [playback, setPlayback] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const user = UseUserDetails();

  const pulseRef1 = useRef();
  const pulseRef2 = useRef();
  const pulseRefs = useMemo(() => [pulseRef1, pulseRef2], []);
  const [pulseAnims, setPulseAnims] = useState([]);

  const pulseWave = () => {
    const anims = [];
    for (let idx = 0; idx < pulseRefs.length; idx += 1) {
      const anim = gsap
        .timeline({ delay: idx * 0.75, repeat: -1 })
        .fromTo(
          pulseRefs[idx].current,
          { scale: 1, autoAlpha: 1 },
          { scale: 5, autoAlpha: 0, duration: 0.75, ease: "easeOut" },
        );
      anims.push(anim);
    }
    setPulseAnims(anims);
  };

  const pulseOff = () => {
    for (let idx = 0; idx < pulseAnims.length; idx += 1) {
      pulseAnims[idx].kill();
      gsap
        .timeline({ repeat: 0 })
        .to(pulseRefs[idx].current, { scale: 1, autoAlpha: 1, ease: "easeIn" });
    }
    setPulseAnims([]);
  };

  const longPress = UseButtonLongPress({
    activate: startRecording,
    deactivate: stopRecording,
  });

  const uploadAudio = () => {
    if (!error && playback && !uploading && !uploaded) {
      setUploading(true);
      const formData = new FormData();
      const ext = audioBlob.type.split("/")[1];
      formData.append("type", ext);
      formData.append("userId", user._id);
      formData.append("file", audioBlob);
      PUT_FEEDBACK(formData)
        .then(() => {
          setUploaded(true);
          setUploading(false);
        })
        .catch(() => {
          setUploading(false);
        });
    }
  };

  useEffect(() => {
    if (status === "recording") {
      pulseWave();
      setUploaded(false);
      setPlayback(null);
    } else if (status === "stopped" && mediaBlobUrl) {
      pulseOff();
      setPlayback(mediaBlobUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [status, mediaBlobUrl]);

  return (
    <Backdrop
      sx={{
        color: "#FFF",
        backgroundColor: "rgba(0,0,0,0.90)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "top",
          padding: 4,
          minWidth: "520px",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={8} />
          <Grid item xs={4} align="center">
            <Box className="pulse-container">
              <Box ref={pulseRef1} className="pulse" />
              <Box ref={pulseRef2} className="pulse" />
              <HearingIcon className="recording-icon" />
            </Box>
          </Grid>
          <Grid item xs={12} align="center">
            <IconButton
              {...longPress()}
              color="primary"
              aria-label="Push To Talk"
              disabled={uploading}
              sx={{
                marginBottom: 2,
                maxWidth: "20vh",
                maxHeight: "20vh",
                background: "white",
              }}
            >
              {uploading ? (
                <CircularProgress color="primary" size="10rem" />
              ) : (
                <MicNoneOutlinedIcon sx={{ width: "8rem", height: "8rem" }} />
              )}
            </IconButton>
            <Typography variant="body2" color="white" sx={{ mb: 4 }}>
              {!error
                ? "PRESS AND HOLD TO RECORD"
                : `Microphone is disabled: ${error}`}
            </Typography>
            <Box sx={{ height: "15vh", justifyContent: "center" }}>
              {playback && (
                <AudioBite src={playback} avatarImage={user.image} />
              )}
              {uploaded && (
                <Typography variant="body2" color="primary" sx={{ mb: 4 }}>
                  Feedback Uploaded
                </Typography>
              )}
            </Box>
            <ButtonGroup>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                sx={{ minWidth: "8rem" }}
                onClick={closeDialog}
              >
                Cancel
              </Button>
              {playback && !uploaded && (
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: "8rem" }}
                  onClick={uploadAudio}
                >
                  Submit
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Backdrop>
  );
}
