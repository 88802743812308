import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 1111111111111111,
};

const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

const getAnimationSettings = (originXA, originXB, gravity) => {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    gravity,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
};

const ConfettiFireworks = ({
  count = 15,
  minGap = 250,
  maxGap = 500,
  gravity = 1.5,
}) => {
  const refAnimationInstance = useRef(null);
  const [counter, setCounter] = useState(0);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.9, gravity));
    }
  }, []);

  useEffect(() => {
    if (counter < count) {
      nextTickAnimation();
      setTimeout(
        () => setCounter((prev) => prev + 1),
        randomInRange(minGap, maxGap),
      );
    }
  }, [count, counter, nextTickAnimation, minGap, maxGap]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};

export default ConfettiFireworks;
