/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";

// Custom styles for SoftSelect
import softStyles from "./styles";

const SoftSelect = forwardRef(
  (
    {
      size,
      error,
      success,
      styles: customStyles = null,
      darkTheme,
      fontFamily,
      ...rest
    },
    ref,
  ) => {
    const { light } = colors;

    return (
      <Select
        {...rest}
        ref={ref}
        styles={softStyles(
          size,
          error,
          success,
          customStyles,
          darkTheme,
          fontFamily,
        )}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
  },
);

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  darkTheme: false,
  fontFamily: "DM Sans",
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  darkTheme: PropTypes.bool,
  fontFamily: PropTypes.string,
};

export default SoftSelect;
