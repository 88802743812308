import React from "react";
import { Dialog, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { UseSubscriptionLevel } from "../../hooks/company";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";

// Imported Images and Icons
import PopUpModal from "../../img/enter-challenge-arena-notif.png";
import PopUpMobile from "../../img/ca-mobile-popup.png";
import gmbLogo from "../../img/gmb-favicon-192.png";
import MonsterGif from "../../img/challenge-arena-upgrade.gif";

const UpgradePopUp = ({ showUpgrade, closeUpgrade }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const level = UseSubscriptionLevel();

  return (
    <Dialog
      open={showUpgrade}
      onClose={closeUpgrade}
      PaperProps={{
        className: "bg-transparent shadow-none w-full md:!mx-[0rem]",
      }}
      maxWidth={matches ? "md" : "sm"}
    >
      <Box
        sx={{
          width: "100%",
          height: { mini: "650px", md: "420px" },
          display: "flex",
          justifyContent: { mini: "center" },
          alignItems: "center",
          marginX: "auto",
          backgroundImage: {
            mini: `url(${PopUpMobile})`,
            md: `url(${PopUpModal})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: { mini: "inherit", md: "contain" },
          backgroundPosition: { mini: "center", md: "8px 0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "center" },
            gap: 1,
            marginTop: { mini: "0rem", md: "2.25rem" },
            marginLeft: { mini: "0rem", md: "16.5rem" },
            height: { mini: "100%", md: "auto" },
          }}
        >
          <Box sx={{ marginTop: { mini: "3rem", md: "auto" } }}>
            <div className="max-w-[60px] mx-auto">
              <img src={gmbLogo} alt="logo" className="w-full" />
            </div>

            <div className="mt-[1rem] mb-[2rem] text-center">
              <div className="flex items-center justify-center mb-[1rem]">
                <div className="max-w-[60px]">
                  <img src={MonsterGif} alt="" className="w-full" />
                </div>
                <SoftTypography
                  sx={{
                    color: "white !important",
                    fontWeight: 400,
                    fontSize: {
                      mini: "1.5rem",
                      sm: "2rem",
                    },
                  }}
                >
                  UPGRADE <span style={{ fontWeight: 600 }}>NOW!</span>
                </SoftTypography>
              </div>

              <SoftTypography
                sx={{
                  color: "white !important",
                  fontWeight: 400,
                  fontSize: {
                    mini: "0.875rem",
                    sm: "1rem",
                  },
                  marginBottom: "1.5rem",
                }}
              >
                This feature is not available for{" "}
                {level ? "your current subscription tier" : "freemium users"}.
              </SoftTypography>
              <SoftTypography
                variant="body2"
                sx={{
                  color: "white !important",
                  fontWeight: 400,
                  fontSize: {
                    mini: "0.875rem",
                    sm: "1rem",
                  },
                  marginBottom: "1.5rem",
                }}
              >
                Upgrade to {level ? "a higher" : "any of the"} tier paid plan to
                access this feature.
              </SoftTypography>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              marginTop: { mini: "16rem", md: "auto" },
            }}
          >
            <SoftButton
              variant="outlined"
              sx={{
                color: "white !important",
                borderRadius: "2.25rem",
                fontWeight: 300,
                background: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
                backdropFilter: "blur(1.5px)",
                "&:hover": {
                  background: "rgba(255,255,255,0.3) !important",
                },
              }}
              onClick={() => {
                closeUpgrade();
              }}
            >
              CANCEL
            </SoftButton>
            <SoftButton
              variant="outlined"
              sx={{
                color: "white !important",
                borderRadius: "2.25rem",
                fontWeight: 300,
                background: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
                backdropFilter: "blur(1.5px)",
                "&:hover": {
                  background: "rgba(255,255,255,0.3) !important",
                },
              }}
              onClick={() => navigate("/upgrade")}
            >
              UPGRADE
            </SoftButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UpgradePopUp;
