import React from "react";
import { UseIsAdmin, UseCompanyId } from "../../hooks/auth";
import { UseGroupKpis } from "../../hooks/kpi";
import { GET_USER_KPIS } from "../../api/kpi";

import ScoreTable from "./ScoreTable";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { Divider, LinearProgress } from "@mui/material";
import "./scorecardStyles.css";
import { UseCompanyName } from "../../hooks/company";
import SoftProfile from "../../components/ProfilePictures/SoftProfile";

const HIDDEN_INTEGRATION_LABEL =
  process.env.REACT_APP_INTEGRATION_LABEL === "true";

const ScoreData = ({
  player,
  kpiId,
  isOwn,
  viewItem,
  light = false,
  isSmallScreen = false,
}) => {
  // Redux company and user states
  const companyId = UseCompanyId();
  const groupKpis = UseGroupKpis();
  const isAdmin = UseIsAdmin();
  const companyName = UseCompanyName();

  // Component states
  const [isLoading, setIsLoading] = React.useState(false);
  const [employeeKpis, setEmployeeKpis] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [kpiTitle, setKpiTitle] = React.useState(null);

  const getGroupKpi = (sKpiId) => {
    return groupKpis.find(item => item._id === sKpiId);
  }

  React.useEffect(() => {
    if (!player || !groupKpis) return;

    setIsLoading(true);
    setMessage(null);
    setEmployeeKpis([]);

    GET_USER_KPIS(companyId, player?.userId, kpiId)
      .then(({ data: eKpis }) => {
        if (eKpis.length === 0) {
          setMessage("No recent records found");
        } else {
          eKpis.forEach((ekpi) => {
            ekpi.name = player?.firstName
              ? `${player.firstName} ${player.lastName}`
              : "Unregistered";
            ekpi.image = player?.image;
          });
          eKpis.forEach((ekpi) => {
            const obj = groupKpis.find((kpi) => kpi._id === ekpi.kpiId);
            ekpi.kpiName = obj?.title || obj?.name;
            ekpi.kind = obj?.kind;
            const source =
              obj?.source?.toUpperCase() ||
              ekpi.sourceId?.toUpperCase() ||
              "MANUAL";
            if (HIDDEN_INTEGRATION_LABEL) {
              ekpi.dataSource =
                source !== "MANUAL" && source !== "CLAIMS"
                  ? "INTEGRATED"
                  : source;
            } else {
              ekpi.dataSource = source;
            }
          });
          setEmployeeKpis(eKpis);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setEmployeeKpis([]);
        setMessage(err.message);
        setIsLoading(false);
      });
  }, [player, companyId, kpiId]);

  React.useEffect(() => {
    if (kpiId && groupKpis?.length) {
      const kpiObj = groupKpis.find((k) => k._id === kpiId);
      setKpiTitle(kpiObj?.title);
    }
  }, [kpiId, groupKpis]);

  return (
    <SoftBox className="h-full w-full py-4">
      <SoftBox
        sx={{
          display: "flex",
          gap: "0.75rem",
          marginBottom: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SoftProfile size="xl" src={player?.image} name="" />

        <SoftBox>
          <SoftTypography
            variant={kpiTitle ? "h4" : "h3"}
            color="white"
            sx={{ width: "100%" }}
          >
            {`${player?.firstName} ${player?.lastName}${kpiTitle ? "'s" : ""}`}
            {kpiTitle ? ` ${kpiTitle}` : ""}
          </SoftTypography>
          <SoftTypography variant="body2" color="white" sx={{ width: "100%" }}>
            {companyName}
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      <SoftBox className="mx-4">
        <Divider className="!mb-0" />
        {isLoading && <LinearProgress />}
      </SoftBox>
      <SoftBox className="w-full overflow-y-scroll text-center no-scrollbar">
        <ScoreTable
          light={light}
          employeeKpis={employeeKpis}
          isAdmin={isAdmin}
          className="px-2"
          message={message}
          viewItem={viewItem}
          isSmallScreen={isSmallScreen}
          getGroupKpi={getGroupKpi}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default ScoreData;
