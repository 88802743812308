import React from "react";
import { styled } from "@mui/material/styles";
import SoftBox from "../../../SoftBox";

const FormPageLayout = styled(SoftBox)(({ theme }) => ({
  "& .MuiInputBase-root, .MuiButtonBase-root": {
    padding: "12px",
    borderRadius: "4px"
  },
  "& .MuiBox-root": {
    marginBottom: "1rem"
  }
}));

export default FormPageLayout;
