import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TagIcon from "@mui/icons-material/Tag";
import PercentIcon from "@mui/icons-material/Percent";

export const KIND_CONFIGS = {
  "$": {
    icon: <AttachMoneyIcon />,
    name: "Monetary",
    desc: "Select this if your KPI is measured in currency or money",
  },
  "#": {
    icon: <TagIcon />,
    name: "Item",
    desc: "Choose this if your KPI is measured in discrete items or counts",
  },
  "%": {
      icon: <PercentIcon />,
      name: "Percentage",
      desc: "Opt for this if your KPI is expressed as a percentage of a whole",
    }
};

export const KIND_OPTIONS = [
  KIND_CONFIGS.$,
  KIND_CONFIGS["#"],
  // On KPI Metric type package, specific kind is defined per type
  // Except for Progressive which can either be Monetray or Item; Percentage is no longer an option
  // KIND_CONFIGS["%"],
];
