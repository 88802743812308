import React, { useState, useEffect } from "react";
import { UseCompanyId } from "../../hooks/auth";
import moment from "moment";
// Imported Images and Icons
import Grid from "@mui/material/Grid";
import LoadingScreen from "../../components/LoadingScreen";
import { GET_FEEDBACKS, GET_FEEDBACK_URL } from "../../api/feedback";
import AudioBite from "../../components/FeedbackRecorder/AudioBite";
import PageHeader from "../../components/PageHeader";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { GET_IMAGE } from "../../helpers/images";
import defaultAvatar from "../../img/avatar2.png";

const FeedbackViewer = () => {
  const companyId = UseCompanyId();
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (companyId) {
      GET_FEEDBACKS(companyId).then(({ data }) => {
        setItems(data);
        setIsLoading(false);
      });
    }
  }, [companyId]);
  return (
      <div>
        {/* Page Content */}
        <div className="container my-10 pl-[12px] md:pl-[72px]">
          {/* Page Header */}
          <PageHeader headerText="User Feedback" iconKey="sound" />

          {/* Feedback Section */}
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <div className="flex justify-center">
              <Grid container spacing={4} sx={{ ml: 4, mr: 4 }}>
                {items &&
                  items.length > 0 &&
                  items.map((feedback) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        key={feedback._id}
                        sx={{ justifyContent: "flex-start" }}
                      >
                        <p className="text-m md:text-sm font-bold">
                          {feedback.user
                            ? `${feedback.user?.firstName} ${feedback.user?.lastName}`
                            : "Anonymous"}
                        </p>
                        <p className="text-xs md:text-sm font-semibold text-[#9B9B9B]">
                          {moment(feedback.createdAt).format(
                            "ddd | MMM Do YYYY",
                          )}
                        </p>
                        {feedback.key && (
                          <AudioBite
                            src={GET_FEEDBACK_URL(feedback.key)}
                            avatarImage={feedback.user?.image}
                          />
                        )}
                        {feedback.text && (
                          <Stack
                            direction="column"
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <img
                              className="w-16 h-16 rounded-full border-2 border-white dark:border-gray-800"
                              src={
                                feedback.user?.image
                                  ? GET_IMAGE(feedback.user?.image)
                                  : defaultAvatar
                              }
                              alt=""
                            />
                            <Typography variant="caption" component="p">
                              {" "}
                              {feedback.text}
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                    );
                  })}
                {items && items.length === 0 && (
                  <p className="text-m md:text-sm font-bold">
                    No User Feedback
                  </p>
                )}
              </Grid>
            </div>
          )}
        </div>
      </div>
  );
};

export default FeedbackViewer;
