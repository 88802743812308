import React, { useEffect, useState } from "react";
import {
  Grid,
  Link,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { LoginSocialGoogle } from "reactjs-social-login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import { loginUser, socialLogin } from "../../redux/auth/actions";
import GMGLogo from "../../img/GMB_Logo.svg";
import { useStyles } from "./loginStyles";
import SocialsLogin from "./SocialsLogin";
import { LoginSchema } from "../../form-validations";
import {
  UseAuth,
  UseAuthErrorMessage,
  UseUserDetails,
  UseIsAdmin,
  UseLoading,
} from "../../hooks/auth";
import {
  UseIsCompanyUpToDate,
  UseSubscriptionLevel,
} from "../../hooks/company";
import googleLogo from "../../img/googleLogo.png";
import SoftButton from "../SoftButton";

const GOOLE_AUTH_SCOPE =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

const LoginCard = ({ authMode, coldLeads = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginStatus = UseAuth();
  const companyStatus = UseIsCompanyUpToDate();
  const authLoading = UseLoading();
  const user = UseUserDetails();
  const errorMessage = UseAuthErrorMessage();
  const isAdmin = UseIsAdmin();
  const navigate = useNavigate();
  const isSubscribed = UseSubscriptionLevel();

  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const allowedCompanyViewSubscription = 2;
  const MAX_LEVEL_DEMO = 99;

  const validationOptions = {
    resolver: yupResolver(LoginSchema),
    shouldUnregister: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationOptions);

  const handleLogin = async (formData) => {
    setIsLoading(true);
    const payload = {
      ...formData,
      username: formData.username.trim().toLowerCase(),
    };

    dispatch(loginUser(payload));

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (loginStatus && companyStatus && user && !authMode) {
      // No redirects on auth mode
      if (user.companyId) {
        let adminOrPlayerHomeRoute = "/home";

        // Redirect to Admin dashboard only for admin accounts with subscription lvl >= 2
        if (isAdmin && isSubscribed >= allowedCompanyViewSubscription && isSubscribed !== MAX_LEVEL_DEMO) {
          adminOrPlayerHomeRoute = "/company-view";
        }

        navigate(adminOrPlayerHomeRoute);
      } else {
        navigate(`/company`);
      }
    }

    if (window.ReactNativeWebView && user) {
      const data = {
        ...user,
        login_state: "success",
      };

      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }, [loginStatus, companyStatus, user, authMode, navigate, isSubscribed]);

  useEffect(() => {
    if (!authLoading) {
      setIsLoading(false);
    }
  }, [authLoading]);

  return (
    <>
      <SoftBox className={classes.logoBox}>
        <img src={GMGLogo} alt="Game My Biz" />
        <span
          style={{
            color: "#051923",
            fontSize: "20px",
            fontWeight: "500",
            marginLeft: "10px",
          }}
        >
          {" "}
          Game My Biz
        </span>
      </SoftBox>
      <SoftBox borderRadius="xl" shadow="lg" className={classes.leftPanel}>
        <Grid
          item
          xs={12}
          md={12}
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <SoftTypography
            sx={{
              fontSize: "32px",
              fontWeight: "700",
              textAlign: "center",
              marginBottom: "5px",
              color: "#475467",
              "@media screen and (max-width: 700px)": {
                lineHeight: "35px !important",
              },
            }}
          >
            Log in to your Gameboard
          </SoftTypography>
          <SoftTypography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
              color: "#98A2B3",
            }}
          >
            Kindly enter your email and password to continue playing.
          </SoftTypography>
        </Grid>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <SoftInput
              placeholder="Username"
              label="Username"
              {...register("username")}
              error={!!errors?.username}
              helperText={errors?.username?.message}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ width: "100%" }}>
            <SoftInput
              placeholder="Password"
              label="Password"
              type={visible ? "text" : "password"}
              {...register("password")}
              error={!!errors?.password}
              helperText={errors?.password?.message}
              icon={{
                component: visible ? <VisibilityOffIcon /> : <VisibilityIcon />,
                direction: "right",
                handler: () => {
                  setVisible(!visible);
                },
              }}
            />
          </Grid>
          {errorMessage && (
            <p className="text-orange-600 italic">{errorMessage}</p>
          )}
          <Grid item md={12} style={{ wdith: "100%", marginBottom: "20px" }}>
            {!coldLeads && (
              <SoftBox display="flex" justifyContent="space-between" pt="5px">
                <Link
                  href="/forgot-password"
                  sx={{
                    textDecoration: "none",
                    fontSize: "1rem",
                    color: "#475467",
                  }}
                >
                  Forgot Password?
                </Link>
                {!authMode && (
                  <FormControlLabel
                    className={classes.toggleStyle}
                    control={<Switch defaultChecked />}
                    label="Remember Me"
                  />
                )}
              </SoftBox>
            )}
          </Grid>
          <Grid item md={12}>
            <SoftBox className={classes.buttonContainer}>
              <SoftButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                className={classes.buttonStyle}
                disabled={isLoading}
              >
                Sign In
                {isLoading && (
                  <CircularProgress
                    style={{ color: "white" }}
                    size="1rem"
                    className="ml-2"
                  />
                )}
              </SoftButton>
            </SoftBox>
          </Grid>
        </form>
        <Grid item md={12}>
          <SoftTypography className="mt-2 text-center">or</SoftTypography>
          <SoftBox className="mt-2 mb-4">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID_AUTH || ""}
              isOnlyGetToken
              scope={GOOLE_AUTH_SCOPE}
              onResolve={({ provider, data }) => {
                dispatch(
                  socialLogin(data.access_token, provider.toLowerCase()),
                );
              }}
            >
              <SocialsLogin
                src={googleLogo}
                text="Sign In With Google"
                colour="grey"
              />
            </LoginSocialGoogle>
            {/* <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                  isOnlyGetToken
                  onResolve={({ provider, data }) => {
                    console.log('Facebook', data);
                    dispatch(socialLogin(data.access_token, provider.toLowerCase()))
                  }}
              
              >
                <SocialsLogin src={fbLogo} text='Continue With Facebook' background='#3b5998' colour='white' />
              </LoginSocialFacebook> */}
          </SoftBox>
        </Grid>
        <Grid item md={12}>
          <SoftTypography className={classes.noAccountText}>
            Don't have an account yet?{" "}
            <Link
              href="/register"
              underline="none"
              className={classes.linkText}
            >
              {" "}
              Sign up
            </Link>
          </SoftTypography>
        </Grid>
      </SoftBox>
    </>
  );
};

export default LoginCard;
