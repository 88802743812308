import React, { useState } from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import { Divider, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ChallengeDetails = ({
  handleClose,
  challenge,
  countdownEnded,
  handleOpen,
  handleViewChallenge,
  companyId,
}) => {
  const [challengeLeaderboard, setChallengeLeaderboard] = useState([]);
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: { mini: "1rem", md: "1.5rem" },
          // justifyContent: { mini: "center", xs: "justify-content" },
        }}
      >
        <SoftTypography variant="h4" color="white">
          {challenge.title} challenge
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          margin: 0,
          backgroundImage:
            "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
        }}
      />
      <SoftBox sx={{ padding: { mini: "1.5rem", md: "2rem" } }}>
        <SoftBox>
          <SoftTypography color="primary" textGradient variant="h5">
            Description
          </SoftTypography>
          <SoftTypography variant="body2" color="white">
            {challenge.description}
          </SoftTypography>
        </SoftBox>
        {challenge.prizeForWinner ||
        challenge.prizeforSecondPlace ||
        challenge.prizeforThirdPlace ? (
          <Divider
            sx={{
              backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
              "&:not([size])": {
                height: "3px",
              },
            }}
          />
        ) : null}
        {challenge.prizeForWinner ||
        challenge.prizeforSecondPlace ||
        challenge.prizeforThirdPlace ? (
          <SoftBox>
            <SoftTypography color="primary" textGradient variant="h5">
              Rewards
            </SoftTypography>
            {challenge.prizeForWinner && (
              <SoftTypography color="white" variant="body2">
                Top performer: {challenge.prizeForWinner}
              </SoftTypography>
            )}
            {challenge.prizeforSecondPlace && (
              <SoftTypography color="white" variant="body2">
                2nd place: {challenge.prizeforSecondPlace}
              </SoftTypography>
            )}
            {challenge.prizeforThirdPlace && (
              <SoftTypography color="white" variant="body2">
                3rd place: {challenge.prizeforThirdPlace}
              </SoftTypography>
            )}
          </SoftBox>
        ) : null}
        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "3px",
            },
          }}
        />
        {challengeLeaderboard.length > 0 ? (
          <>
            <SoftBox>
              <SoftTypography color="primary" textGradient variant="h5">
                Current Leaderboard
              </SoftTypography>
            </SoftBox>
            <Divider
              sx={{
                backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
              }}
            />{" "}
          </>
        ) : null}
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          sx={{ marginTop: "2rem" }}
        >
          {challenge.companyId === companyId ? (
            <SoftButton
              variant="outlined"
              sx={{
                background: "transparent",
                borderColor: "white",
                color: "white !important",
                textTransform: "none",
                padding: "13px 32px",
                width: "100%",
                "&:hover": { borderColor: "white", background: "transparent" },
                "&:focus:not(:hover)": {
                  borderColor: "white",
                  background: "transparent",
                },
              }}
              onClick={handleViewChallenge}
            >
              View Challenge
            </SoftButton>
          ) : (
            <SoftButton
              variant="outlined"
              disabled={!!countdownEnded}
              sx={{
                background: "transparent",
                borderColor: "white",
                color: "white !important",
                textTransform: "none",
                padding: "13px 32px",
                width: "100%",
                "&:hover": { borderColor: "white", background: "transparent" },
                "&:focus:not(:hover)": {
                  borderColor: "white",
                  background: "transparent",
                },
              }}
              onClick={countdownEnded ? "" : handleOpen}
            >
              Join Challenge
            </SoftButton>
          )}

          <SoftButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              textTransform: "none",
            }}
            onClick={handleClose}
          >
            Okay
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default ChallengeDetails;
