import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EastIcon from "@mui/icons-material/East";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import RocketWhite from "../../img/rocket-white.png";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";

import "./styles/billingType.css";

const BillingType = ({ datas, selectSubscription, isActive }) => {
  const [isDisabled] = useState(datas?.disabled);

  return (
    <SoftBox
      shadow="lg"
      borderRadius="lg"
      className={`${
        isDisabled ? "billing-card-disabled" : "billing-card-enabled"
      } relative`}
    >
      <Box
        className={`flex flex-col items-center mb-[1rem] ${
          !isDisabled ? "billing-header-enabled" : "mt-[2.25rem]"
        }`}
      >
        {!isDisabled && (
          <img src={RocketWhite} alt="Starter Plan" height="100px" />
        )}
        <Box className={`billing-title-box ${isDisabled ? "" : "mb-3"}`}>
          <p className="billing-title highlight-gradient">
            {datas.name} Package Plan
          </p>
        </Box>
        <Box
          className="flex justify-center items-end gap-1"
          sx={{ color: isDisabled ? "#343A40" : "#E9ECEF" }}
        >
          <span className="billing-price-amount"> ${datas.amount / 100}</span>
          <span className="billing-price-per mb-[0.25rem]">/ Per Month</span>
        </Box>
        {isDisabled && (
          <Divider
            sx={{
              width: "80%",
              height: "2px",
              bgcolor: isDisabled ? "#E6E8EC" : "#FFF",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          paddingX: "1.5rem",
          // color: isDisabled ? "#343A40" : "#E9ECEF",
          mb: "8rem",
        }}
      >
        {datas.features
          ? datas.features.map((data) => {
              return (
                <Box
                  key={data}
                  className="flex justify-between items-center mt-2"
                >
                  <Box className="flex gap-3">
                    <Box
                      className="rounded-full flex items-center justify-center"
                      sx={{
                        paddingX: "5px",
                        bgcolor: isDisabled ? "#495057" : "#F0F5FF",
                        color: isDisabled ? "#495057" : "#E9ECEF",
                        maxHeight: "1.5rem",
                      }}
                    >
                      <CheckIcon
                        sx={{
                          fontSize: "0.75rem !important",
                          color: isDisabled ? "#FFF" : "#FF0080",
                        }}
                      />
                    </Box>
                    <span className="billing-list-tiem">{data}</span>
                  </Box>
                  <ErrorOutlineIcon sx={{ color: "inherit" }} />
                </Box>
              );
            })
          : null}
      </Box>
      {datas.features && (
        <Box className="w-full absolute bottom-[2rem] flex justify-center">
          <SoftButton
            disabled={isDisabled}
            className="flex items-center justify-center gap-2"
            sx={{
              minWidth: "80%",
              color: isDisabled ? "#FFF !important" : "#4B0082",
              bgcolor: isDisabled ? "#0085FF !important" : "#FFF",
              opacity: isDisabled ? "0.5 !important" : "1",
            }}
            onClick={() => selectSubscription()}
          >
            {isDisabled ? "Coming Soon" : "Choose Plan"}
            <EastIcon sx={{ color: "inherit", fontSize: "1.25rem" }} />
          </SoftButton>
        </Box>
      )}
    </SoftBox>
  );
};

export default BillingType;
