import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import moment from "moment";
import { toast } from "react-hot-toast";

// @mui material components
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftButton from "../../SoftButton";

// ChallengeArena Form Components
import DetailsForm from "./Steps/DetailsForm";
import PrizeThemeForm from "./Steps/PrizeThemeForm";
import PreviewSubmitForm from "./Steps/PreviewSubmitForm";
import FormControlButtonGroup from "./Layout/FormControlButtonGroup";
import popNotification from "../../Modals/popNotification";

// Hooks
import { UseCompanyId, UseUserId } from "../../../hooks/auth";
import { CREATE_ONE_VS_ONE_CHALLENGE, SEND_INVITE_URL } from "../../../api/challenge";
import CreateChallengeArenaDetailsSchema from "../../../form-validations/CreateChallengeArenaDetailsSchema";

const CreateChallengeForm = () => {
  const [pagesFormData, setPagesFormData] = useState(null);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [isDisableButtons, setDisableButtonGroup] = useState(false);
  const [step, setStep] = useState(0);

  const userId = UseUserId();
  const companyId = UseCompanyId();
  const navigate = useNavigate();

  const MUIMdBreakpoint = "1200px";

  const stepsData = [
    {
      label: "Challenge Details",
      buttonLabel: "Next",
      fields: ["title", "category", "startDate", "endDate", "isPrivate"]
    },
    {
      label: "Prize/Arena Theme",
      buttonLabel: "Preview",
      fields: ["prizeForWinner", "prizeforSecondPlace", "prizeforParticipants", "description"]
    },
    {
      label: "Preview/Submit",
      buttonLabel: "Submit",
      fields: []
    }
  ]

  // Stepper page-specific validation options
  const detailsValidationOptions = {
    resolver: yupResolver(CreateChallengeArenaDetailsSchema),
    shouldUnregister: false,
    defaultValues: {
      startDate: moment().format("YYYY-MM-DD"),
      description: "Test your limits"
    }
  };

  const methods = useForm(detailsValidationOptions);

  // Additional page form data processing
  const handleSubmitForm = (formData) => {
    if (formData) {
      // Check/remove category prices
      if (formData?.category !== "CompanyGroups") {
        const excludePrizes = ["prizeforThirdPlace", "prizeforParticipants"];

        excludePrizes.forEach(field => {
          methods.resetField(field);
          formData[field] = null;
        });
      }

      setPagesFormData(prev => ({ ...prev, ...formData }));
    }
  }

  // Validates the active stepper form on "Next" button press
  const handleNext = async () => {
    // Trigger validation on the active stepper page
    const result = await methods.trigger(stepsData[step].fields, { shouldFocus: true });

    if (!result) return;

    handleSubmitForm(methods.getValues());

    // Trigger form submission on the active stepper page
    // methods.handleSubmit(handleSubmitForm)();

    if (step < stepsData.length) {
      setStep(prev => prev + 1);
    }
  }

  const handleBack = () => {
    setStep(prev => prev - 1);
  }

  // Saves the challenge to DB
  const onSubmit = async () => {
    try {
      const payload = {
        ...pagesFormData,
        isPrivate: pagesFormData?.isPrivate?.value ?? false,
        userId,
        companyId,
      }

      Object.keys(payload).forEach(key => {
        if ([undefined, null, ""].includes(payload[key])) {
          delete payload[key];
        }
      });

      // Create the challenge
      setDisableButtonGroup(true);
      const { data } = await CREATE_ONE_VS_ONE_CHALLENGE(payload);

      // Send player invites only if the payload category is "EmployeeVsEmployee" and if there are users to invite
      if (
        data &&
        payload?.category === "EmployeeVsEmployee" &&
        usersToInvite.length > 0
      ) {
        await Promise.all(
          usersToInvite.map(user =>
            SEND_INVITE_URL(data.challenge._id, user))
        )
      }

      popNotification({
        title: "Success",
        text: data.message
      });

      setTimeout(() => {
        navigate("/challenge-arena");
      }, 1500);
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
    }
  }

  return (
    <>
      {/** Stepper */}
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          marginY: "1.5rem",
          maxWidth: MUIMdBreakpoint,
          margin: "auto",
          "& .MuiStepLabel-labelContainer": {
            "& .Mui-active, .Mui-completed": {
              color: (theme) => `${theme.palette.primary.main} !important`
            }
          }
        }}
      >
        {stepsData.map((stepInfo) => (
          <Step key={crypto?.randomUUID() ?? stepInfo.label}>
            <StepLabel>
              {stepInfo.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/** Content */}
      <Container 
        maxWidth="lg"
        sx={{ 
          padding: 0,
          maxWidth: "900px",
          paddingBottom: (theme) => theme.spacing(5),
          paddingTop: (theme) => theme.spacing(5)
        }
      }>
        <Card 
          sx={{
            width: "100%",
            padding: (theme) => theme.spacing(1),
            maxWidth: "850px",
            margin: "auto",
            borderRadius: "8px",
            boxShadow: {
              xs: "none",
              sm: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)"
            }
          }}
        >
          {/** Form stepper page content */}
          <Grid container>
            <Grid item xs={12} sx={{ margin: "auto" }}>
              <FormProvider {...methods}>
                <form autoComplete="off">
                  {(step === 0) &&
                    <DetailsForm
                      usersToInvite={usersToInvite}
                      setUsersToInvite={setUsersToInvite}
                    />
                  }

                  {(step === 1) &&
                    <PrizeThemeForm />
                  }

                  {(step === 2) &&
                    <PreviewSubmitForm pagesFormData={pagesFormData} />
                  }
                </form>
              </FormProvider>
            </Grid>
          </Grid>

          {/** Form buttons */}
          <FormControlButtonGroup
            sx={{
            padding: { mini: "1rem", md: "1.5rem" },
            width: "100%",
            "& button": {
              textTransform: "capitalize",
              fontStyle: "normal"
            }
          }}>
            {(step > 0)
              ? <SoftButton
                  variant="gradient"
                  color="light"
                  disabled={isDisableButtons}
                  onClick={handleBack}
                >
                  Back
                </SoftButton>
              : <SoftButton sx={{ visibility: "hidden", zIndex: "-1" }}>&nbsp;</SoftButton>
            }

            <SoftButton
              color="dark"
              disabled={isDisableButtons}
              onClick={!(step === stepsData.length - 1) ? handleNext : onSubmit}
              sx={{
                borderRadius: "8px",
                boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12) !important",
                background: (theme) =>
                  `linear-gradient(274deg, ${theme.palette.primary.darkest} 0%, ${theme.palette.primary.main} 100%)`
              }}
            >
              {stepsData[step].buttonLabel}
            </SoftButton>
          </FormControlButtonGroup>
        </Card>
      </Container>
    </>
  )
};

export default CreateChallengeForm;