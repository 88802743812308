/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// Soft UI Dashboard PRO React icons

import LogoutIcon from "@mui/icons-material/Logout";
import CastleIcon from "@mui/icons-material/CastleOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import GameboardIcon from "../components/NavIcons/GameboardIcon";
import SettingsIcon from "../components/NavIcons/SettingsIcon";
import HubIcon from "../components/NavIcons/HubIcon";
import ScorekeeperIcon from "../components/NavIcons/ScorekeeperIcon";
import ClaimsIcon from "../components/NavIcons/ClaimsIcon";
import FeedIcon from "../components/NavIcons/FeedIcon";
import MessageIcon from "../components/NavIcons/MessageIcon";
import ChallengeArenaIcon from "../components/NavIcons/ChallengeArenaIcon";
import DashboardIcon from "../components/NavIcons/DashboardIcon";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const sidenavRoutes = [
  {
    type: "collapse",
    name: "Company View",
    key: "company-view",
    route: "/company-view",
    icon: <ApartmentOutlinedIcon />,
    noCollapse: true,
    minSubscription: 2,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "home",
    route: "/home",
    icon: <DashboardIcon />,
    noCollapse: true,
  },
  { type: "title", title: "Gameboard", key: "gameboard-pages" },
  {
    type: "collapse",
    name: "Gameboard",
    key: "gameboards",
    icon: <GameboardIcon />,
    collapse: [
      {
        name: "Gameboard Library",
        key: "gameboard-library",
        route: "/gameboards/gameboard-library",
      },
      {
        name: "Bad Guy",
        key: "bad-guy",
        route: "/gameboards/bad-guy",
      },
      {
        name: "Metrics",
        key: "workspace-kpi",
        route: "/gameboards/workspace-kpi",
      },
    ],
  },
  { type: "title", title: "Company", key: "company-pages" },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsIcon />,
    collapse: [
      {
        name: "Company Details",
        key: "company-details",
        route: "/settings/company-details",
      },
      {
        name: "Players Management",
        key: "players",
        route: "/settings/players",
      },
      {
        name: "Level Up",
        key: "level-up",
        route: "/settings/level-up",
        primeOnly: true,
      },
      {
        name: "Manage Widgets",
        key: "manage-widgets",
        route: "/widgets",
        primeOnly: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Hub",
    key: "hub",
    route: "/hub",
    icon: <HubIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Scorekeeper",
    key: "scorekeeper",
    route: "/scorekeeper",
    icon: <ScorekeeperIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Claims",
    key: "claims",
    route: "/claims",
    icon: <ClaimsIcon />,
    noCollapse: true,
  },
  {
    type: "title",
    title: "Activities",
    key: "activity-pages",
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Feed",
    key: "feed",
    route: "/feed",
    icon: <FeedIcon />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    route: "/messages",
    icon: <MessageIcon />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Challenge Arena",
    key: "challenge-arena",
    route: "/challenge-arena",
    icon: <ChallengeArenaIcon />,
    noCollapse: true,
    primeOnly: true,
  },
  {
    type: "title",
    title: "System",
    key: "system-pages",
    affiliateOnly: true,
    primeOnly: true,
  },
  {
    type: "collapse",
    name: "Affiliate Hub",
    key: "affiliates",
    route: "/affiliates",
    icon: <CastleIcon />,
    noCollapse: true,
    affiliateOnly: true,
    primeOnly: true,
  },
  /* Hide the Upgrade button while we're implementing the Per Seat Pricing model
  {
    type: "collapse",
    name: "Upgrade",
    key: "upgrade",
    route: "/upgrade",
    icon: <TrendingUpIcon />,
    noCollapse: true,
    maxSubscription: 2,
  },
  */
];

export default sidenavRoutes;
