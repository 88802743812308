import { useSelector } from "react-redux";

export const UseArrangedUsers = () => {
  const getArrangedUsers = useSelector(
    (state) => state.usersReducer.arrangedUsers,
  );
  return getArrangedUsers;
};

export const UseLoading = () => {
  const loading = useSelector((state) => state.usersReducer.isLoading);
  return loading;
};

export const UseViewAsUser = () => {
  const viewAsUser = useSelector((state) => state.usersReducer.viewAsUser);
  return viewAsUser;
};

export const UseSocket = () => {
  const getSocket = useSelector((state) => state.usersReducer.socket);

  return getSocket;
};

export const UseTableDetails = () => {
  const getDetails = useSelector(
    (state) => state.usersReducer.tableDetails,
  );

  return getDetails;
};

export const UseTablePageNumber = () => {
  const pageNumber = useSelector(
    (state) => state.usersReducer.pageNumber,
  );
  return pageNumber;
};
