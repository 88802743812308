import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";

import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import { GET_IMAGE } from "../../helpers/images";
import { dataLevels } from "./data/index";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  height: 700,
  overflowY: "scroll",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
};

const BadgeRequirementPreview = ({
  openPreview,
  setOpenPreview,
  board,
  setBoard,
  isAdmin,
  setPopUp,
}) => {
  const [levelIndex, setLevelIndex] = useState(null);

  useEffect(() => {
    board.columns.map((item, index) => {
      item.cards.map((card) => {
        if (card.id === openPreview.id) {
          setLevelIndex(index);
        }
      });
    });
  }, []);

  const actionTrigger = () => {
    const duplicate = { ...board };
    if (openPreview.add) {
      const element = {
        index: openPreview.template.index,
        badges: [
          { id: openPreview.template._id, template: openPreview.template },
        ],
      };

      openPreview.onAdd(element);
      setPopUp({ open: false, index: 0, badgesCopy: [] });
    } else {
      const filteredCards = duplicate.columns[levelIndex].cards.filter(
        (item) => item.id !== openPreview.id,
      );
      duplicate.columns[levelIndex].cards = filteredCards;

      setBoard(duplicate);
      setOpenPreview({ open: false, id: null, template: null, add: null });
    }
  };

  return (
    <Modal
      open={openPreview.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftBox display="flex" alignItems="center" className="mb-4">
          <SoftBox display="flex" alignItems="center">
            <div
              style={{
                height: "50px",
                width: "50px",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
              }}
              className="my-2"
            >
              <img
                src={GET_IMAGE(openPreview.template.image)}
                className={openPreview.status < 1 ? "grayscale-image" : ""}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography
                component="h3"
                sx={{
                  fontSize: "18px",
                  color: "#000",
                  margin: 0,
                  padding: 0,
                  fontWeight: "500",
                }}
              >
                Badge Requirement
              </SoftTypography>
              <SoftTypography
                component="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0, 0, 0, 0.6);",
                }}
              >
                Social Media Metrics, Window Cleaning
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {isAdmin ? (
            <div className="ml-auto">
              <SoftButton
                variant="outlined"
                sx={{
                  borderRadius: "100px",
                  color: "rgba(0, 0, 0, 0.6)",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  textTransform: "capitalize",
                }}
                onClick={actionTrigger}
              >
                {openPreview.add ? "Add" : "Remove"}
              </SoftButton>
            </div>
          ) : null}
        </SoftBox>
        <div className="flex justify-between items-center border-t border-b border-gray-300 py-4 px-8">
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              component="h6"
              sx={{
                fontSize: "16px",
                color: "#000",
                margin: 0,
                padding: 0,
                fontWeight: "500",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Connected Level
            </SoftTypography>
            <SoftButton
              variant="outlined"
              sx={{
                borderRadius: "100px",
                color:
                  levelIndex === null
                    ? "#FF5722"
                    : dataLevels[levelIndex].lvlColor,
                borderColor:
                  levelIndex === null
                    ? "#FF5722"
                    : dataLevels[levelIndex].lvlColor,
                textTransform: "capitalize",
              }}
            >
              {levelIndex === null
                ? "0 not yet connected"
                : `${dataLevels[levelIndex].lvlName}`}
            </SoftButton>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              component="h6"
              sx={{
                fontSize: "16px",
                color: "#000",
                margin: 0,
                padding: 0,
                fontWeight: "500",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Badge Category
            </SoftTypography>
            <SoftButton
              variant="outlined"
              sx={{
                borderRadius: "100px",
                color: "rgba(0, 0, 0, 0.6)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                textTransform: "capitalize",
              }}
            >
              Main Quest
            </SoftButton>
          </SoftBox>
        </div>
        <SoftBox display="flex" flexDirection="column" justifyContent="center">
          <div
            style={{
              height: 180,
              width: 180,
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
            className="my-2"
          >
            <img
              src={GET_IMAGE(openPreview.template.image)}
              alt=""
              style={{ height: "100%", width: "100%" }}
              className={openPreview.status < 1 ? "grayscale-image" : ""}
            />
          </div>
          <SoftTypography
            component="h6"
            sx={{
              fontSize: "16px",
              color: "#000",
              margin: 0,
              padding: 0,
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Description
          </SoftTypography>
          <SoftTypography
            component="p"
            sx={{
              fontSize: "14px",
              fontWeight: 300,
              color: "rgba(0, 0, 0, 0.6)",
              textAlign: "center",
              width: "420px",
              margin: "10px auto 20px auto",
            }}
          >
            Complete the following quest to obtain this badge:
            <br />
            1. Go to HR Office and meet your **Supervisor. Attend Seminar within
            the given timeframe: Must complete within this week.
            <br />
            2. Get your JT User Handbook from the HR and start reviewing the
            details.
          </SoftTypography>
          <SoftButton
            onClick={() =>
              setOpenPreview({
                open: false,
                id: null,
                template: null,
                status: 0,
                add: null,
                onAdd: null,
              })
            }
            variant="gradient"
            sx={{
              color: "#FFF",
              backgroundColor: "#0160FE",
              width: "89px",
              margin: "0 auto",
            }}
          >
            Close
          </SoftButton>
        </SoftBox>
      </div>
    </Modal>
  );
};

export default BadgeRequirementPreview;
