/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import Icon from "@mui/material/Icon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "./styles/sidenavCollapse";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "../../context/softui";

function SidenavCollapse({
  icon,
  name,
  children,
  active,
  noCollapse,
  open,
  badge,
  ...rest
}) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  return (
    <>
      <ListItem component="li">
        <SoftBox
          {...rest}
          sx={(theme) => collapseItem(theme, { active, transparentSidenav })}
        >
          {/* <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                active,
                transparentSidenav,
                sidenavColor,
              })
            }
          > */}
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
          {/* </ListItemIcon> */}

          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "inherit",
              width: "100%",
            }}
          >
            <SoftBox
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  active,
                })
              }
            >
              <ListItemText
                style={{ fontWeight: 500, fontSize: "20px !important" }}
                primary={name}
              />
              {badge > 0 ? <Badge badgeContent={badge} color="error" /> : null}
            </SoftBox>

            {!noCollapse && (
              <Icon
                sx={(theme) =>
                  collapseArrow(theme, {
                    noCollapse,
                    transparentSidenav,
                    miniSidenav,
                    open,
                  })
                }
              >
                <KeyboardArrowUpIcon sx={{ display: "block" }} />
              </Icon>
            )}
          </SoftBox>
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
  badge: 0,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  badge: PropTypes.number,
};

export default SidenavCollapse;
