import React from "react";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

const GameboardPageContainer = ({
  title = "Title",
  caption = "Caption text",
  children
}) => {
  return (
    <SoftBox
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "8px",
        minHeight: "756px",
        padding: (theme) => theme.spacing(3),
        "& h3, h4": {
          fontFamily: "DM Sans",
          fontStyle: "normal",
        }
      }}
    >
      <SoftBox sx={{
        marginBottom: (theme) => theme.spacing(9),
        width: "50%",
      }}>
        <SoftTypography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "#000",
            marginBottom: "16px"
          }}
        >
          {title}
        </SoftTypography>

        <SoftTypography
          variant="h4"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "21px",
            color: "#66708"
          }}
        >
          {caption}
        </SoftTypography>
      </SoftBox>
      
      <SoftBox
        sx={{
          margin: "auto"
        }}
      >
        {children}
      </SoftBox>
    </SoftBox>
  );
};

export default GameboardPageContainer;

