import React, { useState } from "react";
import { Dialog, Divider, CircularProgress } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "styled-components";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import { ADD_NEW_DEFAULT_CARD } from "../../api/payment";

const CardInputWrapper = styled.div`
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 15px 10px;
  margin-bottom: 20px;
`;

const AddNewCardDialog = ({ newCardDialog, closeNewCardDialog }) => {
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        toast.error(error.message);
        setLoading(false);
        return;
      }

      const data = {
        cardToken: token.id,
      };
      await ADD_NEW_DEFAULT_CARD(data);

      toast.success("Cards added successfully");
      setLoading(false);
      closeNewCardDialog();
    } catch (error) {
      console.log("Error creating a new default card", error.response);
      toast.error(error.response.data.message ?? "Unable to add a card");
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={newCardDialog}
      onClose={closeNewCardDialog}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1rem 1.5rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "600px",
          borderWidth: "1px",
          borderColor: "#FFF",
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h4" color="white" sx={{ fontSize: "20px" }}>
          Add and Replace card
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeNewCardDialog}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      <form onSubmit={handleSubmit}>
        <CardInputWrapper>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "18px",
                  color: "#FFF",
                  iconColor: "#fff",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </CardInputWrapper>
        <SoftBox display="flex" justifyContent="flex-end">
          <SoftButton
            type="submit"
            variant="outlined"
            color="white"
            disabled={loading}
            sx={{
              color: "primary.contrastText",
              textTransform: "none",
              borderRadius: "4px",
            }}
          >
            Add Card
            {loading && (
              <CircularProgress color="inherit" size="1rem" className="ml-2" />
            )}
          </SoftButton>
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default AddNewCardDialog;
