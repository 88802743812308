import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { lighten } from "@mui/material/styles";
import KeyIcon from "@mui/icons-material/Key";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";
import borders from "../../assets/theme/base/borders";

const CustomOutlineCard = ({ code, challengeUrl }) => {
  const { secondary, dark } = colors;
  const { borderWidth } = borders;
  const [inviteCode, setInviteCode] = useState(null);

  useEffect(() => {
    if (code && challengeUrl) {
      setInviteCode(code);
    }
  }, [code, challengeUrl]);

  return (
    <SoftBox
      borderRadius="md"
      border={`${borderWidth[1]} dashed ${secondary.main}`}
      p={3}
    >
      <SoftBox my="0.5rem">
        <SoftTypography variant="body2" color="text">
          Generated 23 days ago by <strong>johnnyTsunamiWash23</strong>
        </SoftTypography>
        <SoftTypography variant="body2" color="text">
          (Used one time)
        </SoftTypography>
      </SoftBox>
      <SoftBox my="0.5rem">
        <Grid container spacing={4}>
          <Grid item mini={12} md={6}>
            <SoftInput
              value={inviteCode}
              icon={{
                component: <KeyIcon />,
                direction: "left",
              }}
              disabled={!inviteCode}
            />
          </Grid>
          <Grid item mini={12} md={6}>
            <CopyToClipboard
              text={inviteCode}
              onCopy={() => {
                toast.success("Challenge arena invite code copied to clipboard!");
              }}
            >
              <SoftButton variant="outlined" color="primary" disabled={!inviteCode}>
                Copy
              </SoftButton>
            </CopyToClipboard>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox my="0.5rem">
        <SoftTypography variant="body2" color="text">
          You cannot generate codes.
        </SoftTypography>
        <SoftTypography variant="body2" color="text">
          Upgrade to <strong>Ambassador Affiliate</strong> or{" "}
          <strong>Contact us</strong> to generate more referrals link.
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default CustomOutlineCard;
