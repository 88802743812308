import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tabs, Tab, Grid, Divider } from "@mui/material";
import { UseIsAdmin } from "../../hooks/auth";

// icons
import RocketIcon from "@mui/icons-material/Rocket";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import SoftBox from "../SoftBox";
import SoftSelect from "../SoftSelect";
import LiveLeaderboard from "./LiveLeaderboard";
import PastLeaderboard from "./PastLeaderboard";
import SoftButton from "../SoftButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SoftTypography from "../SoftTypography";
import { UseSelectedWorkspace } from "../../hooks/company";

const PERIODS = [
  { value: 0, label: "Yesterday" },
  { value: 1, label: "Today" },
  { value: 2, label: "This Week" },
  { value: 3, label: "This Month" },
];

function LeaderBoard({ isViewOnly = false, bigMode = true, isRealAdmin = false }) {
  const workspace = UseSelectedWorkspace();
  const [leaderboardTab, setLeaderboardTab] = useState(1);
  const [togglePeriod, setTogglePeriod] = useState(3);
  const isAdmin = UseIsAdmin();
  const navigate = useNavigate();
  const breakpointTheme = useTheme();
  const isBigScreen = useMediaQuery(breakpointTheme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(breakpointTheme.breakpoints.down("sm"));
  const isMidsizeScreen = useMediaQuery(breakpointTheme.breakpoints.down("md"));

  const LeaderboardComponent = [
    <PastLeaderboard bigMode={isBigScreen} isAdmin={isAdmin} />,
    <LiveLeaderboard
      periodIdx={togglePeriod}
      bigMode={isBigScreen}
      isAdmin={isAdmin}
      isViewOnly
    />,
  ];

  return (
    <SoftBox
      shadow="sm"
      borderRadius="12px"
      sx={{
        marginTop: ".40rem",
        background: "#fff",
        paddingX: {
          mini: "0.5rem",
          xs: "1rem",
          md: "2rem",
        },
        paddingY: { mini: "1rem", lg: "1.5rem" },
      }}
    >
      <SoftBox
        sx={{
          width: "100%",
          minHeight: "25vh",
          padding: "16px 0",
          ...(isSmallScreen && ({ padding: "8px 8px" }))
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={isBigScreen ? 0 : 2}
        >
          <Grid item mini={12} sx={{ height: { mini: "40px", sm: "unset" } }}>
            <SoftBox width="100%">
              <SoftTypography variant="h3"
                sx={{
                  textFillColor: "#000000",
                  fontSize: { mini: "12px", sm: "18px" },
                  lineHeight: { mini: "18px", sm: "1.167" },
                  fontWeight: "500",
                  marginBottom: "2rem"
                }}
              >
                {workspace.name} Leaderboard
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid
            item
            mini={12}
            xs={8}
            lg={5}
            xxl={4}
            xxxl={3}
            sx={{ display: "flex", gap: "0.5rem" }}
          >
            <SoftBox shadow="sm" borderRadius="8px" width="100%">
              <Tabs
                value={leaderboardTab}
                onChange={(e, value) => {
                  setLeaderboardTab(value);
                }}
                sx={{
                  padding: { mini: "0.25rem", md: "0.5rem" },
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    borderRadius: "7px",
                    boxShadow: "none",
                  },
                }}
              >
                <Tab
                  icon={<RocketIcon sx={{ height: "1.5rem" }} />}
                  label="Past Boards"
                  sx={{ fontSize: isSmallScreen ? "0.6rem" : ".9rem" }}
                />
                <Tab
                  icon={<LeaderboardIcon sx={{ height: "1.5rem" }} />}
                  label="Leaderboard"
                  sx={{ fontSize: isSmallScreen ? "0.6rem" : ".9rem" }}
                />
              </Tabs>
            </SoftBox>

            {/* Calendar button for mobile view */}
            <SoftBox display="flex" alignItems="center" justifyContent="center">
              <SoftButton
                sx={{
                  padding: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: isSmallScreen ? "0.9rem" : "1.25rem",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  width: "max-content",
                  height: "max-content",
                  gap: "0.25rem",
                  display: { mini: "flex", xs: "none" },
                }}
                onClick={() => navigate("/calendar")}
              >
                <CalendarMonthIcon
                  sx={{ height: "1.25rem", color: "primary.main" }}
                />
              </SoftButton>
            </SoftBox>
          </Grid>

          {!isViewOnly && (
            <Grid
              item
              mini={4}
              lg={4}
              xxl={4}
              display="flex"
              justifyContent="end"
              gap={isMidsizeScreen ? "8px" : "1rem"}
            >
              {/* Calendar button for desktop view */}
              <SoftBox
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SoftButton
                  sx={{
                    padding: "13px, 25px, 13px, 25px",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: isSmallScreen ? "0.9rem" : "1.25rem",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    width: "max-content",
                    height: "max-content",
                    gap: "0.25rem",
                    display: { mini: "none", xs: "flex" },
                  }}
                  onClick={() => navigate("/calendar")}
                >
                  <CalendarMonthIcon
                    sx={{ height: "1.25rem", color: "primary.main" }}
                  />
                </SoftButton>
              </SoftBox>

              {isAdmin && (
                <SoftButton
                  onClick={() => navigate("/settings/players")}
                  color="primary"
                  sx={{
                    padding: "13px, 25px, 13px, 25px",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    width: "max-content",
                    height: "max-content",
                    gap: "0.25rem",
                    display: { mini: "none", xs: "flex" },
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  <PersonAddIcon sx={{ height: "1.25rem", color: "#E9ECEF", }}/> Add Players
                </SoftButton>
              )}

              {/** Mobile add player button */}
              {(!isAdmin && isRealAdmin) && (
                <SoftButton
                  onClick={() => navigate("/settings/players")}
                  color="primary"
                  sx={{
                    padding: "13px, 25px, 13px, 25px",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    width: "max-content",
                    height: "max-content",
                    gap: "0.25rem",
                    display: { mini: "none", xs: "flex" },
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  <PersonAddIcon sx={{ height: "1.25rem", color: "#E9ECEF", }}/>
                </SoftButton>
              )}
            </Grid>
          )}
        </Grid>

        {(isViewOnly || isSmallScreen)
          ? <Divider sx={{ marginY: { mini: "1rem", lg: "1.5rem" } }} />
          : <hr className="my-3 divide-slate-300" />
        }

        <SoftBox
          borderRadius="xl"
          sx={{
            background: "#fff",
            paddingY: { mini: "0", md: "1.5rem" },
            paddingX: { mini: "0", md: "1.5rem" },
          }}
        >
          {leaderboardTab === 1 && !isViewOnly && !isMidsizeScreen && (
            <SoftBox
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <SoftSelect
                id="periods"
                name="periods"
                options={PERIODS}
                onChange={(option) => setTogglePeriod(option.value)}
                defaultValue={PERIODS[3]}
              />
            </SoftBox>
          )}

          {LeaderboardComponent[leaderboardTab]}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default LeaderBoard;
