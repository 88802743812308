/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

import { useLocation, NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftProfile from "../ProfilePictures/SoftProfile";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "../../context/softui";
import { UseAccess } from "../../hooks/auth";
import HelpCenter from "../HelpCenter";

function Sidenav({
  color,
  brand,
  brandName,
  routes,
  headerMenu,
  subheader,
  profile,
  badges = {},
  isPrime,
  isAdmin,
  isSubscribed: subscriptionLevel,
  isAffiliate,
  ...rest
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const access = UseAccess();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const dashboardRoute = routes?.find((route) => route.key === "home");
  const allowedCompanyViewSubscription = 2;
  const MAX_LEVEL_DEMO = 99;

  const adminOrHomeRoute = () => {
    if (!isAdmin || subscriptionLevel === MAX_LEVEL_DEMO) {
      return "/home";
    }
    return isAdmin && subscriptionLevel < allowedCompanyViewSubscription
      ? "/home"
      : "/company-view";
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      ),
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(
      ({ name, collapse, route, href, key, primeOnly, affiliateOnly }) => {
        // Do not render some menus unless user fits the pre-requisites
        if (primeOnly && !isPrime) {
          return null;
        }
        if (affiliateOnly && !isAffiliate) {
          return null;
        }

        let returnValue;

        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              name={name}
              active={key === itemName}
              open={openNestedCollapse === name}
              onClick={() =>
                openNestedCollapse === name
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(name)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem name={name} active={key === itemName} />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem name={name} active={key === itemName} />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      },
    );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      affiliateOnly,
      primeOnly,
      minSubscription,
      maxSubscription,
    }) => {
      let returnValue;

      // Do not render some menus unless user fits the pre-requisites
      if (primeOnly && !isPrime) {
        return null;
      }

      if (affiliateOnly && !isAffiliate) {
        return null;
      }

      if (minSubscription && minSubscription > subscriptionLevel) {
        return null;
      }

      if (maxSubscription && maxSubscription <= subscriptionLevel) {
        return null;
      }

      if (access[key] === false) {
        return null;
      }

      if (key === "company-view" && subscriptionLevel === MAX_LEVEL_DEMO) {
        return null;
      }

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                badge={badges[key]}
                sx={{ color: "primary.contrastText" }}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          // Highlight the selected Dashboard tab
          const newCollapseName = dashboardRoute?.route?.includes(collapseName)
            ? dashboardRoute.key
            : collapseName;

          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === newCollapseName}
                badge={badges[key]}
                sx={{ color: "primary.contrastText" }}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              badge={badges[key]}
              sx={{ color: "primary.contrastText" }}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <div key={key}>
            <hr />
            <SoftTypography
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
              sx={{ color: "primary.contrastText" }}
            >
              {title}
            </SoftTypography>
          </div>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    },
  );

  // Auto-hide side navbar if small screen
  const navDocking = { variant: isSmallScreen ? "temporary" : "permanent" };
  return (
    <SidenavRoot
      {...navDocking}
      {...rest}
      open={!isSmallScreen || !miniSidenav}
      hideBackdrop={false}
      ownerState={{ transparentSidenav, miniSidenav }}
      {...(isSmallScreen && {
        onClick: () => setMiniSidenav(dispatch, !miniSidenav),
      })}
      sx={{
        ".MuiPaper-root": {
            display: "flex"
          }
      }}
    >
      <SoftBox pt={3} pb={3} px={2} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftBox
            component={NavLink}
            to={adminOrHomeRoute()}
            display="flex"
            alignItems="center"
          >
            <SoftProfile
              src={brand}
              name={brandName}
              size="sm"
              fullCover
              sx={{
                marginRight: "0.5rem",
                borderImage: "linear-gradient(310deg, #ced4da, #ebeff4)) 30",
                borderWidth: "3px",
                borderStyle: "solid",
                borderRadius: "4px",
              }}
            />
            <SoftBox
              width="100%"
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <SoftTypography
                component="h4"
                variant="button"
                color="white"
                textAlign="left"
                sx={{ fontWeight: 700, fontSize: "16px" }}
              >
                {brandName}
              </SoftTypography>
              <SoftTypography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  fontSize: "0.9rem",
                  maxWidth: "170px",
                  fontWeight: 500,
                }}
                className="text-left truncate text-ellipsis"
              >
                {subheader}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {headerMenu}
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <List>{renderRoutes}</List>
      </SoftBox>
      <HelpCenter />
      <SoftBox py={2} px={2}>
        {profile}
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
