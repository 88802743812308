import React from "react";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const AppSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    sx={{ width: "52px", height: "32px" }}
  />
))(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#299E47 !important",
    "&:hover": {
      backgroundColor: alpha(
        "#299E47 !important",
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#299E47 !important",
    borderColor: "#299E47 !important",
  },
  "& .MuiSwitch-thumb": {
    height: "24px",
    width: "24px",
  },
  "& .MuiButtonBase-root.MuiSwitch-switchBase": {
    left: "2px",
    top: 0,
  },
}));

export default AppSwitch;
