import React from 'react'
import { useStyles } from "./loginStyles";

const SocialsLogin = ({ src, text, colour, background }) => {
    const classes = useStyles();
    return (
        <div className={classes.socialLoginButton} style={{ background: `${background}`, color: `${colour}` }}>
            <div className={classes.socialLogo}>
                <img src={src} alt='' />
            </div>
            <div className={classes.socialText}>{text}</div>
        </div>
    )
}

export default SocialsLogin