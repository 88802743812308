import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import LoadingScreen from "../LoadingScreen";
import { GET_ACTIVE_CARD, GET_ALL_CARDS } from "../../api/payment";
import AddNewCardDialog from "./AddNewCardDialog";

// Stripe Card Icons
import AmexCardIcon from "../../img/stripe_card_icons/stripe_amex.svg";
import DinersCardIcon from "../../img/stripe_card_icons/stripe_diners.svg";
import DiscoverCardIcon from "../../img/stripe_card_icons/stripe_discover.svg";
import JcbCardIcon from "../../img/stripe_card_icons/stripe_jcb.svg";
import MasterCardIcon from "../../img/stripe_card_icons/stripe_mastercard.svg";
import UnionpayCardIcon from "../../img/stripe_card_icons/stripe_unionpay.svg";
import UnknownCardIcon from "../../img/stripe_card_icons/stripe_unknown.svg";
import VisaCardIcon from "../../img/stripe_card_icons/stripe_visa.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCardIcon = {
  amex: AmexCardIcon,
  diners: DinersCardIcon,
  discover: DiscoverCardIcon,
  jcb: JcbCardIcon,
  // mastercard: MasterCardIcon,
  unionpay: UnionpayCardIcon,
  unknown: UnknownCardIcon,
  visa: VisaCardIcon,
};

const PaymentMethod = () => {
  const [activeCard, setActiveCard] = useState({});
  const [newCardDialog, setNewCardDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const openNewCardDialog = () => setNewCardDialog(true);
  const closeNewCardDialog = () => setNewCardDialog(false);

  const fecthAllActiveCards = async () => {
    setLoading(true);
    try {
      const { data } = await GET_ACTIVE_CARD();
      console.log(data);
      if (data) {
        setActiveCard(data);
      }
    } catch (error) {
      console.log("Error fetching cards", error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fecthAllActiveCards();
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        {newCardDialog && (
          <AddNewCardDialog
            newCardDialog={newCardDialog}
            closeNewCardDialog={closeNewCardDialog}
          />
        )}
      </Elements>
      <SoftBox
        sx={{ background: "white", height: "100%", borderRadius: "16px" }}
      >
        <SoftBox sx={{ padding: "10px 20px" }}>
          <SoftTypography>Payment Method</SoftTypography>
        </SoftBox>
        <Divider
          sx={{
            margin: 0,
            backgroundImage:
              "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
          }}
        />
        <SoftBox sx={{ padding: "30px 20px" }}>
          {loading ? (
            <LoadingScreen height="50px" logoHeight="50px" />
          ) : (
            activeCard && (
              <SoftBox
                sx={{
                  background:
                    "linear-gradient(0deg, #F2F4F7, #F2F4F7), linear-gradient(0deg, #EAECF0, #EAECF0)",
                  border: "1px solid #EAECF0",
                  padding: "10px 20px",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <SoftBox display="flex" gap="0.5rem">
                  <SoftBox>
                    <img
                      src={
                        StripeCardIcon[activeCard.brand] ??
                        StripeCardIcon.unknown
                      }
                      alt="Card Icon"
                    />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <SoftBox>
                      <SoftTypography
                        sx={{
                          color: "#2C2C2C",
                          fontWeight: 500,
                          fontSize: "18px",
                          textTransform: "capitalize",
                        }}
                      >
                        {activeCard.brand ?? "Unknown"}
                      </SoftTypography>
                      <SoftTypography
                        sx={{
                          color: "#2C2C2C",
                          fontWeight: 500,
                          fontSize: "17px",
                        }}
                      >
                        **** **** **** {activeCard.last4}
                      </SoftTypography>
                      <SoftTypography
                        sx={{
                          color: "#696969",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Expiry on {activeCard.exp_month}/{activeCard.exp_year}
                      </SoftTypography>
                    </SoftBox>
                    {activeCard.isCustomerDefault && (
                      <SoftBox
                        sx={{
                          background:
                            "linear-gradient(0deg, #E2E4E7, #E2E4E7), linear-gradient(0deg, #DADCDF, #DADCDF)",
                          padding: "0px 15px",
                          height: "35px",
                          lineHeight: "35px",
                          textAlign: "center",
                          borderRadius: "28px",
                        }}
                      >
                        <SoftTypography
                          variant="p"
                          sx={{
                            color: "#696969",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                        >
                          Default
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )
          )}

          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              onClick={openNewCardDialog}
              sx={{
                color: "primary.contrastText",
                textTransform: "none",
                borderRadius: "4px",
              }}
            >
              Replace Credit Card
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default PaymentMethod;
