import { request } from "../helpers/axios.new";

export const PUT_FEEDBACK = (requestData) => {
  return request({
    url: "/feedback",
    method: "PUT",
    headers: { "Content-Type": "multipart/form-data" },
    data: requestData,
  });
};

export const PUT_TEXT_FEEDBACK = (requestData) => {
  return request({
    url: "/feedback/text",
    method: "PUT",
    data: requestData,
  });
};

export const GET_FEEDBACKS = (companyId) => {
  return request({
    url: `/feedback/${companyId}`,
  });
};

export const GET_FEEDBACK_URL = (key) =>
  `${process.env.REACT_APP_LINK_HOST}/api/feedback/item/${key}`;
