import { types } from "./types";

export const loginUser = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload,
});

export const socialLogin = (token, authType = "google") => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  token,
  authType,
});

export const quickLoginUser = (token, userPayload) => ({
  type: types.LOGIN_QUICK_REQUEST,
  token,
  userPayload,
});

export const registerUser = (payload) => ({
  type: types.REGISTER_REQUEST,
  payload,
});

export const updateProfileImage = (payload) => ({
  type: types.PROFILE_PIC_UPLOAD,
  payload,
});

export const refetchUserData = (payload) => ({
  type: types.UPDATE_WIDGETS_REQUEST,
  payload,
});

export const removeError = () => ({
  type: "REMOVE_ERROR",
});

export const updateProfile = (id, payload) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  id,
  payload: {
    ...payload,
    updateMe: true,
  },
});
export const createCompany = (payload) => ({
  type: "CREATE_COMPANY",
  payload,
});

export const setAsAdmin = () => ({
  type: "SET_AS_ADMIN",
});

export const updateUserDetails = (payload) => {
  return {
    type: "UPDATE_USER_DETAILS",
    payload,
  };
};
