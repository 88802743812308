export const REACTION_ICON_TYPE = {
  LIKE: "like",
  SLOW_CLAP: "slow-clap",
  FAVORITE: "favorite"
};

export const REACTION_PROPERTY_TYPE = {
  JOB : "Job",
  COMMENT: "Comment"
}
