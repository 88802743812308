import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid } from "@mui/material";

import { GET_BILLING } from "../../api/billing";
import { UseUsersDictionary } from "../../hooks/company";
import PageHeader from "../../components/PageHeader";
import LoadingScreen from "../../components/LoadingScreen";
import SoftDatePicker from "../../components/SoftDatePicker";
import CurrentPlanSummary from "../../components/Billing/CurrentPlanSummary";
import PaymentMethod from "../../components/Billing/PaymentMethod";
import BillingInvoice from "../../components/Billing/BillingInvoice";
import InfoBar from "../../components/InfoBar";

const Billing = () => {
  return (
    <>
      <PageHeader
        headerText="Billing Page"
        subheaderText="Manage your billings and payments details"
      />
      <InfoBar
        headerText="Welcome to your billing preview"
        subheaderText="Here, you'll find a clear snapshot of your plan, billing details,
            and any updates. Your billing, your way!"
      />
      <Grid
        container
        spacing={3}
        sx={{
          padding: "1rem 1.5rem 1rem 1.5rem",
        }}
      >
        <Grid item lg={6} md={12} sx={{ width: "max-content" }}>
          <CurrentPlanSummary />
        </Grid>
        <Grid item lg={6} md={12}>
          <PaymentMethod />
        </Grid>
        <Grid item md={12}>
          <BillingInvoice />
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;

/*
const [loading, setLoading] = useState(false);
const [month, setMonth] = useState(null);
const [bills, setBills] = useState({});
const [currentBill, setCurrentBill] = useState(null);
const users = UseUsersDictionary();
*/

/*
useEffect(() => {
  // Initialize initial month to last month
  const lastMonth = moment()
    .subtract(1, "month")
    .startOf("month")
    .format("YYYY-MM");
  setMonth(lastMonth);
}, []);

useEffect(() => {
  if (month) {
    console.log('wut', month)
    if (!bills[month]) {
      setLoading(true);
      GET_BILLING(month)
        .then(({ data }) => {
          const billsData = { ...bills };
          const { sendAt, ...info } = data;
          const dateKey = moment(sendAt).format("YYYY-MM");

          billsData[dateKey] = { ...info };
          setCurrentBill(info);
          setBills(billsData);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setCurrentBill(bills[month]);
    }
  }
}, [month]);
*/

/*
<div className="px-4">
  <div className="flex justify-between">
    <h1>{moment(month).format("MMMM YYYY")} Bill</h1>
    <SoftDatePicker
      options={{
        defaultDate: month,
        minDate: "2020-01",
        maxDate: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
        dateFormat: "m/Y",
        showMonths: 1,
      }}
      onChange={(date) => setMonth(moment(date[0]).format("YYYY-MM"))}
    />
  </div>
  {loading ? (
    <LoadingScreen />
  ) : (
    <div className="px-2 py-4">
      {currentBill?.data ? (
        currentBill.data.map((item) => {
          const user = users[item._id];

          return (
            <div key={item._id} className="flex justify-between mt-2">
              <h2>
                {user?.firstName ?? "Unregisted User"} {user?.lastName}
              </h2>

              <h2>
                {Math.round((item.usage * currentBill.totalHours) / 24)}{" "}
                days active
              </h2>
            </div>
          );
        })
      ) : (
        <div className="w-full flex justify-center">
          <h2>No Billing for the Month</h2>
        </div>
      )}
    </div>
  )}
</div>
*/
