import { request } from "../helpers/axios.new";

export const GET_HUB_ITEMS = (workspaceId, userId = null) => {
  if (!userId) {
    return request({
      method: "GET",
      url: `/hub/${workspaceId}`,
    });
  } else {
    return request({
      method: "GET",
      url: `/hub/${workspaceId}?userId=${userId}`,
    });
  }
};

export const GET_POPULAR_ITEMS = (limit = 5) => {
  return request({
    method: "GET",
    url: `/hub/popular?limit=${limit}`,
  });
};

export const GET_URL_METADATA = (url) => {
  return request({
    url: "/hub/url",
    method: "POST",
    data: { url },
  });
};

export const PUT_CUSTOM_LOGO = (imageData) =>
  request({
    method: "PUT",
    url: "/hub/custom-logo",
    data: imageData,
  });

export const PUT_HUB_ITEM = (
  id = null,
  userId,
  groupId,
  url,
  type,
  title,
  logo,
  desc,
  assignedTo,
) => {
  return request({
    url: `/hub/${userId}`,
    method: "PUT",
    data: {
      id, // Null if new item
      groupId,
      url,
      type,
      title,
      logo,
      desc,
      assignedTo,
    },
  });
};

export const UPDATE_HUB_ITEM = ({
  id = null,
  userId,
  groupId,
  url,
  type,
  assignedTo,
}) => {
  return request({
    url: `/hub/${userId}`,
    method: "PUT",
    data: {
      id, // Null if new item
      groupId,
      url,
      type,
      assignedTo,
    },
  });
};

export const DELETE_HUB_ITEM = (id) => {
  return request({
    url: `/hub/${id}`,
    method: "DELETE",
  });
};

export const ENABLE_HUB_ITEM = (hubItemId, isEnabled = true) => {
  return request({
    url: `/hub/enable/${hubItemId}`,
    method: "POST",
    data: { enabled: isEnabled },
  });
};
