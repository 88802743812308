import React from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

import CreateChallengeForm from "../../components/ChallengeArena/CreateChallengeForm/CreateChallengeForm";

const CreateChallengePage = () => {
  return (
    <>
      <SoftBox
        sx={{
          height: { mini: "72px", xs: "58px", md: "60px" },
        }}
      />
      {/* Page Header */}
      <SoftBox
        sx={{
          padding: "1.1rem 1.5rem",
          background: (theme) => theme.palette.primary.main,
          borderRadius: "20px",

          marginY: { mini: "0rem", xxs: "1rem", sm: "1.5rem" },
          marginX: { mini: "1rem", sm: "1.5rem" },
        }}
      >
        <SoftTypography
          variant="h2"
          color="white"
          sx={{ marginBottom: "0.25rem", fontSize: "23px" }}
        >
          Challenge Arena
        </SoftTypography>
        <SoftTypography
          variant="body2"
          sx={{ color: "rgba(255, 255, 255, 0.6)" }}
        >
          Challenge and get rewarded
        </SoftTypography>
      </SoftBox>

      {/* Page Content */}
      <SoftBox
        sx={{
          margin: { mini: "1rem", sm: "1.5rem" },
          background: "white",
          borderRadius: "20px",
        }}
      >
        <SoftBox
          sx={{
            padding: "1.1rem 1.5rem",
            margin: { mini: "1rem", sm: "1.5rem" },
            textAlign: "center",
          }}
        >
          <SoftTypography
            variant="h2"
            // sx={{ marginBottom: "0.25rem", color: "#051923" }}
            sx={{ fontSize: "32px" }}
          >
            Create A Challenge
          </SoftTypography>
          <SoftTypography
            variant="body1"
            // sx={{ color: "#6C757D", fontWeight: 400 }}
          >
            Create a challenge that will inspire Your team
          </SoftTypography>
        </SoftBox>

        <CreateChallengeForm />
      </SoftBox>
    </>
  );
};

export default CreateChallengePage;
