import React, { useEffect, useRef, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Grid, useTheme, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import SoftSelect from "../../../../SoftSelect";
import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";
import SoftButton from "../../../../SoftButton";
import SoftInput from "../../../../SoftInput";
import { ReactComponent as RocketSvg } from "../../../../../img/customkpi-rocket.svg";
import { CustomDisplayMetricSchema } from "../../../../../form-validations";
import useIntegratedMetricsData from "../../../../../pages/AccountSettings/AccountSettingsV2/hooks/useIntegratedMetricsData";
import { updateWorkspaceKpi } from "../../../../../redux/kpi/actions";
import { KPI_TYPES } from "../../../../../constants/kpi";
import { UseSelectedWorkspace } from "../../../../../hooks/company";

const DisplayMetricDialog = ({ metric, closeMetricDialog, openDialog }) => {
  const [loading, setLoading] = useState(false);

  const { _id: groupId } = UseSelectedWorkspace();
  const { filteredApps, isLoading } = useIntegratedMetricsData(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const validationOptions = {
    resolver: yupResolver(CustomDisplayMetricSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      dataSource: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    trigger,
    watch,
  } = useForm(validationOptions);

  const handleRegister = async (formData) => {
    const { data } = formData;
    delete formData.data;

    const kpiData = {
      target: 0,
      pointScale: 0,
      direction: 1,
      metricType: KPI_TYPES[2],
      groupId,
      ...formData,
      ...data,
    };

    dispatch(
      updateWorkspaceKpi({
        ...kpiData,
      }),
    );

    const isZapier = kpiData.integrationMeta;

    if (isZapier) {
      navigate(`/zapier-setup?id=${isZapier.id}`);
    } else {
      setTimeout(() => {
        openDialog();
      }, 1500);
      reset();
      closeMetricDialog();
    }
  };

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
          <SoftBox>
            <SoftTypography
              variant="h4"
              sx={{ color: "#FFF", fontSize: "20px" }}
            >
              Create {metric.metricTitle}
            </SoftTypography>
            <SoftTypography
              variant="caption"
              sx={{ color: "#FFF", fontSize: "12px" }}
            >
              Set daily targets and track real-time performance against
              predefined goals
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              transform: "rotate(6.137deg)",
              alignSelf: "end",
              height: "max-content",
            }}
          >
            <RocketSvg />
          </SoftBox>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeMetricDialog}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <form onSubmit={handleSubmit(handleRegister)}>
        <Grid container spacing={1} className="mt-2">
          <Grid item mini={12} marginBottom={3}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Metric Name
              </SoftTypography>
            </SoftBox>
            <SoftInput
              size="large"
              {...register("name")}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>
          <Grid item mini={12} marginBottom={3}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Select Data Source
              </SoftTypography>
              <SoftTypography
                variant="button"
                sx={{ color: "#98A2B3", fontWeight: 400 }}
              >
                This is where the system will retrieve the relevant data to
                display on your dashboard
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              options={filteredApps}
              {...register("dataSource", { required: true })}
              error={!!errors?.dataSource}
              helperText={errors?.dataSource?.message}
              isLoading={isLoading}
              onChange={(option) => {
                setValue("dataSource", option.value);
                setValue("data", option);
              }}
              placeholder="Data Source"
              onBlur={() => {}}
              styles={{
                color: "white !important",
                height: "fit-content !important",
                textTransform: "capitalize !important",
                placeholder: {
                  color: "white !important",
                  opacity: "0.7",
                },
                input: {
                  color: "white !important",
                  opacity: "0.7",
                },
                singleValue: {
                  color: "white !important",
                  opacity: "0.7",
                },
              }}
            />
          </Grid>
          <Grid item mini={12}>
            <SoftTypography
              component="p"
              sx={{
                textTransform: "none",
                color: "#FFF",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              Unlock valuable insights with Display Metrics! Connect your data
              sources to visualize key performance indicators effortlessly
            </SoftTypography>
            <Divider
              sx={{
                backgroundColor: "#FFF",
              }}
            />
          </Grid>
          <Grid item mini={12} marginBottom={3}>
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "0.75rem",
              }}
            >
              <SoftButton
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
                onClick={closeMetricDialog}
              >
                Cancel
              </SoftButton>
              <SoftButton
                color="primary"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "4px",
                  color: "#E9ECEF",
                }}
                disabled={loading}
                type="submit"
              >
                Submit{" "}
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    className="ml-2"
                  />
                )}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </form>

    </>
  );
};

export default DisplayMetricDialog;
