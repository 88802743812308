export const initState = {
  messages: [],
  hasMore: false,
  currentPage: 1,
};

export const types = {
  GET_ALL_MESSAGES: "GET_ALL_MESSAGES",
  GET_ALL_MESSAGES_SUCCESS: "GET_ALL_MESSAGES_SUCCESS",
  GET_ALL_MESSAGES_FAILED: "GET_ALL_MESSAGES_FAILED",
  ADD_MESSAGE: "ADD_MESSAGE",
};
