import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// MUI/SoftUI
import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

// Components
import SetupFormInputField from "../layout/SetupFormInputField";

const SetupProgressiveForm = ({ metric }) => {
  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#D0D5DD",
    lineHeight: "20.83px"
  };

  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue("pointScale", metric?.defaultPointScale ?? 0);
  }, [setValue, metric]);

  return (
    <SoftBox sx={{
      padding: "0 32px",
      "& p": { ...fontStyles }
    }}>
      <SetupFormInputField
        label="Set daily Target"
        placeholder="Set a target from 0 - 10,000 "
        name="target"
        isFakeTooltip
        handleRegister={register}
        errors={errors}
      />

      <SetupFormInputField
        label="Set a point Value"
        placeholder="Set a point Value"
        name="pointScale"
        isFakeTooltip
        handleRegister={register}
        errors={errors}
      />

      {/** Footer text */}
      <SoftBox
        sx={{
          width: "80%",
          margin: "53px 0"
        }}
      >
        <SoftTypography
          variant="body1"
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "12px !important",
            lineHeight: "15.62px !important",
            color: "#D0D5DD",
          }}
        >
          The progressive metrics and the anchor metrics will enable you to allocate and setup a target for your players.
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default SetupProgressiveForm;
