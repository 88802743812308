import React from "react";

const FeedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 15.1667H5.99998C2.37998 15.1667 0.833313 13.62 0.833313 10V6.00001C0.833313 2.38001 2.37998 0.833344 5.99998 0.833344H9.99998C13.62 0.833344 15.1666 2.38001 15.1666 6.00001V10C15.1666 13.62 13.62 15.1667 9.99998 15.1667ZM5.99998 1.83334C2.92665 1.83334 1.83331 2.92668 1.83331 6.00001V10C1.83331 13.0733 2.92665 14.1667 5.99998 14.1667H9.99998C13.0733 14.1667 14.1666 13.0733 14.1666 10V6.00001C14.1666 2.92668 13.0733 1.83334 9.99998 1.83334H5.99998Z"
        fill="#F9FAFB"
      />
      <path
        d="M14.32 5.23999H1.67999C1.40666 5.23999 1.17999 5.01332 1.17999 4.73999C1.17999 4.46666 1.39999 4.23999 1.67999 4.23999H14.32C14.5933 4.23999 14.82 4.46666 14.82 4.73999C14.82 5.01332 14.6 5.23999 14.32 5.23999Z"
        fill="#F9FAFB"
      />
      <path
        d="M5.67999 5.14668C5.40666 5.14668 5.17999 4.92001 5.17999 4.64668V1.40668C5.17999 1.13334 5.40666 0.906677 5.67999 0.906677C5.95333 0.906677 6.17999 1.13334 6.17999 1.40668V4.64668C6.17999 4.92001 5.95333 5.14668 5.67999 5.14668Z"
        fill="#F9FAFB"
      />
      <path
        d="M10.32 4.84668C10.0467 4.84668 9.82001 4.62001 9.82001 4.34668V1.40668C9.82001 1.13334 10.0467 0.906677 10.32 0.906677C10.5933 0.906677 10.82 1.13334 10.82 1.40668V4.34668C10.82 4.62668 10.6 4.84668 10.32 4.84668Z"
        fill="#F9FAFB"
      />
      <path
        d="M7.39333 12.08C7.15333 12.08 6.92667 12.02 6.72 11.9067C6.26667 11.64 6 11.1067 6 10.4333V8.83333C6 8.16 6.26667 7.62 6.72667 7.35333C7.18667 7.08666 7.78667 7.12666 8.36667 7.46666L9.75333 8.26666C10.3333 8.59999 10.6733 9.1 10.6733 9.63333C10.6733 10.1667 10.3333 10.6667 9.74667 11L8.36 11.8C8.04 11.9867 7.70667 12.08 7.39333 12.08ZM7.4 8.18C7.33333 8.18 7.27333 8.19333 7.22667 8.22C7.08667 8.3 7 8.52666 7 8.83333V10.4333C7 10.7333 7.08667 10.96 7.22667 11.0467C7.36667 11.1267 7.60667 11.0867 7.86667 10.9333L9.25333 10.1333C9.51333 9.98 9.67333 9.79333 9.67333 9.63333C9.67333 9.47333 9.52 9.28666 9.25333 9.13333L7.86667 8.33333C7.69333 8.23333 7.52667 8.18 7.4 8.18Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default FeedIcon;
