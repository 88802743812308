import React from 'react';
import { ReactComponent as Rank1st } from "../../img/arena/1st.svg";
import { ReactComponent as Rank2nd } from "../../img/arena/2nd.svg";
import { ReactComponent as Rank3rd } from "../../img/arena/3rd.svg";

import GoldCrown from "../../img/arena/GoldCrown.svg";
import SilverCrown from "../../img/arena/SilverCrown.svg";
import BronzeCrown from "../../img/arena/BronzeCrown.svg";

const PODIUMS = [
  {
    height: "70%",
    stroke: "rgba(255, 252, 0, 0.60)",
    border: "rgba(255, 252, 0, 0.60)",
    background:
      "radial-gradient(50% 100% at 50% 0.00%, rgba(255, 252, 0, 0.30) 0%, rgba(255, 252, 0, 0.00) 100%), rgba(255, 252, 0, 0.50)",
    shadow:
      "-4px -4px 8px 0px rgba(255, 252, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 252, 0, 0.10) inset, 0px 0px 32px 4px rgba(255, 252, 0, 0.40)",

    orderWord: { asset: <Rank1st />, width: "45%" },
    crown: GoldCrown,
  },
  {
    height: "50%",
    stroke: "rgba(110, 202, 220, 0.80)",
    border: "rgba(110, 202, 220, 0.80)",
    background:
      "radial-gradient(50% 100% at 50% 0.00%, rgba(110, 202, 220, 0.20) 0%, rgba(110, 202, 220, 0.00) 100%), rgba(110, 202, 220, 0.50)",
    shadow:
      "-4px -4px 8px 0px rgba(110, 202, 220, 0.20) inset, 0px 0px 24px 0px rgba(110, 202, 220, 0.10) inset, 0px 0px 32px 4px rgba(110, 202, 220, 0.40);",

    orderWord: { asset: <Rank2nd />, width: "40%" },
    crown: SilverCrown,
  },
  {
    height: "45%",
    stroke: "rgba(255, 153, 0, 0.80)",
    border: "rgba(255, 153, 0, 0.80)",
    background:
      "radial-gradient(50% 100% at 50% 0.00%, rgba(255, 153, 0, 0.20) 0%, rgba(255, 153, 0, 0.00) 100%), rgba(255, 153, 0, 0.50)",
    shadow:
      "-4px -4px 8px 0px rgba(255, 153, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 153, 0, 0.10) inset, 0px 0px 32px 4px rgba(255, 153, 0, 0.40)",
    orderWord: { asset: <Rank3rd />, width: "35%" },
    crown: BronzeCrown,
  },
];

export default PODIUMS;