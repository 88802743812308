import { createTheme, darken, lighten } from "@mui/material/styles";
import chroma from "chroma-js";

import templateTheme from "./assets/theme";
import rgba from "./assets/theme/functions/rgba";
import "./assets/fonts.css";

export const PRIMARY_DEFAULT = "#EE238A";
export const SECONDARY_DEFAULT = "#922790";

export const BRIGHTNESS_MIN = 0.2;
export const BRIGHTNESS_MAX = 0.7;

export const generateTheme = (primaryColor) => {
  const pColor = chroma.valid(primaryColor) ? primaryColor : PRIMARY_DEFAULT;
  const pColorLight = lighten(pColor, 0.35);
  const pColorLightest = lighten(pColor, 0.9);
  const pColorDark = darken(pColor, 0.1);
  const sColor = darken(pColor, 0.3);
  const tColor = darken(pColor, 0.15);
  const plusColor = "#299E47";
  const minusColor = "#F50D00";

  return createTheme({
    ...templateTheme,
    typography: {
      fontFamily: [
        "DM Sans",
        "Inter",
        // Adding fallback fonts here if needed
        "Arial",
        "sans-serif",
      ].join(","),
      size: {
        xxs: "0.65rem",
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.25rem",
        xl: "1.5rem",
        xxl: "1.875rem",
        xxxl: "2.25rem",
        xxxxl: "3rem",
      },
    },
    palette: {
      ...templateTheme.palette,
      primary: {
        ...templateTheme.palette.primary,
        main: pColor,
        focus: lighten(pColor, 0.35),
        lighter: lighten(pColor, 0.5),
        lightest: lighten(pColor, 0.8),
        darker: darken(pColor, 0.4),
        darkest: darken(pColor, 0.7),
      },
      secondary: {
        ...templateTheme.palette.secondary,
        main: sColor,
        focus: lighten(sColor, 0.35),
        lighter: lighten(sColor, 0.5),
        lightest: lighten(sColor, 0.8),
        darker: darken(sColor, 0.4),
        darkest: darken(sColor, 0.7),
      },
      tertiary: {
        main: tColor,
        focus: lighten(tColor, 0.35),
        light: lighten(sColor, 0.2),
        lighter: lighten(sColor, 0.5),
        lightest: lighten(sColor, 0.8),
        dark: darken(tColor, 0.1),
        darker: darken(tColor, 0.4),
        darkest: darken(tColor, 0.7),
        contrastText: "#FFFFFF",
      },
      positive: {
        main: plusColor,
        focus: lighten(plusColor, 0.35),
        light: lighten(plusColor, 0.2),
        lighter: lighten(plusColor, 0.5),
        lightest: lighten(plusColor, 0.7),
        dark: darken(plusColor, 0.1),
      },
      negative: {
        main: minusColor,
        focus: lighten(minusColor, 0.35),
        light: lighten(minusColor, 0.2),
        lighter: lighten(minusColor, 0.5),
        lightest: lighten(minusColor, 0.7),
        dark: darken(minusColor, 0.1),
      },
      gradient: `linear-gradient(180deg, ${pColor}, ${sColor})`,
      radialGradient: `radial-gradient(59.05% 147.38% at 50% -36.49%, #FFFFFF 0%, ${pColorLight} 50%, ${pColor} 75%, ${sColor} 100%)`,
      gradients: {
        ...templateTheme.palette.gradients,
        primary: {
          main: darken(pColor, 0.35),
          state: lighten(pColor, 0.2),
        },
        secondary: {
          main: darken(sColor, 0.35),
          state: lighten(sColor, 0.2),
        },
        tertiary: {
          main: darken(tColor, 0.35),
          state: lighten(tColor, 0.2),
        },
      },
      dividerGradients: {
        light: `linear-gradient(to right, ${rgba(pColorLight, 0)}, ${rgba(
          pColorLight,
          0.5,
        )}, ${rgba(pColorLight, 0)}) !important`,
        dark: `linear-gradient(to right, ${rgba(pColorDark, 0)}, ${rgba(
          pColorDark,
          0.5,
        )}, ${rgba(pColorDark, 0)}) !important`,
      },
      kpiGradient: `linear-gradient(90deg, ${pColorLightest} 0%, rgba(28, 132, 207, 0.00) 100%)`,
      contrastThreshold: 4,
    },
  });
};
