import React from "react";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import FormSection from "../../../../components/ChallengeArena/CreateChallengeForm/Layout/FormSection"

const AccountSection = ({
  title = "Section Title",
  subtitle = "Subtitle",
  children
}) => {
  return (
    <FormSection>
      <SoftBox sx={{ marginBottom: "24px" }}>
        <SoftTypography variant="h3">
          {title}
        </SoftTypography>

        <SoftTypography variant="h5">
          {subtitle}
        </SoftTypography>
      </SoftBox>

      {children}
    </FormSection>
  );
};

export default AccountSection;
