import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftInput from "../../../../components/SoftInput";
import { PersonalGoalSchema } from "../../../../form-validations";
import { UseUserId } from "../../../../hooks/auth";
import { refetchUserData } from "../../../../redux/auth/actions";
import {
  ADD_WIDGETS_TO_LIST,
  GET_WIDGETS_LIST,
} from "../../../../api/userWidgets";

const PersonalGoalEdit = ({ widget, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const userId = UseUserId();
  const dispatch = useDispatch();

  const validationOpt = {
    resolver: yupResolver(PersonalGoalSchema),
    shouldUnregister: false,
    defaultValues: {
      personalGoal: "",
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(validationOpt);

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const fetchUserWidgets = async () => {
    setLoading(true);
    try {
      const { data } = await GET_WIDGETS_LIST({ key: widget.key, userId });
      if (data) {
        setValue("personalGoal", data[0].userInput);
      }
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserWidgets();
  }, []);

  const triggerSave = async (formData) => {
    const { personalGoal } = formData;

    setLoading(true);
    const payload = {
      userId,
      key: widget.key,
      category: widget.category,
      title: widget.title,
      userInput: personalGoal,
    };

    try {
      await ADD_WIDGETS_TO_LIST(payload);
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        toast.success("Widget updated successfully");
        closeModal();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(triggerSave)}>
      <SoftInput
        {...register("personalGoal")}
        placeholder={`Enter ${widget?.title.toLowerCase()}`}
        error={!!errors?.personalGoal}
        helperText={errors?.personalGoal?.message}
        mb="30px"
        className="text-black mb-3"
        sx={{
          border: "1px solid rgba(233, 236, 239, 0.10)",
          backgroundColor: "rgba(255, 255, 255, 0.10) !important",
          color: "#ffffffb3 !important",
          "& .MuiInputBase-input::placeholder": {
            color: "white !important",
            opacity: "0.7",
          },
          "&.Mui-focused": {
            borderColor: primaryColor,
            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
          },
        }}
      />
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.10)",
            color: "#CED4DA",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          type="submit"
          sx={{
            width: "100%",
            textTransform: "none",
            borderRadius: "4px",
            color: "#E9ECEF",
          }}
          disabled={loading}
          loading={loading}
        >
          Update
        </SoftButton>
      </SoftBox>
    </form>
  );
};

export default PersonalGoalEdit;
