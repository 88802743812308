import React, { useMemo } from "react";
import { Dialog, IconButton, useTheme, Divider } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Controller, useForm } from "react-hook-form";

import SoftBox from "../SoftBox";
import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import { StyledPaper } from "../../pages/ManualEntry/manualEntryStyles";

const AddLinkModal = ({
  isOpen,
  kpiId,
  kpiLinksData,
  onModalClose,
  triggerAddLink,
}) => {
  const theme = useTheme();

  const {
    primary: { main: primaryColor, focus: brighterPrimaryColor },
    secondary: { main: secondaryColor },
    tertiary: { main: tertiaryColor },
  } = theme.palette;

  const handleClose = () => {
    onModalClose({
      kpiLinks: null,
    });
  };

  const CustomKpiSchema = Yup.object().shape({
    text: Yup.string().required("Field is required"),
    link: Yup.string().required("Field is required"),
  });

  const validationOptions = {
    resolver: yupResolver(CustomKpiSchema),
    shouldUnregister: false,
    defaultValues: {
      text: "",
      type: "web-address",
      link: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm(validationOptions);

  const handleRegister = async (formData) => {
    if (formData.type === "e-mail") {
      formData = {
        ...formData,
        link: `mailto:${formData.link}`,
      };
    } else if (!formData.link.includes("https://")) {
      formData = {
        ...formData,
        link: `https://${formData.link}`,
      };
    }

    const payload = {};
    if (kpiLinksData && kpiLinksData?.kpiLinks?.length) {
      payload.links = [{ ...formData }, ...kpiLinksData.kpiLinks];
    } else {
      payload.links = [{ ...formData }];
    }

    await triggerAddLink(kpiId, payload);
    handleClose();
  };

  return (
    <Dialog
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={isOpen}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1rem 1.5rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "600px",
          borderWidth: "1px",
          borderColor: "#FFF",
        },
      }}
    >
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "10px" }}
      >
        <SoftTypography
          variant="h4"
          color="white"
          sx={{ fontSize: "20px", fontWeight: 500 }}
        >
          Add Link
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      <form onSubmit={handleSubmit(handleRegister)}>
        <SoftTypography
          component="p"
          mb="5px"
          sx={{
            color: "#6C757D",
            fontSize: "15px",
          }}
        >
          Text to display
        </SoftTypography>
        <SoftInput
          {...register("text")}
          error={!!errors?.text}
          helperText={errors?.text?.message}
          sx={{
            backgroundColor: "transparent !important",
            color: "#ffffffb3 !important",
            "& .MuiInputBase-input::placeholder": {
              color: "white !important",
              opacity: "0.7",
            },
            "&.Mui-focused": {
              borderColor: primaryColor,
              boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
            },
          }}
        />

        <SoftBox display="flex" alignItems="center" mb="20px" mt="20px">
          <FormLabel
            sx={{
              color: "#6C757D",
              fontSize: "15px",
              marginRight: "20px",
              top: "0 !important",
            }}
          >
            Link to:
          </FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="type"
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                onChange={(e) => setValue("type", e.target.value)}
              >
                <FormControlLabel
                  value="web-address"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"),linear-gradient(310deg, ${secondaryColor}, ${tertiaryColor})`,
                          borderColor: `${brighterPrimaryColor} !important`,
                        },
                      }}
                    />
                  }
                  label="Web address"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "white !important",
                    },
                  }}
                />
                <FormControlLabel
                  value="e-mail"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"),linear-gradient(310deg, ${secondaryColor}, ${tertiaryColor})`,
                          borderColor: `${brighterPrimaryColor} !important`,
                        },
                      }}
                    />
                  }
                  label="E-mail"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "white !important",
                    },
                  }}
                />
              </RadioGroup>
            )}
          />
        </SoftBox>

        <SoftInput
          {...register("link")}
          error={!!errors?.link}
          helperText={errors?.link?.message}
          sx={{
            backgroundColor: "transparent !important",
            color: "#ffffffb3 !important",
            "& .MuiInputBase-input::placeholder": {
              color: "white !important",
              opacity: "0.7",
            },
            "&.Mui-focused": {
              borderColor: primaryColor,
              boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
            },
          }}
        />
        <SoftTypography
          component="p"
          sx={{
            fontSize: "14px",
            color: "#6C757D",
            lineHeight: "17px",
            width: "530px",
            margin: "20px 0px 30px 0px",
          }}
        >
          Not sure what to put in the box? First, find the page on the web that
          you want to link to. (A search engine might be useful.) Then, copy the
          web address from the box in your browser's address bar, and paste it
          into the box above
        </SoftTypography>
        <Divider
          sx={{
            backgroundColor: "#FFF",
          }}
        />
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <SoftButton
            variant="outlined"
            sx={{
              width: "100%",
              textTransform: "none",
              color: "#6C757D",
              "&:hover": {
                textDecoration: "underline",
                color: "#6C757D",
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            sx={{ textTransform: "none", width: "100%" }}
            color="primary"
            type="submit"
          >
            Add
          </SoftButton>
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default AddLinkModal;
