const adminFooterRoutes = [
  {
    name: "Account Settings",
    key: "settings",
    route: "/account-settings",
  },
  {
    name: "My Billings",
    key: "billings",
    route: "/settings/billing",
  },
  {
    name: "Log Out",
    key: "signout",
    route: "/signout",
  },
];

export default adminFooterRoutes;
