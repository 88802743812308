import { request } from "../helpers/axios.new";
import { useMutation, useQuery } from "@tanstack/react-query";

export const FETCH_GROUPS = (companyId) => {
  return request({
    url: `/group/${companyId}`,
    method: "GET",
  });
};

export const FETCH_GROUP = (groupId) =>
  useQuery(["fetchGroup"], () => request({ url: `/group/${groupId}` }));

export const FETCH_GROUP_TYPE = (typeId) =>
  useQuery(["fetchGroupType", typeId], () => request({ url: `/group/type/${typeId}` }), {staleTime: Infinity });

export const FETCH_GROUP_TYPES = () =>
  useQuery(["fetchGroupTypes"], () => request({ url: `/group/types` }), {staleTime: Infinity });

export const ADD_GROUP = () =>
  useMutation(({ companyId, data }) =>
    request({
      method: "POST",
      url: `/group/${companyId}`,
      data,
    }),
  );

export const ADD_SUB_GROUP = () =>
  useMutation(({ companyId, data }) =>
    request({
      method: "POST",
      url: `/group/sub/${companyId}`,
      data,
    }),
  );

export const UPDATE_GROUP = () =>
  useMutation(({ groupId, data }) =>
    request({
      method: "PUT",
      url: `/group/${groupId}`,
      data,
    }),
  );

export const UPDATE_SUB_GROUP = () =>
  useMutation(({ groupId, data }) =>
    request({
      method: "PUT",
      url: `/group/sub/${groupId}`,
      data,
    }),
  );

export const ADD_GROUP_MEMBER = () =>
  useMutation(({ groupId, data }) =>
    request({
      method: "POST",
      url: `/group/${groupId}/member`,
      data,
    }),
  );

export const REMOVE_GROUP_MEMBER = () =>
  useMutation(({ userId }) =>
    request({
      method: "DELETE",
      url: `/group/member/${userId}`,
    }),
  );

export const DELETE_GROUP = () =>
  useMutation(({ groupId }) =>
    request({
      method: "DELETE",
      url: `/group/${groupId}`,
    }),
  );


export const PUT_WORKSPACE_IMAGE = (imageData) =>
  request({
    method: "PUT",
    url: `/group/image-upload`,
    data: imageData,
  });

export const SUBMIT_AWARD_PRICE = (body) => {
    return request({
      url: '/group/submit-award-price',
      method: "PATCH",
      data: body,
    });
  };
  
export const GET_AWARD = (groupId) => {
  return request({
    url: `/group/get-award/${groupId}`,
  });
};

export const GET_BACKGROUND = (groupId) => {
  return request({
    url: `/group/get-background/${groupId}`,
  });
};

export const SUBMIT_BGIMAGE = (body) => {
  return request({
    url: '/group/submit-background',
    method: "PATCH",
    data: body,
  });
};