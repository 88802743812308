import React, { useState } from "react";
import { Dialog } from "@mui/material";
import WorskpacePanel from "../Workspaces/WorskpacePanel";
import GridViewIcon from "@mui/icons-material/GridView";

import {
  ADD_GROUP,
  DELETE_GROUP,
  FETCH_GROUP_TYPES,
  UPDATE_GROUP,
} from "../../api/group";
import { UseCompany, UseSelectedWorkspace } from "../../hooks/company";
import toast from "react-hot-toast";
import {
  getWorkspaces,
  setSelectedWorkspace,
} from "../../redux/workspace/actions";
import { useDispatch } from "react-redux";
import UpgradePopUp from "../ChallengeArenaPopUp/UpgradePopUp";
import ERRORS from "../../constants/errorStatus";
import SoftDialog from "../SoftConfirmationDialog";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";

export const WORKSPACE_ACTION = {
  CREATE: "create",
  UPDATE: "update",
};

const WorskpaceDialog = ({ open, setOpen, action, group, onEnd }) => {
  const [dialog, setDialog] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const dispatch = useDispatch();
  const { _id: companyId } = UseCompany();
  const { _id: currentWorkspaceId } = UseSelectedWorkspace();
  const { data: types, isLoading: isLoadingTypes } = FETCH_GROUP_TYPES();
  const { mutateAsync: addGroup, isLoading: isLoadingAdd } = ADD_GROUP();
  const { mutateAsync: udpateGroup, isLoading: isLoadingUpdate } =
    UPDATE_GROUP();
  const { mutateAsync: deleteGroup, isLoading: isDeleting } = DELETE_GROUP();

  const handleClose = () => {
    setOpen(false);
    // onEnd?.();
  };

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);

  // data = {name, typeId, image};
  const handleSave = async (data) => {
    try {
      let result;
      switch (action) {
        case WORKSPACE_ACTION.CREATE:
          result = await addGroup({
            companyId,
            data,
          });
          openDialog();
          break;
        case WORKSPACE_ACTION.UPDATE:
          result = await udpateGroup({
            groupId: group._id,
            data,
          });
          dispatch(getWorkspaces({ selectedWorkspace: result.data }));
          break;
        default:
      }

      setWorkspace(result.data);
      handleClose();
    } catch (err) {
      if (err?.response?.status === ERRORS.UPGRADE_REQUIRED) {
        setShowUpgrade(true);
      }
      toast.error(err?.response?.data?.error?.message);
    }
  };

  const handleDelete = async () => {
    const hasSubworkspaces = group.subworkspaces?.length;
    if (hasSubworkspaces) {
      // Do not proceed with delete if workspace has subs
      toast.error(
        "Unable to delete this workspace. Remove its subworkspaces first.",
      );
      return;
    }

    const deleting = toast.loading("Deleting workspace ...");
    try {
      await deleteGroup({ groupId: group._id });
      if (currentWorkspaceId === group._id) {
        // Reset selected workspace if it was the deleted one
        dispatch(setSelectedWorkspace({ selectedWorkspace: null }));
      }
      dispatch(getWorkspaces()); // Refresh workspaces list
      handleClose();
      toast.success("Workspace deleted", { id: deleting });
    } catch (err) {
      toast.error(err?.response?.data?.error?.message, { id: deleting });
    }
  };

  const exploreHandler = () => {
    dispatch(getWorkspaces());
    closeDialog();
  };

  const switchToNewWorkspaceHandler = () => {
    dispatch(getWorkspaces({ selectedWorkspace: workspace }));
    closeDialog();
  };

  return (
    <>
      {dialog && (
        <SoftDialog
          open={dialog}
          onClose={closeDialog}
          title="Workspace Created"
          type="success"
          icon={<GridViewIcon fontSize="large" sx={{ color: "#299E47" }} />}
          description={
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              mt="25px"
              mb="35px"
            >
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                Congratulations!
              </SoftTypography>
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                You have successfully created a new workspace. You can now
                organize your projects and collaborate with your team more
                efficiently
              </SoftTypography>
            </SoftBox>
          }
          footer={
            <SoftBox display="flex" gap="10px" justifyContent="center">
              <SoftButton
                size="small"
                variant="contained"
                color="outlined"
                onClick={exploreHandler}
                sx={{
                  textTransform: "none",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
              >
                Keep Exploring
              </SoftButton>
              <SoftButton
                size="small"
                color="white"
                onClick={switchToNewWorkspaceHandler}
                sx={{
                  width: "max-content",
                  borderRadius: "4px",
                  textTransform: "none",
                  color: "#344054",
                }}
              >
                Go to New Workspace
              </SoftButton>
            </SoftBox>
          }
        />
      )}
      <Dialog
        open={open}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1.5rem 1.7rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "700px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <WorskpacePanel
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          isLoading={
            isLoadingAdd || isLoadingTypes || isLoadingUpdate || isDeleting
          }
          types={types}
          group={group}
        />
      </Dialog>
      <UpgradePopUp
        showUpgrade={showUpgrade}
        closeUpgrade={() => setShowUpgrade(false)}
      />
    </>
  );
};

export default WorskpaceDialog;
