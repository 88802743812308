export const initState = {
	summary: [],
	arrangedUsers: [],
	isLoading: false,
	isDeleting: false,
	error: "",
	socket: null,
	tableDetails: null,
	pageNumber: 20,
	viewAsUser: false
};

export const types = {

	// get socket
	GET_SOCKET_SUCCESS: "GET_SOCKET_SUCCESS",
	GET_SOCKET_FAILED: "GET_SOCKET_FAILED",

	// get page number
	GET_PAGE_NUMBER: "GET_PAGE_NUMBER",
	TOGGLE_VIEW_AS_USER: "TOGGLE_VIEW_AS_USER"
};
