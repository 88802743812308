import React from "react";

const HubIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24667 15.1667H3.08C1.54667 15.1667 0.833336 14.4533 0.833336 12.92V9.75333C0.833336 8.21333 1.54667 7.5 3.08 7.5H4.66667C4.94 7.5 5.16667 7.72667 5.16667 8V9.58C5.16667 10.5533 5.44667 10.8333 6.41334 10.8333H8C8.27334 10.8333 8.5 11.06 8.5 11.3333V12.92C8.5 14.4533 7.78667 15.1667 6.24667 15.1667ZM3.08 8.5C2.11334 8.5 1.83334 8.78 1.83334 9.75333V12.92C1.83334 13.8867 2.11334 14.1667 3.08 14.1667H6.24667C7.22 14.1667 7.5 13.8867 7.5 12.92V11.8333H6.41334C4.88 11.8333 4.16667 11.12 4.16667 9.58V8.5H3.08Z"
        fill="#F2F4F7"
      />
      <path
        d="M9.58 11.8333H6.41333C4.88 11.8333 4.16666 11.12 4.16666 9.58V6.41334C4.16666 4.88001 4.88 4.16667 6.41333 4.16667H8C8.27333 4.16667 8.5 4.39334 8.5 4.66667V6.24667C8.5 7.22 8.78 7.5 9.74666 7.5H11.3333C11.6067 7.5 11.8333 7.72667 11.8333 8V9.58C11.8333 11.12 11.12 11.8333 9.58 11.8333ZM6.41333 5.16667C5.44666 5.16667 5.16666 5.44667 5.16666 6.41334V9.58C5.16666 10.5533 5.44666 10.8333 6.41333 10.8333H9.58C10.5533 10.8333 10.8333 10.5533 10.8333 9.58V8.5H9.74666C8.21333 8.5 7.5 7.78667 7.5 6.24667V5.16667H6.41333Z"
        fill="#F2F4F7"
      />
      <path
        d="M12.9133 8.49999H9.74667C8.21333 8.49999 7.5 7.78666 7.5 6.24666V3.07999C7.5 1.54666 8.21333 0.833328 9.74667 0.833328H12.9133C14.4533 0.833328 15.1667 1.54666 15.1667 3.07999V6.24666C15.1667 7.78666 14.4533 8.49999 12.9133 8.49999ZM9.74667 1.83333C8.78 1.83333 8.5 2.11333 8.5 3.07999V6.24666C8.5 7.21999 8.78 7.49999 9.74667 7.49999H12.9133C13.8867 7.49999 14.1667 7.21999 14.1667 6.24666V3.07999C14.1667 2.11333 13.8867 1.83333 12.9133 1.83333H9.74667Z"
        fill="#F2F4F7"
      />
    </svg>
  );
};

export default HubIcon;
