import React, { useEffect, useRef, useState, useMemo } from "react";
import { Card, Dialog } from "@mui/material";
import { Line, Bar } from "react-chartjs-2";

// Style Sheet File
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import configs from "./configs";
import ExpandableView from "./ExpandableView";
import Grid from "@mui/material/Grid";
import { border, borderRadius, fontWeight, padding } from "@mui/system";
import rankWidgetIcon from "../../img/rankWidgetIcon.svg";
import levelWidgetIcon from "../../img/levelWidgetIcon.svg";

function PerformBox({ title, amount, showGraph = true }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const [modal, setModal] = useState(false);
  const { data, options } = chartData;

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const kpiChartData = {
      labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: title,
          color: "dark",
          data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
        },
      ],
    };

    const chartDatasets = kpiChartData.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "#ffffff",
      fill: false,
      maxBarThickness: 6,
    }));

    setChartData(configs(kpiChartData.labels, chartDatasets));
  }, []);

  return (
    <>
      <Dialog
        open={modal}
        onClose={closeModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <ExpandableView title={title} amount={amount} closeModal={closeModal} />
      </Dialog>
      <Card
        onClick={() => setModal(true)}
        sx={{
          marginTop: "1rem",
          boxShadow: "0px 0px 0px 0px",
          background: {
            mini: "linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
            md: "#FCFCFD",
          },
          height: "7rem",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <SoftBox lineHeight={1} sx={{ padding: { mini: 1, xxs: 2 } }}>
        <Grid container>
          <Grid item md={6} lg={3} style={{textAlign: 'center'}}>
            <SoftBox sx={{background: '#FFFFFF', padding: "10%", border: '0.5px solid #F2F4F7', borderRadius: '6px'}}>
              {title === 'Ranking' && <img src={rankWidgetIcon} alt='rankIcon' />}
              {title !== 'Ranking' && <img src={levelWidgetIcon} alt='levelWidgetIcon' />}
            </SoftBox>
          </Grid>
          <Grid item md={6} lg={9} sx={{paddingLeft: '1rem', display: 'flex', alignItems: 'center'}}>
            <SoftBox>
              <SoftTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{
                  color: "#98A2B3",
                  fontSize: "14px",
                }}
              >
                {title}
              </SoftTypography>
              <SoftTypography
                variant="h1"
                fontWeight="regular"
                sx={{
                  color: "#000",
                  whiteSpace: "nowrap",
                  width: { mini: "110px", md: "290px" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: { mini: "14px", xxs: "14px", md: "14px" },
                  fontWeight: '500'
                }}
              >
                {amount}&nbsp;
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        </SoftBox>
        {/* {useMemo(
          () => (
            <SoftBox
              ref={chartRef}
              sx={{
                height: "7rem",
                position: "absolute",
                bottom: { mini: "-35px", xxs: "-45px", md: "-20px" },
                right: { mini: "5px", xxs: "10px", md: 0 },
                width: "60%",
              }}
            > */}
              {/* eslint-disable */}
              {/* {showGraph ? (
                title === "Level" ? (
                  <Bar data={data} options={options} />
                ) : (
                  <Line data={data} options={options} />
                )
              ) : null}
            </SoftBox>
          ),
          [chartData],
        )} */}
      </Card>
    </>
  );
}

export default PerformBox;
