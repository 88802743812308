import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FORM_ELEMENTS from "./formElementOptions";

const DROPPABLE_SELECTED = "droppable_selected";
const DROPPABLE_OPTIONS = "droppable_options";

const KpiSubmissionFormBuilder = ({ errors, inputs, updateInputs }) => {
  const [itemIdx, setItemIdx] = useState(0);
  const selectedElements = useMemo(() => {
    return inputs;
  }, [inputs]);
  const [formElements, setFormElements] = useState([...FORM_ELEMENTS]);

  const getFormOption = (draggable, element) => {
    return (
      <SoftBox
        ref={draggable?.innerRef}
        {...draggable?.draggableProps}
        {...draggable?.dragHandleProps}
        borderRadius="md"
        shadow="sm"
        sx={{
          cursor: element.disabled ? "stop" : "auto",
          border: "1px solid rgba(33, 82, 255, 0.25)",
          height: "2.25rem",
          width: "100%",
          px: 2,
          py: 2,
          background: element.disabled
            ? "rgba(108, 117, 125, 0.35)"
            : "transparent",
        }}
      >
        <SoftBox
          className="w-full h-full border-black-500 flex justify-between items-center"
          disabled={element.disabled}
        >
          <SoftTypography
            variant="button"
            disabled={true}
            sx={{ color: "#CED4DA" }}
          >
            {element.label}
          </SoftTypography>
          {element.justOne ? (
            <SoftTypography
              variant="caption"
              disabled={true}
              sx={{ color: "#CED4DA" }}
            >
              (Max: 1)
            </SoftTypography>
          ) : null}
          {element.icon}
        </SoftBox>
      </SoftBox>
    );
  };

  const setFormElementOptions = (currentItems) => {
    // If limited item has already been selected, remove the item from the options by disabling it
    const limitedItems = currentItems
      .filter((item) => item.justOne)
      .map((item) => item.type);
    setFormElements(
      formElements.map((el) => ({
        ...el,
        ...(limitedItems.includes(el.id)
          ? { disabled: true }
          : { disabled: false }),
      })),
    );
  };

  useEffect(() => {
    if (errors && errors.inputs) {
      const filledUpForm = inputs.map((el, idx) => {
        const inputError =
          errors.inputs[idx]?.type?.message ||
          errors.inputs[idx]?.value?.message;
        return {
          ...el,
          ...(inputError && { error: inputError }),
        };
      });
      updateInputs?.(filledUpForm);
    }
  }, [errors]);

  const updateValue = (id, value) => {
    const updatedElements = [...inputs];
    const elIdx = inputs.findIndex((el) => el.id === id);
    updatedElements[elIdx].value = value;
    updateInputs(updatedElements);
  };

  const removeSelectedElement = (id) => {
    const updatedElements = [...inputs];
    const elIdx = inputs.findIndex((el) => el.id === id);
    const isLimited = inputs[elIdx].justOne;
    updatedElements.splice(elIdx, 1);
    updateInputs(updatedElements);

    if (isLimited) {
      // Set form elements options to re-enable previously locked limited form option
      setFormElementOptions(formElements);
    }
  };

  const getFormElement = (draggable, element, isDragging) => {
    return (
      <div className="flex flex-col gap-0">
        <SoftBox
          ref={draggable.innerRef}
          {...draggable.draggableProps}
          {...draggable.dragHandleProps}
          className="pl-3 pr-1 py-2 w-full flex gap-0"
          sx={{
            borderWidth: "0 0 0 0.25rem",
            borderColor: isDragging ? "primary.main" : "rgba(0,0,0,0)",
            background: isDragging
              ? "linear-gradient(310deg, rgba(12, 91, 219, 0.10) 0%, rgba(168, 184, 216, 0.10) 100%)"
              : "transparent",
            "&:hover": {
              background:
                "linear-gradient(310deg, rgba(12, 91, 219, 0.10) 0%, rgba(168, 184, 216, 0.10) 100%)",
              borderColor: "primary.main",
            },
          }}
        >
          <SoftBox
            className="grow border-1 rounded-md"
            sx={{
              borderColor: element.error
                ? "negative.lighter"
                : "rgb(203 213 225, 0.5)", // element.error? red: gray
            }}
          >
            <SoftInput
              size="medium"
              placeholder={`Enter ${element.label?.toLowerCase()} input label`}
              multiline
              icon={{
                component: element.icon,
                direction: "right",
              }}
              value={element.value}
              onChange={(e) => updateValue(element.id, e.target.value)}
              error={!!element.error}
              className="bg-transparent w-full !border-none"
              sx={{
                border: "0.65px solid rgba(108, 117, 125, 0.25)",
                borderColor: element.error
                  ? "rgba(0,0,0,0)"
                  : "rgba(108, 117, 125, 0.25)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: element.error ? "rgba(0,0,0,0)" : "primary.main",
                },
              }}
            />
          </SoftBox>
          <SoftButton
            size="medium"
            iconOnly
            circular
            color="error"
            variant="text"
            onClick={() => removeSelectedElement(element.id)}
          >
            <CloseOutlinedIcon
              color="red"
              sx={{ width: "1.75rem", height: "auto" }}
            />
          </SoftButton>
        </SoftBox>
        {element.error && (
          <p className="text-danger text-xs font-semibold px-4">
            {element.error}
          </p>
        )}
      </div>
    );
  };

  // Handle the element drop event
  const onDragEnd = (result) => {
    if (!result.destination) return;

    // Draggable was dropped on output form builder
    if (result.destination.droppableId === DROPPABLE_SELECTED) {
      // Draggable was taken from form element options: add selected item to output form
      if (result.source.droppableId === DROPPABLE_OPTIONS) {
        const draggedElement = formElements.find(
          (element) => element.id === result.draggableId,
        );
        const elemId = `${draggedElement.id}-${itemIdx + 1}`;
        const newSelected = [...selectedElements];
        newSelected.splice(result.destination.index, 0, {
          ...draggedElement,
          id: elemId,
          type: draggedElement.id,
          selected: true,
          value: "",
        });

        setItemIdx((prev) => prev + 1);
        updateInputs(newSelected);
        setFormElementOptions(newSelected);
      } else {
        // Draggable was taken from form output form: revise the order of the elements in the output form
        const revisedSelectedEls = [...selectedElements];
        const elIdx = revisedSelectedEls.findIndex(
          (el) => el.id === result.draggableId,
        );
        revisedSelectedEls.splice(elIdx, 1); // Remove selected from original position
        revisedSelectedEls.splice(
          result.destination.index,
          0,
          selectedElements[elIdx],
        ); // Then attach it to new position
        updateInputs(revisedSelectedEls);
      }
    } else if (result.source.droppableId === DROPPABLE_OPTIONS) {
      // Draggable was taken from and dropped on element options; not much to do here except re-order the form elements just for cosmetic reasons
      const revisedFormElements = [...formElements];
      const elIdx = revisedFormElements.findIndex(
        (el) => el.id === result.draggableId,
      );
      revisedFormElements.splice(elIdx, 1); // Remove selected from original position
      revisedFormElements.splice(
        result.destination.index,
        0,
        formElements[elIdx],
      ); // Then attach it to new position
      setFormElements(revisedFormElements);
    }
  };

  return (
    <div className="w-full h-auto flex flex-col justify-center">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="elements-list rounded-xl h-full w-full flex gap-0">
          <Droppable
            droppableId={DROPPABLE_OPTIONS}
            type="ELEMENT"
            renderClone={(provided, _, rubric) => {
              const element = formElements[rubric.source.index];
              return getFormOption(provided, element);
            }}
          >
            {(provided, snapshot) => (
              <SoftBox
                shadow="md"
                className="flex flex-col gap-2 px-3 py-3"
                ref={provided.innerRef}
                sx={{
                  border: "2px solid rgba(233, 236, 239, 0.25)",
                  background: "rgba(17, 33, 47, 0.60)",
                  width: "220px",
                  height: "420px",
                  borderRadius: "0px",
                }}
                {...provided.droppableProps}
              >
                <SoftTypography
                  variant="body2"
                  color="white"
                  sx={{
                    px: 2,
                    textAlign: "center",
                    fontSize: "0.75rem",
                    mb: 2,
                  }}
                >
                  Drag and drop elements to the right to build your form
                </SoftTypography>
                {formElements.map((element, index) => {
                  const shouldRenderClone =
                    element.id === snapshot.draggingFromThisWith;

                  return shouldRenderClone ? (
                    // Render element clone without draggable properties
                    getFormOption(null, element)
                  ) : (
                    // Render draggable element
                    <Draggable
                      key={element.id}
                      draggableId={element.id}
                      index={index}
                      isDragDisabled={element.disabled}
                    >
                      {(draggable) => getFormOption(draggable, element)}
                    </Draggable>
                  );
                })}
                {errors && errors.inputs && (
                  <p className="text-danger text-xs font-semibold">
                    {errors.inputs.message}
                  </p>
                )}
                {provided.placeholder}
              </SoftBox>
            )}
          </Droppable>
          <Droppable
            droppableId={DROPPABLE_SELECTED}
            type="ELEMENT"
            renderClone={(provided, _, rubric) => {
              const element = selectedElements[rubric.source.index];
              return getFormElement(provided, element, true);
            }}
          >
            {(provided) => (
              <SoftBox
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-col gap-1 p-4 grow"
              >
                {selectedElements.map((element, index) => (
                  <Draggable
                    key={element.id}
                    draggableId={element.id}
                    index={index}
                  >
                    {(draggable) => getFormElement(draggable, element)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </SoftBox>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default KpiSubmissionFormBuilder;
