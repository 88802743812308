import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  Skeleton,
  Slide,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Style Sheet File
import { manualEntryStyles } from "./manualEntryStyles";
import "./manualEntryStyles.css";

// Imported Images and Icon
import {
  UseGroupKpis,
  UseKpiLoading,
  UseKpiScoresTable,
} from "../../hooks/kpi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { getPreviousKpis } from "../../redux/kpi/actions";
import { useDispatch } from "react-redux";
import { UseSelectedWorkspace } from "../../hooks/company";
import SoftBox from "../../components/SoftBox";
import ManualEntryTable from "../../components/ManualEntry/Table";
import PageHeader from "../../components/PageHeader";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";
import { ReactComponent as BellIcon } from "../../img/scorekeeper-bell.svg";
import { ReactComponent as FilterIcon } from "../../img/scorekeeper-filter.svg";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import SearchBar from "../../components/SearchBar";
import SendKudos from "../../components/ManualEntry/SendKudos";
import Filter from "../../components/ManualEntry/Filter";
import ScoreSettings from "../../components/ManualEntry/ScoreSettings";

export default function ManualEntry() {
  const dispatch = useDispatch();
  const classes = manualEntryStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertBoxText, setAlertBoxText] = useState("");
  const [monthRef, setMonthRef] = React.useState(null);
  const groupKpis = UseGroupKpis();
  const isLoading = UseKpiLoading();
  const usersKpis = UseKpiScoresTable(monthRef);
  const currentWorkspace = UseSelectedWorkspace();
  const [filterIndex, setFilterIndex] = useState(0);
  const theme = useTheme();
  const {
    primary: { main: primaryColor, light: brighterPrimaryColor },
  } = theme.palette;

  const handleApply = (index) => {
    setFilterIndex(index);
  };

  useEffect(() => {
    if (alertOpen) {
      setTimeout(() => {
        setAlertOpen(false);
      }, 2000);
    }
  }, [alertOpen]);

  React.useEffect(() => {
    if (monthRef) {
      dispatch(getPreviousKpis(monthRef));
    }
  }, [monthRef, dispatch, currentWorkspace]);

  const topUser = usersKpis.reduce((maxObject, currentObject) => {
    if (currentObject.total > maxObject.total) {
      return currentObject;
    } else {
      return maxObject;
    }
  }, usersKpis[0]);

  return (
    <>
      <PageHeader
        headerText="Scorekeeper"
        subheaderText={`Manage Player Scores and Performance in the ${currentWorkspace?.name} workspace`}
      />

     {/*  <SoftBox
        sx={{
          marginX: { mini: "1rem", md: "1.5rem" },
          marginY: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SoftBox>
          <SoftTypography variant="h5" color="black">
            Technicians
          </SoftTypography>
          <SoftTypography variant="body2" color="black">
            The score of players in the technicians workspace
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" gap="0.75rem" alignItems="center">
          <BellIcon />
          <SendKudos topUser={topUser}>
            <SoftButton
              variant="contained"
              color="white"
              size="small"
              sx={{ textTransform: "none", padding: "13px 19px" }}
            >
              Click Here
            </SoftButton>
          </SendKudos>
        </SoftBox>
      </SoftBox> */}

      <SoftBox sx={{ margin: { mini: "1rem", md: "1.5rem" } }}>
        <Grid container>
          {alertOpen && (
            <Box className={classes.successAlert}>
              <Slide direction="left" in={alertOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {alertBoxText}
                </Alert>
              </Slide>
            </Box>
          )}

          <Grid item mini={12}>
            <SoftBox
              sx={{
                mt: 2,
                overflowX: "auto",
                background: "white",
                borderRadius: "1rem",
              }}
            >
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "1rem",
                }}
              >
                <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
                  <SoftBox>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        inputFormat="MM/YYYY"
                        label=""
                        minDate={new Date("2020-01-01")}
                        maxDate={new Date()}
                        value={monthRef}
                        onChange={(newValue) => {
                          setMonthRef(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Last 30 days"
                            {...params}
                            helperText={null}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {/* <SearchBar
                      placeholder="Last 30 days"
                      icon={{
                        component: (
                          <EventOutlinedIcon sx={{ fill: "#6C757D" }} />
                        ),
                        direction: "right",
                      }}
                      sx={{
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                          outline: 0,
                        },
                      }}
                    /> */}
                  </SoftBox>
                  <SoftBox>
                    <Filter handleApply={handleApply}>
                      <SoftButton
                        variant="outlined"
                        sx={{
                          color: "#495057",
                          borderColor: "#E9ECEF",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          padding: "10px 29px",
                          boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <FilterIcon sx={{ marginRight: "0.5rem" }} /> Filter
                      </SoftButton>
                    </Filter>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <Divider />
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={210} />
              ) : (
                <ManualEntryTable
                  groupKpis={groupKpis}
                  usersKpis={usersKpis}
                  monthRef={monthRef}
                  filterIndex={filterIndex}
                />
              )}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}
