import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import SoftButton from "../../components/SoftButton";

import { GET_IMAGE } from "../../helpers/images";
import { PUT_JOB_IMAGE, PUT_NEW_TAGS } from "../../api/jobs";
import { useDispatch } from "react-redux";
import { getJob } from "../../redux/jobs/actions";
import "./feedStyles.css";
import ImageCropper from "./ImageCropper";
import toast from "react-hot-toast";

const DEFAULT_TITLES = ["BEFORE", "AFTER", ""];
export default function FeedGallery({
  jobId,
  images,
  open,
  exit,
  setGallery,
  gallery,
}) {
  const [titles, setTitles] = useState([]);
  const [imageIdx, setImageIdx] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [tagEdited, setTagEdited] = useState(false);
  const imageInputRef = useRef();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const adddGalleryImage = (imageKey, index) => {
    if (index < gallery.length) {
      const newGallery = [...gallery];
      newGallery[index] = GET_IMAGE(imageKey);
      setGallery(newGallery);
    } else {
      setGallery([...gallery, GET_IMAGE(imageKey)]);
    }
    setImageUpdated(true);
  };

  const addTag = (tag, index) => {
    const newTags = DEFAULT_TITLES.map((item, idx) =>
      titles[idx] ? titles[idx] : item,
    );
    newTags[index] = tag;
    setTitles(newTags);
    setTagEdited(true);
  };

  useEffect(() => {
    if (images && images.length) {
      setGallery(images.map((imgData) => GET_IMAGE(imgData.src)));
      setTitles(images.map((imgData) => imgData.tag));
    }
  }, [images]);

  useEffect(() => {
    return () => {
      setIsUploading(false);
    };
  }, []);

  const handleInputChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const imgSrc = e.target.files[0];
    if (imgSrc) {
      setImageSrc(URL.createObjectURL(imgSrc));
      setOpenDialog(true);
    }
  };

  const onSaveCroppedImage = async (croppedImageFile) => {
    if (!croppedImageFile) return;

    const formData = new FormData();
    setIsUploading(true);
    formData.append("image", croppedImageFile);
    formData.append("index", imageIdx);
    formData.append("tag", titles[imageIdx] || DEFAULT_TITLES[imageIdx]);
    PUT_JOB_IMAGE(jobId, formData, imageIdx)
      .then(({ data }) => {
        adddGalleryImage(data.key, JSON.parse(data.index));
        setIsUploading(false);
        toast.success("Upload Success");
      })
      .catch((err) => {
        console.log("Job image upload error", err.message);
        setIsUploading(false);
      });

    setOpenDialog(false);
    imageInputRef.current.value = null;
  };

  const handleUpload = (index) => {
    imageInputRef.current.value = null;
    setImageIdx(index);
    imageInputRef.current.click();
  };

  const handleSaveThenClose = () => {
    if (tagEdited) {
      PUT_NEW_TAGS(jobId, titles).then(() => {
        dispatch(getJob(jobId));
      });
    } else if (imageUpdated) {
      dispatch(getJob(jobId));
    }
    setTagEdited(false);
    setImageUpdated(false);
    exit();
  };

  return (
    <div>
      <Dialog
        open={open}
        sx={{
          margin: "auto",
          width: { xs: "100vw", md: "80vw" },
          height: "80vh",
        }}
        PaperProps={{
          className: "w-full h-full !max-w-full !rounded-3xl pt-8 pb-8",
        }}
        onClose={exit}
      >
        <Typography variant="h6" align="center" className="w-full">
          Upload Image To Gallery
        </Typography>

        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ height: { xs: "auto", md: "80%" } }}
          className="w-5/6 m-auto flex items-center gap-8 md:!mb-[0rem]"
        >
          {DEFAULT_TITLES.map((item, i) => {
            const childComp =
              gallery && gallery[i] ? (
                <img
                  key={`image-${item}`}
                  src={gallery[i]}
                  alt="job"
                  onClick={() => handleUpload(i)}
                  className="object-cover h-full w-full"
                />
              ) : (
                <Stack className="flex items-center">
                  <IconButton
                    key={`image-${item}`}
                    aria-label="upload image"
                    color="primary"
                    disabled={i > gallery.length}
                    onClick={() => handleUpload(i)}
                  >
                    <AddCircleRoundedIcon
                      sx={{ width: "5rem", height: "5rem" }}
                    />
                  </IconButton>
                  <p className="text-[#6D85A4] font-semibold text-base md:text-lg xl:text-xl">
                    Upload
                  </p>
                  <p className="text-[#6D85A4] font-semibold text-base md:text-lg xl:text-xl">
                    Image
                  </p>
                </Stack>
              );

            return (
              <Box key={`img${item}`} className="w-5/6 h-full mb-8">
                <Paper
                  elevation={2}
                  className="w-full h-5/6 !rounded-2xl overflow-hidden flex items-center justify-center mb-4"
                >
                  {i === imageIdx && isUploading ? (
                    <CircularProgress />
                  ) : (
                    childComp
                  )}
                </Paper>
                <Box className="w-full flex items-center gap-x-[1rem]">
                  <Typography
                    variant="title"
                    className="text-base md:text-lg xl:text-xl font-semibold"
                  >
                    Title:
                  </Typography>
                  <input
                    type="text"
                    defaultValue={titles[i] ? titles[i] : item}
                    onChange={(e) => addTag(e.target.value, i)}
                    className="border-[2px] border-solid border-[#0000001f] px-[0.75rem] py-[0.5rem] text-lg md:text-xl xl:text-2xl font-semibold w-full rounded-md"
                  />
                </Box>
              </Box>
            );
          })}

          <input
            ref={imageInputRef}
            onInput={handleInputChange}
            accept="image/*"
            type="file"
            hidden
          />
          <Box sx={{ height: { xs: "2rem" } }} />
          <ImageCropper
            imgSrc={imageSrc}
            open={openDialog}
            setOpen={setOpenDialog}
            onSaveCroppedImage={onSaveCroppedImage}
          />
        </Stack>

        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          gap={2}
          className="mx-auto flex items-center"
        >
          <SoftButton
            className="w-[200px] py-[0.75rem] font-medium text-sm md:text-base"
            onClick={exit}
            color="primary"
            variant="outlined"
          >
            CANCEL
          </SoftButton>
          <SoftButton
            variant="contained"
            className="w-[200px] py-[0.75rem] text-[#fff] font-medium text-sm md:text-base"
            onClick={handleSaveThenClose}
            color="primary"
          >
            SAVE
          </SoftButton>
        </Stack>
      </Dialog>
    </div>
  );
}
