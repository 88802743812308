import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createCustomWorkspaceKpi } from "../../../redux/kpi/actions";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomKpiSchema } from "../../../form-validations";

import { KPI_KINDS, KPI_DIRECTIONS } from "../../../constants/kpi";
import { UseSelectedWorkspace } from "../../../hooks/company";

import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import SoftInput from "../../SoftInput";

import { debounce } from "lodash";
import {
  Dialog,
  Divider,
  useTheme,
  Grid,
  CircularProgress,
  Slider,
} from "@mui/material";

import { ReactComponent as RocketSvg } from "../../../img/customkpi-rocket.svg";
import { ReactComponent as TargetSvg } from "../../../img/customkpi-target.svg";
import { ReactComponent as RulerSvg } from "../../../img/customkpi-ruler.svg";
import { ReactComponent as DiceSvg } from "../../../img/customkpi-dice.svg";

import KpiSubmissionFormBuilder from "../../FormBuilder/KpiSubmission";
import popNotification from "../../Modals/popNotification";

import { TYPE_OPTIONS } from "../form/typeOptions";
import { KIND_OPTIONS } from "../form/kindOptions";
import { EFFECT_OPTIONS } from "../form/effectOptions";
import { FORM_OPTIONS } from "../form/formOptions";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const CreateCustomKpi = ({ openCustomKpi, handleCloseCustomKpi }) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const [processing, setProcessing] = useState(false);
  // const []
  const [inputs, setInputs] = useState([]);
  const [sliderPoints, setSliderPoints] = useState(0);
  const [sliderTarget, setSliderTarget] = useState(0);
  const { _id: groupId } = UseSelectedWorkspace();
  const dispatch = useDispatch();

  // Stepper
  const [activeStep, setActiveStep] = useState(0);

  const currentValidationSchema = CustomKpiSchema[activeStep];
  const validationOptions = {
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      kind: KPI_KINDS[0],
      direction: KPI_DIRECTIONS[0],
      target: 0,
      pointScale: 0,
      type: 0,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    trigger,
  } = useForm(validationOptions);

  const [kindIdx, setKindIdx] = useState(0);
  const handleKindSelect = (idx) => {
    setKindIdx(idx);
    setValue("kind", KPI_KINDS[idx]);
  };

  // Custom KPI Effect Radio Buttons States
  const [effectIdx, setEffectIdx] = useState(0);
  const [typeIdx, setTypeIdx] = useState(0);
  const [formIdx, setFormIdx] = useState(0);

  const handleTypeSelect = (idx) => {
    setTypeIdx(idx);
    setValue("type", idx);
    if (typeIdx === 1) {
      // Anchor Metric Type
      const valueKind = 2; // Percentage
      setKindIdx(valueKind);
      setValue("kind", KPI_KINDS[valueKind]);
    } else {
      // Progressive Type Metric
      const valueKind = 0; // Monetary
      setKindIdx(valueKind);
      setValue("kind", KPI_KINDS[valueKind]); // Monetary; can be switched to Item type
    }
  };

  const handleEffectSelect = (idx) => {
    setEffectIdx(idx);
    setValue("direction", KPI_DIRECTIONS[idx]);
  };

  const handleInputs = (values) => {
    // Update both react-hook-form inputs and state inputs;
    // Relying on react-hook-form inputs alone does not trigger re-render when values change
    if (values && values.length) {
      setValue("inputs", [...values]);
      setInputs([...values]);
    } else {
      setValue("inputs", []);
      setInputs([]);
    }
  };

  // Valid for one-input only types like one text or one image input (not complex form)
  const handleFormSelect = (idx) => {
    setFormIdx(idx);
    handleInputs(FORM_OPTIONS[idx].initValue);
  };

  const handleNext = debounce(async () => {
    const isStepCompleted = await trigger();
    if (isStepCompleted) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, 250);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setProcessing(false);
    handleCloseCustomKpi();
    setTypeIdx(0);
    setKindIdx(0);
    setEffectIdx(0);
    setFormIdx(0);
    setActiveStep(0);
    setSliderTarget(0);
    setSliderPoints(0);
    clearErrors();
    reset();
    setInputs([]);
  };

  const handleRegister = async (formData) => {
    setProcessing(true);

    const { inputs: rawInputs, type, ...data } = formData;
    let requiredInputs = [];

    if (rawInputs && rawInputs.length) {
      requiredInputs = rawInputs.map((el) => {
        // Extract only valid elements; it may have extra values from Form Builder
        const { type: inputType, value, desc } = el;
        return {
          type: inputType,
          value: value.toLowerCase().replace(" ", "_"),
          desc,
        };
      });
    }
    const kpiData = {
      ...data,
      groupId,
      ...(requiredInputs.length && { requiredInputs }),
    };

    dispatch(createCustomWorkspaceKpi(kpiData));
    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Congratulations, Your Custom KPI Is Ready",
          text: `You've successfully created your custom ${data.name.toUpperCase()} KPI.`,
        }),
      1500,
    );
    handleClose();
  };

  return (
    <Dialog
      open={openCustomKpi}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem 0.5rem", md: "1.5rem 1rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
          <SoftBox>
            <SoftTypography variant="h4" sx={{ color: "#E9ECEF" }}>
              Customize a New Metric
            </SoftTypography>
            <SoftTypography variant="caption" sx={{ color: "#E9ECEF" }}>
              Custom metrics for personalized insights and efficiency
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              transform: "rotate(6.137deg)",
              alignSelf: "end",
              height: "max-content",
            }}
          >
            <RocketSvg />
          </SoftBox>
        </SoftBox>

        <SoftButton
          variant="text"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.75rem !important" },
            borderRadius: "50%",
            width: "max-content",
            minWidth: "max-content",
            minHeight: "max-content",
            position: "absolute",
            top: { mini: "1rem", md: "1.5rem" },
            right: { mini: "0.5rem", md: "1rem" },
            padding: 0,
          }}
        >
          <CloseIcon />
        </SoftButton>
      </SoftBox>

      <SoftBox
        display="flex"
        justifyContent="center"
        marginY="1rem"
        alignItems="center"
        gap="0.5rem"
      >
        <SoftBox>
          <SoftBox
            height="7px"
            width="295px"
            sx={{
              background: "rgba(249, 245, 255, 0.10)",
              borderRadius: "3px",
              position: "relative",
            }}
          >
            <SoftBox
              height="7px"
              sx={{
                background: primaryColor,
                borderRadius: "3px",
                width: ["33%", "65%", "100%"][activeStep],
                position: "absolute",
              }}
            />
          </SoftBox>
        </SoftBox>

        <SoftTypography variant="button" sx={{ color: "#CED4DA" }}>
          {activeStep + 1}/3
        </SoftTypography>
      </SoftBox>

      <Divider
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
          "&:not([size])": {
            height: "3px",
          },
        }}
      />

      <form onSubmit={handleSubmit(handleRegister)}>
        <Grid container spacing={1} className="mt-2">
          {activeStep === 0 && (
            <>
              <Grid item mini={12} marginBottom={3}>
                <SoftBox
                  sx={{
                    display: "flex",
                    gap: "0.25rem",
                    marginBottom: "0.25rem",
                  }}
                >
                  <BorderColorOutlinedIcon
                    sx={{ color: "rgba(108, 117, 125, 1)" }}
                  />
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Name Your KPI Metric
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("name")}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>

              <Grid item mini={12} marginBottom={3}>
                <SoftBox
                  sx={{
                    display: "flex",
                    gap: "0.25rem",
                    marginBottom: "0.25rem",
                  }}
                >
                  <AutoFixHighIcon sx={{ color: "rgba(108, 117, 125, 1)" }} />
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Select KPI Metric Type
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    // justifyContent: "space-between",
                    gap: "0.5rem",
                  }}
                >
                  {TYPE_OPTIONS.map((type, index) => (
                    <SoftButton
                      key={type}
                      onClick={() => handleTypeSelect(index)}
                      disabled={type?.disabled}
                      sx={{
                        background: "rgba(255, 255, 255, 0.10)",
                        border:
                          typeIdx === index
                            ? `1px solid ${primaryColor}`
                            : "1px solid rgba(233, 236, 239, 0.10)",
                        borderRadius: "8px",
                        padding: "0.5rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100% !important",
                        textAlign: "start",
                        boxShadow:
                          typeIdx === index
                            ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                            : "none",
                        "&:hover": {
                          borderColor: primaryColor,
                          background: "rgba(255, 255, 255, 0.10)",
                          boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                        },
                        "&:disabled": {
                          backgroundColor: "transparent !important",
                          width: "50%"
                        },
                        "&:focus:not(:hover)": {
                          borderColor: primaryColor,
                          background: "rgba(255, 255, 255, 0.10)",
                          boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                        },
                      }}
                    >
                      <SoftBox
                        display="flex"
                        gap="0.25rem"
                        alignItems="center"
                        width="100%"
                      >
                        <SoftBox height="3rem" width="auto">
                          {type.icon}
                        </SoftBox>

                        <SoftBox className="flex flex-col">
                          <SoftTypography
                            variant="button"
                            sx={{ color: "#CED4DA" }}
                          >
                            {type.name}
                          </SoftTypography>
                          <SoftTypography
                            variant="caption"
                            sx={{ color: "#CED4DA" }}
                          >
                            {type.desc}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftButton>
                  ))}
                </SoftBox>
              </Grid>
            </>
          )}

          {activeStep === 1 && (
            <>
              {/** Progressive Type Inputs - Start */}
              {typeIdx === 0 && (
                <>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                        alignItems: "center",
                      }}
                    >
                      <TargetSvg />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Set Your Daily Target
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      size="large"
                      {...register("target")}
                      error={!!errors?.target}
                      helperText={errors?.target?.message}
                      sx={{
                        border: "1px solid rgba(233, 236, 239, 0.10)",
                        backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                        alignItems: "center",
                      }}
                    >
                      <RulerSvg />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Select Point Scale
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      size="large"
                      {...register("pointScale")}
                      error={!!errors?.pointScale}
                      helperText={errors?.pointScale?.message}
                      sx={{
                        border: "1px solid rgba(233, 236, 239, 0.10)",
                        backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                      }}
                    >
                      <AutoFixHighIcon
                        sx={{ color: "rgba(108, 117, 125, 1)" }}
                      />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Select KPI Value Type
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "0.5rem",
                      }}
                    >
                      {KIND_OPTIONS.map((kind, index) => (
                        <SoftButton
                          key={kind}
                          onClick={() => handleKindSelect(index)}
                          sx={{
                            background: "rgba(255, 255, 255, 0.10)",
                            border:
                              kindIdx === index
                                ? `1px solid ${primaryColor}`
                                : "1px solid rgba(233, 236, 239, 0.10)",
                            borderRadius: "8px",
                            padding: "0.5rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "start",
                            boxShadow:
                              kindIdx === index
                                ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                                : "none",
                            "&:hover": {
                              borderColor: primaryColor,
                              background: "rgba(255, 255, 255, 0.10)",
                              boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                            },

                            "&:focus:not(:hover)": {
                              borderColor: primaryColor,
                              background: "rgba(255, 255, 255, 0.10)",
                              boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                            },
                          }}
                        >
                          <SoftBox
                            display="flex"
                            gap="0.15rem"
                            alignItems="center"
                            width="100%"
                            color="#CED4DA"
                          >
                            {kind.icon}
                            <SoftTypography
                              variant="button"
                              sx={{ color: "inherit" }}
                            >
                              {kind.name}
                            </SoftTypography>
                          </SoftBox>
                          <SoftTypography
                            variant="caption"
                            sx={{ color: "#CED4DA", marginLeft: "0.25rem" }}
                          >
                            {kind.desc}
                          </SoftTypography>
                        </SoftButton>
                      ))}
                    </SoftBox>
                    {errors?.kind?.message && (
                      <p className="text-danger text-xs font-semibold">
                        {errors.kind.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                      }}
                    >
                      <DiceSvg sx={{ color: "rgba(108, 117, 125, 1)" }} />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Effect Type
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "0.5rem",
                      }}
                      {...register("direction")}
                    >
                      {EFFECT_OPTIONS.map((effect, index) => (
                        <SoftButton
                          onClick={() => handleEffectSelect(index)}
                          sx={{
                            background: "rgba(255, 255, 255, 0.10)",
                            border:
                              effectIdx === index
                                ? `1px solid ${primaryColor}`
                                : "1px solid rgba(233, 236, 239, 0.10)",
                            borderRadius: "8px",
                            padding: "0.5rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "start",
                            boxShadow:
                              effectIdx === index
                                ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                                : "none",
                            "&:hover": {
                              borderColor: primaryColor,
                              background: "rgba(255, 255, 255, 0.10)",
                              boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                            },

                            "&:focus:not(:hover)": {
                              borderColor: primaryColor,
                              background: "rgba(255, 255, 255, 0.10)",
                              boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                            },
                          }}
                        >
                          <SoftBox
                            display="flex"
                            gap="0.15rem"
                            alignItems="center"
                            marginBottom="0.25rem"
                            width="100%"
                            color="#CED4DA"
                          >
                            {effect.icon}
                            <SoftTypography
                              variant="button"
                              sx={{ color: "inherit" }}
                            >
                              {effect.name}
                            </SoftTypography>
                          </SoftBox>
                          <SoftTypography
                            variant="caption"
                            sx={{ color: "#CED4DA", marginLeft: "0.25rem" }}
                          >
                            {effect.desc}
                          </SoftTypography>
                        </SoftButton>
                      ))}
                    </SoftBox>
                    {errors?.direction?.message && (
                      <p className="text-danger text-xs font-semibold">
                        {errors.direction.message}
                      </p>
                    )}
                  </Grid>
                </>
              )}
              {/** Progressive Type Inputs - End */}

              {/** Anchor Type Inputs - Start */}
              {typeIdx === 1 && (
                <>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                        alignItems: "center",
                      }}
                    >
                      <TargetSvg />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Set Your Monthly Target
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      p={2}
                      pt={3}
                      borderRadius="md"
                      sx={{ border: "2px solid rgba(233, 236, 239, 0.1)" }}
                    >
                      <Slider
                        value={sliderTarget}
                        size="large"
                        error={!!errors?.target}
                        getAriaValueText={(value) => `${value}%`}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => `${value}%`}
                        min={0}
                        max={100}
                        step={1}
                        onChange={(e, value) => {
                          setSliderTarget(value);
                          setValue("target", value / 100);
                        }}
                      />
                    </SoftBox>
                    {errors?.target?.message && (
                      <p className="text-danger text-xs font-semibold">
                        {errors.target.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item mini={12} marginBottom={3}>
                    <SoftBox
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        marginBottom: "0.25rem",
                        alignItems: "center",
                      }}
                    >
                      <RulerSvg />
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Select Point Scale
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      p={2}
                      pt={3}
                      borderRadius="md"
                      sx={{ border: "2px solid rgba(233, 236, 239, 0.1)" }}
                    >
                      <Slider
                        value={sliderPoints}
                        size="large"
                        error={!!errors?.pointScale}
                        valueLabelDisplay="on"
                        min={0}
                        max={100}
                        step={1}
                        onChange={(e, value) => {
                          setSliderPoints(value);
                          setValue("pointScale", value * 100);
                        }}
                      />
                    </SoftBox>
                    {errors?.pointScale?.message && (
                      <p className="text-danger text-xs font-semibold">
                        {errors.pointScale.message}
                      </p>
                    )}
                  </Grid>
                </>
              )}
              {/** Anchor Type Inputs - End */}
            </>
          )}

          {activeStep === 2 && (
            <Grid item mini={12} marginBottom={3}>
              <SoftBox
                sx={{
                  display: "flex",
                  gap: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                <DiceSvg sx={{ color: "rgba(108, 117, 125, 1)" }} />
                <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                  Select Submission Type
                </SoftTypography>
              </SoftBox>

              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                }}
              >
                {FORM_OPTIONS.map((formType, index) => (
                  <SoftButton
                    onClick={() => handleFormSelect(index)}
                    sx={{
                      width: "33%",
                      background: "rgba(255, 255, 255, 0.10)",
                      border:
                        formIdx === index
                          ? `1px solid ${primaryColor}`
                          : "1px solid rgba(233, 236, 239, 0.10)",
                      borderRadius: "8px",
                      padding: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "start",
                      boxShadow:
                        formIdx === index
                          ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                          : "none",
                      "&:hover": {
                        borderColor: primaryColor,
                        background: "rgba(255, 255, 255, 0.10)",
                        boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                      },

                      "&:focus:not(:hover)": {
                        borderColor: primaryColor,
                        background: "rgba(255, 255, 255, 0.10)",
                        boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                      },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      gap="0.15rem"
                      alignItems="center"
                      marginBottom="0.25rem"
                      width="100%"
                    >
                      {formType.icon}
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#CED4DA" }}
                      >
                        {formType.name}
                      </SoftTypography>
                    </SoftBox>
                    <SoftTypography
                      variant="caption"
                      sx={{ color: "#CED4DA", marginLeft: "0.25rem" }}
                    >
                      {formType.desc}
                    </SoftTypography>
                  </SoftButton>
                ))}
              </SoftBox>

              {/** KPI Submission Form Builder */}
              <SoftBox
                borderRadius="lg"
                className="w-full h-auto my-4 overflow-hidden"
                sx={{
                  background:
                    "linear-gradient(0deg, #414143, #414143), linear-gradient(0deg, rgba(108, 117, 125, 0.4), rgba(108, 117, 125, 0.4))",
                }}
              >
                <SoftTypography
                  variant="h5"
                  color="white"
                  className="px-4 pt-4"
                >
                  {formIdx === 2 && "Build Your"} KPI Submission Form
                </SoftTypography>
                <Divider
                  sx={{
                    backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
                    "&:not([size])": {
                      height: "3px",
                    },
                    marginBottom: 0,
                  }}
                />
                {formIdx <= 1 && (
                  <SoftBox className="p-4">
                    <SoftInput
                      size="large"
                      placeholder={`Enter ${
                        formIdx === 0 ? "Text Input" : "Image Upload"
                      } Label`}
                      value={inputs[0]?.value}
                      onChange={(e) => {
                        // Match input format to complex form builder so any approach can be validated in the same way
                        const formInputs = [
                          {
                            value: e.target.value,
                            type: formIdx === 0 ? "text" : "image",
                          },
                        ];
                        handleInputs(formInputs);
                      }}
                      // Display error from the 2 main submission types  (text, image) aside from form submission
                      error={
                        !Array.isArray(errors?.inputs)
                          ? !!errors?.inputs
                          : !!errors?.inputs[0].value
                      }
                      helperText={
                        !Array.isArray(errors?.inputs)
                          ? errors?.inputs?.message
                          : errors?.inputs[0].value?.message
                      }
                      sx={{
                        border: "1px solid rgba(233, 236, 239, 0.10)",
                        backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                    />
                  </SoftBox>
                )}
                {formIdx === 2 && (
                  <KpiSubmissionFormBuilder
                    inputs={inputs}
                    updateInputs={(values) => handleInputs(values)}
                    errors={errors}
                  />
                )}
              </SoftBox>
            </Grid>
          )}
        </Grid>

        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "3px",
            },
          }}
        />

        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "0.75rem",
            margin: "1rem",
          }}
        >
          {activeStep === 0 && (
            <SoftButton
              sx={{
                width: "100%",
                borderRadius: "4px",
                border: "1px solid rgba(233, 236, 239, 0.20)",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                textTransform: "none",
                background: "rgba(255, 255, 255, 0.10)",
                color: "#CED4DA",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.10)",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </SoftButton>
          )}

          {activeStep >= 1 && (
            <SoftButton
              sx={{
                width: "100%",
                borderRadius: "4px",
                border: "1px solid rgba(233, 236, 239, 0.20)",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                textTransform: "none",
                background: "rgba(255, 255, 255, 0.10)",
                color: "#CED4DA",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.10)",
                },
              }}
              onClick={handleBack}
            >
              Back
            </SoftButton>
          )}

          {activeStep <= 1 ? (
            <SoftButton
              color="primary"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "4px",
                color: "#E9ECEF",
              }}
              onClick={handleNext}
            >
              Next
            </SoftButton>
          ) : (
            <SoftButton
              color="primary"
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "4px",
                color: "#E9ECEF",
              }}
              disabled={processing}
              onClick={() => handleSubmit(handleRegister)}
              type="submit"
            >
              {processing ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Save KPI"
              )}
            </SoftButton>
          )}
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default CreateCustomKpi;
