import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const SvgIconWrapper = (IconComponent, width, height) => {
  return (
    <SvgIcon 
      component={IconComponent} 
      inheritViewBox 
      sx={{ width, height }} 
    />
  );
};

export default SvgIconWrapper;
