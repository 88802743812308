import React from "react";
import moment from "moment";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftProfile from "../../ProfilePictures/SoftProfile";

const Message = ({ img, name, message, time }) => {
  const timestamp = moment(time).fromNow();

  return (
    <SoftBox
      borderRadius="lg"
      sx={{
        display: "flex",
        gap: { mini: "0.5rem", xxxl: "1rem" },
        marginTop: "0.25rem",
        padding: { mini: "12px 8px", xxxl: 1.5 },
        position: "relative",
        border: "1px solid rgba(255, 255, 255, 0.04)",
      }}
    >
      <SoftProfile
        src={img}
        name={name}
        size="sm"
      />
      <SoftBox
        sx={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}
      >
        <SoftBox sx={{ display: "flex" }}>
          <SoftTypography variant="h6" color="white" sx={{ lineHeight: 1.2 }}>
            {name}
          </SoftTypography>
        </SoftBox>
        <SoftTypography
          variant="body2"
          color="white"
          sx={{ fontSize: "0.875rem !important" }}
        >
          {message}
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="caption"
        sx={{
          fontWeight: 500,
          alignSelf: "center",
          color: `grey.${500}`,
          position: "absolute",
          top: "1rem",
          right: "0.5rem",
          maxWidth: "90px",
          fontSize: {mini: "0.7rem", xxl: "0.75rem"}
        }}
      >
        {timestamp}
      </SoftTypography>
    </SoftBox>
  );
};

export default Message;
