import { request } from "../helpers/axios.new";

export const FETCH_JOBS = (companyId, page, userId) =>
  request({ url: `/jobs/${companyId}?page=${page}&userId=${userId}` });

export const FETCH_JOB = (jobId) => request({ url: `/jobs/job/${jobId}` });

export const PUT_JOB_IMAGE = (jobId, imageData) =>
  request({
    method: "PUT",
    url: `/jobs/${jobId}/image/`,
    data: imageData,
  });

export const PUT_JOB_COMPOSED_IMAGE = (jobId, imageData) =>
  request({
    method: "PUT",
    url: `/jobs/${jobId}/composed-image/`,
    data: imageData,
  });

export const PUT_NEW_TAGS = (jobId, tags) =>
  request({
    method: "PUT",
    url: `/jobs/${jobId}/image-tags/`,
    data: { tags },
  });
