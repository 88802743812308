import React, { useState, useEffect } from "react";
import { GET_JETPACKS } from "../../../api/jetpacks";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { Divider, Grid } from "@mui/material";
import JetpackCard from "./Card";
import LoadingScreen from "../../../components/LoadingScreen";

const JetpackList = () => {
  const [loading, setLoading] = useState(false);
  const [packs, setPacks] = useState(null);

  useEffect(() => {
    setLoading(true);
    GET_JETPACKS()
      .then(({ data }) => {
        setPacks(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <SoftBox borderRadius="lg" shadow="xs" bgColor="white">
      <SoftBox padding={4} paddingBottom={2}>
        <SoftTypography variant="h5" sx={{ color: "#343A40" }}>
          Unlock the Power of Jetpacks
        </SoftTypography>
        <SoftTypography variant="body2" sx={{ color: "#6C757D" }}>
          These specialized tools are designed to supercharge your workflow,
          boost productivity, and streamline your tasks.
        </SoftTypography>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#F2F4F7",
        }}
      />

      {loading ? (
        <LoadingScreen />
      ) : (
        <SoftBox
          sx={{
            padding: {
              mini: "0.5rem 1rem 1rem 1rem",
              md: "0.75rem 1.5rem 1.5rem 1.5rem",
            },
          }}
        >
          <Grid container spacing={3}>
            {packs?.map((item) => (
              <Grid item lg={3} md={6} sm={6}>
                <JetpackCard key={item.name} values={item} />
              </Grid>
            ))}
          </Grid>
        </SoftBox>
      )}
      {/* <SoftBox
        padding={4}
        paddingTop={2}
        display="grid"
        sx={{
          gridTemplateColumns: {
            mini: "1fr",
            xs: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xxxl: "repeat(5, 1fr)",
          },
          gap: { mini: "1rem", lg: "1.25rem" },
        }}
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          packs?.map((item) => <JetpackCard key={item.name} values={item} />)
        )}
      </SoftBox> */}
    </SoftBox>
  );
};

export default JetpackList;
