/* eslint camelcase: 0 */

import { request } from "../../helpers/axios.new";

export const GET_CONNECTIONS = () => {
  return request({
    url: `/apideck/connections`,
    method: "GET",
  });
};
