import React from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const SwitchModal = (props) => {
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      left: "-2px",
      "&.Mui-checked": {
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: `${theme.palette.primary.main} !important`,
          borderColor: "none",
        },
      },
    },

    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      border: "none",
      background: "#343435",
    },

    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: "white",
    },
  }));
  return <CustomSwitch {...props} />;
};

export default SwitchModal;
