import * as React from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftProfile from "../ProfilePictures/SoftProfile";

const Note = ({
  name = "Sample Name",
  date = "November 24, 2023",
  note = "Hello!",
  profile
}) => {
  return (
    <div style={{ display: "flex", width: "100%", marginTop: "16px" }}>
      {/** Profile */}
      <div style={{ paddingRight: "8px" }}>
        <SoftProfile
          size="sm"
          src={profile}
        />
      </div>

      {/** Message details */}
      <div style={{
        minHeight: "70px",
        width: "100%"
      }}>
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& div": {
              fontStyle: "normal",
            },
            "& div:first-child": {
              color: "#344054",
              fontSize: "9px",
              fontWeight: "500",
              lineHeight: "11.979px"
            },
            "& div:last-child": {
              color: "#667085",
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "10.781px"
            }
          }}
        >
          {/** Name */}
          <div>
            {name}
          </div>

          {/** Date and Time */}
          <div>
            {date}
          </div>
        </SoftBox>
        
        {/** Note content */}
        <div
          style={{
            padding: "16px 8.385px",
            marginTop: "4px",
            borderRadius: "5px",
            backgroundColor: "#FFF",
            fontSize: "10.843px",
            fontWeight: 400,
            lineHight: "14.375px",
            wordBreak: "break-all",
            boxShadow: "0px 0.59895px 1.1979px 0px rgba(16, 24, 40, 0.06), 0px 0.59895px 1.79686px 0px rgba(16, 24, 40, 0.10)"
          }}
        >
            {note}
        </div>
      </div>
    </div>
  )
};

export default Note;
