import React from "react";
import { ReactComponent as TypeProgressiveSvg } from "../../../img/metrictype_progressive.svg";
import { ReactComponent as TypeAnchorSvg } from "../../../img/metrictype_anchor.svg";

export const TYPE_CONFIGS = {
  progressive: {
    icon: <TypeProgressiveSvg />,
    name: "Progressive Metric",
    desc: "Players strive to meet or exceed these daily goals.",
    index: 0,
  },
  anchor: {
    icon: <TypeAnchorSvg />,
    name: "Anchor Metric",
    desc: "Coming Soon!",
    // desc: "They aim to maintain a specific performance level over time.",
    index: 1,
    disabled: true
  },
};

export const TYPE_OPTIONS = [
  TYPE_CONFIGS.progressive,
  TYPE_CONFIGS.anchor,
];