import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { NewEventModal } from "./NewEventModal";
import { DeleteEventModal } from "./DeleteEventModal";
import { useDate } from "./calendarDate";

import { UseIsAdmin } from "../../hooks/auth";
import { UseCompany } from "../../hooks/company";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyHolidays } from "../../redux/company/actions";
import { GET_HOLIDAYS, PUT_HOLIDAY, DELETE_HOLIDAY } from "../../api/calendar";
import HolidayItem from "../CalendarHoliday/HolidayItem";
import CalendarRoot from "./CalendarRoot.js";
import AddNewEventModal from "./AddNewEventModal";
import UpdateEventModal from "./UpdateEventModal";

// Imported Styles
import "./calendarStyles.css";
import toast from "react-hot-toast";
import SoftBox from "../SoftBox";
import { Grid, Box } from "@mui/material";

const CalendarContainer = () => {
  const [nav, setNav] = useState(0);
  const [clicked, setClicked] = useState({
    action: null,
    popUp: false,
    day: null,
    item: null,
  });
  const [events, setEvents] = useState([]);
  const [monthEvents, setMonthEvents] = useState(events);
  const calendarRef = useRef(null);
  const [stats, setStats] = useState({
    workingDays: 0,
    nonWorkingDays: 0,
  });
  const { monthDisplay, currentMonth } = useDate(events, nav);
  const [updatedMonth, setUpdatedMonth] = useState(currentMonth);

  const company = UseCompany();
  const isAdmin = UseIsAdmin();
  const dispatch = useDispatch();

  useEffect(() => {
    GET_HOLIDAYS(company._id).then(({ data }) => {
      const holidays = [];
      data.forEach((item) => {
        const date = moment(item.holiday).format("MM/DD/YYYY");
        const holidayItem = {
          date,
          text: item.text || "Holiday",
          title: item.text || "Holiday",
          start: item.holiday,
          end: item.holiday,
          className: "event-info",
        };
        holidays.push(holidayItem);
      });

      setEvents(holidays);
    });
  }, [company._id]);

  const handleAdd = async (title) => {
    PUT_HOLIDAY(company._id, clicked.day.date, title)
      .then(() => {
        dispatch(getCompanyHolidays()); // Refresh company workday details to update their KPI calculations
        const calendarDate = moment(clicked.day.date).format("YYYY-MM-DD");
        const newEvent = {
          date: clicked.day.date,
          text: title,
          title,
          start: calendarDate,
          end: calendarDate,
          className: "event-info",
        };
        setEvents([...events, newEvent]);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
        toast.success("Event Added");
      })
      .catch((error) => {
        console.log(error);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
      });
  };

  const handleDelete = async () => {
    DELETE_HOLIDAY(company._id, clicked.day.date)
      .then(() => {
        dispatch(getCompanyHolidays()); // Refresh company workday details to update their KPI calculations
        const days = [...events];
        const index = days.findIndex((day) => clicked.day.date === day.date);
        days.splice(index, 1);
        setEvents(days);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
        toast.success("Event Deleted");
      })
      .catch((error) => {
        console.log(error);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
      });
  };

  const handleUpdate = async (title) => {
    PUT_HOLIDAY(company._id, clicked.day.date, title)
      .then(() => {
        dispatch(getCompanyHolidays()); // Refresh company workday details to update their KPI calculations
        const days = [...events];
        const index = days.findIndex((day) => clicked.day.date === day.date);
        days[index].title = title;
        setEvents([...days]);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
        toast.success("Event Updated");
      })
      .catch((error) => {
        console.log(error);
        setClicked({
          action: null,
          popUp: false,
          day: null,
          item: null,
        });
      });
  };

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const currentYear = currentDate.getFullYear();

    const filteredEvents = events.filter(
      (data) =>
        data.date.startsWith(updatedMonth) && data.date.endsWith(currentYear),
    );
    setMonthEvents(filteredEvents);
  }, [updatedMonth, clicked]);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    const currentYear = currentDate.getFullYear();

    const month = moment(`${currentYear}-${updatedMonth}-01`, "YYYY-MM-DD");

    let workingDay = 0;
    const daysInMonth = month.daysInMonth();

    const workbreaks = events
      ?.filter((item) => month.isSame(item.date, "month"))
      .map((item) => item.date);

    for (let day = 0; day < daysInMonth; day++) {
      const date = moment(month).startOf("month").add(day, "days");
      const dayOfWeek = date.day(); // 0 = Sunday, 6 = Saturday

      if (dayOfWeek > 0 && dayOfWeek < 6) {
        const isEventDate = workbreaks.some((item) => {
          return date.isSame(item, "day");
        });

        // increment the working days counter if it's not a weekend or an event date
        if (!isEventDate) {
          workingDay++;
        }
      }
    }

    setStats({
      workingDays: workingDay,
      nonWorkingDays: daysInMonth - workingDay,
    });
  }, [updatedMonth, events]);

  const props = {
    initialView: "dayGridMonth",
    initialDate: moment().format("YYYY-MM-DD"),
    selectable: true,
    editable: true,
  };

  const handleDateClick = (info) => {
    const calendarApi = calendarRef.current.getApi();
    const calendarEvents = calendarApi.getEvents();

    // info.date when clicking on the calendar itself; info.event when clicking the event item
    const refDate = info.date ?? info.event.start;
    const clickedDate = new Date(refDate.setHours(0, 0, 0, 0));
    const hasEvents = calendarEvents.some((item) => {
      const eventDate =
        item.start instanceof Date ? item.start : new Date(item.start);
      const eventDateOnly = new Date(eventDate.setHours(0, 0, 0, 0));
      return eventDateOnly.toDateString() === clickedDate.toDateString();
    });

    const date = moment(clickedDate).format("MM/DD/YYYY");

    if (hasEvents) {
      const days = [...events];
      const index = days.findIndex((day) => date === day.date);

      setClicked({
        day: { date },
        action: "DELETE",
        popUp: true,
        item: days[index],
      });
    } else {
      setClicked({
        day: { date },
        action: "CREATE",
        popUp: true,
        item: null,
      });
    }
  };

  const handleDatesSet = (dateInfo) => {
    const currentDate = dateInfo.view.calendar.getDate();
    const currentM = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    setUpdatedMonth(currentM);
  };

  return (
    <Box sx={{ width: "full", marginY: "1rem" }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid
          item
          mini={12}
          md={8}
          lg={9}
          sx={{ height: "max-content" }}
        >
          <CalendarRoot p={2}>
            <FullCalendar
              {...props}
              ref={calendarRef}
              events={events}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              height="100%"
              dateClick={handleDateClick} // handles clicks on the calendar
              eventClick={handleDateClick} // handles clicks on the event item inside the calendar
              datesSet={handleDatesSet}
            />
          </CalendarRoot>
        </Grid>
        <Grid item mini={12} md={4} lg={3}>
          <SoftBox mb={3}>
            <HolidayItem
              clicked={clicked}
              month={monthDisplay}
              monthEvents={monthEvents}
              workingDays={stats.workingDays}
              nonWorkingDays={stats.nonWorkingDays}
            />
          </SoftBox>
        </Grid>
      </Grid>
      {isAdmin && clicked.action === "CREATE" && clicked.popUp && (
        <AddNewEventModal
          onClose={() =>
            setClicked({
              action: null,
              popUp: false,
              day: null,
              item: null,
            })
          }
          onSave={(title) => {
            handleAdd(title);
          }}
          clicked={clicked}
        />
      )}
      {isAdmin && clicked.action === "DELETE" && clicked.popUp && (
        <UpdateEventModal
          onClose={() =>
            setClicked({
              action: null,
              popUp: false,
              day: null,
              item: null,
            })
          }
          onDelete={() => {
            handleDelete(clicked?.day);
          }}
          onUpdate={(title) => {
            handleUpdate(title);
          }}
          clicked={clicked}
        />
      )}
    </Box>
  );
};

export default CalendarContainer;
