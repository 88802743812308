import React from "react";
import ReadyPlayerMe from "../../components/ReadyPlayerMe";

// Imported Styles
import "./avatarStyles.css";

const AvatarPage = () => (
  <div className="w-full h-full">
    <ReadyPlayerMe />
  </div>
);

export default AvatarPage;
