import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { types } from "./types";
import { FETCH_COMMENTS, FETCH_ARENA_COMMENTS } from "../../api/comments";

function* GetAllMessages(action) {
  try {
    const { propertyId, page, source } = action.payload;
    const req = yield call(() => source === "arena" ? FETCH_ARENA_COMMENTS(propertyId, page) : FETCH_COMMENTS(propertyId, page));

    const { comments, hasMore, currentPage } = req.data;

    yield put({
      type: types.GET_ALL_MESSAGES_SUCCESS,
      messages: comments,
      hasMore,
      currentPage,
    });
  } catch (err) {
    yield put({ type: types.GET_ALL_MESSAGES_FAILED, error: err.message });
  }
}

function* watchGetMessagesRequest() {
  yield takeEvery(types.GET_ALL_MESSAGES, GetAllMessages);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchGetMessagesRequest)]);
}

export default rootSaga;
