import * as Yup from "yup";
import {
  KPI_KINDS,
  KPI_DIRECTIONS,
  KPI_FORM_INPUT_TYPES,
  KPI_IMAGE_PARAMS,
} from "../constants/kpi";

const CustomKpiSchema = [
  Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(24, "Metric name must be at most 24 characters"),
    type: Yup.number(),
  }),
  Yup.object().shape({
    kind: Yup.string()
      .oneOf(KPI_KINDS, "Invalid KPI type")
      .required("Type is required"),
    direction: Yup.number()
      .oneOf(KPI_DIRECTIONS, "Invalid direction")
      .required("Direction is required"),
    target: Yup.number()
      .typeError("Not a valid number")
      .when("type", {
        is: 0, // Apply these rules when type is 0
        then: Yup.number()
          .required("Target is required")
          .min(0.01, "Target must be a positive number"),
        otherwise: Yup.number().min(
          0,
          "Target must be zero or a positive number",
        ),
      }),
    pointScale: Yup.number()
      .required("Points is required")
      .typeError("Not a valid number")
      .min(0, "Point scale must be zero or a positive number"),
  }),
  Yup.object().shape({
    inputs: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required("Input type is required")
            .oneOf(KPI_FORM_INPUT_TYPES, "Input type is not valid"),
          value: Yup.string()
            .notOneOf(['amount', 'Amount'], 'Amount is a reserved word. Please select another label.')
            .required("Input label is required"),
        }),
      )
      .min(1, "Form input is required")
      .required("Form input is required")
      .test(
        "one-image-only",
        "Only one image-type input is allowed",
        (values) => {
          // Check count of image-type parameters
          const imageCount = values?.reduce((count, { type }) => {
            const isImage = KPI_IMAGE_PARAMS.some((paramKey) =>
              type.toLowerCase().includes(paramKey),
            );
            return isImage ? count + 1 : count;
          }, 0);

          // Verify if it's 1 or none; If it's more than that, raise an error
          return imageCount <= 1;
        },
      ),
  }),
];

export default CustomKpiSchema;
