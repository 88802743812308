import * as Yup from "yup";

const SecurityChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Password is required"),
  newPassword:  Yup.string()
    .required('New Password is required')
    .notOneOf(
      [
        Yup.ref('oldPassword'),
      ],
      "New password should not be same as old password"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Must Contain at least 6 Characters with at least One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmNewPassword: Yup.string()
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword')], 'New Password and Confirm New Password must match.'),
});

export default SecurityChangePasswordSchema;
