import React from "react";

const GameboardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78 15.1667H1.33334C1.06 15.1667 0.833336 14.94 0.833336 14.6667V10.6667C0.833336 9.65334 1.65334 8.83334 2.66667 8.83334H5.78C6.05333 8.83334 6.28 9.06 6.28 9.33334V14.6667C6.28 14.94 6.05333 15.1667 5.78 15.1667ZM1.83334 14.1667H5.28V9.83334H2.66667C2.20667 9.83334 1.83334 10.2067 1.83334 10.6667V14.1667Z"
        fill="#F2F4F7"
      />
      <path
        d="M10.2201 15.1667H5.77344C5.5001 15.1667 5.27344 14.94 5.27344 14.6667V8C5.27344 6.98666 6.09344 6.16666 7.10677 6.16666H8.88677C9.9001 6.16666 10.7201 6.98666 10.7201 8V14.6667C10.7201 14.94 10.5001 15.1667 10.2201 15.1667ZM6.2801 14.1667H9.72677V8C9.72677 7.54 9.35344 7.16666 8.89344 7.16666H7.11344C6.65344 7.16666 6.2801 7.54 6.2801 8V14.1667Z"
        fill="#F2F4F7"
      />
      <path
        d="M14.6667 15.1667H10.2201C9.94672 15.1667 9.72005 14.94 9.72005 14.6667V11.3333C9.72005 11.06 9.94672 10.8333 10.2201 10.8333H13.3334C14.3467 10.8333 15.1667 11.6533 15.1667 12.6667V14.6667C15.1667 14.94 14.9401 15.1667 14.6667 15.1667ZM10.7201 14.1667H14.1667V12.6667C14.1667 12.2067 13.7934 11.8333 13.3334 11.8333H10.7201V14.1667Z"
        fill="#F2F4F7"
      />
      <path
        d="M9.13326 5.56665C8.97326 5.56665 8.77326 5.51998 8.5466 5.38664L7.99993 5.05998L7.45993 5.37999C6.91326 5.70666 6.55326 5.51332 6.41993 5.41999C6.2866 5.32665 5.99993 5.03332 6.13993 4.41999L6.2666 3.86664L5.81326 3.41331C5.53326 3.13331 5.43326 2.79998 5.53326 2.49331C5.63326 2.18664 5.9066 1.97331 6.29326 1.90664L6.87326 1.80664L7.19993 1.15332C7.55993 0.439991 8.43326 0.439991 8.7866 1.15332L9.11326 1.80664L9.69326 1.90664C10.0799 1.97331 10.3599 2.18664 10.4533 2.49331C10.5533 2.79998 10.4466 3.13331 10.1733 3.41331L9.71993 3.86664L9.8466 4.41999C9.9866 5.03999 9.69993 5.32666 9.5666 5.42666C9.5066 5.47999 9.35326 5.56665 9.13326 5.56665ZM7.99993 4.05331C8.15993 4.05331 8.31993 4.09332 8.45326 4.17332L8.8266 4.39331L8.7466 4.03331C8.67993 3.75331 8.77993 3.41332 8.9866 3.20665L9.3266 2.86664L8.9066 2.79332C8.63993 2.74665 8.37993 2.55332 8.25993 2.31332L7.99993 1.81332L7.7466 2.31332C7.6266 2.55332 7.3666 2.74665 7.09993 2.79332L6.67993 2.85999L7.01993 3.19998C7.2266 3.40664 7.31993 3.74666 7.25993 4.02666L7.17993 4.38664L7.55327 4.16665C7.67993 4.08665 7.83993 4.05331 7.99993 4.05331Z"
        fill="#F2F4F7"
      />
    </svg>
  );
};

export default GameboardIcon;
