import React from "react";

const ClaimsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.1667C7.6 15.1667 7.20666 15.0733 6.88666 14.8933L3.73333 13.0733C2.42666 12.1933 2.34 12.0333 2.34 10.6533V7.34666C2.34 5.96667 2.42666 5.80666 3.70666 4.94667L6.89333 3.10667C7.52666 2.74 8.48 2.74 9.11333 3.10667L12.2667 4.92666C13.5733 5.80666 13.66 5.96667 13.66 7.34666V10.6533C13.66 12.0333 13.5733 12.1933 12.2933 13.0533L9.10666 14.8933C8.79333 15.08 8.39333 15.1667 8 15.1667ZM8 3.83333C7.77333 3.83333 7.54666 3.88 7.38666 3.97333L4.23333 5.79333C3.34 6.4 3.34 6.4 3.34 7.34666V10.6533C3.34 11.6 3.34 11.6 4.26666 12.2267L7.39333 14.0267C7.71333 14.2133 8.29333 14.2133 8.61333 14.0267L11.7667 12.2067C12.66 11.6 12.66 11.6 12.66 10.6533V7.34666C12.66 6.4 12.66 6.4 11.7333 5.77333L8.60666 3.97333C8.45333 3.88 8.22666 3.83333 8 3.83333Z"
        fill="#F9FAFB"
      />
      <path
        d="M11.6667 5.58668C11.3933 5.58668 11.1667 5.36001 11.1667 5.08668V3.33334C11.1667 2.28001 10.72 1.83334 9.66667 1.83334H6.33334C5.28 1.83334 4.83334 2.28001 4.83334 3.33334V5.04001C4.83334 5.31334 4.60667 5.54001 4.33334 5.54001C4.06 5.54001 3.83334 5.32001 3.83334 5.04001V3.33334C3.83334 1.72001 4.72 0.833344 6.33334 0.833344H9.66667C11.28 0.833344 12.1667 1.72001 12.1667 3.33334V5.08668C12.1667 5.36001 11.94 5.58668 11.6667 5.58668Z"
        fill="#F9FAFB"
      />
      <path
        d="M9.08667 11.7533C8.94667 11.7533 8.8 11.7267 8.65333 11.6667L8 11.4133L7.34667 11.6733C6.99333 11.8133 6.63333 11.78 6.36667 11.5867C6.1 11.3933 5.96 11.06 5.98 10.68L6.02 9.97999L5.57333 9.43999C5.33333 9.13999 5.25333 8.79332 5.36 8.47332C5.46 8.15999 5.73333 7.91999 6.1 7.82665L6.78 7.65332L7.16 7.05999C7.56667 6.41999 8.44 6.41999 8.84667 7.05999L9.22667 7.65332L9.90667 7.82665C10.2733 7.91999 10.5467 8.15999 10.6467 8.47332C10.7467 8.78665 10.6667 9.13999 10.4267 9.43332L9.98 9.97332L10.02 10.6733C10.04 11.0533 9.9 11.38 9.63333 11.58C9.47333 11.6933 9.28667 11.7533 9.08667 11.7533ZM6.34667 8.79999L6.79333 9.33999C6.94667 9.51999 7.03333 9.80665 7.02 10.04L6.98 10.74L7.63333 10.48C7.85333 10.3933 8.14667 10.3933 8.36667 10.48L9.02 10.74L8.98 10.04C8.96667 9.80665 9.05333 9.52665 9.20667 9.33999L9.65333 8.79999L8.97333 8.62665C8.74667 8.56665 8.50667 8.39332 8.38 8.19999L8.00667 7.61332L7.62667 8.19999C7.5 8.39999 7.26 8.57332 7.03333 8.63332L6.34667 8.79999Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default ClaimsIcon;
