import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { types } from "./types";
import { types as workspaceTypes } from "../workspace/types";
import { GET_CLAIM_ITEMS, PUT_CLAIM } from "../../api/claims";

const getSelectedWorkspace = (state) =>
  state.workspacesReducer.selectedWorkspace;

function* GetCompanyClaims() {
  try {
    const workspace = yield select(getSelectedWorkspace);
    const { data } = yield call(() => GET_CLAIM_ITEMS(workspace?._id));

    yield put({
      type: types.GET_COMPANY_CLAIMS_SUCCESS,
      claims: data,
    });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_CLAIMS_FAILED, error: err.message });
  }
}

function* PutUserClaim(action) {
  try {
    const { itemId, claimId, claimType } = action;
    yield call(() => PUT_CLAIM(itemId, claimId, claimType));
    yield put({ type: types.PUT_USER_CLAIMS_SUCCESS });
  } catch (err) {
    yield put({ type: types.PUT_USER_CLAIMS_FAILED, error: err.message });
  }
}

function* companyClaimsSaga() {
  yield takeEvery(types.GET_COMPANY_CLAIMS_REQUEST, GetCompanyClaims);
  yield takeEvery(workspaceTypes.SET_SELECTED_WORKSPACE_SUCCESS, GetCompanyClaims);

  yield takeEvery(types.PUT_USER_CLAIMS_REQUEST, PutUserClaim);
  yield takeEvery(types.PUT_USER_CLAIMS_SUCCESS, GetCompanyClaims);
}

// root saga container
function* rootSaga() {
  yield all([fork(companyClaimsSaga)]);
}

export default rootSaga;
