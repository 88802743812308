const messages = {
  mxPlanSuccess: "Successfully added mx plan",
  addRevenueSuccess: "Successfully added revenue",
  callBackAddedSuccess: "Successfully added KPI",
  reviewAddedSuccess: "Successfully added review",
  upsellAmountAddedSuccess: "Successfully added upsell amount",
  registerTCerror: "Accept term and conditions to proceed",
  unmatchedPassword: "Passwords do not match.",
  noKpis: "You need to add KPIs.",
  incompleteKpi: "Please complete Details of KPIs.",
  incompleteCompany: "Please complete company details.",
  incompleteGameBoard: "Please choose a Gameboard.",
  incompleteBadGuy: "Please choose a Bad Guy.",
  emailTaken: "Email already taken.",
};

export default messages;
