import { request } from "../helpers/axios.new";

export const GET_PRICING = () =>
  request({
    method: "GET",
    url: "/payment/prices",
  });

export const GET_SUBSCRIPTION = () =>
  request({
    method: "GET",
    url: "/payment/subscription",
  });

export const POST_SUBSCRIPTION = ({ priceId, code = null }) =>
  request({
    method: "POST",
    url: "/payment/create-subscription",
    data: { priceId, ...(code && { code }) },
  });

export const POST_TRIAL_SUBSCRIPTION = ({ priceId, userData, code = null }) =>
  request({
    method: "PUT",
    url: "/payment/create-trial-subscription",
    data: { priceId, userData, ...(code && { code }) },
  });

export const POST_CCLESS_TRIAL_SUBSCRIPTION = (userData) =>
  request({
    method: "PUT",
    url: "/payment/create-free-trial-subscription",
    data: { userData },
  });

export const VERIFY_PAYMENT = (paymentId, isSetup = false) =>
  request({
    method: "POST",
    url: "/payment/verify",
    data: { paymentId, isSetup },
  });

export const VERIFY_CODE = (code) =>
  request({
    method: "POST",
    url: "/payment/code",
    data: { code },
  });

export const CANCEL_SUBSCRIPTION = ({ reason }) => {
  return request({
    url: `/payment/cancel-subscription`,
    method: "POST",
    data: { reason },
  });
};

export const GET_ALL_CARDS = () => {
  return request({
    url: "/payment/card/all",
  });
};

export const GET_ACTIVE_CARD = () => {
  return request({
    url: "/payment/card/default",
  });
};

export const ADD_NEW_DEFAULT_CARD = (data) => {
  return request({
    url: `/payment/card`,
    method: "POST",
    data,
  });
};
