import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

const AccountDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E9EB",
  borderColor: "transparent",
  backgroundImage: "none !important",
  opacity: 1,
  margin: "24px 0"
}))

export default AccountDivider;
