import React from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import {TextField} from "@mui/material";

const ReactHookFormPhone = ({ control, name, defaultValue, ...rest }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <NumberFormat
          allowNegative={false}
          onValueChange={(v) => onChange(v.value)}
          value={value}
          margin="dense"
          customInput={TextField}
          type="text"
          format="###-###-####"
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default ReactHookFormPhone
