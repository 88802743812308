import React from "react";

import WidgetBox from "../WidgetBox";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";

const PlayerMemo = ({ widget = {}, reFetch, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  return showPerformBox ? (
    <PerformBoxFlexible
      title={widget.title}
      amount={widget.userInput || ""}
      showGraph={false}
      showCharts={showCharts}
      isSmallScreen={isSmallScreen}
    />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={widget.userInput || ""}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default PlayerMemo;
