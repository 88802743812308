/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

export default styled(Avatar)(({ theme, ownerState }) => {
  const { palette, functions, typography, boxShadows, borders } = theme;
  const { shadow, bgColor, size, borderRadius, width, height, fullCover = false } = ownerState;

  const { gradients, transparent } = palette;
  const { pxToRem, linearGradient } = functions;
  const { size: fontSize, fontWeightBold } = typography;
  const { borderRadius: radii } = borders;

  // backgroundImage value
  const backgroundValue =
    bgColor === "transparent"
      ? transparent.main
      : linearGradient(gradients[bgColor].main, gradients[bgColor].state);

  // size value
  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = { // ~24px
        width: "2.25vh",
        height: "2.25vh",
        fontSize: "1.5vh",
      };
      break;
    case "sm":
      sizeValue = { // ~36px
        width: "3.25vh",
        height: "3.25vh",
        fontSize: "2.5vh",
      };
      break;
    case "lg":
      sizeValue = { // ~65px
        width: "6vh",
        height: "6vh",
        fontSize: "4.5vh",
      };
      break;
    case "xl":
      sizeValue = { // ~80px
        width: "8vh",
        height: "8vh",
        fontSize: "6vh",
      };
      break;
    case "xxl":
      sizeValue = { // ~100px
        width: "10vh",
        height: "10vh",
        fontSize: "7.5vh",
      };
      break;
    default: {
      sizeValue = { // ~50px
        width: "4.5vh",
        height: "4.5vh",
        fontSize: "3.25vh",
      };
    }
  }

  return {
    background: backgroundValue,
    fontWeight: fontWeightBold,
    boxShadow: boxShadows[shadow],
    borderRadius: radii[borderRadius] ?? radii.section,
    // If custom width OR height is present, apply custom dimensions
    ...(width || height
      ? {
          width: width ?? "auto",
          height: height ?? "auto",
          aspectRatio: "1/1",
        }
      // otherwise assign preset sizes
      : { ...sizeValue }),
    // Make picture cover the whole circle area
    ...(fullCover &&
      {
        "& .MuiAvatar-img": {
          height: "100%",
          backgroundSize: "cover"
        }
      })
  };
});
