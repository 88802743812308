export const initState = {
  authenticated: false,
  userDetails: null,
  isLoading: false,
  error: false,
  errorMessage: "",
  isPrime: false,
  isDemo: false,
  status: null,
};

export const types = {
  // for login
  LOGIN_REQUEST: "LOGIN_REQUEST",
  SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED_INVALID: "LOGIN_FAILED_INVALID",

  // for quick logins (after freemium sign up)
  LOGIN_QUICK_REQUEST: "LOGIN_QUICK_REQUEST",
  LOGIN_QUICK_SUCCESS: "LOGIN_QUICK_SUCCESS",
  LOGIN_QUICK_FAILED: "LOGIN_QUICK_FAILED",

  // for register
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILED: "REGISTER_FAILED",

  // for get current user summary
  GET_USER_SUMMARY_REQUEST: "GET_USER_SUMMARY_REQUEST",
  GET_USER_SUMMARY_SUCCESS: "GET_USER_SUMMARY_SUCCESS",
  GET_USER_SUMMARY_FAILED: "GET_USER_SUMMARY_FAILED",

  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

  UPDATE_WIDGETS_REQUEST: "UPDATE_WIDGETS_REQUEST",
  UPDATE_WIDGETS_SUCCESS: "UPDATE_WIDGETS_SUCCESS",
  UPDATE_WIDGETS_FAILED: "UPDATE_WIDGETS_FAILED",

  PROFILE_PIC_UPLOAD: "PROFILE_PIC_UPLOAD",
};
