import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";

export const manualEntryStyles = makeStyles({
  // General Styles
  pageWrapper: {
    display: "grid",
    gridTemplateColumns: "5.5rem auto",
  },

  responsiveContainer: {
    margin: "0 auto",
    width: "100%",

    "@media screen and (min-width: 1921px)": {
      maxWidth: "1600px",
    },

    "@media screen and (max-width: 1920px)": {
      maxWidth: "1200px",
    },

    "@media screen and (max-width: 1200px)": {
      maxWidth: "1000px",
    },

    "@media screen and (max-width: 1024px)": {
      maxWidth: "800px",
    },

    "@media screen and (max-width: 768px)": {
      maxWidth: "500px",
    },

    "@media screen and (max-width: 480px)": {
      maxWidth: "400px",
    },
  },

  pText: {
    fontFamily: "DM Sans, Inter, Arial, sans-serif",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    margin: 0,
  },

  // Component Styles

  // Page Info Component
  pageInfo: {
    width: "100%",
    background: "#FFADD6",
    padding: "0.5rem",
  },

  textContainer: {
    display: "flex",
  },

  pageInfoIcon: {
    fontSize: "1.1rem !important",
    marginRight: "0.2rem",
  },

  headerInfo: {
    fontWeight: "bold",
    margin: "0 5px 0 0",
    fontSize: "14px",
  },

  subHeadingInfo: {
    fontSize: "14px",
  },

  // Table Component
  manualEntryContainer: {
    margin: "2rem auto",
    borderRadius: "1rem",
    boxShadow: "1px 4px 10px 2px rgba(135, 135, 135, 0.3)",
    padding: "1.5rem",
  },

  tableContainer: {
    borderCollapse: "separate",
  },

  tableLabel: {
    fontSize: "1.15rem",
    fontWeight: "bold",
    marginTop: "20px",
  },

  avatarStyle: {
    width: "24px",
    marginRight: "0.75rem",
  },

  usernameContainer: {
    display: "flex",
    fontWeight: "600",
    fontSize: "1.1rem",
    alignItems: "center",
  },

  usernameSubContainer: {
    display: "flex",
    alignItems: "center",
  },

  userInfoContainer: {
    "& p": {
      marginBottom: 0,
    },

    "& p:nth-child(2)": {
      fontSize: "0.88rem",
      fontWeight: "500",
      color: "#8392AB",
    },
  },

  thIcon: {
    color: "#8392ab",
    fontWeight: "bold",
    padding: "8px 0 0px",
  },

  thText: {
    color: "#8392ab",
    fontWeight: "bold",
    padding: "0px 0 8px",
  },

  tdWrapper: {
    padding: "8px 10px",
    margin: "5px 0",
  },

  textCentered: {
    textAlign: "center",
  },

  table: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },

  inputFieldStyle: {
    borderColor: "#8392ab",
    padding: "8px",
    width: "100%",
    borderRadius: "10px",

    "&:focus": {
      borderColor: "#d63384",
    },
  },

  successAlert: {
    width: "35%",
    position: "fixed",
    right: "0",
  },
});

export const StyledPaper = styled(Paper)`
  border-radius: 18px;
  padding: 3rem 2rem;
  width: 100%;
`;
