import React from "react";
import SoftBox from "../../components/SoftBox";
import lockedGif from "../../img/locked-gradient.gif";

const LockedOverlay = ({
  opacity = 0.4,
  lockSize = 48,
  backgroundColor = "#000",
  borderRadius = "8px"
}) => {
  return (
    <SoftBox
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100% ",
        zIndex: 1,
        borderRadius: "8px",
        display: "grid",
        placeContent: "center",
        "& img": { zIndex: 1 }
      }}
    >
      <img src={lockedGif} alt="" width={lockSize} height={lockSize} />

      <SoftBox
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor,
          opacity,
          position: "absolute",
          borderRadius
        }}
      />
    </SoftBox> 
  );
};

export default LockedOverlay;
