import React from "react";
import { useNavigate } from "react-router-dom";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SoftTypography from "../SoftTypography";

const AddKpiBox = () => {
  const navigate = useNavigate();

  return (
    <SoftButton
      shadow="sm"
      borderRadius="xl"
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        border: "1px dashed #CED4DA",
        textTransform: "none",
        paddingY: "0.25rem",
        background: "#FAFBFD",
      }}
      // redirect instantly to workspace KPI page instead of showing the modal from before
      onClick={() => navigate("/gameboards/workspace-kpi")}
    >
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "row",
          boxShadow: "none",
          width: "100%",
          borderRadius: "1rem",
          fontSize: "1rem",
          fontWeight: 500,
          justifyContent: "space-between",
          "& svg": { fontSize: "2rem !important" },
        }}
      >
        <SoftBox textAlign="start" width="220px">
          <SoftTypography variant="h5" sx={{ color: "#6C757D" }}>
            Add KPI
          </SoftTypography>
          <SoftTypography
            variant="caption"
            sx={{ color: "rgba(0, 0, 0, 0.40)" }}
          >
            You want to get more insights on a specific metrics, its one click
            away.
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftBox bgColor="primary" p={1} borderRadius="50%">
            <AddRoundedIcon color="white" />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftButton>
  );
};

export default AddKpiBox;
