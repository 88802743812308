import React from "react";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import InvoiceTable from "./InvoiceTable";
import { useSearchParams } from "react-router-dom";

const BillingInvoice = () => {
  const [params] = useSearchParams();
  const tier = params.get("tier");

  return (
    <SoftBox
      sx={{
        background: "white",
        borderRadius: "16px",
        padding: "20px",
      }}
    >
      <SoftBox display="flex" justifyContent="space-between" mb="15px">
        <SoftBox>
          <SoftTypography
            sx={{ color: "#2C2C2C", fontWeight: 700, fontSize: "22px" }}
          >
            Invoice
          </SoftTypography>
          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
            Effortlessly handle your billing and invoices right here. {tier}
          </SoftTypography>
        </SoftBox>
        {/* <SoftButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          sx={{
            color: "primary.contrastText",
            textTransform: "none",
            borderRadius: "4px",
            height: "min-content",
          }}
        >
          Download
          {isLoading && (
          <CircularProgress color="inherit" size="1rem" className="ml-2" />
        )}
        </SoftButton> */}
      </SoftBox>
      <InvoiceTable />
    </SoftBox>
  );
};

export default BillingInvoice;
