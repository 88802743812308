import React, { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";

const SoftDialog = forwardRef(
  ({ open, onClose, type, icon, title, description, footer, ...rest }, ref) => (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          padding: { mini: "1rem", md: "1.5rem 1.7rem" },
          borderRadius: "16px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "500px",
          borderWidth: "1px",
          borderColor: "#FFF",
        },
      }}
      {...rest}
      ref={ref}
    >
      <SoftBox display="flex" justifyContent="center" mb="20px">
        {type === "success" && (
          <SoftBox
            sx={{
              width: "71px",
              height: "71px",
              lineHeight: "71px",
              borderRadius: "50%",
              background:
                "linear-gradient(179.84deg, rgba(41, 158, 71, 0) 4.75%, rgba(41, 158, 71, 0.012) 47.3%, rgba(41, 158, 71, 0.2) 146.48%)",
              border: "0.44px solid",
              borderImageSource:
                "linear-gradient(180deg, rgba(152, 162, 179, 0.03) 0%, rgba(65, 70, 77, 0.16) 122.84%)",
              postion: "relative",
            }}
          >
            <SoftBox
              sx={{
                background:
                  "linear-gradient(179.27deg, rgba(41, 158, 71, 0.25) 0.63%, rgba(41, 158, 71, 0.1) 56.54%, rgba(41, 158, 71, 0.35) 157.35%)",
                border: "0.44px solid",
                borderImageSource:
                  "linear-gradient(180deg, rgba(71, 84, 103, 0.44) 0%, rgba(141, 167, 205, 0.06) 100%)",
                width: "58px",
                height: "58px",
                lineHeight: "58px",
                borderRadius: "50%",
                textAlign: "center",
                position: "absolute",
                left: 0,
                right: 0,
                top: "10%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {icon}
            </SoftBox>
          </SoftBox>
        )}
        {type === "error" && (
          <SoftBox
            sx={{
              width: "71px",
              height: "71px",
              lineHeight: "71px",
              borderRadius: "50%",
              background:
                "linear-gradient(179.84deg, rgba(158, 41, 41, 0) 4.75%, rgba(158, 41, 41, 0.012) 47.3%, rgba(158, 41, 41, 0.2) 146.48%)",
              border: "0.44px solid",
              borderImageSource:
                "linear-gradient(180deg, rgba(152, 162, 179, 0.03) 0%, rgba(65, 70, 77, 0.16) 122.84%)",
              postion: "relative",
            }}
          >
            <SoftBox
              sx={{
                background:
                  "linear-gradient(179.27deg, rgba(158, 41, 41, 0) 0.63%, rgba(158, 41, 41, 0.1) 56.54%, rgba(158, 41, 41, 0.35) 157.35%)",
                border: "0.44px solid",
                borderImageSource:
                  "linear-gradient(180deg, rgba(71, 84, 103, 0.44) 0%, rgba(141, 167, 205, 0.06) 100%)",
                width: "58px",
                height: "58px",
                lineHeight: "58px",
                borderRadius: "50%",
                textAlign: "center",
                position: "absolute",
                left: 0,
                right: 0,
                top: "10%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {icon}
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
      <SoftTypography
        variant="h3"
        sx={{
          fontSize: "18px",
          fontWeight: 700,
          color: "#FFF",
          textAlign: "center",
        }}
      >
        {title}
      </SoftTypography>
      {description}
      {footer}
    </Dialog>
  ),
);

SoftDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["success", "error"]).isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
};

export default SoftDialog;
