import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { types } from "./types";
import { types as companyTypes } from "../company/types";
import { FETCH_GROUPS } from "../../api/group";

const getCompanyId = (state) => state.authReducer.userDetails.companyId;
const getIsAdmin = (state) => state.authReducer.userDetails.isAdmin;
const getGroupId = (state) => state.authReducer.userDetails.groupId;
const getWorkspaces = (state) => state.workspacesReducer.workspaces;

function* GetWorkspaces(action) {
  try {
    const companyId = yield select(getCompanyId);
    const { data: workspaces } = yield call(() => FETCH_GROUPS(companyId));

    yield put({
      type: types.GET_ALL_WORKSPACES_SUCCESS,
      workspaces,
    });

    const { selectedWorkspace } = action;
    let currentWorkspace = selectedWorkspace;
   
    if(!currentWorkspace) {
      const groupId = yield select(getGroupId);
      currentWorkspace = workspaces.find(w=> w._id === groupId);
    }

    yield put({
      type: types.SET_SELECTED_WORKSPACE,
      selectedWorkspace: currentWorkspace || workspaces[0],
    });
  } catch (err) {
    yield put({ type: types.GET_ALL_WORKSPACES_FAILED, error: err.message });
  }
}

function* SetSelectedWorkspace(action) {
  try {
    let { selectedWorkspace } = action;
    
    if(!selectedWorkspace) {
      const isAdmin = yield select(getIsAdmin);
      const groupId = yield select(getGroupId);
      const workspaces = yield select(getWorkspaces);
      
      if(!isAdmin && groupId) {
        selectedWorkspace = workspaces.find(row => row._id === groupId);
      } else {
        // eslint-disable-next-line prefer-destructuring
        selectedWorkspace  = workspaces[0];
      }
    }
    yield put({
      type: types.SET_SELECTED_WORKSPACE_SUCCESS,
      selectedWorkspace,
    });
  } catch (err) {
    yield put({ type: types.SET_SELECTED_WORKSPACE_FAILED, error: err.message });
  }
}

function* watchGetJobsRequest() {
  yield takeEvery(types.GET_ALL_WORKSPACES, GetWorkspaces);
  yield takeEvery(companyTypes.GET_COMPANY_SUCCESS, GetWorkspaces);
  yield takeEvery(types.SET_SELECTED_WORKSPACE, SetSelectedWorkspace);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchGetJobsRequest)]);
}

export default rootSaga;
