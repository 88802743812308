import React from "react";
import CompanyTopPerformers from "./CompanyTopPerformers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { UseSelectedWorkspace } from "../../../hooks/company";
import { UseGroupKpis, UseTopPlayers } from "../../../hooks/kpi";
import { useDispatch } from "react-redux";
import { getPreviousKpis } from "../../../redux/kpi/actions";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

const PastLeaderboard = ({ bigMode = true }) => {
  const [monthRef, setMonthRef] = React.useState(
    moment().subtract(1, "months"),
  );
  const groupKpis = UseGroupKpis();
  const usersKpis = UseTopPlayers("month", monthRef);
  const workspace = UseSelectedWorkspace();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (monthRef) {
      dispatch(getPreviousKpis(monthRef));
    }
  }, [monthRef, groupKpis, dispatch]);

  return (
    <SoftBox className="max-w-[95vw] h-full">
      {/* Current Company Top Performers */}
      <SoftBox
        className="flex flex-col mb-4 text-center"
        sx={{ padding: { mini: 1, sm: 4 } }}
      >
        <SoftBox
          className="flex justify-between items-center mb-4"
          sx={{ flexDirection: { mini: "column", sm: "row" } }}
        >
          <SoftTypography variant="h6" className="font-bold">
            {workspace?.name?.toUpperCase()} - TOP PERFORMERS
          </SoftTypography>
          <SoftBox className="flex items-center gap-2">
            <SoftTypography
              variant="button"
              sx={{ display: { mini: "hidden", sm: "visible" } }}
            >
              Month and Year
            </SoftTypography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                openTo="month"
                views={["year", "month"]}
                value={monthRef}
                onChange={(newValue) => {
                  setMonthRef(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    sx={{ width: "10rem" }}
                  />
                )}
              />
            </LocalizationProvider>
          </SoftBox>
        </SoftBox>
        <CompanyTopPerformers
          groupKpis={groupKpis}
          usersKpis={usersKpis}
          bigMode={bigMode}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default PastLeaderboard;
