import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getChats, setSelectedChat } from "../../redux/chats/actions";
import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import "./style.css";

const MessageContainer = () => {
  const [selected, setSelected] = useState(-1);
  const [reFetchChats, setReFetchChats] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedChat, chats, isLoading } = useSelector(
    (state) => state.chatsReducer,
  );

  useEffect(() => {
    if (location.state && location.state.chatToSelect) {
      dispatch(setSelectedChat(location.state.chatToSelect));
    }
  }, []);

  const selectedHandler = (index) => {
    setSelected(index);
  };

  useEffect(() => {
    dispatch(getChats());
  }, []);

  return (
    <Box style={{ overflowY: "auto" }}>
      <div className="messsageContainer">
        <Grid container spacing={3}>
          <Grid item mini={12} md={4}>
            <div
              className={`chats ${
                selectedChat ? "display-box-none" : "display-box"
              }`}
            >
              <ChatList
                selected={selected}
                onSelected={selectedHandler}
                chats={chats}
                fetchingChats={isLoading}
              />
            </div>
          </Grid>
          <Grid item mini={12} md={8}>
            <div
              className={`chatArea  ${
                selectedChat ? "display-box" : "display-box-none"
              }`}
            >
              <ChatBox
                selectedChat={selectedChat}
                reFetchChats={reFetchChats}
                setReFetchChats={setReFetchChats}
                setSelectedChat={setSelectedChat}
                selectedHandler={selectedHandler}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default MessageContainer;
