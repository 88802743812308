export const AVATAR_URLS = [
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/DefaultMaleAvatar.glb",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/pam-white-uniform-no-logo.glb",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/oscar-white-no-logo.glb",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/darryl-white-uniform-nologo2.glb",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/creed-white-no-logo.glb",
];

export const AVATAR_THUMBNAILS = [
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/Dwight_Thumb.jpg",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/Pam_Thumb.jpg",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/Oscar_Thumb.jpg",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/Daryll_Thumb.jpg",
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/Creed_Thumb.jpg",
];