export const initState = {
  chats: [],
  messages: [],
  selectedChat: null,
  isLoading: false,
  creatingChat: false,
  creatingGroupChat: false,
  fetchingMessages: false,
  hasMoreMessages: false,
  currentMessagePage: 1,
  updatingGroup: false,
};

export const types = {
  // GET CHAT
  GET_CHATS: "GET_CHATS",
  GET_CHATS_SUCCESS: "GET_CHATS_SUCCESS",
  GET_CHATS_FAILED: "GET_CHATS_FAILED",
  // SET SELECTED CHAT
  SET_SELECTED_CHAT: "SET_SELECTED_CHAT",
  SET_SELECTED_CHAT_SUCCESS: "SET_SELECTED_CHAT_SUCCESS",
  SET_SELECTED_CHAT_FAILED: "SET_SELECTED_CHAT_SUCCESS",
  SET_CHATS: "SET_CHATS",
  // CREATE CHAT
  CREATE_CHAT: "CREATE_CHAT",
  CREATE_CHAT_SUCCESS: "CREATE_CHAT_SUCCESS",
  CREATE_CHAT_FAILED: "CREATE_CHAT_FAILED",
  // CREATE GROUP_CHAT
  CREATE_GROUP_CHAT: "CREATE_GROUP_CHAT",
  CREATE_GROUP_CHAT_SUCCESS: "CREATE_GROUP_CHAT_SUCCESS",
  CREATE_GROUP_CHAT_FAILED: "CREATE_GROUP_CHAT_FAILED",
  // MESSAGES
  ADD_CHAT_MESSAGE: "ADD_CHAT_MESSAGE",
  GET_MESSAGES: "GET_MESSAGES",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAILED: "GET_MESSAGES_FAILED",
  // SEND MESSAGES
  SEND_MESSAGE_TO_CHAT: "SEND_MESSAGE_TO_CHAT",
  SEND_MESSAGE_TO_CHAT_SUCCESS: "SEND_MESSAGE_TO_CHAT_SUCCESS",
  SEND_MESSAGE_TO_CHAT_FAILED: "SEND_MESSAGE_TO_CHAT_FAILED",
  // RENAME GROUP_CHAT
  RENAME_GROUP_CHAT: "RENAME_GROUP_CHAT",
  RENAME_GROUP_CHAT_SUCCESS: "RENAME_GROUP_CHAT_SUCCESS",
  RENAME_GROUP_CHAT_FAILED: "RENAME_GROUP_CHAT_FAILED",
  // UPDATE GROUP_USERS_CHAT
  UPDATE_GROUP_USERS_CHAT: "UPDATE_GROUP_USERS_CHAT",
  UPDATE_GROUP_USERS_CHAT_SUCCESS: "UPDATE_GROUP_USERS_CHAT_SUCCESS",
  UPDATE_GROUP_USERS_CHAT_FAILED: "UPDATE_GROUP_USERS_CHAT_FAILED",
};
