import React, { useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftInput from "../../../SoftInput";
import SoftSelect from "../../../SoftSelect";
import SoftTypography from "../../../SoftTypography";

// ChallengeArena Form Components
import FormPageLayout from "../Layout/FormPageLayout";
import FormButton from "../Layout/FormButton";
import FormSection from "../Layout/FormSection";
import FormErrorLabel from "../Layout/FormErrorLabel";
import SvgIconWrapper from "../Layout/SvgIconWrapper";
import InvitePlayers from "../Components/InvitePlayers";
import { errorOnFocusStyles } from "../styles";

// Icons
import { ReactComponent as CompanyVsCompanyIcon } from "../../../../img/createchallenge-compvscomp-dark.svg";
import { ReactComponent as CompanyVsGroupIcon } from "../../../../img/createchallenge-companygroup-dark.svg";
import { ReactComponent as EmployeeVsIcon } from "../../../../img/createchallenge-empvsemp-dark.svg";

const DetailsForm = ({ usersToInvite, setUsersToInvite }) => {
  const [isCloseInvite, setisCloseInvite] = useState(true);

  const {
    register,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const category = watch("category");

  const clearFormError = (field) => {
    if (errors?.[field]?.message) {
      clearErrors(field);
    }
  }

  const handleOnCategoryClick = (e) => {
    const { id: selectedCategory } = e.target;
    setValue("category", selectedCategory);
    clearFormError("category");

    e.preventDefault();
    e.target.blur(); 
  }

  const PRIVACY_OPTIONS = [
    { value: false, label: "Public" },
    { value: true, label: "Private" }
  ]

  const CHALLENGE_CATEGORIES = {
    COMPANY_VS: "CompanyVsCompany",
    EMPLOYEE_VS: "EmployeeVsEmployee",
    COMPANY_GROUPS: "CompanyGroups"
  }

  const activeCategory = useMemo(() => {
    return (!category)
      ? getValues("category")
      : category
  }, [category, getValues]);

  const handleCloseInvite = () => {
    setisCloseInvite(crypto?.randomUUID());
  }

  return (
    <FormPageLayout sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
      {/** Challenge Title */}
      <FormSection sx={errorOnFocusStyles(errors?.title)}>
        <SoftTypography variant="body2">
           Challenge Title
        </SoftTypography>
        <SoftInput
          placeholder="Challenge title"
          type="text"
          id="title"
          name="title"
          autoComplete="off"
          {...register("title")}
          error={!!errors?.title}
          helperText={errors?.title?.message}
          onChange={() => {
            clearFormError("title");
          }}
        />
      </FormSection>

      {/** Challenge Category */}
      <SoftBox>
        <SoftTypography variant="body2">
          Select Challenge Category
        </SoftTypography>

        <Grid container spacing={3}
          sx={{
            '& .MuiGrid-item': {
              width: "100%"
            },
            '& button': {
              flexDirection: {
                xl: "row",
                lg: "row",
                sm: "column",
                xs: "row"
              },
              "& .MuiButton-startIcon": {
                marginRight: {
                  xl: "8px",
                  lg: "8px",
                  sm: "0px",
                  xs: "8px"
                }
              }
            }
          }}
        >
          <Grid item xs={12} sm={4}>
            <FormButton
              id={CHALLENGE_CATEGORIES.COMPANY_VS}
              onClick={handleOnCategoryClick}
              startIcon={SvgIconWrapper(CompanyVsCompanyIcon, "24px", "24px")}
              sx={{
                backgroundColor: (activeCategory === CHALLENGE_CATEGORIES.COMPANY_VS)
                  ? "#ced4da"
                  : "none"
              }}>
              Company vs Company Challenge
            </FormButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormButton
              id={CHALLENGE_CATEGORIES.COMPANY_GROUPS}
              onClick={handleOnCategoryClick}
              startIcon={SvgIconWrapper(CompanyVsGroupIcon, "24px", "24px")}
              sx={{
                backgroundColor: (activeCategory === CHALLENGE_CATEGORIES.COMPANY_GROUPS)
                  ? "#ced4da"
                  : "none"
              }}>
              Company Group Challenge
            </FormButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormButton
              id={CHALLENGE_CATEGORIES.EMPLOYEE_VS}
              onClick={handleOnCategoryClick}
              startIcon={SvgIconWrapper(EmployeeVsIcon, "24px", "24px")}
              sx={{
                backgroundColor: (activeCategory === CHALLENGE_CATEGORIES.EMPLOYEE_VS)
                  ? "#ced4da"
                  : "none"
              }}>
              Employee 1 vs 1 Challenge
            </FormButton>
          </Grid>
        </Grid>

        {(!!errors?.category) &&
          <FormErrorLabel>
            Please select a category
          </FormErrorLabel>
        }
      </SoftBox>

      {/** Invite Players */}
      <Collapse in={activeCategory === CHALLENGE_CATEGORIES.EMPLOYEE_VS}>
        <FormSection
          sx={{
            width: "100%",
            padding: "1rem",
            borderRadius: "4px",
            border: "1px solid #CED4DA"
           }}
        >
          <InvitePlayers
            usersToInvite={usersToInvite}
            handleInviteUsers={setUsersToInvite}
            isClose={isCloseInvite}
          />
        </FormSection>
      </Collapse>

      {/** Start/End Date */}
      <FormSection>
        <Grid container spacing={3}
          sx={{
            '& .MuiGrid-item': {
              width: "100%"
            },
          }}
        >
          <Grid item xs={12} sm={3}>
            <SoftTypography variant="body2">
              Start Date
            </SoftTypography>
            <SoftInput
              placeholder="Start date"
              type="date"
              name="startDate"
              {...register("startDate")}
              error={!!errors?.startDate}
              helperText={errors?.startDate?.message}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              onFocus={handleCloseInvite}
              onChange={(e) => {
                setValue("endDate", null, {
                  shouldValidate: true
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3} sx={errorOnFocusStyles(errors?.endDate)}>
            <SoftTypography variant="body2">
              End Date
            </SoftTypography>
            <SoftInput
              placeholder="End date"
              type="date"
              name="endDate"
              {...register("endDate")}
              error={!!errors?.endDate}
              helperText={errors?.endDate?.message}
              inputProps={{
                min: new Date(
                  new Date(getValues("startDate")).getTime() +
                    24 * 60 * 60 * 1000,
                )
                  .toISOString()
                  .split("T")[0]
              }}
              onFocus={handleCloseInvite}
              onChange={() => clearFormError("endDate")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftTypography variant="body2">
              Select Privacy
            </SoftTypography>
            <SoftSelect
              name="isPrivate"
              id="challenge"
              size="large"
              options={PRIVACY_OPTIONS}
              {...register("isPrivate", { required: true })}
              error={!!errors?.isPrivate}
              placeholder="Select privacy"
              defaultValue={(getValues("isPrivate") ?? null)}
              onFocus={() => {
                handleCloseInvite();
                if (errors?.isPrivate) {
                  clearFormError("isPrivate");
                }
              }}
              onChange={(option) => {
                setValue("isPrivate", option);
                clearFormError("isPrivate");
              }}
            />
              {(!!errors?.isPrivate) &&
              <FormErrorLabel>
                Please select a value
              </FormErrorLabel>
             }
          </Grid>
        </Grid>
      </FormSection>
    </FormPageLayout>
  )
};

export default DetailsForm;
