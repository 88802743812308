import React, { forwardRef, useImperativeHandle } from "react";

const Geolocation = forwardRef(({ onLocated }, ref) => {

  // Function to get the user's current location
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          onLocated?.({lat: position.coords.latitude, lng: position.coords.longitude});
        },
        (locationError) => {
          onLocated?.({error: locationError.message});
        }
      );
    } else {
      onLocated?.({error: 'Geolocation is not supported by this browser.'});
    }
  };

  useImperativeHandle(ref, () => ({
    async activate() {
      getUserLocation();
    },
  }));


  return null;
});

export default Geolocation;