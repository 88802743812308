
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOutUser } from "../../redux/action";

const Signout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(signOutUser());
    navigate("/login", { replace: true });
  }, [dispatch, navigate]);

  return <div />;
};

export default Signout;