import React, { useState, useRef, useMemo, useEffect } from "react";
import { Dialog, CircularProgress, Divider, Grid, Switch } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import {
  GET_URL_METADATA,
  PUT_CUSTOM_LOGO,
  PUT_HUB_ITEM,
  DELETE_HUB_ITEM,
} from "../../api/hub";
import { UseUserId } from "../../hooks/auth";
import { UseSelectedWorkspace, UseUsersFullData } from "../../hooks/company";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ConfirmationModal from "../Modals/ConfirmationModal";
import toast from "react-hot-toast";
import SoftInput from "../SoftInput";
import SoftBox from "../SoftBox";
import SoftSelect from "../SoftSelect";
import SoftButton from "../SoftButton";
import SoftProfile from "../ProfilePictures/SoftProfile";

// Imported Images and Icons
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddLinkIcon from "@mui/icons-material/AddLink";
import SoftTypography from "../SoftTypography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import BlankHub from "../../img/random.png";
import { GET_USER_BY_GROUPID } from "../../api/user";
import SoftDialog from "../SoftConfirmationDialog";

const AddHubLink = ({
  isOpen,
  isEdit,
  onClose,
  openDialog,
  data,
  refetch,
  setDisplayTitle,
  setDisplayWeb,
}) => {
  const [url, setUrl] = useState("");
  const [logo, setLogo] = useState("");
  const [title, setTitle] = useState("");
  const [website, setWebsite] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [type, setType] = useState(undefined);
  const [desc, setDesc] = useState("");
  const [advance, setAdvance] = useState("");
  const [customImage, setCustomImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [forUpload, setForUpload] = useState(false);
  const [dialog, setDialog] = useState(false);

  const workspace = UseSelectedWorkspace();
  const userId = UseUserId();
  const imageInputRef = useRef();
  const appTheme = useTheme();
  const matches = useMediaQuery(appTheme.breakpoints.up(640));
  const verySmallBp = useMediaQuery("(max-width:350px)");
  const usersData = UseUsersFullData();

  const {
    primary: { main: primaryColor },
  } = appTheme.palette;

  const handleClose = () => {
    setIsSaving(false);
    setType(undefined);
    setUrl("");
    setTitle("");
    setLogo("");
    setForUpload(false);
    setCustomImage(null);
    setDesc("");
    setAdvance("");
    onClose();
  };

  const fetchHandler = useMemo(
    () =>
      debounce((urlText) => {
        if (urlText) {
          fetchHandler?.cancel();
          setIsLoading(true);
          setType(undefined);
          GET_URL_METADATA(urlText)
            .then(({ data: urlData }) => {
              const siteTitle = startCase(
                (urlData.type || urlData.title)?.trim(),
              );
              setWebsite(urlData.url);
              setLogo(urlData.logo);
              setTitle(siteTitle);
              setType(urlData.type);
              setDesc(urlData.desc);
              setIsLoading(false);
            })
            .catch((_) => {
              setWebsite(null);
              setLogo(null);
              setType(undefined);
              setDesc(null);
              setIsLoading(false);
              toast.error("No data found for URL  or keyword.");
            });
        }
      }, 1000),
    [],
  );

  const handleInputChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const imgSrc = e.target.files[0];
    if (imgSrc) {
      setCustomImage(imgSrc);
      setForUpload(true);
    }
  };

  const handleSave = async () => {
    try {
      if (url) {
        setIsSaving(true);
        let customImageKey = customImage;
        if (forUpload && customImage) {
          const formData = new FormData();
          formData.append("image", customImage);
          customImageKey = await PUT_CUSTOM_LOGO(formData);
        }

        await PUT_HUB_ITEM(
          data?._id, // For updating existing records; null if new
          userId,
          workspace._id,
          website,
          type,
          title,
          customImageKey?.data || logo,
          desc,
          selectedUsers,
        );
        setDisplayTitle(title);
        setDisplayWeb(website);
        refetch?.();
        setSelectedUsers([]);
        if (isEdit) {
          toast.success(`URL has been updated.`);
        } else {
          openDialog();
        }
        handleClose();
      }
    } catch (saveErr) {
      const errMsg = saveErr?.response?.data?.error.message || saveErr.message;
      toast.error(errMsg);
      handleClose();
    }
  };

  console.log(advance);

  const handleDelete = async () => {
    try {
      if (data._id) {
        setIsSaving(true);
        await DELETE_HUB_ITEM(data._id);
        toast.success("URL was successfully deleted");
        refetch?.();
        handleClose();
      }
    } catch (_) {
      setIsSaving(false);
      toast.error("URL was not deleted");
    }
  };

  const fetchUsersByGroupId = async () => {
    setIsFetching(true);
    try {
      const filteredUsers = usersData
        .filter((user) => user.groupId === workspace._id)
        .map((item) => {
          const username = `${item.firstName} ${item.lastName}`;

          return {
            ...item,
            _id: item.userId,
            value: item.userId,
            label: username,
          };
        });

      setUsers(filteredUsers);
    } catch (error) {
      console.log(error.response);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (data.url) {
      setUrl(data.url);
      setTitle(data.title);
      setWebsite(data.website || data.url);
      setType(data.type?.source);
      setDesc(data.desc);
      setCustomImage(data.logo || data.type?.imageSrc);
    }
  }, [data]);

  useEffect(() => {
    fetchUsersByGroupId();
  }, [usersData]);

  const getDefaultValue = () => {
    if (!data && !data.url) return null;
    if (!data.assignedTo) return null;

    return data.assignedTo.map((item) => {
      const username = `${item.firstName} ${item.lastName}`;
      
      return {
        ...item,
        value: item._id,
        label: username,
      };
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "8px",
          background:
            " linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            " -12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(80px)",
          border: " 1px solid rgba(0, 0, 0, 0.10)",
          maxWidth: "800px",
          width: "100%",
          WebkitBackdropFilter: "blur(15px)",
          overflowY: "visible",
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          background: "rgba(0, 0, 0, 0.2)",
        },
        "& .MuiPaper-root": {
          margin: { mini: "20px", xxs: "32px" },
        },
      }}
    >
      <SoftBox
        sx={{
          padding: { mini: "0.75rem", xxs: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <SoftBox sx={{ textAlign: "center" }}>
          <SoftTypography
            variant={verySmallBp ? "h5" : "h4"}
            sx={{ color: "#CED4DA" }}
          >
            {isEdit ? "Edit" : "Add"} Custom Link
          </SoftTypography>
          <SoftBox
            sx={{
              position: "absolute",
              top: { mini: "0.75rem", xxs: "2.5rem" },
              right: { mini: "0.75rem", xxs: "2.5rem" },
            }}
          >
            <SoftButton
              sx={{
                minWidth: "max-content",
                minHeight: "max-content",
                background: "transparent",
                border: "0.529px solid #CED4DA",
                padding: verySmallBp ? "0.15rem" : "0.3rem",
                borderRadius: "50%",
                "&:hover": {
                  background: "transparent",
                },
                "&:focus:not(:hover)": {
                  background: "transparent",
                },
                "& svg": {
                  fontSize: verySmallBp
                    ? "1rem !important"
                    : "1.25rem !important",
                },
              }}
              onClick={handleClose}
            >
              <CloseRoundedIcon sx={{ color: "#CED4DA" }} />
            </SoftButton>
          </SoftBox>
        </SoftBox>

        <Divider width="100%" sx={{ marginY: "1.5rem" }} />

        <Grid container spacing={2}>
          <Grid item mini={12} xs={12} md={6}>
            <SoftBox
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {isLoading ? (
                <CircularProgress
                  size="2rem"
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.25rem",
                  }}
                />
              ) : null}
              <SoftInput
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="Title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                sx={{
                  borderRadius: "10px",
                  outline: "none !important",
                  backgroundColor: "transparent !important",
                  color: "#ffffffb3 !important",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  "&.Mui-focused": {
                    borderColor: primaryColor,
                    boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                  },
                }}
              />
                            <SoftInput
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="URL"
                placeholder="URL"
                onChange={(e) => {
                  const urlText = e.target.value.trim();
                  setUrl(urlText);
                  fetchHandler(urlText);

                  if (e.target.value.trim() === "") {
                    setTitle("");
                  }
                }}
                value={url}
                sx={{
                  borderRadius: "10px",
                  outline: "none !important",
                  backgroundColor: "transparent !important",
                  color: "#ffffffb3 !important",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  "&.Mui-focused": {
                    borderColor: primaryColor,
                    boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                  },
                }}
              />
              <SoftBox>
                <SoftTypography
                  variant="body2"
                  sx={{ color: "#CED4DA !important", marginY: "5px" }}
                >
                  Note | Description:
                </SoftTypography>
                <SoftInput
                  placeholder="Type your description here ..."
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                  value={desc}
                  sx={{
                    borderRadius: "10px",
                    outline: "none !important",
                    backgroundColor: "transparent !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </SoftBox>
              <SoftBox display="flex" alignItems="flex-start" gap={2}>
                  <SoftBox>
                    <Switch
                      onChange={(e) => setAdvance(e.target.checked)}
                    />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" gap={1}>
                    <SoftTypography
                      variant="h4"
                      sx={{
                        color: "#CED4DA",
                        fontSize: "15px",
                        fontWeight: 500,
                      }}
                    >
                      Advanced Settings
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                
              {advance && (<SoftSelect
                name="isType"
                id="category"
                className="custom-select-catgory"
                options={users}
                isMulti
                onChange={(option) => {
                  const list = [...option];
                  setSelectedUsers(list);
                }}
                isLoading={fetching}
                defaultValue={getDefaultValue()}
                placeholder="Select player"
                styles={{
                  color: "white !important",
                  placeholder: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  input: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  singleValue: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  control: {
                    height: "100%",
                    minWidth: "2.5rem",
                  },
                  valueContainer: {
                    flexFlow: "row wrap",
                    gap: "0.25rem",
                    padding: "0.5rem 0.75rem",
                  },
                  indicatorsContainer: {
                    alignItems: "start",
                  },
                }}
              />)}
            </SoftBox>
          </Grid>
          <Grid item mini={12} xs={12} md={6}>
            <SoftBox
              sx={{
                gridArea: "logo",
                height: "100%",
                borderRadius: "16px",
                border: "1px solid rgba(73, 80, 87, 0.50)",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                padding: "1.5rem",
              }}
            >
              <SoftBox
                sx={{
                  width: { mini: "200px", xs: "240px" },
                  height: "200px",
                  background: "rgba(0, 0, 0, 0.02)",
                  borderRadius: "26px",
                  display: "flex",
                  flexDirection: "column",
                  gap: isEdit ? "10px" : "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {customImage || logo ? (
                  <SoftProfile src={customImage ?? logo} size="xxl" />
                ) : (
                  <SoftTypography
                    variant="h1"
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {(type || title || url)?.[0]?.toUpperCase()}
                  </SoftTypography>
                )}

                {!url && (
                  <SoftBox
                    sx={{
                      padding: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #CED4DA",
                      borderRadius: "18.25px",
                    }}
                  >
                    <FileUploadRoundedIcon
                      sx={{
                        fontSize: "4rem !important",
                        color: "#CED4DA !important",
                      }}
                    />
                  </SoftBox>
                )}

                <SoftBox>
                  <SoftButton
                    variant="outlined"
                    size={matches ? "medium" : "small"}
                    sx={{
                      minWidth: "max-content",
                      minHeight: "max-content",
                      padding: "0.5rem 1rem",
                      borderRadius: "9px",
                      color: "#CED4DA",
                      borderColor: "#CED4DA",
                      background: "transparent",
                      textTransform: "none",
                      "& svg": {
                        fill: "gray",
                      },
                      "&:hover": {
                        background: "transparent",
                      },
                      "&:focus:not(:hover)": {
                        background: "transparent",
                      },
                    }}
                    endIcon={
                      <FileUploadRoundedIcon
                        sx={{
                          fontSize: "1rem !important",
                          fill: "#CED4DA !important",
                        }}
                      />
                    }
                    onClick={() => imageInputRef.current.click()}
                    disabled={isLoading || isSaving}
                  >
                    Upload custom logo
                  </SoftButton>
                  <input
                    ref={imageInputRef}
                    onInput={handleInputChange}
                    accept="image/png, image/gif, image/jpeg"
                    type="file"
                    hidden
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>

        <Divider width="100%" sx={{ marginY: "1.5rem" }} />

        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {!isEdit && (
            <SoftButton
              variant="outlined"
              size="medium"
              onClick={handleClose}
              sx={{
                textTransform: "none",
                color: "#CED4DA",
                borderColor: "#CED4DA",
                background: "transparent",
                "& svg": {
                  fill: "gray",
                },
                "&:hover": {
                  background: "transparent",
                  borderColor: "#CED4DA",
                },
                "&:focus:not(:hover)": {
                  background: "transparent",
                  borderColor: "#CED4DA",
                },
              }}
            >
              Cancel
            </SoftButton>
          )}
          {isEdit && (
            <ConfirmationModal
              title="Delete Hub Item"
              question={`Are you sure you like to delete this URL?\n${url}`}
              yesText="Yes, delete."
              noText="No, cancel"
              onSuccess={handleDelete}
            >
              <SoftButton
                variant="outlined"
                size="medium"
                startIcon={
                  isSaving ? <CircularProgress size={24} color="error" /> : null
                }
                sx={{
                  textTransform: "none",
                  color: "#CED4DA",
                  borderColor: "#CED4DA",
                  background: "transparent",
                  "& svg": {
                    fill: "gray",
                  },
                  "&:hover": {
                    background: "transparent",
                  },
                  "&:focus:not(:hover)": {
                    background: "transparent",
                  },
                }}
              >
                Delete link
              </SoftButton>
            </ConfirmationModal>
          )}
          <SoftButton
            color="primary"
            variant="gradient"
            size="medium"
            sx={{ textTransform: "none", color: "white" }}
            endIcon={
              isSaving ? <CircularProgress size={24} color="inherit" /> : null
            }
            onClick={handleSave}
            disabled={type === undefined}
          >
            {isEdit ? "Update" : "Create"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Dialog>
  );
};

export default AddHubLink;
