import { types } from "./types";

export const refreshKpis = (payload) => ({
  type: types.REFRESH_KPIS,
  payload,
});

// Pass month to get historic data, flag refresh to true to force fetching of new historic data (for when old KPI records are updated)
export const getPreviousKpis = (month, refresh = false) => ({
  type: types.GET_PREVIOUS_KPIS_REQUEST,
  month,
  refresh,
});

export const setToLiveKpis = () => ({
  type: types.SET_TO_LIVE_KPIS,
});

export const addKpiRecord = (kpiId, userId, month, data) => ({
  type: types.ADD_USER_KPI_REQUEST,
  kpiId,
  userId,
  data,
  month,
});

export const updateKpiRecord = (id, kpiId, amount) => ({
  type: types.UPDATE_USER_KPI_REQUEST,
  id,
  kpiId,
  amount,
});

export const deleteKpiRecord = (kpiId) => ({
  type: types.DELETE_USER_KPI_REQUEST,
  kpiId,
});

export const updateWorkspaceKpi = (groupKpi) => ({
  type: types.UPDATE_GROUP_KPI_REQUEST,
  groupKpi,
});

export const createCustomWorkspaceKpi = (groupKpi) => ({
  type: types.CREATE_CUSTOM_GROUP_KPI_REQUEST,
  groupKpi,
});

export const disableWorkspaceKpi = (id) => ({
  type: types.DISABLE_GROUP_KPI_REQUEST,
  id,
});

export const configureIntegration = (integration) => ({
  type: types.ADD_INTEGRATION_REQUEST,
  integration,
});
