import React, { useState } from "react";
import { Dialog, Grid } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReactPlayer from "react-player/youtube";

import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";
import SoftButton from "../../../../SoftButton";
import ExampleDialogGradient from "../../../../../img/example-dialog-gradient.svg";

const ExampleOfUseDialog = ({ metric }) => {
  const [exampleDialog, setExampleDialog] = useState(false);

  const openExampleDialog = () => setExampleDialog(true);
  const closeExampleDialog = () => setExampleDialog(false);

  return (
    <>
      <SoftButton
        variant="text"
        sx={{
          textTransform: "none",
          color: "primary.main",
          fontWeight: 500,
          "&:hover": {
            color: "primary.main",
            textDecoration: "underline",
          },
        }}
        onClick={openExampleDialog}
      >
        See more
      </SoftButton>
      {exampleDialog && (
        <Dialog
          open={exampleDialog}
          onClose={closeExampleDialog}
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              height: "fit-content",
              overflowY: "hidden",
              backgroundImage: `url(${ExampleDialogGradient})`,
            },
          }}
        >
          <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
            <Grid container spacing={2}>
              <Grid item mini={12}>
                <SoftBox display="flex" justifyContent="space-between">
                  <SoftBox>
                    <SoftTypography
                      variant="h4"
                      sx={{
                        color: "#000",
                        fontWeight: 700,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      }}
                    >
                      Example of use
                    </SoftTypography>
                    <SoftTypography
                      variant="p"
                      sx={{
                        color: "#475467",
                        fontWeight: 500,
                        fontSize: "16px",
                        fontFamily: "DM Sans",
                      }}
                    >
                      Here are some additional examples of use for{" "}
                      {metric.metricTitle}
                    </SoftTypography>
                  </SoftBox>
                  <SoftButton
                    variant="outlined"
                    color="black"
                    onClick={closeExampleDialog}
                    sx={{
                      "& svg": { fontSize: "1.25rem !important" },
                      borderRadius: "50%",
                      minWidth: "min-content",
                      minHeight: "fit-content",
                      height: "fit-content",
                      padding: "0.25rem",
                    }}
                  >
                    <CloseRoundedIcon />
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item mini={8}>
                <ol style={{ listStyleType: "auto", padding: "0rem 1rem" }}>
                  {metric.steps.map((step) => (
                    <li key={step} style={{ marginBottom: "20px" }}>
                      <SoftTypography
                        variant="p"
                        sx={{
                          color: "#344054",
                          fontWeight: 500,
                          fontSize: "16px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {step}
                      </SoftTypography>
                    </li>
                  ))}
                </ol>
              </Grid>
              <Grid item mini={4} sx={{ width: "100%" }}>
                <SoftBox sx={{ width: "100%", height: "200px" }}>
                  <ReactPlayer
                    url={metric.videoUrl}
                    width="100%"
                    height="100%"
                  />
                </SoftBox>
                <SoftBox
                  sx={{
                    marginTop: "5px",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  <SoftTypography
                    variant="p"
                    sx={{
                      color: "#344054",
                      fontWeight: 500,
                      fontSize: "12px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Here is a short video guide for seamless creation of{" "}
                    {metric.metricTitle}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Dialog>
      )}
    </>
  );
};

export default ExampleOfUseDialog;
