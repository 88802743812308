import React from "react";
import { Dialog, Divider } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";

import SoftBox from "./components/SoftBox";
import SoftTypography from "./components/SoftTypography";
import SoftButton from "./components/SoftButton";
import GMBLogo from "./img/gmb-installer.png";
import ShareGif from "./img/ca-share-link.gif";
import ArrowGif from "./img/ca-arrow.gif";

import useIosInstallPrompt from "./hooks/useIosInstallPrompt";
import useWebInstallPrompt from "./hooks/useWebInstallPrompt";

const AppInstaller = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  console.log("isMobile", isMobile);

  if ((!iosInstallPrompt && !webInstallPrompt) || !isMobile) {
    return null;
  }

  return (
    <div className="app-installer">
      <Dialog
        open={true}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
            background:
              "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
            boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
            backdropFilter: "blur(18px)",
          },
        }}
      >
        <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
          <SoftTypography
            component="h3"
            sx={{ color: "#FFF", fontSize: "20px !important" }}
          >
            Add to Home Screen
          </SoftTypography>
          <Divider
            sx={{
              margin: 0,
              backgroundImage:
                "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
            }}
          />
          {webInstallPrompt && (
            <>
              <SoftBox display="flex" flexDirection="column" mb="20px">
                <SoftTypography
                  component="h3"
                  sx={{
                    color: "#FFF",
                    fontSize: "20px !important",
                    marginTop: "20px",
                  }}
                >
                  GameMybiz
                </SoftTypography>
                <SoftTypography
                  component="p"
                  sx={{
                    color: "#FFF",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "400",
                    marginBottom: "20px",
                  }}
                >
                  Install the app on your device to easily access it anytime.
                </SoftTypography>
                <SoftBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    background: "rgba(255, 255, 255, 0.02)",
                    border: "1px dashed rgba(255, 255, 255, 0.10)",
                    borderRadius: "12px",
                  }}
                >
                  <img src={GMBLogo} alt="" className="w-[100%] max-w-[50px]" />
                  <img
                    src={ArrowGif}
                    alt=""
                    className="w-[100%] max-w-[50px]"
                  />
                  <img
                    src={ShareGif}
                    alt=""
                    className="w-[100%] max-w-[50px]"
                  />
                </SoftBox>
              </SoftBox>
              <Divider
                sx={{
                  margin: 0,
                  backgroundImage:
                    "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
                }}
              />
              <SoftBox mt="20px" display="flex">
                <SoftButton
                  fullWidth
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                  onClick={handleWebInstallDeclined}
                >
                  Not now
                </SoftButton>
                <SoftButton
                  fullWidth
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(120deg, #6675D3 0%, #141727 100%)",
                    color: "#FFFFFF",
                  }}
                  onClick={handleWebInstallAccepted}
                >
                  Install
                </SoftButton>
              </SoftBox>
            </>
          )}

          {iosInstallPrompt && (
            <SoftBox display="flex" flexDirection="column" mt="20px">
              <SoftTypography
                component="h3"
                sx={{
                  color: "#FFF",
                  fontSize: "20px !important",
                }}
              >
                GameMybiz
              </SoftTypography>
              <SoftTypography
                component="p"
                sx={{
                  color: "#FFF",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  marginBottom: "20px",
                }}
              >
                Install the app on your device to easily access it anytime.
                <br />
                Tap <IosShareIcon /> then "Add to Home Screen"
              </SoftTypography>
              <SoftBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                  background: "rgba(255, 255, 255, 0.02)",
                  border: "1px dashed rgba(255, 255, 255, 0.10)",
                  borderRadius: "12px",
                }}
              >
                <img src={GMBLogo} alt="" className="w-[100%] max-w-[50px]" />
                <img src={ArrowGif} alt="" className="w-[100%] max-w-[50px]" />
                <img src={ShareGif} alt="" className="w-[100%] max-w-[50px]" />
              </SoftBox>
              <Divider
                sx={{
                  margin: 0,
                  backgroundImage:
                    "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
                }}
              />
              <SoftBox mt="20px" display="flex">
                <SoftButton
                  fullWidth
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                  onClick={handleIOSInstallDeclined}
                >
                  Not now
                </SoftButton>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </Dialog>
    </div>
  );
};

export default AppInstaller;
