const currencyFormatter = ({decimals}) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: decimals,
});

const percentageFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  maximumFractionDigits: 0,
});

export const formatCurrency = (value, decimals = 0) => currencyFormatter({decimals}).format(value);
export const formatPercent = (value) => percentageFormatter.format(value);
export const formatNumber = (value, kind) => {
  if(kind === '$') {
    return formatCurrency(value);
  } else if(kind === '%') {
    return formatPercent(value);
  }
  return Math.round(value*100)/100;
}
export const formatForUrl = (value) => value? value.trim().toLowerCase().replace(/[\s]+/g, "-") : "";