import React, { useState, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Modal, Grid, Backdrop, Fade, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from "../../../components/SoftTypography";
import SoftBox from "../../../components/SoftBox";
import SoftInput from "../../../components/SoftInput";
import SoftButton from "../../../components/SoftButton";

import PageHeader from "../../../components/PageHeader";
import ProfilePicture from "./ProfilePicture";
import IntegratedAccounts from "./IntegratedAccounts";

import AccountSection from "./layout/AccountSection";
import AccountButton from "./components/AccountButton";
import AccountDivider from "./components/AccountDivider";
import AccountPassword from "./components/AccountPassword";

import { CANCEL_SUBSCRIPTION } from "../../../api/subscription";


// Icons
import LogoutIcon from "@mui/icons-material/Logout";
import { borderRadius, fontSize, fontWeight, maxWidth, textAlign } from "@mui/system";
import boxShadow from "../../../assets/theme/functions/boxShadow";
import { Span } from "@sentry/tracing";
import { check } from "prettier";

const AccountSettingsV2 = ({
  userDetails,
  isLoading,
  isAdmin,
  fileSelectedCallback,
  handleFormSubmit,
  handleLogout,
  handleSendResetPassword,
  UseUserId
}) => {

  const [cancelForm, setShowcancelForm] = useState(true);
  const [supportContent, setSupport] = useState(false);

  const handleBtnSupport = () => {
    setSupport(true);
    setShowcancelForm(false);
  };

  const [selected, setSelected] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleChange = (event) => {
    const { value, checked } = event.target;
    setCheckboxValue(event.target.value);
    if (checked && value === 'Other') {
      setSelected(event.target.name);
      setShowInput(true);
    } else {
      setSelected(event.target.name);
      setShowInput(false);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [confirmation, setConfirmation] = useState(true);

  const [cancellationDone, setconfirmCancellation] = useState(false);

  const handleBtnConfirmation = () => {
    setConfirmation(false);
  };
  
  const handleCancelForm = () => {
    setOpenModal(true);
  };
  const formRef = useRef(null);

  const confirmCancellation = async () => {
    setconfirmCancellation(true);
    if (formRef.current) {
      const body = {
        userId: UseUserId,
        status: 'Cancellation Request',
        reasonForCancellation: checkboxValue,
      };
      await CANCEL_SUBSCRIPTION(body);
    }
  };

  const handleCloseModal = () => {
    setconfirmCancellation(false);
    setSupport(false);
    setOpenModal(false);
    setConfirmation(true);
    setShowcancelForm(true);
  };

  const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '6px',
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    backdropFilter: 'blur(24px)',
    boxShadow: '10.3553px 10.3553px 20.7106px rgba(0, 0, 0, 0.2), inset -10.3553px -10.3553px 17.2589px rgba(0, 0, 0, 0.2)',
    border: '0.847154px solid #98A2B3'
  };

  const {
    register,
    formState: { errors }
  } = useFormContext();

  const inputFieldStyles = {
    border: "1px solid #CED4DA",
    borderRadius: "8px",
    boxShadow: "0px 2px 3px 0px #0000000D",
    padding: "12px 20px",
    backgroundColor: "#F9FAFB !important",
    "& input": {
      fontFamily: "DM Sans",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "14px",
      color: "#495057"
    },
  };

  const commonTitleStyles = {
    fontFamily: "DM Sans",
    fontWeight: 500
  }

  return (
    <>
      <PageHeader iconKey="accountsettings" headerText="Account Settings" />

      <SoftBox
        sx={{
          margin: { mini: "1rem", sm: "1.5rem" },
          backgroundColor: "#FFF",
          borderRadius: "8px",
          minHeight: "756px",
          padding: (theme) => theme.spacing(3),
          "& h3": { // titles
            ...commonTitleStyles,
            fontSize: "20px",
            lineHeight: "30px",
            color: "#000"
          },
          "& h4": {
            ...commonTitleStyles,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#667085",
            marginBottom: "8px",
          },
          "& h5": { // subtitles
            ...commonTitleStyles,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#344054"
          },
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <SoftBox sx={{ maxWidth: "1380px" }}>
            <SoftBox sx={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <SoftTypography variant="h3">
                My Account Information
              </SoftTypography>
              <AccountButton
                variant="outline"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "13px",
                  lineHeight: "16px",
                  border: "1px solid #CED4DA",
                  width: "110px",
                  borderRadius: "4px",
                  color: "#E9ECEF",
                  backgroundColor: (theme) => `${theme.palette.secondary.light} !important`
                }}
              >
                Update
              </AccountButton>
            </SoftBox>

            {/** Profile Picture */}
            <ProfilePicture
              fileSelectedCallback={fileSelectedCallback}
              disabled={isLoading}
              userDetails={userDetails}
            />

            <AccountDivider />

            {/** Name Section */}
            <AccountSection
              title="Full Name"
              subtitle="Edit/View the name on your account"
            >
              <Grid container spacing={3}>
                <Grid item mini={12} sm={6}>
                  <SoftTypography variant="h4">
                    First Name
                  </SoftTypography>
                  <SoftInput
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    autoComplete="off"
                    sx={inputFieldStyles}
                    disabled={isLoading}
                    {...register("firstName")}
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item mini={12} sm={6}>
                  <SoftTypography variant="h4">
                    Last Name
                  </SoftTypography>
                  <SoftInput
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    autoComplete="off"
                    sx={inputFieldStyles}
                    disabled={isLoading}
                    {...register("lastName")}
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />
                </Grid>
              </Grid>
            </AccountSection>

            <AccountDivider />

            {/** Password Section */}
            <AccountSection
              title="Password"
              subtitle="Change your password by simply entering the old and New password"
            >
              <Grid container spacing={3}>
                <AccountPassword
                  label="Current Password"
                  name="oldPassword"
                  disabled={isLoading}
                  handleRegister={register}
                  errors={errors}
                />

                <AccountPassword
                  label="New Password"
                  name="newPassword"
                  disabled={isLoading}
                  handleRegister={register}
                  errors={errors}
                />

                <AccountPassword
                  label="Confirm Password"
                  name="confirmNewPassword"
                  disabled={isLoading}
                  handleRegister={register}
                  errors={errors}
                />
              </Grid>

              <SoftBox sx={{ marginTop: "16px" }}>
                <SoftTypography
                  variant="h5"
                  sx={{
                    fontSize: "14px !important",
                    lineHeight: "18px !important",
                    color: "#000 !important"
                  }}
                  >
                  Can’t Remember Your current Password?
                </SoftTypography>
                <AccountButton
                  variant="text"
                  component="h4"
                  disabled={isLoading}
                  onClick={handleSendResetPassword}
                  sx={{
                    color: "#7928CA !important",
                    textTransform: "capitalize",
                    padding: "6px 0 6px 0",
                    border: "none"
                  }}
                >
                  Reset Password
                </AccountButton>
              </SoftBox>
            </AccountSection>

            <AccountDivider />

            {/** App Release Version Number */}
            <AccountSection
              title="App Version"
              subtitle="GameMyBiz App Release Number"
            >
              {(process.env.REACT_APP_VERSION)
                ? <SoftTypography variant="h4">
                    {process.env.REACT_APP_VERSION}
                  </SoftTypography>
                : <SoftTypography variant="caption" sx={{ fontStyle: "italic" }}>
                    (Available only in the live app)
                  </SoftTypography>
              }
            </AccountSection>

            <AccountDivider />

            {/** Integrated Accounts section */}
            {(isAdmin) &&
              <AccountSection
                title="Integrated Accounts"
                subtitle="Manage Your current integrated accounts"
              >
                <IntegratedAccounts />

                <AccountDivider />
              </AccountSection>
            }

            {/** Account Email, Logout section */}
            <SoftBox sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "64px",
              gap: "48px",
              flexDirection: {
                mini: "column",
                xs: "row"
              },
            }}>
              <div>
                <SoftTypography variant="h3">
                  Account Signed In
                </SoftTypography>

                <SoftTypography variant="h5">
                  {userDetails?.email}
                </SoftTypography>
              </div>

              <AccountButton
                variant="outline"
                disabled={isLoading}
                onClick={handleLogout}
                sx={{
                  fontSize: "16px !important",
                  lineHeight: "24px",
                  height: "40px",
                  color: "#667085",
                  padding: "8px 21px",
                  border: "1px solid #EAECF0",
                  "& svg": {
                    marginRight: "4px"
                  }
                }}
              >
                <LogoutIcon width={24} />
                Log Out
              </AccountButton>
            </SoftBox>

            <AccountDivider />

            {/** Account Email, Logout section */}
            <SoftBox sx={{
                    marginBottom: "64px",
                    gap: "48px",
                    flexDirection: {
                      mini: "column",
                      xs: "row"
                    },
                  }}>
              <div>
                <SoftTypography variant="h3">
                  Request Account Cancellation
                </SoftTypography>

                <SoftTypography variant="h5" color="#667085">
                  We're sorry to see you go! If you wish to cancel your account, please click on the<br/> "Cancel Account" button below to start the cancellation process
                </SoftTypography>
              </div>

              <SoftTypography sx={{cursor: 'pointer', marginTop: '1.5rem', color: '#EA0606', fontSize: '16px', fontWeight: '600'}} onClick={handleCancelForm}>
                Cancel Account
              </SoftTypography>

            </SoftBox>

          </SoftBox>
        </form>
      </SoftBox>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <SoftBox sx={style}>
            <form ref={formRef}>
              {cancellationDone && (
                <SoftBox sx={{alignItems: 'center', textAlign: 'center'}}>
                  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0 auto"}}>
                    <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_149_11933)" fillOpacity="0.2" stroke="url(#paint1_linear_149_11933)" strokeWidth="0.438272"/>
                    <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_149_11933)" fillOpacity="0.5" stroke="url(#paint3_linear_149_11933)" strokeWidth="0.438272"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M47.7467 25.9868C48.1213 26.3618 48.3316 26.8701 48.3316 27.4001C48.3316 27.9301 48.1213 28.4384 47.7467 28.8134L31.4881 45.0721C31.3004 45.2596 31.0772 45.4079 30.8317 45.5083C30.5861 45.6088 30.323 45.6594 30.0577 45.6571C29.7924 45.6549 29.5302 45.5999 29.2863 45.4953C29.0425 45.3908 28.8219 45.2387 28.6374 45.0481L22.2294 38.4241C21.864 38.0422 21.6645 37.5313 21.6747 37.0028C21.6848 36.4744 21.9038 35.9714 22.2836 35.6039C22.6635 35.2364 23.1734 35.0342 23.7019 35.0415C24.2304 35.0487 24.7345 35.2649 25.1041 35.6428L30.0987 40.8054L44.9201 25.9868C45.2951 25.6122 45.8034 25.4019 46.3334 25.4019C46.8634 25.4019 47.3717 25.6122 47.7467 25.9868Z" fill="#299E47"/>
                    <defs>
                    <linearGradient id="paint0_linear_149_11933" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#299E47" stopOpacity="0"/>
                    <stop offset="0.300199" stopColor="#299E47" stopOpacity="0.06"/>
                    <stop offset="1" stopColor="#299E47"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_149_11933" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                    <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_149_11933" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#299E47" stopOpacity="0.5"/>
                    <stop offset="0.356773" stopColor="#299E47" stopOpacity="0.2"/>
                    <stop offset="1" stopColor="#299E47" stopOpacity="0.7"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_149_11933" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#475467" stopOpacity="0.44"/>
                    <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                    </linearGradient>
                    </defs>
                  </svg>
                  <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '17px', fontWeight: '400', marginTop: '2rem', fontFamily: 'DM Sans'}}>
                    Your Cancellation is Under Review
                  </SoftTypography>
                  <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '2rem', fontFamily: 'DM Sans',
                    "& span": { color: "#7928CA!important" },}}>
                    We received your cancelation request and our team is currently reviewing your data. We will get back to you within 24 hours. If you want to revoke your cancellation request, please contact info@gamemybiz.com
                  </SoftTypography>
                  <SoftButton
                      variant="outlined"
                      color="white"
                      sx={{
                        marginTop: "6rem",
                        "& svg": { fontSize: "1.25rem !important" },
                        minWidth: "48%",
                        padding: ".75rem",
                        borderRadius: "4px",
                        background: "#FFFFFF",
                        color: "#000000"
                      }}
                      onClick={handleCloseModal}
                    >
                      Close
                    </SoftButton>
                </SoftBox>
              )}
              {!cancellationDone && cancelForm &&  (
                <>
                  <SoftBox 
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                    <SoftTypography sx={{textFillColor: "#D93D42", fontSize: '24px', fontWeight: '700', fontFamily: 'DM Sans'}}>
                      Cancel Account
                    </SoftTypography>
                    <SoftButton
                      variant="outlined"
                      color="white"
                      onClick={handleCloseModal}
                      sx={{
                        "& svg": { fontSize: "1.25rem !important" },
                        borderRadius: "50%",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: "0.25rem",
                      }}
                    >
                      <CloseIcon />
                    </SoftButton>
                  </SoftBox>
                  {confirmation ? (
                    <SoftBox>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '16px', fontWeight: '400', marginTop: '2rem', fontFamily: 'DM Sans'}}>
                        Before we proceed with the cancellation, we’d like to understand why you’re leaving. Your feedback helps us improve our services
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '1rem', fontFamily: 'DM Sans'}}>
                        Please select a reason for your cancellation from the options below
                      </SoftTypography>
                      <SoftBox sx={{paddingLeft: '.75rem'}}>
                        <FormGroup>
                          <FormControlLabel
                            sx={{marginTop: '.5rem',
                              '& .MuiFormControlLabel-label': {
                                fontWeight: '400',
                                color: '#FFFFFF',
                                fontFamily: 'DM Sans',
                              },
                            }}
                            control={
                              <Checkbox
                                checked={selected === 'checkbox1'}
                                onChange={handleChange}
                                value="I no longer need the service."
                                name="checkbox1"
                              />
                            }
                            label="I no longer need the service."
                          />
                          <FormControlLabel
                            sx={{marginTop: '1rem',
                              '& .MuiFormControlLabel-label': {
                                fontWeight: '400',
                                color: '#FFFFFF',
                                fontFamily: 'DM Sans',
                              },}}
                            control={
                              <Checkbox
                                checked={selected === 'checkbox2'}
                                onChange={handleChange}
                                value="I found a better alternative."
                                name="checkbox2"
                              />
                            }
                            label="I found a better alternative."
                          />
                          <FormControlLabel
                            sx={{marginTop: '1rem',
                              '& .MuiFormControlLabel-label': {
                                fontWeight: '400',
                                color: '#FFFFFF',
                                fontFamily: 'DM Sans',
                              },}}
                            control={
                              <Checkbox
                                checked={selected === 'checkbox3'}
                                onChange={handleChange}
                                value="I had a technical issue."
                                name="checkbox3"
                              />
                            }
                            label="I had a technical issue."
                          />
                          <FormControlLabel
                            sx={{marginTop: '1rem',
                              '& .MuiFormControlLabel-label': {
                                fontWeight: '400',
                                color: '#FFFFFF',
                                fontFamily: 'DM Sans',
                              },}}
                            control={
                              <Checkbox
                                checked={selected === 'checkbox4'}
                                onChange={handleChange}
                                value="Other"
                                name="checkbox4"
                              />
                            }
                            label="Other (please specify)."
                          />
                          {showInput && (
                            <SoftInput
                              name="others"
                              placeholder="Others"
                              type="text"
                              autoComplete="off"
                              sx={{marginTop: "1rem", marginLeft: "1rem", background: "#3D3D3F!important", borderRadius: "5px", maxWidth: "90%", color: "#FFFFFF!important"}}
                            />
                          )}

                        </FormGroup>
                      </SoftBox>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '5rem', fontFamily: 'DM Sans'}}>
                        If you're facing an issue, our support team is ready to assist.
                      </SoftTypography>
                      <SoftBox 
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{marginTop: "1rem"}}>
                        <SoftButton
                          variant="outlined"
                          color="white"
                          sx={{
                            "& svg": { fontSize: "1.25rem !important" },
                            minWidth: "48%",
                            padding: ".75rem",
                            borderRadius: "4px"
                          }}
                          onClick={handleBtnSupport}
                        >
                          Yes, Contact Support
                        </SoftButton>
                        <SoftButton
                          variant="outlined"
                          color="white"
                          sx={{
                            "& svg": { fontSize: "1.25rem !important" },
                            minWidth: "48%",
                            padding: ".75rem",
                            borderRadius: "4px",
                            background: "#FFFFFF",
                            color: "#000000"
                          }}
                          onClick={handleBtnConfirmation}
                        >
                          No, Continue Cancellation
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  ) : (
                    <SoftBox>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '16px', fontWeight: '400', marginTop: '2rem', fontFamily: 'DM Sans'}}>
                        Are you sure you want to cancel your account? 
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '16px', fontWeight: '400', marginTop: '1rem', fontFamily: 'DM Sans'}}>
                        This action is irreversible, and you will lose access to all your data and services associated with your account
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '12px', fontWeight: '400', marginTop: '3rem', fontFamily: 'DM Sans'}}>
                        Enter your Password to proceed
                      </SoftTypography>
                      <SoftInput
                          name="password"
                          placeholder="Enter your password"
                          type="password"
                          autoComplete="off"
                          sx={{marginTop: "1rem", background: "#3D3D3F!important", borderRadius: "5px", maxWidth: "90%", color: "#FFFFFF!important"}}
                      />
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '2rem', fontFamily: 'DM Sans'}}>
                        If you're facing an issue, our support team is ready to assist.
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '.5rem', fontFamily: 'DM Sans'}}>
                        Support team
                      </SoftTypography>
                      <SoftBox 
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{marginTop: "2rem"}}>
                        <SoftButton
                          variant="outlined"
                          color="white"
                          sx={{
                            "& svg": { fontSize: "1.25rem !important" },
                            minWidth: "48%",
                            padding: ".75rem",
                            borderRadius: "4px"
                          }}
                          onClick={handleCloseModal}
                        >
                          Cancel Request
                        </SoftButton>
                        <SoftButton
                          variant="outlined"
                          color="white"
                          onClick={confirmCancellation}
                          sx={{
                            "& svg": { fontSize: "1.25rem !important" },
                            minWidth: "48%",
                            padding: ".75rem",
                            borderRadius: "4px",
                            background: "#FFFFFF",
                            color: "#000000"
                          }}
                        >
                          Confirm Cancellation
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  )}
                  
                </>
              )} 
              {!cancellationDone  && supportContent &&  (
                <>
                  <SoftBox 
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                    <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '24px', fontWeight: '700', fontFamily: 'DM Sans'}}>
                      Customer care 
                    </SoftTypography>
                    <SoftButton
                      variant="outlined"
                      color="white"
                      onClick={handleCloseModal}
                      sx={{
                        "& svg": { fontSize: "1.25rem !important" },
                        borderRadius: "50%",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: "0.25rem",
                      }}
                    >
                      <CloseIcon />
                    </SoftButton>
                  </SoftBox>
                  <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '16px', fontWeight: '400', marginTop: '3rem', fontFamily: 'DM Sans'}}>
                    Let's Resolve Your Issues!
                  </SoftTypography>
                  <SoftTypography sx={{textFillColor: "#FFFFFF", fontSize: '14px', fontWeight: '400', marginTop: '1rem', fontFamily: 'DM Sans'}}>
                    Our customer care team is here to help. Would you like to contact us to<br/> resolve any issues before canceling your account?
                  </SoftTypography>
                  <SoftBox 
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{marginTop: "7rem"}}>
                    <SoftButton
                      variant="outlined"
                      color="white"
                      sx={{
                        "& svg": { fontSize: "1.25rem !important" },
                        minWidth: "48%",
                        padding: ".75rem",
                        borderRadius: "4px"
                      }}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </SoftButton>
                    <SoftButton
                      variant="outlined"
                      color="white"
                      sx={{
                        "& svg": { fontSize: "1.25rem !important" },
                        minWidth: "48%",
                        padding: ".75rem",
                        borderRadius: "4px",
                        background: "#FFFFFF",
                        color: "#000000"
                      }}
                    >
                      Contact Customer care
                    </SoftButton>
                  </SoftBox>
                </>
              )}
            </form>
          </SoftBox>
        </Fade>
      </Modal>  
    </>
  );
};

export default AccountSettingsV2;
