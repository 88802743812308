import React from "react";
import { Box, Grid } from "@mui/material";
import SoftTypography from "../../SoftTypography";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import useMediaQuery from "@mui/material/useMediaQuery";

const Leaders = ({ data }) => {
  const textBp = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const smBp = useMediaQuery((theme) => theme.breakpoints.down("xxl"));

  return (
    <Grid
      item
      mini={4}
      key={data?._id}
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "end",
        flexDirection: "column",
      }}
    >
      <Box className="flex flex-col items-center justify-start">
        <SoftProfile
          src={data?.image}
          name={data?.company}
          glow={data?.ownCompany}
          size={smBp ? "lg" : "xxl"}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexWrap: "wrap",
            marginY: "1rem",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
          }}
        >
          <SoftTypography
            variant="body"
            color="white"
            sx={{
              textAlign: "center",
              lineHeight: "normal",
              fontWeight: 700,
              fontSize: textBp? "14px": "18px",
              paddingX: "8px",
            }}
          >
            {data?.company || "Anonymous"}
          </SoftTypography>

          <SoftTypography
            variant="h4"
            color="white"
            sx={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.16)",
              borderRadius: "30px",
              paddingX: "12px",
              paddingY: "6px",
              fontSize: textBp? "16px": "20px",
            }}
          >
            {data?.total ? `${Math.round(data.total)} Reviews` : "No Reviews"}
          </SoftTypography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Leaders;
