import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useForm } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmationModal from "../../Modals/ConfirmationModal";
import ImageCropper from "../../../pages/Feed/ImageCropper";
import { PUT_WORKSPACE_IMAGE } from "../../../api/group";
import { GET_USER_BY_GROUPID } from "../../../api/user";
import { GET_IMAGE } from "../../../helpers/images";
import SoftInput from "../../SoftInput";
import SoftSelect from "../../SoftSelect";
import SoftButton from "../../SoftButton";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

const SubWorkspacePanel = ({
  handleClose,
  handleSave,
  handleDelete,
  isLoading,
  types,
  group,
  parentWorkspace,
}) => {
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
      name: "",
      typeId: parentWorkspace.typeId ? parentWorkspace.typeId : group.typeId,
      users: "",
      parentId: parentWorkspace._id,
    },
  });

  const themeHook = useTheme();
  const {
    primary: { main: primaryColor },
  } = themeHook.palette;

  const imageInputRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const hasKpis = useMemo(() => !!group?.kpis?.length, [group]);

  const handleUpload = () => {
    imageInputRef.current.value = null;
    imageInputRef.current.click();
  };

  const handleInputChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const imgSrc = e.target.files[0];
    if (imgSrc) {
      setImageSrc(URL.createObjectURL(imgSrc));
      setOpenDialog(true);
    }
  };

  const onSaveCroppedImage = async (croppedImageFile) => {
    if (!croppedImageFile) return;
    const formData = new FormData();
    setIsUploading(true);
    formData.append("image", croppedImageFile);
    PUT_WORKSPACE_IMAGE(formData)
      .then(({ data }) => {
        setImageSrc(data.key);
        setValue("image", data.key);
        setIsUploading(false);
      })
      .catch((err) => {
        console.log("Job image upload error", err.message);
        setIsUploading(false);
      });

    setOpenDialog(false);
    imageInputRef.current.value = null;
  };

  useEffect(() => {
    return () => {
      setIsUploading(false);
    };
  }, []);

  useEffect(() => {
    if (group) {
      reset(group);
    }
  }, [group]);

  const fetchUsers = async () => {
    setFetching(true);
    try {
      const { data } = await GET_USER_BY_GROUPID(parentWorkspace._id);
      const options = data.map((item) => ({
        value: item._id,
        label: `${item.firstName} ${item.lastName}`,
      }));
      setUsers(options);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
          <SoftTypography variant="h4" sx={{ color: "#FFF" }}>
            {group
              ? "Edit your sub-workspace"
              : `Create a new sub-workspace for ${parentWorkspace.name}`}
          </SoftTypography>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={1} className="mt-2">
          <Grid item mini={12} marginBottom={2}>
            <Grid container spacing={2} className="mt-2">
              <Grid item mini={5} marginBottom={2}>
                <SoftBox
                  sx={{
                    height: "130px",
                    maxWidth: "100%",
                    background: "#04549C1A",
                    border: "1.9px dashed #04549C",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  {!isUploading && !getValues("image") ? (
                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ImageIcon sx={{ fontSize: 100, color: "#6C757D" }} />
                      <SoftTypography
                        sx={{
                          color: "#6C757D",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Upload Logo
                      </SoftTypography>
                    </SoftBox>
                  ) : (
                    <SoftBox sx={{ width: "100%", height: "100%" }}>
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                        variant="rounded"
                        src={
                          !isUploading && getValues("image")
                            ? GET_IMAGE(getValues("image"))
                            : ""
                        }
                      >
                        {isUploading && <CircularProgress />}
                      </Avatar>
                    </SoftBox>
                  )}

                  <input
                    ref={imageInputRef}
                    onInput={handleInputChange}
                    accept="image/*"
                    type="file"
                    hidden
                  />
                  <ImageCropper
                    imgSrc={imageSrc}
                    open={openDialog}
                    setOpen={setOpenDialog}
                    onSaveCroppedImage={onSaveCroppedImage}
                  />
                </SoftBox>
              </Grid>
              <Grid
                item
                mini={6}
                marginBottom={2}
                container
                alignItems="center"
              >
                <SoftBox>
                  <SoftTypography
                    sx={{ color: "#FFF", fontSize: "16px", fontWeight: 500 }}
                  >
                    Upload Your Workspace Logo
                  </SoftTypography>
                  <SoftTypography
                    sx={{
                      color: "#98A2B3",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginBottom: "10px",
                    }}
                  >
                    JPEG, PNG or GIF. Max file size 10GB
                  </SoftTypography>
                  <SoftButton
                    color="outlined"
                    variant="contained"
                    startIcon={<UploadIcon sx={{ color: "#CED4DA" }} />}
                    onClick={handleUpload}
                    sx={{
                      textTransform: "none",
                      borderRadius: "4px",
                      border: "1px solid rgba(233, 236, 239, 0.20)",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      background: "rgba(255, 255, 255, 0.10)",
                      color: "#CED4DA",
                      "&:hover": {
                        background: "rgba(255, 255, 255, 0.10)",
                      },
                    }}
                  >
                    Upload
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item mini={12} marginBottom={2}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Enter Your Sub-Workspace Name
              </SoftTypography>
            </SoftBox>
            <SoftInput
              {...register("name")}
              variant="outlined"
              placeholder="Your sub-workspace name"
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>
          <Grid item mini={12} marginBottom={3}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Select Workspace Type
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              isLoading={fetching}
              options={users}
              name="users"
              // value={formik.values.kpimodels}
              onChange={(selectedOption) => {
                setValue("users", selectedOption);
              }}
              placeholder="Select a player (optional)"
              styles={{
                color: "white !important",
                control: {
                  background: "rgba(255, 255, 255, 0.10) !important",
                },
                placeholder: {
                  color: "white !important",
                  opacity: "0.7",
                },
                input: {
                  color: "white !important",
                  opacity: "0.7",
                },
                singleValue: {
                  color: "white !important",
                  opacity: "0.7",
                },
              }}
              darkTheme
            />
          </Grid>
        </Grid>

        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <ConfirmationModal
            title={hasKpis ? "Disable Subworkspace" : "Delete Subworkspace"}
            question={
              hasKpis
                ? "This workspace has KPIs; both workspace and its KPIs will be disabled. Continue?"
                : "This workspace has no KPIs and would be permanently deleted. Continue?"
            }
            onSuccess={handleDelete}
            noText="No, cancel"
            yesText={hasKpis ? "Yes, disable" : "Yes, delete"}
          >
            <SoftButton
              color="outlined"
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "4px",
                border: "1px solid rgba(233, 236, 239, 0.20)",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                background: "rgba(255, 255, 255, 0.10)",
                color: "#CED4DA",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.10)",
                },
              }}
            >
              Delete
            </SoftButton>
          </ConfirmationModal>
          <SoftBox display="flex" alignItems="center" gap="0.5rem">
            <SoftButton
              variant="contained"
              color="outlined"
              onClick={handleClose}
              sx={{
                textTransform: "none",
                borderRadius: "4px",
                border: "1px solid rgba(233, 236, 239, 0.20)",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                background: "rgba(255, 255, 255, 0.10)",
                color: "#CED4DA",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.10)",
                },
              }}
            >
              BACK
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              sx={{
                color: "primary.contrastText",
                textTransform: "none",
                borderRadius: "4px",
              }}
            >
              Save Sub-Workspace
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  className="ml-2"
                />
              )}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </form>
    </>
  );
};

export default SubWorkspacePanel;
