import * as Yup from "yup";

const TEXT_FIELD_IS_REQUIRED = "Field is required";
const TEXT_REQUIRED = "Required";

const CreateChallengeArenaDetailsSchema = Yup.object().shape({
  title: Yup.string().required(TEXT_FIELD_IS_REQUIRED).nullable(),
  isPrivate: Yup.object().required(TEXT_REQUIRED).nullable(),
  category: Yup.string().required(TEXT_REQUIRED).nullable(),
  startDate: Yup.string().required(TEXT_REQUIRED).nullable(),
  endDate: Yup.string().required(TEXT_REQUIRED).nullable(),
  prizeForWinner: Yup.string().nullable(),
  prizeforSecondPlace: Yup.string().nullable(),
  prizeforThirdPlace: Yup.string().nullable(),
  prizeforParticipants: Yup.string().nullable(),
  description: Yup.string().required(TEXT_FIELD_IS_REQUIRED).nullable(),
  image: Yup.string().nullable()
});

export default CreateChallengeArenaDetailsSchema;
