import { takeEvery, put, all, select, fork } from "redux-saga/effects";
import { types } from "./types";
import { types as authTypes } from "../auth/types";
import { types as workspaceTypes } from "../workspace/types";
import { GET_COMPANY, PUT_COMPANY } from "../../api/company";
import { GET_COMPANY_USERS } from "../../api/user";
import { GET_HOLIDAYS } from "../../api/calendar";
import moment from "moment";

const getCompanyId = (state) => state.authReducer.userDetails.companyId;
const getMyUserDetails = (state) => state.authReducer.userDetails;

// get company
function* GetCompany() {
  try {
    const companyId = yield select(getCompanyId);
    const { data } = yield GET_COMPANY(companyId);

    if(data) {
      yield put({
        type: types.GET_COMPANY_SUCCESS,
        company: data,
      });
    }
  } catch (err) {
    yield put({ type: types.GET_COMPANY_FAILED, error: err.message });
  }
}

function* GetCompanyHolidays() {
  try {
    const companyId = yield select(getCompanyId);
    const { data } = yield GET_HOLIDAYS(companyId);
    
    const holidays = [];

    data.forEach((item) => {
      const holiday = moment.utc(item.holiday);
      const day = holiday.day();
      if (day > 0 && day < 6) {
        holidays.push({
          date: item.holiday,
          text: item.text,
        });
      }
    });

    yield put({
      type: types.GET_COMPANY_HOLIDAYS_SUCCESS,
      holidays,
    });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_HOLIDAYS_FAILED, error: err.message });
  }
}

function* SetCompany(action) {
  const { payload } = action;
  yield put({
    type: types.SET_COMPANY_SUCCESS,
    payload,
  });
}

function* ResetCompany() {
  yield put({
    type: types.RESET_COMPANY_INFO,
  })
}

function* companyInfoSaga() {
  yield takeEvery(authTypes.LOGIN_REQUEST, ResetCompany);
  yield takeEvery(authTypes.LOGIN_QUICK_REQUEST, ResetCompany);
  
  yield takeEvery(types.GET_COMPANY_REQUEST, GetCompany);
  yield takeEvery(authTypes.LOGIN_SUCCESS, GetCompany);
  yield takeEvery(authTypes.LOGIN_QUICK_SUCCESS, GetCompany);
  yield takeEvery(types.SET_COMPANY_REQUEST, SetCompany);

  yield takeEvery(types.GET_COMPANY_HOLIDAYS_REQUEST, GetCompanyHolidays);
  yield takeEvery(types.GET_COMPANY_SUCCESS, GetCompanyHolidays);
}

// get yeti points
function* GetCompanyUsers() {
  try {
    const companyId = yield select(getCompanyId);
    const { data } = yield GET_COMPANY_USERS(companyId);

    const usersDictionary = {};
    const usersList = [];
    data.forEach((user) => {
      const id = user._id;
      delete user._id;
      usersDictionary[id] = user;
      usersList.push(id);
    });

    yield put({
      type: types.GET_COMPANY_USERS_SUCCESS,
      usersDictionary,
      usersList,
    });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_USERS_FAILED, error: err.message });
  }
}

function* UpdateMyCompanyProfile() {
  try {
    const myUserDetails = yield select(getMyUserDetails);
    const myProfile = {};
    myProfile[myUserDetails._id] = myUserDetails;

    yield put({
      type: types.UPDATE_MY_COMPANY_PROFILE,
      myProfile,
    });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_USERS_FAILED, error: err.message });
  }
}

function* companyUsersSaga() {
  yield takeEvery(types.GET_COMPANY_USERS_REQUEST, GetCompanyUsers);
  yield takeEvery(types.GET_COMPANY_SUCCESS, GetCompanyUsers);
  yield takeEvery(workspaceTypes.SET_SELECTED_WORKSPACE_SUCCESS, GetCompanyUsers);
  yield takeEvery(authTypes.UPDATE_PROFILE_SUCCESS, UpdateMyCompanyProfile);
  yield takeEvery(authTypes.PROFILE_PIC_UPLOAD, UpdateMyCompanyProfile);
}

function* UpdateCompanyDetails(action) {
  try {
    const companyId = yield select(getCompanyId);
    const { data } = yield PUT_COMPANY(companyId, action.payload);

    yield put({
      type: "UPDATE_COMPANY_DETAILS",
      data,
    });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_USERS_FAILED, error: err.message });
  }
}

function* updateCompanySaga() {
  yield takeEvery("UPDATE_COMPANY_DETAILS_SAGA", UpdateCompanyDetails);
}

// root saga container
function* rootSaga() {
  yield all([
    fork(companyInfoSaga),
    fork(companyUsersSaga),
    fork(updateCompanySaga),
  ]);
}

export default rootSaga;

// const userDictionary = {};
// users.forEach(user => {
//   const id = user._id;
//   delete user._id;
//   userDictionary[id] = user;
// })
