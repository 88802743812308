import React, { useState, useEffect } from "react";
import { Dialog, useMediaQuery, Grid } from "@mui/material";
import { useTheme, alpha, styled } from "@mui/material/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SearchBar";
import SoftButton from "../SoftButton";
import { GET_INVITE_CODE } from "../../api/affiliate";
import SoftBox from "../SoftBox";

// Imported Images and Icons
import MonsterInvite from "../../img/ca-inviteMonster.png";
import gmbLogo from "../../img/gmb-favicon-192.png";
import MonsterGif from "../../img/challenge-arena-upgrade.gif";
import ShareGif from "../../img/ca-share-link.gif";
import GroupGif from "../../img/ca-group-avatar.gif";
import ArrowGif from "../../img/ca-arrow.gif";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const InvitePopUp = ({ challenge, challengeUrl, showInvite, closeInvite }) => {
  const [shareUrl, setShareUrl] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (challengeUrl) {
      GET_INVITE_CODE().then(({ data: { code } }) => {
        setShareUrl(
          window.location.origin + "/join/" + challengeUrl + "?ref=" + code,
        );
      });
    }
  }, [challengeUrl]);

  return (
    <Dialog
      open={showInvite}
      onClose={closeInvite}
      maxWidth={matches ? "md" : "sm"}
      sx={{
        "& 	.MuiDialog-paper": {
          borderRadius: "16px",
          border: "1px solid rgba(255, 255, 255, 0.10)",
          background: "rgba(255, 255, 255, 0.04)",
          boxShadow:
            "-24px 24px 64px 0px rgba(6, 41, 64, 0.60), -48px 48px 128px 0px rgba(6, 41, 64, 0.60)",
          backdropFilter: "blur(80px)",
          maxWidth: "800px",
          width: "100%",
          padding: { mini: "1rem", md: "2rem" },
        },
      }}
    >
      <SoftBox>
        <SoftBox
          sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}
        >
          <SoftBox sx={{}}>
            <SoftButton
              color="light"
              sx={{
                position: "absolute",
                right: { mini: "1rem", md: "2rem" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                border: "0.529px solid rgba(255, 255, 255, 0.20)",
                padding: "0.25rem",
                background: "transparent",
                "& svg": { fontSize: "1.25rem !important", fill: "white" },
              }}
              onClick={closeInvite}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
          <SoftBox sx={{ maxWidth: "60px" }}>
            <img src={gmbLogo} alt="logo" className="w-full" />
          </SoftBox>
          <SoftBox sx={{ maxWidth: "60px" }}>
            <img src={MonsterGif} alt="" className="w-full" />
          </SoftBox>
        </SoftBox>
        <SoftBox sx={{ textAlign: "center", marginY: "1rem" }}>
          <SoftTypography variant={matches ? "h3" : "h4"} color="white">
            {challenge}
          </SoftTypography>
          <SoftTypography variant="body2" color="light">
            Challenge Your Friends
          </SoftTypography>
        </SoftBox>
        <SoftBox sx={{ marginTop: { mini: "1.5rem", md: "auto" } }}>
          <Grid container spacing={2}>
            <Grid item mini={12} md={3}>
              <SoftBox
                sx={{
                  borderRadius: "8px",
                  border: "1px solid rgba(28, 132, 207, 0.60);",
                  background:
                    "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)",
                  boxShadow:
                    "0px 0px 32px 0px rgba(23, 119, 242, 0.40), 0px 0px 48px 0px rgba(23, 119, 242, 0.20) inset",
                  backdropFilter: "blur(40px)",
                  padding: "0.5rem",
                }}
              >
                <img src={MonsterInvite} alt="" className="mx-auto" />
              </SoftBox>
            </Grid>
            <Grid item mini={12} md={9}>
              <SoftBox
                sx={{
                  display: "flex",
                  padding: "1rem",
                  height: "100%",
                  alignItems: "center",
                  background: "rgba(255, 255, 255, 0.02)",
                  border: "1px dashed rgba(255, 255, 255, 0.10)",
                  borderRadius: "12px",
                }}
              >
                <SoftBox sx={{ width: "100%" }}>
                  <SoftTypography variant="body2" color="light">
                    1. Copy link
                  </SoftTypography>

                  <SoftBox
                    sx={{
                      marginBottom: "1rem",
                    }}
                  >
                    <SoftBox sx={{ display: "flex", alignItems: "center" }}>
                      <SoftInput value={shareUrl} sx={{ width: "100%" }} />
                      <CopyToClipboard
                        text={shareUrl}
                        onCopy={() => {
                          toast.success(
                            "Challenge scoreboard URL copied to clipboard!",
                          );
                        }}
                      >
                        <SoftButton
                          color="primary"
                          sx={{
                            position: "absolute",
                            display: "flex",
                            justifyContent: "space-between",
                            textTransform: "none",
                            padding: "0.5rem 0.5rem",
                            color: "primary.contrastText",
                            right: { mini: "36px", md: "54px" },
                            minHeight: "max-content",
                            minWidth: "90px",
                            "& svg": { fontSize: "1rem !important" },
                          }}
                          disabled={!shareUrl}
                        >
                          Copy Link <ContentCopyIcon />
                        </SoftButton>
                      </CopyToClipboard>
                    </SoftBox>
                  </SoftBox>

                  <SoftTypography variant="body2" color="light">
                    2. Send Link to Your Friends!
                  </SoftTypography>

                  <SoftBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.5rem",
                      background: "rgba(255, 255, 255, 0.02)",
                      border: "1px dashed rgba(255, 255, 255, 0.10)",
                      borderRadius: "12px",
                    }}
                  >
                    <img
                      src={GroupGif}
                      alt=""
                      className="w-[100%] max-w-[50px]"
                    />
                    <img
                      src={ArrowGif}
                      alt=""
                      className="w-[100%] max-w-[50px]"
                    />
                    <img
                      src={ShareGif}
                      alt=""
                      className="w-[100%] max-w-[50px]"
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Dialog>
  );
};

export default InvitePopUp;
