import * as React from "react";

// Material UI components
import IconButton from '@mui/material/IconButton';

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftInput from "../SoftInput";

// Icons
import { ReactComponent as SendIcon } from "../../img/icon-send.svg";

const SendMessage = ({
  disabled = false,
  handleSendClick
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const message = e.target.message.value;
    handleSendClick?.(message);
  }

  return (
    <SoftBox
      component="form"
      onSubmit={handleFormSubmit}
      autocomplete="off"
      sx={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between",
        border: "1.084px solid #CED4DA",
        marginTop: "26px",
        borderRadius: "8px",
        "& .Mui-focused": {
          borderColor: "none !important",
          boxShadow: "none !important"
        },
        "& div": {
          width: "100%"
        },
        "& .MuiButtonBase-root": {
          backgroundColor: "primary.main",
          borderRadius: "6px",
          color: "#000",
          width: "40px",
          height: "32px",
          marginRight: "3px",
          fontSize: "16px",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.dark
          }
        }
      }}
    >
      <SoftInput
        id="message"
        name="message"
        placeholder="Note"
        type="text"
        autocomplete="off"
        disabled={disabled}
        sx={{
          border: "none",
          fontSize: "10.843px !important",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
      />

      <IconButton
        color="primary"
        type="submit"
        disabled={disabled}
      >
        <SendIcon />
      </IconButton>
    </SoftBox>
  );
}

export default SendMessage;