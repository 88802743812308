import { makeStyles } from "@mui/styles";
import backgroundImg from "../../img/mountain.jpg";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    paddingTop: "50px",
    paddingBottom: "50px",
    background: "var(--bg_linear_gra)",
    display: "flex",
  },

  sec_logo: {
    maxWidth: "450px",
    margin: " 20px auto",
    display: "block",
  },

  form_div: {
    maxWidth: "70vw",
    margin: "20px auto 0 auto",
  },

  form_P: {
    color: "#ACACAC",
    textAlign: "center",
    marginBottom: 20,
  },

  imgLogo: {
    width: "50px",
    margin: "0 auto",
  },

  h2: {
    color: " #12498e",
    fontWeight: "bold",
    textAlign: "center",
  },

  signUpContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    height: "100vh",

    "@media screen and (max-width: 1200px)": {
      gridTemplateColumns: "1fr",
    },
  },

  formContainer: {
    padding: "0 2rem 0 5rem",
    "@media screen and (max-width: 1200px)": {
      padding: "0 4rem",
    },
  },

  inputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "1.5rem",
    margin: "20px auto",

    "@media screen and (max-width: 1024px)": {
      display: "block",
    },
  },

  inputSubContainer: {
    display: "grid",
    justifyContent: "center",
    gridTemplateRows: "repeat(3, 1fr)",
  },

  visibilityIcon: {
    marginLeft: "-40px",
    color: "gray",
    cursor: "pointer",
  },

  imageUploadContainer: {
    marginTop: "15px",
    marginLeft: "10px",
  },

  registerBgContainer: {
    top: 0,
    width: "48%",
    height: "100%",
    right: 0,
    position: "absolute",
    zIndex: "0 !important",

    "@media screen and (max-width: 1200px)": {
      display: "none",
    },
  },

  registerBackground: {
    clipPath: "polygon(43% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 93%)",
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
    height: "100%",
    right: 0,
    left: 0,
    zIndex: 2,
    borderBottomLeftRadius: "4rem",
  },

  bgHeadingContainer: {
    position: "absolute",
    top: "45%",
    left: "50%",
  },

  bgHeadingText: {
    color: "white",
    position: "absolute",
    zIndex: 3,
    textAlign: "center",
  },
});

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const style = {
  backgroundColor: "white",
  borderRadius: 18,
  padding: 20,
  p: 2,
  px: 4,
  pb: 3,
};
