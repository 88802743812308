import { initState, types } from "./types";
import _ from "lodash";

const jobsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ALL_JOBS:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case types.GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        jobs: _({}).merge(_(state.jobs).groupBy("_id").value(), _(action.jobs).groupBy("_id").value()).values().flatten().value(),
        hasMore: action.hasMore,
        currentPage: action.currentPage,
        isLoading: false
      };
    case types.GET_ALL_JOBS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.message
      };
    case types.GET_A_JOB:
      return {
        ...state,
        error: ""
      };
    case types.GET_A_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map(item => item._id === action.jobId ? action.job : item)
      };
    case types.GET_A_JOB_FAILED:
      return {
        ...state,
        error: action.message
      };
    case types.RESET_JOBS:
      return {
        ...state,
        jobs: [],
        hasMore: false,
        currentPage: 1
      };
    case types.ADD_COMMENT:
      return {
        ...state,
        jobs: state.jobs.map(job => job._id === action.jobId ? {
          ...job,
          comments: [...job.comments, action.comment]
        } : job)
      };
    default:
      return state;
  }
};

export default jobsReducer;
