/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the Soft UI Dashboard PRO React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Soft UI Dashboard PRO React using thie file.
 */

const breakpoints = {
  values: {
    // xs: 0,
    // sm: 576,
    // md: 768,
    // lg: 992,
    // xl: 1200,
    // xxl: 1400,
    mini: 0,
    xxs: 400,
    xs: 480,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1536,
    xxxl: 1920,
    wide: 2560,
    wider: 3440,
  },
};

export default breakpoints;
