import moment from "moment";
import { formatPercent } from "../../../helpers/formatter";

const options = {
  chart: {
    height: 350,
    type: "line",
    parentHeightOffset: -20,
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.15,
    },
    toolbar: {
      show: false,
    },
    background: "transparent",
  },
  colors: ["#165DFF", "#7D16FF"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    area: {
      fillTo: "origin",
    },
  },
  stroke: {
    curve: "smooth",
    width: 1,
  },
  title: {
    align: "left",
  },
  grid: {
    strokeDashArray: 10,
    borderColor: "#e0e0e0",
    padding: {
      left: 15,
      right: 15,
      bottom: 0,
      top: 0,
    },
  },
  markers: {
    size: 1,
  },
  yaxis: {
    show: false,
    labels: {
      formatter: (value) => {
        return formatPercent(value);
      },
    },
  },
  xaxis: {
    labels: {
      hideOverlappingLabels: true,
      rotateAlways: true,
      rotate: -60,
      style: {
        fontSize: "11px",
        fontFamily: "DM Sans",
      },
      formatter: (value) => {
        return moment(value).format("MM/DD");
      },
    },
    axisBorder: {
      show: false,
    },
  },
  fill: {
    opacity: 0.5,
  },
  legend: {
    show: false,
  },
};

export default options;
