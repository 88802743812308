import React, { useState } from "react";
import WorkspaceDrawer from "./WorkspaceDrawer";

import { Box, Button, Popover } from "@mui/material";
import SoftBox from "../SoftBox";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const WorkspacePopover = ({
  darkMode = false,
  viewOnly = false,
  expands = false,
}) => {
  const [openWorkspace, setOpenWorkspace] = useState(null);
  const handleOpenWs = (event) => {
    setOpenWorkspace(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseWs = () => {
    setOpenWorkspace(null);
  };

  const openWsMenu = Boolean(openWorkspace);

  return (
    <>
      <SoftBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "max-content",
          minWidth: "max-content",
          padding: 0,
          color: darkMode || !expands ? "#FFF" : "#000",
          position: "absolute",
          fontSize: expands ? "2vh" : "1.5rem",
          right: expands ? "1vw" : "16px",
          top: expands ? "1.5vh" : "24px",
        }}
        onClick={handleOpenWs}
      >
        <KeyboardArrowDownRoundedIcon />
      </SoftBox>
      <Popover
        id="workspaceMenu"
        open={openWsMenu}
        onClose={handleCloseWs}
        anchorEl={openWorkspace}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            ...(darkMode && { background: "primary.darker" }),
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            padding: "0rem",
            borderRadius: "0px",
            ...(expands
              ? {
                  width: "20vw",
                }
              : {
                  width: "260px",
                }),
          },
        }}
      >
        <SoftBox
          sx={{
            mt: "10px",
          }}
        />
        <WorkspaceDrawer
          onClose={handleCloseWs}
          viewOnly={viewOnly}
          darkMode={darkMode}
        />
      </Popover>
    </>
  );
};

export default WorkspacePopover;
