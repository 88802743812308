import React, { useState } from "react";
import { Button, Dialog, Grid, Divider, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import toast from "react-hot-toast";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { ADD_COLLEAGUE, GET_USERS } from "../../api/user";
import { UseCompany, UseCompanyWorkspaces } from "../../hooks/company";
import { getCompanyUsers } from "../../redux/company/actions";
import messages from "../../utils/messages";
import { StyledPaper } from "../ManualEntry/manualEntryStyles";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components/SoftInput";
import SoftSelect from "../../components/SoftSelect";
import SoftButton from "../../components/SoftButton";
import UpgradePopUp from "../../components/ChallengeArenaPopUp/UpgradePopUp";
import ERRORS from "../../constants/errorStatus";
import AddPlayerBillingUpdate from "./AddPlayerBillingUpdate";
import AddPlayerSchema from "../../form-validations/AddPlayerSchema";

const AddUser = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const company = UseCompany();
  const theme = useTheme();
  const workspaces = UseCompanyWorkspaces();
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [addPlayerBillingDialog, setAddPlayerBillingDialog] = useState(false);

  const openAddPlayerBillingDialog = () => setAddPlayerBillingDialog(true);
  const closeAddPlayerBillingDialog = () => setAddPlayerBillingDialog(false);

  const departmentNames = workspaces?.map((dept) => ({
    value: dept._id,
    label: dept.name,
  }));

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const validationOpt = {
    resolver: yupResolver(AddPlayerSchema),
    shouldUnregister: false,
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      department: "",
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm(validationOpt);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const triggerSave = async (formData) => {
    setLoading(true);
    try {
      const payload = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName,
        email: formData.email.trim(),
        groupId: formData.department,
        companyId: company._id,
      };

      await ADD_COLLEAGUE(payload);
      reset();
      setLoading(false);
      handleClose();

      setTimeout(() => {
        openAddPlayerBillingDialog();
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.log("Add Player error", error.response);
      if (error.response.status === ERRORS.UPGRADE_REQUIRED) {
        setShowUpgrade(true);
      }
      toast.error(error.response.data ?? "Error while adding a player");
    }
  };

  return (
    <>
      <SoftButton
        color="primary"
        sx={{
          color: "primary.contrastText",
          borderRadius: "4px",
          textTransform: "none",
        }}
        onClick={handleOpen}
      >
        Add Player
      </SoftButton>
      {open && (
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          PaperComponent={StyledPaper}
          sx={{
            margin: "auto",
            width: { xs: "100vw", sm: "60vw" },
            ".MuiPaper-root": {
              borderRadius: "10px",
              width: "100%",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              padding: { mini: "1rem", md: "1.5rem" },
              overflow: "visible",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftTypography variant="h4" color="white">
              Add Player to Workspace
            </SoftTypography>
            <SoftButton
              variant="outlined"
              color="white"
              onClick={handleClose}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
          <Divider
            sx={{
              backgroundColor: "#FFF",
            }}
          />
          <SoftBox
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              borderRadius: "8px",
              border: "1px solid #F2F4F74D",
              padding: "10px 15px",
              display: "flex",
              marginBottom: "20px",
              gap: "0.5rem",
            }}
          >
            <ReportGmailerrorredIcon color="white" fontSize="medium" />
            <SoftBox>
              <SoftTypography
                component="p"
                color="white"
                sx={{ fontSize: "14px", fontWeight: 500, marginBottom: "15px" }}
              >
                You're rocking our free plan, and adding players is currently on
                us! 🎉
              </SoftTypography>
              <SoftTypography
                component="p"
                color="white"
                sx={{ fontSize: "14px", fontWeight: 500 }}
              >
                This is a friendly reminder that any extra players beyond the admin 
                seat will be charged $5 each in the upcoming billing cycle. Embrace 
                the game, and enjoy the ride!
              </SoftTypography>
              {/* <SoftTypography
                component="p"
                color="white"
                sx={{ fontSize: "14px", fontWeight: 500 }}
              >
                Just a friendly reminder that after your 14-day trial, any extra
                players beyond the admin seat will be charged at $5 each in the
                upcoming billing cycle. Embrace the game, and enjoy the ride!
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
          <form onSubmit={handleSubmit(triggerSave)}>
            <Grid container rowSpacing={3} columnSpacing={2} marginBottom={5}>
              <Grid item lg={6}>
                <SoftInput
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                  error={!!errors?.firstName}
                  helperText={errors?.firstName?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                  size="large"
                />
              </Grid>
              <Grid item lg={6}>
                <SoftInput
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                  size="large"
                />
              </Grid>
              <Grid item lg={12}>
                <SoftInput
                  id="outlined-basic"
                  placeholder="Email"
                  type="email"
                  label="Email"
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                  {...register("email")}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="large"
                />
              </Grid>
              <Grid item lg={12}>
                <SoftSelect
                  {...register("department", { required: true })}
                  error={!!errors?.department}
                  helperText={errors?.department?.message}
                  placeholder="Choose a department"
                  options={departmentNames}
                  size="large"
                  onChange={(option) => setValue("department", option.value)}
                  styles={{
                    color: "white !important",
                    height: "fit-content !important",
                    placeholder: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    input: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    singleValue: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                  }}
                  // renderValue={(value) =>
                  //   // eslint-disable-next-line no-unneeded-ternary
                  //   value
                  //     ? workspaces.find((w) => w._id === value).name
                  //     : "Choose a department"
                  // }
                />
              </Grid>
            </Grid>
            <Divider
              sx={{
                backgroundColor: "#FFF",
              }}
            />
            <SoftBox display="flex" justifyContent="flex-end">
              <SoftButton
                type="submit"
                size="small"
                variant="outlined"
                color="white"
                disabled={loading}
                sx={{
                  color: "#FFF",
                  fontSize: "1.1rem",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
              >
                Add Player
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    className="ml-2"
                  />
                )}
              </SoftButton>
            </SoftBox>
          </form>
        </Dialog>
      )}
      {addPlayerBillingDialog && (
        <AddPlayerBillingUpdate
          addPlayerBillingDialog={addPlayerBillingDialog}
          closeAddPlayerBillingDialog={closeAddPlayerBillingDialog}
        />
      )}
      {showUpgrade && (
        <UpgradePopUp
          showUpgrade={showUpgrade}
          closeUpgrade={() => setShowUpgrade(false)}
        />
      )}
    </>
  );
};

export default AddUser;
