import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// Imported MUI and styles
import { Box, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import PaymentForm from "../Stripe/PaymentForm";
import PageHeader from "../PageHeader";
import { POST_SUBSCRIPTION } from "../../api/payment";
import { useDispatch } from "react-redux";
import { getCompany } from "../../redux/company/actions";
import LoadingScreen from "../LoadingScreen";

const Payment = ({ newUser = true }) => {
  const [stripe, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [trialDays, setTrialDays] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  useEffect(() => {
    const priceId = query.get("priceId");

    // Create Stripe subscription item
    if (priceId) {
      POST_SUBSCRIPTION({priceId})
        .then(({ data }) => {
          setClientSecret(data.clientSecret);
          setTrialDays(data.trialDays ?? 0);
          setCoupon(data.coupon);
        })
        .catch((err) =>
          console.log("Stripe Subscription Error", err.response.data),
        );
    }
  }, []);

  useEffect(() => {
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then((stripeRes) => {
      setStripe(stripeRes);
    });
  }, []);

  const handleResult = (message, type) => {
    if (type === "error") {
      setError(message);
    }
    toast[type](message);
    dispatch(getCompany());
    setClientSecret(null);
    toast[type](message);

    navigate('/pricing');
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <PageHeader
        iconKey="accountsettings"
        headerText="Billing Plans and Pricing"
      />

      {stripe &&
      clientSecret &&
      location &&
      location.state &&
      location.state.subscription ? (
        <Box sx={{
          maxWidth: "sm",
          margin: "auto"
        }}>
          <Elements stripe={stripe} options={{ clientSecret }}>
            <PaymentForm
              newUser={newUser}
              subscription={location.state.subscription}
              handleResult={handleResult}
              trialDays={trialDays}
              coupon={coupon}
            />
          </Elements>
        </Box>
      ) : (
        <Box sx={{ marginTop: "1.5rem", paddingX: "1rem" }}>
          {error && (
            <Typography
              color="#FF0000"
              variant="button"
              align="center"
              className="my-2"
            >
              {error}
            </Typography>
          )}
          <LoadingScreen />
        </Box>
      )}
    </>
  );
};

export default Payment;
