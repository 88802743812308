import React from "react";

import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

import SetupDialog from "./layout/SetupDialog";

const SetupDisplayDialog = ({
  open = false,
  handleReset
}) => {
  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#D0D5DD",
    lineHeight: "20.83px"
  };

  const LABELS = [
    { id: 0, label: "Daily Target", value: "$0" },
    { id: 1, label: "Metric Value Type", value: "$0" }
  ];

  return (
    <SetupDialog
      open={open}
      handleClose={handleReset}
      steps={["Step 1", "Step 2"]}
      activeStep={2}
      btnSubmitLabel="Ok"
      handleNextBtnPress={handleReset}
    >
      {/** Header Text */}
      <SoftTypography
        variant="h5"
        sx={{
          ...fontStyles,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "18.23px",
          margin: "auto",
          textAlign: "center",
          color: "#FFFFFF",
        }}
      >
        You cannot allocate point or set daily target on a display metric<br />This just displays your metric information
      </SoftTypography>

      {/** Values content section */}
      <SoftBox sx={{
        padding: "32px",
        "& p": { ...fontStyles }
      }}>
        {LABELS.map((item) => (
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "32px"
            }}
          >
            <SoftBox>
              <SoftTypography variant="body1">
                {item.label}
              </SoftTypography>
            </SoftBox>

            <SoftTypography
              variant="body1"
              sx={{ color: "#9E77ED !important" }}
            >
              {item.value}
            </SoftTypography>
          </SoftBox>
        ))}
      </SoftBox>

      {/** Footer text */}
      <SoftBox sx={{
        padding: "32px",
      }}>
        <SoftTypography
          variant="body"
          sx={{
            ...fontStyles,
            fontWeight: 500,
            fontSize: "12px",
          }}
        >
          This will dynamically display Real-time information about the progress of your metric
        </SoftTypography>
      </SoftBox>
    </SetupDialog>
  );
};

export default SetupDisplayDialog;
