import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { types } from "./types";
import { GET_MASTER_BADGE } from "../../api/badges";

function* GetMasterBadges() {
  try {
    const { data } = yield call(() => GET_MASTER_BADGE());

    yield put({
      type: types.GET_ALL_MASTER_BADGES_SUCCESS,
      masterBadges: data,
    });
  } catch (err) {
    yield put({ type: types.GET_ALL_MASTER_BADGES_FAILED, error: err.message });
  }
}

function* watchGetJobsRequest() {
  yield takeEvery(types.GET_ALL_MASTER_BADGES, GetMasterBadges);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchGetJobsRequest)]);
}

export default rootSaga;
