export const initState = {
  challenges: [],
  isLoading: false,
  error: null,
};

export const types = {
  CREATE_CHALLENGE_REQUEST: "CREATE_CHALLENGE_REQUEST",
  CREATE_CHALLENGE_SUCCESS: "CREATE_CHALLENGE_SUCCESS",
  CREATE_CHALLENGE_FAILED: "CREATE_CHALLENGE_FAILED",
};
