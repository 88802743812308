import React, { useEffect, useState } from "react";
import moment from "moment";

// Material UI Components
import { ClickAwayListener } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";

// Components
import Note from "./Note";
import SendMessage from "./SendMessage";

const NoteList = ({
  notes,
  isVisible = true,
  onSendMessage,
  handleClickAway
}) => {
  const MAX_ITEMS = 5;
  return (
    <ClickAwayListener onClickAway={(e) => {
      const isNoteClick = (
        e.target.nodeName?.toLowerCase() === "span" &&
        e.target?.innerText?.includes("Note"));
      handleClickAway?.(isNoteClick)
    }}>
      <SoftBox
        id="notelist"
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "5px",
          width: "350px",
          maxWidth: "350px",
          minHeight: "233px",
          height: "max-content",
          padding: "20px 36px 20px 22px",
          margin: "16px 16px 16px 0",
          position: (isVisible) ? "relative" : "fixed",
          right: (isVisible) ? "" : 48,
          top: (isVisible) ? "" : 0,
          "& h4": {
            color: "#000",
            fontSize: "22px",
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700
          }
        }}
      >
        <SoftBox >
          {/** Title */}
          <SoftTypography variant="h4">
            Notes
          </SoftTypography>

          {/** Notes List */}
          <div style={{ margin: "24px 0 0 0", minHeight: "70px" }}>
            {(notes === null || notes?.length === 0)
              ? <div>Create a Note</div>
              : notes?.map((note, index) => {
                // Display only the latest MAX_ITEMS notes
                if (index < MAX_ITEMS) {
                  return <Note
                    date={moment(note?.createdAt ?? new Date()).format("D MMM YYYY ddd hh:mm A")}
                    name={`${note?.users[0].firstName} ${note?.users[0].lastName}`}
                    note={note?.content}
                    profile={note?.users[0]?.image}
                  />
                }
              })
            }
          </div>

          {/** Send Message */}
          <div style={{ marginLeft: "36px" }}>
            <SendMessage
              disabled={(notes === null)}
              handleSendClick={onSendMessage}
            />
          </div>
        </SoftBox>
      </SoftBox>
    </ClickAwayListener>
  )
}

export default NoteList;
