import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";

// Sheet Style
import GMGLogo from "../../img/GMB_Logo.svg";
import { useStyles } from "./ResetPassword.styles";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {
  RESET_USER_PASSWORD,
  VERIFY_USER_PASSWORD_RESET,
} from "../../api/user";
import { ResetPasswordSchema } from "../../form-validations";
import { useQuery } from "../../hooks/useQuery";
import LoadingScreen from "../../components/LoadingScreen";

function ResetPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");

  const { mutateAsync: resetUserPassword, data: resetData } =
    RESET_USER_PASSWORD();
  const { isLoading, isError, data } = VERIFY_USER_PASSWORD_RESET(token);
  const [visible, setVisible] = useState(false);

  const validationOpt = {
    resolver: yupResolver(ResetPasswordSchema),
    shouldUnregister: false,
    defaultValues: { password: "", confirmPassword: "" },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(validationOpt);

  const [resetNow, setResetNow] = useState(false);

  const handleResetPassword = async (formData) => {
    await resetUserPassword({
      username: data?.data?.user.username,
      password: formData.password,
    });
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <SoftBox className={classes.resetContainer}>
      <SoftBox className={classes.logoBox}>
        <img src={GMGLogo} alt="Game My Biz" />
        <span
          style={{
            color: "#051923",
            fontSize: "20px",
            fontWeight: "500",
            marginLeft: "10px",
          }}
        >
          {" "}
          Game My Biz
        </span>
      </SoftBox>
      <SoftBox borderRadius="xl" shadow="lg" className={classes.leftPanel}>
        {data?.data?.user && !isError && !resetData && (
          <>
            <SoftTypography
              sx={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
                marginBottom: "15px",
                color: "#475467",
                "@media screen and (max-width: 700px)": {
                  lineHeight: "35px !important",
                },
              }}
            >
              Set a New Password
            </SoftTypography>
            <form onSubmit={handleSubmit(handleResetPassword)}>
              <SoftInput
                placeholder="New Password"
                label="New Password"
                {...register("password")}
                type={visible ? "text" : "password"}
                error={!!errors?.password}
                helperText={errors?.password?.message}
                icon={{
                  component: visible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  ),
                  direction: "right",
                  handler: () => {
                    setVisible(!visible);
                  },
                }}
              />
              <SoftBox mb="10px" />
              <SoftInput
                placeholder="Confirm Password"
                label="Confirm Password"
                {...register("confirmPassword")}
                type={visible ? "text" : "password"}
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                icon={{
                  component: visible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  ),
                  direction: "right",
                  handler: () => {
                    setVisible(!visible);
                  },
                }}
              />

              <SoftBox className={classes.buttonContainer}>
                <SoftButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.buttonStyle}
                  disabled={isLoading}
                >
                  Save New Password
                  {isLoading && (
                    <CircularProgress
                      style={{ color: "white" }}
                      size="1rem"
                      className="ml-2"
                    />
                  )}
                </SoftButton>
              </SoftBox>
              {(!data || isError) && (
                <h5 className={classes.textBoldError}>
                  Your password reset link has expired.
                </h5>
              )}
            </form>
            <ul className={classes.conditions}>
              <li className={classes.conditionItem}>
                "Your new password must be at least 8 characters long."
              </li>
              <li className={classes.conditionItem}>
                "Avoid using easily guessable information, such as your name or
                birthday, in your password."
              </li>
              <li className={classes.conditionItem}>
                "Passwords are case-sensitive."
              </li>
            </ul>
          </>
        )}

        {resetData?.data && data?.data && (
          <>
            <SoftTypography
              sx={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
                marginBottom: "15px",
                color: "#475467",
                "@media screen and (max-width: 700px)": {
                  lineHeight: "35px !important",
                },
              }}
            >
              Password Reset Successfully
            </SoftTypography>
            <SoftTypography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                marginBottom: "15px",
                color: "#475467",
              }}
            >
              Password have been updated
            </SoftTypography>
            <SoftBox className={classes.buttonContainer}>
              <SoftButton
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                className={classes.buttonStyle}
                onClick={() => navigate("/login")}
              >
                Back to login
              </SoftButton>
            </SoftBox>
          </>
        )}
      </SoftBox>
    </SoftBox>
  );
}

export default ResetPassword;
