const errorOnFocusStyles = (fieldError) => {
  if (!fieldError) return;

  return {
    "& .Mui-focused": {
      borderColor: "red !important",
      boxShadow: `0rem 0rem 0rem 0.125rem red !important`
    }
  }
}

export {
  errorOnFocusStyles
}
