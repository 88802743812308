import React from "react";
import SoftBox from "../../components/SoftBox";
import LockedOverlay from "../GameboardSettings/LockedOverlay";
import SoftTypography from "../../components/SoftTypography";

const VERTICAL_LABEL_SETTINGS = {
  height: "120px",
  slant: "70%"
};

const BADGUY_INFO = {
  title: null,
  description: null
};

const BadGuyCard = ({
  title = "Card Title",
  image,
  verticalLabel = VERTICAL_LABEL_SETTINGS,
  info = BADGUY_INFO,
  isLocked = false,
  fullCover = false,
  shouldReCenter = false
}) => {
  return (
    <SoftBox sx={{
      display: "flex",
      flexDirection: "column",
      width: "303px",
    }}>
      {/** Bad Guy Card */}
      <SoftBox
        sx={{
          width: "100%",
          height: "315px",
          borderRadius: "8px",
          ...(!isLocked && ({ border: "3px solid #FCFCFD" })),
          background: "linear-gradient(180deg, rgba(52, 64, 84, 0.56) 0%, rgba(115, 142, 186, 0.64) 100%)",
          position: "relative",
          zIndex: 1,
          marginBottom: "16px",

          "& .vertical-label": {
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "18.23px",
            color: "#000",
            writingMode: "vertical-rl",
            position: "absolute",
            padding: "8px 6px",
            height: verticalLabel?.height ?? VERTICAL_LABEL_SETTINGS.height
          },
          "& .vertical-label::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            backgroundColor: "#D9D9D9",
            borderTopLeftRadius: "5px",
            clipPath: `polygon(100% 0%, 100% ${verticalLabel?.slant ?? VERTICAL_LABEL_SETTINGS.slant}, 0% 100%, 0% 0%);`,
          }
        }}
      >
        {/** Locked overlay */}
        {(isLocked) && <LockedOverlay opacity={0.5} lockSize={54} borderRadius="5px" /> }

        {/** Vertical Side Label */}
        <SoftBox className="vertical-label">
          {title}
        </SoftBox>

        {/** Bacground image */}
        <SoftBox
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: shouldReCenter || fullCover
              ? "cover"
              : "contain",
            backgroundPosition: shouldReCenter || fullCover
              ? "center"
              : "right",
            width: "100%",
            height: "100%",
            zIndex: -3,
            position: "absolute",
            borderRadius: "5px"
          }}
        />
      </SoftBox>

      {/** Bad Guy Information */}
      {(info?.title) &&
        <SoftBox
          sx={{
            display: "inline-block !important",
            borderRadius: "3px",
            overflow: "hidden !important",

            "& > div": {
              width: "100%",
              minHeight: "113px",
              padding: "16px",
              background: "linear-gradient(180deg, rgba(52, 64, 84, 0.77) 6.82%, rgba(115, 142, 186, 0) 109.09%)",
              backgroundRepeat: "no-repeat",
              border: "3px solid",
              borderRadius: "8px",
              borderImageSource: "linear-gradient(178.89deg, rgba(113, 3, 149, 0.71) 0.95%, rgba(102, 102, 102, 0) 68.98%)",
              borderImageSlice: 1
            }
          }}
        >
          <SoftBox>
            <SoftTypography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "16px",
                color: "#FFF",
                lineHeight: "21px",
              }}
            >
              {info.title}
            </SoftTypography>

            <SoftTypography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: "12px",
                color: "#D0D5DD",
                lineHeight: "16px",
                marginTop: "8px"
              }}
            >
              {info.description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      }
    </SoftBox>
  );
};

export default BadGuyCard;
