import React from "react";

import PerformBox from "./PerformBox";
import PerformBoxMobile from "./PerformBoxMobile";

const PerformBoxFlexible = (props) => {
  const { isSmallScreen } = props;

  return isSmallScreen
    ? <PerformBoxMobile {...props} />
    : <PerformBox {...props} />
};

export default PerformBoxFlexible;
