import { useLongPress } from 'use-long-press';

export const UseButtonLongPress = ({activate, deactivate}) => {
  
  const longPress = useLongPress(
    () =>  activate(), // Long press detected, activate callback
    {
      onFinish: () => deactivate(),
      onCancel: () => deactivate(),
      filterEvents: () => true, 
      threshold: 750,
      captureEvent: true,
      cancelOnMovement: false,
      detect: 'both',
    }
  );
  return longPress;
};