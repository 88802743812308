import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftBox from "../../components/SoftBox";
import { UseUserDetails } from "../../hooks/auth";
import { ReactComponent as SendChatSvg } from "../../img/arena/SendMessage.svg";
import LoadingScreen from "../../components/LoadingScreen";
import Message from "../../components/Freemium/Scoreboard/Message";
import GmbLogo from "../../img/GameMyBiz.png";
import UpgradePopUp from "../../components/ChallengeArenaPopUp/UpgradePopUp";
import { UseSubscriptionLevel } from "../../hooks/company";
import { useNavigate } from "react-router-dom";

const PublicMessageBoard = ({
  messages,
  setPage,
  hasMore,
  currentPage,
  onAddComment,
  onChangeMessageInput,
  message,
  classname,
}) => {
  const { _id: userId } = UseUserDetails();
  const isSubscribed = UseSubscriptionLevel();
  const navigate = useNavigate();

  const [showUpgrade, setShowUpgrade] = useState(false);

  const closeUpgrade = () => {
    setShowUpgrade(false);
  };

  // Use Multiple containers to segregate filter effects;
  // having them in one main container causes the filter effects to overlap, thus making it different from figma
  return (
    <SoftBox className={`message-board ${classname}`}>
      <SoftBox className="message-board-box">
        <SoftBox
          shadow="lg"
          borderRadius="lg"
          sx={{
            background: {
              mini: "white",
              xl: "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)",
            },
            backdropFilter: { xl: "blur(17px)" },
            height: "100%",
            minHeight: "inherit",
            paddingX: "0.5rem",
          }}
        >
          <SoftBox
            sx={{
              position: "absolute",
              top: "0%",
              zIndex: 3,
              background:
                "linear-gradient(180deg, #596E7C 56.94%, rgba(89, 110, 124, 0.00) 99.45%)",
              left: 0,
              right: 0,
              marginX: "auto",
              borderTopRightRadius: "0.75rem",
              borderTopLeftRadius: "0.75rem",
              display: "flex",
              height: "130px",
            }}
          >
            <SoftBox
              sx={{
                maxWidth: {
                  mini: "120px",
                  xs: "160px",
                  lg: "220px",
                },
                boxShadow: 1,
                borderRadius: "12px",
                padding: "12px 24px",
                background: "#fff",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#1C84CF",
                marginX: "auto",
                height: "max-content",
                marginTop: "1rem",
              }}
            >
              <img
                src={GmbLogo}
                alt=""
                className="w-full cursor-pointer"
                onClick={() =>
                  userId && isSubscribed === 0
                    ? setShowUpgrade(true)
                    : navigate("/home")
                }
              />
            </SoftBox>

            {showUpgrade && (
              <UpgradePopUp
                showUpgrade={showUpgrade}
                closeUpgrade={closeUpgrade}
              />
            )}
          </SoftBox>
          <SoftBox
            className="no-scrollbar"
            id="scrollableDiv"
            sx={{
              background: "transparent",
              maxHeight: "500px",
              overflowY: "scroll",
              "& div:last-child": {
                marginBottom: 0,
              },
              py: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
              marginBottom: "4rem",
              paddingBottom: "0px",
            }}
          >
            {!messages.length && (
              <SoftTypography variant="body2">No Comments Yet</SoftTypography>
            )}

            <InfiniteScroll
              style={{
                overflow: "hidden",
                padding: 4,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              dataLength={messages.length}
              next={() => {
                setPage(currentPage + 1);
              }}
              inverse
              hasMore={hasMore}
              loader={<LoadingScreen height="50px" logoHeight="50px" />}
              scrollableTarget="scrollableDiv"
            >
              {messages.map((item, idx) => (
                <Message
                  name={item.users[0].firstName}
                  time={item.createdAt}
                  message={item.content}
                  key={item._id}
                  img={item.users[0]?.image ?? item.users[0]?.company?.logo}
                  userId={item.userId}
                  prevUserId={idx > 0 ? messages[idx - 1].userId : null}
                />
              ))}
            </InfiniteScroll>
          </SoftBox>
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              gap: 1,
              flexGrow: 1,
              position: "absolute",
              bottom: "1rem",
              width: "95%",
            }}
          >
            <SoftInput
              className="w-full"
              placeholder={userId ? "Type A Message" : "Login to Comment"}
              multiline
              rows={1}
              onChange={onChangeMessageInput}
              value={message}
              disabled={!userId}
              sx={{
                color: "#FFF !important",
                backgroundColor: "rgba(18, 95, 153, 0.30) !important",
                border: "0 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "rgba(255, 255, 255, 0.40) !important",
                },
              }}
            />
            <SoftBox
              sx={{
                borderRadius: 2,
                padding: "0",
                cursor: "pointer",
                height: "40px",
              }}
              onClick={onAddComment}
              disabled={!userId}
              className="flex justify-between items-center"
            >
              <SendChatSvg style={{ fontSize: "4rem" }} />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default PublicMessageBoard;
