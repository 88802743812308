import { initState, types } from "./types";

const arenaReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_CHALLENGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case types.CREATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: null,
      };
    case types.CREATE_CHALLENGE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default arenaReducer;
