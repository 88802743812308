import React from "react";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import emailimage from "../../img/email-verification.png";
import { Grid } from "@mui/material";
import GMGLogo from "../../img/GMB_Logo.svg";

const ThankYou = ( ) => {

  return (
    <Grid
    container
    spacing={2}
    className="w-full flex justify-center mt-5 pt-5">
        <Grid item xs={12} sm={12} className="flex justify-center items-center mb-5" >
            <img src={GMGLogo} alt="Game My Biz" />
            <span style={{color: "#051923", fontSize: "20px", fontWeight: "500", marginLeft: "10px"}}> Game My Biz</span>
        </Grid>
        <Grid item sm={4}>
        <SoftBox borderRadius="xl" shadow="lg" className="p-10">
          <img className="mb-5" style={{margin: "0 auto"}} src={emailimage} alt="Email Verification" />
          <h2 style={{textAlign: "center", fontWeight: "bold"}}>Check your Email!</h2>
          <p className="mt-3" style={{textAlign: "center"}}>Welcome to Game My Biz</p>
          <p className="mt-3" style={{textAlign: "center"}}>Hey, we are happy to have you here, <br />
            Kindly click the link below to Confirm your Email address</p>
            <a href="/login">
              <SoftButton 
                fullWidth
                style={{borderRadius: "8px", background: "linear-gradient(120deg, rgba(0, 36, 224, 0.65) 0%, rgba(166, 0, 224, 0.85) 100%)", color: "#FFFFFF"}} className="mb-2 mt-5">
                Click to Login
              </SoftButton>
            </a>
        </SoftBox>
      </Grid>
    </Grid>
  );
};

export default ThankYou;
