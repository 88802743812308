import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import Chart from "react-apexcharts";

import SoftBox from "../../components/SoftBox";

import { GET_ALL_COMPANY_SCORES } from "../../api/kpi";
import { UseHolidays, UseCompanyWorkspaces } from "../../hooks/company";
import { UseCompanyKpis } from "../../hooks/kpi";

import options from "./config/graphOptions";
import SoftTypography from "../../components/SoftTypography";
import KpiAverageBox from "./KpiAverageBox";
import LoadingScreen from "../../components/LoadingScreen";

const KpiHistory = ({ currentWorkspace, scores }) => {
  const [scoreData, setScoreData] = useState(null);
  const [charts, setCharts] = useState(null);
  const [title, setTitle] = useState(null);
  const [kpis, setKpis] = useState([]);
  const companyKpis = UseCompanyKpis();
  const workspaces = UseCompanyWorkspaces(true);
  const holidays = UseHolidays();

  useEffect(() => {
    // Fetch daily breakdown for scores; scores from parent Company View are summarized per month so it's not compatible
    GET_ALL_COMPANY_SCORES("daily").then(({ data }) => {
      setScoreData(data.scores);
    });
  }, []);

  useEffect(() => {
    if (!scoreData) return;
    if (workspaces?.length <= 0) return;
    if (!companyKpis || Object.values(companyKpis).length <= 0) return;

    const chartData = {};

    workspaces.forEach((workspace) => {
      const currentKpis = [];

      // Acquire specific workspace KPIs
      const { _id, name, parentId } = workspace;
      companyKpis[_id]?.forEach((kpi) => {
        currentKpis.push({
          ...kpi,
          workspace: {
            id: _id,
            name,
            parentId,
          },
        });
      });

      const today = moment();
      const monthStart = moment().startOf("month");
      const totalDays = today.diff(monthStart, "days");

      const playerScores = [];
      const yetiScores = [];
      const coveredDates = [];

      for (let d = 0; d <= totalDays; d += 1) {
        const day = moment(monthStart).add(d, "days");
        const date = day.format("YYYY-MM-DD");

        const isHoliday =
          day.day() === 0 ||
          day.day() === 6 ||
          holidays.some((h) => h.date === date);

        if (!isHoliday || scoreData[date]) {
          const dayScore = currentKpis.reduce((total, kpi) => {
            const score = scoreData[date]?.[kpi._id] ?? 0;
            const ratio = score / (kpi.target || 1);
            return total + ratio;
          }, 0);

          playerScores.push(
            Math.round(dayScore * 100) / (100 * currentKpis?.length),
          );
          yetiScores.push(isHoliday ? 0 : 1);
          coveredDates.push(day);
        }
      }

      chartData[workspace?._id] = {
        workspace: workspace?.name,
        players: playerScores,
        yeti: yetiScores,
        dates: coveredDates,
      };
    });

   setCharts(chartData);
  }, [companyKpis, workspaces, scoreData, holidays]);

  const chartOptions = useMemo(() => {
    if (charts && currentWorkspace) {
      const selectedWorkspace = workspaces.find(
        (w) => w._id === currentWorkspace,
      );
      setTitle(selectedWorkspace?.name);

      const range = Math.ceil(
        charts[currentWorkspace]?.players.reduce((a, b) => Math.max(a, b), 1),
      );
      return {
        ...options,

        chart: {
          ...options.chart,
        },
        xaxis: {
          ...options.xaxis,
          categories: charts[currentWorkspace]?.dates,
        },
        yaxis: {
          ...options.yaxis,
          min: (-1 * range) / 4,
          max: range,
        },
      };
    }
    return null;
  }, [charts, currentWorkspace]);

  useEffect(() => {
    if (companyKpis && scores && currentWorkspace) {
      const workspaceKpis = companyKpis[currentWorkspace];
      const workspaceScores = scores.find(
        (s) => s.workspaceId === currentWorkspace,
      );

      const currentKpis = workspaceKpis?.map((kpi) => {
        let kpiScore = 0;
        let kpiRatio = 0;
        if (workspaceScores) {
          const kScore = workspaceScores?.scores.find(
            (s) => s.kpiId === kpi._id,
          );
          kpiScore = kScore?.score ?? 0;
          kpiRatio = Math.min(Math.max(kScore?.ratio ?? 0, -1), 1);
        }
        return {
          ...kpi,
          score: kpiScore,
          ratio: kpiRatio,
        };
      });
      setKpis(currentKpis);
    }
  }, [companyKpis, scores, currentWorkspace]);

  return (
    <SoftBox shadow="sm" borderRadius="xxl" className="py-2 px-4 h-full">
      <SoftTypography variant="h4" sx={{ color: "#343A40" }}>
        {title ?? "Workspace"}
      </SoftTypography>
      <SoftTypography
        variant="body2"
        sx={{ color: "#64748B", fontSize: "11px", lineHeight: "1" }}
      >
        Performance against the yeti
      </SoftTypography>
      <SoftBox className="flex flex-col">
        <SoftBox
          borderRadius="xl"
          sx={{
            px: 2,
            mt: 2,
            background: "rgba(205, 215, 235, 0.24)",
            height: { xs: "180px", md: "260px", lg: "360px" },
          }}
        >
          {charts && currentWorkspace && chartOptions ? (
            <Chart
              series={[
                {
                  name: "Players",
                  data: charts[currentWorkspace].players,
                },
                {
                  name: "Yeti",
                  data: charts[currentWorkspace].yeti,
                },
              ]}
              options={chartOptions}
              type="area"
              height="100%"
              width="100%"
            />
          ) : (
            <SoftBox className="h-full w-full flex justify-center items-center">
              <LoadingScreen height="200px" logoHeight="200px" />
            </SoftBox>
          )}
        </SoftBox>
        <SoftBox className="grow flex flex-row py-4 gap-4 justify-between">
          {kpis?.length > 0
            ? kpis.map((kpi) => <KpiAverageBox kpi={kpi} />)
            : null}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default KpiHistory;
