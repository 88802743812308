import React, { useState, useMemo } from "react";
import { Grid } from "@mui/material";

import { UseUserId } from "../../../hooks/auth";
import GoldCrown from "../../../img/arena/GoldCrown.svg";
import SilverCrown from "../../../img/arena/SilverCrown.svg";
import BronzeCrown from "../../../img/arena/BronzeCrown.svg";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import useMediaQuery from "@mui/material/useMediaQuery";
import SoftTypography from "../../SoftTypography";
import SoftBox from "../../SoftBox";

const PLACES = [1, 0, 2];

const ORDER = ["1ST", "2ND", "3RD"];
const FONTSIZE = ["3rem", "2.5rem", "2rem"];
const FONTSIZE_MOBILE = ["2rem", "1.5rem", "1.5rem"];
const COLORS = ["primary", "tertiary", "secondary"];
const PODIUMS = [1, 0.85, 0.75];

const Leaders = ({ top3 }) => {
  const [podiums] = useState(PODIUMS);
  const userId = UseUserId();
  const userIndex = top3.findIndex((data) => data.userId === userId);
  const textBp = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const smallBp = useMediaQuery((theme) => theme.breakpoints.down("xxs"));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMidsizeScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const podiumConfig = useMemo(() => {
    // Supports only up to 320px mobile screen width
    if (smallBp) {
      return { container: 200, elem: 205, ratio: 1.1, platform: 0.2 }
    } else if (isSM) {
      return { container: 240, elem: 240, ratio: 1.1, platform: 0.5 }
    }
    else if (isMidsizeScreen) {
      return { container: 300, elem: 300, ratio: 1.1, platform: 0.5 }
    }
    else {
      return { container: 420, elem: 450, ratio: 1.1, platform: 0.5 }
    }
  }, [isSM, smallBp, isMidsizeScreen]);

  return (
    <SoftBox
      className="container w-full h-full flex flex-col justify-center items-center"
      sx={{
        paddingBottom: { mini: 4, lg: 0 },
      }}
    >
      <Grid container className={`w-full h-full min-h-[${podiumConfig.container}px] flex items-end`}>
        {PLACES.map((rank, idx) => {
          return (
            <Grid
              item
              key={rank}
              mini={4}
              className="w-full h-full flex flex-col items-end"
            >
              <SoftBox
                className="w-full flex flex-col items-center px-[1px] transition-height duration-500"
                sx={{
                  height: `${podiums[rank]}`,
                  minHeight: `${podiums[rank] * 450}px`,
                  ...(isMidsizeScreen && ({
                    height: `${podiums[rank] * podiumConfig.ratio}`,
                    minHeight: `${podiums[rank] * podiumConfig.ratio * podiumConfig.elem}px`,
                   }))
                }}
              >
                <SoftBox className="flex flex-col items-center">
                  <SoftBox className="w-full flex justify-center pb-[1rem]">
                  {rank === 0 ? (
                    <img src={GoldCrown} alt="Top 1" className={`w-auto h-[${isSM ? 1.5 : 3}rem]`} />
                    ) : null}
                  {rank === 1 ? (
                  <img src={SilverCrown} alt="Top 2" className={`w-auto h-[${isSM ? 1.5 : 3}rem]`}  />
                  ) : null}
                  {rank === 2 ? (
                  <img src={BronzeCrown} alt="Top 3" className={`w-auto h-[${isSM ? 1.5 : 3}rem]`}  />
                  ) : null}
                  </SoftBox>
                  <SoftProfile
                    src={top3[rank]?.image}
                    name={top3[rank]?.firstName}
                    // eslint-disable-next-line no-nested-ternary
                    size={smallBp ? "lg" : textBp ? "xl" : "xxl"}
                    glow={userIndex === rank}
                    fullCover
                  />
                  <SoftBox
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      gap: "0.25rem",
                      flexWrap: "wrap",
                      marginY: "1rem",
                    }}
                  >
                    <SoftTypography
                      variant={textBp ? "h6" : "h5"}
                      color="black"
                      sx={{
                        position: "relative",
                        textAlign: "center",
                        ...(isMidsizeScreen && ({ fontSize: "10.5px !important", lineHeight: "15.31px", color: "#98A2B3" }))
                      }}
                    >
                      {top3[rank]?.firstName || "Anonymous"}
                    </SoftTypography>
                    <SoftTypography
                      variant={textBp ? "h6" : "h5"}
                      color="black"
                      sx={{
                        position: "relative",
                        textAlign: "center",
                        ...(isMidsizeScreen && ({ fontSize: "10.5px !important", lineHeight: "15.31px", color: "#98A2B3" }))
                      }}
                    >
                      {top3[rank]?.lastName || "Player"}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox className="flex flex-col flex-1 w-full text-white">
                  <SoftBox
                    sx={{
                      width: `100% + ${podiumConfig.platform}rem`,
                      height: 0,
                    }}
                  />

                  <SoftBox
                    className="flex-1 w-full flex justify-center relative"
                    sx={{ bgcolor: `${COLORS[rank]}.main` }}
                  >
                    {(!isMidsizeScreen) &&
                      <SoftBox
                        className="absolute bottom-[1rem] rounded-full py-1 text-center"
                        sx={{
                          bgcolor: "rgba(0,0,0,0.25)",
                          paddingX: { mini: 2, sm: 4 },
                        }}
                      >
                        <SoftTypography
                          className="font-bold"
                          variant={textBp ? "caption" : "h5"}
                          sx={{ color: "secondary.contrastText" }}
                        >
                          {top3[rank]?.scores.total
                            ? Math.round(top3[rank].scores.total)
                            : 0}
                        </SoftTypography>
                      </SoftBox>
                    }
                    <SoftBox
                      sx={{
                        position: "absolute",
                        top: { md: "0.5rem" },
                      }}
                    >
                      <SoftTypography
                        className={isMidsizeScreen ? "none" : "font-bold"}
                        sx={{
                          fontSize: (isSM)
                            ? FONTSIZE_MOBILE[rank]
                            : FONTSIZE[rank],
                          ...(smallBp && rank === 2  && ({ fontSize: "1rem !important" })),
                          color: "secondary.contrastText",
                        }}
                      >
                        {(isMidsizeScreen)
                          ? ORDER[rank].substring(0, 1)
                          : ORDER[rank]
                        }
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Grid>
          );
        })}
      </Grid>
    </SoftBox>
  );
};

export default Leaders;
