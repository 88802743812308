import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { TableRow, TableCell } from "@mui/material";

import { ADD_GROUP_MEMBER } from "../../api/group";
import { UseUserId } from "../../hooks/auth";
import { getCompanyUsers } from "../../redux/company/actions";
import SoftTypography from "../../components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import RemovePlayer from "./RemovePlayer";
import ArchivePlayer from "./ArchivePlayer";
import BillingUpdate from "./BillingUpdate";
import UpdatePlayerRole from "./UpdatePlayerRole";

const User = ({ user, workspaces, archiveUser }) => {
  const authUserId = UseUserId();
  const isLoggedInUser = useMemo(
    () => authUserId === user?.userId,
    [authUserId, user._id],
  );
  const [billingUpdateDialog, setBillingUpdateDialog] = React.useState(false);
  const dispatch = useDispatch();

  workspaces = workspaces.map((item) => ({
    ...item,
    value: item._id,
    label: item.name,
  }));
  const initialDept = workspaces.filter((item) => item._id === user.groupId);

  const { mutateAsync: addGroupMember } = ADD_GROUP_MEMBER();
  const [department, setDepartment] = React.useState(initialDept);

  const openBillingUpdateDialog = () => setBillingUpdateDialog(true);
  const closeBillingUpdateDialog = () => setBillingUpdateDialog(false);

  const handleChange = async (selectedOption) => {
    setDepartment(selectedOption);
    await addGroupMember({
      groupId: selectedOption.value,
      data: { userId: user.userId },
    });
    dispatch(getCompanyUsers());
  };

  return (
    <>
      {billingUpdateDialog && (
        <BillingUpdate
          billingUpdateDialog={billingUpdateDialog}
          closeBillingUpdateDialog={closeBillingUpdateDialog}
        />
      )}
      <TableRow>
        <TableCell>
          <SoftTypography
            sx={{ color: "#191D23", fontWeight: 500, fontSize: "18px" }}
          >
            {user.firstName} {user.lastName}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <Select
            options={workspaces}
            value={department}
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <SoftTypography
            sx={{
              background: "#ECFDF5",
              color: "#064E3B",
              padding: "5px 15px",
              fontSize: "15px",
              fontWeight: 600,
              borderRadius: "4px",
              width: "min-content",
            }}
          >
            {user.isVerified ? "Accepted" : "Not yet Accepted"}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <UpdatePlayerRole user={user} isLoggedInUser={isLoggedInUser} />
          {/* <Tooltip
            disableFocusListener
            title={`Click to set as ${user.isAdmin ? "Player" : "Admin"}`}
            placement="top"
          >
            <SoftButton
              size="small"
              variant={user.isAdmin ? "gradient" : "outlined"}
              color="primary"
              disabled={isLoggedInUser}
              sx={{ width: "8rem", borderRadius: "4px" }}
              onClick={() => handleAdmin(user.userId, user.isAdmin)}
            >
              {user.isAdmin ? (
                <>
                  Admin <PsychologyIcon fontSize="small" />
                </>
              ) : (
                <>
                  Player <SportsEsportsIcon fontSize="small" />
                </>
              )}
            </SoftButton>
          </Tooltip> */}
        </TableCell>
        <TableCell>
          <ArchivePlayer
            isLoggedInUser={isLoggedInUser}
            archiveUser={archiveUser}
            user={user}
            archiveStatus={user.isArchived}
            openBillingUpdateDialog={openBillingUpdateDialog}
          />

          <RemovePlayer
            isLoggedInUser={isLoggedInUser}
            user={user}
            openBillingUpdateDialog={openBillingUpdateDialog}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
export default User;
