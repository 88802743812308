import React from "react";

const HighlightKeyword = ({ text, keyword, color }) => {
  if (!keyword) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${keyword})`, "gi"));

  return (
    <span>
      {parts.map((part, index) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`${part}-${index}`}
          style={part.toLowerCase() === keyword.toLowerCase() ? { color } : {}}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightKeyword;
