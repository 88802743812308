/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftTypography from "../SoftTypography/index";
import SoftBox from "../SoftBox/index";

// Soft UI Dashboard PRO React example components
import DefaultItem from "./EventItems";
import moment from "moment";

function NextEvents({ monthEvents }) {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Events
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        {!monthEvents.length ? (
          <SoftTypography variant="p">No upcoming events</SoftTypography>
        ) : (
          monthEvents.map((item, index) => (
            /* eslint-disable */
            <div
              key={`${item.title}-${index}`}
              className="bg-[#00000014] px-[0.5rem] py-[0.75rem] rounded-2xl"
              style={{ marginBottom: "20px" }}
            >
              <DefaultItem
                color="error"
                title={item.title}
                description={moment(item.start).format("MMMM Do, YYYY")}
              />
            </div>
          ))
        )}
      </SoftBox>
    </Card>
  );
}

export default NextEvents;
