import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

import AddHubLink from "./AddHubLink";
import PlugPlay from "./PlugPlay";
import { GET_HUB_ITEMS } from "../../api/hub";
import { UseIsAdmin, UseUserId } from "../../hooks/auth";
import { UseSelectedWorkspace } from "../../hooks/company";
import { Divider, Grid, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SoftInput from "../SoftInput";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import { GET_SOURCE_IMAGE } from "../../api/pluginmaster";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import SoftDialog from "../SoftConfirmationDialog";

const PowerLinks = () => {
  const workspace = UseSelectedWorkspace();
  const userId = UseUserId();
  const isAdmin = UseIsAdmin();
  const [plugins, setPlugins] = useState([]);
  const [currentPlugin, setCurrentPlugin] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [displayTitle, setDisplayTitle] = useState("");
  const [displayWeb, setDisplayWeb] = useState("");
  const [query, setQuery] = useState("");
  const [filteredPlugins, setFilteredPlugins] = useState([]);
  const [pluginMaster, setPluginMaster] = useState([]);
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);

  useEffect(() => {
    GET_HUB_ITEMS(workspace._id, isAdmin ? null : userId).then(({ data }) => {
      setPlugins(data);
    });
  }, [workspace, isAdmin, userId, refetch]);

  useEffect(() => {
    GET_SOURCE_IMAGE().then(({ data }) => {
      setPluginMaster(data);
    });
  }, []);

  const getFilteredPlugins = (e) => {
    setQuery(e.target.value);
    setFilteredPlugins(
      plugins.filter((plugin) =>
        plugin.title.toLowerCase().includes(e.target.value),
      ),
    );
  };

  const DisplayPlugins = () => {
    if (plugins.length) {
      if (!query) {
        return plugins.map((plugin) => {
          return (
            <Grid item lg={3} md={6} sm={6}>
              <PlugPlay
                key={plugin._id}
                id={plugin._id}
                url={plugin.url}
                customImage={plugin.logo}
                name={plugin.title}
                type={plugin.type?.source}
                fallbackImageUrl={plugin.type?.imageSrc}
                desc={plugin.desc}
                assignedTo={plugin.assignedTo}
                personValue={plugin.count}
                switchOn={plugin.isEnabled}
                isOwner={plugin.userId === userId}
                onEnableChange={() => setRefetch(!refetch)}
                onEdit={(event) => {
                  event?.stopPropagation();
                  setOpenModal(true);
                  setEditMode(true);
                  setCurrentPlugin(plugin);
                }}
              />
            </Grid>
          );
        });
      } else {
        return filteredPlugins.map((plugin) => {
          return (
            <Grid item lg={3} md={6} sm={6}>
              <PlugPlay
                key={plugin._id}
                id={plugin._id}
                url={plugin.url}
                customImage={plugin.logo}
                name={plugin.title}
                type={plugin?.type?.source}
                desc={plugin.desc}
                assignedTo={plugin.assignedTo}
                personValue={plugin.count}
                switchOn={plugin.isEnabled}
                isOwner={plugin.userId === userId}
                onEnableChange={() => setRefetch(!refetch)}
                onEdit={(event) => {
                  event?.stopPropagation();
                  setOpenModal(true);
                  setEditMode(true);
                  setCurrentPlugin(plugin);
                }}
              />
            </Grid>
          );
        });
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {dialog && (
        <SoftDialog
          open={dialog}
          onClose={closeDialog}
          title="You've successfully added a Power Link!"
          type="success"
          icon={<CheckIcon fontSize="large" sx={{ color: "#299E47" }} />}
          description={
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              mt="25px"
              mb="35px"
            >
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                Name: {displayTitle}
              </SoftTypography>
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                URL: {displayWeb}
              </SoftTypography>
            </SoftBox>
          }
          footer={
            <SoftBox display="flex" gap="10px" justifyContent="center">
              <SoftButton
                size="small"
                color="white"
                onClick={closeDialog}
                sx={{
                  width: "min-content",
                  borderRadius: "4px",
                  textTransform: "none",
                  color: "#344054",
                }}
              >
                Okay
              </SoftButton>
            </SoftBox>
          }
        />
      )}
      <SoftBox bgColor="white" borderRadius="16px">
        <SoftBox
          padding={{
            mini: "1rem 1rem 0rem 1rem",
            md: "1.5rem 1.5rem 0rem 1.5rem",
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftTypography
                variant="h5"
                sx={{
                  color: "#343A40",
                  display: { mini: "none", lg: "block" },
                }}
              >
                Power Links
              </SoftTypography>
              <SoftTypography
                variant="body2"
                sx={{
                  color: "#6C757D",
                  display: { mini: "none", lg: "block" },
                }}
              >
                Customize your workspace by adding links to external tools and
                resources.
              </SoftTypography>
              <SoftBox
                sx={{
                  display: { mini: "flex", lg: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                  "& > div": {
                    width: "100%",
                  },
                }}
              >
                <SoftInput
                  placeholder="Search"
                  size="large"
                  icon={{
                    component: <SearchSvg />,
                    direction: "left",
                  }}
                  onChange={getFilteredPlugins}
                  sx={{
                    width: "100%",
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" gap="0.5rem">
              <SoftBox
                sx={{
                  display: { mini: "none", lg: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                  "& > div": {
                    width: "100%",
                  },
                }}
              >
                <SoftInput
                  placeholder="Search"
                  size="large"
                  icon={{
                    component: <SearchSvg />,
                    direction: "left",
                  }}
                  onChange={getFilteredPlugins}
                  sx={{
                    width: "100%",
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </SoftBox>

              <SoftBox>
                <SoftButton
                  variant="outlined"
                  size="large"
                  sx={{
                    padding: { mini: "4px 8px", md: "10px 25px" },
                    textTransform: "none",
                    borderColor: "#E9ECEF",
                    borderRadius: "10px",
                    color: "black !important",
                    "&:hover": {
                      borderColor: "#E9ECEF",
                    },
                    "&:focus:not(:hover)": {
                      borderColor: "#E9ECEF",
                    },
                    /* Hide Add Custom Link button for mobile */
                    display: { mini: "none", sm: "flex" },
                    justifyContent: "between",
                    gap: 1,
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  <SoftBox
                    width="24px"
                    height="24px"
                    bgColor="primary"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                  >
                    <AddRoundedIcon
                      sx={{
                        fill: "white",
                      }}
                    />
                  </SoftBox>
                  Add a custom link
                </SoftButton>
                {openModal && (
                  <AddHubLink
                    isOpen={openModal}
                    onClose={() => {
                      setCurrentPlugin({});
                      setOpenModal(false);
                      setEditMode(false);
                    }}
                    openDialog={openDialog}
                    isEdit={editMode}
                    data={currentPlugin}
                    refetch={() => setRefetch(!refetch)}
                    setDisplayTitle={setDisplayTitle}
                    setDisplayWeb={setDisplayWeb}
                  />
                )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>

        <Divider
          sx={{
            backgroundColor: "#F2F4F7",
          }}
        />

        <SoftBox
          sx={{
            padding: {
              mini: "0.5rem 1rem 1rem 1rem",
              md: "0.75rem 1.5rem 1.5rem 1.5rem",
            },
          }}
        >
          <Grid container spacing={3}>
            <DisplayPlugins />
          </Grid>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default PowerLinks;
