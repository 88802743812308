import React, { useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import toast from "react-hot-toast";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { DELETE_COLLEAGUE } from "../../api/user";

const RemovePlayer = ({ isLoggedInUser, user, openBillingUpdateDialog }) => {
  const [removePlayer, setRemovePlayer] = useState(false);
  const [loading, setLoading] = useState(false);

  const openRemoveDialog = () => setRemovePlayer(true);
  const closeRemoveDialog = () => setRemovePlayer(false);

  const deleteUser = async () => {
    setLoading(true);
    try {
      await DELETE_COLLEAGUE(user.userId);
      setLoading(false);
      closeRemoveDialog();
      openBillingUpdateDialog();
    } catch (error) {
      console.log("Error deleteUser", error.response);
      toast.error("Unable to delete the user");
      setLoading(false);
    }
  };

  return (
    <>
      <SoftButton
        size="small"
        variant="outlined"
        color="error"
        disabled={isLoggedInUser}
        onClick={openRemoveDialog}
        sx={{
          width: "min-content",
          borderRadius: "4px",
          textTransform: "none",
        }}
      >
        Remove
      </SoftButton>
      {removePlayer && (
        <Dialog
          open={removePlayer}
          onClose={closeRemoveDialog}
          sx={{
            "& .MuiPaper-root": {
              padding: { mini: "1rem", md: "1.5rem 1.7rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "700px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="outlined"
              color="white"
              onClick={closeRemoveDialog}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb="60px"
          >
            <QuestionMarkIcon
              sx={{ fontSize: "100px !important" }}
              color="white"
            />
            <SoftTypography
              component="h4"
              sx={{
                fontWeight: 700,
                color: "#FFF",
                fontSize: 24,
                marginBottom: "20px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              Are you sure you want to remove {user.firstName} {user.lastName}?
            </SoftTypography>
            <SoftTypography
              component="p"
              sx={{
                fontWeight: 500,
                color: "#FFF",
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Confirming this Action will update your Subscription
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="space-between">
            <SoftButton
              color="white"
              variant="outlined"
              disabled={loading}
              onClick={closeRemoveDialog}
              sx={{
                textTransform: "none",
                borderRadius: "4px",
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={deleteUser}
              disabled={loading}
              sx={{
                mx: 2,
                textTransform: "none",
                borderRadius: "4px",
                color: "primary.contrastText",
              }}
            >
              Remove Player
              {loading && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  className="ml-2"
                />
              )}
            </SoftButton>
          </SoftBox>
        </Dialog>
      )}
    </>
  );
};

export default RemovePlayer;
