import * as Yup from "yup";

const JoinChallengeSchema = Yup.object().shape({
  firstName: Yup.string().required("Field is required"),
  lastName: Yup.string().required("Field is required"),
  email: Yup.string()
    .email("Provide a valid email")
    .required("Field is required"),
});

export default JoinChallengeSchema;
