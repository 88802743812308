import React from "react";
import CalendarContainer from "../../components/Calendar/CalendarContainer";
import PageHeader from "../../components/PageHeader";
import SoftBox from "../../components/SoftBox";

const Calendar = () => {
  return (
    <>
      <PageHeader headerText="Calendar" />
      <SoftBox
        borderRadius="lg"
        shadow="sm"
        sx={{
          margin: { mini: "1rem", sm: "1.5rem" },
          padding: { mini: "0.5rem 0rem", md: "1rem" },
        }}
      >
        <CalendarContainer />
      </SoftBox>
    </>
  );
};

export default Calendar;
