import { request } from "../helpers/axios.new";

export const SET_KPI_PREFERENCES = (payload) => {
  return request({
    url: `/preference/settings`,
    method: "POST",
    data: payload
  });
};

export const GET_KPI_PREFERENCES = (groupId, field = null) => {
  const queryField = field ? `&queryField=${field}` : "";

  return request({
    url: `/preference/all?groupId=${groupId}${queryField}`,
    method: "GET"
  });
};

export const GET_KPI_COLUMS_PREFERENCE = (groupId, category) => {
  return request({
    url: `/preference/kpi/columns?groupId=${groupId}&category=${category}`,
    method: "GET",
  });
};
