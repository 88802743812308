import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import SendIcon from "@mui/icons-material/Send";
import UploadIcon from "@mui/icons-material/Upload";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  useTheme,
} from "@mui/material";
import { toast } from "react-hot-toast";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import socket from "../../configs/socket";
import socketConstants from "../../constants/socket";
import {
  fetchMessages,
  addChatMessage,
  sendMessageToChat,
  setSelectedChat,
  getChats,
} from "../../redux/chats/actions";
import SoftInput from "../SoftInput";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import blankPic from "../../img/blankPic.png";
import { getReceiverName, getReceiverImage } from "./helpers/index";
import { UseUserDetails } from "../../hooks/auth";
import UpdateGroupChatModal from "./UpdateGroupChatModal";
import LoadingScreen from "../LoadingScreen";
import UnselectedChat from "./UnselectedChat";
import { GET_IMAGE } from "../../helpers/images";
import {
  addNotification,
  createNotification,
} from "../../redux/notifications/actions";
import { ReactComponent as SendMessageIcon } from "../../img/message-sendIcon.svg";
import MsgChatBoxBg from "../../img/messages-chatboxBg.png";

let selectedChatCompare;

const ChatBox = ({ reFetchChats, setReFetchChats, selectedHandler }) => {
  const fileInputRef = useRef(null);
  const userDetails = UseUserDetails();
  const dispatch = useDispatch();
  const { selectedChat, messages, currentMessagePage, hasMoreMessages } =
    useSelector((state) => state.chatsReducer);

  const { SOCKET_EVENT } = socketConstants;
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
  } = theme.palette;

  const [newMessage, setNewMessage] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    users: [],
  });

  const handleGalleryIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Read the image file and convert it to a data URL
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile({
        file,
        previewURL: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    socket.emit(SOCKET_EVENT.SETUP_CHAT, userDetails);
    socket.on(SOCKET_EVENT.TYPING, () => setIsTyping(true));
    socket.on(SOCKET_EVENT.STOP_TYPING, () => setIsTyping(false));
  }, []);

  const onChangeTrigger = (e) => {
    setNewMessage(e.target.value);

    if (!typing) {
      setTyping(true);
      socket.emit(SOCKET_EVENT.TYPING, selectedChat._id);
    }

    const lastTypingTime = new Date().getTime();
    const timerLength = 2000;

    setTimeout(() => {
      const timeNow = new Date().getTime();
      const timeDiff = timeNow - lastTypingTime;

      if (timeDiff >= timerLength && typing) {
        socket.emit(SOCKET_EVENT.STOP_TYPING, selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };

  const onSendTrigger = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newMessage !== "" || selectedFile) {
      socket.emit(SOCKET_EVENT.STOP_TYPING, selectedChat._id);

      const formData = new FormData();
      formData.append("chatId", selectedChat._id);
      formData.append("message", newMessage.trim());

      if (selectedFile) {
        formData.append("image", selectedFile.file);
      }

      setNewMessage("");
      setSelectedFile(null);
      try {
        const payload = {
          formData,
          selectedHandler,
        };

        dispatch(sendMessageToChat(payload));
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
      }
    }
    setLoading(false);
  };

  const fetchAllMessages = async () => {
    try {
      dispatch(fetchMessages({ id: selectedChat._id, page }));

      socket.emit(SOCKET_EVENT.JOIN_CHAT, selectedChat._id);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (selectedChat && selectedChat._id) {
      fetchAllMessages();
      selectedChatCompare = selectedChat;
    }
  }, [selectedChat, page]);

  useEffect(() => {
    socket.on(SOCKET_EVENT.MESSAGE_RECEIVED, (receivedMessage) => {
      const payload = {
        loading: false,
      };
      dispatch(getChats(payload));
      if (
        !selectedChatCompare || // if chat is not selected or doesn't match current chat
        selectedChatCompare._id !== receivedMessage.chat._id
      ) {
        receivedMessage.chat.users.forEach((user) => {
          if (userDetails._id !== receivedMessage.sender._id) {
            const chatNotiData = {
              action: "chat",
              senderName: receivedMessage.chat.isGroupChat
                ? receivedMessage.chat.chatName
                : `${receivedMessage.sender.firstName} ${receivedMessage.sender.lastName}`,
              sender: receivedMessage.sender._id,
              receiver: user._id,
              is_read: false,
              chat: receivedMessage.chat._id,
              image: receivedMessage.sender.image
                ? receivedMessage.sender.image
                : blankPic,
              timeElapsed: "Just now",
            };

            if (userDetails._id === user._id) {
              dispatch(addNotification(chatNotiData));
            }
            dispatch(createNotification(chatNotiData));
          }
        });
      } else {
        dispatch(addChatMessage({ receivedMessage }));
      }
    });
  }, []);

  const UploadMessageIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic:baseline-upload">
        <path
          id="Vector"
          d="M5 20H19V18H5V20ZM5 10H9V16H15V10H19L12 3L5 10Z"
          fill="url(#paint0_linear_4950_25354)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4950_25354"
          x1="16.4288"
          y1="59.9439"
          x2="1.39685"
          y2="58.9763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={secondaryColor} />
          <stop offset="1" stopColor={primaryColor} />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <SoftBox sx={{ height: { mini: "65vh", md: "70vh" } }}>
      {openModal.open && (
        <UpdateGroupChatModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedChat={selectedChat}
          reFetchChats={reFetchChats}
          setReFetchChats={setReFetchChats}
        />
      )}
      {selectedChat && selectedChat._id ? (
        <Card sx={{ background: `url(${MsgChatBoxBg})`, height: "100%" }}>
          <CardHeader
            avatar={
              <>
                <div
                  className={
                    selectedChat ? "display-back-btn" : "display-back-btn-none"
                  }
                >
                  <SoftButton
                    variant="text"
                    sx={{
                      color: "#757575",
                      minWidth: "max-content",
                      minHeight: "max-content",
                      width: "max-content",
                      height: "max-content",
                      padding: "0.25rem",
                      "& svg": {
                        fontSize: "1.5rem !important",
                      },
                      "&:hover": {
                        color: "#757575",
                      },
                    }}
                    onClick={() => dispatch(setSelectedChat(null))}
                  >
                    <ArrowBackIcon />
                  </SoftButton>
                </div>
                {!selectedChat.isGroupChat ? (
                  <Avatar
                    variant="square"
                    sx={{
                      width: "2.25rem",
                      height: "2.25rem",
                      borderRadius: "12px",
                      "& .MuiAvatar-img": {
                        borderRadius: "12px",
                      },
                    }}
                    src={
                      getReceiverImage(selectedChat.users, userDetails) === null
                        ? ""
                        : getReceiverImage(selectedChat.users, userDetails)
                    }
                  >
                    <span>
                      {getReceiverName(selectedChat.users, userDetails)[0]}
                    </span>
                  </Avatar>
                ) : (
                  selectedChat.isGroupChat && (
                    <Avatar
                      variant="square"
                      sx={{
                        width: "2.25rem",
                        height: "2.25rem",
                        borderRadius: "12px",
                        "& .MuiAvatar-img": {
                          borderRadius: "12px",
                        },
                      }}
                    >
                      <GroupsIcon fontSize="small" />
                    </Avatar>
                  )
                )}
              </>
            }
            title={
              selectedChat.isGroupChat
                ? selectedChat.chatName
                : getReceiverName(selectedChat.users, userDetails)
            }
            subheader={
              <SoftTypography
                display="block"
                variant="h6"
                sx={{
                  fontSize: "14px",
                  color: "#344767",
                  opacity: ".5 !important",
                }}
              >
                {selectedChat.isGroupChat
                  ? `${selectedChat.users.length} participants`
                  : null}
              </SoftTypography>
            }
            action={
              selectedChat.isGroupChat ? (
                <IconButton
                  sx={{
                    fontWeight: "fontWeightBold",
                    stroke: "dark.main",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const updatedUsers = selectedChat.users.map((item) => ({
                      ...item,
                      value: item._id,
                      label: `${item.firstName} ${item.lastName}`,
                    }));

                    setOpenModal({
                      open: true,
                      users: updatedUsers,
                    });
                  }}
                >
                  <SettingsIcon fontSize="small" />
                </IconButton>
              ) : null
            }
          />

          <CardContent
            className="cardarea-body"
            id="scrollableDiv"
            sx={{
              height: "100%",
              overflowY: "scroll",
              "& div:last-child": {
                marginBottom: 0,
              },
              py: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {istyping ? (
              <SoftBox
                display="flex"
                flexDirection="column"
                className="message-box other-message"
              >
                <SoftTypography variant="p" className="message-typing">
                  typing...
                </SoftTypography>
              </SoftBox>
            ) : null}
            <InfiniteScroll
              style={{
                overflow: "hidden",
                padding: 4,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              dataLength={messages.length}
              next={() => {
                setPage(currentMessagePage + 1);
              }}
              inverse
              hasMore={hasMoreMessages}
              loader={<LoadingScreen height="50px" logoHeight="50px" />}
              scrollableTarget="scrollableDiv"
            >
              {messages &&
                messages.map((message, index) => (
                  <SoftBox
                    key={message._id}
                    display="flex"
                    flexDirection="column"
                    sx={{
                      backgroundColor:
                        message.sender._id === userDetails._id
                          ? "#e9ecef!important"
                          : "#FFF",
                    }}
                    className={`message-box ${
                      message.sender._id === userDetails._id
                        ? "own-message"
                        : "other-message"
                    }`}
                  >
                    {message.sender._id !== userDetails._id ? (
                      <SoftTypography variant="p" className="sender-name">
                        {`${message.sender.firstName} ${message.sender.lastName}`}
                      </SoftTypography>
                    ) : null}

                    <SoftTypography variant="p" className="message-content">
                      {message.message}
                    </SoftTypography>
                    {message.image && (
                      <div className="message-image-container">
                        <img
                          src={GET_IMAGE(message.image)}
                          alt={message.createdAt}
                          className="message-image"
                        />
                      </div>
                    )}
                    <SoftBox display="flex">
                      <SoftTypography variant="p">
                        <CheckIcon /> {moment(message.createdAt).fromNow()}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                ))}
            </InfiniteScroll>
          </CardContent>
          <CardActions className="cardarea-footer">
            <form
              onSubmit={onSendTrigger}
              style={{ display: "flex", width: "100%", gap: "0.75rem" }}
            >
              <SoftBox
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SoftInput
                  placeholder="Type here"
                  value={newMessage}
                  onChange={onChangeTrigger}
                  sx={{
                    width: "100%",
                    flex: "1 !important",
                    padding: "0.5rem 2.5rem 0.5rem 0.75rem !important",
                  }}
                />
                <SoftBox sx={{ position: "absolute", alignSelf: "end" }}>
                  <SoftButton
                    variant="text"
                    color="primary"
                    sx={{
                      width: "max-content",
                      height: "max-content",
                      minWidth: "max-content",
                      minHeight: "max-content",
                      padding: "0.5rem !important",
                    }}
                    onClick={handleGalleryIconClick}
                    disabled={loading}
                  >
                    {UploadMessageIcon}
                  </SoftButton>
                </SoftBox>

                {selectedFile && (
                  <div className="image-preview">
                    <img src={selectedFile.previewURL} alt="Selected" />
                  </div>
                )}
              </SoftBox>
              <SoftBox sx={{ display: "flex", height: "fit-content" }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  hidden
                />
                <SoftButton
                  type="submit"
                  disabled={loading}
                  variant="gradient"
                  color="primary"
                  size="small"
                  sx={{
                    width: "max-content",
                    height: "max-content",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    padding: "0.5rem !important",
                  }}
                >
                  <SendMessageIcon />
                </SoftButton>
              </SoftBox>
            </form>
          </CardActions>
        </Card>
      ) : (
        <UnselectedChat />
      )}
    </SoftBox>
  );
};

export default ChatBox;
