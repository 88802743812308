import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftTypography from "../../../../components/SoftTypography";
import SoftInput from "../../../../components/SoftInput";
import { QuoteWidgetSchema } from "../../../../form-validations";
import { UseCompanyId, UseUserId } from "../../../../hooks/auth";
import { refetchUserData } from "../../../../redux/auth/actions";
import {
  ADD_WIDGETS_TO_LIST,
  GET_WIDGETS_LIST,
} from "../../../../api/userWidgets";

const QuoteOfTheDayEdit = ({ widget, closeModal, setButtonLabel }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const userId = UseUserId();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const validationOpt = {
    resolver: yupResolver(QuoteWidgetSchema),
    shouldUnregister: false,
    defaultValues: {
      quote: "",
      author: "",
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(validationOpt);

  const fetchUserWidgets = async () => {
    setLoading(true);
    try {
      const { data } = await GET_WIDGETS_LIST({ key: widget.key, companyId });

      const userQuotes = data[0].multilineUserInput.map((quote) => {
        const splittedQuote = quote.split("-");

        return {
          quote: splittedQuote[0].trim(),
          author: splittedQuote[1].trim(),
        };
      });
      setQuotes(userQuotes);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserWidgets();
  }, []);

  const handleRemoveClick = (index) => {
    const list = [...quotes];
    list.splice(index, 1);
    setQuotes(list);
  };

  const addTrigger = async (formData) => {
    const { quote, author } = formData;
    const newQuote = {
      quote,
      author,
    };

    setQuotes([...quotes, newQuote]);
    reset();
  };

  const triggerSave = async () => {
    if (!quotes.length) {
      toast.error("Quotes are required");
      return;
    }

    // if (quotes.length < 7) {
    //   toast.error("Add quotes for 7 days");
    //   return;
    // }

    const multilineUserInput = quotes.map(
      ({ quote, author }) => `${quote} - ${author}`,
    );

    setLoading(true);
    const payload = {
      userId,
      key: widget.key,
      category: widget.category,
      title: widget.title,
      multilineUserInput,
      companyId,
    };

    try {
      await ADD_WIDGETS_TO_LIST(payload);
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        setButtonLabel("Deactivate");
        toast.success("Widget activated successfully");
        closeModal();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(addTrigger)}>
      <SoftBox
        marginY="0.5rem"
        sx={{
          borderRadius: "4px",
          border: "1px solid rgba(73, 80, 87, 0.50)",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
          padding: "1rem",
          marginBottom: "10px",
        }}
      >
        <SoftBox sx={{ width: "100%" }}>
          <SoftInput
            {...register("quote")}
            placeholder="Add a quote"
            error={!!errors?.quote}
            helperText={errors?.quote?.message}
            mb="30px"
            className="text-black mb-3"
            sx={{
              border: "1px solid rgba(233, 236, 239, 0.10)",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              color: "#ffffffb3 !important",
              "& .MuiInputBase-input::placeholder": {
                color: "white !important",
                opacity: "0.7",
              },
              "&.Mui-focused": {
                borderColor: primaryColor,
                boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
              },
            }}
          />
          <SoftInput
            {...register("author")}
            placeholder="Author"
            error={!!errors?.author}
            helperText={errors?.author?.message}
            mb="30px"
            className="text-black mb-3"
            sx={{
              border: "1px solid rgba(233, 236, 239, 0.10)",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              color: "#ffffffb3 !important",
              "& .MuiInputBase-input::placeholder": {
                color: "white !important",
                opacity: "0.7",
              },
              "&.Mui-focused": {
                borderColor: primaryColor,
                boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
              },
            }}
          />
        </SoftBox>
        <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
          <SoftButton
            variant="outlined"
            color="white"
            type="submit"
            sx={{
              textTransform: "none",
              padding: "0px 30px",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Add a quote
          </SoftButton>
        </SoftBox>
      </SoftBox>
      {quotes.length && (
        <SoftBox
          sx={{
            borderRadius: "4px",
            border: "1px solid rgba(73, 80, 87, 0.50)",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
            padding: "0.6rem",
            height: "240px",
            overflowY: "auto",
          }}
        >
          <SoftTypography variant="h5" sx={{ color: "#FFF", fontSize: "16px" }}>
            Added quotes
          </SoftTypography>
          <SoftTypography
            variant="p"
            sx={{ color: "#6C757D", fontSize: "12px" }}
          >
            Below are the quotes that displays
          </SoftTypography>
          <SoftBox mb="15px" />
          {
            /* eslint-disable */
            quotes.map(({ quote, author }, index) => (
              <SoftBox
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "4px",
                  border: "1px solid rgba(73, 80, 87, 0.50)",
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
                  padding: "0.6rem",
                  marginBottom: "10px",
                }}
              >
                <SoftTypography
                  variant="p"
                  sx={{ color: "#FFF", fontSize: "14px" }}
                >
                  {quote} - {author}
                </SoftTypography>
                <SoftButton
                  variant="outlined"
                  color="white"
                  onClick={() => handleRemoveClick(index)}
                  sx={{
                    "& svg": { fontSize: "1.25rem !important" },
                    borderRadius: "50%",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    padding: "0.25rem",
                  }}
                >
                  <DeleteIcon />
                </SoftButton>
              </SoftBox>
            ))
          }
        </SoftBox>
      )}
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.10)",
            color: "#CED4DA",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          sx={{
            width: "100%",
            textTransform: "none",
            borderRadius: "4px",
            color: "#E9ECEF",
          }}
          disabled={loading}
          loading={loading}
          onClick={triggerSave}
        >
          Update
        </SoftButton>
      </SoftBox>
    </form>
  );
};

export default QuoteOfTheDayEdit;
