import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  realTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    gap: '2.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F5F5',
    width: '100%',
    height: '100%',
  },
  reviewBox: {
    background: 'white',
    width: '100%',
    borderRadius: '40px',
    padding: '20px 0px',
  }
});
