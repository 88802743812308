import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";
import { Euler } from "three";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Bounds, BakeShadows } from "@react-three/drei";
import AvatarLoader from "./AvatarLoader";
// import DEFAULT_AVATAR_URL from "../../img/avatars/DefaultMaleAvatar.glb";

const DEFAULT_AVATAR_URL =
  "https://gmb-public.s3.us-east-2.amazonaws.com/avatars/WhiteOverall_masculine.glb";

const Avatar = forwardRef(({ url, logo, industry, margin, fixed, recolor, autoRotate, idle, animation, disableAnim, onAnimEnd, noControls = false, isOwn = true, yeti = false }, ref) => {
    const [gender, setGender] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [avatarLoaded, setAvatarLoaded] = useState(false);
    const orbitControlsRef = useRef(null);
    const avatarRef = useRef(null);

    useImperativeHandle(ref, () => ({
      playAnimation() {
        avatarRef?.current?.playAnimation();
      },
    }));

    useEffect(() => {
      if (!fixed && url) {
        const urlBits = url.split(".");
        urlBits.splice(urlBits.length - 1, 1);
        const metaUrl = `${urlBits.join(".")}.json`;
        axios
          .get(metaUrl)
          .then(({ data }) => {
            setGender(data?.outfitGender || "masculine");
          })
          .catch(() => setGender("masculine"));
      } else {
        setGender("masculine");
      }
    }, [url, fixed]);

    useEffect(() => {
      if (gender) {
        if (url) {
          // Test whether avatar URL is accessible; RPM server often returns error so pre-check seems necessary
          const tempUrl = `${url}?textureAtlas=none&quality=medium`;
          axios.get(tempUrl)
            .then(() =>  setAvatarUrl(tempUrl))
            .catch(() => setAvatarUrl(DEFAULT_AVATAR_URL));
        } else {
          setAvatarUrl(DEFAULT_AVATAR_URL);
        }
      }
    }, [url, gender]);

    return (
      <Canvas
        orthographic={true}
        shadows={false}
        camera={{
          fov: 40,
          near: 0.1,
          far: 600,
          position: [0, 0, 10],
        }}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <ambientLight intensity={0.5} color={0xffffff} />
        {/* eslint-disable-next-line react/no-unknown-property */}
      <hemisphereLight intensity={0.6} color={0xb1e1ff} groundColor={0xb97a20} />
        <React.Suspense fallback={null}>
          {avatarUrl && (
            <Bounds
              fit
              clip
              // Add extra margin for female avatars to make it a bit smaller, lessen margin for yeti to make it bigger
              margin={(margin || 1) + (gender === "feminine" ? 0.10 : 0) - (yeti? 0.075: 0)}
            >
              <AvatarLoader
                ref={avatarRef}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                url={avatarUrl}
                logo={logo}
                industry={industry}
                recolor={recolor}
                gender={gender}
                fixed={fixed}
                disableAnim={disableAnim}
                idle={idle}
                animation={animation}
                onAnimEnd={onAnimEnd}
                onAvatarLoad={() => setAvatarLoaded(true)}
                yeti={yeti}
                isOwn={isOwn}
              />
            </Bounds>
          )}
          <BakeShadows />
        </React.Suspense>
        {process.env.NODE_ENV === "development" && <axesHelper />}
        { !noControls && <OrbitControls
            ref={orbitControlsRef}
            makeDefault
            maxPolarAngle={Math.PI / 2}
            minPolarAngle={Math.PI / 2}
            enableZoom={process.env.NODE_ENV === "development"}
            enablePan={false}
            enableRotate={false}
            autoRotate={autoRotate}
            autoRotateSpeed={10}
            rotation={autoRotate ? undefined : new Euler(0, 0, 0)}
          />
        }
      </Canvas>
    );
  },
);

export default Avatar;
