import * as Yup from "yup";

const IntegratedDisplaySetupSchema = Yup.object().shape({
  metricType: Yup.string()
    .required("Metric type is required"),
  name: Yup.string()
    .required("Name is required")
    .max(24, "Metric name must be at most 24 characters"),
  target: Yup.number()
    .typeError("Not a valid number")
    .when("metricType", {
      is: "progressive",
      then: Yup.number()  // Progressive metrics
        .required("Target is required")
        .typeError("Not a valid number")
        .min(0, "Target must be 0 - 10,000")
        .max(10000, "Target must be 0.01 - 10,000"),
      otherwise: Yup.number() // Anchor metrics
        .typeError("Not a valid number")
        .when("kind", {
          is: "%",  // Anchor-percentage type
          then: Yup.number()
            .required("Required")
            .typeError("Not a valid number")
            .min(0, "Value must be 0 - 100")
            .max(100, "Value must be less or equal to 100"),
          otherwise: Yup.number() // Anchor dollar|item type
            .required("Target is required")
            .typeError("Not a valid number")
            .min(0, "Value must be zero or a positive number")
        }),
    }),
  pointScale: Yup.number()
    .required("Points is required")
    .typeError("Not a valid number")
    .min(0, "Point scale must be zero or a positive number"),
  point: Yup.number()
    .required("Points is required")
    .typeError("Not a valid number")
    .min(0, "Point scale must be zero or a positive number"),
  unit: Yup.number()
    .required("Unit is required")
    .typeError("Not a valid number")
    .min(1, "Unit must be greater than 1"),
  kind: Yup.string().required("Kind is required"),
  anchorSetup: Yup.string().test("check-field", "Invalid value", function (value) {
    if (value === "idle") {
      return this.createError({ message: "Please setup the anchor value" });
    }

    if (value === "open") {
      return this.createError({ message: "Please save the anchor value" });
    }

    if (value === "edited") {
      return true;
    }
  })
});

export default IntegratedDisplaySetupSchema;
