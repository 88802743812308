import { request } from "../helpers/axios.new";

/* Retrieves challenge information by its challenge URL */
export const GET_CHALLENGE = (challengeUrl) => {
  return request({
    url: `/challenge/${challengeUrl}`,
    method: "GET",
  });
};

/* Retrieves challenge information by its participant */
export const GET_CHALLENGE_EVENT = (companyId) => {
  return request({
    url: `/challenge/event/${companyId}`,
    method: "GET",
  });
};

/* Sends challenge invitation to friends (also reserves a challenge slot for them) */
export const SEND_INVITE = (challengeId, userId, email, note) => {
  return request({
    url: `/challenge/invite/${challengeId}`,
    method: "PUT",
    data: { userId, email, note },
  });
};

export const SEND_INVITE_URL = (challengeId, userId) => {
  return request({
    url: `/challenge/invite-url/${challengeId}`,
    method: "PUT",
    data: { userId },
  });
};

export const VERIFY_INVITE_TOKEN = (token) => {
  return request({
    url: `/challenge/verify/${token}`,
    method: "GET",
  });
};

export const GET_SCOREBOARD = (challengeId) => {
  return request({
    url: `/challenge/scores/${challengeId}`,
    method: "GET",
  });
};

export const JOIN_CHALLENGE = ({
  code,
  challengeId,
  companyId,
  userId,
  groupId,
  parameters = null,
  email = null,
}) => {
  return request({
    url: `/challenge/join${challengeId ? `/${challengeId}` : ""}`,
    method: "POST",
    data: {
      companyId,
      userId,
      groupId,
      ...(code && { code }),
      ...(parameters && { parameters }),
      ...(email && { email }),
    },
  });
};

/* One vs One Challenge */
export const CREATE_ONE_VS_ONE_CHALLENGE = (data) => {
  return request({
    url: `/challenge/one-vs-one`,
    method: "POST",
    data,
  });
};

export const GET_ONE_VS_ONE_CHALLENGE = ({
  page = 1,
  userID,
  companyId,
  allCompleted,
  allChallengeFilter,
  playerId,
}) => {
  return request({
    url: `/challenge/one-vs-one-client?page=${page}&userId=${userID}&companyId=${companyId}&allCompleted=${allCompleted}&allChallengeFilter=${allChallengeFilter}&playerId=${playerId}`,
    method: "GET",
  });
};

export const JOIN_ONE_VS_ONE_CHALLENGE = (challengeId, data) => {
  return request({
    url: `/challenge/join-one-vs-one/${challengeId}`,
    method: "POST",
    data,
  });
};
