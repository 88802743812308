import { types, initState } from "./types";

const getCompanyUsersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_SOCKET_SUCCESS:
      return {
        ...state,
        socket: action.payload,
      };
    case types.GET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case types.TOGGLE_VIEW_AS_USER:
      return {
        ...state,
        viewAsUser: action.payload,
      };
    default:
      return state;
  }
};

export default getCompanyUsersReducer;
