import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Dialog, Divider, Grid, Collapse, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import moment from "moment";
import { toast } from "react-hot-toast";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import SoftInput from "../SoftInput";
import SoftSelect from "../SoftSelect";
import { CreateChallengeArenaSchema } from "../../form-validations";
import { UseCompanyId, UseUserId } from "../../hooks/auth";
import {
  CREATE_ONE_VS_ONE_CHALLENGE,
  SEND_INVITE_URL,
} from "../../api/challenge";
import { ReactComponent as CompanyGroupIcon } from "../../img/createchallenge-companygroup.svg";
import { ReactComponent as CompanyVsCompanyIcon } from "../../img/createchallenge-compvscomp.svg";
import { ReactComponent as EmployeeVsEmoployeeIcon } from "../../img/createchallenge-empvsemp.svg";
import { UseSelectedWorkspace } from "../../hooks/company";
import { GET_USER_BY_GROUPID } from "../../api/user";
import { GET_IMAGE } from "../../helpers/images";
import SoftProfile from "../ProfilePictures/SoftProfile";

const challengeType = [
  {
    value: false,
    label: "Public",
  },
  { value: true, label: "Private" },
];

const challengeCategories = [
  {
    value: "EmployeeVsEmployee",
    label: (
      <SoftBox sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <EmployeeVsEmoployeeIcon width="20px" />

        <SoftTypography variant="caption" color="white">
          Employee vs Employee
        </SoftTypography>
      </SoftBox>
    ),
  },
  {
    value: "CompanyVsCompany",
    label: (
      <SoftBox sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <CompanyVsCompanyIcon width="20px" />

        <SoftTypography variant="caption" color="white">
          Company vs Company
        </SoftTypography>
      </SoftBox>
    ),
  },
  {
    value: "CompanyGroups",
    label: (
      <SoftBox sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <CompanyGroupIcon width="20px" />

        <SoftTypography variant="caption" color="white">
          Company Group Challenge
        </SoftTypography>
      </SoftBox>
    ),
  },
];

const CreateChallenge = ({
  handleClose,
  handleOpenConfirm,
  setFetch,
  fetch,
}) => {
  const [selectType, setSelectType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openPrizes, setOpenPrizes] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [previousUsers, setPreviousUsers] = useState([]);
  const [firstPrize, setFirstPrize] = useState(null);
  const [secondPrize, setSecondPrize] = useState(null);
  const [thirdPrize, setThirdPrize] = useState(null);
  const [allPrize, setAllPrize] = useState(null);
  const theme = useTheme();

  const {
    primary: {
      main: primaryColor,
      light: brighterPrimaryColor,
      focus: lightPrimaryColor,
    },
    secondary: { main: darkerPrimaryColor },
    tertiary: { darkest: darkestTColor },
  } = theme.palette;

  const dispatch = useDispatch();
  const userId = UseUserId();
  const companyId = UseCompanyId();
  const { _id: groupId } = UseSelectedWorkspace();

  const fetchUsersByGroupId = async () => {
    setFetching(true);
    try {
      const { data } = await GET_USER_BY_GROUPID(groupId);
      const filteredUsers = data.filter((user) => user._id !== userId);
      setUsers(filteredUsers);
      setPreviousUsers(filteredUsers);
    } catch (error) {
      console.log(error);
    }
    setFetching(false);
  };

  const filterUser = () => {
    const filteredUsers = previousUsers.filter((item) => {
      const username = `${item.firstName} ${item.lastName}`;
      return username.toLowerCase().search(searchText.toLowerCase()) !== -1;
    });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    fetchUsersByGroupId();
  }, []);

  useEffect(() => {
    filterUser();
  }, [searchText]);

  const handleSortChallenge = (sort) => {
    setSelectType(sort);
  };

  const validationOpt = {
    resolver: yupResolver(CreateChallengeArenaSchema),
    shouldUnregister: false,
    defaultValues: {
      startDate: moment().format("YYYY-MM-DD"),
      isPrivate: false,
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
    setError,
    reset,
    getValues,
    watch,
    resetField,
  } = useForm(validationOpt);

  const handleOpenPrize = () => {
    setOpenPrizes((prev) => !prev);
    if (openPrizes) {
      resetField("prizeForWinner");
      resetField("prizeforSecondPlace");
      resetField("prizeforThirdPlace");
      resetField("prizeforParticipants");
    }
  };

  const handleOpenInvite = () => {
    setOpenInvite((prev) => !prev);
  };

  const watchCategory = watch("category");

  const addOrRemoveUserToInviteList = (id) => {
    const index = usersToInvite.findIndex((user) => user === id);

    if (index !== -1) {
      const updatedList = usersToInvite.filter((user) => user !== id);
      setUsersToInvite(updatedList);
    } else {
      const copy = [...usersToInvite, id];
      setUsersToInvite(copy);
    }
  };

  const checkIfUserExist = (id) => {
    const userExist = usersToInvite.findIndex((user) => user === id);

    if (userExist !== -1) return true;
    else return false;
  };

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      userId,
      companyId,
    };
    // dispatch(createChallenge(payload));
    setIsLoading(true);
    try {
      const { data } = await CREATE_ONE_VS_ONE_CHALLENGE(payload);

      if (data) {
        if (usersToInvite.length) {
          await Promise.all(
            usersToInvite.map((user) =>
              SEND_INVITE_URL(data.challenge._id, user),
            ),
          );
        }
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong",
      );
      console.log(error.response);
    }
    setIsLoading(false);
    reset();
    setFetch(fetch + 1);
    handleClose();
    // handleOpenConfirm(true);
  };

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: { mini: "1rem", md: "1.5rem" } }}
      >
        <SoftTypography variant="h4" color="white">
          Create a challenge
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          margin: 0,
          backgroundImage:
            "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
          <SoftBox marginBottom="1rem">
            <SoftInput
              placeholder="Challenge title"
              type="text"
              {...register("title")}
              error={!!errors?.title}
              helperText={errors?.title?.message}
              sx={{
                backgroundColor: "transparent !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </SoftBox>
          <SoftBox
            marginY="1rem"
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(73, 80, 87, 0.50)",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
              padding: "1rem",
            }}
          >
            <Grid item md={12}>
              <SoftSelect
                name="isType"
                id="category"
                className="custom-select-catgory"
                options={challengeCategories}
                {...register("category", { required: true })}
                error={!!errors?.category}
                onChange={(option) => setValue("category", option.value)}
                placeholder="Challenge category"
                styles={{
                  color: "white !important",
                  placeholder: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  input: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  singleValue: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                }}
              />
              <Collapse in={watchCategory === "EmployeeVsEmployee"}>
                <SoftBox
                  // display="flex"
                  // justifyContent="space-between"
                  sx={{
                    background: "transparent",
                    border: "1px solid #d2d6da",
                    borderRadius: "10px",
                    color: "#d2d6da !important",
                    textTransform: "none",
                    padding: "0.75rem 0.75rem",
                    marginTop: "1rem",
                    fontSize: "14px",
                    width: "100%",
                    "&:hover": {
                      borderColor: "#d2d6da",
                      background: "transparent",
                      cursor: "pointer",
                    },
                    "&:focus:not(:hover)": {
                      borderColor: "#d2d6da",
                      background: "transparent",
                    },
                  }}
                  onClick={handleOpenInvite}
                >
                  Invite Players to this challenge
                </SoftBox>
              </Collapse>
              <Collapse in={openInvite}>
                {openInvite && (
                  <SoftBox
                    marginY="1rem"
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(73, 80, 87, 0.50)",
                      background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
                      padding: "1rem",
                    }}
                  >
                    <SoftInput
                      placeholder="Search user by name"
                      type="text"
                      onChange={(e) => setSearchText(e.target.value)}
                      sx={{
                        backgroundColor: "transparent !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                    />
                    <SoftBox
                      marginY="1rem"
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid rgba(73, 80, 87, 0.50)",
                        // background: "#303030",
                        background:
                          "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
                        padding: "1rem",
                        height: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {users.map((user) => (
                        <SoftBox
                          key={user._id}
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <SoftProfile
                              src={GET_IMAGE(user.image) || ""}
                              alt=""
                              size="md"
                            />
                            <SoftTypography variant="h6" color="white">
                              {user.firstName} {user.lastName}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox>
                            <SoftButton
                              onClick={() =>
                                addOrRemoveUserToInviteList(user._id)
                              }
                              sx={{
                                textTransform: "none",
                                minWidth: "max-content",
                                minHeight: "max-content",
                                padding: "0.5rem 0.75rem",
                                borderRadius: "32px",
                                border: "1px solid rgba(255, 255, 255, 0.10)",
                                background: "transparent",
                                color: "white !important",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  borderColor: "primary.lighter",
                                  boxShadow: `0px 0px 32px 0px ${brighterPrimaryColor}, 0px 0px 48px 0px ${lightPrimaryColor} inset`,
                                  background:
                                    "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)",
                                  color: "white !important",
                                },
                              }}
                            >
                              {checkIfUserExist(user._id) ? "Unsend" : "Send"}
                            </SoftButton>
                          </SoftBox>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  </SoftBox>
                )}
              </Collapse>
            </Grid>
          </SoftBox>
          <SoftBox marginY="1rem">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <SoftInput
                  placeholder="Start date"
                  type="date"
                  name="startDate"
                  {...register("startDate")}
                  error={!!errors?.startDate}
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  helperText={errors?.startDate?.message}
                  sx={{
                    backgroundColor: "transparent !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <SoftInput
                  placeholder="End date"
                  type="date"
                  name="endDate"
                  {...register("endDate")}
                  error={!!errors?.endDate}
                  helperText={errors?.endDate?.message}
                  inputProps={{
                    min: new Date(
                      new Date(watch("startDate")).getTime() +
                        24 * 60 * 60 * 1000,
                    )
                      .toISOString()
                      .split("T")[0],
                  }}
                  sx={{
                    backgroundColor: "transparent !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox marginY="1rem">
            <Grid item md={12}>
              <SoftSelect
                name="isPrivate"
                id="challenge"
                options={challengeType}
                placeholder="Select privacy"
                {...register("isPrivate", { required: true })}
                error={!!errors?.maxPlayers}
                onChange={(option) => setValue("isPrivate", option.value)}
                styles={{
                  placeholder: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  input: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                  singleValue: {
                    color: "white !important",
                    opacity: "0.7",
                  },
                }}
              />
            </Grid>
          </SoftBox>
          <SoftBox margin="auto">
            <SoftButton
              variant="outlined"
              sx={{
                background: "transparent",
                borderColor: "white",
                color: "white !important",
                textTransform: "none",
                padding: "0.15rem 0.75rem",
                width: "100%",
                "&:hover": { borderColor: "white", background: "transparent" },
                "&:focus:not(:hover)": {
                  borderColor: "white",
                  background: "transparent",
                },
              }}
              onClick={handleOpenPrize}
            >
              {openPrizes ? "Remove Prizes" : "Add Prizes"}
            </SoftButton>
          </SoftBox>

          <Collapse in={openPrizes}>
            <SoftBox
              marginY="1rem"
              sx={{
                borderRadius: "4px",
                border: "1px solid rgba(73, 80, 87, 0.50)",
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
                padding: "1rem",
              }}
            >
              <SoftBox
                marginBottom={
                  watchCategory === "CompanyGroups" ? "1rem" : "0rem"
                }
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <SoftInput
                      placeholder="Enter prize for winner"
                      type="text"
                      {...register("prizeForWinner")}
                      error={!!errors?.prizeForWinner}
                      helperText={errors?.prizeForWinner?.message}
                      name="prizeForWinner"
                      sx={{
                        backgroundColor: "transparent !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <SoftInput
                      placeholder="Enter prize for 2nd place"
                      type="text"
                      {...register("prizeforSecondPlace")}
                      error={!!errors?.prizeforSecondPlace}
                      helperText={errors?.prizeforSecondPlace?.message}
                      name="prizeforSecondPlace"
                      sx={{
                        backgroundColor: "transparent !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>

              {watchCategory === "CompanyGroups" ? (
                <SoftBox marginTop="1rem">
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <SoftInput
                        placeholder="Enter prize for 3rd place"
                        type="text"
                        {...register("prizeforThirdPlace")}
                        error={!!errors?.prizeforThirdPlace}
                        helperText={errors?.prizeforThirdPlace?.message}
                        name="prizeforThirdPlace"
                        sx={{
                          backgroundColor: "transparent !important",
                          color: "#ffffffb3 !important",
                          "& .MuiInputBase-input::placeholder": {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          "&.Mui-focused": {
                            borderColor: primaryColor,
                            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SoftInput
                        placeholder="Enter prize for participants"
                        type="text"
                        {...register("prizeforParticipants")}
                        error={!!errors?.prizeforParticipants}
                        helperText={errors?.prizeforParticipants?.message}
                        name="prizeforParticipants"
                        sx={{
                          backgroundColor: "transparent !important",
                          color: "#ffffffb3 !important",
                          "& .MuiInputBase-input::placeholder": {
                            color: "white !important",
                            opacity: "0.7",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
              ) : null}
            </SoftBox>
          </Collapse>
          <SoftBox marginY="1rem">
            <SoftInput
              multiline
              minRows={4}
              placeholder="Description"
              {...register("description")}
              error={!!errors?.description}
              helperText={errors?.description?.message}
              name="description"
              defaultValue="Challenge your limits."
              sx={{
                backgroundColor: "transparent !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
            sx={{ marginTop: "2rem" }}
          >
            <SoftButton
              variant="outlined"
              color="white"
              onClick={handleClose}
              disabled={isLoading}
              loading={isLoading}
              sx={{
                width: "100%",
                textTransform: "none",
              }}
            >
              Cancel
            </SoftButton>

            <SoftButton
              variant="outlined"
              color="white"
              type="submit"
              sx={{
                width: "100%",
                textTransform: "none",
              }}
              disabled={isLoading}
              loading={isLoading}
            >
              Submit
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </form>
    </>
  );
};

export default CreateChallenge;
