import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import BLANK_SLIDE from "../../img/blank_slide.png";

const styles = {
  label: {
    color: "#FFF",
    position: "absolute",
    bottom: "10px",
    right: "15px",
  },
  button: {
    color: "#FF1D8F",
    position: "absolute",
    bottom: "10px",
  },
  imageFooter: {
    marginRight: 1,
  },
  image: {
    objectFit: "fill",
    width: "100%",
    height: "100%",
  },
};

const ImageCarousel = ({ images, labels, height }) => {
  const [slides, setSlides] = React.useState([BLANK_SLIDE]); // Initialize slides with a blank image to avoid locking unto small widths

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
    arrows: false,
    adaptiveHeight: true,
  };

  React.useEffect(() => {
    if (images && images.length) {
      setSlides(images);
    }
  }, [images]);

  return (
    <Box
      sx={{
        bgcolor: "secondary.darker",
        overflow: "hidden",
        borderRadius: "8px",
        height: "100%",
      }}
    >
      <Slider {...settings}>
        {(slides || []).map((imgSrc, index) => {
          return (
            <Box key={imgSrc}>
              <img
                src={imgSrc}
                alt="Job Slide"
                style={{ height }}
                className="w-full h-full object-fit m-auto"
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={styles.imageFooter}
              >
                <Typography sx={styles.label} align="right" variant="h5">
                  {labels[index]}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default ImageCarousel;
