import React, { useState } from "react";
import KeyboardArrowUpRounded from "@mui/icons-material/KeyboardArrowUpRounded";
import { Popover } from "@mui/material";

import SoftBox from "../SoftBox";
import ProfileDrawer from "./ProfileDrawer";

const ProfilePopover = ({
  darkMode = false,
  viewOnly = false,
  expands = false,
}) => {
  const [openDrawer, setOpenDrawer] = useState(null);

  const handleOpenWs = (event) => {
    setOpenDrawer(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(null);
  };

  const openDrawerMenu = Boolean(openDrawer);

  return (
    <>
      <SoftBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "max-content",
          minWidth: "max-content",
          padding: 0,
          color: darkMode || !expands ? "#FFF" : "#000",
          fontSize: expands ? "2vh" : "1.5rem",
          right: expands ? "1vw" : "16px",
          top: expands ? "4.5vh" : "60px",
          "&:hover": {
            cursor: "pointer",
          },
          borderRadius: "0px !important",
        }}
        onClick={handleOpenWs}
      >
        <KeyboardArrowUpRounded />
      </SoftBox>
      <Popover
        id="footerMenu"
        open={openDrawerMenu}
        onClose={handleCloseDrawer}
        anchorEl={openDrawer}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            ...(darkMode && { background: "primary.darker" }),
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            padding: "0rem",
            ...(expands
              ? {
                  width: "18vw",
                }
              : {
                  width: "234px",
                }),
            borderRadius: 0,
          },
        }}
      >
        <ProfileDrawer />
      </Popover>
    </>
  );
};

export default ProfilePopover;
