import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { types } from "./types";
import { getUserNotification } from "./actions";
import { UseUserDetails } from "../../hooks/auth";
import {
  GET_NOTIFICATIONS_BY_USERID,
  CREATE_NOTIFICATIONS,
  UPDATE_ALL_NOTIFICATION_STATUS,
  UPDATE_NOTIFICATION_READ_STATUS,
  DELETE_NOTIFICATION,
} from "../../api/notification";
import { GET_IMAGE } from "../../helpers/images";
import blankPic from "../../img/blankPic.png";

function* GetUserNotification(action) {
  try {
    const { data } = yield call(() =>
      GET_NOTIFICATIONS_BY_USERID(action.payload),
    );

    const updatedNotifications = data.data.map((noti) => ({
      ...noti,
      image: noti.image ? GET_IMAGE(noti.image) : blankPic,
    }));

    const unreadNotifications = updatedNotifications.filter(
      (noti) => noti.is_read === false,
    );

    yield put({
      type: types.GET_USER_NOTIFICATION_SUCCESS,
      notifications: updatedNotifications,
      unreadNotifications,
    });
  } catch (err) {
    yield put({ type: types.GET_USER_NOTIFICATION_FAILED, error: err.message });
  }
}

function* CreateNotification(action) {
  try {
    const { data } = yield call(() => CREATE_NOTIFICATIONS(action.payload));

    yield put({
      type: types.GET_USER_NOTIFICATION_SUCCESS,
    });
    yield put(getUserNotification(UseUserDetails()._id));
  } catch (err) {
    yield put({ type: types.GET_USER_NOTIFICATION_FAILED, error: err.message });
  }
}

function* UpdateAllNotificationStatus(action) {
  try {
    yield put({
      type: types.UPDATE_ALL_NOTIFICATION_STATUS_UI_SUCCESS,
    });

    yield call(() =>
      UPDATE_ALL_NOTIFICATION_STATUS(action.payload.userId, {
        is_read: action.payload.is_read,
      }),
    );

    yield put({
      type: types.UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: types.UPDATE_ALL_NOTIFICATION_STATUS_FAILED,
      error: err.message,
    });
  }
}

function* UpdateAllNotificationReadStatus(action) {
  try {
    yield put({
      type: types.UPDATE_NOTIFICATION_READ_STATUS_UI_SUCCESS,
    });

    yield call(() =>
      UPDATE_ALL_NOTIFICATION_STATUS(action.payload.userId, {
        is_read: action.payload.is_read,
      }),
    );

    yield put({
      type: types.UPDATE_NOTIFICATION_READ_STATUS_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: types.UPDATE_NOTIFICATION_READ_STATUS_FAILED,
      error: err.message,
    });
  }
}

function* UpdateNotification(action) {
  try {
    yield call(() => UPDATE_NOTIFICATION_READ_STATUS(action.payload));

    yield put({
      type: types.READ_NOTIFICATION_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: types.READ_NOTIFICATION_FAILED,
      error: err.message,
    });
  }
}

function* DeleteNotification(action) {
  try {
    yield call(() => DELETE_NOTIFICATION(action.payload));

    yield put({
      type: types.DELETE_NOTIFICATION_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: types.DELETE_NOTIFICATION_FAILED,
      error: err.message,
    });
  }
}

function* watchFetchChatsRequest() {
  yield takeEvery(types.GET_USER_NOTIFICATION, GetUserNotification);
  yield takeEvery(types.CREATE_NOTIFICATION, CreateNotification);
  yield takeEvery(
    types.UPDATE_ALL_NOTIFICATION_STATUS,
    UpdateAllNotificationStatus,
  );
  yield takeEvery(
    types.UPDATE_NOTIFICATION_READ_STATUS,
    UpdateAllNotificationReadStatus,
  );
  yield takeEvery(types.READ_NOTIFICATION, UpdateNotification);
  yield takeEvery(types.DELETE_NOTIFICATION, DeleteNotification);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchFetchChatsRequest)]);
}

export default rootSaga;
