/* eslint camelcase: 0 */

import { request } from "../../helpers/axios.new";

const ZAPIER_CLIENT_ID = process.env.REACT_APP_ZAPIER_CLIENT_ID;
const REDIRECT_URI = `${process.env.REACT_APP_FRONTEND}/zapier-auth`;
const ZAPIER_AUTH_URI = "https://zapier.com/oauth/authorize";

export const GET_ZAPIER_AUTH_URL = () => {
  const zapierAuthUrl = `${ZAPIER_AUTH_URI}?client_id=${ZAPIER_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=token&scope=zap%20profile`
  return zapierAuthUrl;
};

export const POST_EMBED_TOKEN = ({access_token, token_type}) => {
  return request({
    url: `/zapier/token`,
    method: "POST",
    data: { 
      parameters: {
        access_token, 
        token_type
      }
    }
  });
};

export const GET_USER = () => {
  return request({
    url: `/zapier/user`,
    method: "GET",
  });
};

export const GET_ZAP = (id) => {
  return request({
    url: `/zapier/zaps/${id}`,
    method: "GET",
  });
};

export const GET_ZAPS = () => {
  return request({
    url: `/zapier/zaps`,
    method: "GET",
  });
};

export const LINK_KPIS = () => {
  return request({
    url: `/zapier/link-kpis`,
    method: "PUT",
  });
};