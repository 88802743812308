import * as React from "react";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { getPageNumber } from "../../redux/user/actions";

const StyledButton = styled("button")(
  () => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  width: 55px;
  background: #fff;
  border-radius: 0.75em;
  padding: 4px 5px;
  text-align: left;
  line-height: 1.5;
  color: black;
  font-weight: bold;
  margin-right: 0.5em;

  &:hover {
    background: #00000033;
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid #CB0C9F;
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      color: #0000008a;
      content: '▴';
    }
  }

  &::after {
    color: #0000008a;
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled("ul")(
  () => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 4px 5px;
  margin: 1px 0;
  min-width: 55px;
  background: #fff;
  border: 1px solid #0000008a;
  border-radius: 0.75em;
  color: #0000008a;
  overflow: auto;
  outline: 0px;
  font-weight: bold;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  () => `
  list-style: none;
  padding: 4px 5px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #0000008a;
    color: #0000008a;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #0000008a;
    color: #0000008a;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #0000008a;
    color: #fff;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #00000033;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color:  #00000033;
    color: #fff;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef((props, ref) => {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const options = [
  { value: 10, label: "all" },
  { value: 15, label: "upsell" },
  { value: 20, label: "revenue" },
  { value: 25, label: "review" },
];

export default function ScDropdown() {
  const dispatch = useDispatch();
  const [filterPage, setFilterPage] = React.useState(10);

  React.useEffect(() => {
    dispatch(getPageNumber(filterPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPage]);

  return (
    <CustomSelect value={filterPage} onChange={setFilterPage}>
      {options.map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledOption key={index} value={val.value}>
          {val.value}
        </StyledOption>
      ))}
    </CustomSelect>
  );
}
