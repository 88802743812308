import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, useMediaQuery } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import SoftBox from "../../components/SoftBox";
import UpgradePopUp from "../../components/ChallengeArenaPopUp/UpgradePopUp";
import SoftTypography from "../../components/SoftTypography";

const UnauthorizedPage = ({ forAdmin = true, upgradeRequired = false }) => {
  const [showUpgrade, setShowUpgrade] = useState(upgradeRequired);
  const navigate = useNavigate();
  const lgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <>
      <SoftBox
        sx={{
          height: { mini: "72px", md: "70px" },
        }}
      />
      {/* Page Content */}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ height: "auto", margin: "1rem" }}
      >
        <LockPersonIcon sx={{ fontSize: 50 }} />
        {lgBreakpoint ? (
          <>
            <SoftTypography variant="h5">
              You don't have permission to view this page
            </SoftTypography>
            <SoftTypography variant="caption">
              {forAdmin
                ? `Only admin users can view this page`
                : `Page is restricted`}
            </SoftTypography>
          </>
        ) : (
          <>
            <SoftTypography variant="h5">
              Page not available in mobile view
            </SoftTypography>
            <SoftTypography variant="caption">
              Page is restricted
            </SoftTypography>
          </>
        )}
      </Stack>
      {upgradeRequired && (
        <UpgradePopUp
          showUpgrade={showUpgrade}
          closeUpgrade={() => {
            navigate(-1);
            setShowUpgrade(false);
          }}
        />
      )}
    </>
  );
};

export default UnauthorizedPage;
