/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import typography from "../../base/typography";
import borders from "../../base/borders";
import colors from "../../base/colors";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "../../functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark, white } = colors;

const tab = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flex: "1 1 auto",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.md,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: pxToRem(4),
      paddingRight: pxToRem(12),
      paddingLeft: pxToRem(12),
      borderRadius: borderRadius.md,
      color: dark.main,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: `${pxToRem(2)} !important`,
        marginRight: pxToRem(4),
      },

      "& svg": {
        marginBottom: `${pxToRem(2)} !important`,
        marginRight: pxToRem(6),
      },
      
      "&.Mui-selected": {
        color: white.main,
      },
    },

    /* labelIcon: {
      paddingTop: pxToRem(4),
    }, */

  },
};

export default tab;
