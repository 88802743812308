import { types } from "./types";
// prettier-ignore
import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import { updateProfile as updateProfileApi } from "../../api/index";
import { LOGIN, REGISTER, GOOGLE_LOGIN } from "../../api/auth";
import { GET_USER } from "../../api/user";

const generateUserData = async (data) => {
  const {
    token,
    username,
    _id,
    companyId,
    isAdmin,
    isPrime,
    isDemo,
    access = {},
    __v,
    ...otherData
  } = data;

  // Store basic user data
  await localStorage.setItem(
    "user",
    JSON.stringify({ username, userId: _id, companyId, isAdmin }),
  );
  // Store auth token
  await localStorage.setItem("token", token);

  return {
    // Only include relevant information on redux; exclude tokens, etc
    userDetails: { ...otherData, _id, companyId, isAdmin, username },
    isPrime,
    isDemo,
    access,
  };
};

// for login saga
function* handleLogin(action) {
  try {
    const { data } = yield call(() => LOGIN(action.payload));
    const userData = yield call(() => generateUserData(data));

    yield put({
      type: types.LOGIN_SUCCESS,
      ...userData,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({ type: types.LOGIN_FAILED_INVALID, message: data });
  }
}

function* handleSocialLogin(action) {
  try {
    const { token: accessToken, authType } = action;
    let result = null;

    if (authType === "google") {
      result = yield call(() => GOOGLE_LOGIN({ token: accessToken }));
    }
    if (result) {
      const userData = yield call(() => generateUserData(result.data));
      yield put({
        type: types.LOGIN_SUCCESS,
        ...userData,
      });
    } else {
      yield put({
        type: types.LOGIN_FAILED_INVALID,
        error: `User not found.`,
      });
    }
  } catch (err) {
    const { data } = err.response;
    yield put({ type: types.LOGIN_FAILED_INVALID, message: data });
  }
}

function* handleQuickLogin(action) {
  try {
    // Token and user has already been generated right after registration
    const { token, userPayload } = action;

    if (token && userPayload) {
      const userData = yield call(() =>
        generateUserData({ ...userPayload, token }),
      );
      const access =
        typeof userData.access === "object"
          ? userData.access
          : JSON.parse(userData.access);

      yield put({
        type: types.LOGIN_QUICK_SUCCESS,
        ...userData,
        access,
      });
    }
  } catch (err) {
    yield put({ type: types.LOGIN_QUICK_FAILED });
  }
}

function* loginSaga() {
  yield takeEvery(types.LOGIN_REQUEST, handleLogin);
  yield takeEvery(types.SOCIAL_LOGIN_REQUEST, handleSocialLogin);
  yield takeEvery(types.LOGIN_QUICK_REQUEST, handleQuickLogin);
}

function* handleRegister(action) {
  try {
    const { data } = yield call(() => REGISTER(action.payload));

    yield put({
      type: types.REGISTER_SUCCESS,
      userDetails: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({ type: types.REGISTER_FAILED, message: data });
  }
}

function* registerSaga() {
  yield takeEvery(types.REGISTER_REQUEST, handleRegister);
}

function* updateProfile(action) {
  try {
    const req = yield call(() =>
      updateProfileApi({
        id: action.id,
        payload: action.payload,
      }),
    );

    yield put({
      type: types.UPDATE_PROFILE_SUCCESS,
      userDetails: req.data,
    });
  } catch (err) {
    yield put({ type: types.UPDATE_PROFILE_FAILED, error: err.message });
  }
}

function* updateProfileSaga() {
  yield takeEvery(types.UPDATE_PROFILE_REQUEST, updateProfile);
}

/* eslint-disable */
function* getUpdatedUserData(action) {
  try {
    const { data } = yield call(() => GET_USER(action.payload));

    yield put({
      type: types.UPDATE_WIDGETS_SUCCESS,
      userDetails: data,
    });
  } catch (err) {
    const { data } = err.response;
    yield put({ type: types.UPDATE_WIDGETS_FAILED, message: data });
  }
}

function* updateWidgetsSaga() {
  yield takeEvery(types.UPDATE_WIDGETS_REQUEST, getUpdatedUserData);
}

// root saga container
function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(registerSaga),
    fork(updateProfileSaga),
    fork(updateWidgetsSaga),
  ]);
}

export default rootSaga;
