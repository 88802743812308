import { initState, types } from "./types";

const workspacesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ALL_MASTER_BADGES:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case types.GET_ALL_MASTER_BADGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        masterBadges: action.masterBadges,
        isError: null,
      };
    case types.GET_ALL_MASTER_BADGES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        masterBadges: [],
      };
    default:
      return state;
  }
};

export default workspacesReducer;
