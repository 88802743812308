/* eslint camelcase: 0 */
import { request } from "../helpers/axios.new";

export const CREATE_LEVEL = (data) => {
  return request({
    url: `/level-up`,
    method: "POST",
    data,
  });
};

export const GET_LEVEL = (companyId, all) => {
  return request({
    url: `/level-up?companyId=${companyId}&all=${all}`,
    method: "GET",
  });
};
