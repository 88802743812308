import React, { useState, useMemo, useEffect } from "react";
import { UseCompany } from "../../hooks/company";
import { useDispatch } from "react-redux";
import { updateCompany } from "../../redux/company/actions";
import { useForm } from "react-hook-form";

import SoftBox from "../../components/SoftBox";
// import SoftTypography from "../../components/SoftTypography";
import { Grid, Tab, Tabs } from "@mui/material";
import SoftInput from "../../components/SoftInput";
import SoftSelect from "../../components/SoftSelect";
import PageHeader from "../../components/PageHeader";

import toast from "react-hot-toast";
// import { stepperPageStyles } from "../CreateCompany/stepperPageStyles";
// import industryOptions from "../../constants/industries";
import { UseUserDetails } from "../../hooks/auth";
import { updateUserDetails } from "../../redux/auth/actions";
import { GET_INDUSTRIES } from '../../api/industry';

// Services
import { PUT_USER } from "../../api/user";

export default function CompanySettings() {
  const company = UseCompany();
  const user = UseUserDetails();
  const dispatch = useDispatch();
  // const classes = stepperPageStyles();

  // Detect "demo" user from user profile
  // Note: Demo accounts have companyName and industry fields in the user object
  const isDemoUser = useMemo(() => {
    return user?.industry && user?.companyName
  }, [user]);

  const { register, setValue, handleSubmit, formState: { errors },
  getValues,
  setError,
  clearErrors,
  trigger,
  } = useForm({
    defaultValues: {
      ...company,
      // Use "demo" account companyName and industry, if available
      businessName: isDemoUser ? user?.companyName : company?.businessName,
      industry: isDemoUser ? user?.industry : company?.industry,
    },
  });

  const onSubmit = async (data) => {
    if (isDemoUser) {
     try {
      // Update companyName and industry in the "user" profile
      await PUT_USER(user?._id, {
        companyName: data?.businessName,
        industry: data?.industry
      });

      dispatch(updateUserDetails({
        companyName: data?.businessName,
        industry: data?.industry
      }));

      toast.success("Successfully Updated Company Details");
     } catch (err) {
      toast.error(err.message);
     }
    } else {
      // Update companyName and industry in the "company" profile
      dispatch(updateCompany(data));
      toast.success("Successfully Updated Company Details");
    }
  };

  const [industryOptions, setIndustryOptions] = useState([]);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const industries = await GET_INDUSTRIES();
        const businessIndustries = industries.map(industry => ({
          value: industry.name,
          label: industry.name,
        }));
        console.log(businessIndustries);
        setIndustryOptions(businessIndustries);
      } catch (fetchError) {
        console.error('Failed to fetch industries', fetchError);
      }
    };
  
    fetchIndustries();
  }, []);

  const checkPassword = async () => {
    const newPass = getValues("newPassword");
    const confPass = getValues("confirmPassword");
    const result = await trigger("confirmPassword");
    if (!result) return;
    if (newPass !== confPass) {
      setError("confirmPassword", {
        type: "custom",
        message: `Password not match!`,
      });
      return;
    }else {
      clearErrors("confirmPassword");
    }
  };

  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <PageHeader headerText="Company Settings" subheaderText="Information about your company"  />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <Grid container>
          <Grid
              className="rounded-lg p-4"
              style={{ backgroundColor: 'white' }}
               item mini={12}>
              <Grid container>
                <Grid item mini={6}>
                  <p style={{ color: '#000000', fontSize: '22px', fontWeight: '600' }}>Company Details</p>
                  <p className="mb-3 text-black">Edit and update your profile for better performance</p>
                </Grid>
                <Grid item mini={6} className="text-end">
                  <button
                      type="submit"
                      form="compForm"
                      className="border px-4 py-1 rounded text-white"
                      style={{ backgroundColor: company.primaryColor }}
                    >
                    Update
                  </button>
                </Grid>
              </Grid>
              <Grid container>
                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(newValue)}
                    className="mt-3 p-0"
                    style={{ borderRadius: '0px' }}
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#fff",
                        boxShadow: 'none',
                      },
                      "& .MuiTab-root": {
                        fontWeight: '500',
                        fontSize: '18px',
                        borderBottom: "1px solid #E5E9EB",
                        borderRadius: "0px",
                        paddingRight: '20px',
                        paddingBottom: '10px',
                        paddingLeft: '0px',
                        color: '#6C757D',
                        "&.Mui-selected": {
                          color: '#04549C',
                          paddingLeft: '0px',
                          borderBottom: "1px solid #04549C",
                        },
                      },
                    }}
                  >
                  <Tab label="Profile Details" />
                </Tabs>
                {tabValue === 0 && (
                  <form id="compForm" onSubmit={handleSubmit(onSubmit)}>
                    <SoftBox className="mt-5">
                      <Grid container className="mt-3">
                        <Grid item mini={4}>
                        <p style={{ color: '#000000', fontSize: '22px', fontWeight: '700' }}>Business Details</p>
                          <p>This section provides essential information about the company's official business name. The business name is a fundamental aspect of the company's identity, serving as a unique identifier in the business world</p>
                        </Grid>
                        <Grid item mini={2}>{/* blank */}</Grid>
                        <Grid item mini={6}>
                          <SoftInput
                            id="companyBusinessName"
                            type="name"
                            className="shadow-sm rounded-lg"
                            placeholder="E.g. GameMyBiz"
                            {...register("businessName", { required: true })}
                            size="large"
                          />
                          <SoftSelect
                            className="mt-3 shadow-sm rounded-lg"
                            name="industry"
                            id="industry"
                            {...register("industry", { required: true })}
                            // Set onChange with manual setValue, SoftSelect's react-select has a different onChange parameter than usual select
                            onChange={(option) => setValue("industry", option.value)}
                            size="large"
                            options={industryOptions}
                            placeholder="Select your industry"
                            defaultValue={{
                              value: isDemoUser ? user?.industry : company?.industry,
                              label: isDemoUser ? user?.industry : company?.industry,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <hr className="my-3 divide-slate-300 mt-5 mb-4" />
                      <Grid container>
                        <Grid item mini={3}>
                          <p style={{ color: '#000000', fontSize: '22px', fontWeight: '700' }}>Business Address</p>
                          <p>The business address is a pivotal piece of information providing users with the physical location of the company. This includes the street address, city, state, and postal code</p>
                        </Grid>
                        <Grid item mini={3}>{/* blank */}</Grid>
                        <Grid item mini={6}>
                          <SoftBox
                          sx={{
                            display: "flex",
                            gap: 4,
                            flex: "1 1 0%",
                            marginBottom: "0.75rem",
                          }}
                        >
                          <SoftBox sx={{ flex: "1 1 0%" }}>
                            <SoftInput
                              id="companyCountry"
                              type="name"
                              className="shadow-sm rounded-lg"
                              placeholder="E.g. USA"
                              {...register("country", { required: true })}
                              size="large"
                              disabled={isDemoUser}
                            />
                          </SoftBox>
                          <SoftBox sx={{ flex: "1 1 0%" }}>
                            <SoftInput
                              id="companyStateRegion"
                              type="name"
                              className="shadow-sm rounded-lg"
                              placeholder="E.g. Idaho, USA"
                              {...register("state", { required: true })}
                              size="large"
                              disabled={isDemoUser}
                            />
                          </SoftBox>
                          </SoftBox>
                          <SoftBox
                            sx={{
                              display: "flex",
                              gap: 4,
                              flex: "1 1 0%",
                              marginBottom: "0.75rem",
                            }}
                          >
                          <SoftBox sx={{ flex: "1 1 0%" }}>
                            <SoftInput
                              id="companyStreetAddress"
                              type="name"
                              className="shadow-sm rounded-lg"
                              placeholder="E.g. 123 Bear Lake Ave"
                              {...register("addressOne", { required: true })}
                              size="large"
                              disabled={isDemoUser}
                            />
                          </SoftBox>
                          <SoftBox sx={{ flex: "1 1 0%" }}>
                            <SoftInput
                              id="addressLineTwo"
                              type="name"
                              className="shadow-sm rounded-lg"
                              placeholder="Address Line 2 (optional)"
                              {...register("addressTwo", { required: false })}
                              size="large"
                              disabled={isDemoUser}
                            />
                          </SoftBox>
                          </SoftBox>
                          <SoftBox
                            sx={{
                              display: "flex",
                              gap: 4,
                              flex: "1 1 0%",
                              marginBottom: "0.75rem",
                            }}
                          >
                            <SoftBox sx={{ flex: "1 1 0%" }}>
                              <SoftInput
                                id="companyTownCity"
                                type="name"
                                className="shadow-sm rounded-lg"
                                placeholder="E.g. Pocatello"
                                {...register("city", { required: true })}
                                size="large"
                                disabled={isDemoUser}
                              />
                            </SoftBox>
                            <SoftBox sx={{ flex: "1 1 0%" }}>
                              <SoftInput
                                id="companyZipcode"
                                type="name"
                                className="shadow-sm rounded-lg"
                                placeholder="E.g. 89123"
                                {...register("zipcode", { required: true })}
                                size="large"
                                disabled={isDemoUser}
                              />
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid>
                      <hr className="my-3 divide-slate-300 mt-5 mb-4" />
                      <Grid container>
                        <Grid item mini={3}>
                          <p style={{ color: '#000000', fontSize: '22px', fontWeight: '700' }}>Business Contact details</p>
                          <p>This section contains essential contact information for the business, facilitating seamless communication between stakeholders</p>
                        </Grid>
                        <Grid item mini={3}>{/* blank */}</Grid>
                        <Grid item mini={6}>
                          <SoftBox sx={{ display: "flex", gap: 4, flex: "1 1 0%" }}>
                            <SoftBox sx={{ flex: "1 1 0%" }}>
                              <SoftInput
                                id="companyPhoneNum"
                                type="tel"
                                placeholder="E.g. 912-123-8564"
                                format="###-###-####"
                                className="shadow-sm rounded-lg"
                                {...register("phoneNumber", { required: true })}
                                size="large"
                                disabled={isDemoUser}
                              />
                            </SoftBox>
                            <SoftBox sx={{ flex: "1 1 0%" }}>
                              <SoftInput
                                id="companyWebsite"
                                type="name"
                                className="shadow-sm rounded-lg"
                                placeholder="E.g. www.gamemybiz.com"
                                {...register("websiteUrl", { required: true })}
                                size="large"
                                disabled={isDemoUser}
                              />
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </form>
                )}
              </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}
