import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import { FETCH_ENABLED_WIDGETS } from "../../api/widgets";
import LoadingScreen from "../../components/LoadingScreen";
import DynamicComponent from "./components/index";
import { UseUserDetails } from "../../hooks/auth";

const WidgetsList = ({ searchTerm }) => {
  const [widgets, setWidgets] = useState([]);
  const [filteredWidgets, setFilteredWidgets] = useState([]);
  const [reFetch, setReFetch] = useState(0);
  const [fetchingWidgets, setFetchingWidgets] = useState(false);

  const userDetails = UseUserDetails();

  const fetchEnabledWidgets = async () => {
    setFetchingWidgets(true);
    try {
      const { data } = await FETCH_ENABLED_WIDGETS();
      let updatedWidgets = [];

      if (userDetails.widgets && userDetails.widgets.length) {
        updatedWidgets = data.map((widget) => {
          const item = userDetails.widgets.find((el) => el.key === widget.key);

          /* eslint-disable */
          return {
            ...widget,
            toggled: item ? true : false,
          };
        });
      } else {
        updatedWidgets = data.map((widget) => ({
          ...widget,
          toggled: false,
        }));
      }

      setWidgets(updatedWidgets);
      setFilteredWidgets(updatedWidgets);
    } catch (error) {
      console.log(error);
    }
    setFetchingWidgets(false);
  };

  const fetchEnabledWidgetsWithoutLoading = async () => {
    // ... (keep the existing code)
  };

  useEffect(() => {
    fetchEnabledWidgets();
  }, []);

  useEffect(() => {
    if (reFetch > 0) {
      fetchEnabledWidgetsWithoutLoading();
    }
  }, [reFetch]);

  useEffect(() => {
    const filtered = widgets.filter((widget) =>
      widget.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWidgets(filtered);
  }, [searchTerm, widgets]);

  return (
    <>
      {fetchingWidgets ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          {filteredWidgets.map((widget) => (
            <Grid
              item
              mini={12}
              xs={6}
              md={4}
              lg={3}
              xl={4}
              xxl={3}
              xxxl={2}
              key={widget.key}
            >
              <DynamicComponent
                widget={widget}
                setReFetch={() => setReFetch(reFetch + 1)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default WidgetsList;
