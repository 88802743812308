/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================
ORIGINATES FROM SOFT UI's CONFIGURATOR COMPONENT
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftAvatar
import SoftDrawerRoot from "./SoftDrawerRoot";

const SoftDrawer = forwardRef(({ open, shadow, dark, ...rest }, ref) => (
  <SoftDrawerRoot ref={ref} open={open} ownerState={{ open, shadow, dark }} {...rest}  />
));

// Setting default values for the props of SoftAvatar
SoftDrawer.defaultProps = {
  open: false,
  shadow: "lg",
  dark: false,
};

// Typechecking props for the SoftAvatar
SoftDrawer.propTypes = {
  open: PropTypes.bool,
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
  dark: PropTypes.bool,
};

export default SoftDrawer;
