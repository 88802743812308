/* eslint-disable no-nested-ternary */
import React from "react";
import { UseUserId } from "../../../hooks/auth";
import SoftTypography from "../../SoftTypography";
import useMediaQuery from "@mui/material/useMediaQuery";

// Import from material ui
import { Box, Divider } from "@mui/material";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import SoftBox from "../../SoftBox";
import { useTheme } from "@mui/material/styles";
import GoldBadge from "../../../img/ca-topGold.png";
import SilverBadge from "../../../img/ca-topSilver.png";
import BronzeBadge from "../../../img/ca-topBronze.png";
import OtherBadge from "../../../img/ca-topOther.png";

function BarLeaderboard({
  top,
  imgsrc,
  name,
  points,
  position,
  othersTop,
  userIdNum,
  rankColor,
}) {
  const barProgress = points ? Math.round((points / top) * 100) : 0;
  const mobileBarComponent = (
    <Box
      sx={{
        width: `${barProgress}%`,
        background: "linear-gradient(0deg, rgba(28, 132, 207, 0.40) 0%, rgba(28, 132, 207, 0.40) 100%), radial-gradient(50% 100% at 50.21% 100%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)",
        height: "28px",
        border: "2px solid rgba(28, 132, 207, 0.60)",
        borderRadius: "8px",
        boxShadow: "0px 0px 32px 4px rgba(23, 119, 242, 0.40), 0px 0px 48px 4px rgba(23, 119, 242, 0.20) inset",
        transition: "width 600ms linear",
      }}
    />
  );

  const userId = UseUserId();
  const leaderBp = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  return (
    <>
      <Box
        sx={{
          marginBottom: { mini: "1rem", xxl: "0rem" },
          background: "transparent",
          paddingY: { mini: 2, xs: 3 },
          paddingX: { mini: 1, xs: 2 },
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box>
            <SoftTypography
              variant="button"
              color="white"
              sx={{
                position: "absolute",
                zIndex: 2,
                marginLeft: position > 9 ? "46px" : "47px",
                fontSize: position > 9 ? "0.7rem" : "0.875rem",
                marginTop: position > 9 ? "1px" : 0,
              }}
            >
              #{position}
            </SoftTypography>
            <SoftBox
              sx={{
                position: "absolute",
                zIndex: 1,
                marginLeft: "10px",
                marginTop: "-38px",
              }}
            >
              <SoftBox>
                {position === 1 ? (
                  <img src={GoldBadge} alt="" />
                ) : position === 2 ? (
                  <img src={SilverBadge} alt="" />
                ) : position === 3 ? (
                  <img src={BronzeBadge} alt="" />
                ) : (
                  <img src={OtherBadge} alt="" />
                )}
              </SoftBox>
            </SoftBox>

            <SoftProfile
              src={imgsrc}
              glow={userIdNum === userId}
              variant="lg"
            />
          </Box>

          <Box
            sx={{
              // width: { mini: "70%", sm: "75%", xl: "80%", xxl: "85%" },
              flex: 1,
            }}
          >
            <Box>
              <SoftTypography
                variant="h6"
                color="white"
                sx={{ lineHeight: 1.6 }}
              >
                {name}
              </SoftTypography>
            </Box>
            <Box className="relative">
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Box
                  sx={{
                    height: "18px",
                    borderRadius: "4px",
                    display: "flex",
                    overflow: "hidden",
                    width: "100%",
                    background: "rgba(255, 255, 255, 0.06)",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      alignSelf: "end",
                      alignItems: "center",
                      position: "absolute",
                      paddingX: "6px",
                    }}
                  >
                    <SoftTypography
                      variant="body"
                      color="white"
                      sx={{
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: 1.4,
                      }}
                    >
                      {points}
                    </SoftTypography>
                    <SoftTypography
                      variant="body"
                      color="white"
                      sx={{
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: 1.4,
                      }}
                    >
                      reviews
                    </SoftTypography>
                  </Box>
                  {mobileBarComponent}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          backgroundImage:
            "linear-gradient(to right, rgba(54, 162, 240, 0.02), rgba(54, 162, 240, 1), rgba(54, 162, 240, 0.02)) !important",
          "&:not([size])": {
            height: "3px",
          },
          margin: 0,
        }}
      />
    </>
  );
}

export default BarLeaderboard;
