import React from "react";
import { Modal, Typography, Backdrop, Fade, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Confirmation = ({ showConfirmation, time, url }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={showConfirmation}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showConfirmation}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { mini: 300, xxs: 350, xs: 430, sm: 600 },
            bgcolor: "background.paper",
            borderRadius: "1.5rem",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="mb-[2rem] text-center">
            <Typography variant="h2">{time}</Typography>
          </div>
          <div className="text-center my-[1rem]">
            <Typography
              variant="h2"
              sx={(theme) => ({
                textAlign: "center",
                fontWeight: 800,
                background: `-webkit-linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main});`,
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              })}
              className="!text-[1.5rem] sm:!text-[2rem] lg:!text-[2.75rem] 2xl:!text-[3.5rem]"
            >
              THANK YOU
            </Typography>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "#223354",
              }}
              className="!text-[1.25rem] sm:!text-[1.75rem] lg:!text-[2.25rem] 2xl:!text-[3rem]"
            >
              for signing up
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
              className="text-[1rem] sm:!text-[1.5rem] !my-[2rem] text-[#3A416F]"
            >
              Please verify your email.
            </Typography>
          </div>

          <div className="text-center mt-[1rem]">
            <Button
              variant="contained"
              sx={{
                width: 220,
                height: 56,
                borderRadius: "12px",
              }}
              onClick={() => navigate(`/arena/${url}`)}
            >
              Go To Challenge Arena
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Confirmation;
