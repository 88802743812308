/* eslint-disable no-nested-ternary */
import React from "react";
import SoftBox from "../SoftBox";
import { useTheme } from "@mui/styles";
import OneVOneBlank from "../../img/1v1AvatarBlank.png";
import SoftTypography from "../SoftTypography";
import UserInfo from "./UserInfo";
import Avatar from "../Avatar";
import { UseCompanyLogo, UseCompanyIndustry } from "../../hooks/company";
import CompanyInfo from "./CompanyInfo";
import GoldCrown from "../../img/arena/GoldCrown.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const Rival = ({
  avatar,
  isOpponent,
  user,
  challengeScore,
  challengeDetails,
  currentLead,
  countdownEnded,
}) => {
  const theme = useTheme();
  const logo = UseCompanyLogo();
  const industry = UseCompanyIndustry();
  const {
    primary: { light: brighterPrimaryColor },
  } = theme.palette;
  const lgBp = useMediaQuery(() => theme.breakpoints.up("lg"));
  const xsBp = useMediaQuery(() => theme.breakpoints.up("xs"));

  return challengeDetails?.category === "EmployeeVsEmployee" ? (
    <SoftBox
      sx={{
        borderRadius: "16px",
        borderWidth: "1px",
        borderStyle: "color",
        borderColor:
          countdownEnded && currentLead?.companyId === !user._id
            ? "rgba(255, 252, 0, 0.60)"
            : avatar
            ? brighterPrimaryColor
            : "rgba(255, 255, 255, 0.10)",
        background:
          countdownEnded && currentLead?.companyId === !user._id
            ? "radial-gradient(50% 100% at 50% 0%, rgba(255, 252, 0, 0.30) 0%, rgba(255, 252, 0, 0.00) 100%), rgba(255, 252, 0, 0.50);"
            : avatar
            ? "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)"
            : "rgba(255, 255, 255, 0.04)",
        backdropFilter:
          countdownEnded && currentLead?.companyId === !user._id
            ? "blur(16px)"
            : "blur(40px)",
        "-webkit-backdrop-filter":
          countdownEnded && currentLead?.companyId === !user._id
            ? "blur(16px)"
            : "blur(40px)",
        boxShadow:
          countdownEnded && currentLead?.companyId === !user._id
            ? "-4px -4px 8px 0px rgba(255, 252, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 252, 0, 0.10) inset, 0px 0px 32px 0px rgba(255, 252, 0, 0.40)"
            : avatar
            ? "0px 0px 11.54595px 0px rgba(23, 119, 242, 0.40), 0px 0px 17.31892px 0px rgba(23, 119, 242, 0.20) inset"
            : "none",
        height: { mini: "100%", md: "90%", wide: "100%" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginY: "auto",
      }}
    >
      {countdownEnded && currentLead?.companyId === !user._id && (
        <SoftBox
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            zIndex: 2,
            width: "max-content",
            height: {
              mini: "270px",
              xxs: "360px",
              xs: "380px",
              md: "480px",
              lg: "650px",
              wide: "800px",
            },
          }}
        >
          <img
            src={GoldCrown}
            alt=""
            style={{
              width: "auto",
              height: lgBp ? "2.25rem" : xsBp ? "1.75rem" : "1.25rem",
            }}
          />
        </SoftBox>
      )}
      {isOpponent ? (
        <SoftBox
          sx={{
            position: "absolute",
            width: {
              mini: "120px",
              xxs: "180px",
              xs: "220px",
              md: "240px",
              lg: "350px",
              wide: "500px",
            },
            height: {
              mini: "300px",
              md: "400px",
              lg: "550px",
              wide: "720px",
            },
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
          }}
        >
          {challengeScore
            ?.filter((rival) => rival.companyId !== user._id)
            .map((rival) => (
              <Avatar url={rival.avatar} noControls={true} />
            ))}
        </SoftBox>
      ) : (
        <>
          <img
            src={OneVOneBlank}
            alt=""
            className="avatarImg1v1"
            style={{ width: "auto" }}
          />
          <SoftTypography
            variant="h1"
            sx={{
              fontSize: { mini: "12rem", md: "13rem", xxl: "14rem" },
              position: "absolute",
            }}
            color="white"
          >
            ?
          </SoftTypography>
        </>
      )}

      {isOpponent &&
        challengeScore
          ?.filter((rival) => rival.companyId !== user._id)
          .map((rival) => <UserInfo user={rival} />)}
    </SoftBox>
  ) : (
    // Company Vs Company
    <SoftBox
      sx={{
        borderRadius: "16px",
        borderWidth: "1px",
        borderStyle: "color",
        borderColor:
          countdownEnded && currentLead?.companyId === !user.companyId
            ? "rgba(255, 252, 0, 0.60)"
            : avatar
            ? brighterPrimaryColor
            : "rgba(255, 255, 255, 0.10)",
        background:
          countdownEnded && currentLead?.companyId === !user.companyId
            ? "radial-gradient(50% 100% at 50% 0%, rgba(255, 252, 0, 0.30) 0%, rgba(255, 252, 0, 0.00) 100%), rgba(255, 252, 0, 0.50);"
            : avatar
            ? "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)"
            : "rgba(255, 255, 255, 0.04)",
        backdropFilter:
          countdownEnded && currentLead?.companyId === !user.companyId
            ? "blur(16px)"
            : "blur(40px)",
        "-webkit-backdrop-filter":
          countdownEnded && currentLead?.companyId === !user.companyId
            ? "blur(16px)"
            : "blur(40px)",
        boxShadow:
          countdownEnded && currentLead?.companyId === !user.companyId
            ? "-4px -4px 8px 0px rgba(255, 252, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 252, 0, 0.10) inset, 0px 0px 32px 0px rgba(255, 252, 0, 0.40)"
            : avatar
            ? "0px 0px 11.54595px 0px rgba(23, 119, 242, 0.40), 0px 0px 17.31892px 0px rgba(23, 119, 242, 0.20) inset"
            : "none",
        height: { mini: "100%", md: "90%", wide: "100%" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginY: "auto",
      }}
    >
      {countdownEnded && currentLead?.companyId === !user.companyId && (
        <SoftBox
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            zIndex: 2,
            width: "max-content",
            height: {
              mini: "270px",
              xxs: "360px",
              xs: "380px",
              md: "480px",
              lg: "650px",
              wide: "800px",
            },
          }}
        >
          <img
            src={GoldCrown}
            alt=""
            style={{
              width: "auto",
              height: lgBp ? "2.25rem" : xsBp ? "1.75rem" : "1.25rem",
            }}
          />
        </SoftBox>
      )}
      {isOpponent ? (
        <SoftBox
          sx={{
            position: "absolute",
            width: {
              mini: "120px",
              xxs: "180px",
              xs: "220px",
              md: "240px",
              lg: "350px",
              wide: "500px",
            },
            height: {
              mini: "300px",
              md: "400px",
              lg: "550px",
              wide: "720px",
            },
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
          }}
        >
          {challengeScore
            ?.filter((comp) => comp.companyId !== user.companyId)
            .map((comp) => (
              <Avatar
                url={
                  // eslint-disable-next-line no-nested-ternary
                  comp.owner?.avatar
                    ? comp.owner.avatar
                    : comp.avatar
                    ? comp.avatar
                    : null
                }
                logo={comp.image}
                industry={comp.industry}
                noControls={true}
              />
            ))}
        </SoftBox>
      ) : (
        <>
          <img
            src={OneVOneBlank}
            alt=""
            className="avatarImg1v1"
            style={{ width: "auto" }}
          />
          <SoftTypography
            variant="h1"
            sx={{
              fontSize: { mini: "12rem", md: "13rem", xxl: "14rem" },
              position: "absolute",
            }}
            color="white"
          >
            ?
          </SoftTypography>
        </>
      )}

      {isOpponent &&
        challengeScore
          ?.filter((comp) => comp.companyId !== user.companyId)
          .map((comp) => (
            <CompanyInfo
              companyName={comp.company}
              companyLogo={comp.image}
              companyScore={comp.total}
            />
          ))}
    </SoftBox>
  );
};

export default Rival;
