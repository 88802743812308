import React from "react";
import { styled } from '@mui/material/styles';
import SoftBox from "../../../SoftBox";

const StepperIcon = (props) => {
  const { active, completed, className } = props;
  const rectangleStyles = {
    width: "72px",
    height: "8px",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #000",
  };

  return (
    <SoftBox>
      {completed ? (
        <SoftBox sx={rectangleStyles}>
          &nbsp;
        </SoftBox>
      ) : (
        <SoftBox sx={{ ...rectangleStyles, backgroundColor: "#667085"}}>
          &nbsp;
        </SoftBox>
      )}
    </SoftBox>
  );
}

export default StepperIcon;
