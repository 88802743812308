/* eslint-disable import/no-unresolved */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "gmb-dev-7e3df.firebaseapp.com",
    projectId: "gmb-dev-7e3df",
    storageBucket: "gmb-dev-7e3df.appspot.com",
    messagingSenderId: "716865589758",
    appId: "1:716865589758:web:438ced2c5e49aa8d6a30af",
    measurementId: "G-VZBTB8R9CN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);