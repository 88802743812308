import Resizer from "react-image-file-resizer";

export const GET_IMAGE_URL = (imageKey) =>
  `${process.env.REACT_APP_LINK_HOST}/images/${imageKey}`;

export const GET_IMAGE = (src) => {
  if (src) {
    let imgUrl = null;
    try {
      const testUrl = new URL(src);
      imgUrl = src;
    } catch (_) {
      imgUrl = GET_IMAGE_URL(src);
    }

    return imgUrl;
  }
  return null;
};

export const resizeImage = (file) => {
  try {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1280,
        720,
        "JPEG",
        75,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    });
  } catch (err) {
    console.log("File Resize error", err);
    return null;
  }
};
