import React, { useState, useMemo } from "react";

import SoftBox from "../../SoftBox";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import HighlightKeyword from "../../TextEffects/HighlightKeyword";
import MetricsModal from "../metrics/MetricsModal";

import zapierLogo from "../../../img/brands/zapier-logo_black.png";
import { ReactComponent as DataCubeIcon } from "../../../img/datacube-icon.svg";
import BoltIcon from "@mui/icons-material/Bolt";

import CARD_STYLES from "../configs/cardStyles";

const AppCard = ({ app, keyword, functions, workspaceKpiCount, groupKpis }) => {
  const [viewMetrics, setViewMetrics] = useState(false);
  const style = useMemo(() => {
    if (app.isZapier) {
      return CARD_STYLES.zapier;
    }
    return app.isConnected ? CARD_STYLES.connected : CARD_STYLES.default;
  }, [app]);

  const metricCount = useMemo(() => app?.metrics?.length ?? 0, [app?.metrics]);

  return (
    <SoftBox
      variant="contained"
      borderRadius="md"
      className="flex flex-col justify-between items-start"
      sx={{
        minHeight: "168px",
        border: `1px solid ${style.borderColor}`,
        background: style.bgColor,
        paddingX: { xs: "1rem", xxl: "1.5rem" },
        paddingY: { xs: "0.75rem", xxl: "1rem" },
      }}
    >
      <SoftBox className="w-full flex justify-between items-center">
        <SoftProfile
          src={app.logo}
          name={app.name}
          size="sm"
          borderRadius="md"
          sx={{ background: "#FFF" }}
        />
        <SoftBox className="flex gap-0 items-center">
          {app.isZapier && (
            <img
              src={zapierLogo}
              alt="Zapier"
              style={{
                height: "1rem",
                width: "auto",
                filter: !app.isConnected ? "grayscale(80%)" : "none",
              }}
            />
          )}
          {app.isConnected && !app.isManual && (
            <BoltIcon
              sx={{
                color: style.borderColor,
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          )}
          {app.isManual && (
            <>
              <DataCubeIcon width="1.5rem" height="1.5rem" />
              <SoftTypography
                sx={{
                  marginLeft: 1,
                  color: "#343A40",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Data Cube{" "}
              </SoftTypography>
            </>
          )}
        </SoftBox>
      </SoftBox>
      <SoftBox className="flex flex-col mt-1">
        <SoftTypography
          variant="body2"
          sx={{
            color: "#6C757D",
            fontWeight: 500,
          }}
        >
          <HighlightKeyword text={app.name} keyword={keyword} color="#04549C" />
        </SoftTypography>
        <SoftTypography
          variant="body2"
          sx={{
            color: "#6C757D",
            fontSize: "0.75rem",
          }}
        >
          {app.description ??
            (app.dataSets
              ? `Metrics for ${app.dataSets?.join(", ")}`
              : `${app.name} integration`)}
        </SoftTypography>
      </SoftBox>
      <SoftBox className="w-full flex justify-between items-center">
        <SoftTypography
          sx={{
            color: "#343A40",
            fontSize: "0.9rem",
            fontWeight: 600,
          }}
        >
          {metricCount} Metric{metricCount > 1 ? "s" : ""}
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="primary"
          size="small"
          className="!normal-case !py-0 !px-2 !font-['Inter'] !text-[10px]"
          onClick={() => setViewMetrics(true)}
        >
          View Metrics
        </SoftButton>
      </SoftBox>
      <MetricsModal
        app={app}
        isOpen={viewMetrics}
        handleClose={() => setViewMetrics(false)}
        functions={functions}
        workspaceKpiCount={workspaceKpiCount}
        groupKpis={groupKpis}
      />
    </SoftBox>
  );
};

export default AppCard;
