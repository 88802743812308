import React, { useState } from "react";
import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import {
  CloseRounded,
  QuestionMark,
  Psychology,
  SportsEsports,
} from "@mui/icons-material";

import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import { PUT_USER } from "../../api/user";
import ERRORS from "../../constants/errorStatus";
import UpgradePopUp from "../../components/ChallengeArenaPopUp/UpgradePopUp";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";

const UpdatePlayerRole = ({ user, isLoggedInUser }) => {
  const [updateRole, setUpdateRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const openRemoveDialog = () => setUpdateRole(true);
  const closeUpdateRoleDialog = () => setUpdateRole(false);

  const openUpgradeDialog = () => setShowUpgrade(true);
  const closeUpgradeDialog = () => setShowUpgrade(false);

  const dispatch = useDispatch();

  const handleAdmin = async (id, admin) => {
    setLoading(true);
    const action = admin ? "Remov" : "Add";

    try {
      await PUT_USER(id, { isAdmin: !admin }, false);
      setLoading(true);
      closeUpdateRoleDialog();
      setTimeout(() => {
        dispatch(getCompanyUsers());
      }, 1000);

      toast.success(`Admin Successfully ${action}ed`);
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === ERRORS.UPGRADE_REQUIRED) {
        openUpgradeDialog();
      }
      toast.error(err?.response?.data?.message || "Unable to update role");
    }
  };

  return (
    <>
      <Tooltip
        disableFocusListener
        title={`Click to set as ${user.isAdmin ? "Player" : "Admin"}`}
        placement="top"
      >
        <SoftButton
          size="small"
          variant={user.isAdmin ? "gradient" : "outlined"}
          color="primary"
          disabled={isLoggedInUser}
          sx={{ width: "8rem", borderRadius: "4px" }}
          onClick={openRemoveDialog}
        >
          {user.isAdmin ? (
            <>
              Admin <Psychology fontSize="small" />
            </>
          ) : (
            <>
              Player <SportsEsports fontSize="small" />
            </>
          )}
        </SoftButton>
      </Tooltip>
      {updateRole && (
        <Dialog
          open={updateRole}
          onClose={closeUpdateRoleDialog}
          sx={{
            "& .MuiPaper-root": {
              padding: { mini: "1rem", md: "1.5rem 1.7rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "700px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
            <SoftBox display="flex" justifyContent="flex-end">
              <SoftButton
                variant="outlined"
                color="white"
                onClick={closeUpdateRoleDialog}
                sx={{
                  "& svg": { fontSize: "1.25rem !important" },
                  borderRadius: "50%",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  padding: "0.25rem",
                }}
              >
                <CloseRounded />
              </SoftButton>
            </SoftBox>
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb="60px"
            >
              <QuestionMark
                sx={{ fontSize: "100px !important" }}
                color="white"
              />
              <SoftTypography
                component="h4"
                sx={{
                  fontWeight: 700,
                  color: "#FFF",
                  fontSize: 24,
                  marginBottom: "20px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to update the role of {user.firstName}{" "}
                {user.lastName} to {user.isAdmin ? "Player" : "Admin"}?
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftButton
                color="white"
                variant="outlined"
                disabled={loading}
                onClick={closeUpdateRoleDialog}
                sx={{
                  textTransform: "none",
                  borderRadius: "4px",
                }}
              >
                Cancel
              </SoftButton>
              <SoftButton
                variant="contained"
                color="primary"
                onClick={() => handleAdmin(user.userId, user.isAdmin)}
                disabled={loading}
                sx={{
                  mx: 2,
                  textTransform: "none",
                  color: "primary.contrastText",
                  borderRadius: "4px",
                }}
              >
                Set to {user.isAdmin ? "Player" : "Admin"}
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    className="ml-2"
                  />
                )}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Dialog>
      )}
      {showUpgrade && (
        <UpgradePopUp
          showUpgrade={showUpgrade}
          closeUpgrade={closeUpgradeDialog}
        />
      )}
    </>
  );
};

export default UpdatePlayerRole;
