export const initState = {
  jobs: [],
  currentPage: 1,
  hasMore: false,
  isLoading: false,
  error: "",
};

export const types = {
  GET_ALL_JOBS: "GET_ALL_JOBS",
  GET_ALL_JOBS_SUCCESS: "GET_ALL_JOBS_SUCCESS",
  GET_ALL_JOBS_FAILED: "GET_ALL_JOBS_FAILED",
  GET_MY_JOBS: "GET_MY_JOBS",

  GET_A_JOB: "GET_A_JOB", // Refresh one specific Job data
  GET_A_JOB_SUCCESS: "GET_A_JOB_SUCCESS",
  GET_A_JOB_FAILED: "GET_A_JOB_FAILED",
  RESET_JOBS: "RESET_JOBS",
  ADD_COMMENT: "ADD_COMMENT"
};
