import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { UseCompanyColors } from "./hooks/company";
import { generateTheme } from "./theme";
import { AuthContextProvider } from "./context/authContext";
import { SoftUIControllerProvider } from "./context/softui";
import { AudioProvider } from "./context/audioContext";
import AppRoutes from "./routes";
import "./App.css";

const queryClient = new QueryClient();

function App() {
  const { primaryColor } = UseCompanyColors();
  const theme = React.useMemo(
    () => generateTheme(primaryColor),
    [primaryColor],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AudioProvider>
          <AuthContextProvider>
            <SoftUIControllerProvider>
              <AppRoutes />
            </SoftUIControllerProvider>
          </AuthContextProvider>
        </AudioProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
