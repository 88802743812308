import { combineReducers } from "redux";
import appReducer from "./app/reducer";
import authReducer from "./auth/reducer";
import usersReducer from "./user/reducer";
import kpiReducer from "./kpi/reducer";
import companyReducer from "./company/reducer";
import jobsReducer from "./jobs/reducer";
import messageReducer from "./message/reducer";
import workspacesReducer from "./workspace/reducer";
import masterBadgeReducer from "./badges/reducer";
import chatsReducer from "./chats/reducer";
import notificationsReducer from "./notifications/reducer";
import arenaReducer from "./arena/reducer";
import claimsReducer from "./claims/reducer";
import { types } from "./types";

const allReducers = combineReducers({
  appReducer,
  authReducer,
  usersReducer,
  kpiReducer,
  companyReducer,
  jobsReducer,
  messageReducer,
  workspacesReducer,
  masterBadgeReducer,
  chatsReducer,
  notificationsReducer,
  arenaReducer,
  claimsReducer,
});

const rootReducer = (state, act) => {
  switch (act.type) {
    case types.SIGN_OUT:
      // eslint-disable-next-line no-case-declarations
      const { appReducer: appState } = state; // Reset all saved information execept for appReducer
      state = { appReducer: appState };
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return allReducers(state, act);
    default:
      return allReducers(state, act);
  }
};
export default rootReducer;
