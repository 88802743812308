import React, { useState, useEffect } from "react";
import SoftBox from "../../SoftBox";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import { formatNumber } from "../../../helpers/formatter";

// Imported Images and Icons
import goldRank from "../../../img/lb-gold.png";
import silverRank from "../../../img/lb-silver.png";
import bronzeRank from "../../../img/lb-bronze.png";

const RANK_BADGES = [goldRank, silverRank, bronzeRank];

const CompanyTopPerformers = ({ groupKpis, usersKpis, bigMode = true }) => {
  const [kpiHeaders, setKpiHeaders] = useState([]);
  const [kpiScores, setKpiScores] = useState([]);

  useEffect(() => {
    if (groupKpis?.length) {
      setKpiHeaders(groupKpis?.map((kpi) => kpi.title.toUpperCase()));
    }
  }, [groupKpis]);

  useEffect(() => {
    if (groupKpis?.length) {
      if (usersKpis?.length) {
        setKpiScores(
          usersKpis.map((kpi) => {
            const kpiAmounts = [];
            groupKpis.forEach((gKpi) => {
              kpiAmounts.push({
                id: gKpi._id,
                amt: kpi.scores[gKpi._id]?.amt || 0,
                kind: gKpi.kind,
              });
            });

            return {
              ...kpi,
              kpiAmounts,
            };
          }),
        );
      } else {
        setKpiScores([]);
      }
    }
  }, [usersKpis, groupKpis]);

  return (
    <div className="grow rounded-lg border border-[#F6F6F6] border-solid overflow-x-scroll h-full">
      <table className="w-full h-full">
        <thead className="font-semibold text-sm md:text-md lg:text-lg xl:text-lg text-center">
          <tr>
            <td className="px-2 py-1 text-[#8392AB]">#</td>
            <td className="px-2 py-1 text-[#8392AB]">PLAYER</td>
            {bigMode && <td className="px-2 py-1 text-[#8392AB]">WORKSPACE</td>}
            <td className="px-2 py-1 text-[#8392AB]">TOTAL POINTS</td>
            {kpiHeaders.map((kpi) => (
              <td key={kpi} className="px-2 py-1 text-[#8392AB]">
                {kpi}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="text-center mb-2">
          {kpiScores.map((score, index) => {
            const badge =
              index < RANK_BADGES.length ? (
                <img
                  height="100%"
                  width="auto"
                  style={{ maxWidth: bigMode ? "4rem" : "3rem" }}
                  src={RANK_BADGES[index]}
                  alt="badgeRank"
                />
              ) : (
                <p className="font-semibold text-xl leading-[45.3px]">
                  {index + 1}
                </p>
              );

            return (
              <tr
                key={score.userId}
                className="border-bottom border-[#F6F6F6] border-solid"
              >
                <td className="px-2 py-2">{badge}</td>
                <td className="px-2 py-2">
                  <div className="flex items-center justify-start">
                    <SoftProfile
                      src={score.image}
                      size={bigMode ? "md" : "sm"}
                      borderRadius="lg"
                      name={score.firstName}
                      sx={{ mr: 1 }}
                    />
                    <div className="text-left">
                      <p className="font-semibold text-sm sm:text-md md:text-lg !leading-none">
                        {score.firstName}{" "}
                        {bigMode
                          ? score.lastName
                          : `${score.lastName?.charAt(0)}.`}
                      </p>
                    </div>
                  </div>
                </td>
                {bigMode && (
                  <td className="px-2 py-2">
                    <div className="flex items-center justify-start w-full">
                      <SoftProfile
                        src={score.group?.image}
                        size={bigMode ? "md" : "sm"}
                        borderRadius="lg"
                        name={score.group?.name}
                        sx={{ mr: 1 }}
                      />
                      <div className="text-left">
                        <p className="font-semibold text-sm sm:text-md md:text-lg !leading-none">
                          {score.group?.name || "Ungrouped"}
                        </p>
                      </div>
                    </div>
                  </td>
                )}
                <td className="px-2 py-2 font-semibold">
                  {Math.round(score.scores.total)}
                </td>
                {score.kpiAmounts?.map((kpi) => (
                  <td key={kpi.id} className="px-3 py-2 font-semibold">
                    {formatNumber(kpi.amt, kpi.kind)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default CompanyTopPerformers;
