import React from "react";
import { List, useTheme } from "@mui/material";

import SoftBox from "../SoftBox";
import adminFooterRoutes from "./adminFooterRoutes";
import playerFooterRoutes from "./playerFooterRoutes";
import { UseIsAdmin } from "../../hooks/auth";
import MenuItem from "./MenuItem";

const ProfileDrawer = () => {
  const isAdmin = UseIsAdmin();
  const theme = useTheme();

  const {
    secondary: { main: darkerPrimaryColor },
  } = theme.palette;

  return (
    <SoftBox
      sx={{
        display: "flex",
        width: "82%",
        borderRadius: "4px !important",
        padding: "15px",
        background: darkerPrimaryColor,
        margin: "0 auto",
      }}
    >
      <List sx={{ width: "100%" }}>
        {isAdmin &&
          adminFooterRoutes.map((route) => (
            <MenuItem key={route.key} route={route} />
          ))}
        {!isAdmin &&
          playerFooterRoutes.map((route) => (
            <MenuItem key={route.key} route={route} />
          ))}
      </List>
    </SoftBox>
  );
};

export default ProfileDrawer;
