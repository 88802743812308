import React from "react";

const QuestionMark = () => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.63116 11.7245L2.54061 7.9892H3.58197C4.35167 7.9892 5.05346 7.92128 5.68733 7.78545C6.3212 7.64962 6.82679 7.38551 7.2041 6.99311C7.5814 6.60072 7.77005 6.02721 7.77005 5.2726C7.77005 4.54818 7.55122 3.98222 7.11354 3.57473C6.67587 3.15215 6.09482 2.94086 5.37039 2.94086C4.61578 2.94086 4.02719 3.15215 3.60461 3.57473C3.18202 3.98222 2.97073 4.54063 2.97073 5.24997H0.2315C0.216408 4.34443 0.412606 3.537 0.820096 2.82767C1.22759 2.11833 1.81618 1.55992 2.58588 1.15243C3.37068 0.744944 4.30639 0.541199 5.39303 0.541199C6.40421 0.541199 7.29465 0.729852 8.06435 1.10716C8.84915 1.48446 9.46038 2.02778 9.89805 2.73711C10.3508 3.43136 10.5772 4.28406 10.5772 5.29524C10.5772 6.3366 10.3433 7.19686 9.87542 7.87601C9.42265 8.55516 8.79632 9.06075 7.99644 9.39277C7.21164 9.7248 6.29856 9.89836 5.2572 9.91345L5.18929 11.7245H2.63116ZM3.92154 16.8181C3.39332 16.8181 2.95564 16.6521 2.60852 16.3201C2.27649 15.9881 2.11048 15.5881 2.11048 15.1203C2.11048 14.6373 2.27649 14.2298 2.60852 13.8978C2.95564 13.5658 3.39332 13.3998 3.92154 13.3998C4.44977 13.3998 4.8799 13.5658 5.21193 13.8978C5.54395 14.2298 5.70997 14.6373 5.70997 15.1203C5.70997 15.5881 5.54395 15.9881 5.21193 16.3201C4.8799 16.6521 4.44977 16.8181 3.92154 16.8181Z"
        fill="white"
      />
    </svg>
  );
};

export default QuestionMark;
