import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";

import Slider from "react-slick";
import Gameboard from "../../components/gameboard";
import ColoredPodiums from "../../components/Podiums/ColoredPodiums";
import ItemBox from "../../components/YetiKpiBox/ItemBox";
import ProgressBar from "../../components/LeaderBoard/LiveLeaderboard/ProgressBar";
import WorkspacePopover from "../../components/WorkspaceDrawer/WorkspacePopover";
import SoftProfile from "../../components/ProfilePictures/SoftProfile";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import Grid from "@mui/material/Grid";

import { UseMyKpis, UseGroupKpis, UseTopPlayers } from "../../hooks/kpi";
import { UseCompany, UseSelectedWorkspace } from "../../hooks/company";
import { refreshKpis } from "../../redux/kpi/actions";

import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import GMBLogo from "../../img/main_logo.png";
import "./slider.css";

const BACK_TO_HOME = "company-view";

const TVLeaderboard = () => {
  const [dark, setDark] = useState(true);
  const topPlayers = UseTopPlayers("month");
  const groupKpis = UseGroupKpis();
  const userKpis = UseMyKpis(true);
  const company = UseCompany();
  const workspace = UseSelectedWorkspace();
  const dispatch = useDispatch();

  const kpis = useMemo(() => {
    if (groupKpis) {
      const kpiSummary = groupKpis?.map((kpiItem) => ({
        ...kpiItem,
        amount: userKpis?.month[kpiItem._id]?.amt ?? 0,
        points: userKpis?.month[kpiItem._id]?.pts ?? 0,
        target: kpiItem.target,
      }));

      return kpiSummary;
    }
    return null;
  }, [groupKpis, userKpis]);

  useEffect(() => {
    // Refresh scores every x minutes
    setInterval(() => {
      dispatch(refreshKpis());
    }, 3 * 60 * 1000); 
  }, [dispatch]);

  return (
    <SoftBox
      className="w-[100vw] h-[100vh] flex justify-center items-center relative"
      sx={{
        px: "3vw",
        pt: "9vh",
        pb: "1vh",
        ...(dark && {
          background:
            "radial-gradient(50% 50% at 55.21% 50%, #29375B 0%, #21272D 100%);",
        }),
      }}
      
    >
      {/** Top bar */}
      <SoftBox
        className="absolute top-0 left-0 w-full h-[7vh] flex justify-between items-center px-[2%]"
        variant="gradient" // applied when not in dark mode
        bgColor="primary" // applied when not in dark mode
        sx={{
          ...(dark && {
            background:
              "linear-gradient(180deg, rgba(54, 77, 108, 0.80) 0%, rgba(11, 19, 29, 0.80) 100%)",
            backdropFilter: "blur(12px)",
          }),
        }}
      >
        <SoftBox>
          <SoftBox
            shadow="lg"
            borderRadius="section"
            bgColor={dark ? "white" : "dark"}
            className="min-w-[12vw] h-[5vh] w-auto flex justify-between items-center px-[5%] py-[5%] gap-[5%] relative"
          >
            <SoftBox className="h-full flex items-center gap-[4%]">
              <SoftProfile
                size="md"
                src={company?.logo}
                name={company?.businessName}
              />
              <SoftTypography
                variant="h1"
                color={dark ? "black" : "white"}
                sx={{ fontSize: "1.75vh" }}
              >
                {workspace?.name}
              </SoftTypography>
            </SoftBox>
            <WorkspacePopover darkMode={!dark} viewOnly={true} expands={true} />
          </SoftBox>
        </SoftBox>
        <SoftBox className="h-full flex justify-center items-center gap-[4%] min-w-[15vw] translate-x-[-25%]">
          <SoftBox
            bgColor={dark ? "black" : "white"}
            className="w-[3.5vh] h-[3.5vh] flex justify-center items-center"
            sx={{ borderRadius: "1vh", cursor: "pointer" }}
            onClick={() => {
              // Used window.location.replace instead of react-router-dom navigate to fully refresh the page for TV
              window.location.replace(`${window.location.origin}/${BACK_TO_HOME}`);
            }}
          >
            <img
              src={GMBLogo}
              alt="Game My Biz"
              style={{ width: "70%", height: "auto", aspectRatio: "1/1" }}
            />
          </SoftBox>
          <SoftTypography
            variant="h1"
            color="white"
            sx={{ fontSize: "2.5vh", cursor: "pointer" }}
            onClick={() => {
              // Used window.location.replace instead of react-router-dom navigate to fully refresh the page for TV
              window.location.replace(`${window.location.origin}/${BACK_TO_HOME}`);
            }}
          >
            Game My Biz
          </SoftTypography>
        </SoftBox>

        <SoftBox>
          <SoftButton
            iconOnly={true}
            circular={true}
            sx={{ fontSize: "4vh", width: "5vh", height: "5vh" }}
            color={dark ? "light" : "dark"}
            onClick={() => setDark((prevValue) => !prevValue)}
          >
            {dark ? <LightModeIcon /> : <DarkModeIcon />}
          </SoftButton>
        </SoftBox>
      </SoftBox>

      {/* Main Section */}

      <Grid
        container
        gap={0}
        spacing={0}
        className="w-auto h-full aspect-video flex justify-center"
      >
        <Grid item xs={6} className="h-[44vh] px-[1vw]">
          {/** Gameboard element; Adding key based on dark mode to force rerender when switching modes */}
          <Gameboard
            key={`gameboard-${dark ? "dark" : "light"}`}
            darkMode={dark}
            noScroll
          />
        </Grid>
        <Grid item xs={6} className="h-[44vh] px-[1vw]">
          <SoftBox
            variant="gradient"
            bgColor={dark ? "dark" : "transparent"}
            shadow="sm"
            className="w-full h-full py-4 px-12"
            sx={{ borderRadius: "2vw" }}
          >
            <ColoredPodiums
              darkMode={dark}
              players={topPlayers ? [...topPlayers].splice(0, 3) : []}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={6} className="h-[44vh] px-[1vw]">
          <SoftBox
            variant="gradient"
            bgColor={dark ? "dark" : "transparent"}
            shadow="sm"
            className="w-full h-full py-[2%] px-[2%] flex justify-center items-center"
            sx={{ borderRadius: "2vw" }}
          >
            <SoftBox
              container
              className="w-full h-full flex flex-wrap justify-center items-center"
            >
              {kpis?.map((kpi) => (
                <SoftBox
                  item
                  xs={4}
                  className="w-[32%] h-1/2 py-[1%] px-[2%]"
                  key={kpi._id}
                >
                  <ItemBox
                    kpi={kpi}
                    amount={kpi.amount}
                    points={kpi.points}
                    darkMode={dark}
                  />
                </SoftBox>
              ))}
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={6} className="h-[44vh] px-[1vw]">
          <SoftBox
            variant="gradient"
            bgColor={dark ? "dark" : "transparent"}
            shadow="sm"
            className="w-full h-full py-[1%] px-[2%]"
            sx={{ borderRadius: "2vw" }}
          >
            <Slider
              dots
              infinite
              arrows={false}
              autoplay={true}
              speed={1000}
              autoplaySpeed={5000}
              rows={4}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {topPlayers && topPlayers.length
                ? topPlayers.map((user, index) => (
                    <ProgressBar
                      key={user.userId || user.firstName}
                      userId={user.userId}
                      userFirstName={user.firstName}
                      userLastName={user.lastName}
                      groupKpis={groupKpis}
                      userKpis={user.scores}
                      isOwnId={user.ownId}
                      imgsrc={user.image}
                      top={topPlayers[0].scores.total}
                      email={user.email}
                      position={index + 1}
                      prevPosition={user.yesterdayRank}
                      showRankMove
                      isActive={false}
                      isYeti={false}
                      darkMode={dark}
                      height="10vh"
                      fullColor
                      selectable={false}
                    />
                  ))
                : null}
            </Slider>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default TVLeaderboard;
