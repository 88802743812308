import Level1Bg from "../../../img/level1-bg.svg";
import Level2Bg from "../../../img/level2-bg.svg";
import Level3Bg from "../../../img/level3-bg.svg";
import Level4Bg from "../../../img/level4-bg.svg";
import Level5Bg from "../../../img/level5-bg.svg";
import Level6Bg from "../../../img/level6-bg.svg";
import Level7Bg from "../../../img/level7-bg.svg";
import Level8Bg from "../../../img/level8-bg.svg";
import Level9Bg from "../../../img/level9-bg.svg";
import Level10Bg from "../../../img/level10-bg.svg";

export const dataLevels = [
  {
    id: 1,
    lvlName: "Level 1",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 1",
    lvlColor: "#FF5722",
    lvlBackground: Level1Bg,
    lvlBGColor: "#FF5722",
  },
  {
    id: 2,
    lvlName: "Level 2",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 2",
    lvlColor: "#FF1100",
    lvlBackground: Level2Bg,
    lvlBGColor: "#FF1100",
  },
  {
    id: 3,
    lvlName: "Level 3",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 3",
    lvlColor: "#E91E63",
    lvlBackground: Level3Bg,
    lvlBGColor: "#E91E63",
  },
  {
    id: 4,
    lvlName: "Level 4",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 4",
    lvlColor: "#9C27B0",
    lvlBackground: Level4Bg,
    lvlBGColor: "#9C27B0",
  },
  {
    id: 5,
    lvlName: "Level 5",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 5",
    lvlColor: "#673AB7",
    lvlBackground: Level5Bg,
    lvlBGColor: "#673AB7",
  },
  {
    id: 6,
    lvlName: "Level 6",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 1",
    lvlColor: "#2C387E",
    lvlBackground: Level6Bg,
    lvlBGColor: "#3F51B5",
  },
  {
    id: 7,
    lvlName: "Level 7",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 2",
    lvlColor: "#1769AA",
    lvlBackground: Level7Bg,
    lvlBGColor: "#2196F3",
  },
  {
    id: 8,
    lvlName: "Level 8",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 3",
    lvlColor: "#357A38",
    lvlBackground: Level8Bg,
    lvlBGColor: "#4CAF50",
  },
  {
    id: 9,
    lvlName: "Level 9",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 4",
    lvlColor: "#618833",
    lvlBackground: Level9Bg,
    lvlBGColor: "#8BC34A",
  },
  {
    id: 10,
    lvlName: "Level 10",
    lvlInfo: "Level Description",
    lvlNum: "LEVEL 5",
    lvlColor: "#B2A429",
    lvlBackground: Level10Bg,
    lvlBGColor: "#FFEB3B",
  },
];
