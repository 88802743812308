export default {
  SOCKET_EVENT: {
    JOIN_JOB: "joinJob",
    REFRESH_JOB: "refreshJob",
    NOTIFY_REFETCH_JOB: "notifyRefetchJob",
    POST_COMMENT: "postComment",
    NEW_USER_CONNECTED: "newUserConnected",
    SEND_COMMENT_NOTI: "sendCommentNoti",
    SEND_SLOW_CLAP_NOTI: "sendSlowClapNoti",
    POST_SLOW_CLAP: "psotSlowClap",
    REAL_TIME_EMIT: "realTimeEmit",
    REAL_TIME_FROM_SERVER: "realTimeFromServer",
    TOTAL_ACTIVE_USERS: "totalActiveUsers",
    BADGE_COMPLETED: "BADGE_COMPLETED",
    REFERRAL_SIGN_UP: "REFERRAL_SIGN_UP",
    SETUP_CHAT: "SETUP_CHAT",
    CHAT_CONNECTED: "CHAT_CONNECTED",
    JOIN_CHAT: "JOIN_CHAT",
    NEW_MESSAGE: "NEW_MESSAGE",
    MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
    TYPING: "TYPING",
    STOP_TYPING: "STOP_TYPING",
    SEND_MESSAGE_NOTI: "SEND_MESSAGE_NOTI",
    SEND_UNREAD_MESSAGE_NOTIFICATION: "SEND_UNREAD_MESSAGE_NOTIFICATION",
  },
};
