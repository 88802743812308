import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";
import { ADD_WIDGETS_TO_LIST } from "../../../../api/userWidgets";
import { refetchUserData } from "../../../../redux/auth/actions";
import { UseUserId } from "../../../../hooks/auth";

const Level = ({ widget, closeModal, setButtonLabel, setReFetch }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const userId = UseUserId();

  const triggerSave = async () => {
    setLoading(true);
    const payload = {
      userId,
      key: widget.key,
      category: widget.category,
      title: widget.title,
    };

    try {
      await ADD_WIDGETS_TO_LIST(payload);
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        setButtonLabel("Deactivate");
        toast.success("Widget activated successfully");
        closeModal();
        setReFetch();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "420px",
          margin: "0 auto",
          textAlign: "center",
          gap: "16px",
        }}
      >
        <SoftTypography variant="p" sx={{ color: "#67748E" }}>
          Are you ready to activate your widget and unlock its full potential?
        </SoftTypography>
        <SoftTypography variant="h2" sx={{ color: "#FFF" }}>
          Level Widget
        </SoftTypography>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            textTransform: "none",
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          sx={{
            width: "100%",
            textTransform: "none",
          }}
          onClick={triggerSave}
          disabled={loading}
          loading={loading}
        >
          Activate
        </SoftButton>
      </SoftBox>
    </>
  );
};

export default Level;
