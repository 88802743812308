/* eslint camelcase: 0 */
import { request } from "../helpers/axios.new";

export const CREATE_LINKS = (kpiId, companyId, data) => {
  return request({
    url: `/links?kpiId=${kpiId}&companyId=${companyId}`,
    method: "POST",
    data,
  });
};

export const GET_LINKS = (companyId) => {
  return request({
    url: `/links?companyId=${companyId}`,
    method: "GET",
  });
};
