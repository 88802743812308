import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftButton from "../../../SoftButton";
import SoftTypography from "../../../SoftTypography";

// Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const WithDarkModal = (Component) => {
  const DarkModalWrapper = (props) => {
    const [open, setIsOpen] = useState(false);

    const {
      title = "Sample Dialog",
      yesButtonLabel = "Ok",
      handleYesClick,
      handleNoClick,
      maxWidth = "md",
      children: ClickableButton
    } = props;

    const handleConfirmClick = () => {
      setIsOpen(prev => !prev);
      handleYesClick?.();
    }

    const handleClose = () => {
      setIsOpen(false);
      handleNoClick?.();
    }
    
    return (
      <>
      {React.Children.map(ClickableButton, (child) => {
        return React.cloneElement(child, {
          onClick: () => {
            setIsOpen(true);
          }
        })
      })}
      <Dialog
        open={open}
        maxWidth={maxWidth}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            border: (theme) => `3px solid ${theme.palette.primary.light}`,
            borderRadius: "8px",
            background: `linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)`,
            boxShadow: "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(15px)",
            padding: (thene) => thene.spacing(5)
          },
          "& h4": {
            color: "#fff"
          }
        }}
      >
        {/** Dialog Title */}
        <SoftBox 
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
        >
          <SoftTypography variant="h4">
            {title}
          </SoftTypography>
          <SoftButton 
            variant="outlined"
            color="white"
            onClick={handleClose}
            sx={{
              "& svg": { fontSize: "1.25rem !important" },
              borderRadius: "50%",
              border: "0.529px solid rgba(255, 255, 255, 0.20)",
              minWidth: "max-content",
              minHeight: "max-content",
              width: "32px",
              height: "32px",
              padding: 0.25
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>

        {/** Divider Top */}
        <Divider
          sx={{
            backgroundImage: (theme) =>
              `linear-gradient(to right, rgba(52, 71, 103, 0), ${theme.palette.primary.main}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "2px",
            }
          }}
        />

        {/** Dialog Content */}
        <SoftBox>
          <Component {...props} setIsOpen={setIsOpen} />
        </SoftBox>

        {/** Divider Bottom */}
        <Divider
          sx={{
            backgroundImage: (theme) =>
              `linear-gradient(to right, rgba(52, 71, 103, 0), ${theme.palette.primary.main}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "2px",
            }
          }}
        />

        {/** Dialog Control buttons */}
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& button": {
              borderRadius: "8px",
              minWidth: "150px",
              color: "#fff",
              textTransform: "capitalize",
              fontStyle: "normal",
              fontSize: "15px",
              fontWeight: 100,
              lineHeight: "normal",
              boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12)"
            }
          }}
        >
          {(yesButtonLabel !== "") &&
            <SoftButton
              onClick={handleConfirmClick}
              sx={{
                background: (theme) => {
                  return `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`
                },
                "&:focus:not(:hover)": {
                  borderColor: "white",
                  boxShadow: "none"
                }
              }}
            >
              {yesButtonLabel}
            </SoftButton>
          }
        </SoftBox>
      </Dialog>
      </>
    )
  };

  DarkModalWrapper.defaultProps = {
    title: "Sampe Dialog",
    yesButtonLabel: "Ok",
    handleYesClick: () => {},
    handleNoClick: () => {},
    componentCallbackHandler: () => {},
    children: <div>Sample</div>,
    maxWidth: "md"
  };

  DarkModalWrapper.propTypes = {
    title: PropTypes.string,
    yesButtonLabel: PropTypes.string,
    handleYesClick: PropTypes.func,
    handleNoClick: PropTypes.func,
    componentCallbackHandler: PropTypes.func,
    children: PropTypes.node,
    maxWidth: PropTypes.oneOf([
      "xl",
      "lg",
      "md",
      "sm",
      "xs"
    ])
  };  

  return DarkModalWrapper;
};

export default WithDarkModal;
