import * as Yup from "yup";

const QuoteWidgetSchema = Yup.object().shape({
  quote: Yup.string()
    .required("Quote is required")
    .max(100, "Quote must be at most 100 characters"),
  author: Yup.string().required("Author name is required"),
});

export default QuoteWidgetSchema;
