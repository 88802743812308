/* eslint-disable react/forbid-prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

// Timeline context
import { useTimeline } from "../context";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "./styles";

function TimelineItem({
  color,
  icon,
  title,
  dateTime,
  description,
  badges,
  lastItem,
}) {
  const isDark = useTimeline();

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <SoftBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              {badge}
            </SoftBox>
          );
        })
      : null;

  return (
    <SoftBox
      position="relative"
      sx={(theme) => timelineItem(theme, { lastItem })}
    >
      <SoftBox
        bgColor={isDark ? "dark" : "white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon sx={(theme) => timelineItemIcon(theme, { color })}>{icon}</Icon>
      </SoftBox>
      <SoftBox
        ml={5.75}
        pt={description ? 0.7 : 0.5}
        lineHeight={0}
        maxWidth="30rem"
      >
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color={isDark ? "white" : "dark"}
        >
          {title}
        </SoftTypography>
        <SoftBox mt={0.5}>
          <SoftTypography
            variant="caption"
            fontWeight="medium"
            color={isDark ? "secondary" : "text"}
          >
            {dateTime}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={2} mb={1.5}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Player: {description[0]}
          </SoftTypography>

          {description[1]}

          <SoftTypography variant="button" fontWeight="regular" color="text">
            Amount: {description[2]}
          </SoftTypography>
        </SoftBox>
        {badges.length > 0 ? (
          <SoftBox
            display="flex"
            alignItems="center"
            pb={lastItem ? 0.75 : 1.5}
          >
            {renderBadges}
          </SoftBox>
        ) : null}
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "primary",
  lastItem: false,
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
