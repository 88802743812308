export const initState = {
  companyKpis: null, // Company KPI targets (All workspaces)
  groupKpis: null, // Workspace KPI targets
  usersKpis: null, // Employee KPIs of the entire Company
  myKpis: null, // The current user's employee KPIs
  adminKpis: null, // The current workspace's averaged KPI scores
  
  historicKpis: null, // Historic record of users' KPIs
  monthRef: null, // (YYYY-MM) Date reference for previous KPI records

  isLoading: true,
  error: null,
};

export const types = {
  // refresh all kpis
  REFRESH_KPIS: 'REFRESH_KPIS',
  SET_TO_LIVE_KPIS: 'SET_TO_LIVE_KPIS',
  SET_TO_HISTORIC_KPIS: 'SET_TO_HISTORIC_KPIS',
  
  // get kpi user scores:
  GET_KPI_SCORE_SUCCESS: 'GET_KPI_SCORE_SUCCESS',
  GET_KPI_SCORE_FAILED: 'GET_KPI_SCORE_FAILED',
  GET_KPI_SCORE_EMPTY: 'GET_KPI_SCORE_EMPTY',
  
  ADD_USER_KPI_REQUEST: 'ADD_USER_KPI_REQUEST',
  ADD_USER_KPI_SUCCESS: 'ADD_USER_KPI_SUCCESS',
  ADD_USER_KPI_FAILED: 'ADD_USER_KPI_FAILED',

  ADD_OLD_USER_KPI_FAILED: 'ADD_OLD_USER_KPI_FAILED',
  ADD_OLD_USER_KPI_SUCCESS: 'ADD_OLD_USER_KPI_SUCCESS',

  UPDATE_USER_KPI_REQUEST: 'UPDATE_USER_KPI_REQUEST',
  UPDATE_USER_KPI_SUCCESS: 'UPDATE_USER_KPI_SUCCESS',
  UPDATE_USER_KPI_FAILED: 'UPDATE_USER_KPI_FAILED',

  DELETE_USER_KPI_REQUEST: 'DELETE_USER_KPI_REQUEST',
  DELETE_USER_KPI_SUCCESS: 'DELETE_USER_KPI_SUCCESS',
  DELETE_USER_KPI_FAILED: 'DELETE_USER_KPI_FAILED',

  UPDATE_GROUP_KPI_REQUEST: 'UPDATE_GROUP_KPI_REQUEST',
  UPDATE_GROUP_KPI_SUCCESS: 'UPDATE_GROUP_KPI_SUCCESS',
  UPDATE_GROUP_KPI_FAILED: 'UPDATE_GROUP_KPI_FAILED',

  CREATE_CUSTOM_GROUP_KPI_REQUEST: 'CREATE_CUSTOM_GROUP_KPI_REQUEST',
  CREATE_CUSTOM_GROUP_KPI_SUCCESS: 'CREATE_CUSTOM_GROUP_KPI_SUCCESS',
  CREATE_CUSTOM_GROUP_KPI_FAILED: 'CREATE_CUSTOM_GROUP_KPI_FAILED',

  DISABLE_GROUP_KPI_REQUEST: 'DISABLE_GROUP_KPI_REQUEST',
  DISABLE_GROUP_KPI_SUCCESS: 'DISABLE_GROUP_KPI_SUCCESS',
  DISABLE_GROUP_KPI_FAILED: 'DISABLE_GROUP_KPI_FAILED',

  GET_PREVIOUS_KPIS_REQUEST: 'GET_PREVIOUS_KPIS_REQUEST',
  GET_PREVIOUS_KPIS_SUCCESS: 'GET_PREVIOUS_KPIS_SUCCESS',
  GET_PREVIOUS_KPIS_FAILED: 'GET_PREVIOUS_KPIS_FAILED',

  ADD_INTEGRATION_REQUEST: 'ADD_INTEGRATION_REQUEST',
  ADD_INTEGRATION_SUCCESS: 'ADD_INTEGRATION_SUCCESS',
  ADD_INTEGRATION_FAILED: 'ADD_INTEGRATION_FAILED',
};
