import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import * as serviceWorker from "./serviceWorker";
import "./assets/fonts.css";

// Sentry.init({
//   dsn: "https://011f1f9ac5f049168ffda37a26c08327@o1386961.ingest.sentry.io/6707743",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: process.env.NODE_ENV,
//   enabled: process.env.NODE_ENV !== "development",
// });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

serviceWorker.register();
