import React, { useState, useEffect, useMemo } from "react";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import LinearProgress from "@mui/material/LinearProgress";
import Icon from "../../components/Icons/Icon";

import { formatNumber } from "../../helpers/formatter";

const KpiAverageBox = ({ kpi }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (kpi.ratio) {
      setStatus(Math.round(kpi.ratio * 100));
    } else {
      setStatus(0);
    }
  }, [kpi]);

  return (
    <SoftBox className="grow h-full p-0">
      <SoftBox className="flex flex-row gap-1 items-center">
        <SoftBox borderRadius="md" shadow="lg" width="fit-content">
          <Icon
            iconKey={kpi.title}
            transparent={true}
            iconColor="#6C757D"
            size={2}
          />
        </SoftBox>
        <SoftTypography
          variant="body2"
          sx={{
            color: "#67748E",
            fontSize: { xs: "10px", lg: "11px", xxl: "14px" },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: { xs: "40px", lg: "80px", xxl: "120px" },
          }}
        >
          {kpi.title}
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="h5"
        sx={{
          mt: 1,
          color: "#252F40",
          fontWeight: "600",
        }}
      >
        {formatNumber(kpi.score ?? 0, kpi.kind)}
      </SoftTypography>
      {status !== null ? (
        <SoftBox
          width="100%"
          borderRadius="md"
          height="1vh"
          className="flex justify-center items-center gap-0 overflow-hidden"
        >
          {/** Negative area; below yeti target */}
          <SoftBox
            height="0.75vh"
            width="49%"
            className="scale-x-[-1]"
            sx={{
              bgcolor: "#e0e0e0",
              borderRadius: "0 1vh 1vh 0",
              overflow: "hidden",
            }}
          >
            <SoftBox
              height="100%"
              width={`${status < 0 ? Math.abs(status) : 0}%`}
              sx={{ bgcolor: "negative.main", borderRadius: "0 1vh 1vh 0" }}
            />
          </SoftBox>
          {/** Centerline divider representing the yeti target */}
          <SoftBox
            borderRadius="md"
            height="100%"
            sx={{ borderLeft: "0.25vh solid #212121" }}
          />
          {/** Positive area; above yeti target */}
          <SoftBox
            height="0.75vh"
            width="49%"
            sx={{
              bgcolor: "#e0e0e0",
              borderRadius: "0 1vh 1vh 0",
              overflow: "hidden",
            }}
          >
            <SoftBox
              height="100%"
              width={`${status > 0 ? Math.abs(status) : 0}%`}
              sx={{ bgcolor: "positive.main", borderRadius: "0 1vh 1vh 0" }}
            />
          </SoftBox>
        </SoftBox>
      ) : null}
    </SoftBox>
  );
};

export default KpiAverageBox;
