/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from "react";
import FreemiumForm from "../../components/Freemium/Onboarding/FreemiumForm";
import AvatarSelector from "../../components/Freemium/Onboarding/AvatarSelector";
import { GET_CHALLENGE, VERIFY_INVITE_TOKEN } from "../../api/challenge";
import { GET_CHALLENGE_REFERRAL } from "../../api/affiliate";
import SoftBox from "../../components/SoftBox";
import { Grid, Stack } from "@mui/material";
import toast from "react-hot-toast";

import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import SoftTypography from "../../components/SoftTypography";
import challengeArenaVid from "../../img/arena/challengeArenaVid.mp4";
import GMBLogo from "../../img/GameMyBiz.png";
// Imported Styles
import "./freemiumStyles.css";

// Set the spaces here as contants since it's re-used in several components multiple times
const SPACEX_MINI = "2rem";
const SPACEX_LARGE = "7.5rem";

const FreemiumPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [challengeInfo, setChallengeInfo] = useState(null);
  const [email, setEmail] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [companyColor, setCompanyColor] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const code = searchParams.get("ref");
  const { challengeUrl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (challengeUrl) {
      GET_CHALLENGE(challengeUrl)
        .then(({ data }) => {
          setChallengeInfo(data);
          setIsLoading(false);
        })
        .catch(() => {
          navigate("/challenge-not-found");
        });
    } else {
      setIsLoading(false);
    }
  }, [challengeUrl, navigate]);

  useEffect(() => {
    if (token) {
      VERIFY_INVITE_TOKEN(token)
        .then(({ data }) => {
          setChallengeInfo(data.challenge);
          setEmail(data.email);
          setIsLoading(false);
        })
        .catch(() => {
          navigate("/challenge-not-found");
        });
    }
  }, [token, navigate]);

  useEffect(() => {
    if (code) {
      GET_CHALLENGE_REFERRAL(code)
        .then(({ data: { userId } }) => {
          setReferrer(userId);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data ??
              "Something went wrong when reviewing your invite code",
          );
          setReferrer(null);
        });
    }
  }, [code]);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <SoftBox sx={{ bgcolor: "#F5F6F7" }}>
          {/* GMB Logo */}
          <SoftBox
            sx={{
              width: "100vw",
              marginX: { mini: SPACEX_MINI, md: SPACEX_LARGE },
              paddingY: { mini: "2.5rem", md: "3.75rem" },
              height: { mini: "2.5rem", md: "4rem" },
            }}
          >
            <img
              src={GMBLogo}
              alt="Game My Biz"
              style={{ height: "inherit", width: "auto" }}
            />
          </SoftBox>

          {/* Header and Video Highlight Animation */}
          <SoftBox
            sx={{
              marginX: { mini: SPACEX_MINI, md: SPACEX_LARGE },
              marginTop: { mini: "4rem", md: "0" },
            }}
          >
            <Stack
              direction={{ mini: "column", md: "row" }}
              columnGap="5%"
              rowGap="4rem"
            >
              <SoftBox
                sx={{
                  width: { xs: "95%", md: "55%" },
                  height: { xs: "auto", md: "356px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SoftBox>
                  <SoftTypography
                    sx={{
                      fontFamily: "'Inter', sans-serif;",
                      fontSize: { mini: "2.25rem", md: "3rem" },
                      color: "#051923",
                      fontWeight: 700,
                    }}
                  >
                    Set up your 5-Star Google Reviews Profile
                  </SoftTypography>
                  <SoftTypography
                    variant="body"
                    sx={{
                      marginTop: "1.5rem",
                      fontFamily: "'Inter', sans-serif;",
                      color: "#6C757D",
                    }}
                  >
                    Get Ready to Shine!
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  width: { mini: "100%", md: "40%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  autoPlay
                  loop
                  muted
                  style={{ height: "auto", width: "100%" }}
                >
                  <source src={challengeArenaVid} type="video/mp4" />
                </video>
              </SoftBox>
            </Stack>
          </SoftBox>

          {/* Main Instructions */}
          <SoftBox
            shadow="sm"
            borderRadius="none"
            bgColor="white"
            sx={{
              paddingX: { mini: SPACEX_MINI, md: SPACEX_LARGE },
              paddingY: { mini: "4rem", md: "3.5rem" },
              marginTop: "2rem",
            }}
          >
            <SoftTypography
              sx={{
                fontFamily: "'Inter', sans-serif;",
                fontSize: "1.5rem",
                fontWeight: 700,
                color: "#000",
              }}
            >
              Complete these few steps below to join the challenge
            </SoftTypography>
            <SoftTypography
              variant="body"
              sx={{
                marginTop: "1rem",
                fontFamily: "'Inter', sans-serif;",
                fontWeight: 500,
                color: "#343A40",
              }}
            >
              Arm your self with what you need to succeed
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              marginX: { mini: SPACEX_MINI, md: SPACEX_LARGE },
              marginTop: { mini: "2rem", md: "2.5rem" },
            }}
          >
            <FreemiumForm
              challengeInfo={challengeInfo}
              preRegisteredEmail={email}
              avatar={avatar}
              referrer={referrer}
              companyColor={companyColor}
              setAvatar={setAvatar}
              setCompanyColor={setCompanyColor}
            />
          </SoftBox>
        </SoftBox>
      )}
    </div>
  );
};
export default FreemiumPage;
