import { types } from "./types";

export const getCompanyClaims = () => ({
  type: types.GET_COMPANY_CLAIMS_REQUEST,
});

export const claimItem = (itemId, claimId, claimType) => ({
  type: types.PUT_USER_CLAIMS_REQUEST,
  itemId,
  claimId,
  claimType,
});
