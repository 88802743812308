import { formatNumber } from "../../../helpers/formatter";

function configs(labels, datasets, numberType = null) {
   return {
      data: {
         labels,
         datasets: [...datasets],
      },
      options: {
         responsive: true,
         maintainAspectRatio: false,
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               callbacks: {
                  label: (yPoint) => " " + formatNumber(Math.abs(yPoint.raw), numberType),
               }
            }
         },
         interaction: {
            intersect: false,
            mode: "index",
         },
         scales: {
            y: {
               grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
               },
               ticks: {
                  display: false,
               },
            },
            x: {
               grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
               },
               ticks: {
                  display: false,
               },
            },
         },
      },
   };
}

export default configs;
