import { request } from "../helpers/axios.new";

export const GET_INDUSTRIES = async () => {
  try {
    const response = await request({
      url: '/industry/get-industry',
      method: "GET",
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching industries:', error);
    throw error;
  }
};
