import { request } from "../helpers/axios.new";

export const GET_CLAIM_ITEMS = (workspaceId) => {
  return request({
    url: `/claims${workspaceId ? `?workspaceId=${workspaceId}` : ""}`,
  });
};

export const PUT_CLAIM = (itemId, claimId, claimType) => {
  return request({
    method: "PUT",
    url: `/claims/${itemId}`,
    data: { claimId, type: claimType },
  });
};
