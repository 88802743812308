/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Backdrop, Fade, CircularProgress, Box } from '@mui/material';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { gsap } from "gsap";
import person from "../../img/person.svg";
import trophy from "../../img/TrophyCup.svg";
import menuTrophy from "../../img/menuTrophy.svg";
import YETI_PROFILE from "../../constants/yetiProfile";
import GameboardName from "./GameboardName";
import { useTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import SoftProfile from "../ProfilePictures/SoftProfile";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftInput from "../SoftInput";
import { CountUp } from "countup.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import SoftTypography from "../SoftTypography";
import { borderRadius, display, height, margin, textAlign, width } from "@mui/system";
import UploadIcon from "@mui/icons-material/Upload";
import ImageCropper from "../../pages/Feed/ImageCropper";
import Avatar from "@mui/material/Avatar";
import { PUT_WORKSPACE_IMAGE, SUBMIT_AWARD_PRICE, GET_AWARD } from "../../api/group";
import { GET_IMAGE } from "../../helpers/images";
import { useForm } from "react-hook-form";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
gsap.core.globals("ScrollTrigger", ScrollTrigger);
import { UseIsAdmin } from "../../hooks/auth";
import { UseSelectedWorkspace } from "../../hooks/company";
import { GET_IMAGE_URL } from "../../helpers/images";
import { position } from "stylis";
import { ReactComponent as SvgGameboardTitleBg } from "../../img/GameboardTitleBgUpdated.svg";
import zIndex from "@mui/material/styles/zIndex";

const movePerson = (name, pts, maxPoint) => {
  const tempElem = {
    pts: 0,
  };

  // ensure that stopping point does not go beyond allowable limit to avoid messing up animation
  const endPoint = Math.min(pts / maxPoint, 0.99);


  if (endPoint === 1) {
    return gsap
      .timeline()
      .to(`#${name}`, {
        duration: 4,
        ease: "sine.out",
        motionPath: {
          path: `#${name}-line`,
          align: `#${name}-line`,
          alignOrigin: [0.5, 1],
          start: 0,
          end: endPoint,
        },
      })
      .to(
        `#end-g-${name}`,
        {
          duration: 4,
          ease: "sine.out",
          motionPath: {
            path: `#${name}-line`,
            align: `#${name}-line`,
            alignOrigin: [0.5, 0.5],
            start: 0,
            end: endPoint,
          },
        },
        "<",
      )
      .to(
        `#${name}-circle`,
        {
          duration: 4,
          ease: "sine.out",
          strokeDashoffset: 337 - 337 * endPoint,
        },
        "<",
      )
      .to(
        tempElem,
        {
          duration: 4,
          pts,
        },
        "<",
      )
      .from(`#end-circle-${name}`, {
        duration: 1,
        transformOrigin: "50% 50%",
        scale: 0,
        ease: "sine.inOut",
      })
      .from(
        `#end-line-${name}`,
        {
          duration: 1,
          transformOrigin: "0% 50%",
          scaleX: 0,
          ease: "sine.inOut",
        },
        "<",
      )
      .to(`#${name}`, { duration: 1, y: "+=-5", ease: "sine.inOut" }, "<")
      .to(`#${name}`, { duration: 1, y: "+=5", ease: "sine.inOut" }, ">")
      .fromTo(
        `#trophy`,
        { y: 0 },
        { duration: 1, y: "+=5", ease: "sine.inOut" },
        "<",
      );
  } else {
    return gsap
      .timeline()
      .to(`#${name}`, {
        duration: 4,
        ease: "sine.out",
        motionPath: {
          path: `#${name}-line`,
          align: `#${name}-line`,
          alignOrigin: [0.5, 1],
          start: 0,
          end: endPoint + 0.01,
        },
      })
      .to(
        `#end-g-${name}`,
        {
          duration: 4,
          ease: "sine.out",
          motionPath: {
            path: `#${name}-line`,
            align: `#${name}-line`,
            alignOrigin: [0.5, 0.5],
            start: 0,
            end: endPoint + 0.01,
          },
        },
        "<",
      )
      .to(
        `#${name}-circle`,
        {
          duration: 4,
          ease: "sine.out",
          strokeDashoffset: 337 - 337 * endPoint,
        },
        "<",
      )
      .to(
        tempElem,
        {
          duration: 4,
          pts,
        },
        "<",
      )
      .from(`#end-circle-${name}`, {
        duration: 1,
        transformOrigin: "50% 50%",
        scale: 0,
        ease: "sine.inOut",
      })
      .from(
        `#end-line-${name}`,
        {
          duration: 1,
          transformOrigin: "0% 50%",
          scaleX: 0,
          ease: "sine.inOut",
        },
        "<",
      );
  }
};

// animation

gsap.set("#person1-circle, #person2-circle", {
  strokeDasharray: 337,
  strokeDashoffset: 337,
});
gsap.fromTo(
  "#trophy",
  { transformOrigin: "0% 100%", scaleX: 1, skewY: 1 },
  {
    duration: 1,
    transformOrigin: "0% 100%",
    yoyo: true,
    ease: "sine.inOut",
  },
);

const startTL = gsap
  .timeline({ paused: true })
  .from("#mount1", { duration: 1, y: 500, opacity: 0 })
  .from("#mount2", { duration: 1, y: 500, opacity: 0 }, "<+0.5")
  .from("#mount3", { duration: 1, y: 500, opacity: 0 }, "<+0.5")
  .from("#menu", { duration: 1, opacity: 0 }, "<")
  .from("#avatarSvg", { duration: 1, opacity: 0 }, "<+0.5");

gsap.set("#gameMyBizSVG", { opacity: 1 });

// Component
const KingOfTheMountain = ({
  maxPoint,
  yetiScore,
  playerScore,
  playerName,
  playerLogo,
  darkMode,
  noScroll,
}) => {
  const boardRef = useRef();
  const [animation, setAnimation] = useState(null);
  const theme = useTheme();
  const playerPtsRef = useRef();
  const yetiPtsRef = useRef();
  const [imageURL, setImageURL] = useState(null);
  const fileRef = useRef();
  const formRef = useRef(null);

  useEffect(() => {
    // Cleanup
    return () => {
      URL.revokeObjectURL(imageURL);
    }
  }, [imageURL]);

  const setSelectedFile = async (e) => {
    const pictureBlob = URL.createObjectURL(e.target.files[0]);

    setImageURL(pictureBlob);
    fileSelectedCallback?.(e.target.files[0]);
  }

  const clearFile = (e) => {
    e.preventDefault();

    URL.revokeObjectURL(imageURL);
    setImageURL(null);
    fileRef.current.value = null;

    fileSelectedCallback?.(null, true);
  }

  useEffect(() => {
    if (maxPoint) {
      const rightNums = document.querySelectorAll("#right-nums text");
      const canRightNums = rightNums ? rightNums.href : "";
      if (rightNums.length > 0 && canRightNums) {
        for (let i = 0; i < 9; i++) {
          if (i === 0) {
            rightNums[i].innerHTML = "+ " + (maxPoint - (maxPoint / 8) * i);
          } else {
            rightNums[i].innerHTML = maxPoint - (maxPoint / 8) * i;
          }
        }
      }

      if (animation) {
        animation.kill();
      }

      // Setup count up sequence for Player and Yeti Points
      const playerCountup = new CountUp(
        playerPtsRef?.current,
        Number.isNaN(playerScore) || !Number.isFinite(playerScore)
          ? 0
          : playerScore,
        { duration: 2.5, useEasing: true, separator: "," },
      );
      const yetiCountup = new CountUp(yetiPtsRef?.current, yetiScore, {
        duration: 2.5,
        useEasing: true,
        separator: ",",
      });
      playerCountup.start();
      yetiCountup.start();

      // Set scroll trigger only if board is not shown in full screen
      const boardAnim = gsap
        .timeline({
          ...(noScroll
            ? {}
            : {
                scrollTrigger: {
                  trigger: boardRef.current,
                  start: "top center",
                  once: true,
                },
              }),
        })
        .to(startTL, { duration: 3, progress: 1, ease: "none" })
        .add(movePerson("person1", playerScore, maxPoint).restart())
        .add(movePerson("person2", yetiScore, maxPoint).restart(), "<")
        .from("#persons", { duration: 1, opacity: 0 }, "<");

      setAnimation(boardAnim);

      return () => {
        if (boardAnim) {
          boardAnim.kill();
        }
      };
    }
  }, [maxPoint, yetiScore, playerScore]);

  const {
    primary: { main: primaryColor, light: brighterPrimaryColor },
    secondary: { main: darkerPrimaryColor },
  } = theme.palette;

  const isAdmin = UseIsAdmin();

  const smBp = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const lgBp = useMediaQuery((theme) => theme.breakpoints.up("xxl"));

  const [openModal, setOpenModal] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);

  const handlePriceClick = () => {
    setOpenPriceModal(true);
  };

  const handleClosePriceModal = () => {
    setOpenPriceModal(false);
  };

  const handleImageClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    borderWidth: "1px",
    borderColor: "#FFF",
    backdropFilter: 'blur(25px)'
  };

  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
      name: "",
    },
  });

  const imageInputRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = () => {
    imageInputRef.current.value = null;
    imageInputRef.current.click();
  };

  const handleInputChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const imgSrc = e.target.files[0];
    if (imgSrc) {
      setImageSrc(URL.createObjectURL(imgSrc));
      setOpenDialog(true);
    }
  };

  const onSaveCroppedImage = async (croppedImageFile) => {
    if (!croppedImageFile) return;
    const formData = new FormData();
    setIsUploading(true);
    formData.append("image", croppedImageFile);
    PUT_WORKSPACE_IMAGE(formData)
      .then(({ data }) => {
        setImageSrc(data.key);
        setValue("image", data.key);
        setIsUploading(false);
      })
      .catch((err) => {
        console.log("Job image upload error", err.message);
        setIsUploading(false);
      });

    setOpenDialog(false);
    imageInputRef.current.value = null;
  };

  const { _id: groupId } = UseSelectedWorkspace();
  const [workspaceData, setWorkspaceData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await GET_AWARD(groupId);
      setWorkspaceData(response.data);  
    } catch (error) {
      console.error("Error fetching the award data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [groupId]);
  
  const submitPrice = async () => {
    const priceValue = getValues("price");
    if (formRef.current) {
      const body = {
        groupId: groupId,
        price: priceValue,
        priceImage: imageSrc,
      };
      await SUBMIT_AWARD_PRICE(body);
      fetchData();
    }
    setOpenModal(false);
    imageInputRef.current.value = null;
    reset();
  };

  useEffect(() => {
    return () => {
      setIsUploading(false);
    };
  }, []);

  return (
    <>
      {maxPoint > 0 ? (
        <SoftBox className="w-full relative" sx={{ zIndex: 1, padding: { mini: "24px 24px 0 24px", xxs: "unset" } }}>
          <SoftBox
            shadow="md"
            sx={{
              width: "100%",
              height: "100%",
              minHeight: "30vh",
              position: "relative",
              overflow: "hidden",
              borderRadius: "12px",
            }}
            ref={boardRef}
            variant="gradient"
            bgColor={darkMode ? "dark" : "transparent"}
          >
            {/** Header */}
            <SoftBox
              sx={{
                paddingLeft: { mini: "16px", sm: "2rem" },
                paddingRight: { mini: "16px", sm: "2rem" },
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}>
              <Box
                sx={{
                  color: "primary.main",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  width: "100%",
                  height: "auto",
                  transform: "translate(-50%, 0)",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "auto", // Change this relative witdth to make gameboard title wider
                    height: "auto",
                    marginX: "auto",
                    position: "relative",
                  }}
                >
                  <SvgGameboardTitleBg />
                  <Box
                    sx={{
                      color: "primary.contrastText",
                      position: "absolute",
                      top: "25%",
                      left: "20%",
                      width: "70%",
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 160 18"
                      overflow="visible"
                    >
                      <text
                        x="80"
                        y="9"
                        fontFamily="DM Sans"
                        fontSize="14"
                        fontWeight="500"
                        fill="white"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        King of the Mountain
                      </text>
                    </svg>
                  </Box>
                </Box>
              </Box>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
                style={{ 
                  marginTop: '1rem',
                  zIndex: "1000",
                  ...(isAdmin && { cursor: 'pointer' }) 
                }}
                onClick={isAdmin ? handleImageClick : undefined}>
                <path d="M10.125 16.125C9.78333 16.125 9.5 15.8417 9.5 15.5V13.75C9.5 13.4083 9.78333 13.125 10.125 13.125C10.4667 13.125 10.75 13.4083 10.75 13.75V15.5C10.75 15.8417 10.4667 16.125 10.125 16.125Z" fill="#292D32"/>
                <path d="M14.9164 18.958H5.33307V17.4997C5.33307 16.233 6.35807 15.208 7.62474 15.208H12.6247C13.8914 15.208 14.9164 16.233 14.9164 17.4997V18.958ZM6.58307 17.708H13.6664V17.4997C13.6664 16.9247 13.1997 16.458 12.6247 16.458H7.62474C7.04974 16.458 6.58307 16.9247 6.58307 17.4997V17.708V17.708Z" fill="#292D32"/>
                <path d="M15.125 18.958H5.125C4.78333 18.958 4.5 18.6747 4.5 18.333C4.5 17.9914 4.78333 17.708 5.125 17.708H15.125C15.4667 17.708 15.75 17.9914 15.75 18.333C15.75 18.6747 15.4667 18.958 15.125 18.958Z" fill="#292D32"/>
                <path d="M15.3586 10.3668C15.1836 10.3668 15.0086 10.2918 14.8836 10.1502C14.7253 9.96683 14.6836 9.70849 14.7836 9.49183C15.0669 8.84183 15.2086 8.15016 15.2086 7.42516V4.92516C15.2086 4.63349 15.1586 4.35016 15.0586 4.05016C15.0503 4.02516 15.0419 3.99183 15.0336 3.95849C15.0086 3.83349 15.0003 3.70849 15.0003 3.59183C15.0003 3.25016 15.2836 2.96683 15.6253 2.96683H16.1253C17.6169 2.96683 18.8336 4.21683 18.8336 5.75849C18.8336 7.03349 18.3086 8.29183 17.4003 9.20016C17.3836 9.21683 17.3336 9.25849 17.3253 9.26683C16.8336 9.67516 16.2753 10.1335 15.5253 10.3418C15.4669 10.3585 15.4169 10.3668 15.3586 10.3668ZM16.4003 4.24183C16.4419 4.46683 16.4586 4.70016 16.4586 4.92516V7.42516C16.4586 7.76683 16.4336 8.09183 16.3836 8.42516C16.4336 8.38349 16.4753 8.35016 16.5253 8.30849C17.1919 7.64183 17.5836 6.70849 17.5836 5.75849C17.5836 5.00849 17.0753 4.37516 16.4003 4.24183Z" fill="#292D32"/>
                <path d="M4.65033 10.3333C4.58366 10.3333 4.52533 10.325 4.45866 10.3C3.77533 10.0833 3.13366 9.6833 2.60033 9.14997C1.64199 8.09164 1.16699 6.9333 1.16699 5.7083C1.16699 4.19164 2.35866 2.99997 3.87533 2.99997H4.41699C4.62533 2.99997 4.82533 3.1083 4.94199 3.2833C5.05866 3.4583 5.07533 3.6833 4.99199 3.87497C4.85866 4.17497 4.79199 4.51664 4.79199 4.87497V7.37497C4.79199 8.09164 4.93366 8.79164 5.22533 9.4583C5.32533 9.6833 5.27533 9.94164 5.11699 10.125C4.99199 10.2583 4.82533 10.3333 4.65033 10.3333ZM3.58366 4.27497C2.90866 4.4083 2.41699 4.99164 2.41699 5.7083C2.41699 6.61664 2.78366 7.49164 3.50866 8.29164C3.54199 8.3333 3.58366 8.36664 3.62533 8.39997C3.56699 8.0583 3.54199 7.71664 3.54199 7.37497V4.87497C3.54199 4.67497 3.55866 4.47497 3.58366 4.27497Z" fill="#292D32"/>
                <path d="M10.0003 13.9587C6.44199 13.9587 3.54199 11.0587 3.54199 7.50033V5.00033C3.54199 2.81699 5.31699 1.04199 7.50033 1.04199H12.5003C14.6837 1.04199 16.4587 2.81699 16.4587 5.00033V7.50033C16.4587 11.0587 13.5587 13.9587 10.0003 13.9587ZM7.50033 2.29199C6.00866 2.29199 4.79199 3.50866 4.79199 5.00033V7.50033C4.79199 10.3753 7.12533 12.7087 10.0003 12.7087C12.8753 12.7087 15.2087 10.3753 15.2087 7.50033V5.00033C15.2087 3.50866 13.992 2.29199 12.5003 2.29199H7.50033Z" fill="#292D32"/>
              </svg>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <SoftBox sx={style}>
                  <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                        King of Mountain Award Prize
                      </SoftTypography>
                      <SoftButton
                        variant="outlined"
                        color="white"
                        onClick={handleCloseModal}
                        sx={{
                          "& svg": { fontSize: "1.25rem !important" },
                          borderRadius: "50%",
                          minWidth: "max-content",
                          minHeight: "max-content",
                          padding: "0.25rem",
                        }}
                      >
                        <CloseIcon />
                      </SoftButton>
                    </SoftBox>
                    <SoftTypography sx={{ mt: 4, mb: 4, textFillColor: "white", textAlign: 'center', fontSize: '14px' }}>
                      Set the reward for the next King of the Mountain winner. Customize your prize and motivate competitors to climb to the top!
                    </SoftTypography>
                    <form ref={formRef}>
                    <SoftInput
                      {...register("price")}
                      name="price"
                      type="text"
                      placeholder="Enter amount to be rewarded"
                      sx={{
                        border: "1px solid rgba(233, 236, 239, 0.10)",
                        backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                        color: "#ffffffb3 !important",
                        "& .MuiInputBase-input::placeholder": {
                          color: "white !important",
                          opacity: "0.7",
                        },
                        "&.Mui-focused": {
                          borderColor: primaryColor,
                          boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                        },
                      }}
                      size="large"
                    />
                    <SoftBox sx={{border: '1px dashed #98A2B3', padding: 1, mt: 4, borderRadius: '6px', textAlign: 'center'}}>
                      <SoftTypography sx={{ mt: 2, mb: 3, textFillColor: "white", textAlign: 'center', fontSize: '14px' }}>
                        Is the king of the mountain prize a material gift? Kindly upload an image
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          height: "150px",
                          maxWidth: "50%",
                          border: "1.9px dashed #98A2B3",
                          borderRadius: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                          margin: "0 auto"
                        }}
                      >
                        {!isUploading && !getValues("image") ? (
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <SoftTypography
                              sx={{
                                color: "#6C757D",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Upload Price
                            </SoftTypography>
                          </SoftBox>
                        ) : (
                          <SoftBox sx={{ width: "100%", height: "100%" }}>
                            <Avatar
                              sx={{
                                width: "100%",
                                height: "100%",
                              }}
                              variant="rounded"
                              src={
                                !isUploading && getValues("image")
                                  ? GET_IMAGE(getValues("image"))
                                  : ""
                              }
                            >
                              {isUploading && <CircularProgress />}
                            </Avatar>
                          </SoftBox>
                        )}
                        <input
                          ref={imageInputRef}
                          onInput={handleInputChange}
                          accept="image/*"
                          type="file"
                          hidden
                        />
                        <ImageCropper
                          imgSrc={imageSrc}
                          open={openDialog}
                          setOpen={setOpenDialog}
                          onSaveCroppedImage={onSaveCroppedImage}
                        />
                      </SoftBox>
                      <SoftBox>
                        <SoftTypography
                          sx={{
                            color: "#98A2B3",
                            fontSize: "13px",
                            fontWeight: 400,
                            marginBottom: "10px",
                          }}
                        >
                          JPEG, PNG or GIF. Max file size 10GB
                        </SoftTypography>
                        <SoftButton
                          color="outlined"
                          variant="contained"
                          startIcon={<UploadIcon sx={{ color: "#CED4DA" }} />}
                          onClick={handleUpload}
                          sx={{
                            textTransform: "none",
                            borderRadius: "4px",
                            border: "1px solid rgba(233, 236, 239, 0.20)",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                            background: "rgba(255, 255, 255, 0.10)",
                            color: "#CED4DA",
                            "&:hover": {
                              background: "rgba(255, 255, 255, 0.10)",
                            },
                          }}
                        >
                          Upload
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox sx={{textAlign: 'center'}}>
                      <SoftButton sx={{width: '40%', marginTop: '1.5rem'}} onClick={submitPrice}>
                        Submit
                      </SoftButton>
                    </SoftBox>
                    </form>
                  </SoftBox>
                </Fade>
              </Modal>
            </SoftBox>
            <hr className="my-3 divide-slate-300 mb-5" />
            {/** Mountain svg */}
            <div className="absolute w-full h-full">
              <svg
                version="1.1"
                id="avatarSvg"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 965 615"
              >
                <rect
                  x="157.28"
                  y="186.99"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="none"
                  width="142.72"
                  height="37.01"
                />
              </svg>
            </div>
            <div className="absolute w-full h-full">
              <svg
                version="1.1"
                id="avatarSvg"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 965 615"
              >
                <rect
                  x="720.28"
                  y="186.99"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="none"
                  width="142.72"
                  height="37.01"
                />
              </svg>
            </div>
            <Modal
              open={openPriceModal}
              onClose={handleClosePriceModal}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openPriceModal}>
                <SoftBox sx={style}>
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                      King of Mountain Award Prize
                    </SoftTypography>
                    <SoftButton
                      variant="outlined"
                      color="white"
                      onClick={handleClosePriceModal}
                      sx={{
                        "& svg": { fontSize: "1.25rem !important" },
                        borderRadius: "50%",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: "0.25rem",
                      }}
                    >
                      <CloseIcon />
                    </SoftButton>
                  </SoftBox>
                  {workspaceData?.price && (
                    <>
                      <SoftTypography sx={{ mt: 4, mb: 3, textFillColor: "white", textAlign: 'center', fontSize: '32px' }}>
                        {workspaceData?.price}
                      </SoftTypography>
                      <img 
                        style={{ 
                          marginBottom: '4rem', 
                          margin: '0 auto', 
                          maxWidth: '300px', 
                          width: '100%',
                          height: 'auto'
                        }}
                        src={GET_IMAGE_URL(workspaceData?.priceImage)}
                        alt="Workspace"
                      />
                    </>
                  )}
                </SoftBox>
              </Fade>
            </Modal>
            <svg
              height="100%"
              width="100%"
              version="1.1"
              id="gameMyBizSVG"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 965 615"
              enableBackground="new 0 0 965 615"
              space="preserve"
              preserveAspectRatio="none"
              style={{position: 'relative', zIndex: '1000'}}
            >
              <g>
                <g id="mount2">
                  <polygon
                    fill={darkMode ? "#1B1B1B" : "#ECF0EF"}
                    points="483,562 418,376 381.68,344.02 360,316 345,316 286,241 239,321 199,395 179.01,505.28 207,554
                    211.98,614.6 502.5,614.6 		"
                  />
                  <polygon
                    fill={darkMode ? "#4F4E4E" : "#D9D9D9"}
                    points="0,615 0,523 69,438 107,412 126,368 207,321 248,265 286,241 278,295 235,374 248,396 223,438
                    262,547 262.5,614.5 		"
                  />
                  0
                </g>
                <g id="mount1">
                  <polygon
                    fill={darkMode ? "#1B1B1B" : "#EEF2F4"}
                    points="770.65,615 965,615 965,476 941,452 901,443 892,404 852,374 822,314 785,282 755,342 755,436
                    758,540 		"
                  />
                  <polygon
                    fill={darkMode ? "#4F4E4E" : "#DEE2E1"}
                    points="896.17,615 907,602 838,590 860,548 812,516 819,457 788,442 822,404 792,366 779,309 785,282
                    764,314 721,325 694,363 687,444 635,533 613,540 575.5,615 		"
                  />
                </g>
                <g id="mount3">
                  <polygon
                    fill={darkMode ? darkerPrimaryColor : primaryColor}
                    points="42.5,614.5 111.5,523.5 204.5,494.5 228.5,440.5 346.5,392.5 375.5,348.5 415.5,319.5
                    482.5,133.5 487.5,133.5 520.5,185.5 564.5,222.5 582.5,259.5 703.5,346.5 712.5,441.5 758.5,467.5 833.5,572.5 885.5,614.5 		"
                  />
                  <polygon
                    fill={darkMode ? primaryColor : darkerPrimaryColor}
                    points="457.5,552.5 441.5,513.5 482.5,475.5 451.5,463.5 419.5,431.5 451.5,366.5 423.5,344.5
                    480.5,275.5 467.5,250.5 481.39,136.57 415.5,319.5 375.5,348.5 346.5,392.5 228.5,440.5 204.5,494.5 111.5,523.5 42.5,614.5
                    413.45,614.5 		"
                  />
                  <g>
                    <g>
                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="413"
                        y1="614"
                        x2="415.32"
                        y2="610.74"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="7.5585,7.5585"
                        x1="419.69"
                        y1="604.57"
                        x2="452.5"
                        y2="558.34"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="454.68,555.26
                        457,552 455.48,548.3 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="6.8309,6.8309"
                        x1="452.89"
                        y1="541.98"
                        x2="443.81"
                        y2="519.86"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="442.52,516.7
                        441,513 443.93,510.28 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="6.8431,6.8431"
                        x1="448.95"
                        y1="505.63"
                        x2="476.56"
                        y2="480.04"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="479.07,477.72
                        482,475 478.27,473.56 				"
                      />

                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="7.8329,7.8329"
                        points="
                        470.96,470.73 451,463 424.6,436.6 				"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="421.83,433.83
                        419,431 420.77,427.41 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="7.1611,7.1611"
                        x1="423.93"
                        y1="420.99"
                        x2="447.65"
                        y2="372.8"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="449.23,369.59
                        451,366 447.85,363.53 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="9.203,9.203"
                        x1="440.62"
                        y1="357.84"
                        x2="429.76"
                        y2="349.31"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="426.15,346.47
                        423,344 425.6,340.96 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="7.526,7.526"
                        x1="430.49"
                        y1="335.24"
                        x2="476.95"
                        y2="280.9"
                      />
                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="479.4,278.04
                        482,275 479.94,271.57 				"
                      />

                      <polyline
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="8.1831,8.1831"
                        points="
                        475.73,264.55 467,250 480.97,141.03 				"
                      />

                      <line
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="481.49"
                        y1="136.97"
                        x2="482"
                        y2="133"
                      />
                    </g>
                  </g>
                  <polygon
                    fill={brighterPrimaryColor}
                    points="502.5,522.5 519.5,553.5 514.5,596.5 519.5,615 584.5,615 550.5,596.5 540.5,549.5 		"
                  />
                  <g>
                  <image
                      x="448"
                      y="35"
                      width="80"
                      href={trophy}
                      style={{cursor: 'pointer'}}
                      onClick={handlePriceClick}
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <rect id="SVGID_1_" x="0" y="116" width="930" height="488" />
                </defs>
                <clipPath id="SVGID_2_">
                  <use href="#SVGID_1_" overflow="visible" />
                </clipPath>
                {/** Yeti circle and line */}
                <g clipPath="url(#SVGID_2_)">
                  <g id="end-g-person2">
                    <line
                      id="end-line-person2"
                      fill="none"
                      stroke="#EC2786"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      x1="474"
                      y1="541"
                      x2="1583"
                      y2="541"
                    />
                    <circle fill="none" cx="474" cy="541" r="1109" />
                    <circle
                      id="end-circle-person2"
                      cx="489"
                      cy="541.5"
                      r="3"
                      fill="#FFFFFF"
                    />
                    <circle
                      id="end-circle-person2"
                      cx="474.5"
                      cy="541.5"
                      r="9"
                      fill="#EC2786"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                    />
                  </g>
                  {/** Player circle and line */}
                  <g id="end-g-person1">
                    <line
                      id="end-line-person1"
                      fill="none"
                      stroke={brighterPrimaryColor}
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      x1="423"
                      y1="523"
                      x2="1532"
                      y2="523"
                    />
                    <circle fill="none" cx="423" cy="523" r="1109" />
                    <circle
                      id="end-circle-person1"
                      cx="438"
                      cy="523.5"
                      r="3"
                      fill="#FFFFFF"
                    />
                    <circle
                      id="end-circle-person1"
                      cx="423.5"
                      cy="523.5"
                      r="9"
                      fill={primaryColor}
                      stroke="#FFFFFF"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </g>
              <g id="persons">
                <g>
                  <path
                    id="person2-line"
                    fill="none"
                    d="M448.16,617.74l35.02-57.83c2.71-4.48,2.94-10.04,0.61-14.74l-10.31-20.71
                    c-2.84-5.71-1.84-12.58,2.5-17.25l14.2-15.23c7.71-8.27,4.1-21.75-6.71-25.05l-5.3-1.62c-2.04-0.62-3.92-1.66-5.53-3.05
                    l-23.52-20.32c-4.95-4.28-6.63-11.24-4.18-17.31l18.43-45.52c2.71-6.7,0.37-14.38-5.63-18.42l-6.03-4.07
                    c-7.21-4.86-8.92-14.74-3.77-21.74l37.85-51.41c3.9-5.29,3.98-12.49,0.19-17.87l-7.39-10.5c-1.98-2.81-2.95-6.2-2.76-9.64L482,133
                    "
                  />
                  <path
                    display="none"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M464.43,529.53c0,0,0.63,0,0.65,0.36c0,0-0.02,0.29,0.22,0.31
                    c0.24,0.02,0.57-0.11,0.62-0.55c0.04-0.44,0.13-1.74,0.13-1.74s0.32-1.39,1.49-3.31c1.17-1.93,2.55-3.68,2.55-3.68l1.34-1.44
                    c0,0,0.72-0.45,1.24-0.55c0.52-0.1,1.75-1.55,1.75-1.55s1.1-1.72,1.79-1.89c0.69-0.17,2-0.21,2.27,0.62
                    c0.28,0.83,0.62,1.07,0.62,1.07s0.34,0.76,0.24,1.17c-0.1,0.41-0.45,0.65-0.45,0.65l-0.07,1.38l-0.62,0.76l0.21,2.24l0.55,0.48
                    c0,0,2.51,1.03,5.2,4.71c0,0,0.41,1.69-1.41,1.51l-2.72-1.03c0,0-1.55-0.45-2.44-2.2c0,0-0.55-0.34-0.69,0.07
                    c-0.14,0.41,0.1,1.03,0.1,1.03v1.69c0,0,0.89,1.79,1.17,2.27c0.28,0.48,1.51,2.13,1.58,3.68c0.07,1.55,0.17,4.23,0.17,4.23
                    s0.38,0.58,0.93,0.93c0.55,0.34,1.1,0.69,1.1,0.69l0.17,0.58h-5.57c0,0-0.41-0.34-0.45-1.41c-0.03-1.07,0.28-3.06,0.28-3.06
                    l-0.86-2.24l-0.83-1.07c0,0-0.38-0.24-0.48,0.17c-0.1,0.41,0,1.96,0,2.48c0,0.52-0.58,1.62-1.75,2.24c-1.17,0.62-1.93,0.58-1.86,1
                    c0.07,0.41,0.58,0.83,0.58,0.83l0.65,0.48l0.24,0.58c0,0-0.62,0.24-1.24,0.14c-0.62-0.1-0.89-0.31-1.38-0.38
                    c-0.48-0.07-1.38-0.31-1.38-0.31s-1.58-0.52-1.72-1.2c-0.14-0.69,0.31-0.93,0.72-1.24c0.41-0.31,1.51-1.17,1.51-1.17
                    s1.69-2.1,1.93-3.34c0.24-1.24,0.07-1.48,0.07-1.48s-0.96-0.45-1.2-1.34c-0.24-0.89-0.07-2.51-0.07-2.51s-0.41-0.55-0.86,0.17
                    c-0.45,0.72-0.24,3.03-3.17,3.1C465.3,532.44,462.77,530.65,464.43,529.53z"
                  />

                  {/* <image
                    overflow="visible"
                    width="512"
                    height="512"
                    id="person2"
                    href={person}
                    transform="matrix(0.0547 0 0 0.0547 461 514)"
                  /> */}
                </g>
                <g>
                  <path
                    id="person1-line"
                    fill="none"
                    d="M375.27,607.06l44.4-53.12c2.71-3.24,3.57-7.65,2.28-11.68l-8.19-25.53
                    c-1.57-4.9,0.06-10.26,4.1-13.45l21.66-17.1c7.16-5.65,5.92-16.85-2.3-20.8l-9.81-4.72c-1.55-0.74-2.92-1.8-4.04-3.11
                    l-21.95-25.72c-3.43-4.02-3.92-9.77-1.22-14.31l27.4-46.08c2.98-5.01,2.04-11.42-2.24-15.38l-9.37-8.64
                    c-5.16-4.76-5.32-12.85-0.35-17.8l48.87-48.77c3.75-3.75,4.69-9.48,2.33-14.23l-7.12-14.32c-1.23-2.48-1.6-5.31-1.03-8.02L482,133
                    "
                  />
                  <path
                    display="none"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M414.08,510.6c0,0,0.63,0,0.65,0.36c0,0-0.02,0.29,0.22,0.31
                    c0.24,0.02,0.57-0.11,0.62-0.55c0.04-0.44,0.13-1.74,0.13-1.74s0.32-1.39,1.49-3.31c1.17-1.93,2.55-3.68,2.55-3.68l1.34-1.45
                    c0,0,0.72-0.45,1.24-0.55c0.52-0.1,1.75-1.55,1.75-1.55s1.1-1.72,1.79-1.89c0.69-0.17,2-0.21,2.27,0.62
                    c0.28,0.83,0.62,1.07,0.62,1.07s0.34,0.76,0.24,1.17c-0.1,0.41-0.45,0.65-0.45,0.65l-0.07,1.38l-0.62,0.76l0.21,2.24l0.55,0.48
                    c0,0,2.51,1.03,5.2,4.71c0,0,0.41,1.69-1.41,1.51l-2.72-1.03c0,0-1.55-0.45-2.44-2.2c0,0-0.55-0.34-0.69,0.07
                    c-0.14,0.41,0.1,1.03,0.1,1.03v1.69c0,0,0.89,1.79,1.17,2.27c0.28,0.48,1.51,2.13,1.58,3.68c0.07,1.55,0.17,4.23,0.17,4.23
                    s0.38,0.58,0.93,0.93c0.55,0.34,1.1,0.69,1.1,0.69l0.17,0.58h-5.57c0,0-0.41-0.34-0.45-1.41c-0.03-1.07,0.28-3.06,0.28-3.06
                    l-0.86-2.24l-0.83-1.07c0,0-0.38-0.24-0.48,0.17c-0.1,0.41,0,1.96,0,2.48c0,0.52-0.58,1.62-1.75,2.24c-1.17,0.62-1.93,0.58-1.86,1
                    c0.07,0.41,0.58,0.83,0.58,0.83l0.65,0.48l0.24,0.58c0,0-0.62,0.24-1.24,0.14c-0.62-0.1-0.89-0.31-1.38-0.38
                    c-0.48-0.07-1.38-0.31-1.38-0.31s-1.58-0.52-1.72-1.2c-0.14-0.69,0.31-0.93,0.72-1.24c0.41-0.31,1.51-1.17,1.51-1.17
                    s1.69-2.1,1.93-3.34c0.24-1.24,0.07-1.48,0.07-1.48s-0.96-0.45-1.2-1.34c-0.24-0.89-0.07-2.51-0.07-2.51s-0.41-0.55-0.86,0.17
                    c-0.45,0.72-0.24,3.03-3.17,3.1C414.95,513.51,412.42,511.72,414.08,510.6z"
                  />

                  {/* <image
                    overflow="visible"
                    width="512"
                    height="512"
                    id="person1"
                    href={person}
                    transform="matrix(0.0547 0 0 0.0547 410 496)"
                  /> */}
                </g>
              </g>
              <g id="menu">
                <g id="right-nums">
                  <rect
                    x="906"
                    y="129.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 139.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {Math.round(maxPoint)}
                  </text>
                  <rect
                    x="906"
                    y="188.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 199.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="507000"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((7 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="248.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 257.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((6 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="306.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 317.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((5 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="364.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 375.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((4 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="425.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 434.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((3 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="484.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 492.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((2 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="541.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 552.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((1 / 8) * maxPoint, 10)}
                  </text>
                  <rect
                    x="906"
                    y="599.99"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="none"
                    width="60"
                    height="12.01"
                  />
                  <text
                    transform="matrix(1 0 0 1 906 610.5186)"
                    fontFamily="'Roboto'"
                    textAnchor="end"
                    fontSize="14px"
                    fontWeight="700"
                    fill={darkMode ? "#FFF" : "#000"}
                  >
                    {parseInt((0 / 9) * maxPoint, 10)}
                  </text>
                </g>
                <g>
                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="604.3"
                    x2="929.96"
                    y2="604.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="545.55"
                    x2="929.96"
                    y2="545.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="598.43"
                    x2="929.96"
                    y2="598.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="592.55"
                    x2="929.96"
                    y2="592.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="586.68"
                    x2="929.96"
                    y2="586.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="580.8"
                    x2="929.96"
                    y2="580.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="574.93"
                    x2="929.96"
                    y2="574.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="569.05"
                    x2="929.96"
                    y2="569.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="563.18"
                    x2="929.96"
                    y2="563.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="557.3"
                    x2="929.96"
                    y2="557.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="551.43"
                    x2="929.96"
                    y2="551.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="486.8"
                    x2="929.96"
                    y2="486.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="539.68"
                    x2="929.96"
                    y2="539.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="533.8"
                    x2="929.96"
                    y2="533.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="527.93"
                    x2="929.96"
                    y2="527.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="522.05"
                    x2="929.96"
                    y2="522.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="516.18"
                    x2="929.96"
                    y2="516.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="510.3"
                    x2="929.96"
                    y2="510.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="504.43"
                    x2="929.96"
                    y2="504.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="498.55"
                    x2="929.96"
                    y2="498.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="492.68"
                    x2="929.96"
                    y2="492.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="428.05"
                    x2="929.96"
                    y2="428.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="480.93"
                    x2="929.96"
                    y2="480.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="475.05"
                    x2="929.96"
                    y2="475.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="469.18"
                    x2="929.96"
                    y2="469.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="463.3"
                    x2="929.96"
                    y2="463.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="457.43"
                    x2="929.96"
                    y2="457.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="451.55"
                    x2="929.96"
                    y2="451.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="445.68"
                    x2="929.96"
                    y2="445.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="439.8"
                    x2="929.96"
                    y2="439.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="433.93"
                    x2="929.96"
                    y2="433.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="369.3"
                    x2="929.96"
                    y2="369.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="422.18"
                    x2="929.96"
                    y2="422.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="416.3"
                    x2="929.96"
                    y2="416.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="410.43"
                    x2="929.96"
                    y2="410.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="404.55"
                    x2="929.96"
                    y2="404.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="398.68"
                    x2="929.96"
                    y2="398.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="392.8"
                    x2="929.96"
                    y2="392.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="386.93"
                    x2="929.96"
                    y2="386.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="381.05"
                    x2="929.96"
                    y2="381.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="375.18"
                    x2="929.96"
                    y2="375.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="310.55"
                    x2="929.96"
                    y2="310.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="363.43"
                    x2="929.96"
                    y2="363.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="357.55"
                    x2="929.96"
                    y2="357.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="351.68"
                    x2="929.96"
                    y2="351.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="345.8"
                    x2="929.96"
                    y2="345.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="339.93"
                    x2="929.96"
                    y2="339.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="334.05"
                    x2="929.96"
                    y2="334.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="328.18"
                    x2="929.96"
                    y2="328.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="322.3"
                    x2="929.96"
                    y2="322.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="316.43"
                    x2="929.96"
                    y2="316.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="251.8"
                    x2="929.96"
                    y2="251.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="304.68"
                    x2="929.96"
                    y2="304.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="298.8"
                    x2="929.96"
                    y2="298.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="292.93"
                    x2="929.96"
                    y2="292.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="287.05"
                    x2="929.96"
                    y2="287.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="281.18"
                    x2="929.96"
                    y2="281.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="275.3"
                    x2="929.96"
                    y2="275.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="269.43"
                    x2="929.96"
                    y2="269.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="263.55"
                    x2="929.96"
                    y2="263.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="257.68"
                    x2="929.96"
                    y2="257.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="193.05"
                    x2="929.96"
                    y2="193.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="245.93"
                    x2="929.96"
                    y2="245.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="240.05"
                    x2="929.96"
                    y2="240.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="234.18"
                    x2="929.96"
                    y2="234.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="228.3"
                    x2="929.96"
                    y2="228.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="222.43"
                    x2="929.96"
                    y2="222.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="216.55"
                    x2="929.96"
                    y2="216.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="210.68"
                    x2="929.96"
                    y2="210.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="204.8"
                    x2="929.96"
                    y2="204.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="198.93"
                    x2="929.96"
                    y2="198.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="909.96"
                    y1="134.3"
                    x2="929.96"
                    y2="134.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="187.18"
                    x2="929.96"
                    y2="187.18"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="181.3"
                    x2="929.96"
                    y2="181.3"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="175.43"
                    x2="929.96"
                    y2="175.43"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="169.55"
                    x2="929.96"
                    y2="169.55"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="163.68"
                    x2="929.96"
                    y2="163.68"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="157.8"
                    x2="929.96"
                    y2="157.8"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="151.93"
                    x2="929.96"
                    y2="151.93"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="146.05"
                    x2="929.96"
                    y2="146.05"
                  />

                  <line
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="917.96"
                    y1="140.18"
                    x2="929.96"
                    y2="140.18"
                  />
                </g>
                <g>
                  <image x="900" y="95" width="45" href={menuTrophy} />
                </g>
              </g>
            </svg>
          </SoftBox>

          {/** Player and yeti score cards */}
          <SoftBox
            sx={{
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <SoftBox
              shadow="sm"
              bgColor={darkMode ? "#0D0D0D" : "transparent"}
              sx={{
                display: "flex",
                gap: "8%",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: "12px",
                paddingX: "2%",
                paddingY: { mini: "2%", md: "1%", xl: "0.5%" },
                width: { mini: "27%", xs: "25%", lg: "30%", xxl: "28%" },
                height: { mini: "48px", xs: "auto" },
                position: "absolute",
                left: { mini: "10%", md: "12%" },
                top: { mini: "22%", xs: "14%", sm: "10%" },
              }}
            >
              <SoftProfile
                size={smBp ? "sm" : !lgBp ? "md" : "lg"}
                src={playerLogo}
                name={playerName}
                fullCover
              />
              <SoftBox
                sx={{
                  py: "5%",
                  height: "100%",
                  width: "50%",
                }}
              >
                <SoftBox
                  sx={{
                    height: "55%",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5%",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 80 32"
                    overflow="visible"
                  >
                    <text
                      ref={playerPtsRef}
                      x="0"
                      y="25"
                      fontFamily="Roboto"
                      fontSize="26"
                      fontWeight="500"
                      fill={darkMode ? "white" : "black"}
                      textAnchor="start"
                      alignmentBaseline="top"
                    >
                      {Number.isNaN(playerScore) ||
                      !Number.isFinite(playerScore)
                        ? 0
                        : Math.round(playerScore)}
                    </text>
                  </svg>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "25%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 80 14"
                    overflow="visible"
                  >
                    <text
                      x="0"
                      y="8"
                      fontFamily="Roboto"
                      fontSize="14"
                      fill={darkMode ? "white" : "black"}
                      textAnchor="start"
                    >
                      Points
                    </text>
                  </svg>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              shadow="sm"
              bgColor={darkMode ? "#0D0D0D" : "white"}
              sx={{
                display: "flex",
                gap: "8%",
                alignItems: "center",
                justifyContent: "flex-end",
                borderRadius: "12px",
                paddingX: "2%",
                paddingY: { mini: "2%", md: "1%", xl: "0.5%" },
                width: { mini: "27%", xs: "25%", lg: "30%", xxl: "28%" },
                height: { mini: "48px", xs: "auto" },
                position: "absolute",
                right: { mini: "10%", md: "12%" },
                top: { mini: "22%", xs: "14%", sm: "10%" },
              }}
            >
              <SoftBox
                sx={{
                  py: "5%",
                  height: "100%",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <SoftBox
                  sx={{
                    height: "55%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "5%",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 80 32"
                    overflow="visible"
                  >
                    <text
                      ref={yetiPtsRef}
                      x="80"
                      y="25"
                      fontFamily="Roboto"
                      fontSize="26"
                      fontWeight="500"
                      fill={darkMode ? "white" : "black"}
                      textAnchor="end"
                    >
                      {Math.round(yetiScore)}
                    </text>
                  </svg>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "25%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 80 14"
                    overflow="visible"
                  >
                    <text
                      x="80"
                      y="8"
                      fontFamily="Roboto"
                      fontSize="14"
                      fill={darkMode ? "white" : "black"}
                      textAnchor="end"
                      alignmentBaseline="top"
                    >
                      Points
                    </text>
                  </svg>
                </SoftBox>
              </SoftBox>

              <SoftProfile
                size={smBp ? "sm" : !lgBp ? "md" : "lg"}
                src={YETI_PROFILE.image}
                name={YETI_PROFILE.firstName}
              />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      ) : null}
    </>
  );
};

export default KingOfTheMountain;
