/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 250;
  // const { white, transparent } = palette;
  const { secondary } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    backgroundColor: secondary.main,
    color: secondary.contrastText,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      // backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },

    // Temporary: Force zero width until navbar mechanics has been finalized
    [breakpoints.down("sm")]: {
      width: sidebarWidth,
      display: "block",
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    backgroundColor: secondary.main,
    color: secondary.contrastText,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      // backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },

    // Temporary: Force zero width until navbar mechanics has been finalized
    [breakpoints.down("sm")]: {
      width: 0,
      display: "hidden",
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      margin: 0,
      // borderRadius: "1rem",
      borderRadius: "0px",
      minHeight: "100vh",
      pb: 8,

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  
    "& .MuiPaper-root": {
      "& h4": {
        paddingRight: "20px",
        lineHeight: "normal",
        whiteSpace: "normal"
      }
    },
    
    "& hr": {
      height: "2px",
      background:
        "linear-gradient( 90deg, rgba(255, 255, 255, 0) 0%, #f5f5f5 46.87%, rgba(255, 255, 255, 0) 99.94% )",
      margin: "0.5rem 0",
    },
  };
});
