/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { FETCH_JOB } from "../../api/jobs";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteKpiRecord, updateKpiRecord } from "../../redux/kpi/actions";

import {
  Edit as EditIcon,
  DeleteOutline,
  BoltRounded,
  SwitchAccessShortcut,
} from "@mui/icons-material";
import SoftButton from "../../components/SoftButton";
import Backdrop from "@mui/material/Backdrop";
import ConfirmatonModal from "../../components/Modals/ConfirmationModal";
import InputModal from "../../components/Modals/InputModal";

import FeedItem from "../Feed/FeedItem";
import toast from "react-hot-toast";
import { formatNumber } from "../../helpers/formatter";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import borders from "../../assets/theme/base/borders";

const SOURCE_ICONS = {
  INTEGRATED: (
    <BoltRounded style={{ cursor: "pointer", color: "primary.main" }} />
  ),
  MANUAL: <EditIcon style={{ cursor: "pointer", color: "primary.main" }} />,
  OTHER: (
    <SwitchAccessShortcut
      style={{ cursor: "pointer", color: "primary.main" }}
    />
  ),
};

function Table({ employeeKpis, isAdmin }) {
  const [kpiScores, setKpiScores] = useState(employeeKpis);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [job, setJob] = useState(null);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const dispatch = useDispatch();

  const getJobItem = (jobId) => {
    if (jobId) {
      FETCH_JOB(jobId).then(({ data }) => {
        setJob(data);
      });
    }
  };

  useEffect(() => {
    const columns = [
      { name: "kpi", align: "center" },
      ...(isAdmin ? [{ name: "player", align: "center" }] : []),
      { name: "source", align: "center" },
      { name: "amount", align: "center" },
      { name: "date", align: "center" },
      { name: "reference", align: "center" },
      ...(isAdmin ? [{ name: "actions", align: "center" }] : []),
    ];

    const handleDelete = (id) => {
      dispatch(deleteKpiRecord(id));
      const ekpis = [...kpiScores];
      const index = ekpis.findIndex((item) => item._id === id);
      if (index >= 0) {
        ekpis.splice(index, 1);
        setKpiScores(ekpis);
      }
      toast.success("KPI Record Deleted");
    };

    const handleUpdate = (id, kpiId, amount) => {
      if (amount) {
        dispatch(updateKpiRecord(id, kpiId, amount));
        const ekpis = [...kpiScores];
        const index = ekpis.findIndex((item) => item._id === id);
        if (index >= 0) {
          ekpis[index].amount = amount;
          setKpiScores(ekpis);
        }
        toast.success("KPI Record Updated");
      }
    };

    const rowsData = kpiScores.map((score) => {
      const jobId =
        score.refModel === "Job"
          ? score.refId
          : score.claimModel === "Job"
          ? score.claimId
          : null;

      const rowContainer = {};
      rowContainer.kpi = score.kpiName;
      rowContainer.source = (
        <SoftBox>
          {SOURCE_ICONS[score.dataSource] ?? SOURCE_ICONS.OTHER}
          <SoftTypography variant="caption">{score.dataSource}</SoftTypography>
        </SoftBox>
      );

      if (isAdmin) {
        rowContainer.player = score.name;
      }

      const formattedAmt = formatNumber(score.amount, score.kind);
      rowContainer.amount = formattedAmt;
      rowContainer.rawAmount = score.amount;
      rowContainer.date = moment(score.createdAt).format("MMM D,YYYY");
      rowContainer.reference = jobId ? (
        <SoftButton
          variant="text"
          sx={{
            color: "primary.main",
            "&:hover": {
              color: "primary.focus",
            },
          }}
          onClick={() => getJobItem(jobId)}
        >
          View Job
        </SoftButton>
      ) : (
        <SoftTypography variant="caption">{score.notes}</SoftTypography>
      );

      if (isAdmin) {
        rowContainer.actions = (
          <SoftBox className="flex flex-row">
            <InputModal
              title={`Update ${score.kpiName} Score`}
              question={`Do you want to update ${score.name}'s  ${score.kpiName} KPI record? Current amount: ${formattedAmt}`}
              yesText="Yes, update"
              noText="No, cancel"
              label="New Value"
              isNumericInput={true}
              onsuccess={(value) => handleUpdate(score._id, score.kpiId, value)}
            >
              <EditIcon style={{ cursor: "pointer", margin: 5 }} />
            </InputModal>
            <ConfirmatonModal
              title={`Delete ${score.kpiName} Score`}
              question={`Are you sure you want to delete \n ${score.name}'s ${formattedAmt} ${score.kpiName} KPI record?`}
              yesText="Yes, delete"
              noText="No, cancel"
              onSuccess={() => handleDelete(score._id)}
            >
              <DeleteOutline style={{ cursor: "pointer", margin: 5 }} />
            </ConfirmatonModal>
          </SoftBox>
        );
      }
      return rowContainer;
    });

    const renderColumns = columns.map(({ name, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 3;
        pr = 3;
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.sm}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {name === "source" ? "DATA SOURCE" : name.toUpperCase()}
        </SoftBox>
      );
    });

    const renderRows = rowsData.map((row, key) => {
      const rowKey = `row-${key}`;

      const tableRow = columns.map(({ name, align }) => {
        return (
          <SoftBox
            key={`${rowKey}-${name}`}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={
              row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
            }
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      });

      return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });

    setRows(renderRows);
    setCols(renderColumns);
  }, [kpiScores, isAdmin, dispatch, borderWidth, light, size, fontWeightBold]);

  const tableComponent = useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{cols}</TableRow>
          </SoftBox>
          <TableBody>{rows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [rows, cols],
  );

  return (
    <>
      {tableComponent}
      {job && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={job !== null}
          onClick={() => setJob(null)}
        >
          <SoftBox
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "60vw" },
              maxHeight: "95vh",
            }}
          >
            <FeedItem job={job} />
          </SoftBox>
        </Backdrop>
      )}
    </>
  );
}

export default Table;
