import * as Yup from "yup";

const FreemiumSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string().email().required("Enter a valid e-mail address"),
    hasInfo: Yup.boolean().default(false),
    code: Yup.string().when(["hasInfo"], {
        is: true,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required("Enter an invite code.")
      }),
    // Allow dev env to proceed to freemium sign-in with Google Business
    ...process.env.NODE_ENV === "production" && { googleBusinessProfile: Yup.string().required("Google Business Profile is required")},
 });

 export default FreemiumSchema;