import { initState, types } from "./types";

const workspacesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ALL_WORKSPACES:
      return {
        ...state,
        isLoading: true,
        isError: null
      };
    case types.GET_ALL_WORKSPACES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedWorkspace: null,
        workspaces: action.workspaces,
        isError: null
      };
    case types.GET_ALL_WORKSPACES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        workspaces: []
      };
    case types.SET_SELECTED_WORKSPACE_SUCCESS:
      return {
        ...state,
        selectedWorkspace: action.selectedWorkspace
      };
    case types.SET_SELECTED_WORKSPACE_FAILED:
      return {
        ...state,
        selectedWorkspace: null,
        error: action.error,
      }
    case types.SET_WORKSPACES:
      return {
        ...state,
        workspaces: action.workspaces,
      };
    default:
      return state;
  }
};

export default workspacesReducer;
