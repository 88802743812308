import React, { useState } from "react";

// MUI/SoftUI
import Grid from "@mui/material/Grid";
import Collapse from '@mui/material/Collapse';

import SoftBox from "../../../SoftBox";
import SoftButton from "../../../SoftButton";
import SoftInput from "../../../SoftInput";
import SoftSelect from "../../../SoftSelect";
import SoftTypography from "../../../SoftTypography";

// Icons
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

// Constants
import { anchorPointTypes } from "../../metrics/CustomerMetricsType/Dialogs/utils/constants";
import { ANCHOR_BUTTON_STATE, KINDS_INFO } from "../constants";

const AnchorPointForm = ({
  kind,
  errors,
  handleRegister,
  handleSetValue,
  handleTrigger,
 }) => {
  const [show, setShow] = useState(false);
  const [anchorState, setAnchorState] = useState(ANCHOR_BUTTON_STATE.IDLE);

  const defaultKind = anchorPointTypes.find(item => item.label.includes(kind))
  const anchorInfo = KINDS_INFO.find(item => item.kind === kind)?.info
    ?? KINDS_INFO[1].info;

  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#E9ECEF",
    lineHeight: "15.62px"
  };

  const fontStylesLabel = {
    fontFamily: "Inter !important",
    fontWeight: 400,
    fontSize: "12px",
    fontStyle: "normal",
    color: "#FFF !important",
    lineHeight: "14.52px"
  };

  const buttonTextStyles = {
    fontWeight: 700,
    textTransform: "capitalize",
    color: "#FFF !important",
    border: "1px solid #667085",
    borderRadius: "4px",
    padding: "5px 10px",
    minWidth: "48px",
    minHeight: "26px",
    cursor: "pointer"
  };

  const toggleAnchorInputField = async () => {
    const result = await handleTrigger("target", { shouldFocus: true });
    if (!result) return;

    setShow(prev => !prev);
    let anchorButtonState

    if ([ANCHOR_BUTTON_STATE.IDLE, ANCHOR_BUTTON_STATE.EDITED].includes(anchorState)) {
      anchorButtonState = ANCHOR_BUTTON_STATE.OPEN;
    }

    if (anchorState === ANCHOR_BUTTON_STATE.OPEN) {
      anchorButtonState = ANCHOR_BUTTON_STATE.EDITED;
    }

    setAnchorState(anchorButtonState);
    handleSetValue(anchorButtonState);
  }

  return (
    <SoftBox
      sx={{
        minHeight: "80px",
        marginBottom: "32px",
        backgroundColor: "#3D3D3F",
        padding: "16px 24px",
        border: "0.99454px solid rgba(233, 236, 239, 0.2)",
        borderRadius: "6px",
        boxShadow: " 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1)"
      }}
    >
      <SoftBox>
        {/** Anchor point header and controls */}
        <Grid container>
          {/** RIGHTSIDE main content - Anchor point description text */}
          <Grid item xs={9}>
            <SoftTypography
              variant="body1"
              sx={{
                ...fontStyles,
                fontWeight: "500 !important",
                marginBottom: "6px"
              }}
            >
              Select Anchor Point
            </SoftTypography>
            <SoftTypography
              variant="body1"
              sx={{
                ...fontStyles,
                color: "#D0D5DD !important"
              }}
            >
              This represents the threshold value at which your metric will anchor, determining point gains or losses
            </SoftTypography>
          </Grid>

          {/** LEFTSIDE - control panel */}
          <Grid item xs={3}>
            <SoftBox
              sx={{
                width: "100%",
                height: "100%",
                ...(anchorState === ANCHOR_BUTTON_STATE.IDLE && {
                  display: "grid",
                  placeContent: "center",
                }),
                ...([ANCHOR_BUTTON_STATE.OPEN, ANCHOR_BUTTON_STATE.EDITED].includes(anchorState) && {
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "end"
                })
              }}
            >
              {(anchorState === ANCHOR_BUTTON_STATE.IDLE) &&
                <SoftButton
                  variant="text"
                  onClick={toggleAnchorInputField}
                  sx={{
                    textTransform: "capitalize",
                    color: "#9E77ED !important",
                  }}
                >
                  Click to Setup
                </SoftButton>
              }

              {(anchorState === ANCHOR_BUTTON_STATE.OPEN) &&
                <SoftBox
                  onClick={toggleAnchorInputField}
                  sx={{ ...fontStyles, ...buttonTextStyles }}
                >
                  Save
                </SoftBox>
              }

              {(anchorState === ANCHOR_BUTTON_STATE.EDITED) &&
                <SoftBox>
                  <SoftBox
                    sx={{
                      "& svg": { width: "16px", marginRight: "4px", color: "#57EC54" },
                    }}
                  >
                    <CheckCircleOutlineOutlinedIcon />
                    <SoftTypography
                      variant="caption"
                      sx={{
                        ...fontStyles,
                        fontSize: "10px",
                        lineHeight: "13.02px"
                      }}
                    >
                      Anchor Set
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox

                    onClick={toggleAnchorInputField}
                    sx={{
                      ...fontStyles,
                      ...buttonTextStyles,
                      width: "5px",
                      float: "right",
                      border: "none",
                      padding: "12px 10px 0 24px",
                    }}
                  >
                    Edit
                  </SoftBox>
                </SoftBox>
              }
            </SoftBox>
          </Grid>
        </Grid>

        {/** Anchor target input field */}
        <Collapse in={show}>
          <SoftBox
            sx={{
              minHeight: "100px",
              marginTop: "32px"
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <SoftTypography
                  variant="body1"
                  sx={{ ...fontStylesLabel }}
                >
                  Enter desired anchor
                </SoftTypography>
                <SoftInput
                  name="target"
                  placeholder="target"
                  autoComplete="off"
                  {...handleRegister("target")}
                  error={!!errors?.target}
                  helperText={errors?.target?.message}
                  onClick={() => handleSetValue(anchorState)}
                  sx={{
                    ...fontStyles,
                    marginTop: "8px",
                    marginBottom: "8px",
                    lineHeight: "15.62px",
                    borderRadius: "4px",
                    height: "41px !important",
                    border: "1px solid #667085",
                    backgroundColor: "rgba(255, 255, 255, 0.2) !important",
                    color: "#FFFFFF !important",
                    "& input": {
                      ...fontStylesLabel,
                      padding: "7px 22px",
                      lineHeight: "14.62px",

                     },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#EAECF0 !important",
                      fontSize: "12px",
                      lineHeight: "14.62px"
                    },
                    "&.Mui-focused": {
                      borderColor: (theme) => theme.palette.primary.main,
                      boxShadow: "none",
                    },
                  }}
                />

                {/** Regular helper text */}
                <SoftTypography
                  variant="body1"
                  sx={{
                    ...fontStylesLabel,
                    fontSize: "10px !important",
                    lineHeight: "12px !importnat",
                    color: "#77ED83 !important"
                  }}
                >
                  {(!errors?.target) && anchorInfo}
                </SoftTypography>
              </Grid>
              <Grid item xs={3}>
                <SoftTypography
                  variant="body1"
                  sx={{ ...fontStylesLabel, marginBottom: "8px" }}
                >
                  &nbsp;
                </SoftTypography>
                <SoftSelect
                  options={anchorPointTypes}
                  defaultValue={defaultKind}
                  isDisabled
                  styles={{
                    control: {
                      border: "1px solid #98A2B3",
                      borderRadius: "4px",
                    },
                    placeholder: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    input: {
                      color: "white !important",
                    },
                    singleValue: {
                      color: "white !important",
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/** Sub-footer text */}
            <SoftTypography
              variant="body1"
              sx={{
                ...fontStylesLabel,
                fontFamily: "DM Sans !important",
                fontSize: "10px !important",
                lineHeight: "13px !importnat",
                color: "#FFF !important",
                marginTop: "32px"
              }}
            >
              Note: This value determines point gains or losses based on your metric's performance relative to this anchor
            </SoftTypography>
          </SoftBox>
        </Collapse>
      </SoftBox>

      {(errors?.anchorSetup && !errors?.target) &&
        <SoftTypography variant="caption" color="error">
          {errors.anchorSetup.message}
        </SoftTypography>
      }

    </SoftBox>
  );
};

export default AnchorPointForm;
