import React, { useState, useEffect } from "react";
import SoftTypography from "../SoftTypography";
import { isBefore, intervalToDuration, formatDuration } from "date-fns";
import SoftBox from "../SoftBox";
import useMediaQuery from "@mui/material/useMediaQuery";

const ChallengeCountdown = ({
  challenge,
  countdownEnded,
  setCountdownEnded,
  isWord,
  isCentered,
  isBigWord,
  isMarginCentered,
  noBorder,
}) => {
  const { startDate, endDate } = challenge;
  const challengeStartDate = new Date(startDate);
  const challengeEndDate = new Date(endDate);
  const [years, setYears] = useState(null);
  const [months, setMonths] = useState(null);
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [word, setWord] = useState(null);
  const mdBp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    // const formatDistanceLocale = {
    //   xMinutes: "{{count}}M",
    //   xHours: "{{count}}H",
    //   xDays: "{{count}}D",
    // };
    // const shortEnLocale = {
    //   formatDistance: (token, count) =>
    //     formatDistanceLocale[token].replace("{{count}}", count),
    // };

    const interval = setInterval(() => {
      const now = new Date();

      const challengeNotStarted = intervalToDuration({
        start: challengeStartDate,
        end: now,
      });

      const challengeStarted = intervalToDuration({
        start: now,
        end: challengeEndDate,
      });

      if (isBefore(challengeEndDate, now)) {
        setCountdownEnded(true);
        clearInterval(interval);
        setWord("ENDS IN");
      } else if (isBefore(now, challengeStartDate)) {
        // setCountdown(
        //   `${formatDuration(
        //     challengeNotStarted,
        //     {
        //       format: ["days", "hours", "minutes"],
        //       locale: shortEnLocale,
        //     },
        //     { zero: true },
        //   )}`,
        // );
        setYears(challengeNotStarted.years);
        setMonths(challengeNotStarted.months);
        setDays(challengeNotStarted.days);
        setHours(challengeNotStarted.hours);
        setMinutes(challengeNotStarted.minutes);
        setWord("STARTS IN");
      } else {
        // setCountdown(
        //   `${formatDuration(
        //     challengeStarted,
        //     {
        //       format: ["days", "hours", "minutes"],
        //       locale: shortEnLocale,
        //     },
        //     { zero: true },
        //   )}`,
        // );
        setYears(challengeStarted.years);
        setMonths(challengeStarted.months);
        setDays(challengeStarted.days);
        setHours(challengeStarted.hours);
        setMinutes(challengeStarted.minutes);
        setWord("ENDS IN");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isWord && word?.length > 0 ? (
        <SoftTypography
          variant="caption"
          color="white"
          sx={{
            maxWidth: "max-content",
            background: noBorder ? "transparent" : "rgba(255, 255, 255, 0.10)",
            border: noBorder ? "none" : "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "6px",
            padding: "0.25rem 0.75rem",
            marginBottom: "0.5rem",
            display: "block",
            marginX: "auto",
            fontSize: "0.6rem !important",
          }}
        >
          {word}
        </SoftTypography>
      ) : null}
      {isBigWord && word?.length > 0 ? (
        <SoftTypography
          variant="button"
          color="white"
          sx={{
            maxWidth: "max-content",
            background: noBorder ? "transparent" : "rgba(255, 255, 255, 0.10)",
            border: noBorder ? "none" : "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "6px",
            padding: "0.25rem 0.75rem",
            marginBottom: "1rem",
            display: "block",
            marginX: { mini: "auto", md: isMarginCentered ? "auto" : 0 },
          }}
        >
          {word}
        </SoftTypography>
      ) : null}
      {countdownEnded ? (
        <SoftTypography
          variant={mdBp ? "h4" : "h5"}
          color="white"
          marginBottom="2rem"
          sx={{
            textAlign: { mini: "center", md: isCentered ? "center" : "start" },
          }}
        >
          CHALLENGE ENDED
        </SoftTypography>
      ) : (
        <SoftBox
          display="flex"
          alignItems="baseline"
          sx={{
            marginBottom: { mini: "1rem", md: "2rem" },
            justifyContent: {
              mini: "center",
              md: isCentered ? "center" : "start",
            },
          }}
        >
          {years > 0 ? (
            <>
              <SoftTypography variant={isBigWord ? "h1" : "h3"} color="white">
                {years}
              </SoftTypography>
              <SoftTypography variant={isBigWord ? "h3" : "h6"} color="white">
                Y
              </SoftTypography>
              <SoftTypography
                variant={isBigWord ? "h1" : "h3"}
                color="white"
                marginX="0.25rem"
              >
                :
              </SoftTypography>
            </>
          ) : null}
          {months > 0 ? (
            <>
              <SoftTypography variant={isBigWord ? "h1" : "h3"} color="white">
                {months}
              </SoftTypography>
              <SoftTypography variant={isBigWord ? "h3" : "h6"} color="white">
                M
              </SoftTypography>
              <SoftTypography
                variant={isBigWord ? "h1" : "h3"}
                color="white"
                marginX={isBigWord ? "0.5rem" : "0.25rem"}
              >
                :
              </SoftTypography>
            </>
          ) : null}
          {days > 0 ? (
            <>
              <SoftTypography variant={isBigWord ? "h1" : "h3"} color="white">
                {days}
              </SoftTypography>
              <SoftTypography variant={isBigWord ? "h3" : "h6"} color="white">
                D
              </SoftTypography>
              <SoftTypography
                variant={isBigWord ? "h1" : "h3"}
                color="white"
                marginX={isBigWord ? "0.5rem" : "0.25rem"}
              >
                :
              </SoftTypography>
            </>
          ) : null}
          {hours > 0 ? (
            <>
              <SoftTypography variant={isBigWord ? "h1" : "h3"} color="white">
                {hours}
              </SoftTypography>
              <SoftTypography variant={isBigWord ? "h3" : "h6"} color="white">
                H
              </SoftTypography>
              <SoftTypography
                variant={isBigWord ? "h1" : "h3"}
                color="white"
                marginX={isBigWord ? "0.5rem" : "0.25rem"}
              >
                :
              </SoftTypography>
            </>
          ) : null}
          {minutes > 0 ? (
            <>
              <SoftTypography variant={isBigWord ? "h1" : "h3"} color="white">
                {minutes}
              </SoftTypography>
              <SoftTypography variant={isBigWord ? "h3" : "h6"} color="white">
                M
              </SoftTypography>
            </>
          ) : null}
        </SoftBox>
      )}
    </>
  );
};

export default ChallengeCountdown;
