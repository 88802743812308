import { types, initState } from "./types";

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
    case types.REGISTER_REQUEST:
      return {
        ...state,
        authenticated: false,
        isLoading: true,
        userDetails: null,
        status: null,
        errorMessage: "",
      };
    case types.LOGIN_SUCCESS:
    case types.LOGIN_QUICK_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isLoading: false,
        userDetails: action.userDetails,
        error: false,
        isPrime: action.isPrime,
        isDemo: action.isDemo,
        access: action.access,
        status: "authenticated",
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: "registered",
      };
    case types.LOGIN_FAILED_INVALID:
    case types.REGISTER_FAILED:
    case types.LOGIN_QUICK_FAILED:
      return {
        ...state,
        authenticated: false,
        isLoading: false,
        status: "error",
        errorMessage: action.message,
      };

    case "REMOVE_ERROR":
      return {
        ...state,
        errorMessage: "",
      };
    case types.PROFILE_PIC_UPLOAD:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          image: action.payload.key || action.payload,
        },
      };
    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails, // retain unchanged parameters
          ...action.userDetails,
        },
        isLoading: false,
      };
    case types.UPDATE_WIDGETS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_WIDGETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.userDetails,
        error: false,
      };
    case types.UPDATE_WIDGETS_FAILED:
      return {
        ...state,
        isLoading: false,
        status: "error",
        errorMessage: action.message,
      };
    case "CREATE_COMPANY":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    case "SET_AS_ADMIN":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isAdmin: true,
        },
      };
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default loginReducer;
