/** Soft UI versio of profile pictures */
import React, { forwardRef } from "react";
import SoftAvatar from "../SoftAvatar";
import { GlowEffect } from "../../styles/effects";
import { GET_IMAGE } from "../../helpers/images";

const AvatarComponent = ({ ref, src, name, fullCover = false, ...rest }) => (
  <SoftAvatar {...rest} alt={name} src={src ? GET_IMAGE(src) : null} fullCover={fullCover}>
    {
      !src && name ? ([...name][0]).toUpperCase(): null
    }
  </SoftAvatar>
);

const SoftProfile = forwardRef(
  ({ src, name, glow, borderRadius, fullCover = false, ...rest }, ref) => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {glow ? (
          <GlowEffect borderRadius={borderRadius}>
            <AvatarComponent
              ref={ref}
              {...rest}
              src={src}
              name={name}
              borderRadius={borderRadius}
              fullCover={fullCover}
            />
          </GlowEffect>
        ) : (
          <AvatarComponent
            ref={ref}
            {...rest}
            src={src}
            name={name}
            borderRadius={borderRadius}
            fullCover={fullCover}
          />
        )}
      </>
    );
  },
);

SoftProfile.defaultProps = {
  bgColor: "light",
  size: "lg",
  shadow: "none",
  borderRadius: "section",
  glow: false,
};

export default SoftProfile;
