const CARD_STYLES = {
  default: {
    borderColor: "#E9ECEF",
    bgColor: "white",
  },
  zapier: {
    borderColor: "rgba(255, 79, 0, 0.40)",
    bgColor: "rgba(244, 144, 12, 0.03)",
  },
  connected: {
    borderColor: "#04549C",
    bgColor: "rgba(4, 84, 156, 0.03)",
  },
};


export default CARD_STYLES;