import { types } from "./types";

export const getWorkspaces = (payload) => ({
  type: types.GET_ALL_WORKSPACES,
  ...payload
});

export const setSelectedWorkspace = (payload) => ({
  type: types.SET_SELECTED_WORKSPACE,
  ...payload
})
