import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../helpers/cropImage";
import { Box, Dialog, DialogActions, DialogContent, Grid, Slider, Stack, Typography } from "@mui/material";
import SoftButton from "../../components/SoftButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@mui/material/styles";


const ImageCropper = ({ imgSrc, open, setOpen, onSaveCroppedImage }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const theme = useTheme();

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	// eslint-disable-next-line no-shadow
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		try {
			// eslint-disable-next-line no-shadow
			const croppedImageFile = await getCroppedImg(
				imgSrc,
				croppedAreaPixels,
				rotation
			);
			onSaveCroppedImage(croppedImageFile);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels, rotation]);

	return (
		<Dialog
			onClose={() => setOpen(!open)}
			open={open}
			fullWidth
			fullScreen={fullScreen}
		>
			<DialogContent>
				<Box
					sx={{ height: fullScreen ? "100%" : 550 }}
				>
					<Cropper
						image={imgSrc}
						crop={crop}
						rotation={rotation}
						zoom={zoom}
						aspect={4 / 3}
						onCropChange={setCrop}
						onRotationChange={setRotation}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</Box>
			</DialogContent>
			<Stack direction="column" sx={{ padding: 2 }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
				>
					<Grid item>
						<Typography
							variant="overline"
						>
							Zoom
						</Typography>
					</Grid>
					<Grid item xs={8} md={10}>
						<Slider
							value={zoom}
							min={1}
							max={3}
							step={0.1}
							aria-labelledby="Zoom"
							onChange={(e, newZoom) => setZoom(newZoom)}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={1}
				>
					<Grid item>
						<Typography
							variant="overline"
						>
							Rotation
						</Typography>
					</Grid>
					<Grid item xs={8} md={10}>
						<Slider
							value={rotation}
							min={0}
							max={360}
							step={1}
							aria-labelledby="Rotation"
							onChange={(e, newRotation) => setRotation(newRotation)}
						/>
					</Grid>
				</Grid>
			</Stack>
			<DialogActions clasName="flex justify-center">
				<SoftButton
					onClick={() => setOpen(false)}
					variant="outlined"
					color="primary"
					fullWidth
				>
					CANCEL
				</SoftButton>
				<SoftButton
					onClick={showCroppedImage}
					variant="contained"
					color="primary"
					fullWidth
				>
					DONE
				</SoftButton>
			</DialogActions>
		</Dialog>
	);
};

export default ImageCropper;

