/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "../../SoftBox";

// Timeline context
import { TimelineProvider } from "../context";

function TimelineList({ dark, children }) {
  return (
    <TimelineProvider value={dark}>
      <SoftBox bgColor="transparent">
        <SoftBox p={2}>{children}</SoftBox>
      </SoftBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
