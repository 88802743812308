import React from "react";
import { styled } from "@mui/material/styles";
import SoftBox from "../../../SoftBox";

const FormTextSummary = styled(SoftBox)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "12px 12px",
  borderRadius: "4px",
  border: "1px solid #CED4DA",
  boxShadow: "none",
  fontSize: "14px",
  textTransform: "capitalize"
}))

export default FormTextSummary;
