import React from "react";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import "./swal-globals.css";

const InputModal = ({ title, question, label, isNumericInput, yesText, noText, onsuccess, onCancel, onOpen, onClose, children }) => {
  const theme = useTheme();

  const showAlert = () => {
    onOpen?.();
  
    Swal.fire({
        title,
        text: question,
        icon: question? "question": "success",
        iconColor: theme?.palette.secondary.main,
        confirmButtonColor: theme?.palette.primary.main,
        cancelButtonColor: theme?.palette.primary.lighter,
        ...noText && { showCancelButton: true },
        ...yesText && { confirmButtonText: yesText },
        ...noText && { cancelButtonText: noText },
        reverseButtons: true,
        customClass: { container: "swal-container" },
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        ...label && { inputLabel: label },
        inputValidator: (val) => {
          if (!val) {
            return 'No input found.'
          } else if (isNumericInput && Number.isNaN(val)) {
            return `${val} is not a valid number.`
          }
        }
      })
      .then((result) => {
        if (result.value) {
          onsuccess?.(result.value);
         }else if (result.dismiss === Swal.DismissReason.cancel) {
          onCancel?.();
         }
        onClose?.();
      });
  };

  return (
    <div onClick={showAlert}>{children}</div>
  );
};

export default InputModal;
