import React, { useState, useEffect } from "react";

// @mui material components
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftButton from "../../../SoftButton";
import SoftTypography from "../../../SoftTypography";

import StepperIcon from "./StepperIcon";

// Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const SetupDialog = ({
  title = "Setup Metric",
  subtitle = "Adjust your custom metric settings",
  btnYesLabel = "Ok",
  btnNoLabel = "Back",
  btnSubmitLabel = "Submit",
  open = false,
  isDisabled = false,
  handleClose,
  handleNextBtnPress,
  handleBackBtnPress,
  steps = ["Step 1", "Step 2"],
  activeStep = 1,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsOpen(open), 500);
  }, [open]);

  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontStyle: "normal",
    color: "#E9ECEF"
  };

  const buttonStyles = {
    width: "100%",
    height: "35px",
    border: "0.69px solid rgba(233, 236, 239, 0.1)",
    borderRadius: "2.75px",
    textTransform: "capitalize",
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: 500,
    padding: "0 12px",
    boxShadow: "0px 0.69px 1.37px 0px rgba(0, 0, 0, 0.05)",
    letterSpacing: "-0.02em"
  };

  const handleDialogClose = (isSubmit = false) => {
    setIsOpen(false);
    handleClose?.({ isSubmit });
  }

  const handleDialogBackStep = () => {
    if (activeStep === 1) {
      handleDialogClose(false);
    }

    if (activeStep > 1) {
      handleBackBtnPress?.();
    }
  }

  const handleDialogConfirm = () => {
    handleNextBtnPress?.();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose?.({ isSubmit: false })}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "694px",
          width: "100%",
          border: '1px solid #98A2B3',
          borderRadius: "8px",
          background: `linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)`,
          boxShadow: "12.2236px 12.2236px 24.4473px rgba(0, 0, 0, 0.2), inset -12.2236px -12.2236px 20.3727px rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(28.2614px)",
          "::-webkit-scrollbar": {
            display: "none",
            height: 0,
            width: 0
          }
        },
      }}
    >
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%"
        }}
      >
        {/** Dialog Title and Subtitle */}
        <SoftBox
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #1D2939"
          padding="32px 32px 16px 32px"
          alignItems="baseline"
        >
          <SoftBox>
            <SoftTypography
              variant="h5"
              sx={{
                ...fontStyles,
                fontSize: "20px",
                lineHeight: "29px",
                marginBottom: "-6px"
              }}
            >
              {title}
            </SoftTypography>
            <SoftTypography
              variant="subtitle"
              sx={{
                ...fontStyles,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#FFF"
              }}
            >
              {subtitle}
            </SoftTypography>
          </SoftBox>

          {/** Close Icon */}
          <SoftButton
            variant="outlined"
            color="white"
            onClick={handleDialogClose}
            sx={{
              "& svg": { fontSize: "1rem !important" },
              borderRadius: "50%",
              border: "0.529px solid rgba(255, 255, 255, 0.2)",
              background: "none",
              minWidth: "max-content",
              minHeight: "max-content",
              width: "24px",
              height: "24px",
              padding: 0.05,
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>

        {/** Main Content */}
        <SoftBox sx={{ width: "100%", height: "100%", marginTop: "16px" }}>
          {/** Gradient Stepper Preview */}
          <SoftBox
            sx={{
              width: "503px",
              height: "75px",
              background: "radial-gradient(ellipse at bottom, rgba(158,119,237,1) -90%, rgba(26,25,26,1) 65%, rgba(26,25,26,1) 95%, rgba(26,25,26,1) 96%)",
              border: "1px dashed #475467",
              boxShadow: "0px 20px 24px -4px rgba(8, 9, 11, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.2)",
              borderRadius: "4px",
              margin: "auto",
              marginBottom: "32px"
            }}
          >
            {/** Stepper Preview */}
            <Stepper
              activeStep={activeStep}
              connector={null}
              sx={{
                height: "16px",
                width: "fit-content",
                margin: "auto",
                marginTop: "8px",
                "& .MuiStepLabel-iconContainer, .MuiStep-horizontal": {
                  padding: 0,
                }
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepperIcon}>&nbsp;</StepLabel>
                </Step>
              ))}
            </Stepper>

            <SoftBox sx={{ textAlign: "center" }}>
              <SoftTypography
                variant="body"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#FFF"
                }}
              >
                Setup Parameters
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          {/** Dialog Content */}
          {children}
        </SoftBox>

        {/** Dialog Footer Buttons */}
        <Grid container
          sx={{
            width: "100%",
            height: "100px",
            backgroundColor: "#2E2E2F",
            "& div": { padding: "32px" }}}
          >
          <Grid item xs={4} sx={{ paddingRight: "0 !important" }}>
            <SoftButton
              variant="outline"
              onClick={handleDialogBackStep}
              disabled={isDisabled}
              sx={{
                ...fontStyles,
                ...buttonStyles,
                color: "#CED4DA",
                border: "0.685293px solid rgba(233, 236, 239, 0.1)",
                boxShadow: "0px 0.685293px 1.37059px rgba(0, 0, 0, 0.05)",
                background: "rgba(255, 255, 255, 0.1)",
                "&:hover": { background: "rgba(255, 255, 255, 0.1)" }
              }}
            >
              {(activeStep === 1)
                ? btnNoLabel
                : "Back"
              }
            </SoftButton>
          </Grid>

          <Grid xs={4}>&nbsp;</Grid>

          <Grid item xs={4} sx={{ paddingLeft: "0 !important" }}>
            <SoftButton
              variant="outline"
              onClick={handleDialogConfirm}
              sx={{
                ...fontStyles,
                ...buttonStyles,
                color: "#344054",
                fontWeight: 700,
                letterSpacing: "-0.02em",
                background: "#F9FAFB",
                boxShadow: "0px 0.69px 1.37px 0px #0000000D",
                "&:hover": { background: "#F9FAFB" }
              }}
            >
              {(activeStep < steps.length)
                ? btnYesLabel
                : btnSubmitLabel
              }
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Dialog>
  );
};

export default SetupDialog;
