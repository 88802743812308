import React from "react";
import { styled } from "@mui/material/styles";
import SoftTypography from "../../../SoftTypography";

const FormErrorLabel = styled(SoftTypography)(({ theme }) => ({
  color: "#ea0606",
  fontSize: "12px",
  marginTop: "2px"
}))

export default FormErrorLabel;
