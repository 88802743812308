import { request } from "../helpers/axios.new";

export const CREATE_NOTIFICATIONS = (data) => {
  return request({
    method: "POST",
    url: "/notifications",
    data,
  });
};

export const GET_NOTIFICATIONS_BY_USERID = (userId) => {
  return request({
    method: "GET",
    url: `/notifications?userId=${userId}`,
  });
};

export const UPDATE_NOTIFICATION_READ_STATUS = (id) => {
  return request({
    method: "PATCH",
    url: `/notifications?notiId=${id}`,
  });
};

export const DELETE_NOTIFICATION = (id) => {
  return request({
    method: "DELETE",
    url: `/notifications?notiId=${id}`,
  });
};

export const UPDATE_ALL_NOTIFICATION_STATUS = (id, data) => {
  return request({
    method: "PATCH",
    url: `/notifications/update-all-status?userId=${id}`,
    data,
  });
};
