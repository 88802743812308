import React from "react";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";

const ChallengeConfirmation = () => {
  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="1.5rem"
      >
        <svg
          width="77"
          height="59"
          viewBox="0 0 77 59"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.6257 46.3966L6.72942 28.5003L0.635254 34.5516L24.6257 58.542L76.1257 7.04197L70.0744 0.990723L24.6257 46.3966Z"
            fill="#28D31A"
          />
        </svg>
      </SoftBox>
      <SoftTypography
        variant="h2"
        color="white"
        textAlign="center"
        marginBottom="1.5rem"
      >
        Challenge successfully created
      </SoftTypography>

      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
      >
        <SoftButton
          variant="outlined"
          color="white"
          sx={{
            width: "100%",
            textTransform: "none",
          }}
        >
          Send invites
        </SoftButton>
        <SoftButton
          variant="outlined"
          color="primary"
          sx={{
            width: "100%",
            textTransform: "none",
          }}
        >
          View Arena
        </SoftButton>
      </SoftBox>
    </>
  );
};

export default ChallengeConfirmation;
