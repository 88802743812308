import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import SoftTypography from "../../../../components/SoftTypography";
import SoftBox from "../../../../components/SoftBox";
import SoftInput from "../../../../components/SoftInput";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AccountPassword = ({
  label = "label",
  name = "name",
  disabled = false,
  handleRegister,
  errors
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Grid item mini={12} md={4} sx={{
      "& div": {
        borderRadius: "8px",
        backgroundColor: "#F9FAFB",
      }
    }}>
      <SoftTypography variant="h4">
        {label}
      </SoftTypography>

      <SoftBox sx={{ boxShadow: "0px 2px 3px 0px #0000000D" }}>
        <SoftInput
          name={name}
          placeholder={showPassword ? label : "*********"}
          type={showPassword ? "text" : "password"}
          autoComplete="off"
          disabled={disabled}
          {...handleRegister(name)}
          error={!!errors?.[name]}
          // helperText={errors?.[name]?.message}
          sx={{
            border: "none",
            borderRadius: "8px 0 0 8px !important",
            padding: "12px 20px",
            backgroundColor: "#F9FAFB !important",
            "& input": {
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#495057"
            },
          }}
          icon={{
            component: showPassword
              ? <VisibilityOff
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(prev => !prev)}
                />
              : <Visibility
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(prev => !prev)}
                />,
            direction: "right",
          }}
        />
      </SoftBox>

      {/** Error message helper text */}
      <SoftBox
        component="p"
        color="error"
        sx={{
          fontWeight: 400,
          fontSize: "0.75rem",
          lineHeight: 1.66,
          fontFamily: "DM Sans, Inter, Arial, sans-serif",
          marginTop: "3px"
        }}
      >
        {errors?.[name]?.message}
      </SoftBox>
    </Grid>
  );
};

export default AccountPassword;
