export const IDLE_TOOL_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/BriefcaseIdle.fbx";
export const IDLE_LOOKING_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/LookingIdle.fbx";
export const IDLE_HAPPY_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/HappyIdle.fbx";
export const HIPHOP_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/HipHopDance.fbx";

export const VICTORY1_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Victory1.fbx";
export const VICTORY2_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Victory2.fbx";
export const VICTORY3_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Victory3.fbx";

export const IDLE1_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Idle1.fbx";
export const IDLE2_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Idle2.fbx";
export const IDLE3_ANIM = "https://gmb-public.s3.us-east-2.amazonaws.com/anims/Idle3.fbx";