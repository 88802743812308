import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";

import startCase from "lodash/startCase";
import defaultCardMedia from "../../../img/cardmedia_default.png";
import { ReactComponent as RocketIcon } from "../../../img/streamline-emojis_rocket.svg";
import "./card.css";
import { display } from "@mui/system";

const JetpackCard = ({ values }) => {
  const data = useMemo(() => values, [values]);
  const navigate = useNavigate();

  return (
    <SoftBox sx={{ width: "100%", height: "auto" }}>
      <Card className="cardPaper">
        <SoftBox sx={{ padding: 2 }}>
          <SoftBox
            sx={{
              height: "150px",
              backgroundImage: `url(${data.image ?? defaultCardMedia})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </SoftBox>

        <CardContent className="cardContent">
          <div className="flex items-center">
            <SoftTypography className="cardTitle">
              {startCase(data.name || "Jetpack")}
            </SoftTypography>
            <RocketIcon width="24px" height="24px" />
          </div>
          <SoftTypography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              height: "70px",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {data.desc || "Missing Jetpack description"}
          </SoftTypography>
          <SoftBox
            mt="20px"
            display="flex"
            flexDirection="column"
            gap="10px"
            alignItems="center"
          >
            <SoftButton
              color="primary"
              variant="outlined"
              sx={{
                display: 'none',
                borderRadius: "4px",
                width: "max-content",
                textTransform: "none",
              }}
              onClick={() => window.open(data.url, "_blank")}
            >
              Start {data.trial}-day Free Trial
            </SoftButton>
            <SoftButton
              color="primary"
              variant="text"
              sx={{
                borderRadius: "4px",
                width: "max-content",
                textTransform: "none",
                color: "#475467",
                fontSize: "14px",
              }}
              onClick={() =>
                navigate(
                  `/hub/jetpacks/${encodeURIComponent(
                    data.name.replace(" ", "-"),
                  )}`,
                  { state: { data } },
                )
              }
            >
              Learn more
            </SoftButton>
          </SoftBox>
        </CardContent>
      </Card>
    </SoftBox>
  );
};

export default JetpackCard;
