import React, { useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Comment from "./Comment";
import { GET_IMAGE } from "../../helpers/images";
import defaultAvatar from "../../img/avatar2.png";
import { orderBy } from "lodash";

const CommentList = ({
  comments = [],
  currentUser,
  jobId,
  onAddComment = () => {},
  onToggleFavorite = () => {},
  onDeleteComment = () => {},
}) => {
  const [content, setContent] = useState("");

  const handleAddComment = () => {
    onAddComment(jobId, currentUser._id, content);
    setContent("");
  };

  const sortedComments = orderBy(comments, ["createdAt"], ["desc"]);
  return (
    <div className="grid grid-flow-row auto-rows-max gap-y-5 ">
      {sortedComments.map((comment) => (
        <Comment
          key={comment._id}
          jobId={jobId}
          comment={comment}
          currentUser={currentUser}
          onToggleFavorite={onToggleFavorite}
          onDeleteComment={onDeleteComment}
        />
      ))}

      <div className="flex">
        <div className="flex-none w-14 h-14">
          <img
            key={currentUser?._id}
            className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800"
            src={
              currentUser?.image ? GET_IMAGE(currentUser.image) : defaultAvatar
            }
            alt=""
          />
        </div>

        <div className="flex-auto">
          <div className="relative">
            <input
              type="text"
              className="p-[0.75rem] md:p-[1rem] pr-[3.75rem] md:pr-[4.75rem] w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write a comment..."
              onChange={(event) => setContent(event.target.value)}
              value={content}
            />
            <button
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-[0.5rem] py-[0.25rem] md:px-[1rem] md:py-[0.5rem] dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleAddComment}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentList;
