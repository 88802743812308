/* eslint-disable */

import React, { useState } from "react";
import { Modal, TextField } from "@material-ui/core";
import Search from "@mui/icons-material/Search";

import SoftTypography from "../../components/SoftTypography";
import { GET_IMAGE } from "../../helpers/images";
import SoftButton from "../../components/SoftButton";
import SoftAvatar from "../../components/SoftAvatar";
import LoadingScreen from "../../components/LoadingScreen/index";
import "./levelUpStyles.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
};

const AddBadgePopup = ({
  popUp,
  setPopUp,
  selectedItem,
  setSelectedItem,
  board,
  setBoard,
  isLoading,
  openPreview,
  setOpenPreview,
  masterBadges = [],
}) => {
  const [badges, setBadges] = useState(popUp.badgesCopy);
  const handleSelectItem = (item) => {
    setOpenPreview({
      open: true,
      id: item.id,
      template: { ...item, index: popUp.index },
      add: true,
      onAdd: (el) => onAddTrigger(el),
    });
  };

  const onAddTrigger = (el) => {
    const duplicate = { ...board };
    duplicate.columns[popUp.index].cards = [
      ...board.columns[popUp.index].cards,
      ...el.badges,
    ];
    setBoard(duplicate);
    setOpenPreview({
      open: false,
      id: null,
      template: null,
      add: null,
      onAdd: null,
    });
  };

  const cancelTrigger = () => {
    setPopUp({
      open: false,
      index: 0,
      badgesCopy: popUp.index,
    });
    setSelectedItem({});
  };

  const onChangeTrigger = (e) => {
    const filterList = popUp.badgesCopy.filter(
      (item) =>
        item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1,
    );

    setBadges(filterList);
  };

  return (
    <Modal
      open={popUp.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftTypography
          component="h6"
          className="text-black mb-3"
          sx={{ fontSize: "18px", fontWeight: "400" }}
        >
          Choose Badges
        </SoftTypography>
        <div className="mb-3 input-radius">
          <TextField
            type="text"
            variant="outlined"
            onChange={onChangeTrigger}
            placeholder="Search Badge here..."
            InputProps={{
              startAdornment: (
                <Search style={{ marginRight: "0px", color: "#67748E" }} />
              ),
            }}
            style={{ width: "100%" }}
          />
        </div>
        {isLoading ? (
          <LoadingScreen height="200px" logoHeight="200px" />
        ) : !badges.length ? (
          <>
            <SoftTypography
              component="h6"
              className="text-black mb-3"
              sx={{ fontSize: "16px", fontWeight: "600" }}
            >
              No badges found!
            </SoftTypography>
            <div className="my-3">
              <SoftButton
                size="small"
                variant="outlined"
                onClick={cancelTrigger}
                className="cancelBtn"
              >
                Cancel
              </SoftButton>
            </div>
          </>
        ) : (
          <>
            {badges.map((item, i) => (
              <div
                key={item._id}
                onClick={() => handleSelectItem(item, i)}
                className={`flex items-center py-2 px-3 cursor-pointer mb-3 ${
                  selectedItem[i]?.id === item._id ? "selected-shadow" : ""
                }`}
                style={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: "9px",
                }}
              >
                <SoftAvatar
                  src={GET_IMAGE(item.image)}
                  alt={item.target.toString()}
                  size="xs"
                  sx={{
                    ml: -1,
                  }}
                />
                <SoftTypography
                  component="p"
                  className="text-black ml-2 mb-3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px !important",
                    padding: "0px !important",
                  }}
                >
                  {item.title}
                </SoftTypography>
              </div>
            ))}
            <div
              className="my-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <SoftButton
                size="small"
                variant="gradient"
                onClick={cancelTrigger}
                className="addBtn"
              >
                Cancel
              </SoftButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddBadgePopup;
