import React, { useState } from "react";

import { Box, Button } from "@mui/material";
import SoftInput from "../../SoftInput";

import { configureIntegration } from "../../../redux/kpi/actions";
import { useDispatch } from "react-redux";
import { GET_IMAGE } from "../../../helpers/images";
import startCase from "lodash/startCase";
import toast from "react-hot-toast";

const AppSettings = ({
  integrationId,
  logo,
  code,
  parameters,
  handleClose,
}) => {
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const setValue = (param, value) => {
    const tempParams = { ...params };
    tempParams[param] = value;
    setParams(tempParams);
  };

  const handleSubmit = () => {
    let valid = true;
    parameters.forEach((p) => {
      if (!params[p]) {
        toast.error(`${startCase(p)} has not been defined.`);
        valid = false;
      }
    });

    if (valid) {
      dispatch(
        configureIntegration({
          integrationId,
          code,
          parameters: params,
        }),
      );
    }

    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className="flex flex-col items-center p-0">
        <img src={GET_IMAGE(logo)} alt={code} className="w-[4rem] h-[4rem]" />
        <Box className="w-full p-2">
          {parameters?.map((p) => (
            <SoftInput
              key={p}
              className="w-full my-4"
              placeholder={`Enter ${startCase(p)}`}
              onChange={(e) => setValue(p, e.target.value)}
            />
          ))}
          <Box className="grow w-full flex justify-end">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className="!normal-case !bg-white"
            >
              Connect
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default AppSettings;
