import React from "react";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";

function PromoCard({ color, image, description, button, action }) {
  const template = (
    <Card
      sx={({
        functions: { rgba, linearGradient },
        palette: { gradients },
        borders: { borderRadius },
      }) => ({
        backgroundImage: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.8),
              rgba(gradients[color].state, 0.8),
            )}, url(${image})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8),
            )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.lg,
      })}
    >
      <SoftBox textAlign="center" py={6} px={3} lineHeight={1}>
        <SoftBox mb={3}>
          <SoftTypography variant="h6" color="white">
            {description}
          </SoftTypography>
        </SoftBox>
        {button && (
          <SoftButton variant="text" color="light">
            {button} <ArrowForwardRoundedIcon />
          </SoftButton>
        )}
      </SoftBox>
    </Card>
  );

  return action.type === "external" ? (
    <MuiLink href={action.route} target="_blank" rel="noreferrer">
      {template}
    </MuiLink>
  ) : (
    <Link to={action.route}>{template}</Link>
  );
}

// Setting default values for the props of DefaultBackgroundCard
PromoCard.defaultProps = {
  color: "dark",
  button: "",
  action: false,
};

// Typechecking props for the DefaultBackgroundCard
PromoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  image: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  button: PropTypes.string,
  action: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
};

export default PromoCard;
