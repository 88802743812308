import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getPageProperties } from "../../routes/routes";

import {
  UseUserDetails,
  UseIsAdmin,
  UseIsAffiliate,
  UseIsPrime,
  UseIsDemo,
} from "../../hooks/auth";
import {
  UseCompanyLogo,
  UseCompanyName,
  UseCompanyLoading,
  UseSelectedWorkspace,
  UseSubscriptionLevel,
} from "../../hooks/company";
import { UseUnclaimedCount } from "../../hooks/claims";

import Sidenav from "../../components/Sidenav";
import BottomNav from "../../components/BottomNav";
import Blackout from "../../components/Blackout";
import WorkspacePopover from "../../components/WorkspaceDrawer/WorkspacePopover";
import NavProfile from "../../components/ProfilePictures/NavProfile";
import TextFeedback from "../../components/TextFeedback";
import DashboardNavbar from "../../components/DashboardNavbar";
import LoadingScreen from "../../components/LoadingScreen";

import Box from "@mui/material/Box";

import adminRoutes from "../../routes/adminNavRoutes";
import playerRoutes from "../../routes/playerNavRoutes";

import gmbNavbarLogo from "../../img/gmb-nav-round.png";
import UpgradeModal from "../../components/Billing/UpgradeModal";

function Main() {
  const { image, firstName, lastName, isAdmin: isTrueAdmin } = UseUserDetails();
  const isLoading = UseCompanyLoading();
  const isAdmin = UseIsAdmin();
  const isPrime = UseIsPrime();
  const isDemo = UseIsDemo();
  const isAffiliate = UseIsAffiliate();
  const logo = UseCompanyLogo();
  const company = UseCompanyName();
  const workspace = UseSelectedWorkspace();
  const isSubscribed = UseSubscriptionLevel();
  const [badges, setBadges] = useState({});
  const unclaimed = UseUnclaimedCount();
  const [isUpgradeRequired, setIsUpgradeRequired] = useState(false);
  const location = useLocation();
  const [pageProps, setPageProps] = useState(null);

  useEffect(() => {
    if (unclaimed >= 0) {
      setBadges((prevValue) => ({ ...prevValue, claims: unclaimed }));
    }
  }, [unclaimed]);

  useEffect(() => {
    // Workaround to fetch page properties; props aren't easily accessible on Main layout since props data belongs to its child component
    if (location.pathname) {
      const props = getPageProperties({ path: location.pathname });
      const upgradeFlag = !props?.isFreemium && !isSubscribed; // Restricted access for freemium users
      setIsUpgradeRequired(upgradeFlag);
      setPageProps(props);
    }
  }, [location.pathname, isDemo, isSubscribed]);

  return (
    <>
      {/* Main Page Component */}
      <Box
        sx={{
          display: "flex",
          pointerEvents: isUpgradeRequired ? "none" : "auto",
        }}
      >
        {!pageProps?.hideSideNav ? (
          <Sidenav
            color="primary"
            brand={logo ?? gmbNavbarLogo}
            routes={isAdmin ? adminRoutes : playerRoutes}
            brandName={company || "Game My Biz"}
            headerMenu={isTrueAdmin ? <WorkspacePopover /> : null}
            badges={badges}
            isPrime={isPrime}
            isAdmin={isAdmin}
            isSubscribed={isSubscribed}
            isAffiliate={isAffiliate}
            subheader={workspace?.name}
            profile={
              <NavProfile
                image={image}
                firstName={firstName}
                lastName={lastName}
                isAdmin={isAdmin}
              />
            }
          />
        ) : null}

        <Box
          className="flex flex-col grow relative max-w-[100vw] overflow-x-hidden overflow-y-visible"
          sx={{ background: "#F8F9FA" }}
        >
          {!pageProps?.hideTopbar ? (
            <DashboardNavbar absolute={true} light={true} />
          ) : null}
          <Box className="grow min-h-[100vh]">
            {isLoading ? <LoadingScreen /> : <Outlet />}
          </Box>
        </Box>
        <Blackout />
        <BottomNav badges={badges} />
        {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
        <TextFeedback />
      </Box> */}
      </Box>

      {/* Upgrade overlay that blocks the Main component */}
      {!isLoading && isUpgradeRequired && <UpgradeModal />}
    </>
  );
}

export default Main;
