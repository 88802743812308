import { types } from "./types";

export const getChats = (payload) => ({
  type: types.GET_CHATS,
  payload,
});

export const setSelectedChat = (payload) => ({
  type: types.SET_SELECTED_CHAT,
  payload,
});

export const fetchMessages = (payload) => ({
  type: types.GET_MESSAGES,
  payload,
});

export const addChatMessage = (payload) => ({
  type: types.ADD_CHAT_MESSAGE,
  ...payload,
});

export const createChat = (payload) => ({
  type: types.CREATE_CHAT,
  payload,
});

export const createGroupChat = (payload) => ({
  type: types.CREATE_GROUP_CHAT,
  payload,
});

export const sendMessageToChat = (payload) => ({
  type: types.SEND_MESSAGE_TO_CHAT,
  payload,
});

export const renameGroup = (payload) => ({
  type: types.RENAME_GROUP_CHAT,
  payload,
});

export const updateGroupUsers = (payload) => ({
  type: types.UPDATE_GROUP_USERS_CHAT,
  payload,
});
