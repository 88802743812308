import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../SoftBox";
import SoftInput from "../../../SoftInput";
import SoftTypography from "../../../SoftTypography";

// ChallengeArena Form Components
import FormPageLayout from "../Layout/FormPageLayout";
import FormButton from "../Layout/FormButton";
import FormSection from "../Layout/FormSection";
import FormTextSummary from "../Layout/FormTextSummary";
import ArenaThemeSelectorModal from "../Components/ArenaThemeSelector";

import { errorOnFocusStyles } from "../styles";

const PrizeThemeForm = () => {
  const [isPrizeInputVisible, togglePrizeInput] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");

  const {
    register,
    clearErrors,
    resetField,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const PRIZE_FIELDS = {
    WINNER: "prizeForWinner",
    SECOND: "prizeforSecondPlace",
    THIRD: "prizeforThirdPlace",
    PARTICIPANTS: "prizeforParticipants"
  };

  useEffect(() => {
    // Restore the "opened" price input fields
    const prizeValues = getValues(Object.values(PRIZE_FIELDS)) ?? [];

    if (prizeValues.filter(prize =>
      ![undefined, ""].includes(prize))?.length > 0
    ) {
      togglePrizeInput(true);
    }
  }, [PRIZE_FIELDS]);

  const handleOpenPrize = () => {
    togglePrizeInput(prev => !prev);

    if (isPrizeInputVisible) {
      Object.values(PRIZE_FIELDS).forEach(prize => {
        resetField(prize);
      });
    }
  }

  const handleSelectTheme = () => {
    setValue("image", selectedTheme);
  }

  return (
    <FormPageLayout sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
      {/** Prizes */}
      <FormSection>
        <SoftTypography variant="body2">
          Prize for Winners
        </SoftTypography>
        <FormButton
          placeholder="Click to setup prize"
          onClick={handleOpenPrize}>
          {(!isPrizeInputVisible)
            ? "Click to setup prize"
            : "Remove price"
          }
        </FormButton>
          
        <Collapse in={isPrizeInputVisible}>
          <SoftBox
            sx={{
              width: "100%",
              padding: "1rem",
              borderRadius: "4px",
              border: "1px solid #CED4DA",
              "& .MuiGrid-item": {
                width: "100%"
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftInput
                  placeholder="Enter prize for winner"
                  type="text"
                  autoComplete="off"
                  {...register(PRIZE_FIELDS.WINNER)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftInput
                  placeholder="Enter prize for 2nd place"
                  type="text"
                  autoComplete="off"
                  {...register(PRIZE_FIELDS.SECOND)}
                />
              </Grid>
              {(getValues("category") === "CompanyGroups") &&
              <>
                <Grid item xs={12} sm={6}>
                  <SoftInput
                    placeholder="Enter prize 3rd place"
                    type="text"
                    autoComplete="off"
                    {...register(PRIZE_FIELDS.THIRD)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftInput
                    placeholder="Enter prize for participants"
                    type="text"
                    autoComplete="off"
                    {...register(PRIZE_FIELDS.PARTICIPANTS)}
                  />
              </Grid>
              </>
              }
            </Grid>
          </SoftBox>
        </Collapse>
      </FormSection>

      {/** Arena Theme Selector */}
      <SoftBox>
        <SoftTypography variant="body2">
          Select Theme for your Arena
        </SoftTypography>
        <FormTextSummary
          sx={{
            padding: "0.5rem",
            "& button": {
              maxWidth: "max-content",
              lineHeight: "normal"
            }
          }}
        >
          {/** Arena Theme Selector Modal */}
          <ArenaThemeSelectorModal
            maxWidth="md"
            title="Arena Theme Selector"
            yesButtonLabel="Select"
            handleYesClick={handleSelectTheme}
            componentCallbackHandler={(url) => setSelectedTheme(url)}
          >
            <FormButton size="small">
              Select from Library
            </FormButton>
          </ArenaThemeSelectorModal>
        </FormTextSummary>
      </SoftBox>

      {/** Start/End Date */}
      <FormSection sx={errorOnFocusStyles(errors?.description)}>
        <SoftTypography variant="body2">
          Challenge Description
        </SoftTypography>
        <SoftInput
          placeholder="Test your limits"
          type="text"
          multiline
          minRows={4}
          autoComplete="off"
          {...register("description")}
          error={!!errors?.description}
          helperText={errors?.description?.message}
          onChange={() => {
            if (errors?.description?.message !== "") {
              clearErrors("description");
            }
          }}
        />
      </FormSection>
    </FormPageLayout>
  )
};

export default PrizeThemeForm;
