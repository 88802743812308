import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Stack, Box } from "@mui/material";
import { FETCH_JOB } from "../../api/jobs";
import FeedItem from "./FeedItem";
import LoadingScreen from "../../components/LoadingScreen";
import SoftTypography from "../../components/SoftTypography";

const SingleFeed = () => {
  const { jobId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState({});

  const fetchJob = async () => {
    const data = await FETCH_JOB(jobId);
    console.log(data.data);
    setJob(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchJob();
  }, []);

  return (
    <div>
      <div className="container my-10">
        <Box
          sx={{
            padding: "1.1rem 1.5rem",
            background: (theme) => theme.palette.primary.main,
            borderRadius: "20px",
          }}
        >
          <SoftTypography
            variant="h2"
            color="white"
            sx={{ marginBottom: "0.25rem" }}
          >
            Team Feed
          </SoftTypography>
          <SoftTypography
            variant="body2"
            sx={{ color: "rgba(255, 255, 255, 0.6)" }}
          >
            Feed is like an instant office in your Game My Biz dashboard.
          </SoftTypography>
        </Box>

        <div className="feedContainer flex justify-center">
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <Stack className="flex items-center">
              <FeedItem job={job} />
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleFeed;
