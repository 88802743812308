import React from "react";
import PageHeader from "../../components/PageHeader";
import WorkspaceKpi from "../../components/WorkspaceKpi";
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import { UseSelectedWorkspace } from "../../hooks/company";

const KpiSettings = () => {
  const workspace = UseSelectedWorkspace();

  return (
    <>
      <PageHeader
        headerText={`${workspace.name ?? "Workspace"} KPI's`}
        subheaderText=" Get real-time insights into your key performance indicators"
      />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        {/* Page Content */}
        <Grid container>
          <Grid item mini={12}>
            <SoftBox
              sx={{ margin: "1rem 0rem 1rem", display: "block !important" }}
            >
              <WorkspaceKpi />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default KpiSettings;
