import React from "react";

import Box from "@mui/material/Box";
import SoftTypography from "../SoftTypography";
import SoftProfile from "../ProfilePictures/SoftProfile";

import PODIUMS from "./config";

const PLACES = [1, 0, 2];

const ColoredPodiums = ({ darkMode, players }) => {
  return (
    <Box
      container
      className="w-full h-full px-[10%] py-[2%] aspect-video flex justify-center"
    >
      {PLACES.map((rank, idx) => {
        const podium = PODIUMS[rank];
        const player = players[rank];
        return (
          <Box
            key={`PODIUM${rank + 1}`}
            className="w-1/3 flex flex-col justify-end items-center relative"
          >
            {player && (
              <>
                <img
                  src={podium.crown}
                  alt="Winner's Crown"
                  width="20%"
                  height="auto"
                />
                <SoftProfile
                  size="xxl"
                  name={player?.firstName}
                  src={player?.image}
                />
                <Box width="100%">
                  <SoftTypography
                    variant="body2"
                    color={darkMode ? "white" : "primary"}
                    className="mt-[5%] mb-[8%] text-center"
                    sx={{ fontSize: "1.5vh" }}
                  >
                    {player?.firstName} {player?.lastName}
                  </SoftTypography>
                </Box>
              </>
            )}
            <Box // Colored Podiums Box
              className="w-full flex flex-col items-center px-[1%] transition-height duration-500"
              sx={{ height: podium.height }}
            >
              <Box className="flex flex-col flex-1 w-full text-white">
                <Box
                  sx={{
                    width: "100%",
                    height: 0,
                    ...(idx !== 0 && {
                      borderRight: "1.5rem solid transparent",
                    }),
                    ...(idx !== 2 && {
                      borderLeft: "1.5rem solid transparent",
                    }),
                    borderBottomWidth: "2.25rem",
                    borderBottomStyle: "solid",
                    borderBottomColor: `${podium.stroke}`,
                  }}
                />
                <Box
                  className="flex-1 w-full flex flex-col items-center"
                  sx={{
                    border: `0.25rem solid ${podium.border}`,
                    background: `${podium.background}`,
                    backdropFilter: "blur(1rem)",
                    boxShadow: `${podium.shadow}`,
                  }}
                >
                  <Box sx={{ mt: "5%", width: podium.orderWord.width }}>
                    {podium.orderWord.asset}
                  </Box>
                </Box>
                <Box className="w-full h-[7%] absolute bottom-[5%] flex justify-center items-center">
                  <Box
                    className="w-[45%] px-[12%] h-full flex justify-center items-center font-bold text-white rounded-full"
                    sx={{
                      background: "rgba(255, 255, 255, 0.16)",
                    }}
                  >
                    <SoftTypography color="inherit" sx={{fontSize: "1.75vh" }}>
                      {Math.floor(player?.scores?.total ?? 0) }
                    </SoftTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ColoredPodiums;
