import { types, initState } from "./types";

const getCompanyUsersReducer = (state = initState, action) => {
	switch (action.type) {
		case types.RESET_COMPANY_INFO:
			// Sync updated state to user login
			return { ...state, error: null, updated: false, isLoading: true }
		case types.GET_COMPANY_REQUEST:
			return { ...state,  error: null, isLoading: true };
		case types.GET_COMPANY_SUCCESS:
			return {
				...state,
				isLoading: false,
				company: action.company,
				updated: true,
			};
		case types.GET_COMPANY_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.error
			};
		case types.GET_COMPANY_HOLIDAYS_SUCCESS:
			return {
				...state,
				holidays: action.holidays,
			};
		case types.GET_COMPANY_HOLIDAYS_FALED:
			return {
				...state,
				error: action.error
			};
		case types.SET_COMPANY_SUCCESS:
			return {
				...state,
				company: action.payload
			};
		case types.GET_COMPANY_USERS_REQUEST:
			return { ...state, error: null, isLoading: true };
		case types.GET_COMPANY_USERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				usersDictionary: action.usersDictionary,
				usersList: action.usersList
			};
		case types.GET_COMPANY_USERS_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.error
			};
		case types.UPDATE_MY_COMPANY_PROFILE:
			return {
				...state,
				usersDictionary: { ...state.usersDictionary, ...action.myProfile }
			};
		case "UPDATE_COMPANY_DETAILS":
			return {
				...state,
				company: {...state.company, ...action.data},
			};
		case "UPDATE_LOGO":
			return {
				...state,
				company: { ...state.company, logo: action.payload }
			};
		case "UPDATE_SHOW_BUSINESS_NAME":
			return {
				...state,
				company: { ...state.company, showBusinessName: !action.payload }
			};
		case "PREVIEW_PRIMARY_COLOR":
			return {
				...state,
				company: { ...state.company, primaryColor: action.payload }
			};
		case "PREVIEW_SECONDARY_COLOR":
			return {
				...state,
				company: { ...state.company, secondaryColor: action.payload }
			};
		default:
			return state;
	}
};

export default getCompanyUsersReducer;
