import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import { types } from "./types";
import {
  FETCH_CHATS,
  CREATE_CHAT,
  CREATE_GROUP_CHAT,
  RENAME_GROUP_CHAT,
  ADD_PARTICIPANTS_TO_OR_REMOVE_FROM_GROUP_CHAT,
} from "../../api/chats";
import { getChats, setSelectedChat } from "./actions";
import { SEND_MESSAGE, FETCH_MESSAGES } from "../../api/message";
import socket from "../../configs/socket";
import socketConstants from "../../constants/socket";

function* GetChats(action) {
  try {
    const res = yield call(() => FETCH_CHATS());

    const { data } = res;

    yield put({
      type: types.GET_CHATS_SUCCESS,
      chats: data,
    });

    if (action.selectedHandler) {
      action.selectedHandler(0);
    }
  } catch (err) {
    yield put({ type: types.GET_CHATS_FAILED, error: err.message });
  }
}

function* GetMessages(action) {
  try {
    const { id, page } = action.payload;

    const {
      data: { messages, hasMoreMessages, currentMessagePage },
    } = yield call(() => FETCH_MESSAGES(id, page));

    yield put({
      type: types.GET_MESSAGES_SUCCESS,
      messages,
      hasMoreMessages,
      currentMessagePage,
    });
  } catch (err) {
    yield put({ type: types.GET_MESSAGES_FAILED, error: err.message });
  }
}

function* CreateChat(action) {
  try {
    const { data } = yield call(() => CREATE_CHAT(action.payload));

    yield put({
      type: types.CREATE_CHAT_SUCCESS,
      chat: data,
    });
  } catch (err) {
    yield put({ type: types.CREATE_CHAT_FAILED, error: err.message });
  }
}

function* CreateGroupChat(action) {
  try {
    const { data } = yield call(() => CREATE_GROUP_CHAT(action.payload));

    yield put({
      type: types.CREATE_GROUP_CHAT_SUCCESS,
      chat: data,
    });
    toast.success("Group chat created successfully!");
  } catch (err) {
    yield put({ type: types.CREATE_GROUP_CHAT_FAILED, error: err.message });
  }
}

function* SendMessageToChat(action) {
  try {
    const { data } = yield call(() => SEND_MESSAGE(action.payload.formData));

    yield put({
      type: types.SEND_MESSAGE_TO_CHAT_SUCCESS,
      message: [data],
    });

    yield put({
      type: types.GET_CHATS,
      loading: false,
      selectedHandler: action.payload.selectedHandler,
    });

    const { SOCKET_EVENT } = socketConstants;
    socket.emit(SOCKET_EVENT.NEW_MESSAGE, data);
  } catch (err) {
    yield put({ type: types.SEND_MESSAGE_TO_CHAT_FAILED, error: err.message });
  }
}

function* RenameGroup(action) {
  try {
    const { data } = yield call(() => RENAME_GROUP_CHAT(action.payload));

    yield put({
      type: types.RENAME_GROUP_CHAT_SUCCESS,
    });

    yield put(getChats());
    yield put(setSelectedChat(data));
    toast.success("Group renamed successfully!");
  } catch (err) {
    yield put({ type: types.RENAME_GROUP_CHAT_FAILED, error: err.message });
  }
}

function* UpdateGroupUsers(action) {
  try {
    const { data } = yield call(() =>
      ADD_PARTICIPANTS_TO_OR_REMOVE_FROM_GROUP_CHAT(action.payload),
    );

    yield put({
      type: types.UPDATE_GROUP_USERS_CHAT_SUCCESS,
    });

    yield put(getChats());
    yield put(setSelectedChat(data));
    toast.success("Group updated successfully!");
  } catch (err) {
    yield put({
      type: types.UPDATE_GROUP_USERS_CHAT_FAILED,
      error: err.message,
    });
  }
}

function* watchFetchChatsRequest() {
  yield takeEvery(types.GET_CHATS, GetChats);
  yield takeEvery(types.GET_MESSAGES, GetMessages);
  yield takeEvery(types.CREATE_CHAT, CreateChat);
  yield takeEvery(types.CREATE_GROUP_CHAT, CreateGroupChat);
  yield takeEvery(types.SEND_MESSAGE_TO_CHAT, SendMessageToChat);
  yield takeEvery(types.RENAME_GROUP_CHAT, RenameGroup);
  yield takeEvery(types.UPDATE_GROUP_USERS_CHAT, UpdateGroupUsers);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchFetchChatsRequest)]);
}

export default rootSaga;
