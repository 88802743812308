import { types } from "./types";

export const getCompany = () => ({
  type: types.GET_COMPANY_REQUEST,
});

export const getCompanyUsers = () => ({
  type: types.GET_COMPANY_USERS_REQUEST,
});

export const getCompanyHolidays = () => ({
  type: types.GET_COMPANY_HOLIDAYS_REQUEST,
});

export const updateCompany = (payload) => ({
  type: "UPDATE_COMPANY_DETAILS_SAGA",
  payload,
});

export const setCompany = (payload) => ({
  type: types.SET_COMPANY_REQUEST,
  payload,
});

export const updateLogo = (payload) => ({
  type: "UPDATE_LOGO",
  payload,
});

export const updateShowBusinessName = (payload) => ({
  type: "UPDATE_SHOW_BUSINESS_NAME",
  payload,
});

export const previewPrimaryColor = (payload) => ({
  type: "PREVIEW_PRIMARY_COLOR",
  payload,
});

export const previewSecondaryColor = (payload) => ({
  type: "PREVIEW_SECONDARY_COLOR",
  payload,
});
