import ErrorPage from "../components/Error";
import AcctSettings from "../pages/AccountSettings/AccountSettingsV2";
import Avatar from "../pages/Avatar";
import ClaimsPage from "../pages/Claims";
import CompanySettings from "../pages/CompanySettings";
import Calendar from "../pages/CompanySettings/Calendar";
import KpiSettings from "../pages/CompanySettings/KpiSettings";
import CreateCompany from "../pages/CreateCompany";
import FeedPage from "../pages/Feed/FeedPage";
import FeedbackViewer from "../pages/FeedbackViewer";
import ForgotPassword from "../pages/ForgotPassword";
import Homepage from "../pages/Homepage";
import HubPage from "../pages/Hub/UserHub";
import LevelUp from "../pages/LevelUp";
import Login from "../pages/Login/Login";
import LoginCL from "../pages/Login/LoginCL";
import ManualEntry from "../pages/ManualEntry";
import Messages from "../pages/Messages";
import Register from "../pages/Register/Register";
import ResetPassword from "../pages/ResetPassword";
import ScoreKeeper from "../pages/ScoreKeeper";
import Scorecard from "../pages/Scorecard";
import UserMngmt from "../pages/UserManagement/UserManagement";
import FreemiumPage from "../pages/Freemium";
import FreemiumScoreboard from "../pages/Freemium/FreemiumScoreboard";
import GoogleBusinessWindow from "../pages/GoogleBusiness/GoogleBusinessWindow";
import AuthWindow from "../pages/AuthWindow";
import GameboardLibrary from "../pages/GameboardSettings/GameboardLibrary";
import Payment from "../pages/Payment";
import Billing from "../pages/Billing";
import SingleFeedPage from "../pages/Feed/SingleFeed";
import ManageWidgets from "../pages/Widgets";
import Pricing from "../pages/Pricing/Pricing";
import Signout from "../components/Signout";
import BadGuy from "../pages/BadGuy";
import Affiliate from "../pages/Affiliate";
import FreeTrial from "../pages/FreeTrial";
import ThankYou from "../components/ThankYou/ThankYouPage";
import OneVOneChallenge from "../pages/1v1Challenge";
import CompanyView from "../pages/CompanyView/CompanyView";
import ChallengeArena from "../pages/ChallengeArena";
import CreateChallenge from "../pages/CreateChallenge";
import PlaceholderComponent from "../pages/ChallengeArena/PlaceholderComponent";
import TVLeaderboard from "../pages/LiveTV/TVLeaderboard";
import Timeline from "../pages/Timeline/Timeline";
import JetpackContent from "../pages/Hub/JetpackContent";
import DemoPage from "../pages/Demo";
import PlaybookEditor from "../pages/Playbook/PlaybookEditor";
import PlaybookViewer from "../pages/Playbook/PlaybookViewer";

// Temporary integration pages; these should be unified in one page
import ZapierIntegrations from "../pages/Integrations/Zapier/Integrations";
import ZapierAuthentication from "../pages/Integrations/Zapier/Authentication";
import ZapierSetup from "../pages/Integrations/Zapier/Setup";
import ApideckIntegrations from "../pages/Integrations/Apideck/Integrations";
import ExploreCustomMetricsTypes from "../pages/CompanySettings/ExploreCustomMetricsTypes";

/**
 * Route Properties
 *
 * * Access-Related Properties *
 * isPublic: no login required
 * isFreemium: login required, but subscriptiom is not required
 * isDemo: login required, but page user has to be a "demo" user (All demo users belong to one fake company)
 * isPrime: login required, but 'Prime' company membership is required (Prime are users with full access to even experimental features)
 *
 * * Layout-Related Properties *
 *  hideSideNav: hides left side navbar when page is shown,
 *  hideTopbar: hides top bar when page is shown,
 */

const routes = [
  {
    key: "error",
    path: "*",
    element: ErrorPage,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "forgot-password",
    path: "/forgot-password",
    element: ForgotPassword,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    element: ResetPassword,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "login-home",
    path: "/",
    element: Login,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "login",
    path: "/login",
    element: Login,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "login",
    path: "/login-cl/",
    element: LoginCL,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "register",
    path: "/register",
    // Removed original registration page and used free-trial sign-up instead
    element: FreeTrial,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "home",
    path: "/home",
    element: Homepage,
    isPublic: false,
    isAdmin: false,
    isDemo: true,
  },
  {
    key: "dashboard",
    path: "/dashboard",
    element: Homepage,
    isPublic: false,
    isAdmin: false,
    isDemo: true,
  },
  {
    key: "company",
    path: "/company",
    element: CreateCompany,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "scorecard",
    path: "/scorecard",
    element: Scorecard,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "level-up",
    path: "/settings/level-up",
    element: LevelUp,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "hub",
    path: "/hub",
    element: HubPage,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "scorekeeper-timeline",
    path: "/scorekeeper/timeline",
    element: Timeline,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "account-settings",
    path: "/account-settings",
    element: AcctSettings,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "avatar",
    path: "/avatar",
    element: Avatar,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "claims",
    path: "/claims",
    element: ClaimsPage,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "feed",
    path: "/feed",
    element: FeedPage,
    isPublic: false,
    isAdmin: false,
    isDemo: true,
  },
  {
    key: "single-feed",
    path: "/feed/:jobId",
    element: SingleFeedPage,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "scorekeeper",
    path: "/scorekeeper",
    element: ManualEntry,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "account-settings",
    path: "/account-settings",
    element: AcctSettings,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "company-settings",
    path: "/settings/company-details",
    element: CompanySettings,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "settings",
    path: "/settings",
    element: CompanySettings,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "workspace-kpi",
    path: "/gameboards/workspace-kpi",
    element: KpiSettings,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "workspace-kpi",
    path: "/gameboards/workspace-kpi/custom-metric-types",
    element: ExploreCustomMetricsTypes,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "manage-widgets",
    path: "/widgets",
    element: ManageWidgets,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "user-feedback",
    path: "/user-feedback",
    element: FeedbackViewer,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "level-up",
    path: "/levelup",
    element: LevelUp,
    isPrime: true,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "players",
    path: "/settings/players",
    element: UserMngmt,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "calendar",
    path: "/calendar",
    element: Calendar,
  },
  {
    key: "messages",
    path: "/messages",
    element: Messages,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "bad-guy",
    path: "/gameboards/bad-guy",
    element: BadGuy,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "join-challenge",
    path: "/join",
    element: FreemiumPage,
    isPublic: false, // page deactivated for public
    isAdmin: false,
    isFreemium: true,
  },
  {
    key: "join-challenge-url",
    path: "/join/:challengeUrl",
    element: FreemiumPage,
    isPublic: false, // page deactivated for public
    isAdmin: false,
    isFreemium: true,
  },
  {
    key: "accept-challenge",
    path: "/accept-challenge",
    element: FreemiumPage,
    isPublic: false, // page deactivated for public
    isAdmin: false,
  },
  {
    // Public arena route; the challenge identifier is a url param
    key: "arena-public",
    path: "/arena/:challengeUrl",
    element: FreemiumScoreboard,
    isPublic: false, // page deactivated for public
    isAdmin: false,
  },
  {
    // Semi-public arena route; the challenge identifier is retrieved from user auth
    key: "arena",
    path: "/arena",
    element: FreemiumScoreboard,
    isPublic: false, // page deactivated for public
    isAdmin: false,
    isFreemium: true,
  },
  {
    key: "no-challenge",
    path: "/challenge-not-found",
    element: ErrorPage,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "gameboard-library",
    path: "/gameboards/gameboard-library",
    element: GameboardLibrary,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "gameboards",
    path: "/gameboards",
    element: GameboardLibrary,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "google-business",
    path: "/google/business",
    element: GoogleBusinessWindow,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "auth",
    path: "/auth/:type",
    element: AuthWindow,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "payment",
    path: "/payment",
    element: Payment,
    isPublic: false,
    isAdmin: true,
    isFreemium: true,
    hideSideNav: true,
    hideTopbar: true,
  },
  {
    key: "billing",
    path: "/settings/billing",
    element: Billing,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "zapier-authentication",
    path: "/zapier-auth",
    element: ZapierAuthentication,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "zapier-setup",
    path: "/zapier-setup",
    element: ZapierSetup,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "apideck-integrations",
    path: "/apideck",
    element: ApideckIntegrations,
    isPublic: false,
    isAmin: true,
  },
  {
    key: "pricing",
    path: "/pricing",
    element: Pricing,
    isPublic: false,
    isAdmin: true,
    isFreemium: true,
  },
  {
    // Duplicated OG pricing page to have a specific URL for upgrade
    // This might later diverge to a diff page than OG Pricing page
    key: "upgrade",
    path: "/upgrade",
    element: Pricing,
    isPublic: false,
    isAdmin: true,
    isFreemium: true,
    hideSideNav: true,
    hideTopbar: true,
  },
  {
    key: "signout",
    path: "/signout",
    element: Signout,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "affiliates",
    path: "/affiliates",
    element: Affiliate,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "free-trial",
    path: "/free-trial",
    element: FreeTrial,
    isPrime: false,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "thank-you",
    path: "/thank-you",
    element: ThankYou,
    isPrime: false,
    isPublic: true,
    isAdmin: false,
  },
  {
    key: "challenge",
    path: "/1v1-challenge",
    element: OneVOneChallenge,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
  },
  {
    key: "company-view",
    path: "/company-view",
    element: CompanyView,
    isPrime: true,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "challenge-arena",
    path: "/challenge-arena",
    element: ChallengeArena,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
    isFreemium: true,
    isDemo: true,
  },
  {
    key: "create-challenge",
    path: "/challenge-arena/create-challenge",
    element: CreateChallenge,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
    isFreemium: true,
    isDemo: true,
  },
  {
    // 1v1 Challenge (The challenge identifier is a url param)
    key: "challenge-url",
    path: "/1v1-challenge/:challengeUrl",
    element: OneVOneChallenge,
    isPrime: true,
    isPublic: false,
    isAdmin: false,
    isFreemium: true,
    isDemo: true,
  },
  {
    // Group company Challenge (The challenge identifier is a url param)
    key: "company-arena",
    path: "/company-arena/:challengeUrl",
    element: FreemiumScoreboard,
    isPrime: true,
    isAdmin: false,
    isFreemium: true,
    isDemo: true,
  },
  {
    key: "live-leaderboard",
    path: "/live/leaderboard/tv",
    element: TVLeaderboard,
    isPrime: true,
    hideSideNav: true,
    hideTopbar: true,
  },
  {
    key: "jetpack-content",
    path: "/hub/jetpacks/:name",
    element: JetpackContent,
    isAdmin: true,
    isPrime: true,
  },
  {
    key: "demo-page",
    path: "/demo",
    element: DemoPage,
  },
  {
    key: "playbook-editor",
    path: "/playbook-editor",
    element: PlaybookEditor,
    isPrime: false,
    isPublic: false,
    isAdmin: true,
  },
  {
    key: "playbook-viewer",
    path: "/playbook-viewer",
    element: PlaybookViewer,
    isPrime: false,
    isPublic: false,
    isAdmin: false,
  },
];

export const getPageProperties = ({ path }) => {
  const pageProps = routes.find((route) => route.path === path);
  return pageProps;
};

export default routes;
