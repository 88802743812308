/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

// Imported Pictures and Icons
import LoginForm from "../../components/Login/LoginForm";
import loginLogo from "../../img/GameMyBiz.png";
import loginBackground from "../../img/main_logo.png";
import { quickLoginUser } from "../../redux/auth/actions";
import { signOutUser } from "../../redux/action";
import LoadingScreen from "../../components/LoadingScreen";

// Sheet Style
import { useStyles } from "./loginStyles";
import clsx from "clsx";
import { homePropmt } from "../../hooks/addToHome";

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    homePropmt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(signOutUser());
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const encodedData = queryParams.get("data");
      const clickedRowData = decodeURIComponent(encodedData);
      const decodedData = {};

      const items = clickedRowData.split("&");
      for (const pair of items) {
        const [key, value] = pair.split("=");
        try {
          decodedData[key] = JSON.parse(value); // attempt to parse (used for boolean & integer values)
        } catch (err) {
          decodedData[key] = value; // else, treat it as string
        }
      }

      const { token, updatedAt, createdAt, createdBy, ...userData } = decodedData
      dispatch(quickLoginUser(token, userData));
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <section className={classes.root}>
      <Box
        className={clsx(classes.responsiveContainer, classes.loginContainer)}
      >
        <div className={classes.gridContainer}>
          <Box className={classes.signInBoxContainer}>
            <div className={classes.formContainer}>
              <img className={classes.logoStyle} src={loginLogo} alt="" />
              <h2 className={classes.formHeading}>
                Sign in to Access Your GMB Industry Dashboard!
              </h2>
              <LoginForm coldLeads={true} />
            </div>
          </Box>
          <div>
            <div className="max-w-[450px] m-auto mt-2 flex justify-center">
              <img
                src={loginBackground}
                alt="Game My Biz"
                className="w-[90%]"
              />
            </div>
            <div className="mt-2 w-[95%] m-auto">
              <h5 className="font-bold">
                Get Back in the Game: Sign in to Access Your Business Insights!
              </h5>
              <p>
                Welcome Back, Player! Ready to continue your journey to success?
                Access your account and pick up right where you left off. Your
                progress is waiting for you, along with valuable insights and
                data to help you level up your business game.
              </p>
            </div>
          </div>
        </div>
      </Box>
    </section>
  );
}

export default Login;
