import React from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuItem = ({ route }) => {
  const navigate = useNavigate();

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: "3.25vh",
          justifyContent: "center",
          px: "0.25vw",
          borderRadius: "1vh",
          paddingY: "0.5vh",
        }}
        onClick={() => navigate(route.route)}
      >
        <ListItemText
          primary={route.name}
          sx={{
            "& .MuiTypography-root": {
              fontSize: "16px",
              color: "primary.contrastText",
              fontWeight: 500,
              lineHeight: "normal",
              wordBreak: "break-word",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
