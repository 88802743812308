import React, { useState, useEffect, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Avatar from "../../Avatar";
import { AVATAR_URLS, AVATAR_THUMBNAILS } from "../freemiumAvatarsDef";
import { HexColorPicker } from "react-colorful";
import "./onboardingStyles.css";
import SoftBox from "../../SoftBox";
import debounce from "lodash/debounce";
import chroma from "chroma-js";
import { BRIGHTNESS_MIN, BRIGHTNESS_MAX } from "../../../theme";

const AvatarSelector = ({ setAvatar, setCompanyColor }) => {
  const [selected, setSelected] = useState(-1);
  const [index, setIndex] = useState(0);
  const [sequenceId, setSequenceId] = useState(null);
  const [color, setColor] = useState("#FFFFFF");
  const avatarRef = useRef(null);

  useEffect(() => {
    const intId = setTimeout(() => {
      if (index < AVATAR_URLS.length - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    }, 8000);
    setSequenceId(intId);
  }, [index]);

  useEffect(() => {
    if (selected >= 0) {
      setIndex(selected);
      setAvatar(AVATAR_URLS[selected]);
      clearTimeout(sequenceId);
    }
  }, [selected, setAvatar, sequenceId]);

  const selectColor = debounce((newColor) => {
    let pColor = newColor;
    const hsv = chroma(newColor).hsv();
    if (hsv[2] > BRIGHTNESS_MAX) {
      pColor = chroma.hsv(hsv[0], hsv[1], BRIGHTNESS_MAX).hex();
    } else if (hsv[2] < BRIGHTNESS_MIN) {
      pColor = chroma.hsv(hsv[0], hsv[1], BRIGHTNESS_MIN).hex();
    }
    setColor(newColor);
    setCompanyColor(pColor);
  }, 350);

  return (
    <SoftBox sx={{ height: "auto", marginX: "auto" }}>
      <Stack
        direction="row"
        className="flex justify-between w-full max-w-[250px] md:max-w-[375px] mx-auto my-2"
      >
        {AVATAR_THUMBNAILS.map((src, idx) => (
          <Box
            key={src}
            className="rounded-lg overflow-hidden cursor-pointer"
            sx={{
              border: {
                mini: "2px solid",
                md: "4px solid",
              },
              borderColor: idx === index ? "primary.main" : "white",
              bgcolor: idx === index ? "primary.main" : "white",
              width: {
                mini: idx === selected ? "2.75rem" : "2.5rem",
                md: idx === selected ? "4.25rem" : "4rem",
              },
              height: {
                mini: idx === selected ? "2.75rem" : "2.5rem",
                md: idx === selected ? "4.25rem" : "4rem",
              },
            }}
            onClick={() => setSelected(idx)}
          >
            <img
              src={src}
              alt={src}
              className="h-full w-full object-cover rounded-lg"
            />
          </Box>
        ))}
      </Stack>
      <SoftBox
        sx={{
          height: { mini: "280px", md: "380px" },
          width: { mini: "240px", xs: "380px" },
          marginX: "auto",
        }}
      >
        <Avatar
          ref={avatarRef}
          url={AVATAR_URLS[index]}
          fixed={true}
          autoRotate={true}
          recolor={color}
          disableAnim={true}
          isOwn={false}
        />
      </SoftBox>
      <SoftBox
        className="color-picker"
        sx={{
          width: { mini: "95%", md: "75%" },
          marginTop: 1,
          display: "flex",
          justifyContent: "center",
          marginX: "auto",
        }}
      >
        <HexColorPicker
          className="color-picker"
          color={color}
          onChange={(c) => selectColor(c)}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default AvatarSelector;
