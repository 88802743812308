import React from "react";
// Style Sheet File
import { loadingScreenStyles } from "./loadingScreenStyle";

// Imported Images and Icons
import gmbLogoLoad from "../../img/newLoading.gif";

export default function LoadingScreen({ height, logoHeight, logoWidth }) {
  const classes = loadingScreenStyles();

  return (
    <div className={classes.gmbGifContainer} style={{ height: `${height || '100vh'}` }}>
      <img src={gmbLogoLoad} alt="" className={classes.logoGifStyle} style={{ height: `${logoHeight || 'auto'}`, width: `${logoWidth || 'auto'}` }} />
    </div>
  );
}
