import React from "react";

const ScorecardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2019_9002)">
        <path
          d="M13.4459 3.98829L6.87785 10.5563C6.6217 10.8124 6.20791 10.8124 5.95176 10.5563L4.09302 8.69755C3.83686 8.4414 3.83686 8.02762 4.09302 7.77146C4.34917 7.51531 4.76295 7.51531 5.01911 7.77146L6.41152 9.16388L12.5132 3.0622C12.7694 2.80605 13.1831 2.80605 13.4393 3.0622C13.702 3.31836 13.702 3.73214 13.4459 3.98829ZM7.34418 13.2557C4.25065 13.2557 1.77451 10.5694 2.12262 7.41022C2.37877 5.09829 4.17183 3.20013 6.46407 2.81919C7.65287 2.62215 8.78257 2.83232 9.7415 3.33149C9.99765 3.46285 10.3063 3.41688 10.51 3.21327C10.8252 2.898 10.7464 2.366 10.3523 2.16239C9.38683 1.66979 8.29654 1.4005 7.134 1.43334C3.75805 1.53843 0.953511 4.28385 0.782743 7.65324C0.592271 11.443 3.60042 14.5693 7.34418 14.5693C8.13234 14.5693 8.88109 14.4314 9.58387 14.1753C10.0305 14.0111 10.1553 13.4331 9.81375 13.0915C9.63641 12.9142 9.36712 12.8485 9.13068 12.9405C8.5724 13.1441 7.96814 13.2557 7.34418 13.2557ZM11.9418 9.97174H10.6282C10.2669 9.97174 9.97138 10.2673 9.97138 10.6285C9.97138 10.9898 10.2669 11.2853 10.6282 11.2853H11.9418V12.5989C11.9418 12.9602 12.2373 13.2557 12.5986 13.2557C12.9598 13.2557 13.2554 12.9602 13.2554 12.5989V11.2853H14.569C14.9302 11.2853 15.2258 10.9898 15.2258 10.6285C15.2258 10.2673 14.9302 9.97174 14.569 9.97174H13.2554V8.65814C13.2554 8.2969 12.9598 8.00134 12.5986 8.00134C12.2373 8.00134 11.9418 8.2969 11.9418 8.65814V9.97174Z"
          fill="#F2F4F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_9002">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ScorecardIcon;
