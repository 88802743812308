/* eslint camelcase: 0 */
import { request } from "../helpers/axios.new";

export const LOGIN = ({username, password}) => {
  return request({
    url: `/auth/login`,
    method: "POST",
    data: {username, password}
  });
};

export const REGISTER = (userData) => {
  return request({
    url: `/auth/register`,
    method: "POST",
    data: userData,
  });
  
}
export const GOOGLE_LOGIN = ({token}) => {
  return request({
    url: '/auth/google-login', 
    method: "POST",
    data: { token }
  });
};

export const AUTH_ZAPIER = ({client_id, redirect_uri, state, companyId}) => {
  return request({
    url: `/zapier/auth`,
    method: "POST",
    data: {client_id, state, redirect_uri, companyId}
  });
};
