import { types } from "./types";

export const createNotification = (payload) => ({
  type: types.CREATE_NOTIFICATION,
  payload,
});

export const getUserNotification = (payload) => ({
  type: types.GET_USER_NOTIFICATION,
  payload,
});

export const updateNotificationReadStatus = (payload) => ({
  type: types.UPDATE_NOTIFICATION_READ_STATUS,
  payload,
});

export const deleteNotification = (payload) => ({
  type: types.DELETE_NOTIFICATION,
  payload,
});

export const updateAllNotificationStatus = (payload) => ({
  type: types.UPDATE_ALL_NOTIFICATION_STATUS,
  payload,
});

export const updateNotificationStatus = (payload) => ({
  type: types.READ_NOTIFICATION,
  payload,
});

export const addNotification = (payload) => ({
  type: types.ADD_NOTIFICATION,
  payload,
});
