import React, { useEffect, useState, useMemo } from "react";
import {
  FormControlLabel,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  Divider,
} from "@mui/material";
import { useTheme } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Imported Images and Icons
import AddUser from "./AddUser";
import {
  UseCompanyWorkspaces,
  UseSelectedWorkspace,
  UseUsersFullData,
} from "../../hooks/company";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";
import User from "./User";
import { debounce } from "lodash";
import Mark from "mark.js";
import { DELETE_COLLEAGUE, ARCHIVE_COLLEAGUE } from "../../api/user";
import SoftInput from "../../components/SoftInput";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftSelect from "../../components/SoftSelect";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import SoftTypography from "../../components/SoftTypography";
import PlayersTable from "./PlayersTable";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import borders from "../../assets/theme/base/borders";
import UserTable from "./UserTable";
import AddPlayerBillingUpdate from "./AddPlayerBillingUpdate";

const getGroupName = (groupId, groups) => {
  const group = groups.find(({ _id }) => _id === groupId);
  return group?.name || "";
};

const searchUser = (searchKey, data, groups) => {
  const key = searchKey.toLowerCase();
  const filteredData = data.filter((user) => {
    return (
      user?.firstName?.toLowerCase().includes(key) ||
      user?.lastName?.toLowerCase().includes(key) ||
      user?.email?.toString().toLowerCase().includes(key) ||
      getGroupName(user?.groupId, groups).toLowerCase().includes(key) ||
      (user?.isAdmin ? "admin" : "").includes(key)
    );
  });
  return filteredData;
};

const UmTable = () => {
  const allWorkspaces = UseCompanyWorkspaces(true);
  const workspaces = useMemo(
    () => [...allWorkspaces].sort((a, b) => a.name.localeCompare(b.name)),
    [allWorkspaces],
  );
  const { _id: workspaceId, name: workspaceName } = UseSelectedWorkspace();
  const allUsers = UseUsersFullData();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [showAll, setShowAll] = useState(0);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const roles = [
    { value: "player", label: "Player" },
    { value: "admin", label: "Admin" },
  ];

  const columns = [
    { name: "Players Name" },
    { name: "Workspace" },
    { name: "Status" },
    { name: "Players Role" },
    { name: "Actions" },
  ];

  // eslint-disable-next-line no-shadow
  const debounceFilter = debounce((searchKey, users) => {
    setFilteredUsers(searchUser(searchKey, users, workspaces));
    const context = document.querySelector("#search-table-body");
    const markInstance = new Mark(context);
    markInstance.unmark({
      done: () => {
        markInstance.mark(searchKey);
      },
    });
  }, 100);

  // filter users by current worksace or if ungrouped
  useEffect(() => {
    const activeUsers = allUsers.filter(
      (u) => u.groupId === workspaceId || !u.groupId,
    );
    activeUsers.sort((a, b) => {
      if (!b.groupId) return -1;
      return a.firstName.localeCompare(b.firstName);
    });
    setWorkspaceUsers(activeUsers);
  }, [allUsers]);

  useEffect(() => {
    if (showAll) {
      setFilteredUsers(workspaceUsers);
    } else {
      const activeUsers = workspaceUsers.filter((u) => !u.isArchived);
      setFilteredUsers(activeUsers);
    }
  }, [workspaceUsers, showAll]);

  const archiveUser = async (id, isArchived) => {
    // flag as archived for immediate action feedback
    const updatedList = [...workspaceUsers];
    const idx = updatedList.findIndex((user) => user.userId === id);
    if (idx > 0) {
      updatedList[idx].isArchived = isArchived;
      setWorkspaceUsers(updatedList);
    }
    await ARCHIVE_COLLEAGUE(id, isArchived);
  };

  return (
    <SoftBox>
      <Grid container sx={{ marginBottom: "1rem" }}>
        <Grid item mini={10}>
          <SoftTypography
            sx={{ color: "#000", fontWeight: 500, fontSize: "20px" }}
          >
            {workspaceName ?? ""} Players
          </SoftTypography>
          <SoftTypography
            sx={{ color: "#667085", fontWeight: 500, fontSize: "16px" }}
          >
            {workspaceUsers.length} player(s)
          </SoftTypography>
        </Grid>
        <Grid item mini={2}>
          <Tabs
            value={showAll}
            onChange={(e, value) => setShowAll(value)}
            sx={{
              borderRadius: "4px", padding: "10px",
                      "& .MuiTabs-indicator": {
                        borderRadius: "4px",
                      },
                      "& .MuiTab-root": {
                        padding: "10px"
                      },
                    }}
          >
            <Tab
              label="Active Players"
              sx={{
                fontSize: "0.75rem",
                ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                  borderRadius: "4px !important",
                  background: "inherit",
                  padding: "10px 15px",
                },
              }}
            />
            <Tab
              label="All Players"
              sx={{
                fontSize: "0.75rem",
                ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                  borderRadius: "4px !important",
                  background: "inherit",
                  padding: "10px 15px",
                },
              }}
            />
          </Tabs>
        </Grid>
      </Grid>
      <SoftBox
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          padding: "1rem 1rem 1rem 1.5rem",
        }}
      >
        <Grid container sx={{ marginY: "1rem" }}>
          <Grid item mini={6}>
            <SoftInput
              className="bg-light rounded-lg"
              type="text"
              placeholder="Search Players"
              onChange={(e) => {
                debounceFilter(e.target.value, allUsers);
                setSearch(e.target.value);
              }}
              sx={{
                background: "linear-gradient(0deg, #f8f9fa, #f8f9fa), linear-gradient(0deg, #f8f9fa, #f8f9fa)",
                border: "1px solid #F2F4F7",
                borderRadius: '4px',
              }}
              icon={{
                component: <SearchSvg />,
                direction: "left",
              }}
            />
          </Grid>

          <Grid item mini={6}>
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: { mini: "start", md: "end" },
                alignItems: "center",
                gap: { mini: "0.5rem", md: "1rem" },
              }}
            >
              <AddUser />
            </SoftBox>
          </Grid>
        </Grid>
        <hr className="my-3 divide-slate-300" />
        {/*  <UserTable workspaces={workspaces} /> */}
        <Table>
          <SoftBox component="thead">
            <TableRow>
              {columns.map((column, i) => (
                <TableCell style={{ width: "20%" }}>{column.name}</TableCell>
              ))}
            </TableRow>
          </SoftBox>
          <TableBody>
            {filteredUsers.map((user) => (
              <User
                key={user.userId}
                user={user}
                workspaces={workspaces}
                archiveUser={archiveUser}
              />
            ))}
          </TableBody>
        </Table>
      </SoftBox>
      {/* <PlayersTable filteredUsers={filteredUsers} /> */}
      {/* <div className="py-[1rem]">
        <div className="flex justify-between">
          <div>
            <span className="mr-[0.25rem] text-[#00000099]">
              Rows Per Page:{" "}
            </span>
            <UmDropdown />
          </div>
          <div className="flex justify-end">
            <Pagination
              className="userPagination"
              count={100}
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
      </div> */}
    </SoftBox>
  );
};

export default UmTable;
