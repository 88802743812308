import { request } from "../helpers/axios.new";

export const ADD_COMMENT = (requestData) => {
  return request({
    url: `/comments/${requestData.jobId}`,
    method: "POST",
    data: requestData?.data,
  });
};

export const DELETE_COMMENT = (commentId) =>
  request({
    url: `/comments/${commentId}`,
    method: "DELETE",
  });

export const FETCH_COMMENTS = (propertyId, page) =>
  request({ url: `/comments/${propertyId}?page=${page}` });

export const FETCH_ARENA_COMMENTS = (propertyId, page) =>
  request({ url: `/comments/arena/${propertyId}?page=${page}` });
