import React from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

export const EFFECT_CONFIGS = {
  "1" : {
    icon: <TrendingUpIcon />,
    name: "Positive",
    desc: "Select this if achieving a higher value for this KPI is beneficial to your goals",
  },
  "-1" : {
    icon: <TrendingDownIcon />,
    name: "Negative",
    desc: "Choose this if achieving a lower value for this KPI is beneficial to your goals",
  },
};


export const EFFECT_OPTIONS = [
  EFFECT_CONFIGS["1"],
  EFFECT_CONFIGS["-1"],
];