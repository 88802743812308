import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider } from "@mui/material";
import moment from "moment";

import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import { UseCompanyId } from "../../../hooks/auth";
import { GET_CAMPAIGNS, UPDATE_CAMPAIGN } from "../../../api/integration";
import LoadingScreen from "../../LoadingScreen";
import toast from "react-hot-toast";

const FbCampaignDialog = ({ closeCampaignModal }) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const companyId = UseCompanyId();

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const { data } = await GET_CAMPAIGNS(companyId);
      console.log("data", data);
      setCampaigns(data);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const changeCampaign = (index) => {
    const copy = [...campaigns];

    if (!copy[index].isSelected) {
      setSelectedCampaign(copy[index]);
      // Mark the selected campaign as true and others as false
      copy.forEach((campaign, i) => {
        campaign.isSelected = i === index;
      });
    }

    setCampaigns(copy);
  };

  const saveCampaignHandler = async () => {
    if (selectedCampaign) {
      try {
        const data = await UPDATE_CAMPAIGN(companyId, selectedCampaign);

        if (data) {
          toast.success(data.data);
          closeCampaignModal();
        }
      } catch (error) {
        console.log(error.response);
      }
    } else {
      toast.error("Select a campaign first");
    }
  };

  return (
    <SoftBox padding={3}>
      <SoftBox mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <SoftTypography variant="h5" sx={{ color: "#FFF" }}>
          Choose Your Campaign
        </SoftTypography>
      </SoftBox>
      <Divider />
      <SoftTypography variant="h6" sx={{ color: "#FFF" }}>
        Select one of your active Facebook Ads campaigns
      </SoftTypography>
      <SoftTypography variant="p" sx={{ color: "#6C757D" }}>
        Choose the campaign you want to track within Gamemybiz
      </SoftTypography>
      <SoftBox
        sx={{
          border: "1px solid #E9ECEF",
          borderRadius: "8px",
          padding: "15px",
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxHeight: "350px",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <LoadingScreen height="50px" logoHeight="50px" />
        ) : (
          <>
            {campaigns.map((campaign, index) => (
              <SoftBox
                key={campaign.id}
                onClick={() => changeCampaign(index)}
                sx={{
                  background: campaign.isSelected ? "#04549C" : "#414143",
                  borderRadius: "8px",
                  padding: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  transition: "all .5s",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography variant="h6" sx={{ color: "#FFF" }}>
                    {campaign.name}
                  </SoftTypography>
                  <SoftTypography variant="p" sx={{ color: "#FFF" }}>
                    Objective: {campaign.objective}
                  </SoftTypography>
                </SoftBox>
                <SoftTypography variant="p" sx={{ color: "#FFF" }}>
                  Running: {moment(campaign.start_time).format("MMM, DD")}&nbsp;
                  -&nbsp;
                  <span style={{ color: "#63BD0B", fontWeight: "bold" }}>
                    Active
                  </span>
                </SoftTypography>
              </SoftBox>
            ))}
          </>
        )}
      </SoftBox>
      <SoftTypography variant="p" sx={{ color: "#FFF" }}>
        Note: Only active campaigns associated with your Facebook Ads account
        will be shown.
      </SoftTypography>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeCampaignModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            textTransform: "none",
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          onClick={saveCampaignHandler}
          sx={{
            width: "100%",
            textTransform: "none",
          }}
          disabled={loading}
          loading={loading}
        >
          Save
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
};

export default FbCampaignDialog;
