/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Divider,
  useTheme,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import IntegrationApp from "./IntegrationApp";
import PropTypes from "prop-types";
import { UseGroupKpis } from "../../hooks/kpi";
import { UseSelectedWorkspace } from "../../hooks/company";
import { FETCH_GROUP_TYPE } from "../../api/group";
import {
  GET_APP_INTEGRATIONS,
  GET_KPI_INTEGRATIONS_BY_DATASET,
  GET_COMPANY_INTEGRATIONS,
} from "../../api/integration";
import {
  GET_ZAPS,
  GET_USER as GET_ZAPIER_USER,
} from "../../api/integrations/zapier";
import startCase from "lodash/startCase";
import SearchBar from "../SearchBar";
import LoadingScreen from "../LoadingScreen";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <SoftBox
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      height="100%"
    >
      {value === index && (
        <SoftBox sx={{ p: 3, height: "100%" }}>{children}</SoftBox>
      )}
    </SoftBox>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const MAX_WORKSPACE_KPIS = 5;

const Integrations = () => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const groupKpis = UseGroupKpis();
  const workspace = UseSelectedWorkspace();
  const { data: workspaceData } = FETCH_GROUP_TYPE(workspace.typeId);
  const [tabValue, setTabValue] = useState(0);
  const [apps, setApps] = useState([]);
  const [allApps, setAllApps] = useState([]);
  const [integrationsObj, setIntegrationsObj] = useState(null);
  const [currentAppIntegrations, setCurrentAppIntegrations] = useState({});
  const [connectedApps, setConnectedApps] = useState([]);
  const [adding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const addZapierKpis = async (appsData) => {
    try {
      const [zapTemplatesResponse, zapUserResponse] = await Promise.all([
        GET_ZAPS(),
        GET_ZAPIER_USER(),
      ]);

      const zapTemplates = zapTemplatesResponse.data;
      const zapUser = zapUserResponse.data;

      zapTemplates.forEach((z) => {
        const idx = appsData.findIndex((app) => {
          return app.name === z.name;
        });
        z.isConnected = !!zapUser;

        const zaps = z.zaps.map((zap) => ({
          ...zap,
          id: z.serviceId,
          // Assign Zapier integration Id for Zapier KPIs that are merged together with other integrated KPIs
          integrationId: z._id,
          // Remove original Game My Biz text from zap since it seems redundant here
          description: zap.description.replace("Game My Biz", ""),
          isZapier: true,
        }));

        if (idx >= 0) {
          appsData[idx].zaps = zaps;
        } else {
          appsData.push({
            ...z,
            zaps,
          });
        }
      });
      return appsData;
    } catch (err) {
      console.log("zap templates err", err);
      return appsData;
    }
  };

  useEffect(() => {
    if (workspaceData) {
      const getIntegrationsData = async () => {
        const [appsResponse, integResponse, companyIntegrationsResponse] =
          await Promise.all([
            GET_APP_INTEGRATIONS(),
            GET_KPI_INTEGRATIONS_BY_DATASET(),
            GET_COMPANY_INTEGRATIONS(),
          ]);

        let appsData = appsResponse.data;
        const integData = integResponse.data;
        const companyIntegrations = companyIntegrationsResponse.data;

        for (let j = 0; j < appsData.length; j += 1) {
          const { code, serviceId } = appsData[j];
          const idx = companyIntegrations.findIndex((app) => {
            return serviceId ? app.serviceId === serviceId : app.code === code;
          });
          appsData[j].isConnected = idx >= 0;
          appsData[j].description = appsData[j].dataSets?.join(", ");
        }
        appsData = await addZapierKpis(appsData);
        appsData.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        appsData.push({
          // Add Manual KPIs
          name: "Manual",
          code: "manual",
          description: "Manually-encoded KPIs",
          isConnected: true,
          models: workspaceData?.data.map((model) => {
            const { _id, ...rest } = model;
            return {
              kpiModelId: _id,
              ...rest,
            };
          }),
        });
        const appsConnected = appsData.filter(
          (item) => item.isConnected === true,
        );
        setConnectedApps(appsConnected);
        setAllApps(appsData);
        setApps(appsData);
        setIntegrationsObj(integData);
        setIsLoading(false);
      };

      getIntegrationsData();
    }
  }, [workspaceData]);

  useEffect(() => {
    if (groupKpis) {
      const appIntegrations = {};
      groupKpis
        .filter((kpi) => kpi.integrationId)
        .forEach((kpi) => {
          (appIntegrations[kpi.integrationId] ??= []).push(kpi.sourceId);
        });
      setCurrentAppIntegrations(appIntegrations);
      setAdding(false);
    }
  }, [groupKpis]);

  //   useEffect(() => {
  //     GET_HUB_ITEMS(workspace._id, isAdmin ? null : userId).then(({ data }) => {
  //       setPlugins(data);
  //     });
  //   }, [workspace, isAdmin, userId, refetch]);

  //   useEffect(() => {
  //     GET_SOURCE_IMAGE().then(({ data }) => {
  //       setPluginMaster(data);
  //     });
  //   }, []);

  //   const getFilteredIntegrations = (e) => {
  //     setQuery(e.target.value);
  //     setFilteredPlugins(
  //       plugins.filter((plugin) =>
  //         plugin.title.toLowerCase().includes(e.target.value),
  //       ),
  //     );
  //   };

  const handleSearch = (e) => {
    const key = e.target.value.trim().toLowerCase();
    const filteredApps = allApps.filter(
      (a) => a.name.toLowerCase().indexOf(key) >= 0,
    );
    setApps(filteredApps);
  };

  return (
    <SoftBox bgColor="white" borderRadius="16px">
      <SoftBox
        padding={{
          mini: "1rem 1rem 0rem 1rem",
          md: "1.5rem 1.5rem 0rem 1.5rem",
        }}
      >
        <SoftBox
          justifyContent="space-between"
          alignItems="center"
          sx={{ display: { mini: "block", lg: "flex" } }}
        >
          <SoftBox>
            <SoftTypography
              variant="h5"
              sx={{ color: "#343A40", display: { mini: "none", lg: "block" } }}
            >
              Seamless App Integrations
            </SoftTypography>
            <SoftTypography
              variant="body2"
              sx={{ color: "#6C757D", display: { mini: "none", lg: "block" } }}
            >
              Connect Your Favorite Tools
            </SoftTypography>
            <SoftBox
              sx={{
                border: "1px solid #E9ECEF",
                borderRadius: "8px",
                boxShadow: "0px 2.33389px 3.50084px 0px rgba(0, 0, 0, 0.05)",
                background: "white",
                display: { mini: "block", lg: "none" },
                marginBottom: "1rem",
              }}
            >
              <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                sx={{
                  padding: "8px",
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                  },
                }}
              >
                <Tab
                  label="Communication"
                  {...a11yProps(0)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
                <Tab
                  label="Productivity"
                  {...a11yProps(1)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
                <Tab
                  label="Marketing"
                  {...a11yProps(2)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
              </Tabs>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" gap="0.5rem">
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& > div": {
                  width: "100%",
                },
              }}
            >
              <SearchBar
                placeholder="Search for Apps.."
                size="large"
                icon={{
                  component: <SearchSvg />,
                  direction: "left",
                }}
                sx={{
                  width: "100%",
                  "&.Mui-focused": {
                    borderColor: primaryColor,
                    boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                  },
                }}
                onChange={handleSearch}
              />
            </SoftBox>

            {/* <SoftBox
              sx={{
                border: "1px solid #E9ECEF",
                borderRadius: "8px",
                boxShadow: "0px 2.33389px 3.50084px 0px rgba(0, 0, 0, 0.05)",
                background: "white",
                display: { mini: "none", lg: "block" },
              }}
            >
              <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                sx={{
                  padding: "8px",
                  width: "max-content",
                  "& .MuiTabs-indicator": {
                    borderRadius: "0.5rem",
                    boxShadow: "none",
                  },
                }}
              >
                <Tab
                  label="Communication"
                  {...a11yProps(0)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
                <Tab
                  label="Productivity"
                  {...a11yProps(1)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
                <Tab
                  label="Marketing"
                  {...a11yProps(2)}
                  sx={{
                    color: "#343A40",
                    fontSize: "0.85rem",
                  }}
                />
              </Tabs>
            </SoftBox> */}
          </SoftBox>
        </SoftBox>
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#F2F4F7",
        }}
      />

      <SoftBox
        bgColor="transparent"
        sx={{
          padding: {
            mini: "0.5rem 1rem 1rem 1rem",
            md: "0.75rem 1.5rem 1.5rem 1.5rem",
          },
        }}
      >
        <Grid container spacing={2}>
          {isLoading ? (
            <LoadingScreen />
          ) : apps.length > 0 && integrationsObj ? (
            apps.map((a) => {
              const {
                _id,
                dataSets,
                models,
                zaps,
                name,
                code,
                logo,
                parameters,
                isConnected,
                description,
                ...rest
              } = a;

              let appIntegrations = null;
              if (models || zaps) {
                appIntegrations = models ?? zaps;
              } else {
                appIntegrations = dataSets?.flatMap(
                  (d) => integrationsObj[d] ?? [],
                );
                if (currentAppIntegrations[_id]) {
                  appIntegrations = appIntegrations.filter(
                    (integration) =>
                      !currentAppIntegrations[_id].includes(
                        integration.sourceId,
                      ),
                  );
                }
              }

              return (
                <Grid item mini={12} xxs={6} lg={4} xxl={3}>
                  <IntegrationApp
                    key={rest.serviceId || name}
                    integrationId={_id}
                    currentIntegrations={currentAppIntegrations[_id]}
                    name={name || startCase(code)}
                    code={code}
                    logo={logo}
                    integrations={appIntegrations}
                    parameters={parameters}
                    isConnected={isConnected}
                    description={description}
                    config={rest}
                    maxWorkspaceKpis={MAX_WORKSPACE_KPIS}
                    setAdding={setAdding}
                    groupKpis={groupKpis}
                  />
                </Grid>
              );
            })
          ) : null}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
};

export default Integrations;
