import React from "react";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

const ExploreInfo = () => {
  return (
    <>
      <SoftBox sx={{ padding: "1rem 1rem 0rem 1.5rem" }}>
        <SoftTypography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: "#000",
          }}
        >
          Explore Custom Metrics Types{" "}
          <Tooltip
            title="Click on each metric type to explore its unique setup options and functionalities"
            placement="top"
          >
            <Info
              sx={{
                color: "rgba(0, 0, 0, 0.4)",
              }}
            />
          </Tooltip>
        </SoftTypography>
        <SoftTypography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#475467",
          }}
        >
          Welcome to the Custom Metrics Creation Center! Empower your analytics
          strategy by crafting personalized metrics tailored to your unique
          business goals.{" "}
        </SoftTypography>
      </SoftBox>
      <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
    </>
  );
};

export default ExploreInfo;
