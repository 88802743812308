import React from "react";
import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";
// import BadGuySuggestions from "../../components/BadGuys/BadGuySuggestions";
import GameboardPageContainer from "../GameboardSettings/GameboardPageContainer";
import BadGuySelector from "./BadGuySelector";

const BadGuyPage = () => {
  return (
    <SoftBox>
      <PageHeader
        headerText="Bad Guy"
        subheaderText="Choose where you want to play the game."
        className="mb-8"
      />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <GameboardPageContainer
          title="Choose Your Badguy"
          caption=" Select a character or entity that represents the &quot;antagonist&quot; or challenge within Your gaming or performance tracking environment. Choose from a list of available bad guys,"
        >
          <BadGuySelector />
        </GameboardPageContainer>
      </SoftBox>
    </SoftBox>
  );
};

export default BadGuyPage;
