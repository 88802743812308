import * as Yup from "yup";

const CompanyInfoSchema = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  industry: Yup.string().required("Industry is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  addressOne: Yup.string().required("Address line 1 is required"),
  city: Yup.string().required("City is required"),
  zipcode: Yup.string().required("Zip/Postal Code is required"),
  websiteUrl: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Please enter correct url!"
  ).required("Please enter website"),
  phoneNumber: Yup.string().required("Phone is required")
});

export default CompanyInfoSchema;
