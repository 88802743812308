import {
  WINDOW_CLEANING,
  FLOOR_COATING,
  CARPET_CLEANING,
  AUTOMOTIVE,
  ELECTRICAL,
  HVAC,
  EXTERIOR_CLEANING,
  OTHERS,
} from "../../constants/industries";

import CarpetCleaner  from "../../img/avatars/tools/CarpetCleaner.glb";

const tools = {};

tools[EXTERIOR_CLEANING] ={
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/exterior-cleaning-pressure-washer-y-up.glb",
  bone: "RightHand",
  rotate: {
    x: -1.5,
    y: -0.50,
    z: -2.45,
  },
  translate: {
    x: -0.35,
    y: -1.25,
    z: 0,
  }
};

tools[WINDOW_CLEANING] ={
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/window-cleaner-version-tool-yup-end-straight+upwards.glb",
  bone: "RightHandMiddle1",
  rotate: {
    x: -1.5,
    y: -0.5,
    z: 2,
  },
  translate: {
    x: -0.01,
    y: -0.02,
    z: 0,
  }
};

tools[CARPET_CLEANING] ={
  asset: CarpetCleaner,
  bone: "RightHand",
  rotate: {
    x: 0.250,
    y: 3.55,
    z: -1.12,
  },
  translate: {
    x: 0.075,
    y: 0.310,
    z: -0.690,
  }
};

tools[AUTOMOTIVE] ={
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/automotive-socket-wrench-final-yup.glb",
  bone: "RightHandMiddle1",
  rotate: {
    x: 3.14,
    y: 1.925,
    z: 0,
  },
  translate: {
    x: 0.0325,
    y: -0.030,
    z: 0,
  }
};

tools[ELECTRICAL] ={
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/electrical-multi-tested-up.glb",
  bone: "RightHandMiddle1",
  rotate: {
    x: 0.05,
    y: 3.12,
    z: 0,
  },
  translate: {
    x: 0,
    y: -0.095,
    z: 0,
  }
};

tools[HVAC] ={
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/hvac-pipe-bender.glb",
  bone: "RightHandMiddle1",
  rotate: {
    x: -1.5,
    y: -0.5,
    z: 2,
  },
  translate: {
    x: -0.01,
    y: -0.02,
    z: 0,
  }
};


tools[FLOOR_COATING] = {
  asset: "https://gmb-public.s3.us-east-2.amazonaws.com/tools/paint-roller-resized-fit-y-upwards.glb",
  bone: "RightHandMiddle1",
  rotate: {
    x: 1.25,
    y: -0.65,
    z: -0.35,
  },
  translate: {
    x: -0.0355,
    y: 0.05,
    z: 0.0175,
  }
};

tools.DEFAULT = tools[WINDOW_CLEANING];
tools.NONE = OTHERS;

export default tools; 

