import React, { useEffect, useState } from "react";

// Sertices API
import {
  GET_ZAPS,
  GET_USER as GET_ZAPIER_USER,
} from "../../../../api/integrations/zapier";

import {
  GET_APP_INTEGRATIONS,
  GET_KPI_INTEGRATIONS_BY_DATASET,
  GET_COMPANY_INTEGRATIONS,
} from "../../../../api/integration";

// Hooks
import { UseSelectedWorkspace } from "../../../../hooks/company";

import { FETCH_GROUP_TYPE } from "../../../../api/group";
import { GMB_LOGO_URL } from "../../../../constants/branding";

/**
 * Fetches, filters and builds Integrations data, borrowed from /WorkspaceKpi.jsx
 * @returns {Object} { connectedApps, integrations, isLoading, error }
 *  - connectedApps {Object[]}: list of connected apps (integrated accounts)
 *  - integrations {Object[]}: list of integrations objects
 *  - isLoading {Bool}: Flag if data is currently being fetched
 *  - error {String}: Fetching or parsing error messages
 */
const useIntegrationsData = () => {
  const workspace = UseSelectedWorkspace();
  const { data: workspaceData } = FETCH_GROUP_TYPE(workspace.typeId);

  const [allApps, setAllApps] = useState(null);
  const [connectedApps, setConnecteDApps] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const addZapierKpis = async (appsData) => {
    try {
      const [zapTemplatesResponse, zapUserResponse] = await Promise.all([
        GET_ZAPS(),
        GET_ZAPIER_USER(),
      ]);

      const zapTemplates = zapTemplatesResponse.data;
      const zapUser = zapUserResponse.data;

      zapTemplates.forEach((z) => {
        const idx = appsData.findIndex((app) => {
          return app.name === z.name;
        });
        z.isConnected = !!zapUser;

        const zaps = z.zaps.map((zap) => ({
          ...zap,
          id: z.serviceId,
          // Assign Zapier integration Id for Zapier KPIs that are merged together with other integrated KPIs
          integrationId: z._id,
          // Remove original Game My Biz text from zap since it seems redundant here
          description: zap.description.replace("Game My Biz", ""),
          isZapier: true,
        }));

        if (idx >= 0) {
          appsData[idx].zaps = zaps;
        } else {
          appsData.push({
            ...z,
            zaps,
          });
        }
      });

      setTimeout(() => setIsLoading(false), 500);
      return appsData;
    } catch (err) {
      setError(
        "zap templates err" + err?.response?.data?.message ?? err.message,
      );
      setIsLoading(false);
      return appsData;
    }
  };

  useEffect(() => {
    if (workspaceData) {
      const getIntegrationsData = async () => {
        try {
          const [appsResponse, integResponse, companyIntegrationsResponse] =
            await Promise.all([
              GET_APP_INTEGRATIONS(),
              GET_KPI_INTEGRATIONS_BY_DATASET(),
              GET_COMPANY_INTEGRATIONS(),
            ]);

          let appsData = appsResponse.data;
          const integData = integResponse.data;
          const companyIntegrations = companyIntegrationsResponse.data;

          for (let j = 0; j < appsData.length; j += 1) {
            const { code, serviceId } = appsData[j];
            const idx = companyIntegrations.findIndex((app) => {
              return serviceId
                ? app.serviceId === serviceId
                : app.code === code;
            });

            if (idx >= 0) {
              appsData[j].isConnected = true;
              appsData[j].paramKeys = {
                ...companyIntegrations[idx].parameters,
              };
            } else {
              appsData[j].isConnected = false;
            }
          }

          appsData = await addZapierKpis(appsData);

          appsData.push({
            // Add Manual KPIs
            name: "Game My Biz",
            code: "manual",
            logo: GMB_LOGO_URL,
            description: "Custom-built metrics for your business",
            isConnected: true,
            isManual: true,
            models: workspaceData?.data.map((model) => {
              const { _id, ...rest } = model;
              return {
                kpiModelId: _id,
                ...rest,
              };
            }),
          });

          const appsConnected = appsData.filter(
            (item) => item.isConnected === true,
          );

          // Sort apps: connected ones first then sort alphabetically
          const sortedApps = appsData.sort((app1, app2) => {
            // First, sort by isConnected in descending order (true comes first)
            if (app1.isConnected && !app2.isConnected) {
              return -1;
            }
            if (!app1.isConnected && app2.isConnected) {
              return 1;
            }

            // If isConnected values are equal, sort by isZapier in ascending order (false comes first)
            if (!app1.isZapier && app2.isZapier) {
              return -1;
            }
            if (app1.isZapier && !app2.isZapier) {
              return 1;
            }

            // Next, sort by isManual in ascending order (false comes first)
            if (!app1.isManual && app2.isManual) {
              return -1;
            }
            if (app1.isManual && !app2.isManual) {
              return 1;
            }

            // If isConnected and isZapier values are equal, then sort by name in ascending order
            return app1.name.localeCompare(app2.name);
          });

          setConnecteDApps(appsConnected);
          setAllApps(sortedApps);
          setIntegrations(integData);
          setIsLoading(false);
        } catch (err) {
          setError(err?.response?.data?.message ?? err.message);
          setIsLoading(false);
        }
      };

      setIsLoading(true);
      getIntegrationsData();
    }
  }, [workspaceData]);

  return {
    connectedApps,
    allApps,
    integrations,
    isLoading,
    error,
  };
};

export default useIntegrationsData;
