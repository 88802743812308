import React from "react";

import SoftTypography from "../SoftTypography";
import SoftProfile from "./SoftProfile";
import SoftBox from "../SoftBox";
import ProfilePopover from "../FooterDrawer/ProfilePopover";

const NavProfile = ({ image, firstName, lastName, isAdmin }) => {
  return (
    <SoftBox display="flex" justifyContent="space-between">
      <SoftBox
        // component={NavLink}
        // to={adminOrHomeRoute()}
        display="flex"
        alignItems="center"
      >
        <SoftProfile
          src={image}
          name={firstName}
          size="md"
          fullCover
          sx={{
            marginRight: "0.5rem",
            borderImage: "linear-gradient(310deg, #ced4da, #ebeff4)) 30",
            borderWidth: "3px",
            borderStyle: "solid",
          }}
        />
        <SoftBox
          width="100%"
          // sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
        >
          <SoftTypography
            component="h4"
            variant="button"
            color="white"
            textAlign="left"
            sx={{ fontWeight: 700, fontSize: "16px" }}
          >
            {firstName}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              maxWidth: "170px",
              fontSize: "12px",
              fontWeight: 400,
            }}
            className="text-left truncate text-ellipsis"
          >
            {isAdmin ? "Admin" : "Player"}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <ProfilePopover />
    </SoftBox>
  );
};

export default NavProfile;
