import { request } from "../helpers/axios.new";

export const GET_GROUP_KPIS = (companyId, groupId = null) => request({
  url: `/kpi/${companyId}${groupId ? `?groupId=${groupId}`: ""}`,
});

export const POST_GROUP_KPIS = (companyId, data) =>
  request({
    url: `/kpi/${companyId}`,
    method: "POST",
    data,
  });

export const CREATE_CUSTOM_KPI = (data) =>
  request({
    url: `/kpi/custom`,
    method: "PUT",
    data,
  });

export const GET_ALL_USER_KPIS = (companyId, groupId = null, month = null) => {
  const query = [
    ...(groupId ? [`groupId=${groupId}`]: []),
    ...(month ? [`month=${month}`]: []),
  ];
  return request({
    url: `/ekpi/${companyId}?${query.join("&")}`,
  });
}

export const GET_ALL_COMPANY_SCORES = (daily = null) => {
  return request({
    url: `/ekpi/company${daily ? `?daily=1` : ''}`,
  });
}

export const ADD_USER_KPI = ({kpiId, companyId, userId, month, data}) => {
  const query = [
    ...(kpiId ? [`kpiId=${kpiId}`]: []),
    ...(userId ? [`userId=${userId}`]: []),
    ...(companyId ? [`companyId=${companyId}`]: []),
    ...(month ? [`month=${month}`]: []),
  ];

  return request({
    method: "POST",
    url: `/ekpi/?${query.join("&")}`,
    data,
  });
}

export const UPDATE_USER_KPI = (id, kpiId, amount) =>
  request({
    method: "PUT",
    url: `/ekpi/${id}`,
    data: { kpiId, amount },
  });

export const DELETE_USER_KPI = (kpiId) =>
  request({
    method: "DELETE",
    url: `/ekpi/${kpiId}`,
  });


export const GET_USER_KPIS = (companyId, userId = null, kpiId = null) => {
  const query = [
    ...(userId ? [`userId=${userId}`]: []),
    ...(kpiId ? [`kpiId=${kpiId}`]: []),
  ];

  // either userId or kpiId can be empty, but server rejects request if both are
  if (query.length) {
    return request({
      method: "GET",
      url: `/ekpi/users/${companyId}?${query.join("&")}`,
    });
  }
  return Promise.reject(new Error("Empty query parameters"));
};

export const UPDATE_GROUP_KPI = (groupId, kpiObj) =>
  request({
    method: "PUT",
    url: `/kpi/${groupId}`,
    data: kpiObj,
  });

  export const DISABLE_GROUP_KPI = (kpiId) =>
  request({
    method: "DELETE",
    url: `/kpi/${kpiId}`,
  });

  export const SET_GROUP_KPI_ACCESS = (workspaceId, params) => request({
    method: "PUT",
    url: `kpi/access/${workspaceId}`,
    data: params
  })