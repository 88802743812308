import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { types } from "./types";
import { FETCH_JOBS, FETCH_JOB } from "../../api/jobs";


function* GetJobs(action) {
  try {
    const { companyId, page, userId } = action.payload;
    const req = yield call(() =>
      FETCH_JOBS(companyId, page, userId)
    );
    const { jobs, currentPage, hasMore } = req.data;

    yield put({
      type: types.GET_ALL_JOBS_SUCCESS,
      jobs,
      currentPage,
      hasMore,
    });
  } catch (err) {
    yield put({ type: types.GET_ALL_JOBS_FAILED, error: err.message });
  }
}

function* GetJob(action) {
  try {
    const jobId = action.payload;
    const { data } = yield call(() =>
      FETCH_JOB(jobId)
    );

    yield put({
      type: types.GET_A_JOB_SUCCESS,
      job: data,
      jobId,
    });
  } catch (err) {
    yield put({ type: types.GET_A_JOB_FAILED, error: err.message });
  }
}

function* watchGetJobsRequest() {
  yield takeEvery(types.GET_ALL_JOBS, GetJobs);
  yield takeEvery(types.GET_A_JOB, GetJob)
}

// root saga container
function* rootSaga() {
  yield all([fork(watchGetJobsRequest)]);
}

export default rootSaga;
