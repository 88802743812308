import React, { useEffect, useRef, useState, useMemo } from "react";

// Components
import { Line } from "react-chartjs-2";
import SoftBox from "../SoftBox";

// Data
import { UseYetiStats } from "../../hooks/company";
import { useTheme } from '@mui/material/styles';

import configs from "./configs";
import { PRIMARY_DEFAULT } from "../../theme";

const CHART_POINTS = [0, .35, .25, .50, 1.0, 0.65, 1.25, 1.15, 1.5];

const MiniChart = ({kpi, playerAmount = 0}) => {
  const [chartData, setChartData] = useState(null);
  const [scores, setScores] = useState(null);
  const [winning, setWinning] = useState(false);
  const [title] = useState(kpi?.title);
  const [playerPt, setPlayerPt] = useState(0);
  const [yetiPt, setYetiPt] = useState(0);
  const yeti = UseYetiStats();
  const theme = useTheme();
  const chartRef = useRef(null);

  useEffect(() => {
    if(yeti) {
      const yetiMax = kpi.calc? kpi.target : yeti.today[kpi._id]?.amt * yeti.totalDays;
      const playerTotal = (playerAmount * kpi.direction || 0);
      const total = yetiMax > playerTotal ? yetiMax : playerTotal;
      const chartPoints = kpi.direction >= 0 ? [...CHART_POINTS]: [...CHART_POINTS].reverse();
      const points = chartPoints.map(p => Math.round(p * total * 100 * kpi.direction)/100);
      const yetiTotal = yeti?.month[kpi._id]?.amt * kpi.direction || 0;

      if(playerTotal) {
        let playerHit = false;
        for(let p=0; p<points.length; p+=1) {
          if(playerTotal <= points[p]) {
            points.splice(p, playerTotal === points[p] ? 1: 0, playerTotal);
            playerHit = true;
            break;
          }
        }
        if(!playerHit) { // Player exceeded chart values
          points.push(playerTotal); // So add at the end / peak of the chart
        }
      }

      for(let p=0; p<points.length-1; p+=1) {
        if(yetiTotal <= points[p]) {
          points.splice(p, yetiTotal === points[p] ? 1: 0, yetiTotal);
          setYetiPt(p);
          break;
        }
      }

      setPlayerPt(points.indexOf(playerTotal)); // search for player point *after* yeti point has been set
      setWinning(playerTotal>=yetiTotal);
      setScores(points);
    }
  }, [kpi, playerAmount, yeti]);

  useEffect(() => {
    if(scores) {
      const color = theme?.palette[winning? "positive" : "negative"];
      // eslint-disable-next-line no-nested-ternary
      const labels = scores.map((score,s) => s === playerPt ? "Player" :  s === yetiPt? "Yeti" :  "-");
      const pointRadius = scores.map((score, s) => s === playerPt || s === yetiPt? 5 : 0);
      const pointBackgroundColor = scores.map((score, s) => s === yetiPt? PRIMARY_DEFAULT : theme?.palette.primary.dark);
      const kpiChartData = {
        labels,
        datasets: [
          {
            label: title,
            data: scores,
          },
        ],
      };

      const chartDatasets = kpiChartData.datasets.map((dataset) => ({
        ...dataset,
        tension: 0.4,
        borderWidth: 2,
        fill: false,
        borderColor: color.main,
        pointBackgroundColor,
        pointBorderColor: "white",
        pointRadius,
        maxBarThickness: 6,
      }));
  
      setChartData(configs(kpiChartData.labels, chartDatasets, kpi.kind));
    }
  }, [scores, title, winning, playerPt, yetiPt, kpi.kind, theme?.palette]);


  return <>
    {useMemo(
      () => {
        if(chartData) {
          const { data, options } = chartData;
          return <SoftBox
            ref={chartRef}
            sx={{
                position: "absolute",
                bottom: "5%",
                right: "2%",
                width: "50%",
                height: "90%",
              }}
            >
              <Line data={data} options={options} />
          </SoftBox>
        }
        return null;
      },
      [chartData],
    )}
  </>        
}

export default MiniChart;