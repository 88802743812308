import * as Yup from "yup";

const CustomDisplayMetricSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(24, "Metric name must be at most 24 characters"),
  dataSource: Yup.string().required("Data source is required"),
  data: Yup.object(),
});

export default CustomDisplayMetricSchema;
