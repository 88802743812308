export const initState = {
  notifications: [],
  unreadNotifications: [],
  fetchingNotification: false,
  error: null,
};

export const types = {
  // CREATE_NOTIFICATION
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILED: "CREATE_NOTIFICATION_FAILED",
  // GET_USER_NOTIFICATION
  GET_USER_NOTIFICATION: "GET_USER_NOTIFICATION",
  GET_USER_NOTIFICATION_SUCCESS: "GET_USER_NOTIFICATION_SUCCESS",
  GET_USER_NOTIFICATION_FAILED: "GET_USER_NOTIFICATION_FAILED",
  // UPDATE_NOTIFICATION_READ_STATUS
  UPDATE_NOTIFICATION_READ_STATUS: "UPDATE_NOTIFICATION_READ_STATUS",
  UPDATE_NOTIFICATION_READ_STATUS_SUCCESS:
    "UPDATE_NOTIFICATION_READ_STATUS_SUCCESS",
  UPDATE_NOTIFICATION_READ_STATUS_FAILED:
    "UPDATE_NOTIFICATION_READ_STATUS_FAILED",
  UPDATE_NOTIFICATION_READ_STATUS_UI_SUCCESS:
    "UPDATE_NOTIFICATION_READ_STATUS_UI_SUCCESS",
  // DELETE_NOTIFICATION
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILED: "DELETE_NOTIFICATION_FAILED",
  // READ_NOTIFICATION
  READ_NOTIFICATION: "READ_NOTIFICATION",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILED: "READ_NOTIFICATION_FAILED",
  // UPDATE_ALL_NOTIFICATION_STATUS
  UPDATE_ALL_NOTIFICATION_STATUS: "UPDATE_ALL_NOTIFICATION_STATUS",
  UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS:
    "UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS",
  UPDATE_ALL_NOTIFICATION_STATUS_FAILED:
    "UPDATE_ALL_NOTIFICATION_STATUS_FAILED",
  UPDATE_ALL_NOTIFICATION_STATUS_UI_SUCCESS:
    "UPDATE_ALL_NOTIFICATION_STATUS_UI_SUCCESS",

  // ADD_NOTIFICATION
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
};
