import { request } from "../helpers/axios.new";

export const CREATE_UPDATE_HANDBOOK = (data) => {
  return request({
    url: `handbook`,
    method: "POST",
    data,
  });
};

export const GET_HANDBOOK = ({ workspaceId }) => {
  return request({
    url: `handbook?workspaceId=${workspaceId}`,
    method: "GET",
  });
};
