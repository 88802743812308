import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  formText: {
    color: "#ACACAC",
    marginTop: "1rem",
  },

  leftPanel: {
    padding: "50px",
    width: "75%",
    margin: "80px 0px 40px 0px",
    "@media screen and (max-width: 768px)": {
      width: "90% !important",
    },
  },

  inputWrapper: {
    display: "flex",
    alignItems: "center",

    "& > :not(style):nth-child(1)": {
      marginTop: "16px",
      padding: "16px",
      width: "100%",
      boxShadow: "12px 12px 24px rgba(189, 189, 189, 0.25)",
      background: "#F7F7F7",
      borderRadius: "55px",
      height: "55px",
      border: "none",
      outline: "none",
    },
  },

  visibilityIcon: {
    marginLeft: "-40px",
    color: "gray",
    cursor: "pointer",
  },

  forgotLink: {
    display: "block",
    textAlign: "right",
    color: "#9B9B9B",
    textDecoration: "none",
    margin: 0,
    fontFamily: "DM Sans, Inter, Arial, sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",

    "&:hover": {
      color: "#ee238a",
    },
  },

  toggleStyle: {
    fontSize: "0.75rem",
  },

  buttonContainer: {
    marginTop: "24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > :not(style)": {
      borderRadius: "32px",
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    "@media screen and (max-width: 767px)": {
      flexDirection: "column",
      textAlign: "center",
    },
  },

  buttonStyle: {
    paddingRight: "48px !important",
    paddingLeft: "48px !important",
    fontSize: "1rem !important",
    fontWeight: "700",
    width: "100%",
    color: "#fff !important",
    background: "linear-gradient(90deg, #EE238A 0%, #922790 93.85%) !important",
    borderRadius: "8px !important",
  },

  textBold: {
    fontWeight: "700 !important",
  },

  textCentered: {
    textAlign: "center",
  },

  socialLoginButton: {
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px",
    padding: "10px 0px",
    gap: "10px",
    boxShadow: "0px 0px 2px 2px #eeeee4",
    fontFamily: "DM Sans, Helvetica, Arial, sans-serif",
    cursor: "pointer",
  },
  socialLogo: {
    width: "30px",
    height: "30px",
  },
  socialText: {
    fontSize: "15px",
    fontFamily: "DM Sans",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "20px",
    left: "50px",
  },
  noAccountText: {
    textAlign: "center",
    fontSize: "16px !important",
    fontWeight: 500,
    color: "#667085",
  },
  linkText: {
    color: "#000",
    fontWeight: "bold",
  },
});
