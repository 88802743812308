import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { CREATE_COMPANY } from "../../api/company";
import { setCompany as setCompanyRedux } from "../../redux/company/actions";
import { setSelectedWorkspace } from "../../redux/workspace/actions";
import { updateProfile } from "../../redux/auth/actions";

import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyInfoSchema } from "../../form-validations";
import { CompanyContext } from "../../context/createCompanyContext";

import { Box, CircularProgress, Grid, Stack, TextField } from "@mui/material";
import SoftButton from "../SoftButton";
import { ReactHookFormPhone, ReactHookFormSelect } from "../ReactHookForms";
import { stepperPageStyles } from "../../pages/CreateCompany/stepperPageStyles";

import industries from "../../constants/industries";
import onboarding from "../../constants/onboarding";

const CompanyInfo = ({ handleNext, handleBack, companyId, userId }) => {
	const { company, setCompany } = useContext(CompanyContext);
	const classes = stepperPageStyles();
	const validationOpt = {
		resolver: yupResolver(CompanyInfoSchema),
		shouldUnregister: false,
		defaultValues: company
	};

	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(validationOpt);

	const industryOptions = industries.map(item => ({ value: item, label: item }));
	const dispatch = useDispatch();

	const onSubmit = async (data) => {
		if (Object.keys(errors).length) return;
		
		setIsLoading(true);
		setCompany(data);

		if (!companyId) {
			// Create new company
			const {data: { company: newCompany, workspace: newWorkspace }} = await CREATE_COMPANY({
				...data,
				onboardingStatus: onboarding.ONGOING,
			});

			// Update Redux company and workspace data
			dispatch(setCompanyRedux(newCompany));
			dispatch(setSelectedWorkspace({ selectedWorkspace: newWorkspace }));

			// Configure current user
			dispatch(updateProfile(userId, 
				{
					companyId: newCompany._id,
					groupId: newWorkspace._id,
					isAdmin: true,
				}
			));
		}

		setIsLoading(false);
		handleNext();
	};

	React.useEffect(() => {
		if (company) reset(company);
	}, [company, reset]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				spacing={2}
			>
				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Business Name:*</label>
						<TextField
							id="companyBusinessName"
							type="name"
							placeholder="E.g. GameMyBiz"
							{...register("businessName")}
							error={!!errors?.businessName}
							helperText={errors?.businessName?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Business Industry:*</label>
						<ReactHookFormSelect
							name="industry"
							id="industry"
							register={register}
							options={industryOptions}
							error={!!errors?.industry}
							helperText={errors?.industry?.message}
							defaultValue={company.industry}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Country:*</label>
						<TextField
							id="companyCountry"
							type="name"
							placeholder="E.g. USA"
							{...register("country")}
							error={"country" in errors}
							helperText={errors?.country?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>State/Region:*</label>
						<TextField
							id="companyStateRegion"
							type="name"
							placeholder="E.g. Idaho, USA"
							{...register("state")}
							error={"state" in errors}
							helperText={errors?.state?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Address line 1:*</label>
						<TextField
							id="companyStreetAddress"
							type="name"
							placeholder="E.g. 123 Bear Lake Ave"
							{...register("addressOne")}
							error={"addressOne" in errors}
							helperText={errors?.addressOne?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Address line 2:</label>
						<TextField
							id="addressLineTwo"
							type="name"
							placeholder="Address Line 2 (optional)"
							{...register("addressTwo")}
							error={"addressTwo" in errors}
							helperText={errors?.addressTwo?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Town/City:*</label>
						<TextField
							id="companyTownCity"
							type="name"
							placeholder="E.g. Pocatello"
							{...register("city")}
							error={"city" in errors}
							helperText={errors?.city?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Zipcode/Postcode:*</label>
						<TextField
							id="companyZipcode"
							type="name"
							placeholder="E.g. 89123"
							{...register("zipcode")}
							error={"zipcode" in errors}
							helperText={errors?.zipcode?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Phone number:</label>
						<ReactHookFormPhone
							id="companyPhoneNum"
							type="tel"
							placeholder="E.g. 912-123-8564"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							{...register("phoneNumber")}
							error={"phoneNumber" in errors}
							helperText={errors?.phoneNumber?.message}
							control={control}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Stack spacing={1}>
						<label>Website URL:*</label>
						<TextField
							id="companyWebsite"
							type="name"
							placeholder="E.g. www.gamemybiz.com"
							{...register("websiteUrl")}
							error={"websiteUrl" in errors}
							helperText={errors?.websiteUrl?.message}
							sx={{
								"& .MuiOutlinedInput-notchedOutline legend": {
									display: "none"
								}
							}}
						/>
					</Stack>
				</Grid>
			</Grid>
			<Box className={classes.stepperButtonContainer}>
				<SoftButton
					className="mt-2"
					variant="gradient"
					color="primary"
					onClick={handleBack}
				>
					Cancel
				</SoftButton>
				<div className="flex items-center gap-4">
					<SoftButton className="mt-2" type="submit" variant="gradient" color="primary" disabled={isLoading}>
						Save {" "}
						{isLoading && (<CircularProgress size="1rem"/>)}
					</SoftButton>
				</div>
			</Box>
		</form>
	);
};

export default CompanyInfo;
