import { useContext, createContext, useState, useEffect } from "react";
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";


import { auth } from '../hooks/firebase';


const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [googleUser, setUser] = useState({});


    const googleSignIn = () => {
        const promise = new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
                .then((result) => {
                    resolve(result.user);
                }).catch((error) => {
                    reject(error);
                });
        });
        return promise;
    }

    const fbSignIn = () => {
        const promise = new Promise((resolve, reject) => {
            const provider = new FacebookAuthProvider();
            signInWithPopup(auth, provider)
                .then((result) => {
                    resolve(result.user);
                }).catch((error) => {
                    reject(error);
                });
        });
        return promise;
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        });
        return () => {
            unsubscribe();
        }
    });

    const logOut = () => {
        signOut(auth);
    };

    return (
        // eslint-disable-next-line react/react-in-jsx-scope, react/jsx-no-constructed-context-values
        <AuthContext.Provider value={{ fbSignIn, googleSignIn, logOut, googleUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext);
}