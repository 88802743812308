import React, { useEffect, useState } from "react";
import { Dialog, Divider, CircularProgress } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import toast from "react-hot-toast";

import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import SoftSelect from "../SoftSelect";
import { UseUserDetails } from "../../hooks/auth";
import { useForm } from "react-hook-form";
import { UseSelectedWorkspace, UseUsersFullData } from "../../hooks/company";
import { UPDATE_HUB_ITEM } from "../../api/hub";

const AssignDialog = ({ id, url, type, assignedTo, onEnableChange }) => {
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);

  const userDetails = UseUserDetails();
  const workspace = UseSelectedWorkspace();
  const usersData = UseUsersFullData();

  const validationOpt = {
    shouldUnregister: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationOpt);

  const fetchUsersByGroupId = () => {
    setLoading(true);
    const filteredUsers = usersData
      .filter((user) => user.groupId === workspace._id)
      .map((item) => {
        const username = `${item.firstName} ${item.lastName}`;

        return {
          ...item,
          _id: item.userId,
          value: item.userId,
          label: username,
        };
      });

    setUsers(filteredUsers);
    setLoading(false);
  };

  useEffect(() => {
    if (dialog) {
      fetchUsersByGroupId();
    }
  }, [dialog, usersData]);

  const triggerAssign = async (formData) => {
    setAssigning(true);
    try {
      await UPDATE_HUB_ITEM({
        id,
        userId: userDetails._id,
        groupId: workspace._id,
        url,
        type,
        assignedTo: selectedUser,
      });

      setAssigning(false);
      toast.success("Link assigned successfully");
      onEnableChange?.();
      closeDialog();
    } catch (error) {
      setAssigning(false);
      console.log("=== error", error.response);
    }
  };

  const getDefaultValue = () => {
    if (!id && !url) return null;
    if (!assignedTo) return null;

    return assignedTo.map((item) => {
      const username = `${item.firstName} ${item.lastName}`;

      return {
        ...item,
        value: item._id,
        label: username,
      };
    });
  };

  console.log("=== getDefaultValue()", getDefaultValue());

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="flex-end"
        sx={{ width: "100% !important" }}
      >
        <SoftButton
          color="primary"
          variant="text"
          onClick={openDialog}
          sx={{
            textTransform: "none",
            color: "#344054",
            fontWeight: 600,
            fontSize: "14px",
            fontFamily: "DM Sans",
            height: "min-content",
            width: "max-content",
            borderRadius: "4px",
            "&:hover": {
              color: "#6C757D",
            },
          }}
        >
          Assign Link
        </SoftButton>
      </SoftBox>
      {dialog && (
        <Dialog
          open={openDialog}
          onClose={closeDialog}
          sx={{
            ".MuiPaper-root": {
              padding: { mini: "1rem", md: "1.5rem 1.7rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "600px",
              borderWidth: "1px",
              borderColor: "#FFF",
              "::-webkit-scrollbar": {
                display: "none",
                height: 0,
                width: 0,
              },
            },
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
              <SoftBox>
                <SoftTypography
                  variant="h4"
                  sx={{ color: "#FFF", fontSize: "20px" }}
                >
                  Assign Custom power link to Player
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  sx={{ color: "#FFF", fontSize: "12px" }}
                >
                  Select a player from the list below to assign the power link
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftButton
              variant="outlined"
              color="white"
              onClick={closeDialog}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
          <Divider
            sx={{
              backgroundColor: "#FFF",
            }}
          />
          <form onSubmit={handleSubmit(triggerAssign)}>
            <SoftBox mb="10px" />
            <SoftSelect
              name="isType"
              id="category"
              className="custom-select-catgory"
              options={users}
              isMulti
              onChange={(option) => {
                const list = [...option];
                setSelectedUser(list);
              }}
              defaultValue={getDefaultValue()}
              isLoading={loading}
              placeholder="Select player"
              styles={{
                control: {
                  backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                  height: "100%",
                  minWidth: "2.5rem",
                },
                placeholder: {
                  color: "rgba(206, 212, 218, 1) !important",
                  opacity: "0.7",
                },
                input: {
                  color: "rgba(206, 212, 218, 1) !important",
                  opacity: "0.7",
                },
                singleValue: {
                  color: "white !important",
                  opacity: "0.7",
                },
                valueContainer: {
                  flexFlow: "row wrap",
                  gap: "0.25rem",
                  padding: "0.5rem 0.75rem",
                },
                indicatorsContainer: {
                  alignItems: "start",
                },
              }}
            />
            <SoftTypography
              variant="caption"
              sx={{
                color: "#FFF",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              You can pick more than one player
            </SoftTypography>
            <SoftBox mb="50px" />
            <Divider
              sx={{
                backgroundColor: "#FFF",
              }}
            />

            <SoftBox
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "0.75rem",
                marginTop: "10px",
              }}
            >
              <SoftButton
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
                onClick={closeDialog}
              >
                Cancel
              </SoftButton>
              <SoftButton
                color="primary"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "4px",
                  color: "#E9ECEF",
                }}
                disabled={assigning}
                type="submit"
              >
                Assign{" "}
                {assigning && (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    className="ml-2"
                  />
                )}
              </SoftButton>
            </SoftBox>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default AssignDialog;
