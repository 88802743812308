import React from "react";

import Rank from "./Rank";
import Level from "./Level";
import TopKPI from "./TopKPI";
import PersonalGoal from "./PersonalGoal";
import AdminBulletin from "./AdminBulletin";
import CompanyValues from "./CompanyValues";
import PlayerMemo from "./PlayerMemo";
import QuoteOfTheDay from "./QuoteOfTheDay";

export const Components = {
  ranking: Rank,
  level: Level,
  top_kpi: TopKPI,
  personal_goals: PersonalGoal,
  admin_bulletin: AdminBulletin,
  company_values: CompanyValues,
  player_memo: PlayerMemo,
  quote_of_the_day: QuoteOfTheDay,
};

const DynamicComponent = ({ widget, setReFetch, showPerformBox = false, isSmallScreen = false }) => {
  if (typeof Components[widget.key] !== "undefined") {
    const Component = Components[widget.key];
    return (
      <Component
        widget={widget}
        showPerformBox={showPerformBox}
        setReFetch={setReFetch}
        isSmallScreen={isSmallScreen}
      />
    );
  }

  /* eslint-disable */
  return <></>;
};

export default DynamicComponent;
