import React, { useEffect, useRef, useState, useMemo } from "react";
import { Card, Dialog } from "@mui/material";
import { Line, Bar } from "react-chartjs-2";

// Style Sheet File
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import configs from "./configs";
import ExpandableView from "./ExpandableView";

// Icons
import { ReactComponent as RankWidgetIcon } from "../../img/rankWidgetIcon.svg";
import { ReactComponent as LevelWidgetIcon } from "../../img/levelWidgetIcon.svg";

// Original PerformBox with minor adjustments for mobile
function PerformBoxMobile({ title, amount, showGraph = true, showCharts = true }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const [modal, setModal] = useState(false);
  const { data, options } = chartData;

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const kpiChartData = {
      labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: title,
          color: "dark",
          data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
        },
      ],
    };

    const chartDatasets = kpiChartData.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "#ffffff",
      fill: false,
      maxBarThickness: 6,
    }));

    setChartData(configs(kpiChartData.labels, chartDatasets));
  }, []);

  return (
    <>
      <Dialog
        open={modal}
        onClose={closeModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <ExpandableView title={title} amount={amount} closeModal={closeModal} />
      </Dialog>
      <Card
        onClick={() => setModal(true)}
        sx={{
          boxShadow: "0px 0px 0px 0px",
          background: "#F9FAFB",
          borderRadius: "6px",
          height: showCharts ? "7rem" : "84px",
          width: "100%",
          border: "0.72px solid #F2F4F7",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
            <SoftBox
              sx={{
                "& svg": {
                  height: "50px", width: "50px", marginLeft: "16px", padding: "8px",
                  backgroundColor: "#FFF",
                  border: "0.5px solid #F2F4F7"
                }
              }}
            >
              {title === "Ranking" && <RankWidgetIcon />}
              {title !== "Ranking" &&  <LevelWidgetIcon />}
            </SoftBox>

          <SoftBox lineHeight={1} sx={{ padding: { mini: 1, xxs: 2 }, width: "100%" }}>
            <SoftTypography
              variant="button"
              textTransform="capitalize"
              sx={{
                color: "#98A2B3",
                fontFamily: "DM Sans",
                fontSize: { mini: "12px", xxs: "12px", md: "16px" },
                fontWeight: 500
              }}
            >
              {title}
            </SoftTypography>

            <SoftTypography
              variant="h1"
              fontWeight="regular"
              sx={{
                color: "#000",
                whiteSpace: "nowrap",
                width: { mini: "110px", md: "290px" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "DM Sans",
                fontSize: { mini: "20px", xxs: "20px", md: "22px" },
                fontWeight: 500,
                ...(!showCharts && ({ width: { mini: "80%", xs: "90%", md: "100%" } }))
              }}
            >
              {amount}&nbsp;
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {useMemo(
          () => (
            (showCharts)
              ? <SoftBox
                  ref={chartRef}
                  sx={{
                    height: "7rem",
                    position: "absolute",
                    bottom: { mini: "-35px", xxs: "-45px", md: "-20px" },
                    right: { mini: "5px", xxs: "10px", md: 0 },
                    width: "60%",
                  }}
                >
                  {/* eslint-disable */}
                  {showGraph ? (
                    title === "Level" ? (
                      <Bar data={data} options={options} />
                    ) : (
                      <Line data={data} options={options} />
                    )
                  ) : null}
                </SoftBox>
              : null

          ),
          [chartData],
        )}
      </Card>
    </>
  );
}

export default PerformBoxMobile;
