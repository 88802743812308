export const ONE_TO_TEN = "1-10";
export const ELEVEN_TO_FIFTY = "11-50";
export const FIFTY_TO_HUNDRED = "50-100";
export const FIFTY_FIVEHUNDRED = "100-500";
export const FIVEHUNDRED_PLUS = "500+";

export default [
  ONE_TO_TEN,
  ELEVEN_TO_FIFTY,
  FIFTY_TO_HUNDRED,
  FIFTY_FIVEHUNDRED,
  FIVEHUNDRED_PLUS,
];
