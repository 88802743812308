/* eslint-disable no-nested-ternary */
import React from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftProfile from "../ProfilePictures/SoftProfile";
import useMediaQuery from "@mui/material/useMediaQuery";

const CompanyInfo = ({ companyName, companyLogo, companyScore }) => {
  const md = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const xs = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const xxs = useMediaQuery((theme) => theme.breakpoints.up("xxs"));

  return (
    <SoftBox
      sx={{
        position: "absolute",
        bottom: "4%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginX: "auto",
        left: 0,
        right: 0,
        gap: "0.25rem",
      }}
    >
      <SoftProfile
        src={companyLogo}
        alt=""
        size={md ? "xl" : xs ? "lg" : "md"}
        sx={{ border: "3px solid white" }}
      />
      <SoftTypography
        color="white"
        variant={md ? "h3" : xs ? "h4" : xxs ? "h5" : "h6"}
      >
        {companyName}
      </SoftTypography>
      <SoftTypography
        color="white"
        variant={md ? "h4" : xs ? "h5" : xxs ? "h6" : "button"}
        sx={{
          borderRadius: "30px",
          background: "rgba(0, 0, 0, 0.16)",
          padding: md ? "9px 18px" : xs ? "6px 15px" : "3px 12px",
          fontSize: { mini: "0.9rem", xxs: "auto" },
        }}
      >
        {companyScore} Points
      </SoftTypography>
    </SoftBox>
  );
};

export default CompanyInfo;
