import React, { useEffect, useState } from "react";

import { Box, Typography, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { GET_PRICING } from "../../api/payment";
import BillingType from "../../components/Billing/BillingType";
import { UseSubscriptionLevel } from "../../hooks/company";

import GMB_LOGO from "../../img/main_logo.png";
import "./styles/pricing.css";

const PricingDetails = () => {
  const [isMonthly, setIsMonthly] = useState(1);
  const [allOptions, setAllOptions] = useState([]);
  const [subOptions, setSubOptions] = useState([]);

  const subscriptionLevel = UseSubscriptionLevel();
  const navigate = useNavigate();

  useEffect(() => {
    // Get all Subscription prices from Stripe
    GET_PRICING()
      .then(({ data }) => {
        setAllOptions(data.sort((a, b) => a.amount - b.amount));
      })
      .catch((err) => console.log("Stripe Get Prices Error", err));
  }, []);

  useEffect(() => {
    // Filter subscription options
    if (allOptions.length) {
      const options = allOptions.filter(
        (item) => item.interval === (isMonthly ? "month" : "year"),
      );
      setSubOptions(options);
    }
  }, [allOptions, isMonthly]);

  return (
    <Box>
      {/* Header */}
      <Box className="top-header">
        <img src={GMB_LOGO} className="top-logo" alt="Game My Biz" />
        <p className="top-title">Game My Biz</p>
      </Box>
      {/* Billing Content */}
      <Box sx={{ paddingX: "4rem", marginBottom: "2rem" }}>
        <Box sx={{ marginTop: "2rem", marginBottom: "4rem" }}>
          <Box className="flex flex-col items-center">
            <p className="header">Let's Upgrade Your Experience</p>
            <p className="subheader">
              Unlock premium features and take your experience to the next
              level. Enjoy enhanced capabilities and stay tuned for more
              exciting plans coming soon!
            </p>
          </Box>
          {/** Re-activate if Yearly options have been re-configured on Stripe */}
          {/* <Box className="flex justify-end">
          <Box className="flex flex-col text-end">
            <Tabs
              value={isMonthly}
              onChange={(e, value) => setIsMonthly(value)}
              sx={{ py: "2px", px: "4px", maxWidth: "15rem" }}
              color="secondary"
            >
              <Tab label="Yearly" sx={{ fontSize: "1rem" }} />
              <Tab label="Monthly" sx={{ fontSize: "1rem" }} />
            </Tabs>
            <Typography variant="caption" className="mt-2 px-2">
              Yearly, SAVE 18%!
            </Typography>
          </Box>
        </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            // gridTemplateColumns: "repeat(1, 1fr)",
            justifyContent: "center",
            // gap: "2rem",
          }}
        >
          {subOptions.map((item, idx) => (
            idx > 0 // Display only the Starter Pricing info
              ? null 
              : <BillingType
                  key={item.name}
                  datas={item}
                  selectSubscription={() =>
                    navigate(`/payment?priceId=${subOptions[idx].id}`, {
                      state: {
                        subscription: subOptions[idx],
                      },
                    })
                  }
                  isActive={idx === subscriptionLevel - 1}
                />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PricingDetails;
