/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import ChallengeBg from "../../img/ca-bg.png";
import { useTheme, lighten } from "@mui/material/styles";
import SoftButton from "../../components/SoftButton";
import Rival from "../../components/OneVOneChallenge/Rival";
import ChallengeInfo from "../../components/OneVOneChallenge/ChallengeInfo";
import ChallengeInvite from "../../components/OneVOneChallenge/ChallengeInvite";
import Avatar from "../../components/Avatar";
import { UseUserDetails } from "../../hooks/auth";
import {
  UseCompanyLogo,
  UseCompanyIndustry,
  UseCompanyName,
} from "../../hooks/company";
import UserInfo from "../../components/OneVOneChallenge/UserInfo";
import { useParams } from "react-router-dom";
import {
  GET_CHALLENGE,
  GET_CHALLENGE_EVENT,
  GET_SCOREBOARD,
} from "../../api/challenge";
import InvitePopUp from "../../components/ChallengeArenaPopUp/InvitePopUp";
import SoftAvatar from "../../components/SoftAvatar";
import CompanyInfo from "../../components/OneVOneChallenge/CompanyInfo";
import ChallengeCountdown from "../../components/ChallengeArena/ChallengeCountdown";
import LoadingScreen from "../../components/LoadingScreen";
import "./1v1Challenge.css";
import GoldCrown from "../../img/arena/GoldCrown.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const OneVOneChallenge = () => {
  const theme = useTheme();
  const user = UseUserDetails();
  const logo = UseCompanyLogo();
  const industry = UseCompanyIndustry();
  const companyName = UseCompanyName();
  const {
    primary: { light: brighterPrimaryColor },
  } = theme.palette;
  const { challengeUrl } = useParams();

  const [isOpponent, setIsOpponent] = useState(false);
  const [isChallenge, setIsChallenge] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [showInvite, setShowInvite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [challengeScore, setChallengeScore] = useState(null);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);

  const lgBp = useMediaQuery(() => theme.breakpoints.up("lg"));
  const xsBp = useMediaQuery(() => theme.breakpoints.up("xs"));

  const fetchChallengeDetails = async () => {
    try {
      const { data } = await GET_CHALLENGE(challengeUrl);
      setChallengeDetails(data);
    } catch (error) {
      console.log("Challenge details error", error);
    }
  };

  useEffect(() => {
    if (challengeUrl) {
      fetchChallengeDetails();
    }
  }, [challengeUrl]);

  useEffect(() => {
    if (challengeDetails) {
      GET_SCOREBOARD(challengeDetails._id)
        .then(({ data }) => {
          if (user.companyId) {
            const companyIdx = data.findIndex(
              (c) => c.companyId === user.companyId,
            );
            if (companyIdx >= 0) {
              data[companyIdx].ownCompany = true;
            }
          }

          const scores = [...data];
          console.log(scores);
          scores.sort((a, b) => b.total - a.total);
          setChallengeScore(scores);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Challenge scoreboard error:", err.message);
        });
    }
  }, [challengeDetails, user.companyId]);

  useEffect(() => {
    if (challengeScore?.length > 1) {
      setIsOpponent(true);
    }

    if (challengeScore) {
      challengeScore.reduce((prev, current) => {
        // eslint-disable-next-line no-unused-expressions
        prev.total > current.total
          ? setCurrentLead(prev)
          : setCurrentLead(current);
      });
    }
  }, [challengeScore]);

  const handleClose = () => {
    setShowInvite(false);
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <SoftBox sx={{ height: { mini: "100%", md: "100vh" } }}>
      <SoftBox
        sx={{
          height: "100%",
          backgroundImage: `url(${challengeDetails?.image ?? ChallengeBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: { mini: "cover" },
          paddingTop: {
            mini: "72px",
            sm: "60px",
          },
          paddingX: { mini: "0.75rem", md: "1.5rem" },
          paddingBottom: { mini: "4.75rem", md: "1.5rem" },
        }}
      >
        <SoftBox
          sx={{
            display: { mini: "flex", md: "none" },
            justifyContent: "center",
            marginY: "2rem",
          }}
        >
          <ChallengeInfo
            isOpponent={isOpponent}
            isChallenge={isChallenge}
            challenge={challengeDetails}
            challengeScore={challengeScore}
            countdownEnded={countdownEnded}
            setCountdownEnded={setCountdownEnded}
          >
            <SoftButton
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                borderRadius: "9px",
                paddingX: { mini: "1rem", xl: "2rem" },
                background: "#EC2786",
                color: "white !important",
                "&:hover": { background: "#EC2786" },
                "&:focus:not(:hover)": {
                  background: lighten("#EC2786", 0.3),
                },
              }}
            >
              {challengeDetails?.title
                ? challengeDetails.title
                : "Challenge N/A"}
            </SoftButton>
          </ChallengeInfo>
        </SoftBox>
        <SoftBox
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mini: "1fr 0.2fr 1fr",
              sm: "1fr 0.4fr 1fr",
              md: "1.1fr 0.6fr 1.1fr",
            },
            gap: { mini: "1rem", sm: "2rem", lg: "3rem", xxl: "4rem" },
            height: { mini: "400px", md: "100%" },
          }}
        >
          <SoftBox
            sx={{
              borderRadius: "16px",
              borderWidth: "1px",
              borderStyle: "color",
              borderColor:
                countdownEnded && currentLead?.companyId === user.companyId
                  ? "rgba(255, 252, 0, 0.60)"
                  : brighterPrimaryColor,
              background:
                countdownEnded && currentLead?.companyId === user.companyId
                  ? "radial-gradient(50% 100% at 50% 0%, rgba(255, 252, 0, 0.30) 0%, rgba(255, 252, 0, 0.00) 100%), rgba(255, 252, 0, 0.50);"
                  : "radial-gradient(50.00% 100.00% at 50.21% 100.00%, rgba(28, 132, 207, 0.20) 0%, rgba(28, 132, 207, 0.00) 100%), rgba(28, 132, 207, 0.11)",
              backdropFilter:
                countdownEnded && currentLead?.companyId === user.companyId
                  ? "blur(16px)"
                  : "blur(40px)",
              "-webkit-backdrop-filter":
                countdownEnded && currentLead?.companyId === user.companyId
                  ? "blur(16px)"
                  : "blur(40px)",
              height: { mini: "100%", md: "90%", wide: "100%" },
              overflow: "hidden",
              marginY: "auto",
              boxShadow:
                countdownEnded && currentLead?.companyId === user.companyId
                  ? "-4px -4px 8px 0px rgba(255, 252, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 252, 0, 0.10) inset, 0px 0px 32px 0px rgba(255, 252, 0, 0.40)"
                  : "0px 0px 11.54595px 0px rgba(23, 119, 242, 0.40), 0px 0px 17.31892px 0px rgba(23, 119, 242, 0.20) inset",
            }}
          >
            {countdownEnded && currentLead?.companyId === user.companyId && (
              <SoftBox
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                  zIndex: 2,
                  width: "max-content",
                  height: {
                    mini: "270px",
                    xxs: "360px",
                    xs: "380px",
                    md: "480px",
                    lg: "650px",
                    wide: "800px",
                  },
                }}
              >
                <img
                  src={GoldCrown}
                  alt=""
                  style={{
                    width: "auto",
                    height: lgBp ? "2.25rem" : xsBp ? "1.75rem" : "1.25rem",
                  }}
                />
              </SoftBox>
            )}
            {challengeDetails?.category === "EmployeeVsEmployee" ? (
              <SoftBox
                sx={{
                  position: "absolute",
                  width: {
                    mini: "120px",
                    xxs: "180px",
                    xs: "220px",
                    md: "240px",
                    lg: "350px",
                    wide: "500px",
                  },
                  height: {
                    mini: "300px",
                    md: "400px",
                    lg: "550px",
                    wide: "720px",
                  },
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              >
                <Avatar
                  url={user.avatar}
                  logo={logo}
                  industry={industry}
                  noControls={true}
                />
              </SoftBox>
            ) : (
              <SoftBox
                sx={{
                  position: "absolute",
                  width: {
                    mini: "120px",
                    xxs: "180px",
                    xs: "220px",
                    md: "240px",
                    lg: "350px",
                    wide: "500px",
                  },
                  height: {
                    mini: "300px",
                    md: "400px",
                    lg: "550px",
                    wide: "720px",
                  },
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              >
                {challengeScore
                  ?.filter((comp) => comp.companyId === user.companyId)
                  .map((comp) => (
                    <Avatar
                      url={comp.owner?.avatar ?? user.avatar}
                      logo={comp.image}
                      industry={comp.industry}
                      noControls={true}
                    />
                  ))}
              </SoftBox>
            )}
            {challengeDetails?.category === "EmployeeVsEmployee"
              ? challengeScore
                  ?.filter((owner) => owner.companyId === user._id)
                  .map((owner) => <UserInfo user={owner} />)
              : challengeScore
                  ?.filter((comp) => comp.companyId === user.companyId)
                  .map((comp) => (
                    <CompanyInfo
                      companyName={comp.company}
                      companyLogo={comp.image}
                      companyScore={comp.total}
                    />
                  ))}
          </SoftBox>

          {/* Buttons Section */}
          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { mini: "center", md: "space-between" },
              height: { mini: "100%", md: "90%", wide: "100%" },
              marginY: "auto",
            }}
          >
            <ChallengeInfo
              isOpponent={isOpponent}
              isChallenge={isChallenge}
              challenge={challengeDetails}
              challengeScore={challengeScore}
              countdownEnded={countdownEnded}
              setCountdownEnded={setCountdownEnded}
            >
              <SoftButton
                variant="contained"
                size="large"
                sx={{
                  display: { mini: "none", md: "block" },
                  textTransform: "none",
                  borderRadius: "12px",
                  paddingX: { mini: "1rem", xl: "2rem" },
                  background: "#EC2786",
                  color: "white !important",
                  "&:hover": { background: "#EC2786" },
                  "&:focus:not(:hover)": {
                    background: lighten("#EC2786", 0.3),
                  },
                }}
              >
                {challengeDetails?.title
                  ? challengeDetails.title
                  : "Challenge N/A"}
              </SoftButton>
            </ChallengeInfo>

            <SoftTypography
              variant="h1"
              color="white"
              sx={{
                textAlign: "center",
                fontSize: {
                  mini: "2rem",
                  md: "6rem",
                  xxl: "8rem",
                  wide: "10rem",
                },
                fontStyle: "italic",
              }}
            >
              VS
            </SoftTypography>
            {challengeDetails?.category === "EmployeeVsEmployee" ? (
              isChallenge ? (
                <SoftBox
                  sx={{ display: { mini: "none", md: "flex" } }}
                  justifyContent="center"
                  flexDirection="column"
                  gap="1rem"
                >
                  <SoftBox
                    sx={{
                      marginX: "auto",
                    }}
                  >
                    <ChallengeCountdown
                      challenge={challengeDetails}
                      countdownEnded={countdownEnded}
                      setCountdownEnded={setCountdownEnded}
                      isWord={false}
                      isCentered
                      isBigWord
                      isMarginCentered
                    />
                  </SoftBox>
                  <SoftButton
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                      borderRadius: "12px",
                      paddingX: { mini: "1rem", xl: "2rem" },
                    }}
                  >
                    Share Now
                  </SoftButton>
                </SoftBox>
              ) : (
                <SoftBox
                  sx={{ display: { mini: "none", md: "flex" } }}
                  justifyContent="center"
                  flexDirection="column"
                  gap="1rem"
                >
                  <SoftBox
                    sx={{
                      marginX: "auto",
                    }}
                  >
                    <ChallengeCountdown
                      challenge={challengeDetails}
                      countdownEnded={countdownEnded}
                      setCountdownEnded={setCountdownEnded}
                      isWord={false}
                      isCentered
                      isBigWord
                      isMarginCentered
                    />
                  </SoftBox>
                  <ChallengeInvite>
                    <SoftButton
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        textTransform: "none",
                        borderRadius: "12px",
                        paddingX: { mini: "1rem", xl: "2rem" },
                      }}
                    >
                      Challenge Now
                    </SoftButton>
                  </ChallengeInvite>
                </SoftBox>
              )
            ) : (
              <SoftBox
                sx={{ display: { mini: "none", md: "flex" } }}
                justifyContent="center"
                flexDirection="column"
                gap="1rem"
              >
                <SoftBox
                  sx={{
                    marginX: "auto",
                  }}
                >
                  <ChallengeCountdown
                    challenge={challengeDetails}
                    countdownEnded={countdownEnded}
                    setCountdownEnded={setCountdownEnded}
                    isWord={false}
                    isCentered
                    isBigWord
                    isMarginCentered
                  />
                </SoftBox>
                <SoftButton
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    textTransform: "none",
                    borderRadius: "12px",
                    paddingX: { mini: "1rem", xl: "2rem" },
                  }}
                  onClick={() => {
                    setShowInvite(true);
                  }}
                >
                  + Invite your friends
                </SoftButton>
                {showInvite && (
                  <InvitePopUp
                    challengeUrl={challengeDetails?.url}
                    showInvite={showInvite}
                    closeInvite={handleClose}
                    {...challengeDetails}
                  />
                )}
              </SoftBox>
            )}
          </SoftBox>

          <Rival
            isOpponent={isOpponent}
            user={user}
            challengeScore={challengeScore}
            challengeDetails={challengeDetails}
            currentLead={currentLead}
            countdownEnded={countdownEnded}
          />
        </SoftBox>
        <SoftBox
          sx={{ display: { mini: "block", md: "none" }, marginY: "2rem" }}
        >
          {challengeDetails?.category === "EmployeeVsEmployee" ? (
            isChallenge ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                flexDirection="column"
                gap="1rem"
              >
                <SoftBox
                  sx={{
                    marginX: "auto",
                  }}
                >
                  <ChallengeCountdown
                    challenge={challengeDetails}
                    countdownEnded={countdownEnded}
                    setCountdownEnded={setCountdownEnded}
                    isWord={false}
                    isCentered
                    isMarginCentered
                  />
                </SoftBox>
                <SoftButton
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    textTransform: "none",
                    borderRadius: { mini: "8px", md: "12px" },
                    paddingX: { mini: "1rem", xl: "2rem" },
                  }}
                >
                  Share Now
                </SoftButton>
              </SoftBox>
            ) : (
              <SoftBox
                display="flex"
                justifyContent="center"
                flexDirection="column"
                gap="1rem"
              >
                <SoftBox
                  sx={{
                    marginX: "auto",
                  }}
                >
                  <ChallengeCountdown
                    challenge={challengeDetails}
                    countdownEnded={countdownEnded}
                    setCountdownEnded={setCountdownEnded}
                    isWord
                    isCentered
                    isMarginCentered
                  />
                </SoftBox>
                <ChallengeInvite>
                  <SoftButton
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      textTransform: "none",
                      borderRadius: { mini: "8px", md: "12px" },
                      paddingX: { mini: "1rem", xl: "2rem" },
                    }}
                  >
                    Challenge Now
                  </SoftButton>
                </ChallengeInvite>
              </SoftBox>
            )
          ) : (
            <SoftBox
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <SoftBox
                sx={{
                  marginX: "auto",
                }}
              >
                <ChallengeCountdown
                  challenge={challengeDetails}
                  countdownEnded={countdownEnded}
                  setCountdownEnded={setCountdownEnded}
                  isWord
                  isCentered
                  isMarginCentered
                  noBorder
                />
              </SoftBox>
              <SoftButton
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  textTransform: "none",
                  borderRadius: { mini: "8px", md: "12px" },
                  paddingX: { mini: "1rem", xl: "2rem" },
                  width: "max-content",
                  marginX: "auto",
                }}
                onClick={() => {
                  setShowInvite(true);
                }}
              >
                + Invite your friends
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default OneVOneChallenge;
