import React, { useState } from "react";

import SoftBox from "../../../components/SoftBox";
import SoftProfile from "../../../components/ProfilePictures/SoftProfile";
import SoftTypography from "../../../components/SoftTypography";

import AccountButton from "./components/AccountButton";

import useIntegrationsData from "./hooks/useIntegrationsData";
import { GET_IMAGE } from "../../../helpers/images";
import { GMB_LOGO_URL } from "../../../constants/branding";

const MAX_APPS = 2;

const IntegratedAccounts = () => {
  const { isLoading, connectedApps } = useIntegrationsData();
  const [isMaxApps, setIsMaxApps] = useState(true);

  const commonTitleStyles = {
    fontFamily: "DM Sans",
    fontWeight: 500
  }  

  return (
    <SoftBox
      sx={{
        "& h4": {
          ...commonTitleStyles,
          fontSize: "18px",
          lineHeight: "28px",
          color: "#000"
        },
        "& h5": {
          ...commonTitleStyles,
          fontSize: "12px",
          lineHeight: "18px",
          color: "#6C757D"
        },
      }}
    >
      {(!isLoading && connectedApps?.length === 0) &&
        <SoftTypography variant="h5">
          No connected apps
        </SoftTypography>
      }

      {(!isLoading && connectedApps?.length > 0) &&
        connectedApps?.map((app, index) => (
          (isMaxApps && index + 1 > MAX_APPS)
            ? null
            : <SoftBox
                key={app._id}
                sx={{
                  maxWidth: "656px",
                  minHeight: "75px",
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  padding: "12px",
                  marginBottom: "8px",
                  display: "flex",
                  gap: "8px",
                  justifyContent: "space-between",
                  flexDirection: {
                    mini: "column",
                    xs: "column",
                    sm: "row"
                  },
                  alignItems: {
                    mini: "justify",
                    xs: "justify",
                    sm: "center"
                  }
                }}
              >
                {/** Integrated app Logo and Name */}
                <SoftBox
                  sx={{
                    display: "flex",
                    gap: "10px",
                    "& img": { width: "32px", height: "32px" },
                  }}
                >
                  <SoftProfile
                    src={GET_IMAGE(app.logo) || GMB_LOGO_URL}
                    name={app.name}
                    alt={app.code}
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid #E9ECEF",
                      background: "transparent",
                      width: "52px",
                      height: "52px"
                    }}
                  />

                  <SoftBox>
                    <SoftTypography variant="h4">
                      {app.name}
                    </SoftTypography>
                    <SoftTypography variant="h5">
                      {app.desc ?? app.name}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>

                {/** Connected button */}
                <SoftBox>
                  <AccountButton
                    variant="text"
                    component="h4"
                    disabled
                    sx={{
                      padding: "8px 21px",
                      border: "1px solid #04549C",
                      float: {
                        mini: "right",
                        xs: "right",
                        sm: "unset"
                      }
                    }}
                  >
                    <SoftTypography
                      variant="h5"
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "11px",
                        color: "#04549C !important",
                        lineHeight: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      Connected
                    </SoftTypography>
                  </AccountButton>
                </SoftBox>
              </SoftBox>
      ))}

      {/** See All button */}
      {(!isLoading && connectedApps?.length > 0) &&
        <SoftBox sx={{ maxWidth: "656px", }}>
          <AccountButton
            variant="text"
            component="h4"
            onClick={() => setIsMaxApps(prev => !prev)}
            sx={{
              color: "#7928CA !important",
              textTransform: "capitalize",
              fontSize: "16px !important",
              padding: "16px 0 24px 0",
              lineHeight: "24px",
              border: "none",
              float: "right"
            }}
          >
            See all
          </AccountButton>
        </SoftBox>
      }
    </SoftBox>
  );
};

export default IntegratedAccounts;
