/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";

// Style Sheet File
import "./footerStyles.css";

// Imported Images and Icons
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FeedbackRecorder from "../FeedbackRecorder";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  return (
    <section className="px-6 pb-24 md:pb-6 container block xl:grid xl:grid-cols-2 xl:items-center">
      <div className="p-2 footerInfo text-base">
        <p className="text-[#8392AB]">
          All rights reserved. Copyright &#169; {year} made with{" "}
          <FavoriteRoundedIcon className="text-[#EA0606]" /> by {" "}
          <span className="font-semibold text-[#252F40]">Game My Biz</span>.
          {(process.env.REACT_APP_VERSION)
            ? <span>&nbsp;&nbsp; Version {process.env.REACT_APP_VERSION}</span>
            : <span>&nbsp;</span>
          }
        </p>
      </div>
      <div className="p-2 text-[#8392AB] w-[300px] xl:w-[350px] flex justify-between xl:justify-self-end footerLinks text-base">
        {/* <a href="">Documentation</a> */}
        <a href="">Blog</a>
        <a href="">Help</a>
        <a href="#" onClick={()=>setFeedbackOpen(true)}>Feedback</a>
      </div>
      {feedbackOpen && <FeedbackRecorder open={feedbackOpen} closeDialog={()=>setFeedbackOpen(false)} />}
    </section>
  );
}

export default Footer;
