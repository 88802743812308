/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import UploadIcon from "@mui/icons-material/Upload";
import FeedGallery from "./FeedGallery";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Divider, Stack, Typography, Grid } from "@mui/material";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import ImageIcon from "@mui/icons-material/Image";
import socket from "../../configs/socket";
import socketConstants from "../../constants/socket";
import moment from "moment";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { formatCurrency } from "../../helpers/formatter";
import CommentIcon from "@mui/icons-material/Comment";
import { GET_IMAGE } from "../../helpers/images";
import SlowClap from "../../components/SlowClap/SlowClap";
import { ADD_REACT, DELETE_REACT } from "../../api/react";
import { useDispatch } from "react-redux";
import { addComment, getJob } from "../../redux/jobs/actions";
import CommentList from "../../components/Comments/CommentList";
import { ADD_COMMENT, DELETE_COMMENT } from "../../api/comments";
import { UseIsAdmin, UseUserDetails } from "../../hooks/auth";
import { UseCompanyKpis } from "../../hooks/kpi";
import { UseCompanyLogo, UseCompanyColors } from "../../hooks/company";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { grey } from "@mui/material/colors";
import useMeasure from "react-use-measure";
import { isMobile } from "react-device-detect";
import SoftProfile from "../../components/ProfilePictures/SoftProfile";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PaidIcon from "@mui/icons-material/Paid";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTheme, lighten } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getFacebookShareable,
  getSocialUrl,
} from "../../components/Feed/socialImage";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { toast } from "react-hot-toast";

const GOOGLE_MAP_EMBED_API = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&zoom=13&q=`;

const { SOCKET_EVENT } = socketConstants;
const MOBILE_HEIGHT = "250px";
const DEFAULT_HEIGHT = "390px";

export default function FeedItem({ job, isModal }) {
  const dispatch = useDispatch();
  const { user, amount: jobTotal, metadata, comments } = job;
  const [ref, { width }] = useMeasure();
  const [showGallery, setShowGallery] = useState(false);
  const location =
    metadata?.location || "9839 Echo Valley Rd NW, Bremerton, WA 98312, USA";
  const [labels, setLabels] = useState([]);
  const [points, setPoints] = useState(0);
  const [kpis, setKpis] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const isAdmin = UseIsAdmin();
  const [url, setUrl] = useState("none");
  const [urlLinkedIn, setUrlLinkedIn] = useState("none");
  const [images, setImages] = useState([]);
  const [reacts, setReacts] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [toggleComment, setToggleComment] = useState(false);
  const [toggleShare, setToggleShare] = useState(false);
  const [toggleClaps, setToggleClaps] = useState(false);
  const shareButtonFacebook = useRef(null);
  const shareButtonLinkedIn = useRef(null);
  const downloadButton = useRef(null);
  const currentUser = UseUserDetails();
  const companyKpis = UseCompanyKpis();
  const companyLogo = UseCompanyLogo();
  const { primaryColor } = UseCompanyColors();
  const brightestPrimaryColor = lighten(primaryColor, 0.9);

  const appTheme = useTheme();
  const largeBp = useMediaQuery(appTheme.breakpoints.up("lg"));
  const miniBp = useMediaQuery(appTheme.breakpoints.up("mini"));
  const xLargeBp = useMediaQuery(appTheme.breakpoints.up("xl"));

  const height = isMobile ? MOBILE_HEIGHT : DEFAULT_HEIGHT;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchJob = (jobId) => {
    dispatch(getJob(jobId));
  };

  const onAddComment = async (jobId, senderId, content) => {
    const { data: comment } = await ADD_COMMENT({
      jobId,
      data: {
        userId: senderId,
        content,
        propertyModel: "Job",
      },
    });

    dispatch(addComment({ jobId, comment }));
    socket.emit(SOCKET_EVENT.REFRESH_JOB, { jobId });
    socket.emit(SOCKET_EVENT.POST_COMMENT, {
      sender: senderId,
      jobId,
      content,
    });
  };

  const onDeleteComment = async (commentId, jobId) => {
    await DELETE_COMMENT(commentId);
    socket.emit(SOCKET_EVENT.REFRESH_JOB, { jobId });
    await refetchJob(jobId);
  };

  const onReactToggle = async (
    propertyId,
    currentUserId,
    isActive,
    react,
    iconType,
    propertyModel,
  ) => {
    if (isActive) {
      socket.emit(SOCKET_EVENT.POST_SLOW_CLAP, {
        sender: currentUser._id,
        jobId: job._id,
      });

      await ADD_REACT({
        propertyId,
        data: {
          userId: currentUserId,
          propertyModel,
          iconType,
        },
      });
    } else {
      await DELETE_REACT(react._id);
    }
    const jobId = job._id;
    socket.emit(SOCKET_EVENT.REFRESH_JOB, { jobId });
    dispatch(getJob(jobId));
  };

  useEffect(() => {
    // Grant owner status for actual user and admins
    setIsOwner(job.userId === currentUser._id || isAdmin);
    setReacts(job.reacts);

    if (job.images && job.images.length) {
      setImages(job.images.map((key) => GET_IMAGE(key.src)));
      setLabels(job.images.map((key) => key.tag));
    } else {
      setImages([]);
      setLabels([]);
    }

    const jobKpis = [...job.kpis, ...(job.claims?.length ? job.claims : [])];
    if (jobKpis.length) {
      const total = jobKpis.reduce((prev, curr) => prev + curr.points, 0);
      setPoints(Math.floor(total));
      const kpiSummary = [];
      const groupKpis = companyKpis[user?.groupId];

      groupKpis?.forEach((kpi) => {
        const scoreMatch = jobKpis.find((score) => {
          if (score.kpiId === kpi._id) {
            return kpi;
          }
        });

        if (kpi.pointScale >= 1) {
          // Filter to exclude revenue KPIs
          if (kpi.direction >= 1 || scoreMatch) {
            // Display Positive KPIs whether hit or missed, But
            // Display Negative KPIs only when it exists
            kpiSummary.push({
              title: kpi.title,
              isActive: scoreMatch != null,
              ...scoreMatch,
            });
          }
        }
      });
      setKpis(kpiSummary);
    }
  }, [job, isAdmin, user?.groupId, currentUser, companyKpis]);

  useEffect(
    () => {
      socket.on(SOCKET_EVENT.NOTIFY_REFETCH_JOB, (data) => {
        if (data.jobId === job._id) {
          refetchJob(job._id);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job, refetchJob],
  );

  useEffect(() => {
    socket.emit(SOCKET_EVENT.JOIN_JOB, job._id);
  }, [job]);

  const getShareableUrl = async () => {
    const { _id, shareable, updatedAt } = job;
    // Return previously composed before & after image if there have been no changes in job data since
    if (shareable?.key && shareable.timestamp >= updatedAt) {
      return getSocialUrl(shareable.key);
    }
    // Else compose a new before & after image
    const shareableUrl = await getFacebookShareable(
      job._id,
      gallery,
      companyLogo,
      primaryColor,
    );
    dispatch(getJob(_id));
    return shareableUrl;
  };

  const onClick = async () => {
    if (url === "none") {
      try {
        const shareable = await getShareableUrl();
        setUrl(shareable);
        setTimeout(() => shareButtonFacebook.current?.click(), 250);
      } catch (err) {
        console.log("Shareable image error", err.response || err);
      }
    }
  };

  const onClickLinkedIn = async () => {
    if (urlLinkedIn === "none") {
      const shareable = await getShareableUrl();
      setUrlLinkedIn(shareable);
      setTimeout(() => shareButtonLinkedIn.current?.click(), 250);
    }
  };

  const onDownloadClick = async () => {
    const toastId = toast.loading("Preparing download ...");
    if (url === "none") {
      const shareable = await getShareableUrl();
      setUrl(shareable);
    }
    toast.success("Downloading ...", { id: toastId });
    setTimeout(() => downloadButton.current?.click(), 250);
  };

  return (
    <SoftBox
      sx={{
        width: "100%",
        padding: {
          mini: "1rem",
          sm: "1.5rem",
          background: "white",
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 0px 3px 0px rgba(0, 0, 0, 0.04), 2px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 16px 0px rgba(0, 0, 0, 0.02), 0px 12px 24px 0px rgba(0, 0, 0, 0.01)",
          borderRadius: "16px",
          marginBottom: "1rem",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item mini={12} md={isModal ? 12 : 6} lg={isModal ? 6 : 6}>
          <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <SoftBox
              className="flex gap-x-[1rem]"
              sx={{
                display: "flex",
                gapX: "1rem",
                flexDirection: { mini: "column", md: "row" },
              }}
            >
              <SoftBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: { mini: "0.5rem", md: 0 },
                }}
              >
                <SoftProfile
                  src={user.image}
                  size={largeBp ? "xxl" : "xl"}
                  name={user.firstName}
                />
              </SoftBox>
              <SoftBox>
                <SoftBox
                  bgColor="primary"
                  sx={{
                    borderRadius: "6px",
                    width: "max-content",
                    marginBottom: "0.5rem",
                    padding: "8px 12px",
                    display: { mini: "none", md: "block" },
                  }}
                >
                  <SoftTypography
                    variant="subtitle2"
                    sx={{
                      color: "primary.contrastText",
                    }}
                  >
                    {points} points
                  </SoftTypography>
                </SoftBox>
                <SoftBox sx={{ display: { mini: "none", md: "block" } }}>
                  <SoftTypography variant="h4" color="black">
                    {user.firstName} {user.lastName}
                  </SoftTypography>
                  <SoftBox>
                    <SoftTypography
                      variant="body2"
                      sx={{ color: `grey.${600}` }}
                    >
                      {location}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              bgColor="light"
              sx={{
                borderRadius: "5px",
                width: "max-content",
                padding: "4px 10px",
                height: "max-content",
                alignItems: "center",
                justifyContent: "center",
                display: { mini: "none", md: "flex" },
              }}
            >
              <SoftTypography variant="caption" color="black">
                {moment(job.extRefDate).format("ddd | MMM Do YYYY")}
              </SoftTypography>
            </SoftBox>

            <SoftBox
              sx={{
                alignItems: "end",
                flexDirection: "column",
                gap: "10px",
                display: { mini: "flex", md: "none" },
              }}
            >
              <SoftBox
                bgColor="light"
                sx={{
                  borderRadius: "5px",
                  width: "max-content",
                  padding: "4px 10px",
                  height: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SoftTypography variant="caption" color="black">
                  {moment(job.extRefDate).format("ddd | MMM Do YYYY")}
                </SoftTypography>
              </SoftBox>
              <SoftBox
                bgColor="primary"
                sx={{
                  borderRadius: "6px",
                  width: "max-content",
                  marginBottom: "0.5rem",
                  padding: "8px 12px",
                }}
              >
                <SoftTypography
                  variant="subtitle2"
                  sx={{
                    color: "primary.contrastText",
                  }}
                >
                  {points} points
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox sx={{ display: { mini: "block", md: "none" } }}>
            <SoftTypography variant={largeBp ? "h4" : "h5"} color="black">
              {user.firstName} {user.lastName}
            </SoftTypography>
            <SoftBox>
              <SoftTypography
                variant={largeBp ? "body2" : "button"}
                sx={{ color: `grey.${600}`, fontWeight: 400 }}
              >
                {location}
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

          <SoftBox
            sx={{
              display: "flex",
              paddingBottom: "0px",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
            }}
          >
            {kpis.length ? (
              kpis.map((kpi) => {
                return (
                  <SoftBox
                    key={kpi.title}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: {
                        mini: "8px 8px 16px 8px",
                        lg: "14px 14px 22px 14px",
                      },
                      gap: { mini: "6px", lg: "10px" },
                      flex: "1 0 0",
                      borderRadius: "16px",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: (theme) => theme.palette.primary.lighter,
                      background: (theme) => theme.palette.kpiGradient,
                    }}
                  >
                    <SoftTypography
                      variant={largeBp ? "subtitle2" : "button"}
                      color="primary"
                      sx={{ textTransform: "uppercase", fontWeight: 500 }}
                    >
                      {kpi.title}
                    </SoftTypography>
                    <SoftTypography
                      variant={largeBp ? "h5" : "h6"}
                      color="primary"
                    >
                      Amount: {kpi.amount || 0}
                    </SoftTypography>
                  </SoftBox>
                );
              })
            ) : (
              <SoftTypography
                color="black"
                variant="h6"
                sx={{ textAlign: "center", width: "100%" }}
              >
                REGISTER [{user.firstName.toUpperCase()}{" "}
                {user.lastName.toUpperCase()}] TO A WORKSPACE
              </SoftTypography>
            )}
          </SoftBox>

          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

          <SoftBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: 2,
            }}
          >
            <SoftTypography variant={largeBp ? "h4" : "h5"} color="black">
              {metadata.customerName} //
            </SoftTypography>
            <SoftTypography
              variant={largeBp ? "h5" : "h6"}
              sx={{ color: `grey.${800}` }}
            >
              {metadata.title || metadata.description}
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
            }}
          >
            <SoftBox
              sx={{
                display: "flex",
                padding: {
                  mini: "7px 12px",
                  sm: "10.5px 18px",
                  lg: "14px 24px",
                },
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                flex: "1 0 0",
                background: (theme) => theme.palette.primary.lightest,
                borderRadius: "16px",
                boxShadow: `0px 0px 1px 0px ${brightestPrimaryColor}`,
              }}
            >
              <SoftTypography
                variant={largeBp ? "body2" : "button"}
                sx={{ color: `grey.${600}`, fontWeight: 400 }}
              >
                Job Total
              </SoftTypography>
              <SoftTypography variant={largeBp ? "h3" : "h4"} color="black">
                {formatCurrency(jobTotal || metadata.total)}
              </SoftTypography>
            </SoftBox>

            <SoftBox
              sx={{
                display: "flex",
                padding: {
                  mini: "7px 12px",
                  sm: "10.5px 18px",
                  lg: "14px 24px",
                },
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                flex: "1 0 0",
                background: (theme) => theme.palette.primary.lightest,
                borderRadius: "16px",
                boxShadow: `0px 0px 1px 0px ${brightestPrimaryColor}`,
              }}
            >
              <SoftTypography
                variant={largeBp ? "body2" : "button"}
                sx={{ color: `grey.${600}`, fontWeight: 400 }}
              >
                Time
              </SoftTypography>
              <SoftTypography variant={largeBp ? "h3" : "h4"} color="black">
                {new Date(metadata.duration * 1000)
                  .toISOString()
                  .substring(11, 16)}
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <SoftBox
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <SlowClap
                jobId={job._id}
                currentUserId={currentUser._id}
                reacts={reacts}
                onToggle={onReactToggle}
                showDetails={false}
                setToggleClaps={setToggleClaps}
                toggleClaps={toggleClaps}
              />

              <SoftBox
                sx={{ display: "flex", gap: "6px", alignItems: "center" }}
              >
                <SoftTypography variant="h6" color="black">
                  {comments.length}
                </SoftTypography>
                <SoftButton
                  type="button"
                  color="primary"
                  variant={toggleComment ? "contained" : "text"}
                  sx={{
                    minWidth: "max-content",
                    minHeight: "max-content",
                    borderRadius: "50%",
                    padding: toggleComment ? "0.5rem" : 0,
                  }}
                >
                  <CommentIcon
                    onClick={() => setToggleComment(!toggleComment)}
                  />
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <SoftTypography
                variant={largeBp ? "body2" : "button"}
                sx={{ color: `grey.${600}`, fontWeight: 400 }}
              >
                SHARE
              </SoftTypography>
              <LinkedinShareButton
                ref={shareButtonLinkedIn}
                openShareDialogOnClick={urlLinkedIn !== "none"}
                url={urlLinkedIn}
                onClick={onClickLinkedIn}
                disabled={!gallery.length || !isOwner}
              >
                <LinkedinIcon round size={largeBp ? 32 : 24} />
              </LinkedinShareButton>
              <FacebookShareButton
                ref={shareButtonFacebook}
                openShareDialogOnClick={url !== "none"}
                url={url}
                onClick={onClick}
                disabled={!gallery.length || !isOwner}
              >
                <FacebookIcon round size={largeBp ? 32 : 24} />
              </FacebookShareButton>
              <SoftButton
                color="primary"
                iconOnly={true}
                circular={true}
                size="small"
                sx={{
                  height: largeBp ? "32px" : "24px",
                  width: largeBp ? "32px" : "24px",
                }}
                disabled={!gallery.length || !isOwner}
                onClick={onDownloadClick}
              >
                <FileDownloadIcon sx={{ height: "80%", width: "80%" }} />
              </SoftButton>
              <a
                ref={downloadButton}
                href={url}
                download={`${metadata.customerName} - ${
                  metadata.title ?? metadata.description
                }.jpg`}
                rel="noopener noreferrer"
                target="_blank"
                hidden
              >
                Download
              </a>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item mini={12} md={isModal ? 12 : 6} lg={isModal ? 6 : 6}>
          <SoftBox sx={{ width: "100%", height: "100%" }}>
            <SoftBox
              sx={{
                position: "absolute",
                right: {
                  mini: isModal ? "7vw" : "4rem",
                  xs: isModal ? "10vw" : "4rem",
                  sm: isModal ? "16vw" : "4rem",
                  md: isModal ? "14vw" : "4rem",
                  lg: isModal ? "13vw" : "4rem",
                  xl: isModal ? "17vw" : "4rem",
                  wide: isModal ? "16.5vw" : "4rem",
                },
                marginTop: "0.75rem",
                zIndex: 1,
              }}
            >
              {location && (
                <iframe
                  title={location}
                  frameBorder="0"
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`${GOOGLE_MAP_EMBED_API}${encodeURIComponent(location)}`}
                  style={{
                    width: isModal ? "140px" : "184px",
                    height: isModal ? "140px" : "184px",
                    borderRadius: "50%",
                    borderWidth: "3px",
                    borderStyle: "solid",
                    borderColor: primaryColor,
                  }}
                />
              )}
            </SoftBox>
            {images.length ? (
              <SoftBox sx={{ position: "relative", height: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ImageCarousel
                    images={images}
                    labels={labels}
                    height={height}
                  />
                </Box>
                {isOwner && (
                  <IconButton
                    aria-label="upload image"
                    size="large"
                    color="secondary"
                    component="label"
                    onClick={() => setShowGallery(true)}
                    className="!absolute !mt-[-56px] !ml-[18px] z-[2] !py-[4px] !px-[4px] rounded-[50%] !bg-[#fff]"
                  >
                    <UploadIcon fontSize="inherit" color="primary" />
                  </IconButton>
                )}
              </SoftBox>
            ) : (
              <SoftBox sx={{ position: "relative", height: "100%" }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={(theme) => ({
                    background: grey[200],
                    border: `2px dashed ${theme.palette.primary.main}`,
                    borderRadius: "8px",
                    height: {
                      mini: isModal ? "180px" : "390px",
                      md: isModal ? "180px" : "100%",
                      lg: "100%",
                    },
                  })}
                >
                  {isOwner && !isModal ? (
                    <>
                      <IconButton
                        aria-label="upload image"
                        component="label"
                        onClick={() => setShowGallery(true)}
                        sx={(theme) => ({
                          fontSize: 100,
                          color: theme.palette.primary.main,
                          [theme.breakpoints.down("sm")]: {
                            fontSize: 50,
                          },
                        })}
                      >
                        <UploadFileIcon
                          sx={(theme) => ({
                            fontSize: 50,
                            color: theme.palette.primary.main,
                            [theme.breakpoints.down("sm")]: {
                              fontSize: 40,
                            },
                          })}
                          color="primary"
                        />
                      </IconButton>
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          textDecoration: "underline",
                        })}
                        onClick={() => setShowGallery(true)}
                      >
                        Click to upload photo
                      </Typography>
                      <Typography
                        sx={{ color: "rgba(0, 0, 0, 0.75)", opacity: "0.75" }}
                      >
                        PNG, JPG, or BMP
                      </Typography>
                    </>
                  ) : (
                    <>
                      <ImageIcon
                        sx={(theme) => ({
                          fontSize: 100,
                          [theme.breakpoints.down("sm")]: {
                            fontSize: 50,
                          },
                        })}
                        color="primary"
                      />
                      <Typography
                        sx={{ color: "rgba(0, 0, 0, 0.75)", opacity: "0.75" }}
                      >
                        No image available
                      </Typography>
                    </>
                  )}
                </Stack>
              </SoftBox>
            )}

            {isOwner && (
              <FeedGallery
                jobId={job._id}
                images={job.images}
                open={showGallery}
                setGallery={setGallery}
                gallery={gallery}
                exit={() => setShowGallery(false)}
              />
            )}
          </SoftBox>
        </Grid>
      </Grid>

      <Divider
        sx={{ marginBottom: 2, marginTop: 2 }}
        className="job-details-sm-display"
      />

      {/* Visible on desktop version only */}
      {/* <div className="grid grid-cols-3 grid-rows-[max-content_max-content] gap-y-[1rem] justify-items-start sm:grid-cols-3 sm:gap-x-[1rem] sm:gap-y-[0rem] job-details-sm-hide">
        <div>
          <p className="text-lg sm:text-xl md:text-2xl font-bold">
            {metadata.customerName}
          </p>
          <Typography
            className="!text-base sm:!text-lg md:!text-xl !font-semibold"
            sx={{ color: "primary.main" }}
          >
            {metadata.title || metadata.description}
          </Typography>
          <div className="flex gap-x-[1rem]">
            <div>
              <p className="text-sm sm:text-base md:text-lg font-semibold text-[#787878]">
                Job Total
              </p>
              <p className="text-xs sm:text-sm md:text-base text-[#787878]">
                {formatCurrency(jobTotal || metadata.total)}
              </p>
            </div>
            <div>
              <p className="text-sm sm:text-base md:text-lg font-semibold text-[#787878]">
                Time
              </p>
              <p className="text-xs sm:text-sm md:text-base text-[#787878]">
                {new Date(metadata.duration * 1000)
                  .toISOString()
                  .substring(11, 16)}
              </p>
            </div>
          </div>
        </div>
        <div className="justify-self-center">
          <div className="flex gap-x-[1rem] md:gap-x-[3rem] px-[1rem] py-[0.5rem] justify-center ">
            <SlowClap
              jobId={job._id}
              currentUserId={currentUser._id}
              reacts={reacts}
              onToggle={onReactToggle}
              showDetails={true}
            />

            <div className="flex gap-x-[0.5rem] pt-[0.5rem]">
              <CommentIcon onClick={() => setToggleComment(!toggleComment)} />
              <p className="text-xs sm:text-sm md:text-base font-semibold">
                {comments.length}
              </p>
            </div>
          </div>
        </div>
        <div className="social-share"> */}
      {/* <JobFeedMetaData image={url !== "none" ? url : undefined} /> */}
      {/* <Typography
            className="!text-base sm:!text-lg md:!text-xl !font-semibold"
            sx={{ marginBottom: 1 }}
          >
            Share:
          </Typography>
          <FacebookShareButton
            style={{ marginRight: 5 }}
            ref={shareButton}
            openShareDialogOnClick={url !== "none"}
            url={url}
            onClick={onClick}
            disabled={!gallery.length || !isOwner}
          >
            <FacebookIcon round size={32} />
          </FacebookShareButton>
          <LinkedinShareButton
            style={{ marginRight: 5 }}
            ref={shareButtonLinkedIn}
            openShareDialogOnClick={urlLinkedIn !== "none"}
            url={urlLinkedIn}
            onClick={onClickLinkedIn}
            disabled={!gallery.length || !isOwner}
          >
            <LinkedinIcon round size={32} />
          </LinkedinShareButton>
        </div>
      </div> */}

      <Divider
        sx={{ marginBottom: 2, marginTop: 2 }}
        className="job-details-sm-display"
      />

      {/* Comments Section */}
      {toggleComment ? (
        <>
          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
          <div className="flex-auto">
            <CommentList
              comments={comments}
              currentUser={currentUser}
              jobId={job._id}
              onAddComment={onAddComment}
              onToggleFavorite={onReactToggle}
              onDeleteComment={onDeleteComment}
            />
          </div>
        </>
      ) : null}

      {toggleShare ? (
        <>
          <Divider sx={{ marginBottom: 1, marginTop: 0 }} />
          <Typography
            className="!text-base sm:!text-lg md:!text-xl !font-semibold text-center"
            sx={{ marginBottom: 1 }}
          >
            Share:
          </Typography>
          <div className="flex justify-center">
            <FacebookShareButton
              style={{ marginRight: 5 }}
              ref={shareButtonFacebook}
              openShareDialogOnClick={url !== "none"}
              url={url}
              onClick={onClick}
              disabled={!gallery.length || !isOwner}
            >
              <FacebookIcon round size={32} />
            </FacebookShareButton>
            <LinkedinShareButton
              style={{ marginRight: 5 }}
              ref={shareButtonLinkedIn}
              openShareDialogOnClick={urlLinkedIn !== "none"}
              url={urlLinkedIn}
              onClick={onClickLinkedIn}
              disabled={!gallery.length || !isOwner}
            >
              <LinkedinIcon round size={32} />
            </LinkedinShareButton>
          </div>
        </>
      ) : null}
    </SoftBox>
  );
}
