import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import { types } from "./types";
import {
  CREATE_ONE_VS_ONE_CHALLENGE,
  GET_ONE_VS_ONE_CHALLENGE,
  JOIN_ONE_VS_ONE_CHALLENGE,
} from "../../api/challenge";

function* CreateChallenge(action) {
  try {
    const { data } = yield call(() =>
      CREATE_ONE_VS_ONE_CHALLENGE(action.payload),
    );
    if (data) {
      toast.success(data);
    }

    yield put({
      type: types.CREATE_CHALLENGE_REQUEST,
    });
  } catch (err) {
    toast.error(err?.response?.data?.error?.message || "Something went wrong");
    yield put({
      type: types.CREATE_CHALLENGE_FAILED,
      error: err?.response?.data?.error?.message,
    });
  }
}

function* watchFetchChatsRequest() {
  yield takeEvery(types.CREATE_CHALLENGE_REQUEST, CreateChallenge);
}

// root saga container
function* rootSaga() {
  yield all([fork(watchFetchChatsRequest)]);
}

export default rootSaga;
