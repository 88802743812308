import React from "react";

import Stack from "@mui/material/Stack";
import defaultAvatar from "../../img/avatar2.png";
import { GET_IMAGE } from "../../helpers/images";

const AudioBite = ({ src, avatarImage }) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
      <img
        className="w-16 h-16 rounded-full border-2 border-white dark:border-gray-800"
        src={avatarImage ? GET_IMAGE(avatarImage) : defaultAvatar}
        alt=""
      />
      {src && (
        /* eslint-disable-next-line jsx-a11y/media-has-caption,react/no-unknown-property */
        <audio controls preload="auto" style={{ width: "100%" }} autoPlay={false} playsInline muted={false}>
          <source src={src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </Stack>
  );
};

export default AudioBite;
