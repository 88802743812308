export const METRIC_OPTIONS = {
  PROGRESSIVE: "progressive",
  ANCHOR: "anchor"
};

export const ANCHOR_BUTTON_STATE = {
  IDLE: "idle",
  OPEN: "open",
  EDITED: "edited"
};

export const KINDS_INFO = [
  {
    kind: "%",  // Percentage
    info: "Enter an anchor point from 0% - 100%"
  },
  {
    kind: "$",  // Dollar
    info: "Enter an Anchor point of any value of your choice"
  },
  {
    kind: "#",  // Item
    info: "Enter an Anchor point of any value of your choice"
  }
];

