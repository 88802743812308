import React from "react";

import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

const UnlockFullPotential = () => {
  return (
    <SoftBox
      sx={{
        marginBottom: "80px",
        width: "45%",
        marginLeft: "auto",
        marginRight: "auto",
        "@media screen and (max-width: 1200px)": {
          width: "90%",
        },
      }}
    >
      <SoftTypography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          color: "#475467",
          textAlign: "center",
        }}
      >
        Unlock the full potential of your workspace with a customized
        point-based system. Start creating metrics that matter and transform the
        way your team works and performs. Get started now and watch your
        productivity soar!
      </SoftTypography>
    </SoftBox>
  );
};

export default UnlockFullPotential;
