import React from "react";

import VectorIcon from "../../img/vector.png";
import SoftTypography from "../SoftTypography";
import "./style.css";

const UnselectedChat = () => {
  return (
    <div className="unselected-view">
      <div className="content-view">
        <img src={VectorIcon} alt="GameMyBiz" style={{ marginBottom: 10 }} />
        <SoftTypography variant="h6" sx={{ color: "#6C757D" }}>
          Start a conversation and connect with your colleagues
        </SoftTypography>
        <SoftTypography variant="p" sx={{ color: "#6C757D" }}>
          Spark meaningful conversations
        </SoftTypography>
      </div>
    </div>
  );
};

export default UnselectedChat;
