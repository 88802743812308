import { makeStyles } from "@mui/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

export const stepperPageStyles = makeStyles({
  // General Styles
  pageWrapper: {
    display: "grid",
    gridTemplateColumns: "5.5rem auto",
  },

  responsiveContainer: {
    margin: "10px auto",
    width: "100%",

    "@media screen and (min-width: 1921px)": {
      maxWidth: "1400px",
    },

    "@media screen and (max-width: 1920px)": {
      maxWidth: "1100px",
    },

    "@media screen and (max-width: 1200px)": {
      maxWidth: "800px",
    },

    "@media screen and (max-width: 1024px)": {
      maxWidth: "700px",
    },

    "@media screen and (max-width: 768px)": {
      maxWidth: "500px",
    },

    "@media screen and (max-width: 480px)": {
      maxWidth: "400px",
    },
  },

  pText: {
    fontFamily: "DM Sans, Inter, Arial, sans-serif",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    margin: 0,
  },

  boldText: {
    fontWeight: "bold",

    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontWeight: "bold",
    },
  },

  lightBoldedText: {
    fontWeight: "600",
  },

  centeredText: {
    textAlign: "center !important",
  },

  blockDisplay: {
    display: "block",
  },

  fullWidth: {
    width: "100%",
  },

  normalBottomSpace: {
    marginBottom: "1.5rem",
  },

  // Component Styles

  // Page Info Component
  pageInfo: {
    width: "100%",
    background: "#FFADD6",
    padding: "0.5rem",
  },

  textContainer: {
    display: "flex",
  },

  pageInfoIcon: {
    fontSize: "1.1rem !important",
    marginRight: "0.2rem",
  },

  headerInfo: {
    fontWeight: "bold",
    margin: "0 5px 0 0",
    fontSize: "14px",
  },

  subHeadingInfo: {
    fontSize: "14px",
    lineHeight: "1.4",
  },

  // Page Stepper Component
  stepperContainer: {
    marginTop: "2rem",
  },

  stepperWrapper: {
    width: "100%",
    marginBottom: "3rem",
  },

  stepperContentContainer: {
    // boxShadow: "0px 2px 6px 2px rgba(135, 135, 135, 0.4)",
    // borderRadius: "1rem",
    margin: "3rem 2rem 2rem 2rem",
  },

  stepperHeader: {
    textAlign: "center",
  },

  stepperButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    padding: "1rem 2rem",
    marginTop: "10px",
  },

  // Step 1: Gameboard Styles
  gameboardContainer: {
    padding: "2rem 5rem !important",
  },

  gameboardGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "1.5rem",

    "@media screen and (max-width: 768px)": {
      display: "block",

      "& > div:nth-child(-n+2)": {
        marginBottom: "2rem",
      },
    },
  },

  gameSelectionContainer: {
    maxWidth: 300,
    margin: "0 auto",
    borderRadius: "1rem !important",
  },

  gameSelectionDescription: {
    paddingTop: "0 !important",
  },

  // Step 2: Company Info and KPIs
  companyContainer: {
    padding: "2rem 4rem",
    boxShadow: "0px 2px 6px 2px rgba(135, 135, 135, 0.4)",
    borderRadius: "2.5rem",

    "@media screen and (max-width: 768px)": {
      padding: "1rem 2rem",
    },
  },

  companyInfoContainer: {
    padding: "2rem 2rem",

    "@media screen and (max-width: 768px)": {
      padding: "1rem 1rem",
    },
  },

  companyInputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "2.5rem",

    "@media screen and (max-width: 768px)": {
      display: "block",
    },
  },

  companyInputSubcontainer: {
    "& div:nth-of-type(-n+4)": {
      marginBottom: "1rem",
    },

    "@media screen and (max-width: 768px)": {
      "& div": {
        marginBottom: "1rem",
      },
    },
  },

  googleBusinessContainer: {
    maxWidth: "1200px",
    padding: "3rem 24px",
    margin: "0 auto",

    "@media screen and (max-width: 768px)": {
      padding: "2rem 1rem",
    },
  },

  googleBusinessHeadingContainer: {
    display: "flex",
    alignItems: "center",
  },

  googleBusinessIconContainer: {
    width: "50px",
    marginRight: "1rem",
  },

  googleBusinessIcon: {
    width: "100%",
  },

  googleBusinessHeading: {
    margin: "0",
  },

  googleBusinessText: {
    fontWeight: 300,
  },

  companyOverviewContainer: {
    maxWidth: "1200px",
    padding: "3rem 24px",
    margin: "0 auto",

    "@media screen and (max-width: 768px)": {
      padding: "2rem 1rem",
    },
  },

  companySearchIcon: {
    position: "absolute",
    color: "#9f9f9e",
    marginLeft: "0.25rem",
    marginTop: "0.75rem",
    fontSize: "1.75rem",
  },

  companyGoogleInput: {
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
    transition: "all 0.15s ease-in",
    backgroundColor: "#fcfcfb",
    padding: "10px 12px 10px 30px",
    width: "350px",

    "&:focus": {
      outline: "0",
      borderColor: "#ee238a",
      boxShadow: "rgba(238, 35, 138, 0.25) 0 0 0 0.2rem",
    },

    "@media screen and (max-width: 480px)": {
      width: "300px",
    },
  },

  companyOverviewTextarea: {
    height: "200px",
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
    transition: "all 0.15s ease-in",
    backgroundColor: "#fcfcfb",

    "&:focus": {
      outline: "0",
      borderColor: "#ee238a",
      boxShadow: "rgba(238, 35, 138, 0.25) 0 0 0 0.2rem",
    },
  },

  // Step 2: KPIs Section
  chooseKpiContainer: {
    marginTop: "4rem",
    padding: "2rem 4rem",
    boxShadow: "0px 2px 6px 2px rgba(135, 135, 135, 0.4)",
    borderRadius: "2.5rem",
  },

  chooseKpiHeading: {
    marginTop: "40px",
    marginBottom: "1rem",
  },

  chooseKpiText: {
    marginBottom: "2rem",
  },

  chooseTopKpiContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "none",
    justifyContent: "space-evenly",
    margin: "1rem auto 2rem",
    gridColumnGap: "0.5rem",
    alignItems: "center",

    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: "none",
      gridTemplateRows: "auto",
      gridRowGap: "1rem",
    },
  },

  chooseLabelText: {
    marginBottom: "0.25rem",
  },

  // Step 2: Track KPI Section
  trackKpiContainer: {
    margin: "2rem auto",
    maxWidth: "650px",
  },

  trackKpiHeaderSpace: {
    marginBottom: "2rem",
  },

  trackKpiWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "1rem",
    justifyItems: "center",

    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: "none",
      gridTemplateRows: "repeat(3,1fr)",
      gridRowGap: "1rem",
    },
  },

  trackKpiCard: {
    width: "100%",
    maxWidth: "152px",
    padding: "1rem",
    boxShadow: "0px 2px 6px 2px rgba(135, 135, 135, 0.4)",
    borderRadius: "1.25rem",
    textAlign: "center",
  },

  trackKpiCardMedia: {
    marginBottom: "0.25rem",
  },

  // Step 3: Choose Bad Guy
  badguyContainer: {
    padding: "2rem 5rem !important",
  },

  badguyGridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "1.5rem",

    "@media screen and (max-width: 768px)": {
      display: "block",

      "& > div:nth-child(-n+2)": {
        marginBottom: "2rem",
      },
    },
  },

  badguySelectionContainer: {
    maxWidth: 300,
    margin: "0 auto",
    borderRadius: "1rem !important",
    height: "100%",
    display: "block",
  },

  badguyCardAction: {
    height: "100%",
  },

  badguySelectionDescription: {
    paddingTop: "0 !important",
  },

  badguyDifficultyLevel: {
    color: "#EE238A",
    marginBottom: "0.5rem !important",
  },

  // Bad Guy KPI
  badGuyChooseKpiContainer: {
    boxShadow: "0px 2px 6px 2px rgba(135, 135, 135, 0.4)",
    padding: "2rem 3rem",
    margin: "2rem 1rem",
    borderRadius: "1rem",
  },

  badGuyTopKpiContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 100px 1fr",
    gridTemplateRows: "none",
    justifyContent: "space-evenly",
    margin: "1rem auto 2rem",
    gridColumnGap: "0.5rem",
    alignItems: "center",

    "@media screen and (max-width: 768px)": {
      gridTemplateColumns: "none",
      gridTemplateRows: "auto",
      gridRowGap: "1rem",
    },
  },

  // Step 4: User Management Styles
  userSubheading: {
    background: "linear-gradient(358deg, #7928ca, #ff0080)",
    padding: "1rem",
    color: "white",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },

  usersManagementSubContainer: {
    padding: "1rem 2rem",
  },

  userDescriptionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  userDescriptionText: {
    color: "#8392AB",
    marginBottom: 0,
  },

  userManagementButton: {
    fontWeight: "bold !important",
    background: "linear-gradient(360deg, #2152ff, #21d4fd)",
    fontSize: "0.8125rem !important",
    padding: "4px 10px !important",
    minHeight: "36px",
  },

  userFilterContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: "1.5rem auto",
  },

  dropdownPlayerContainer: {
    width: "60px",
  },

  dropdownDeptContainer: {
    width: "120px",
  },

  dropdownButton: {
    padding: "2px 8px",
    background: "transparent",
    border: "1px solid #CB0C9F",
    fontWeight: "600",
    color: "#CB0C9F",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },

  dropdownContent: {
    fontWeight: "600",
    position: "absolute",
    zIndex: "2",
    background: "#fff",
    border: "1px solid #CB0C9F",
    padding: "2px 8px",
    borderRadius: "0.5rem",
  },

  dropdownPlayerContent: {
    width: "60px",
  },

  dropdownDeptContent: {
    width: "120px",
  },

  dropdownItem: {
    background: "",
  },

  userFilterSubcontainerOne: {
    display: "flex",
    alignItems: "center",
  },

  userFilterSubcontainerTwo: {
    display: "flex",
    alignItems: "center",
    justifySelf: "end",
  },

  tableHeadingSpace: {
    "& th:nth-child(n+2) > span": {
      marginLeft: "20px",
    },
  },

  tableIconFilter: {
    "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
      opacity: "0.2",
      marginRight: "0px",
    },
  },

  userPaginationContainer: {
    display: "flex",
    justifyContent: "end",
  },

  userPagination: {
    "& button:not(disabled)": {
      boxShadow: "1px 1px 1px #8888",
    },
    "& .Mui-selected": {
      background: "linear-gradient(300deg, #7928ca, #ff0080)",
      color: "white",
    },
  },
});

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg, #7928ca, #ff0080)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg, #7928ca, #ff0080)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

export const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 130deg, #ff0080, #7928ca)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient( 130deg, #ff0080, #7928ca)",
  }),
}));
