import React from "react";

import Leaders from "./Leaders";
import ProgressBar from "./ProgressBar";
import SoftBox from "../../SoftBox";

const LiveLeaderboardComponent = ({
  topList = [],
  groupKpis = null,
  allKpis = null,
  isAdmin = false,
  isViewOnly = false,
  isPointsPercent = false,
  periodIdx = 0,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  return (
    <SoftBox sx={{ marginTop: { mini: "1rem", lg: "2rem" } }}>
      <SoftBox
        sx={{
          display: "grid",
          gridTemplateAreas: {
            mini: `"gameboard"
                  "leaderboard"`,
            lg: `"leaderboard gameboard"`,
          },
          gridTemplateColumns: { lg: "1fr 1fr" },
          gap: { mini: "1rem", md: "1.25rem" },
        }}
      >
        <SoftBox
          sx={{
            gridArea: `leaderboard`,
          }}
        >
          {topList && topList.length
            ? topList.slice(0, 10).map((user, index) => (
                <ProgressBar
                  key={user.userId || user.firstName}
                  userId={user.userId}
                  userFirstName={user.firstName}
                  userLastName={user.lastName}
                  // Use given workspace KPIs or a sub-set from all company KPIs
                  groupKpis={groupKpis ?? allKpis[user.userId]}
                  userKpis={user.scores}
                  isOwnId={user.ownId}
                  imgsrc={user.image}
                  top={topList[0].scores.total}
                  email={user.email}
                  position={index + 1}
                  prevPosition={user.yesterdayRank}
                  showRankMove={periodIdx >= 1}
                  isActive={index === activeIndex}
                  onSelect={(show) => setActiveIndex(show ? index : -1)}
                  isYeti={user.isYeti}
                  isViewOnly={isViewOnly}
                  isPointsPercent={isPointsPercent}
                />
              ))
            : null}
        </SoftBox>
        <SoftBox sx={{ gridArea: `gameboard` }}>
          {topList && topList.length ? (
            <Leaders
              top3={topList.slice(0, 3)}
              groupKpis={groupKpis}
              showRankMove={periodIdx >= 1}
            />
          ) : null}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default LiveLeaderboardComponent;
