import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ReactHtmlParser from "react-html-parser";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";
import SoftTypography from "../../components/SoftTypography";
import { GET_HANDBOOK } from "../../api/handbook";
import { UseSelectedWorkspace } from "../../hooks/company";
import LoadingScreen from "../../components/LoadingScreen";
import styles from "./RichTextEditor.module.css";

const PlaybookViewer = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(false);
  const [TOC, setTOC] = useState([]);
  const [content, setContent] = useState("");
  const xsBp = useMediaQuery((theme) => theme.breakpoints.down("772"));

  const { _id: groupId } = UseSelectedWorkspace();

  const generateTOC = (contentData) => {
    const headings =
      contentData.match(/<h[1-2]\s*id="toc-\d+">.*?<\/h[1-2]>/g) || [];

    const toc = headings
      .filter((heading) => {
        const text = heading.replace(/<\/?[^>]+(>|$)/g, "");

        if (text !== "") return heading;
      })
      .map((heading, index) => {
        const text = heading.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
        const level = parseInt(heading.charAt(2), 10);

        return {
          id: `toc-${index}`,
          text,
          level,
        };
      });
    return toc;
  };

  const fetchHandbook = async () => {
    setLoading(true);
    try {
      const { data } = await GET_HANDBOOK({ workspaceId: groupId });
      if (data) {
        const toc = generateTOC(data?.content);
        setTOC(toc);
        setContent(data?.content);
      }
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHandbook();
  }, []);

  const toggleDrawer = () => {
    setState(!state);
  };

  return (
    <>
      <PageHeader
        headerText="Digital Playbook"
        subheaderText="Learn Just about everything on the resource center"
      />
      {loading ? (
        <LoadingScreen />
      ) : (
        <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
          <Grid container spacing={2}>
            <Grid item mini={12} xs={12} md={9} lg={9} xl={9} xxl={9} xxxl={9}>
              <SoftBox
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: xsBp ? "0px" : "16px",
                  // boxShadow:
                  //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
                  minHeight: "600px",
                }}
              >
                {xsBp && (
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "15px 10px ",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
                      color: "#6C757D",
                    }}
                    onClick={toggleDrawer}
                  >
                    Table Of Content&nbsp;
                    <MenuOpenIcon />
                  </SoftBox>
                )}
                <SoftBox sx={{ padding: "20px" }} className="handbook-content">
                  {/* eslint-disable-next-line */}
                  <div className={styles.playerhandbook} dangerouslySetInnerHTML={{ __html: content }} />
                  {/* {content && ReactHtmlParser(content)} */}
                </SoftBox>
              </SoftBox>
            </Grid>
            <Grid
              item
              mini={12}
              xs={12}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              xxxl={3}
              sx={{
                display: xsBp ? "none" : "block",
                // position: "fixed",
                right: "1.5rem",
                // maxWidth: { xl: "20% !important", xxl: "21% !important" },
                width: "100%",
              }}
            >
              <SoftBox
                sx={{
                  backgroundColor: "#FFF",
                  padding: "20px",
                  borderRadius: "16px",
                  // boxShadow:
                  //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
                  minHeight: "max-content",
                }}
              >
                <SoftTypography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: "#000",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Table of Contents
                </SoftTypography>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {TOC.map((item) => (
                    <li
                      key={item.text}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "80px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: 8,
                        border: "2px solid #04549C",
                        background: "rgba(4, 84, 156, 0.10)",
                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.08)",
                        overflow: "hidden",
                      }}
                    >
                      <a
                        href={`#${item.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          document.querySelector(`#${item.id}`).scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                        style={{
                          lineHeight: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </SoftBox>
            </Grid>
          </Grid>
          {xsBp && (
            <Drawer anchor="right" open={state} onClose={toggleDrawer}>
              <SoftBox sx={{ padding: "20px" }}>
                <SoftTypography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: "#000",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Table of Contents
                </SoftTypography>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {TOC.map((item) => (
                    <li
                      key={item.text}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "80px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: 8,
                        border: "2px solid #04549C",
                        background: "rgba(4, 84, 156, 0.10)",
                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.08)",
                      }}
                    >
                      <a
                        href={`#${item.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          document.querySelector(`#${item.id}`).scrollIntoView({
                            behavior: "smooth",
                          });
                          toggleDrawer();
                        }}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </SoftBox>
            </Drawer>
          )}
        </SoftBox>
      )}
    </>
  );
};

export default PlaybookViewer;
