import React from "react";
import PropTypes from "prop-types";
import SoftBox from "../../components/SoftBox";

const FeedTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return value === index ? (
    <SoftBox
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </SoftBox>
  ) : null;
};

FeedTabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props, react/forbid-prop-types
  children: PropTypes.any,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default FeedTabPanel;
