const options = {
  chart: {
    selection: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    parentHeightOffset: -20,
    offsetY: -20,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: true,
    x: {
      show: true,
      formatter: (
        value,
        {
          seriesIndex,
          dataPointIndex,
          w: {
            config: { series },
          },
        },
      ) => {
        const data = series[seriesIndex].data[dataPointIndex];
        return `${data.workspace} Workspace`;
      },
    },
    y: {
      formatter: (
        value,
        {
          seriesIndex,
          dataPointIndex,
          w: {
            config: { series },
          },
        },
      ) => {
        const data = series[seriesIndex].data[dataPointIndex];
        return data.value;
      },
    },
    z: {
      title: "Amount",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: "14px",
    },
    formatter: (text, op) => {
      const {
        seriesIndex,
        dataPointIndex,
        w: {
          config: { series },
        },
      } = op;
      const data = series[seriesIndex].data[dataPointIndex];
      return [text, data.value];
    },
    offsetY: -4,
  },
  plotOptions: {
    treemap: {
      enableShades: true,
      shadeIntensity: 0.5,
      reverseNegativeShade: true,
      colorScale: {
        ranges: [
          {
            from: -1,
            to: 0,
            color: "#b71c1c",
          },
          {
            from: 0.001,
            to: 1,
            color: "#1b5e20",
          },
        ],
      },
    },
  },
};

export default options;
