import React from "react";
import SoftBox from "../SoftBox";
import CustomDataTable from "./CustomDataTable";
import SoftTypography from "../SoftTypography";
import moment from "moment";
import { GET_IMAGE_URL } from "../../helpers/images";
import SoftAvatar from "../SoftAvatar";

const ReferralTable = ({ data }) => {
  const tableCols = [
    { Header: "company", accessor: "company", width: "30%" },
    { Header: "player", accessor: "player", width: "30%" },
    { Header: "doa", accessor: "doa", width: "20%", align: "center" },
    {
      Header: "subscription",
      accessor: "subscription",
      width: "20%",
      align: "center",
    },
  ];

  const tableRows = [];

  data?.forEach((referral) => {
    const refRow = {};

    refRow.company = (
      <SoftBox display="flex" alignItems="center" gap="0.5rem">
        {referral.company ? (
          <SoftAvatar
            variant="rounded"
            src={GET_IMAGE_URL(referral.company.logo)}
            alt={referral.company.businessName}
          />
        ) : (
          <SoftAvatar
            variant="rounded"
            alt={referral.firstName}
            sx={{ color: `grey.${700}`, backgroundColor: `grey.${400}` }}
          >
            G
          </SoftAvatar>
        )}

        <SoftTypography color="black" variant="h6">
          {referral.company ? referral.company.businessName : "GMB"}
        </SoftTypography>
      </SoftBox>
    );

    refRow.player = (
      <SoftBox display="flex" alignItems="center" gap="0.5rem">
        {referral.image ? (
          <SoftAvatar
            variant="rounded"
            src={GET_IMAGE_URL(referral.image)}
            alt={referral.firstName}
          />
        ) : (
          <SoftAvatar
            variant="rounded"
            alt={referral.firstName}
            sx={{ color: `grey.${700}`, backgroundColor: `grey.${400}` }}
          >
            {referral.firstName && referral.firstName[0]}
          </SoftAvatar>
        )}

        <SoftBox>
          <SoftTypography color="black" variant="h6">
            {referral.firstName} {referral.lastName}
          </SoftTypography>
          <SoftTypography variant="caption" color="text">
            {referral.email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );

    refRow.doa = (
      <SoftTypography variant="caption" color="text">
        {moment(referral.createdAt).format("YY/MM/DD")}
      </SoftTypography>
    );

    refRow.subscription = (
      <SoftTypography variant="caption" color="text">
        {referral.company?.subscription?.level ?? "N/A"}
      </SoftTypography>
    );

    tableRows.push(refRow);
  });

  return (
    <>
      <SoftTypography variant="h5" sx={{ padding: "1.25rem" }}>
        My Referrals
      </SoftTypography>
      <SoftBox padding="1.25rem">
        <CustomDataTable table={{ columns: tableCols, rows: tableRows }} />
      </SoftBox>
    </>
  );
};

export default ReferralTable;
