const YETI_PROFILE = {
  image: "YETI_PROFILE_PIC", // Image Key from S3 for Yeti's profile image
  firstName: "The",
  lastName: "Yeti",
  email: "yeti@gamemybiz.com",
  avatar: "https://models.readyplayer.me/6478c3c5866a701f83052edf.glb",
  group: {
    name: "Yeti",
    image: "YETI_PROFILE_PIC"
  }
};

export default YETI_PROFILE;