import React, { useState, useMemo } from "react";
import {
  Paper,
  TextField,
  Box,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import FreemiumSchema from "../../../form-validations/FreemiumSchema";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { quickLoginUser } from "../../../redux/auth/actions";
import { CHECK_UNIQUE_CREDENTIALS, ADD_COLLEAGUE } from "../../../api/user";
import { GET_CHALLENGE_REFERRAL } from "../../../api/affiliate";
import { JOIN_CHALLENGE } from "../../../api/challenge";
import { CREATE_COMPANY, UPLOAD_LOGO } from "../../../api/company";
import Confirmation from "./Confirmation";
import debounce from "lodash/debounce";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleAuthButton from "../../Google/GoogleAuthButton";
import toast from "react-hot-toast";
import AvatarSelector from "./AvatarSelector";
import FormField from "../../DemoOnboarding/FormField";
import SoftBox from "../../SoftBox";
import SoftButton from "../../SoftButton";
import SoftTypography from "../../SoftTypography";
import SoftInput from "../../SoftInput";

// Imported Images and Icons
import googleBusinessLogo from "../../../img/brands/google.svg";
import { ReactComponent as CloudUploadIcon } from "../../../img/custom_upload_icon.svg";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import LinearProgress from "@mui/material/LinearProgress";

import onboarding from "../../../constants/onboarding";

const THANK_YOU_PAGE = (urlId) => `https://arena.gamemybiz.com/thanks/${urlId}`;

const FreemiumForm = ({
  preRegisteredEmail,
  challengeInfo,
  avatar,
  referrer: referrerByUrl,
  companyColor,
  setAvatar,
  setCompanyColor,
}) => {
  const [info, setInfo] = useState(null);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [logoImg, setLogoImg] = useState(null);
  const [challengeUrl, setChallengeUrl] = useState(challengeInfo?.url);
  const dispatch = useDispatch();

  // Countdown Timer
  const [time, setTime] = useState(6);
  const [timeInterval, setTimeInterval] = useState();
  const [progress, setProgress] = React.useState(0);
  const [dataLoading, setDataLoading] = useState(false);

  let timer = time;

  const stop = () => {
    clearInterval(timeInterval);
  };

  const run = () => {
    if (timer > 0) {
      timer--;
    } else {
      stop();
      window.location.replace(THANK_YOU_PAGE(challengeUrl));
    }

    return setTime(timer);
  };

  const start = () => {
    run();
    setTimeInterval(setInterval(run, 1000));
  };

  const validationOpt = {
    resolver: yupResolver(FreemiumSchema),
    shouldUnregister: false,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm(validationOpt);

  const checkUniqueEmail = async () => {
    const email = getValues("email");
    if (email) {
      return CHECK_UNIQUE_CREDENTIALS({ email })
        .then(() => {
          clearErrors("email");
          return true;
        })
        .catch(() => {
          setError("email", {
            type: "custom",
            message: `Invalid email. Email is already taken.`,
          });
          return false;
        });
    }
  };

  const checkInviteCode = async () => {
    const code = getValues("code")?.trim().toUpperCase();
    if (code) {
      return GET_CHALLENGE_REFERRAL(code)
        .then(({ data: { userId } }) => {
          if (userId) {
            clearErrors("code");
            return userId;
          }
        })
        .catch((err) => {
          setError("code", {
            type: "custom",
            message:
              err?.response?.data ??
              "Something went wrong when checking your invite code",
          });
          return false;
        });
    }
  };

  React.useEffect(() => {
    // Check Google location selected from a Google Window popup
    const checkGoogleLocation = () => {
      const item = localStorage.getItem("location");
      if (item) {
        const location = JSON.parse(item);
        setInfo(location);
        setValue("googleBusinessProfile", location.locationId);
        clearErrors("googleBusinessProfile");
      }
    };
    window.addEventListener("storage", checkGoogleLocation);
    return () => {
      localStorage.removeItem("location");
      window.removeEventListener("storage", checkGoogleLocation);
    };
  }, [setValue, clearErrors]);

  const checkEmail = useMemo(
    () =>
      debounce(async () => {
        checkUniqueEmail();
      }, 2000),
    [],
  );

  const checkCode = useMemo(
    () =>
      debounce(async () => {
        checkInviteCode();
      }, 2000),
    [],
  );

  React.useEffect(() => {
    const timeLoading = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 200);

    return () => {
      clearInterval(timeLoading);
    };
  }, [logoFile]);

  React.useEffect(() => {
    if (preRegisteredEmail) {
      setValue("email", preRegisteredEmail);
    }
  }, [preRegisteredEmail, setValue]);

  React.useEffect(() => {
    if (challengeInfo?.url) {
      setValue("hasInfo", true);
    }
  }, [challengeInfo]);

  const fileChecker = (e) => {
    setProgress(0);
    const imgFile = e.target.files[0];
    clearErrors("companyLogo");
    setLogoFile(imgFile);
    setLogoImg(URL.createObjectURL(imgFile));
  };

  const removeFile = () => {
    setValue("companyLogo", "");
    clearErrors("companyLogo");
    setLogoFile(null);
    setLogoImg(null);
  };

  const fileSizeChecker = (data) => {
    if (data >= 1000000) {
      return parseFloat((data / 1000000).toFixed(1)) + "mb";
    } else if (data >= 1000 && data < 1000000) {
      return parseFloat((data / 1000).toFixed(1)) + "kb";
    } else {
      return data + "bytes";
    }
  };

  const handleJoin = () => {
    setDataLoading(false);
    // Remove countdown as requested by daverly and redirect to thank-you-page
    // setShowConfirmation(true);
    // start();
    window.location.replace(THANK_YOU_PAGE(challengeUrl));
  };

  const handleRegister = async (formData) => {
    if (dataLoading) return;
    setDataLoading(true);

    let referrerByCode = null;
    if (!challengeInfo) {
      // No challenge information retrieved from URL, so must check for invite code to identify challenge arena
      referrerByCode = await checkInviteCode();
      if (!referrerByCode) {
        setDataLoading(false);
        return;
      }
    }

    const unique = await checkUniqueEmail();
    if (!unique)  {
      setDataLoading(false);
      return;
    }

    try {
      const companyData = new FormData();
      companyData.append("businessName", info?.title || "Business Name");
      companyData.append(
        "country",
        info?.storefrontAddress?.regionCode || "Country",
      );
      companyData.append(
        "state",
        info?.storefrontAddress?.administrativeArea || "State",
      );
      companyData.append(
        "addressOne",
        info?.storefrontAddress?.addressLines[0] || "Address",
      );
      companyData.append(
        "addressTwo",
        info?.storefrontAddress?.addressLines[1],
      );
      companyData.append("city", info?.storefrontAddress?.locality || "City");
      companyData.append(
        "zipcode",
        info?.storefrontAddress?.postalCode || "Zip",
      );
      companyData.append("websiteUrl", info?.websiteUri || "Website");
      companyData.append(
        "phoneNumber",
        info?.phoneNumbers ? Object.values(info.phoneNumbers)[0] : "none",
      );
      companyData.append("onboardingStatus", onboarding.ARENA_SIGNUP);
      companyData.append("primaryColor", companyColor);
      if (logoFile) {
        // Append logo file if available
        companyData.append("image", logoFile);
      }
      const res = await CREATE_COMPANY(companyData);
      const { company: createdCompany, workspace: createdWorkspace } = res.data;

      if (createdCompany) {
        /* New User */
        const name = formData.fullName.split(" ");
        const email = formData.email.trim();
        const { data: registration } = await ADD_COLLEAGUE({
          firstName: name.slice(0, name.length - 1).join(" "),
          lastName: name[name.length - 1],
          email,
          companyId: createdCompany._id,
          groupId: createdWorkspace._id,
          avatar,
          isAdmin: true,
          referrer: referrerByCode || referrerByUrl,
        });

        const googleParams = localStorage.getItem("google");
        const parameters = {
          ...JSON.parse(googleParams),
          account_id: info?.accountId,
          location_id: info?.locationId,
        };

        const {
          data: { challenge: joinedChallenge },
        } = await JOIN_CHALLENGE({
          challengeId: challengeInfo?._id,
          companyId: createdCompany._id,
          groupId: createdWorkspace._id,
          userId: registration.user._id,
          code: formData.code?.trim()?.toUpperCase(),
          parameters,
          email,
        });

        if (registration && joinedChallenge) {
          localStorage.removeItem("google");
          setChallengeUrl(joinedChallenge.url);
          dispatch(quickLoginUser(registration.token, registration.user));
          handleJoin();
        }
      }
    } catch (err) {
      const errorMsg = err.response.data;
      if (errorMsg?.includes("logo")) {
        setError("companyLogo", { type: "custom", message: errorMsg });
      }
      toast.error(err);
      setDataLoading(false);
    }
  };

  return (
    <form className="my-[1rem]" onSubmit={handleSubmit(handleRegister)}>
      {/* Step 1: Name and Email Inputs */}
      <SoftBox
        shadow="sm"
        borderRadius="lg"
        bgColor="white"
        sx={{
          marginTop: { mini: "2rem", md: "2.5rem" },
          paddingX: { mini: "1rem", md: "2rem" },
          paddingY: "1.75rem",
        }}
      >
        <Stack
          direction={{ mini: "column", md: "row" }}
          columnGap="10%"
          rowGap="1rem"
        >
          <SoftBox
            sx={{
              width: { xs: "100%", md: "20%" },
            }}
          >
            <SoftBox>
              <SoftTypography
                variant="body"
                sx={{
                  fontFamily: "'Inter', sans-serif;",
                  color: "#000",
                }}
              >
                STEP 1
              </SoftTypography>
              <SoftTypography
                sx={{
                  marginTop: "1rem",
                  fontFamily: "'Inter', sans-serif;",
                  fontSize: { mini: "1.25rem", sm: "1.5rem" },
                  fontWeight: 700,
                  color: "#000",
                }}
              >
                Enter Your Name and Email
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            sx={{
              width: { mini: "100%", md: "75%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SoftBox
              bgColor="white"
              sx={{
                borderRadius: "8px",
                border: {
                  mini: "none",
                  md: "1px solid rgba(0, 0, 0, 0.05)",
                },
                paddingX: { mini: "0", md: "1.5rem" },
                paddingY: { mini: "0", md: "1.5rem" },
                width: "100%",
              }}
            >
              <FormField
                id="userFullName"
                type="text"
                autoComplete="current-full-name"
                placeholder="Full Name"
                className="!mb-[1.25rem]"
                {...register("fullName")}
                error={!!errors?.fullName}
                helperText={errors?.fullName?.message}
                size="large"
                fullWidth
              />
              {!preRegisteredEmail && (
                <FormField
                  id="userEmail"
                  type="email"
                  autoComplete="current-email"
                  placeholder="Email"
                  {...register("email", {
                    onChange: () => {
                      checkEmail();
                    },
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="large"
                  fullWidth
                />
              )}
              <SoftBox
                sx={{
                  textAlign: "center",
                  width: "100%",
                  marginY: "1.5rem",
                  paddingX: "2rem",
                }}
              >
                <SoftTypography
                  variant="body"
                  sx={{
                    color: "#6C757D",
                    fontFamily: "'Inter', sans-serif;",
                    lineHeight: 1,
                    fontWeight: 400,
                    fontSize: { mini: "12px", md: "16px" },
                  }}
                >
                  Our platform welcomes players of all industries to compete
                  with the best and achieve their goals. Take advantage of this
                  opportunity to challenge yourself and measure your success
                  against the best
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Stack>
      </SoftBox>

      {/* Invite Code Input. This should also be re-designed according to new format, but we don't have this on figma yet */}
      {!challengeInfo && ( // Only ask for invite code if Challenge info has not been obtained via URL parameter
        <SoftBox shadow="lg" borderRadius="xl" sx={{ padding: "1.5rem 2rem" }}>
          <FormField
            id="userCode"
            label="Invite Code"
            autoComplete="current-code"
            type="text"
            placeholder="INVITECODE"
            className="!mb-[1.25rem] sm:!mb-[0rem]"
            {...register("code", {
              onChange: () => {
                checkCode();
              },
            })}
            error={!!errors?.code}
            helperText={errors?.code?.message}
            size="large"
          />
        </SoftBox>
      )}

      {/* Step 2: Avatar Selection */}
      <SoftBox
        shadow="sm"
        borderRadius="lg"
        bgColor="white"
        sx={{
          marginTop: { mini: "2rem", md: "2.5rem" },
          paddingX: { mini: "1rem", md: "2rem" },
          paddingY: "1.75rem",
        }}
      >
        <Stack
          direction={{ mini: "column", md: "row" }}
          columnGap="5%"
          rowGap="1rem"
        >
          <SoftBox
            sx={{
              width: { xs: "100%", md: "25%" },
            }}
          >
            <SoftBox>
              <SoftTypography
                variant="body"
                sx={{
                  fontFamily: "'Inter', sans-serif;",
                  color: "#000",
                }}
              >
                STEP 2
              </SoftTypography>
              <SoftTypography
                sx={{
                  marginTop: "1rem",
                  fontFamily: "'Inter', sans-serif;",
                  fontSize: { mini: "1.25rem", sm: "1.5rem" },
                  fontWeight: 700,
                  color: "#000",
                }}
              >
                Select Your Avatar
              </SoftTypography>
              <SoftTypography
                variant="body"
                sx={{
                  marginTop: "1rem",
                  fontFamily: "'Inter', sans-serif;",
                  color: "#343A40",
                }}
              >
                You can update this later in our platform
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <SoftBox
            sx={{
              width: { mini: "100%", md: "75%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SoftBox
              sx={{
                marginY: "2rem",
                width: {
                  mini: "300px",
                  xs: "450px",
                },
                borderRadius: "0.75rem",
                overflow: "hidden",
                marginX: "auto",
              }}
            >
              <AvatarSelector
                setAvatar={setAvatar}
                setCompanyColor={setCompanyColor}
              />
            </SoftBox>
          </SoftBox>
        </Stack>
      </SoftBox>

      {/* Step 3: Link Google Business */}
      <SoftBox
        shadow="sm"
        borderRadius="lg"
        bgColor="white"
        sx={{
          marginTop: { mini: "2rem", md: "2.5rem" },
          paddingX: { mini: "1rem", md: "2rem" },
          paddingY: "1.75rem",
        }}
      >
        <SoftBox sx={{ width: "100%" }}>
          <SoftBox>
            <SoftTypography
              variant="body"
              sx={{
                fontFamily: "'Inter', sans-serif;",
                color: "#000",
              }}
            >
              STEP 3
            </SoftTypography>
            <SoftBox
              sx={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={googleBusinessLogo}
                alt="Google Business"
                className="mr-2 md:mr-4 w-[35px]"
              />
              <SoftTypography
                sx={{
                  fontFamily: "'Inter', sans-serif;",
                  fontSize: { mini: "1.25rem", sm: "1.5rem" },
                  fontWeight: 700,
                  color: "#000",
                }}
              >
                Link Your Google Business Profile
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>

        <SoftBox
          sx={{
            width: "90%",
            marginTop: "1.5rem",
          }}
        >
          <SoftTypography
            variant="body"
            sx={{
              color: "#6C757D",
              fontFamily: "'Inter', sans-serif;",
              fontSize: "14px",
            }}
          >
            Login with your Google account and grant Game My Biz access to your
            Business Profile and Google Reviews.{" "}
            <b>
              We access only the data required to run the challenge effectively.
            </b>{" "}
            <sup>Recommended browsers: Chrome or Firefox</sup>
          </SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            marginTop: "2rem",
            width: "80%",
            maxWidth: "380px",
          }}
        >
          <GoogleAuthButton mode="challenge" label="Authorize" width="100%" />
          {getFieldState("googleBusinessProfile").error ? (
            <Typography color="error" variant="subtitle2">
              No Google Business access authorization
            </Typography>
          ) : null}
          {info ? (
            <div className="mt-[1rem] mb-[1.5rem]">
              <div className="flex justify-start">
                <Paper
                  elevation={4}
                  sx={{
                    borderRadius: 5,
                    padding: 4,
                    maxWidth: "400px",
                  }}
                >
                  <div className="flex">
                    <a
                      href={info.websiteUri}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-xl inline"
                    >
                      {info.title}
                    </a>
                  </div>

                  <p className="font-semibold text-lg">
                    {info.formattedAddress}
                  </p>
                </Paper>
              </div>
            </div>
          ) : null}
        </SoftBox>
        <SoftBox sx={{ width: "70%", marginTop: "2rem" }}>
          <SoftTypography
            variant="subtitle"
            sx={{
              color: "#6C757D",
              fontFamily: "'Inter', sans-serif;",
              fontSize: "12px",
              lineHeight: "1",
            }}
          >
            <b>Note:</b> We use your data solely for the purpose of tracking
            your challenge progress and generating meaningful insights. We
            employ stringent security measures to protect your data
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      {/* Step 4: Upload Logo */}
      <SoftBox
        shadow="sm"
        borderRadius="lg"
        bgColor="white"
        sx={{
          marginTop: { mini: "2rem", md: "2.5rem" },
          paddingX: { mini: "1rem", md: "2rem" },
          paddingY: "1.75rem",
        }}
      >
        <SoftBox sx={{ width: "100%" }}>
          <SoftBox>
            <SoftTypography
              variant="body"
              sx={{
                fontFamily: "'Inter', sans-serif;",
                color: "#000",
              }}
            >
              STEP 4
            </SoftTypography>

            <SoftTypography
              sx={{
                fontFamily: "'Inter', sans-serif;",
                fontSize: { mini: "1.25rem", sm: "1.5rem" },
                fontWeight: 700,
                color: "#000",
              }}
            >
              Upload Your Company Logo
            </SoftTypography>
            <SoftTypography
              variant="body"
              sx={{
                marginTop: "1rem",
                fontFamily: "'Inter', sans-serif;",
                color: "#343A40",
              }}
            >
              You can update this later in our platform
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox>
          <TextField
            hidden
            id="forCompanyLogo"
            label="Company Logo"
            type="file"
            {...register("companyLogo", {
              onChange: (e) => {
                fileChecker(e);
              },
            })}
          />
          <SoftButton
            className="w-full flex-col px-[2rem] !py-[1rem] gap-[0.5rem] !normal-case mt-4 flex justify-center"
            sx={{ border: "1px dashed #6C757D" }}
          >
            <label
              htmlFor="forCompanyLogo"
              className="w-full flex-col px-[2rem] !py-[1rem] gap-[0.5rem] !normal-case"
            >
              {logoImg ? (
                <div className="flex justify-center">
                  <img
                    src={logoImg}
                    alt="Company Logo"
                    className="h-[140px] w-auto"
                  />
                </div>
              ) : (
                <>
                  <Box className="w-full flex justify-center">
                    <CloudUploadIcon color="black" fontSize="2rem !important" />
                  </Box>
                 
                  <p className="text-base text-[#FF1D8F] font-medium">
                    Click to Upload
                  </p>
                  <p className="text-sm md:text-base text-[#000000]">
                    PNG or JPG (max. 3MB)
                  </p>
                </>
              )}
            </label>
          </SoftButton>
          <p
            style={{
              color: "#d32f2f",
              fontWeight: 500,
              fontSize: "1rem",
              marginTop: "3px",
            }}
          >
            {errors?.companyLogo?.message}
          </p>

          {logoFile && (
            <div className="w-full flex-col px-[2rem] !py-[1rem] gap-[0.5rem] !normal-case">
              <div className="w-full flex flex-row justify-between">
                <div className="w-full flex flex-row items-center ">
                  <Box sx={{ padding: "1rem", borderRadius: "50%" }}>
                    <CloudUploadIcon />
                  </Box>

                  <div className="flex-col items-center ">
                    <p>{logoFile.name}</p>

                    <div className="flex flex-row">
                      <p>{fileSizeChecker(logoFile.size)} &#x2022; </p>
                      <p className="ml-2">
                        {progress === 100 ? "Completed" : "Uploading"}
                      </p>
                    </div>
                  </div>
                </div>

                <Button onClick={() => removeFile()}>
                  {" "}
                  <ClearOutlinedIcon />{" "}
                </Button>
              </div>

              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </div>
          )}
        </SoftBox>
      </SoftBox>
      <div className="max-w-[450px] mt-[2.5rem] mx-auto">
        <SoftButton
          type="submit"
          variant="contained"
          sx={{
            width: "100%",
            paddingY: "1rem",
            color: "primary.contrastText",
          }}
          color="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          {dataLoading ? (
            <CircularProgress color="inherit" size="1.5rem" />
          ) : (
            "JOIN CHALLENGE"
          )}
        </SoftButton>
      </div>
      <Confirmation
        showConfirmation={showConfirmation}
        time={time}
        url={challengeUrl}
      />
    </form>
  );
};

export default FreemiumForm;
