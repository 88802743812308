import React from "react";
import Pagination from "@mui/material/Pagination";
import ScDropdown from "./ScDropdown";

// Imported Images and Icons
import blankImg from "../../img/blankPic.png";
import OfflineBoltTwoToneIcon from "@mui/icons-material/OfflineBoltTwoTone";

const ScTable = ({ img }) => {
  const LINK = process.env.REACT_APP_LINK_HOST;
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="overflow-x-scroll">
      <table className="w-full py-[1rem] px-[0.5rem] xxs:px-[1rem] xs:px-[1.5rem]">
        <thead className="font-semibold">
          <tr className="border-b-2 border-solid border-[#F5F5F5] rounded-sm">
            {/* <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              Name
            </td> */}
            <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              KPI
            </td>
            <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              Data Source
            </td>
            <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              Amount
            </td>
            <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              DOE
            </td>
            {/* <td className="text-center py-[0.25rem] px-[0.5rem] text-[#8392AB]">
              Status
            </td> */}
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-solid border-[#F5F5F5] rounded-sm">
            {/* <td className="flex items-center justify-center py-[0.25rem] px-[0.1rem] xs:py-[0.5rem] xs:px-[0.25rem]">
              <img
                src={img ? `${LINK}/images/${img}` : blankImg}
                alt=""
                className="w-[30px] xs:w-[40px] rounded-[50%] mr-[0.15rem] xs:mr-[0.25rem]"
              />
              <span>
                <p className="font-semibold text-[#2442AF] text-xs xxs:text-sm text-base md:text-lg">John Doe</p>
                <p className="text-xs xs:text-sm md:text-base text-[#8392AB] font-light">
                  john@example.com
                </p>
              </span>
            </td> */}
            <td className="text-center py-[0.25rem] px-[0.1rem] xs:py-[0.5rem] xs:px-[0.25rem] text-[#8392AB] text-xs xs:text-sm">
              Upsell
            </td>
            <td className="text-center py-[0.25rem] px-[0.1rem] xs:py-[0.5rem] xs:px-[0.25rem] text-[#2442AF] text-xs xs:text-sm">
              <OfflineBoltTwoToneIcon className="text-[#9C9C9C]" />
            </td>
            <td className="text-center py-[0.25rem] px-[0.1rem] xs:py-[0.5rem] xs:px-[0.25rem] font-semibold text-xs xs:text-sm">
              $ {1}
            </td>
            <td className="text-center py-[0.25rem] px-[0.1rem] xs:py-[0.5rem] xs:px-[0.25rem] font-semibold text-xs xs:text-sm">
              Oct 15, 2021
            </td>
            {/* <td className="text-center py-[0.5rem] px-[0.25rem]">
              <p className="py-[0.1rem] px-[0.05rem] text-[#3BB900] bg-[#ECFBE6] text-xs xxs:text-sm font-semibold rounded-md">
                Added
              </p>
            </td> */}
          </tr>
        </tbody>
      </table>
      <div className="py-[1rem]">
        <div className="block xs:flex xs:justify-between">
          <div className="mb-[1rem] xs:mb-[0rem]">
            <span className="mr-[0.25rem] text-[#00000099]">
              Rows Per Page:{" "}
            </span>
            <ScDropdown />
          </div>
          <div className="flex justify-end">
            <Pagination
              className="userPagination"
              count={3}
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScTable;
