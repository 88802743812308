import React, { createContext, useState } from "react";

export const CompanyContext = createContext();

const CompanyProvider = ({ ...props }) => {
	const [company, setCompany] = React.useState({
		businessName: "",
		industry: "",
		country: "",
		state: "",
		addressOne: "",
		addressTwo: "",
		city: "",
		zipcode: "",
		phoneNumber: "",
		websiteUrl: "",
	});

	return (
		<CompanyContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				company,
				setCompany,
			}}
		>
			{props.children}
		</CompanyContext.Provider>
	);
};

export default CompanyProvider;
