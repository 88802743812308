import React from "react";

// MUI/SoftUI
import Grid from "@mui/material/Grid";
import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

// Icons
import SubjectIcon from "@mui/icons-material/Subject";

const SelectorButton = ({
  title = "Title",
  subtitle = "Subtitle text",
  isSelected = false,
  handleClick
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <SoftBox
        onClick={handleClick}
        sx={{
          border: "0.99454px solid rgba(233, 236, 239, 0.2)",
          borderRadius: "6px",
          padding: "11px 16px",
          height: "72px",
          cursor: "pointer",
          boxShadow: "px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1)",
          ...(isSelected && {
            border: "0.99px solid #7928CA",
            background: "rgba(121, 40, 202, 0.2)",
          }),
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <SubjectIcon color="white" />
          <SoftTypography
            variant="body1"
            sx={{
              fontWeight: "500 !important",
              fontSize: "13px !important",
              lineHeight: "16.93px",
              letterSpacing: "-0.02em !important",
              color: "#E9ECEF !important"
            }}
          >
            {title}
          </SoftTypography>
        </SoftBox>

        <SoftBox
          sx={{
            width: { xs: "100%", sm: "50%" },
            lineHeight: 0.75,
            marginTop: "1px"
          }}>
          <SoftTypography
            variant="caption"
            sx={{
              fontWeight: 400,
              fontSize: "8px",
              lineHeight: "0 !important",
              color: "#CED4DA",
              width: "50%"
            }}
          >
            {subtitle}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Grid>
  );
};

export default SelectorButton;
