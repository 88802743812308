import React from "react";

const ReactHookFormSelect = ({ register, options, name, error, helperText, ...rest }) => {
  return (
    <select
      {...rest}
      name={name}
      {...register("industry", { required: true })}
      className="border w-full py-2 pl-2 rounded my-2 bg-white"
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default ReactHookFormSelect;
