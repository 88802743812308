import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";

const Treemap = ({ scores, title, onClick, options }) => {
  const [series, setSeries] = useState([{ data: [] }]);

  useEffect(() => {
    setSeries(scores?.length ? [{ data: scores }] : null);
  }, [scores]);

  const chartOptions = useMemo(
    () => ({
      ...options,
      title: {
        ...options.title,
        text: title,
      },
      chart: {
        ...options.chart,
        events: {
          dataPointSelection: (e, ctx, config) => {
            const {
              dataPointIndex,
              seriesIndex,
              w: {
                config: { series: seriesData },
              },
            } = config;
            const data = seriesData[seriesIndex].data[dataPointIndex];
            onClick?.(data);
          },
        },
      },
    }),
    [title],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment 
    <>
      {series && (
        <Chart
          series={series}
          options={chartOptions}
          type="treemap"
          height= "100%"
          width= "98%"
        />
      )}
    </>
  );
};

export default Treemap;
