import React from "react";
import { Dialog, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";

const BillingUpdate = ({ billingUpdateDialog, closeBillingUpdateDialog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const okayHandler = () => {
    closeBillingUpdateDialog();
    dispatch(getCompanyUsers());
  };

  return (
    <Dialog
      open={billingUpdateDialog}
      onClose={closeBillingUpdateDialog}
      sx={{
        "& .MuiPaper-root": {
          padding: { mini: "1rem", md: "1.5rem 1.7rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "700px",
          borderWidth: "1px",
          borderColor: "#FFF",
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h4" color="white">
          Billing Update
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={closeBillingUpdateDialog}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      <SoftBox mb="60px">
        <SoftTypography
          component="p"
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: 16,
            marginBottom: "20px",
          }}
        >
          You've successfully removed a player from your workspace. No worries,
          your billing will be updated accordingly.
        </SoftTypography>
        <SoftTypography
          component="p"
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: 16,
            marginBottom: "20px",
          }}
        >
          Remember, you're on our free plan, and any changes will be reflected
          in the next billing cycle. Feel free to manage your players anytime!
        </SoftTypography>
        <SoftTypography
          component="p"
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: 16,
            marginBottom: "20px",
          }}
        >
          Keep the game strong! 🚀
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between">
        <SoftButton
          color="primary"
          variant="contained"
          onClick={() => navigate("/settings/billing")}
          sx={{
            color: "primary.contrastText",
            textTransform: "none",
            borderRadius: "4px",
          }}
        >
          Go to Billing
        </SoftButton>
        <SoftButton
          color="white"
          variant="outlined"
          onClick={okayHandler}
          sx={{
            mx: 2,
            textTransform: "none",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          Okay
        </SoftButton>
      </SoftBox>
    </Dialog>
  );
};

export default BillingUpdate;
