/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { UseIsAdmin, UseUserDetails } from "../../hooks/auth";
import { UseCompanyColors } from "../../hooks/company";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import SoftProfile from "../ProfilePictures/SoftProfile";
import useMediaQuery from "@mui/material/useMediaQuery";
import SoftBox from "../SoftBox";

// Imported Style Sheet
import "./bottomNavStyles.css";

// Imported Images and Icons
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
// import ChatIcon from "@mui/icons-material/Chat";
// import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import AddTaskIcon from "@mui/icons-material/AddTask";
import HubIcon from "@mui/icons-material/Hub";

const BottomNav = ({ badges }) => {
  const navigate = useNavigate();
  const isAdmin = UseIsAdmin();
  const superSmallScreen = useMediaQuery("(max-width:350px)");

  // Menu Appbar
  const [sidebar, setSidebar] = useState(false);
  const sidebarRef = useRef();
  const { primaryColor } = UseCompanyColors();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        sidebar &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        setSidebar(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [sidebar]);

  let location = useLocation();
  location = location.pathname;
  const paths = location?.split("/");
  const currentPath = paths[paths.length - 1];

  // Appbar

  // Bottom Nav
  let isDashboard = false;
  let isClaims = false;
  let isHub = false;
  let isScore = false;

  switch (currentPath) {
    case "home":
      isDashboard = true;
      break;
    case "hub":
      isHub = true;
      break;
    case "scorecard":
    case "scorekeeper":
      isScore = true;
      break;
    case "claims":
      isClaims = true;
      break;
    default:
      break;
  }

  // Image on Bottom Nav
  const user = UseUserDetails();

  return (
    <div className="boxShadow fixed z-10 bottom-0 w-full bg-[#fff] h-[70px] grid grid-cols-5 justify-center md:hidden">
      <div
        className="flex flex-col justify-center items-center cursor-pointer group"
        onClick={() => {
          navigate(`/home`);
        }}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "40px !important",
            minHeight: "40px !important",
            color: "#fff",
            borderRadius: "50% !important",
            transition: "all ease 0.2s",
            transform: isDashboard ? "translateY(-6px)" : "",
            background: isDashboard ? (theme) => theme.palette.gradient : "",
            padding: 0,
            "&:hover": {
              transform: "translateY(-6px)",
            },
            "& svg": {
              fontSize: "28px !important",
              color: isDashboard ? "#fff" : "#000000CC",
            },
          }}
        >
          <Icon icon="bi:speedometer2" />
        </Button>
        <Typography
          sx={{
            color: isDashboard ? "primary.main" : "#000000CC",
            display: superSmallScreen ? "none" : "block",
            fontSize: { mini: "0.8rem", xxs: "0.875rem" },
            marginTop: "-8px",
          }}
        >
          Dashboard
        </Typography>
      </div>

      <div
        className="flex flex-col justify-center items-center cursor-pointer group"
        onClick={() => {
          navigate(`/scorecard`);
        }}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "40px !important",
            minHeight: "40px !important",
            color: "#fff",
            borderRadius: "50% !important",
            transition: "all ease 0.2s",
            transform: isScore ? "translateY(-6px)" : "",
            background: isScore ? (theme) => theme.palette.gradient : "",
            padding: 0,
            "&:hover": {
              transform: "translateY(-6px)",
            },
            "& svg": {
              fontSize: "28px !important",
              color: isScore ? "#fff" : "#000000CC",
            },
          }}
        >
          <AddTaskIcon />
        </Button>
        <Typography
          sx={{
            color: isScore ? "primary.main" : "#000000CC",
            display: superSmallScreen ? "none" : "block",
            fontSize: { mini: "0.8rem", xxs: "0.875rem" },
            marginTop: "-8px",
          }}
        >
          {isAdmin ? "Scorekeeper" : "Scorecard"}
        </Typography>
      </div>

      {/* <UploadPicture /> */}
      <SoftBox
        sx={{
          marginTop: {
            mini: "-10px",
            xxs: "-20px",
            xs: "-25px",
          },
          marginX: "auto",
          cursor: "pointer",
          height: "max-content",
          "&:hover": { opacity: 0.9 },
        }}
        onClick={() => navigate("/account-settings")}
      >
        <SoftProfile
          src={user.image}
          name={user.firstName}
          // eslint-disable-next-line no-nested-ternary
          size={superSmallScreen ? "md" : "xl"}
          sx={{ border: "1px solid #000000" }}
        />
      </SoftBox>

      <div
        className="flex flex-col justify-center items-center cursor-pointer group relative"
        onClick={() => {
          navigate(`/claims`);
        }}
      >
        {badges?.claims > 0 ? (
          <div className="absolute right-[20%] top-[10%]">
            <Badge badgeContent={badges?.claims} color="error" />
          </div>
        ) : null}
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "40px !important",
            minHeight: "40px !important",
            color: "#fff",
            borderRadius: "50% !important",
            transition: "all ease 0.2s",
            transform: isClaims ? "translateY(-6px)" : "",
            background: isClaims ? (theme) => theme.palette.gradient : "",
            padding: 0,
            "&:hover": {
              transform: "translateY(-6px)",
            },
            "& svg": {
              fontSize: "28px !important",
              color: isClaims ? "#fff" : "#000000CC",
            },
          }}
        >
          <NewspaperOutlinedIcon />
        </Button>
        <Typography
          sx={{
            color: isClaims ? "primary.main" : "#000000CC",
            display: superSmallScreen ? "none" : "block",
            fontSize: { mini: "0.8rem", xxs: "0.875rem" },
            marginTop: "-8px",
          }}
        >
          Claims
        </Typography>
      </div>

      <div
        className="flex flex-col justify-center items-center cursor-pointer group"
        onClick={() => {
          navigate(`/hub`);
        }}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "40px !important",
            minHeight: "40px !important",
            color: "#fff",
            borderRadius: "50% !important",
            transition: "all ease 0.2s",
            transform: isHub ? "translateY(-6px)" : "",
            background: isHub ? (theme) => theme.palette.gradient : "",
            padding: 0,
            "&:hover": {
              transform: "translateY(-6px)",
            },
            "& svg": {
              fontSize: "28px !important",
              color: isHub ? "#fff" : "#000000CC",
            },
          }}
        >
          <HubIcon />
        </Button>
        <Typography
          sx={{
            color: isHub ? "primary.main" : "#000000CC",
            display: superSmallScreen ? "none" : "block",
            fontSize: { mini: "0.8rem", xxs: "0.875rem" },
            marginTop: "-8px",
          }}
        >
          Hub
        </Typography>
      </div>
    </div>
  );
};

export default BottomNav;
