import React, { useEffect, useState } from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import PromoCard from "./PromoCard";
import Promo1st from "../../img/notification-img.png";
import Promo2nd from "../../img/game1-gmb-library.png";
import { Grid } from "@mui/material";

import { GET_SETTING } from "../../api/settings";

const Promotional = () => {
  const [value, setValue] = useState([]);

  const promoInfo = [
    {
      id: `promoCard${1}`,
      image: Promo1st,
      info: "User #hashtag in a photo on social media and get $10 for each purchase you make.",
      buttonInfo: "Read More",
      color: "primary",
    },
    {
      id: `promoCard${2}`,
      image: Promo2nd,
      info: "Send the invitation link to 10 friends and get a 50% coupon to use on any purchase.",
      buttonInfo: "Read More",
      color: "primary",
    },
    {
      id: `promoCard${3}`,
      image: "",
      info: "",
      buttonInfo: "Read More",
      color: "primary",
    },
  ];

  const fetchSetting = async () => {
    try {
      const { data } = await GET_SETTING("affiliates_promo");

      if (data && data.value) {
        const values = data.value.split(",");

        setValue(values);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  return (
    <>
      <SoftTypography color="dark" variant="h5">
        Promotional Materials
      </SoftTypography>
      <SoftBox marginTop="0.75rem">
        <Grid container spacing={3}>
          {promoInfo?.map((promo, index) => (
            <Grid item mini={4} key={promo.id}>
              <PromoCard
                color="primary"
                description={promo.info}
                button={promo.buttonInfo}
                image={value.length && value[index] ? value[index] : Promo1st}
              />
            </Grid>
          ))}
        </Grid>
      </SoftBox>
    </>
  );
};

export default Promotional;
