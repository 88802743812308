import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

import LoadingScreen from "../../components/LoadingScreen";
import BottomNav from "../../components/BottomNav";
import Leaders from "../../components/Freemium/Scoreboard/Leaders";
import BarLeaderboard from "../../components/Freemium/Scoreboard/BarLeaderboard";
import AvatarLeaders from "../../components/Freemium/Scoreboard/AvatarLeaders";
import { Box, Button, Dialog, IconButton, Grid, Divider } from "@mui/material";
import { UseUserDetails } from "../../hooks/auth";
import SoftAvatar from "../../components/SoftAvatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SoftTypography from "../../components/SoftTypography";
import { useDispatch, useSelector } from "react-redux";
import "./freemiumStyles.css";
import RealTimeFeed from "../../components/RealTimeFeed";
import PublicMessageBoard from "./PublicMessageBoard";
import { UseCompanyName, UseCompanyLogo } from "../../hooks/company";
import InfoPopUp from "../../components/ChallengeArenaPopUp/InfoPopup";
import { GET_IMAGE } from "../../helpers/images";
import { ADD_COMMENT } from "../../api/comments";
import {
  GET_CHALLENGE,
  GET_CHALLENGE_EVENT,
  GET_SCOREBOARD,
} from "../../api/challenge";

import InvitePopUp from "../../components/ChallengeArenaPopUp/InvitePopUp";
// import socket from "../../configs/socket";
// import socketConstants from "../../constants/socket";
import SoftBox from "../../components/SoftBox";
import { addMessage, getMessages } from "../../redux/message/actions";
import SoftButton from "../../components/SoftButton";

// Imported Images and Icons
import LeaderboardBg from "../../img/ca-bg.png";
import ChatIcon from "@mui/icons-material/Chat";
import scoreboardLogo from "../../img/freemium-scoreboardLogo.png";
import gmbNavbarLogo from "../../img/gmb-nav-round.png";
import MonsterGif from "../../img/challenge-arena-upgrade.gif";
import { ReactComponent as SendChatSvg } from "../../img/send.svg";
import YETI from "../../constants/yetiProfile";
import GoldCrown from "../../img/arena/GoldCrown.svg";
import SilverCrown from "../../img/arena/SilverCrown.svg";
import BronzeCrown from "../../img/arena/BronzeCrown.svg";
import { ReactComponent as Rank1st } from "../../img/arena/1st.svg";
import { ReactComponent as Rank2nd } from "../../img/arena/2nd.svg";
import { ReactComponent as Rank3rd } from "../../img/arena/3rd.svg";

const PLACES = [1, 0, 2];

const ORDERWORD = [
  { asset: <Rank1st />, width: "80px" },
  {
    asset: <Rank2nd />,
    width: "70px",
  },
  { asset: <Rank3rd />, width: "60px" },
];
const COLORS = [
  "radial-gradient(50% 100% at 50% 0.00%, rgba(110, 202, 220, 0.20) 0%, rgba(110, 202, 220, 0.00) 100%), rgba(110, 202, 220, 0.50)",
  "radial-gradient(50% 100% at 50% 0.00%, rgba(255, 252, 0, 0.30) 0%, rgba(255, 252, 0, 0.00) 100%), rgba(255, 252, 0, 0.50)",
  "radial-gradient(50% 100% at 50% 0.00%, rgba(255, 153, 0, 0.20) 0%, rgba(255, 153, 0, 0.00) 100%), rgba(255, 153, 0, 0.50)",
];
const DESKTOPPODIUMS = ["460px", "380px", "340px"];
const MINIPODIUMS = ["420px", "340px", "300px"];
const BORDERCOLORS = [
  "rgba(110, 202, 220, 0.80)",
  "rgba(255, 252, 0, 0.60)",
  "rgba(255, 153, 0, 0.80)",
];

const BOXSHADOW = [
  "-4px -4px 8px 0px rgba(110, 202, 220, 0.20) inset, 0px 0px 24px 0px rgba(110, 202, 220, 0.10) inset, 0px 0px 32px 4px rgba(110, 202, 220, 0.40);",
  "-4px -4px 8px 0px rgba(255, 252, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 252, 0, 0.10) inset, 0px 0px 32px 4px rgba(255, 252, 0, 0.40)",
  "-4px -4px 8px 0px rgba(255, 153, 0, 0.20) inset, 0px 0px 24px 0px rgba(255, 153, 0, 0.10) inset, 0px 0px 32px 4px rgba(255, 153, 0, 0.40)",
];
const STROKECOLORS = [
  "rgba(110, 202, 220, 0.80)",
  "rgba(255, 252, 0, 0.60)",
  "rgba(255, 153, 0, 0.80)",
];

const RANKCOLORS = [
  "rgba(255, 252, 0, 1)",
  "rgba(110, 202, 220, 1)",
  "rgba(255, 153, 0, 1)",
];

const FreemiumScoreboard = () => {
  const [topTrio, setTopTrio] = useState(null);
  const [othersTop, setOthersTop] = useState(null);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const { _id: userId, firstName, lastName, companyId } = UseUserDetails();
  const logo = UseCompanyLogo();
  const businessName = UseCompanyName();
  const [challengeInfo, setChallengeInfo] = useState({});
  const [showChallengeInfo, setShowChallengeInfo] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [challenge, setChallenge] = useState(null);

  const navigate = useNavigate();
  const [topTen, setTopTen] = useState(null);
  const [showMessageBoard, setShowMessageBoard] = useState(false);
  const dispatch = useDispatch();

  const {
    isLoading: loadingMessages,
    messages,
    hasMore,
    currentPage,
  } = useSelector((state) => state.messageReducer);

  const compBp = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const barBp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const themes = useTheme();
  const isXxlScreen = useMediaQuery(themes.breakpoints.up("xxl"));
  const isMiniScreen = useMediaQuery(themes.breakpoints.only("mini"));
  const miniWidth = useMediaQuery("(min-width:320px)");
  const mdWidth = useMediaQuery("(min-width:768px)");
  const responsiveHeight = useMediaQuery("(max-height:450px)");

  const { challengeUrl } = useParams();
  const [fullScreen] = useState(!!challengeUrl);

  const handleClose = () => {
    setShowChallengeInfo(false);
  };

  const closeInvite = () => {
    setShowInvite(false);
  };

  useEffect(() => {
    if (challengeUrl) {
      // page was accessed publicly; no auth
      GET_CHALLENGE(challengeUrl).then(({ data }) => {
        if (data) {
          setChallenge(data);
        } else {
          navigate("/home");
        }
      });
    } else if (companyId) {
      // page was accesed within the app; with auth
      GET_CHALLENGE_EVENT(companyId).then(({ data }) => {
        if (data) {
          setChallenge(data);
        } else {
          navigate("/home");
        }
      });
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeUrl, userId, navigate]);

  useEffect(() => {
    if (challenge) {
      GET_SCOREBOARD(challenge._id)
        .then(({ data }) => {
          if (companyId) {
            const companyIdx = data.findIndex((c) => c.companyId === companyId);
            if (companyIdx >= 0) {
              data[companyIdx].ownCompany = true;
            }
          }

          const scores = [...data];
          const yetiPts = challenge.yetiPoints * 1;
          if (yetiPts > 0) {
            // add yeti on the leaderboard
            const yetiPlayer = {
              avatar: YETI.avatar,
              companyId: YETI.firstName,
              company: `${YETI.firstName} ${YETI.lastName}`,
              image: YETI.image,
              total: Math.max(
                Math.floor(
                  yetiPts *
                    moment
                      .duration(moment().diff(challenge.startDate))
                      .asDays(),
                ),
                0,
              ),

              isYeti: true,
            };

            scores.push(yetiPlayer);
            scores.sort((a, b) => b.total - a.total);
          }
          setTopTrio(scores.slice(0, 3));
          setOthersTop(scores.slice(3, 10));
          setTopTen(scores.slice(0, 9));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Challenge scoreboard error:", err.message);
        });
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge) {
      const info = {
        title: `${challenge.title}`,
        challenge: `${challenge.type.toUpperCase()} CHALLENGE`,
        description:
          "The arena is a sturdy ice structure, recently built. The games are being " +
          "held to determine a champion for the fastest to earn % Reviews in just a month. " +
          "Before the main event there is an exhibition duel between two champions of the arena already free " +
          "(no deaths). The monthly champion wins a petition of the sovereign.",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      };
      setChallengeInfo(info);
    }
  }, [challenge]);

  const fetchMessages = (propertyId) => {
    dispatch(getMessages({ propertyId, page, source: "arena" }));
  };

  useEffect(
    () => {
      if (challenge) {
        fetchMessages(challenge._id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, page, challenge],
  );

  const onAddComment = async () => {
    if (message !== "") {
      // jobId represent ChallengeMasterId here
      try {
        const { data: comment } = await ADD_COMMENT({
          jobId: challenge._id,
          data: {
            userId,
            content: message,
            propertyModel: "ChallengeMaster",
          },
        });

        if (comment) {
          setMessage("");
          dispatch(addMessage({ jobId: challenge._id, comment }));
        }
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  const onChangeMessageInput = (e) => {
    setMessage(e.target.value);
  };

  return (
    <SoftBox sx={{ height: { mini: "100%", xxl: "100vh" } }}>
      {/* {!challengeUrl && ( // Page wasn't accessed publicly
        <SoftBox
          sx={{
            height: { mini: "72px", xs: "58px", md: "54px" },
          }}
        />
      )} */}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <IconButton
            className="floating-btn"
            size="small"
            sx={{
              background: "#2196F3 !important",
              display: { xxl: "none" },
            }}
            onClick={() => setShowMessageBoard(!showMessageBoard)}
          >
            <ChatIcon />
          </IconButton>
          {showMessageBoard && !isXxlScreen && (
            <SoftBox className="messageboard-overlay">
              <PublicMessageBoard
                messages={messages}
                setPage={setPage}
                hasMore={hasMore}
                currentPage={currentPage}
                onAddComment={onAddComment}
                onChangeMessageInput={onChangeMessageInput}
                message={message}
                classname="message-board-top"
              />
            </SoftBox>
          )}
          {/* rgba(255, 255, 255, 0.45) */}

          <BottomNav />
          <Box
            sx={{
              height: "100%",
              marginTop: {
                mini: "0.2rem",
                md: "0rem",
              },
              backgroundImage: `url(${LeaderboardBg})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: { mini: "cover" },
              width: "100%",
              marginX: "auto",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: {
                  mini: responsiveHeight && miniWidth ? "700px" : "67vh",
                  md: responsiveHeight && mdWidth ? "800px" : "80vh",
                  wide: "85vh",
                },
              }}
            >
              {
                /* Only show invite button if user is logged-in
                 */
                userId && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "8%",
                      right: "1rem",
                      display: {
                        mini: "none",
                        md: "block",
                      },
                      zIndex: 3,
                    }}
                  >
                    <SoftButton
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "none", borderRadius: 10 }}
                      onClick={() => {
                        setShowInvite(true);
                      }}
                    >
                      + Invite your friends
                    </SoftButton>
                    <Dialog
                      fullWidth
                      maxWidth="lg"
                      open={showChallengeInfo}
                      onClose={handleClose}
                      sx={{
                        "& 	.MuiDialog-paper": {
                          borderRadius: "16px",
                          border: "1px solid rgba(255, 255, 255, 0.10)",
                          background: "rgba(255, 255, 255, 0.04)",
                          boxShadow:
                            "-24px 24px 64px 0px rgba(6, 41, 64, 0.60), -48px 48px 128px 0px rgba(6, 41, 64, 0.60)",
                          backdropFilter: "blur(80px)",
                        },
                      }}
                    >
                      <InfoPopUp
                        challengeId={challenge._id}
                        {...challengeInfo}
                        handleClose={handleClose}
                        setShowInvite={setShowInvite}
                        setShowChallengeInfo={setShowChallengeInfo}
                      />
                    </Dialog>
                    {showInvite && (
                      <InvitePopUp
                        challengeUrl={challenge.url}
                        showInvite={showInvite}
                        closeInvite={closeInvite}
                        {...challengeInfo}
                      />
                    )}
                  </Box>
                )
              }
              {isXxlScreen && (
                <PublicMessageBoard
                  messages={messages}
                  setPage={setPage}
                  hasMore={hasMore}
                  currentPage={currentPage}
                  onAddComment={onAddComment}
                  onChangeMessageInput={onChangeMessageInput}
                  message={message}
                  classname="message-board-bottom"
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  bottom: { mini: "-160px", xxxl: "-180px" },
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  paddingLeft: { mini: "5%", xs: "10%", md: "28%" },
                  paddingRight: { mini: "5%", xs: "10%", md: "28%" },
                  flexDirection: "column",
                }}
              >
                {topTrio?.length && (
                  <AvatarLeaders data={topTrio} companyLogo={logo} />
                )}
                <Grid
                  container
                  columnGap={0}
                  sx={{ width: "100%", height: "100%", alignItems: "end" }}
                >
                  {PLACES.map((rank, idx) => {
                    return (
                      <Grid
                        item
                        mini={4}
                        className="w-full h-full flex flex-col items-end"
                        key={rank}
                      >
                        <Box
                          className="w-full flex flex-col items-center px-[1px] transition-height duration-500"
                          sx={{
                            height: {
                              mini: MINIPODIUMS[rank],
                              md: DESKTOPPODIUMS[rank],
                            },
                          }}
                        >
                          <Box className="flex flex-col flex-1 w-full text-white">
                            <Box
                              sx={{
                                width: "100%",
                                height: 0,
                                ...(idx !== 0 && {
                                  borderRight: "1.5rem solid transparent",
                                }),
                                ...(idx !== 2 && {
                                  borderLeft: "1.5rem solid transparent",
                                }),
                                borderBottomWidth: "2.25rem",
                                borderBottomStyle: "solid",
                                borderBottomColor: `${STROKECOLORS[idx]}`,
                              }}
                            />
                            <Box
                              className="flex-1 w-full flex flex-col items-center"
                              sx={{
                                border: `3px solid ${BORDERCOLORS[idx]}`,
                                background: `${COLORS[idx]}`,
                                backdropFilter: "blur(16px)",
                                boxShadow: `${BOXSHADOW[idx]}`,
                              }}
                            >
                              <Box sx={{ mt: 2, width: ORDERWORD[rank].width }}>
                                {ORDERWORD[rank].asset}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>

            <SoftBox
              sx={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  height: "100%",
                  alignItems: "end",
                  paddingLeft: { mini: "5%", xs: "10%", md: "28%" },
                  paddingRight: { mini: "5%", xs: "10%", md: "28%" },
                  position: "relative",
                }}
              >
                <Grid
                  item
                  mini={4}
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      marginLeft: {
                        mini: "13.4%",
                        xxs: "14.2%",
                        xs: "9.2%",
                        sm: "10%",
                        md: "5%",
                        lg: "5.8%",
                        xl: "5.9%",
                        xxl: "6.2%",
                        xxxl: "6.4%",
                      },
                      marginTop: {
                        mini: "-365px",
                        xxs: "-420px",
                        xs: "-435px",
                        sm: "-500px",
                        md: "-600px",
                        xxxl: "-660px",
                      },
                    }}
                  >
                    <img
                      src={SilverCrown}
                      alt="Top 2"
                      style={{
                        width: "auto",
                        height: { mini: "2rem", xxxl: "4rem" },
                      }}
                    />
                  </SoftBox>
                </Grid>

                <Grid
                  item
                  mini={4}
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      marginLeft: {
                        mini: "9%",
                        xxs: "10.2%",
                        xs: "9.2%",
                        sm: "10%",
                        md: "5%",
                        lg: "5.3%",
                        xl: "5.8%",
                        xxl: "6.2%",
                        xxxl: "6.3%",
                      },
                      marginTop: {
                        mini: "-465px",
                        xxs: "-490px",
                        xs: "-520px",
                        sm: "-590px",
                        md: "-670px",
                        xxxl: "-740px",
                      },
                    }}
                  >
                    <img
                      src={GoldCrown}
                      alt="Top 1"
                      style={{
                        width: { mini: "32px", xxxl: "40px" },
                        height: { mini: "2rem", xxxl: "4rem" },
                      }}
                    />
                  </SoftBox>
                </Grid>

                <Grid
                  item
                  mini={4}
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      marginLeft: {
                        mini: "6.5%",
                        xs: "9.2%",
                        sm: "10%",
                        md: "4.2%",
                        lg: "5.2",
                        xl: "5.5%",
                        xxl: "5.8%",
                        xxxl: "6%",
                      },
                      marginTop: {
                        mini: "-340px",
                        xxs: "-380px",
                        xs: "-390px",
                        sm: "-460px",
                        md: "-535px",
                        xxxl: "-615px",
                      },
                    }}
                  >
                    <img
                      src={BronzeCrown}
                      alt="Top 3"
                      style={{
                        width: "auto",
                        height: { mini: "2rem", xxxl: "4rem" },
                      }}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            <Box className="w-full flex items-center">
              <Grid
                container
                sx={{
                  width: "100%",
                  height: "100%",
                  alignItems: "end",
                  paddingLeft: { mini: "5%", xs: "10%", md: "28%" },
                  paddingRight: { mini: "5%", xs: "10%", md: "28%" },
                  position: "relative",
                  marginTop: { mini: "-10%", xxs: "-8%", xxxl: "-5%" },
                }}
              >
                {topTrio?.length &&
                  PLACES.map((rank) => {
                    return <Leaders data={topTrio[rank]} />;
                  })}
              </Grid>
            </Box>

            <Box
              sx={{
                display: { mini: "block", xxl: "none" },
                width: "auto",
                position: "relative",
                marginTop: "4%",
                background: othersTop.length
                  ? "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)"
                  : "transparent",
                border: othersTop.length
                  ? "1px solid rgba(255, 255, 255, 0.10)"
                  : "none",
                backdropFilter: "blur(17px)",
                borderRadius: "16px",
                marginX: { mini: "5%", xs: "10%", md: "28%" },
                marginBottom: { mini: "100px", xxl: 0 },
              }}
            >
              {othersTop && othersTop.length
                ? othersTop.map((user, index) => (
                    <BarLeaderboard
                      key={user.companyId}
                      userIdNum={user.companyId}
                      name={user.company}
                      imgsrc={user.image}
                      points={user.total}
                      top={topTrio[0].total}
                      position={index + 4}
                      othersTop={othersTop}
                      rankColor={RANKCOLORS[index]}
                    />
                  ))
                : null}
            </Box>
          </Box>
          <SoftBox
            sx={{
              position: "absolute",
              top: "12%",
              right: 0,
              width: "28%",
              paddingX: "40px",
              display: { mini: "none", xxl: "block" },
            }}
          >
            <SoftBox
              shadow="lg"
              borderRadius="lg"
              className="no-scrollbar"
              sx={{
                width: "100%",
                background: {
                  mini: "white",
                  xl: "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)",
                },
                "&:last-child": { marginBottom: 0 },
                maxHeight: { xxl: "83vh", wide: "85vh" },
                overflow: "scroll",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                backdropFilter: "blur(17px)",
                "& hr:last-child": { display: "none" },
              }}
            >
              {topTrio && topTrio.length
                ? topTrio.map((user, index) => (
                    <BarLeaderboard
                      key={user.companyId}
                      userIdNum={user.companyId}
                      name={user.company}
                      imgsrc={user.image}
                      points={user.total}
                      top={topTrio[0].total}
                      position={index + 1}
                      othersTop={othersTop}
                      rankColor={RANKCOLORS[index]}
                    />
                  ))
                : null}
              {othersTop && othersTop.length
                ? othersTop.map((user, index) => (
                    <BarLeaderboard
                      key={user.companyId}
                      userIdNum={user.companyId}
                      name={user.company}
                      imgsrc={user.image}
                      points={user.total}
                      top={topTrio[0].total}
                      position={index + 4}
                      othersTop={othersTop}
                      rankColor={RANKCOLORS[index]}
                    />
                  ))
                : null}
            </SoftBox>
          </SoftBox>
        </>
      )}
    </SoftBox>
  );
};

export default FreemiumScoreboard;
