import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, useTheme } from "@mui/material";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";

const ExpandableView = ({ title, amount, closeModal }) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography
          variant="h6"
          sx={{
            fontSize: "20px",
            color: "#FFF",
            margin: 0,
            padding: 0,
            fontWeight: "500",
          }}
        >
          {title}
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={() => closeModal()}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />
      <SoftBox
        sx={{
          border: "1px solid rgba(233, 236, 239, 0.10)",
          backgroundColor: "rgba(255, 255, 255, 0.10) !important",
          padding: "20px",
          borderRadius: "6px",
          color: "#fff !important",
          "& .MuiInputBase-input::placeholder": {
            color: "white !important",
            opacity: "0.7",
          },
          "&.Mui-focused": {
            borderColor: primaryColor,
            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
          },
        }}
        mb="10px"
      >
        {amount}
      </SoftBox>
    </>
  );
};

export default ExpandableView;
