import { request } from "../helpers/axios.new";

export const GET_HOLIDAYS = (companyId) => {
  return request({
    url: `/calendar/${companyId}`, 
  });
};

export const PUT_HOLIDAY = (companyId, date, text) => {
  return request({
    url: `/calendar/${companyId}`, 
    method: "PUT",
    data: {date, text},
  });
};

export const DELETE_HOLIDAY = (companyId, date) => {
  return request({
    url: `/calendar/${companyId}`, 
    method: "DELETE",
    data: {date},
  });
};