import React from "react";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import "./swal-globals.css";

const ConfirmationModal = ({
  title,
  question,
  yesText,
  noText,
  onSuccess,
  onCancel,
  onOpen,
  onClose,
  children,
  color,
}) => {
  const theme = useTheme();

  const showAlert = () => {
    onOpen?.();

    Swal.fire({
      title,
      text: question,
      icon: question ? "question" : "success",
      iconColor: theme?.palette[color ?? "secondary"].focus,
      confirmButtonColor: theme?.palette[color ?? "primary"].main,
      cancelButtonColor: theme?.palette.primary.lighter,
      ...(noText && { showCancelButton: true }),
      ...(yesText && { confirmButtonText: yesText }),
      ...(noText && { cancelButtonText: noText }),
      reverseButtons: true,
      customClass: { container: "swal-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        onSuccess?.();
      } else if (result.isDismissed) {
        onCancel?.();
      }
      onClose?.();
    });
  };

  const handleClick = (event) => {
    if (!event.target.disabled) {
      showAlert();
    }
    event.stopPropagation();
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleClick,
        });
      })}
    </div>
  );
};

export default ConfirmationModal;
