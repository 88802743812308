import { request } from "../helpers/axios.new";

export const CREATE_COMPANY = (company) => {
  return request({
    url: `/company`,
    method: "POST",
    data: company,
  });
};

export const GET_COMPANY = (companyId) => {
  return request({
    url: `/company/${companyId}`,
  });
};

export const GET_UNIFORM = (companyId) => {
  return request({
    url: `/company/uniform/${companyId}`,
  });
};

export const PUT_COMPANY = (companyId, data) => {
  return request({
    url: `/company/${companyId}`,
    method: "PUT",
    data,
  });
};

export const UPLOAD_LOGO = (companyId, formData) => {
  return request({
    url: `/company/logo/${companyId}`,
    method: "PUT",
    data: formData,
  });
};
