import React from "react";
import Box from "@material-ui/core/Box";
import SoftTypography from "../../components/SoftTypography";

import { ReactComponent as NoClaimsSvg } from "../../img/claims-no-items.svg";

const NoClaims = () => {
  return (
    <div className="w-full min-h-[50vh] flex flex-col gap-4 justify-center items-center">
      <Box sx={{ width: "200px", height: "auto" }}>
        <NoClaimsSvg />
      </Box>

      <SoftTypography
        sx={{
          color: "#67748E",
          fontFamily: "DM Sans",
          fontSize: "1.5rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        No Items to Claim Yet!
      </SoftTypography>
      <SoftTypography
        sx={{
          color: "#67748E",
          fontFamily: "DM Sans",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          textAlign: "center",
        }}
      >
        New reviews will appear here as soon as they are available. <br />
        In the meantime, explore other features of our platform.
      </SoftTypography>
    </div>
  );
};

export default NoClaims;
