import { useEffect, useState } from "react";

/**
 * Enables horizontal scroll on DOM horizontally-arranged elements inside a larger inner container with "overflow-y:hidden", that's 
 * wrapped inside a smaller-width parent DOM container with "overflow:hidden"
 * @returns {Object} { selectorName, addScrollListener, removeScrollListener, setContainerScrollXOverflow }
 */
export const useHorizontalScroller = () => {
  const [selectorName, setSelectorName] = useState(null);
  const [eventListeners, setEventListeners] = useState(null);

  useEffect(() => {
    const itemlist = document.querySelector(selectorName);

    if (itemlist && eventListeners) {
      itemlist.addEventListener('mousedown', eventListeners.handleMouseDown);
      itemlist.addEventListener('mouseleave', eventListeners.handleMouseLeave);
      itemlist.addEventListener('mouseup', eventListeners.handleMouseUp);
      itemlist.addEventListener('mousemove', eventListeners.handleMouseMove);
    }
  }, [selectorName, eventListeners]);

  /**
   * Enables horizontal scroll on a target DOM element
   * @param {String} querySelectorName - DOM class or ID string
   * @returns 
   */
  const addScrollListener = (querySelectorName) => {
    const itemlist = document.querySelector(querySelectorName);

    if (!querySelectorName || !itemlist) return;

    let isMouseDown = false;
    let startX;
    let scrollLeft;
    
    const handleMouseDown = (e) => {
      isMouseDown = true;
      startX = e.pageX - itemlist.offsetLeft;
      scrollLeft = itemlist.scrollLeft;

      e.preventDefault();
    }
  
    const handleMouseLeave = (e) => {
      isMouseDown = false;
    }
  
    const handleMouseUp = (e) => {
      isMouseDown = false;
    }
  
    const handleMouseMove = (e) => {
      if (!isMouseDown) return;
      e.preventDefault();

      const x = e.pageX - itemlist.offsetLeft;
      const walk = (x - startX) * 2; // Adjust the scrolling speed
      itemlist.scrollLeft = scrollLeft - walk;
    }

    setEventListeners({
      handleMouseDown,
      handleMouseLeave,
      handleMouseUp,
      handleMouseMove
    });

    setSelectorName(querySelectorName);
  }

  /**
   * Rmoves horizontal scroll on the recorded target DOM element
   */
  const removeScrollListener = () => {
    const itemlist = document.querySelector(selectorName);

    if (itemlist && eventListeners) {
      itemlist.removeEventListener('mousedown', eventListeners.handleMouseDown);
      itemlist.removeEventListener('mouseleave', eventListeners.handleMouseLeave);
      itemlist.removeEventListener('mouseup', eventListeners.handleMouseUp);
      itemlist.removeEventListener('mousemove', eventListeners.handleMouseMove);

      setSelectorName(null);
    }
  }

  /**
   * Detects safari web browser
   * @returns {Bool}
   */
  const isSafariBrowser = () => {
    const isSafari = (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0)
    return isSafari
  }

  /**
   * Detects mobile web browser
   * @returns {Boolean}
   */
  const isMobileBrowser = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return isMobile
  }

  /**
   * Sets the scrollable list's root parent container "overflow-x" CSS property for desktop and mobile/safari web browsers
   * @returns {String} "auto" or "hidden" CSS overflow-x style
   */
  const setContainerScrollXOverflow = () => {
    return isSafariBrowser() || isMobileBrowser()
      ? "auto"
      : "hidden"
  }

  return {
    selectorName,
    removeScrollListener,
    addScrollListener,
    setContainerScrollXOverflow
  }
}
