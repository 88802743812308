import React, { useState, useEffect } from "react";

import WidgetBox from "../WidgetBox";
import { GET_LEVEL } from "../../../api/level-up.js";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";
import { UseUserDetails } from "../../../hooks/auth";

const Level = ({ widget = {}, reFetch, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  const [level, setLevel] = useState({});
  const [fetching, setFetching] = useState(false);

  const user = UseUserDetails();

  const fetchLevel = async () => {
    setFetching(true);
    try {
      const { data } = await GET_LEVEL(user.companyId);
      setLevel(data);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchLevel();
  }, []);

  return showPerformBox ? (
    <PerformBoxFlexible title="Level" amount={level?.name || 1} showCharts={showCharts} isSmallScreen={isSmallScreen} />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={level?.name || 1}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default Level;
