/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { UseYetiStats } from "../../hooks/company";
import { formatNumber } from "../../helpers/formatter";
import "./yetiStyles.css";

// Components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import MiniChart from "./MiniChart";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { position } from "stylis";
import { border } from "@mui/system";

const ItemBox = React.forwardRef(
  ({ kpi, amount: playerAmt, points, darkMode = false, ...rest }, ref) => {
    const [isLeading, setIsLeading] = React.useState(true);
    const [amount, setAmount] = React.useState(0);
    const [plus, setPlus] = React.useState("");
    const [yetiDiff, setYetiDiff] = React.useState(0);
    const yeti = UseYetiStats();

    useEffect(() => {
      if (yeti) {
        const versusYeti = points - yeti.month[kpi._id]?.pts;
        setPlus(versusYeti > 0 ? "+" : "");
        setIsLeading(versusYeti >= 0);

        // Original value
        let yetiDifference = playerAmt - yeti.month[kpi._id]?.amt;

        // Since we append the "plus" sign if the "versusYeti" (points) is greater than 0,
        // let this sign take precedence over the negative "yetiDifference" (amount) value
        // Note: This behaviour only happens in Negative direction anchor metrics
        if (
          kpi?.metricType === "anchor" &&
          versusYeti > 0 &&
          yetiDifference < 0
        ) {
          // Make the yetiDifference positive to remove its negative (-) sign
          yetiDifference *= -1;
        }

        setYetiDiff(yetiDifference);
        setAmount(playerAmt);
      }
    }, [kpi, yeti, playerAmt, points]);

    useEffect(() => {
      if (kpi.gap) {
        // Change display value if KPI has gap calculation
        setAmount((playerAmt - kpi.target) * kpi.direction);
      }
    }, [kpi, playerAmt]);

    return (
      <SoftBox
        ref={ref}
        shadow="sm"
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          borderRadius: "12px",
          background: darkMode ? "#21262D" : "#fff",
          ...(isLeading && points ? { border: '1px solid #FFDD00' } : {})
        }}
        {...rest}
      >
        <SoftBox
          lineHeight={1}
          className="flex flex-col justify-between h-full"
          sx={{ position: "relative", px: "8%", py: "5%" }}
        >
          <SoftBox className="flex items-center h-[15%]">
            <SoftTypography
              variant="button"
              fontWeight="regular"
              sx={{
                color: !darkMode ? "rgba(112, 119, 128, 1)" : "#FFFFFF",
                fontSize: {
                  mini: "0.875rem",
                  sm: "0.6875rem",
                  md: "0.6875rem",
                  xxl: "0.875rem",
                  wide: "1.5vh",
                },
              }}
            >
              {kpi.title}{" "}
              {kpi.isShared && (
                <Diversity3Icon className="mt-[-5%] h-[1.5vh] w-auto" />
              )}
            </SoftTypography>
            {isLeading && points !== 0 && (
                <SoftBox sx={{marginLeft: '15px!important'}}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_105_18)">
                    <circle cx="12" cy="12" r="12" fill="#FFF8CB"/>
                    <path d="M6.75001 18.75H17.25M11.6715 5.4495C11.7039 5.3907 11.7514 5.34167 11.8092 5.30752C11.867 5.27338 11.9329 5.25536 12 5.25536C12.0671 5.25536 12.133 5.27338 12.1908 5.30752C12.2486 5.34167 12.2961 5.3907 12.3285 5.4495L14.5425 9.6525C14.5953 9.74982 14.669 9.83424 14.7583 9.89971C14.8476 9.96518 14.9503 10.0101 15.059 10.0311C15.1677 10.0522 15.2797 10.049 15.387 10.0216C15.4943 9.99429 15.5942 9.94353 15.6795 9.873L18.8873 7.125C18.9488 7.07491 19.0247 7.04566 19.104 7.04145C19.1832 7.03724 19.2618 7.05828 19.3283 7.10156C19.3949 7.14483 19.446 7.2081 19.4743 7.28226C19.5025 7.35643 19.5066 7.43765 19.4858 7.51425L17.3603 15.1987C17.3169 15.356 17.2234 15.4948 17.094 15.5942C16.9646 15.6935 16.8064 15.7479 16.6433 15.7492H7.35751C7.19426 15.7481 7.03585 15.6937 6.90631 15.5944C6.77677 15.495 6.68318 15.3561 6.63976 15.1987L4.51501 7.515C4.4942 7.4384 4.49823 7.35718 4.52652 7.28301C4.55481 7.20885 4.6059 7.14558 4.67244 7.10231C4.73898 7.05903 4.81753 7.03799 4.89679 7.0422C4.97605 7.04641 5.05194 7.07566 5.11351 7.12575L8.32051 9.87375C8.40586 9.94428 8.50577 9.99504 8.61306 10.0224C8.72035 10.0497 8.83237 10.053 8.94106 10.0319C9.04976 10.0108 9.15244 9.96593 9.24173 9.90046C9.33102 9.83499 9.40472 9.75057 9.45751 9.65325L11.6715 5.4495Z" stroke="#FDC500" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_105_18">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </SoftBox>
            )}
          </SoftBox>
          <SoftTypography
            variant="h2"
            sx={{
              color: darkMode ? "#FFFFFF" : "rgba(73, 80, 87, 1)",
              fontSize: {
                mini: "1.625rem",
                sm: "1.25rem",
                md: "1.25rem",
                xxl: "1.625rem",
                wide: "5vh",
              },
              fontWeight: { mini: 500, xxl: 500, wide: 700 },
            }}
          >
            {formatNumber(amount, kpi.kind)}&nbsp;
          </SoftTypography>
          <SoftTypography
            variant="button"
            sx={{
              color: kpi.gap
                ? darkMode
                  ? "#FFFFFF"
                  : "#000000"
                : isLeading
                ? "positive.main"
                : "negative.main",
              fontSize: { mini: "14px", md: "0.6875rem", sm: "0.6875rem", xxl: "14px", wide: "1.75vh" },
              fontWeight: { mini: 400, sm: 500 },
            }}
          >
            {!kpi.gap
              ? `${plus}${formatNumber(yetiDiff, kpi.kind)}`
              : formatNumber(kpi.target, kpi.kind)}
          </SoftTypography>
        </SoftBox>
        <MiniChart kpi={kpi} playerAmount={playerAmt} />
      </SoftBox>
    );
  },
);

export default ItemBox;
