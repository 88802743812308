import { initState, types } from "./types";
import _ from "lodash";

const workspacesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_CHATS:
      return {
        ...state,
        isLoading: action.loading === false ? action.loading : true,
        selectedHandler: action.selectedHandler ? action.selectedHandler : null,
        isError: null,
      };
    case types.GET_CHATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chats: action.chats,
        isError: null,
      };
    case types.GET_CHATS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        chats: [],
      };
    case types.CREATE_CHAT:
      return {
        ...state,
        creatingChat: true,
        isError: null,
      };
    case types.CREATE_CHAT_SUCCESS:
      if (!state.chats.find((item) => item._id === action.chat._id)) {
        return {
          ...state,
          creatingChat: false,
          chats: [action.chat, ...state.chats],
          isError: null,
        };
      } else {
        return {
          ...state,
          creatingChat: false,
          chats: [...state.chats],
          isError: null,
        };
      }
    case types.CREATE_CHAT_FAILED:
      return {
        ...state,
        creatingChat: false,
        error: action.error,
        chats: state.chats,
      };
    case types.GET_MESSAGES:
      return {
        ...state,
        fetchingMessages: true,
        isError: null,
      };
    case types.CREATE_GROUP_CHAT:
      return {
        ...state,
        creatingGroupChat: true,
        isError: null,
      };
    case types.CREATE_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        creatingGroupChat: false,
        chats: [action.chat, ...state.chats],
        isError: null,
      };
    case types.CREATE_GROUP_CHAT_FAILED:
      return {
        ...state,
        creatingGroupChat: false,
        error: action.error,
        chats: [...state.chats],
      };
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        fetchingMessages: false,
        messages: _({})
          .merge(
            _(state.messages).groupBy("_id").value(),
            _(action.messages).groupBy("_id").value(),
          )
          .values()
          .flatten()
          .value(),
        hasMoreMessages: action.hasMoreMessages,
        currentMessagePage: action.currentMessagePage,
        isError: null,
      };
    case types.GET_MESSAGES_FAILED:
      return {
        ...state,
        fetchingMessages: false,
        error: action.error,
        messages: [],
      };
    case types.SEND_MESSAGE_TO_CHAT:
      return {
        ...state,
        isError: null,
      };
    case types.SEND_MESSAGE_TO_CHAT_SUCCESS:
      return {
        ...state,
        isError: null,
        messages: _({})
          .merge(
            _(action.message).groupBy("_id").value(),
            _(state.messages).groupBy("_id").value(),
          )
          .values()
          .flatten()
          .value(),
      };
    case types.SEND_MESSAGE_TO_CHAT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case types.SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
        messages: [],
      };
    case types.SET_SELECTED_CHAT_SUCCESS:
      return {
        ...state,
        selectedChat: action.selectedChat,
      };
    case types.SET_SELECTED_CHAT_FAILED:
      return {
        ...state,
        selectedChat: null,
        error: action.error,
      };
    case types.SET_CHATS:
      return {
        ...state,
        chats: action.chats,
      };
    case types.RENAME_GROUP_CHAT:
      return {
        ...state,
        updatingGroup: true,
        isError: null,
      };
    case types.RENAME_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        updatingGroup: false,
        isError: null,
      };
    case types.RENAME_GROUP_CHAT_FAILED:
      return {
        ...state,
        updatingGroup: false,
        error: action.error,
      };
    case types.UPDATE_GROUP_USERS_CHAT:
      return {
        ...state,
        updatingGroup: true,
        isError: null,
      };
    case types.UPDATE_GROUP_USERS_CHAT_SUCCESS:
      return {
        ...state,
        updatingGroup: false,
        isError: null,
      };
    case types.UPDATE_GROUP_USERS_CHAT_FAILED:
      return {
        ...state,
        updatingGroup: false,
        error: action.error,
      };
    case types.ADD_CHAT_MESSAGE:
      return {
        ...state,
        messages: _({})
          .merge(
            _([action.receivedMessage]).groupBy("_id").value(),
            _(state.messages).groupBy("_id").value(),
          )
          .values()
          .flatten()
          .value(),
      };
    default:
      return state;
  }
};

export default workspacesReducer;
