import { types } from "./types";

export const getMessages = (payload) => ({
  type: types.GET_ALL_MESSAGES,
  payload,
});

export const addMessage = (payload) => ({
  type: types.ADD_MESSAGE,
  ...payload,
});
