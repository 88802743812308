import React from "react";
import Box from "@mui/material/Box";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const RankIcon = ({
  difference,
  darkMode,
  ...props
}) => {
  // eslint-disable-next-line no-nested-ternary
  const IconSrc = difference > 0? ArrowDropUpIcon: difference < 0? ArrowDropDownIcon: SyncAltIcon;
  // eslint-disable-next-line no-nested-ternary
  const color = difference > 0 ? "success": difference < 0? "error": (darkMode? "white": "secondary");
  const iconSize = difference === 0? "1.5vh": "2.5vh"; // Make no-change icon smaller

  return (
    <Box sx={{width: "2vh", height: "1vh", display: "flex", alignItems:"center", position: "relative", ...props}}>
      <IconSrc color={color} sx={{width: iconSize}} className="h-auto absolute -translate-x-1/2 left-1/2" />
    </Box>
    
  );
};

export default RankIcon;
