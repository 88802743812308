import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";

// Validation
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccountSettingsSchema } from "../../../form-validations";

// Components
import AccountSettingsV2 from "./AccountSettingsV2";

// Hooks, redux
import { UseUserDetails, UseUserId, UseIsAdmin } from "../../../hooks/auth";
import { updateUserDetails } from "../../../redux/auth/actions";

// Services API
import {
  UPDATE_USER,
  PUT_USER,
  UPDATE_USER_IMAGE,
  DELETE_USER_IMAGE,
  SEND_RESET_PASSWORD_LINK,
} from "../../../api/user";

// Constants
const FORM_FIELDS = ["firstName", "lastName"];
const PASSWORD_FIELDS = ["oldPassword", "newPassword", "confirmNewPassword"];

const DEFAULT_PROFILE_DATA = {
  IS_DELETE: false,
  FILE: null
};

const ACCOUNT_SECTION = {
  NAME: "name",
  PASSWORD: "password",
  PICTURE_DELETE: "picture_delete",
  PICTURE_REPLACE: "picture_replace"
};

const AccountSettingsV2Container = () => {
  const user = UseUserDetails();
  const userId = UseUserId();
  const isAdmin = UseIsAdmin();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pictureData, setPictureData] = useState(DEFAULT_PROFILE_DATA);

  const validationOpt = {
    resolver: yupResolver(AccountSettingsSchema),
    shouldUnregister: false,
    defaultValues: user
  };

  const methods = useForm(validationOpt);
  const { mutateAsync: updateUserPassword } = UPDATE_USER();

  const {
    mutateAsync: sendResetPasswordLink,
    isLoading: isLoadingReset,
  } = SEND_RESET_PASSWORD_LINK();

  /**
   * Retrieves the form input fields to validate
   * @returns {String[]} Account form input fields to validate
   */
  const getFieldsToValidate = (formInputValues) => {
    // First name, last name are default
    let fieldsToValidate = [...FORM_FIELDS];

    const fieldsWithValues = Object.keys(formInputValues).filter((key) => 
      !["", null, undefined].includes(formInputValues[key]));

    // Validate the password input fields only if one of them has a value (user attempted to update password)
    if (fieldsWithValues.some(item => PASSWORD_FIELDS.includes(item))) {
      fieldsToValidate = [...fieldsToValidate, ...PASSWORD_FIELDS];
    }

    return fieldsToValidate;
  }

  // Parses the error message from an Error object
  const getErrorMessage = (error) => {
    return error?.response?.data?.message
      ?? error?.response?.data?.statusText
      ?? error.message;
  }

  const handleProfilePicture = (pictureURL, isDelete = false) => {
    setPictureData({
      IS_DELETE: isDelete,
      FILE: isDelete
        ? null
        : pictureURL
    })
  }

  const processResponse = (section, response) => {
    const { status, reason, value } = response;

    const errorMessage = (status === "rejected")
      ? getErrorMessage(reason) + "\n"
      : "";

    if (status === "rejected" || !value) {
      return errorMessage;
    }

    switch (section) {
      case ACCOUNT_SECTION.NAME: {
        const { _doc: userDetails } = response?.value?.data;

        // Use original implementation if "userDetails" undefined
        const { password, ...userDetailsOrig } = response?.value?.data;
        const updatedUserDetails = userDetails ?? userDetailsOrig;

        // Do not include user p/w in redux
        delete updatedUserDetails?.password;
        dispatch(updateUserDetails(updatedUserDetails));
      }
      break;
      case ACCOUNT_SECTION.PASSWORD:
        break;
      case ACCOUNT_SECTION.PICTURE_DELETE:
        dispatch(updateUserDetails({ image: null }));
        break;
      case ACCOUNT_SECTION.PICTURE_REPLACE:
        dispatch(updateUserDetails({ image: value?.data }));
        break;
      default:
        break;
    }

    return errorMessage;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    methods.clearErrors();
    const formValues = methods.getValues();
    const fieldsToValidate = getFieldsToValidate(formValues);

    // Validate input fields
    const result = await methods.trigger(fieldsToValidate, { shouldFocus: true });
    if (!result) return;

    const formData = { ...formValues };
    const updating = toast.loading("Updating user...");
    let errMsg = "";

    setIsLoading(true);

    // Exclude the current profile pic and passwords when updating the first/last name
    delete formData.image;

    PASSWORD_FIELDS.forEach((field) => {
      delete formData[field];
    });

    try {
      // Update user first/last name request (always update by default)
      const ACCOUNT_UPDATE_QUERIES = Object.values(ACCOUNT_SECTION).reduce((list, key) => ({...list, [key]: undefined}), {});

      // Update first/last name
      ACCOUNT_UPDATE_QUERIES[ACCOUNT_SECTION.NAME] = PUT_USER(userId, formData);

      // Update password
      if (fieldsToValidate.includes('newPassword')) {
        const { oldPassword, newPassword } = formValues;
        ACCOUNT_UPDATE_QUERIES[ACCOUNT_SECTION.PASSWORD] = updateUserPassword({
          userId,
          data: {
            oldPassword,
            newPassword,
            updatePassword: true,
          },
        });
      }

      // Delete profile picture
      if (pictureData.IS_DELETE) {
        ACCOUNT_UPDATE_QUERIES[ACCOUNT_SECTION.PICTURE_DELETE] = DELETE_USER_IMAGE();
      }

      // Replace profile picture
      if (pictureData.FILE) {
        const imageData = new FormData();
        imageData.append("image", pictureData.FILE);
        ACCOUNT_UPDATE_QUERIES[ACCOUNT_SECTION.PICTURE_REPLACE] = UPDATE_USER_IMAGE(userId, imageData);
      }

      // Run parallel queries
      const [userData, passwordData, imgDeleteData, imgUpdateData]
        = await Promise.allSettled(Object.values(ACCOUNT_UPDATE_QUERIES));

      // Process parallel responses
      errMsg += processResponse(ACCOUNT_SECTION.NAME, userData);
      errMsg += processResponse(ACCOUNT_SECTION.PASSWORD, passwordData);
      errMsg += processResponse(ACCOUNT_SECTION.PICTURE_DELETE, imgDeleteData);
      errMsg += processResponse(ACCOUNT_SECTION.PICTURE_REPLACE, imgUpdateData);

      setIsLoading(false);

      if (errMsg !== "") {
        toast.error(errMsg, { id: updating });
      } else {
        PASSWORD_FIELDS.forEach(key => {
          methods.setValue(key, "");
        });

        setPictureData(DEFAULT_PROFILE_DATA);
        toast.success("User Updated", { id: updating });
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      toast.error(errorMessage, { id: updating });
      setIsLoading(false);
    }
  }

  const handleLogout = () => {
    navigate("/signout");
  }

  const handleSendResetPassword = async () => {
    const updating = toast.loading("Sending email...");

    try {
      const { data } = await sendResetPasswordLink({ email: user?.email });

      if (data?.code === "SUCCESS") {
        toast.success(data?.message, { id: updating });
      }
    }
     catch (err) {
      const errorMessage = getErrorMessage(err);
      toast.error(errorMessage, { id: updating });
     }
  }

  return (
    <FormProvider {...methods}>
      <AccountSettingsV2
        userDetails={user}
        isLoading={isLoading || isLoadingReset}
        isAdmin={isAdmin}
        fileSelectedCallback={handleProfilePicture}
        handleFormSubmit={handleFormSubmit}
        handleLogout={handleLogout}
        handleSendResetPassword={handleSendResetPassword}
        UseUserId={userId}
      />
    </FormProvider>
  );
};

export default AccountSettingsV2Container;
