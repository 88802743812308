import React from "react";
import PricingDetails from "./PricingDetails";

const Pricing = () => {
  return (
    <div className="container mt-[1.5rem] mb-[2.5rem] px-[12px]">
      <PricingDetails />
    </div>
  );
};

export default Pricing;
