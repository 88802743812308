import React from "react";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";

import iconImg from "../../img/infobar-icon.png";

const InfoBar = ({ headerText, subheaderText, subheaderText2 }) => {
  return (
    <SoftBox
      sx={{
        padding: "1rem 0 1rem 1.5rem",
        marginX: { mini: "1rem", md: "1.5rem" },
        backgroundColor: "primary.main",
        borderRadius: "8px",
      }}
    >
      <SoftBox display="flex" alignItems="flex-start">
        <img src={iconImg} alt="Icon" style={{ fontSize: "medium" }} />
        <SoftBox
          display="flex"
          flexDirection="column"
          sx={{ marginLeft: "10px" }}
        >
          <SoftTypography variant="h5" sx={{ color: "#FFF" }}>
            {headerText}
          </SoftTypography>
          <SoftTypography variant="p" sx={{ color: "#FFF" }}>
            {subheaderText}
          </SoftTypography>
          <SoftTypography variant="p" sx={{ color: "#FFF" }}>
            {subheaderText2}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default InfoBar;
