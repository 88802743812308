import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

// SoftUI/MUI Componentns
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { Divider, useTheme, Grid, LinearProgress } from "@mui/material";

// Components
import PageHeader from "../../components/PageHeader";
import LoadingScreen from "../../components/LoadingScreen";
import Notes from "../../components/Notes";
import SearchBar from "../../components/SearchBar";
import TimelineList from "../../components/Timeline/TimelineList";
import TimelineItem from "../../components/Timeline/TimelineItem";
import popNotification from "../../components/Modals/popNotification";

// API Services
import { GET_USER_KPIS, UPDATE_USER_KPI, DELETE_USER_KPI } from "../../api/kpi";

// Hooks
import { UseCompanyId } from "../../hooks/auth";
import { UseSelectedWorkspace, UseUsersDictionary } from "../../hooks/company";
import { UseGroupKpis, UseKpiScoresTable } from "../../hooks/kpi";
import useIsTopVisible from "../../hooks/useIsTopVisible";

// Redux
import { getPreviousKpis, refreshKpis } from "../../redux/kpi/actions";

// Utils
import { formatNumber } from "../../helpers/formatter";

// Icons
import { ReactComponent as AdminIcon } from "../../img/timeline-adminicon.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { ReactComponent as IntegratedIcon } from "../../img/timeline-integratedicon.svg";
import { ReactComponent as ManualIcon } from "../../img/timeline-manualicon.svg";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GroupIcon from "@mui/icons-material/Group";

const HIDDEN_INTEGRATION_LABEL =
  process.env.REACT_APP_INTEGRATION_LABEL === "true";

const KPI_SOURCE = {
  INTEGRATED: {
    icon: <IntegratedIcon />,
    description: "Integrated score",
  },
  ADMIN_ADJUSTED: {
    icon: <AdminIcon />,
    description: "Admin-adjusted reported score",
  },
  ADMIN_ENTERED: {
    icon: <AdminPanelSettingsIcon />,
    description: "Admin-entered reported score",
  },
  PLAYER_ENTERED: {
    icon: <ManualIcon />,
    description: "Player-entered reported score",
  },
  OTHER: {
    icon: <AdminIcon />,
    description: "Other reported score",
  },
};

const Timeline = () => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const companyId = UseCompanyId();
  const currentWorkspace = UseSelectedWorkspace();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const userIdParam = searchParams.get("user");
  const kpiIdParam = searchParams.get("kpi");
  const groupKpis = UseGroupKpis();
  const isVisible = useIsTopVisible(true, "#timeline");
  const usersDict = UseUsersDictionary();
  const [userId, setUserId] = useState(userIdParam);
  const [kpiId, setKpiId] = useState(kpiIdParam);
  const [noteKpiId, setNoteKpiId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [allKpis, setAllKpis] = useState([]);
  const [message, setMessage] = useState(null);
  const [monthRef, setMonthRef] = useState(null);

  useEffect(() => {
    if (monthRef) {
      dispatch(getPreviousKpis(monthRef));
    }
  }, [monthRef, dispatch, currentWorkspace]);

  useEffect(() => {
    if (!kpiIdParam && groupKpis && groupKpis.length) {
      setKpiId(groupKpis[0]._id);
    }
  }, [groupKpis, kpiIdParam]);

  useEffect(() => {
    if (kpiId || userId) {
      setIsLoading(true);
      GET_USER_KPIS(companyId, userId, kpiId)
        .then(({ data: eKpis }) => {
          setIsLoading(false);
          if (eKpis.length === 0) {
            setMessage("No recent records found");
            setAllKpis([]);
          } else {
            eKpis.forEach((ekpi) => {
              const users = usersDict[ekpi.ownerId];
              ekpi.name = users?.firstName
                ? `${users.firstName} ${users.lastName}`
                : "Unregistered";
            });

            eKpis.forEach((ekpi) => {
              const obj = groupKpis.find((kpi) => kpi._id === ekpi.kpiId);
              ekpi.kpiName = obj?.title || obj?.name;
              ekpi.kind = obj?.kind;
              ekpi.scoreType = "OTHER";
              ekpi.source = obj?.source?.toUpperCase() ?? "NONE";

              const isOffset = ekpi?.isOffset ?? false;

              if (ekpi.reporterId === undefined) {
                // Label "Integrated" - score is computed/generated from server
                ekpi.scoreType = "INTEGRATED";
              } else if (isOffset) {
                // Label "AdminAdjusted" - score is entered by an Admin using the "Total Score Entry Mode"
                ekpi.scoreType = "ADMIN_ADJUSTED";
              } else {
                // Label "AdminEntered" - score is entered by an Admin using the "Incremental Entry Mode"
                if (ekpi?.reporterId !== ekpi?.ownerId) {
                  ekpi.scoreType = "ADMIN_ENTERED";
                }

                // Label "PlayerEntered" - score is entered by a Player
                if (ekpi?.reporterId === ekpi?.ownerId) {
                  ekpi.scoreType = "PLAYER_ENTERED";
                }
              }
            });

            setAllKpis(eKpis);
          }
        })
        .catch((err) => {
          setAllKpis([]);
          setMessage(err.message);
        });
    }
  }, [companyId, kpiId, userIdParam, usersDict, userId]);

  const handleOpenNotes = (employeeKpiId) => {
    setNoteKpiId(employeeKpiId);
    setOpenNotes(true);
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const handleNoteCount = (id) => {
    const eKpis = [...allKpis];
    const kpiIndex = eKpis.findIndex((item) => item._id === id);

    if (kpiIndex === -1) return;

    eKpis[kpiIndex].noteCount += 1;
    setAllKpis(eKpis);
  };

  const formatItemDescription = (amount, kpiKind, kpiName = "", playerName = "") => {
    const formatAount = formatNumber(amount, kpiKind);
    const itemTypeAddText = amount < 0 ? "deducted from" : "added to";

    return (kpiKind !== "$")
      // Item-type KPIs (i.e., MX Plan, Yard Sign)
      ? `${formatAount} ${kpiName} added to ${playerName}`
      // "$" type KPIs
      : `A total of ${formatAount} ${kpiName} was ${itemTypeAddText} ${playerName}`;
  }

  const updateLocalKpiState = ({
    id,
    amount = 0,
    points = 0,
    operation = "edit",
  }) => {
    // Local state update after POST/DELETE success
    const tempKpis = [...allKpis];
    const recordIndex = tempKpis.findIndex((x) => x._id === id);

    if (recordIndex === -1) return;

    if (operation === "edit") {
      tempKpis[recordIndex].amount = amount;
      tempKpis[recordIndex].points = points;
    } else {
      // Delete
      tempKpis.splice(recordIndex, 1);
    }

    setAllKpis(tempKpis);
  };

  const handleUpdateKpi = async (id, eKpiId, value) => {
    try {
      setIsLoading(true);
      const response = await UPDATE_USER_KPI(id, eKpiId, value);

      dispatch(refreshKpis());
      setIsLoading(false);

      updateLocalKpiState({
        id,
        amount: response?.data?.amount ?? 0,
        points: response?.data?.points ?? 0,
        operation: "edit",
      });

      popNotification({
        title: "Success",
        text: "The KPI entry was updated.",
      });
    } catch (err) {
      popNotification({
        title: "Error",
        text: `The KPI entry was not updated\n${err.message}`,
        icon: "error",
      });
    }
  };

  const handleDeleteKpi = async (id) => {
    try {
      setIsLoading(true);
      await DELETE_USER_KPI(id);

      dispatch(refreshKpis());
      setIsLoading(false);
      updateLocalKpiState({ id, operation: "delete" });

      popNotification({
        title: "Success",
        text: "The KPI entry was deleted.",
      });
    } catch (err) {
      popNotification({
        title: "Error",
        text: `The KPI entry was not deleted\n${err.message}`,
        icon: "error",
      });
    }
  };

  return (
    <>
      <PageHeader
        headerText="Scorekeeper"
        subheaderText="Manage Employee Scores and Performance"
      />

      <SoftBox
        marginY="2rem"
        sx={{
          overflowX: "auto",
          background: "white",
          borderRadius: "1rem",
          marginX: { mini: "1rem", md: "1.5rem" },
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: {
              mini: "1rem 1rem 0rem 1rem",
              md: "1.5rem 1.5rem 0rem 1.5rem",
            },
          }}
        >
          <SoftTypography variant="h4" color="black">
            Timeline
          </SoftTypography>
          <SoftBox>
            <SearchBar
              placeholder="Last 30 days"
              icon={{
                component: <EventOutlinedIcon sx={{ fill: "#6C757D" }} />,
                direction: "right",
              }}
              sx={{
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                  outline: 0,
                },
              }}
            />
          </SoftBox>
        </SoftBox>
        <Divider />
        <SoftBox
          sx={{
            padding: {
              mini: "1rem",
              md: "1.5rem",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item mini={3} xs={4} sm={5} xxl={3}>
              <SoftBox>
                <SoftBox>
                  <SoftTypography
                    variant="body2"
                    marginBottom="0.75rem"
                    sx={{ color: "#6C757D" }}
                  >
                    Available Metrics
                  </SoftTypography>

                  {groupKpis && (
                    <SoftBox display="flex" flexDirection="column" gap="0.5rem">
                      {groupKpis?.map((kpi) => (
                        <SoftButton
                          key={kpi._id}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            maxWidth: {
                              mini: "120px",
                              xs: "160px",
                              sm: "200px",
                              md: "240px",
                              lg: "300px",
                            },
                            fontSize: { mini: "10px", sm: "14px", lg: "20px" },
                            color:
                              kpiId === kpi._id
                                ? "white !important"
                                : "#343A40 !important",
                            borderColor: "#E9ECEF",
                            textTransform: "none",
                            padding: {
                              mini: "0.25rem 0.5rem",
                              sm: "0.35rem 0.75rem",
                              lg: "0.5rem 1.25rem",
                            },
                            boxShadow:
                              "0px 2.68548px 4.47581px 0px rgba(0, 0, 0, 0.06)",
                            justifyContent: "start",
                            textAlign: "start",
                            background:
                              kpiId === kpi._id
                                ? primaryColor
                                : "white !important",
                            "&:hover": {
                              borderColor: "#E9ECEF",
                              background: primaryColor,
                            },
                            "&:focus:not(:hover)": {
                              borderColor: "#E9ECEF",
                              background: primaryColor,
                            },
                          }}
                          onClick={() => setKpiId(kpi._id)}
                        >
                          {kpi.title}
                        </SoftButton>
                      ))}
                    </SoftBox>
                  )}
                </SoftBox>
              </SoftBox>
            </Grid>
            <Grid item mini={9} xs={8} sm={7} xxl={9}>
              <SoftBox
                id="timeline"
                sx={{
                  background: "#F4F6F8",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                {useMemo(
                  () =>
                    // eslint-disable-next-line no-nested-ternary
                    isLoading ? (
                      <LoadingScreen />
                    ) : allKpis.length > 0 ? (
                      <TimelineList>
                        {allKpis?.map((timeline) => (
                          <TimelineItem
                            color="success"
                            icon={
                              KPI_SOURCE[timeline?.scoreType]?.icon ??
                              KPI_SOURCE.OTHER.icon
                            }
                            eKpidId={timeline._id}
                            notesText={`${timeline?.noteCount ?? 0} Note${
                              (timeline?.noteCount ?? 0) > 1 ? "s" : ""
                            }`}
                            title={KPI_SOURCE[timeline?.scoreType]?.description}
                            date={moment(timeline.createdAt).format(`MMM DD`)}
                            description={formatItemDescription(
                              timeline.amount,
                              timeline.kind ,
                              timeline.kpiName,
                              timeline.name
                            )}
                            time={moment(timeline.createdAt).format(`LT`)}
                            timeline={timeline}
                            handleOpenNotes={handleOpenNotes}
                            handleKpiUpdate={handleUpdateKpi}
                            handleKpiDelete={handleDeleteKpi}
                          />
                        ))}
                      </TimelineList>
                    ) : (
                      <SoftBox padding={2} width="100%">
                        <SoftTypography
                          variant="h6"
                          color="primary"
                          sx={{ textAlign: "center", width: "100%" }}
                        >
                          No data
                        </SoftTypography>
                      </SoftBox>
                    ),
                  [isLoading, allKpis],
                )}

                {openNotes && (
                  <Notes
                    eKpidId={noteKpiId}
                    onAddNote={handleNoteCount}
                    isVisible={isVisible}
                    handleClickAway={(isNote) => {
                      if (!isNote) {
                        handleCloseNotes();
                      }
                    }}
                  />
                )}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default Timeline;
