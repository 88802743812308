import {instance as axios} from "../helpers/axios.new";

const url = process.env.REACT_APP_LINK_HOST;

export const getUser = async (id) => {
  try {
    const response = await axios.get(`${url}/user/${id}`);
    const data = await response.data;
    return data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const logIn = async (user) => axios.post(`${url}/auth/login`, user);

export const register = async (user) =>
  axios.post(`${url}/api/auth/register`, user);

export async function updateProfile({ id, payload }) {
  return axios.put(`${url}/api/user/${id}`, payload);
}

export const getColors = async (data) => {
  return axios.post(`${url}/get-colors`, data);
};
