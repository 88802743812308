import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedWorkspace } from "../../redux/workspace/actions";
import { UseCompanyWorkspaces } from "../../hooks/company";

import Slider from "react-slick";
import LoadingScreen from "../../components/LoadingScreen";
import Treemap from "../../components/Treemap";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import options from "./config/treemapOptions";

import { formatNumber, formatPercent } from "../../helpers/formatter";

const KpiTreemap = ({ scoreData }) => {
  const workspaces = UseCompanyWorkspaces(true);
  const [charts, setCharts] = useState(null);
  const [title, setTitle] = useState(
    "Metrics performance all through the workspaces",
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (scoreData) {
      const chartScores = scoreData
        .map(({ workspace, scores: summarizedScores }) => {
          if (!summarizedScores?.length) return null;
          // Recalculate and reformat scores for treemap
          const treemapScores = summarizedScores.map(
            ({ score, ratio, kpiId, title: kpiTitle, kind, workspace: innerWorkspaceInfo }) => {
              const variations = 4;
              const fakeRatio =
                (Math.ceil(Math.abs((ratio || 0.01) * variations)) / variations) *
                (ratio < 0 ? -1 : 1);

              return {
                x: kpiTitle,
                y: Math.max(Math.min(fakeRatio, 1), -1), // Set max 1 and min -1 values
                z: formatNumber(score, kind),
                value: formatPercent(Math.round(ratio * 100) / 100),
                kpiId,
                workspaceId: innerWorkspaceInfo?.id ?? "",
                workspace: (innerWorkspaceInfo !== undefined)
                  ? innerWorkspaceInfo?.name ?? ""
                  : workspace
              };
            },
          );

          return { workspace, scores: treemapScores };
        })
        .filter((item) => !!item);
      setCharts(chartScores);
    }
  }, [scoreData]);

  const handleSelect = (kpi) => {
    const workspaceId = kpi?.workspaceId;
    const workspace = workspaces.find((w) => w._id === workspaceId);

    setTimeout(() => {
      dispatch(setSelectedWorkspace({ selectedWorkspace: workspace }));
    }, 750); // delay the workspace switch a bit to trigger the transition sequence
    navigate("/dashboard");
  };

  return (
    <SoftBox
      shadow="sm"
      borderRadius="xxl"
      sx={{
        p: 2,
        height: "100%",
        minHeight: { xs: "400px", md: "460px" },
        "& .slick-prev, .slick-next": {
          width: "40px",
          height: "40px",
          zIndex: 1,
          opacity: 0.8,
          "&::before": {
            color: "#bfbfbf",
            fontSize: "40px",
            borderRadius: "40px",
          },
        },
      }}
    >
      {charts ? (
        <Slider
          dots
          infinite
          arrows
          autoplay={false}
          speed={1000}
          autoplaySpeed={5000}
          afterChange={(idx) =>
            setTitle(
              charts && charts[idx]?.workspace
                ? `Metrics performance for ${charts[idx]?.workspace} workspace`
                : "Metrics performance all through the workspaces",
            )
          }
        >
          {charts
            ?.filter(({ scores }) => !!scores)
            ?.map(({ workspace, scores }) => (
              <SoftBox key={workspace || "all"}>
                <SoftTypography variant="h6" sx={{ color: "#6C757D", ml: 2 }}>
                  {title}
                </SoftTypography>
                <SoftBox
                  borderRadius="xxl"
                  sx={{
                    overflow: "hidden",
                    height: { xs: "320px", md: "380px" },
                  }}
                >
                  <Treemap
                    options={options}
                    scores={scores}
                    onClick={handleSelect}
                  />
                </SoftBox>
              </SoftBox>
            ))}
        </Slider>
      ) : (
        <SoftBox className="h-full w-full flex justify-center items-center">
          <LoadingScreen height="200px" logoHeight="200px" />
        </SoftBox>
      )}
    </SoftBox>
  );
};

export default KpiTreemap;
