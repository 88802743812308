import React from "react";
import { styled } from "@mui/material/styles";
import SoftBox from "../../../SoftBox";

const FormControlButtonGroup = styled(SoftBox)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
  margin: "auto",
  '& button': {
    width: "30%"
  },
  [theme.breakpoints.down("md")]: {
    '& button': {
      width: "100%"
    }
  },
  [theme.breakpoints.down("481")]: {
    flexDirection: "column"
  }
}));

export default FormControlButtonGroup;
