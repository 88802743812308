/* eslint camelcase: 0 */

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { GET_CONNECTIONS } from "../../../api/integrations/apideck";
import { GET_COMPANY_INTEGRATIONS, GET_CONNECTION_URL } from "../../../api/integration";
import { APIDECK } from "../../../constants/integrations";

const Selector = () => {
  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");

  const handleSelect = async (unifiedApi, serviceId) => {
    const windowRef = window.open("", "_self");
    GET_CONNECTION_URL(APIDECK, {unifiedApi, serviceId})
      .then(({data}) => {
        windowRef.location = data;
      });
  };

  const handleSearch = () => {
    if(search && apps.length) {
      const key = search.trim().toLowerCase();
      const filtered = apps.filter(z => z.title.toLowerCase().indexOf(key) >= 0);
      setFilteredApps(filtered);
    } else {
      setFilteredApps(apps);
    }
  };


  useEffect(() => {
    GET_CONNECTIONS()
      .then(({data: connections}) => {
        const integrations = connections.map(c => ({
          id: c.serviceId,
          title: c.name.toUpperCase(),
          integration: c.serviceId,
          image: c.logo,
          unifiedApi: c.unifiedApi,
          active: false,
        }));
        setApps(integrations);
      });

    GET_COMPANY_INTEGRATIONS()
      .then(({data: integrations}) =>{
          if(integrations?.length) {
            setActiveIntegrations(integrations);
          }
      });
  }, []);

  useEffect(() => {
    if(activeIntegrations.length) {
      activeIntegrations.forEach(integ => {
        const integrationCode = integ?.parameters?.serviceId;
        if(integrationCode) {
          const idx = apps.findIndex(a => a.integration === integrationCode);
          if(idx >= 0) {
            apps[idx].active = true;
          }
        }
      });
    }
    setFilteredApps(apps);
  }, [apps, activeIntegrations]);

  useEffect(() => {
    setTimeout(() => toast.error(error), 1500);
  }, [error]);

  return (
    <div className="p-16">
      <div className="my-4">
        <div className="my-4 flex justify-end">
          <TextField 
            label="Search Integrations" 
            variant="outlined" 
            value={search}
            size="small"
            onChange={(e)=>setSearch(e.target.value)}
            className="mx-2"
          />
          <Button 
            variant="contained"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
        <Stack spacing={4} direction="row">
        {
          filteredApps.length ? 
            filteredApps.map(z => (
              <Card key={z.id} 
                className="min-w-[10rem] p-2 cursor-pointer" 
                onClick={!z.active? () => handleSelect(z.unifiedApi, z.integration): null}
                sx={{
                  background: z.active? (theme) => theme.palette.gradient : "white",
                  color: z.active? "white": "primary.main",
                }}
              >
                <Typography variant="caption">{z.integration.toUpperCase()}</Typography>
                <img src={z.image} alt={z.title} className="p-2 h-[80px]"/>
                <Typography variant="body2">{z.title}</Typography>
              </Card>
            )) :
            <Typography variant="body2">No integrations found</Typography>
        }
        </Stack>
      </div>
    </div>
  );
};

export default Selector;
