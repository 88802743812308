import React from "react";
import SoftBox from "../SoftBox";
import { styled } from "@mui/material/styles";

const ImageBox = styled(SoftBox)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",

  "&::before": {
    content: "'_'",
    position: "absolute",
    inset: 0,
    zIndex: "-1"
  },
  
  "& img": {
    width: "100%",
    height: "100%",
    aspectRation: "1 / 1",
    display: "block",
    objectPosition: "center",
    objectFit: "cover",
    borderRadius: theme.spacing(1),
    opacity: 0,
    transition: "opacity 200ms ease-in-out"
  }
}));

export default ImageBox;
