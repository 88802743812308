import React, { useEffect, useState } from "react";

import { UseIsDemo, UseUserDetails } from "../../../hooks/auth";
import { UseTopPlayers } from "../../../hooks/kpi";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";
import WidgetBox from "../WidgetBox";

const Rank = ({ widget = {}, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  const [rank, setRank] = useState("");

  const isDemo = UseIsDemo();
  const topUsers = UseTopPlayers("month");
  const user = UseUserDetails();

  useEffect(() => {
    if (!isDemo) {
      let tmpRank = null;
      if (topUsers) {
        const index =
          topUsers.findIndex((item) => item.userId === user._id) + 1;
        if (index >= 1) {
          const j = index % 10;
          const k = index % 100;
          if (j === 1 && k !== 11) {
            tmpRank = index + "st";
          } else if (j === 2 && k !== 12) {
            tmpRank = index + "nd";
          } else if (j === 3 && k !== 13) {
            tmpRank = index + "rd";
          } else {
            tmpRank = index + "th";
          }
        }
      }
      setRank(tmpRank ?? "Unranked");
    } else {
      setRank("1st");
    }
  }, [topUsers, user._id, isDemo]);

  return showPerformBox ? (
    <PerformBoxFlexible title="Ranking" amount={rank} showCharts={showCharts} isSmallScreen={isSmallScreen} />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={rank}
      setReFetch={setReFetch}
    />
  );
};

export default Rank;
