import React, { useState, useEffect } from "react";
import KingOfTheMountain from "./KingOfTheMountain";

import { UseUserDetails, UseIsDemo, UseIsAdmin } from "../../hooks/auth";
import {
  UseCompany,
  UseYetiStats,
  UseSelectedWorkspace,
} from "../../hooks/company";
import { UseMyKpis } from "../../hooks/kpi";

const Gameboard = ({ type, mode, playerScore, yetiScore, maxPoint, darkMode, noScroll }) => {
  const [gameboard, setGameboard] = useState(null);
  const [settings, setSettings] = useState(null);

  const user = UseUserDetails();
  const userKpi = UseMyKpis();

  const isDemo = UseIsDemo();
  const yetiStats = UseYetiStats();
  const isAdmin = UseIsAdmin();
  const workspace = UseSelectedWorkspace();
  const { logo, businessName } = UseCompany();

  useEffect(() => {
    if (workspace && user && yetiStats) {
      const boardSettings = {};

      boardSettings.yetiScore = yetiScore ?? yetiStats.month.total;
      boardSettings.maxPoint =
        maxPoint ?? (yetiStats.maxTotal || 10000) + (isDemo ? 500 : 0);

      boardSettings.playerScore = playerScore ?? userKpi?.month?.total ?? 0;

      if (mode === "company") {
        boardSettings.playerName = businessName;
        boardSettings.playerLogo = logo;
      } else if (isAdmin) {
        boardSettings.playerName = workspace.name;
        boardSettings.playerLogo = workspace.image;
      } else {
        boardSettings.playerName = user.firstName;
        boardSettings.playerLogo = user.image;
      }
      setSettings(boardSettings);
    }
  }, [
    mode,
    userKpi,
    yetiScore,
    playerScore,
    maxPoint,
    yetiStats,
    isAdmin,
    user,
    workspace,
    isDemo,
  ]);

  useEffect(() => {
    if (settings) {
      switch (type) {
        default:
          setGameboard(<KingOfTheMountain {...settings} darkMode={darkMode} noScroll={noScroll} />);
      }
    }
  }, [type, settings]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{gameboard}</>;
};

export default Gameboard;
