import React, { useState } from "react";
import {
  Dialog,
  Divider,
  useTheme,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  updateWorkspaceKpi,
  disableWorkspaceKpi,
} from "../../../redux/kpi/actions";
import { CustomKpiEditMetricSchema } from "../../../form-validations";
import { UseSelectedWorkspace } from "../../../hooks/company";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import SoftInput from "../../SoftInput";
import popNotification from "../../Modals/popNotification";
import { KIND_CONFIGS } from "../form/kindOptions";
import { EFFECT_CONFIGS } from "../form/effectOptions";

const EditDisplayMetric = ({
  kpi,
  metricType,
  openEditKpi,
  handleCloseEditKpi,
  handleConfirmationOpen,
}) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const [kindConfig] = useState(KIND_CONFIGS[kpi.kind]);
  const [effectConfig] = useState(EFFECT_CONFIGS[kpi.direction]);
  const [processing, setProcessing] = useState(false);
  const [inputs, setInputs] = useState([]);
  const { _id: groupId } = UseSelectedWorkspace();
  const dispatch = useDispatch();

  const currentValidationSchema = CustomKpiEditMetricSchema[2];

  const validationOptions = {
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      name: kpi.title,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm(validationOptions);

  const handleClose = () => {
    setProcessing(false);
    handleCloseEditKpi();
    clearErrors();
    reset();
    setInputs([]);
  };

  const handleRegister = async (formData) => {
    setProcessing(true);

    const kpiData = {
      ...kpi,
      ...formData,
      groupId,
    };

    dispatch(updateWorkspaceKpi(kpiData));

    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Your Metric has been Updated",
          text: `You've successfully update your ${formData.name.toUpperCase()} Metric.`,
        }),
      1500,
    );
    handleClose();
  };

  const handleDelete = () => {
    dispatch(disableWorkspaceKpi(kpi._id));
    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Metric Deleted",
          text: `You've successfully deactivated ${kpi.title.toUpperCase()} metric.`,
        }),
      1500,
    );
    handleClose();
  };

  return (
    <Dialog
      open={openEditKpi}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1rem 1.5rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "700px",
          borderWidth: "1px",
          borderColor: "#FFF",
          "::-webkit-scrollbar": {
            display: "none",
            height: 0,
            width: 0,
          },
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox
          sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <SoftTypography
            variant="h4"
            sx={{
              color: "#FFF",
              fontSize: "20px",
              textTransform: "capitalize",
            }}
          >
            Edit {kpi.title} Metric ({metricType} Metric)
          </SoftTypography>
          <SoftTypography
            variant="caption"
            sx={{ color: "#FFF", fontSize: "12px" }}
          >
            Adjust your custom metric settings
          </SoftTypography>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <form onSubmit={handleSubmit(handleRegister)}>
        <Grid container spacing={1} className="mt-2">
          <Grid item mini={12} marginBottom={1}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Name Your KPI Metric
              </SoftTypography>
            </SoftBox>
            <SoftInput
              size="large"
              {...register("name")}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>

          <Grid item mini={6} marginBottom={3}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Daily Target
              </SoftTypography>
            </SoftBox>
            <SoftInput
              size="large"
              disabled
              value={kpi.target}
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>
          <Grid item mini={6} marginBottom={3}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Point Value
              </SoftTypography>
            </SoftBox>
            <SoftInput
              size="large"
              disabled
              value={kpi.pointScale}
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid item mini={12} marginBottom={5} marginTop={3}>
          <SoftBox
            sx={{
              marginBottom: "0.25rem",
            }}
          >
            <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
              Metric Behavior
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            {kindConfig && (
              <SoftBox
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "16px", color: "#D0D5DD", fontWeight: 400 }}
                >
                  Metric Effect Type
                </SoftTypography>
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "14px", color: "#9E77ED", fontWeight: 700 }}
                >
                  {kindConfig.name}
                </SoftTypography>
              </SoftBox>
            )}
            {effectConfig && (
              <SoftBox
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "16px", color: "#D0D5DD", fontWeight: 400 }}
                >
                  Metric Value Type
                </SoftTypography>
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "14px", color: "#9E77ED", fontWeight: 700 }}
                >
                  {effectConfig.name}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>

        <Divider
          sx={{
            backgroundColor: "#FFF",
          }}
        />

        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "0.75rem",
            margin: "1rem",
          }}
        >
          <SoftButton
            sx={{
              width: "100%",
              borderRadius: "4px",
              border: "1px solid rgba(233, 236, 239, 0.20)",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              textTransform: "none",
              background: "rgba(255, 255, 255, 0.10)",
              color: "#CED4DA",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.10)",
              },
            }}
            onClick={handleConfirmationOpen}
          >
            <DeleteOutlineIcon fontSize="medium" sx={{ marginRight: "2px" }} />{" "}
            Delete Metric
          </SoftButton>

          <SoftButton
            color="primary"
            sx={{
              width: "100%",
              textTransform: "none",
              borderRadius: "4px",
              color: "#E9ECEF",
            }}
            disabled={processing}
            onClick={() => handleSubmit(handleRegister)}
            type="submit"
          >
            Update Metric
            {processing && (
              <CircularProgress color="inherit" size="1rem" className="ml-2" />
            )}
          </SoftButton>
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default EditDisplayMetric;
