import React from "react";
import moment from "moment";

// Imported styles
import { Box, Typography, Button, Stack } from "@mui/material";

// Imported Images and Icons
import TourIcon from "@mui/icons-material/Tour";
import EventIcon from "@mui/icons-material/Event";

const InfoPopUp = (props) => {
  const {
    challengeId,
    title,
    challenge,
    description,
    startDate,
    endDate,
    setShowInvite,
    setShowChallengeInfo,
  } = props;
  return (
    <Box
      sx={{
        paddingY: {
          mini: "2.5rem",
          md: "2rem",
        },

        paddingX: {
          mini: "1.5rem",
          md: "3rem",
        },
      }}
    >
      <Stack>
        <Typography
          variant="h3"
          sx={(theme) => ({
            fontFamily: "Inter",
            textTransform: "uppercase",
            fontWeight: 800,
            background: `-webkit-linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main});`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          })}
          className="!text-[2.5rem] sm:!text-[2.75rem] lg:!text-[3rem] 2xl:!text-[3.25rem]"
        >
          {title}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textTransform: "uppercase",
            fontWeight: 600,
          }}
          className="!text-[1.5rem] sm:!text-[2rem] lg:!text-[2.5rem] 2xl:!text-[3rem]"
        >
          {challenge}
        </Typography>

        <Typography sx={{ fontWeight: 600, my: 2 }}>
          <TourIcon /> About this Challenge
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2 }}
          className="text-sm md:text-base"
        >
          {description}
        </Typography>
        <Typography variant="body1">
          <EventIcon />
          <span style={{ fontWeight: 700, marginRight: 5, marginLeft: 5 }}>
            Open Arena:
          </span>
          <span>
            {moment(startDate).format("MMM Do")} -{" "}
            {moment(endDate).format("MMM Do, YYYY")}
          </span>
        </Typography>

        <Stack justifyContent="flex-end" alignItems="center" sx={{ pt: 2 }}>
          <Button
            variant="contained"
            onClick={() => {
              setShowChallengeInfo(false);
              setShowInvite(true);
            }}
            color="primary"
          >
            Invite Friends
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InfoPopUp;
