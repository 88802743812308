import React from "react";
import Typography from "@mui/material/Typography";

// Imported Styles
import "./calendarHoliday.css";
import NextEvents from "../Calendar/NextEvents";

// Imported Images and Icons

const HolidayItem = ({
  monthDisplay,
  workingDays,
  nonWorkingDays,
  clicked,
  monthEvents,
}) => {
  return (
    <div className="mx-auto xl:my-[1rem] w-full rounded-lg calHolContainerShadow">
      <Typography
        className="rounded-tr-lg rounded-tl-lg mt-[0rem] p-[1rem] text-[#fff] w-full text-center"
        sx={{ background: (theme) => theme.palette.gradient }}
      >
        {monthDisplay} Workdays
      </Typography>
      <div className="p-[1rem]">
        <div className="calHolEventsShadow p-[1rem] rounded-lg mb-[1rem]">
          <p className="text-[#00000099] my-[0.25rem]">
            This Month's Working Days
          </p>
          <div className="flex items-center gap-2">
            <p className="text-xs md:text-sm w-max rounded-2xl bg-[#00000014] px-[0.5rem] py-[0.25rem]">
              {workingDays} days
            </p>
          </div>
        </div>
        <div className="calHolEventsShadow p-[1rem] rounded-lg">
          <p className="text-[#00000099] my-[0.25rem]">Non-Working Days</p>
          <div className="flex items-center gap-2">
            {/* <span className="font-semibold text-4xl">{nonWorkingDays} </span> */}
            <p className="text-xs md:text-sm w-max rounded-2xl bg-[#00000014] px-[0.5rem] py-[0.25rem]">
              {nonWorkingDays} days
            </p>
          </div>
        </div>
        <hr className="bg-gray rounded-lg w-full h-[1px] mt-[1rem] mb-[0.5rem]" />
        <div className="my-[0.25rem] pb-[1rem]">
          <NextEvents monthEvents={monthEvents} />
        </div>
      </div>
    </div>
  );
};

export default HolidayItem;
