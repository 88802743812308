import { GET_IMAGE } from "../../../helpers/images";

export const getReceiverName = (chatUsers, userDetails) => {
  return chatUsers[0]._id === userDetails._id
    ? // ? `${chatUsers[1].firstName ? chatUsers[1].firstName : "N/A"} ${
      //     chatUsers[1].lastName ? chatUsers[1].lastName : "N/A"
      //   }`
      `${chatUsers[1].firstName} ${chatUsers[1].lastName}`
    : `${chatUsers[0].firstName} ${chatUsers[0].lastName}`;
};

export const getReceiverImage = (chatUsers, userDetails) => {
  return chatUsers[0]._id === userDetails._id
    ? GET_IMAGE(chatUsers[1].image)
    : GET_IMAGE(chatUsers[0].image);
};
