import React, { useState } from "react";
import { Dialog, Divider, useTheme } from "@mui/material";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Filter = ({ children, handleApply }) => {
  const [open, setOpen] = useState(false);
  const [filterIdx, setFilterIdx] = useState(0);
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const handleOpen = (event) => {
    if (!event.target.disabled) {
      setOpen(true);
    }
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FILTER_OPTIONS = [
    {
      name: "Ranking Order",
    },
    {
      name: "Alphabetical Order",
    },
  ];

  const handleFilter = (idx) => {
    setFilterIdx(idx);
  };

  const handleSave = (index) => {
    setOpen(false);
    handleApply(index);
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleOpen,
        });
      })}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiPaper-root": {
            width: "350px",
          },
        }}
      >
        <SoftBox>
          <SoftBox
            paddingX={3}
            paddingBottom={0}
            paddingTop={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftTypography variant="h5" color="black">
              Filter
            </SoftTypography>
            <SoftButton
              variant="text"
              onClick={handleClose}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon sx={{ fill: "rgba(108, 117, 125, 1)" }} />
            </SoftButton>
          </SoftBox>
          <Divider
            sx={{
              backgroundColor: "#E9ECEF",
              boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.08)",
              "&:not([size])": {
                height: "2px",
              },
            }}
          />
        </SoftBox>
        <SoftBox paddingX={3} paddingBottom={3} paddingTop={1}>
          <SoftBox display="flex" flexDirection="column" gap="0.5rem">
            {FILTER_OPTIONS.map((type, index) => (
              <SoftButton
                key={`filter-${type.name}`}
                onClick={() => handleFilter(index)}
                sx={{
                  width: "100%",
                  background:
                    filterIdx === index
                      ? "#E9ECEF"
                      : "rgba(255, 255, 255, 0.10)",
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                  padding: "0.875rem 1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.05)",
                  "&:hover": {
                    background: "#E9ECEF",
                  },

                  "&:focus:not(:hover)": {
                    background: "#E9ECEF",
                  },
                }}
              >
                <SoftTypography variant="h6" sx={{ color: "#343A40" }}>
                  {type.name}
                </SoftTypography>
                <SoftBox
                  width="16px"
                  height="16px"
                  borderRadius="2px"
                  border="1px solid #CED4DA"
                  sx={{
                    background: filterIdx === index ? primaryColor : "white",
                  }}
                />
              </SoftButton>
            ))}
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="end"
            alignItems="end"
            marginTop="1rem"
          >
            <SoftButton
              variant="contained"
              color="primary"
              onClick={() => handleSave(filterIdx)}
              sx={{ textTransform: "none", borderRadius: "4px" }}
            >
              Apply Filter
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Dialog>
    </>
  );
};

export default Filter;
