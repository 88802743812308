import Swal from "sweetalert2";
import "./swal-globals.css";

const popNotification = ({title, text, icon = null}) => {
  Swal.fire({
    title,
    text,
    icon: icon ?? "success",
    customClass: { container: "swal-container" },
  })
};

export default popNotification;