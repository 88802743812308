import { types, initState } from "./types";

const claimsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_COMPANY_CLAIMS_REQUEST:
      return { ...state, error: null, isLoading: true };
    case types.GET_COMPANY_CLAIMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        claims: action.claims,
      };
    case types.GET_COMPANY_CLAIMS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.PUT_USER_CLAIMS_REQUEST:
      return { ...state, error: null, isLoading: true };
    case types.PUT_USER_CLAIMS_SUCCESS:
      return { ...state, isLoading: false };
    case types.PUT_USER_CLAIMS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default claimsReducer;
