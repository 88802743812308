import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";

import { types } from "./types";
import { types as rootTypes } from "../types";
import {GET_APP_VERSION} from "../../api/app";

const getClientVersion = (state) => state.appReducer.version;

function* GetAppVersion() {
  try {
    const {data: version} = yield call(() => GET_APP_VERSION());
    const clientVersion = yield select(getClientVersion); 

    yield put({
      type: types.PUT_VERSION,
      version,
      // Consider as synced if app is loaded from a clear cache (client has no version)
      // Or if current client's app version matches the server's
      synced: !!(clientVersion) || clientVersion === version,
    });
  } catch (err) {
    console.log('Get version error', err.message);
  }
}

function* CheckAppVersion(action) {
  try {
    const {synced} = action;
    if(!synced) { // Force sign out if version doesn't match latest server (API) version
      yield put({type: rootTypes.SIGN_OUT});
    }
  } catch (err) {
    console.log('Version check error', err.message);
  }
}

function* appSaga() {
  yield takeEvery(types.GET_VERSION, GetAppVersion);
  yield takeEvery(types.PUT_VERSION, CheckAppVersion)
}

function* rootSaga() {
  yield all([
    fork(appSaga),
  ]);
}

export default rootSaga;
