import { useEffect, useState } from "react";
import moment from "moment";

export const useDate = (events, nav) => {
   const [dateDisplay, setDateDisplay] = useState("");
   const [monthDisplay, setMonthDisplay] = useState("");
   const [currentMonth, setCurrentMonth] = useState("");
   const [days, setDays] = useState([]);

   useEffect(() => {
      const dt = moment().add(nav, "months");
      const day = dt.date();
      const firstDayOfMonth = moment(dt).startOf("month");
      const daysInMonth = dt.daysInMonth();
      const getMonth = dt.month() + 1;

      const convertMonth = () => {
         return getMonth < 10 ? `0${getMonth.toString()}` : getMonth.toString();
      }

      setDateDisplay(dt.format("MMMM YYYY"));
      setMonthDisplay(dt.format("MMMM"));
      setCurrentMonth(convertMonth());
      const paddingDays = firstDayOfMonth.day();

      const daysArr = [];

      for (let i = 1; i <= paddingDays + daysInMonth; i++) {

         if (i > paddingDays) {
            const calendarDay = moment(dt).startOf("month").add(i - paddingDays - 1, "days");
            const dayOfWeek = calendarDay.day();
            const dayString = calendarDay.format("MM/DD/YYYY");
            const newEvent = events.find(event => event.date === dayString);
            daysArr.push({
               value: i - paddingDays,
               event: newEvent,
               isCurrentDay: i - paddingDays === day && nav === 0,
               isWeekend: dayOfWeek === 0 || dayOfWeek === 6,
               date: dayString,
            });
         } else {
            daysArr.push({
               value: "padding",
               event: null,
               isCurrentDay: false,
               date: "",
            });
         }
      }

      setDays(daysArr);
   }, [events, nav]);

   return {
      days,
      dateDisplay,
      monthDisplay,
      currentMonth
   };
};