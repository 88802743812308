import React from "react";
import MessageContainer from "../../components/MessageContainer";
import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";

export default function Messages() {
  return (
    <>
      <PageHeader
        headerText="Message"
        subheaderText="Connect with Your Teammates"
      />
      <SoftBox sx={{ margin: "1.5rem 1rem" }}>
        <MessageContainer />
      </SoftBox>
    </>
  );
}
