import React from "react";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import { ReactComponent as GoogleSvg } from "../../img/googlebusiness.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const LinkGoogle = ({ handleClose }) => {
  return (
    <>
      <SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <GoogleSvg />
        </SoftBox>
        <SoftTypography variant="h4" color="white" textAlign="center">
          Google Business Profile
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
            position: "absolute",
            right: { mini: "1.5rem", md: "2rem" },
            top: { mini: "1.5rem", md: "2rem" },
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>

      <SoftBox
        borderRadius="12px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginY="1rem"
        sx={{
          background: "rgba(255, 255, 255, 0.10)",
          border: "2px dashed rgba(221, 99, 99, 0.70)",
          padding: { mini: "1rem 1.5rem", md: "1.5rem 2.5rem" },
        }}
      >
        <SoftTypography variant="body2" color="white" textAlign="center">
          To participate in review-based challenges, we need your permission to
          access your Google My Business reviews. This will help us tailor
          challenges based on your business reviews.
        </SoftTypography>
      </SoftBox>

      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginY="1rem"
      >
        <SoftTypography variant="button" sx={{ color: "#9B9EA0" }}>
          Note
        </SoftTypography>
        <SoftTypography variant="caption" sx={{ color: "#9B9EA0" }}>
          Your privacy and security are important to us. We'll only use the
          authorization to provide you with a better challenge experience. You
          can revoke this access at any time from your account settings.
        </SoftTypography>
      </SoftBox>
      <SoftButton
        variant="contained"
        color="primary"
        sx={{
          width: "max-content",
          textTransform: "none",
          padding: "12px 84px",
          margin: "1rem auto 0rem",
        }}
      >
        Authorize
      </SoftButton>
    </>
  );
};

export default LinkGoogle;
