import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  chatListContainer: {
    padding: "15px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    background: "white",
    boxShadow: "0px 15.9768px 31.9536px 4.438px rgba(135, 135, 135, 0.12)",
    borderRadius: "16px 0px 0px 16px",
    height: "70vh",
    overflowY: "auto",
  },
  heading: {
    marginTop: "20px",
    fontWeight: "700",
    fontSize: "24px",
    textAlign: "center",
  },
  searchArea: {
    display: "flex",
    gap: "5px",
    border: "1px solid #9B9B9B",
    borderRadius: "10px",
  },
  input: {
    flex: "1",
    paddingLeft: "5px",
    outline: "none",
    border: "none",
    borderRadius: "10px",
  },
  searchIcon: {},
  chatList: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "20px",
  },
  meetingArea: {
    margin: "20px 0px 10px 0px",
  },
  meetingHeading: {
    fontWeight: "700",
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "10px",
  },
  meetingNotification: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px 10px 20px",
    borderRadius: "10px",
    background: "#FFEBF6",
    alignItems: "center",
  },
  meetingBtn: {
    borderRadius: "10px",
    background: "#FF007E",
    color: "white",
    padding: "10px 7px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  meetingNo: {
    fontSize: "13px",
    fontWeight: "500",
  },
  meetingTime: {
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    fontSize: "10px",
  },
  participant: {
    display: "flex",
    gap: "-15px",
    marginTop: "10px",
    position: "relative",
    height: "30px",
  },
  participentImg: {
    borderRadius: "50%",
    position: "absolute",
  },
});
