import * as Yup from "yup";

const AddPlayerSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Provide a valid email"),
  department: Yup.string().required("Department is required"),
});

export default AddPlayerSchema;
