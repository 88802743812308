import React, { useState, useMemo, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SoftButton from "../SoftButton";
import KpiModal from "../../pages/ManualEntry/KpiModal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Popover, Dialog } from "@mui/material";

// Icons
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as TimelineIcon } from "../../img/sk-timelineicon.svg";
import { ReactComponent as AttachLinkIcon } from "../../img/sk-linkicon.svg";
import { ReactComponent as MetricScoreIcon } from "../../img/sk-settingicon.svg";
import { ReactComponent as IntegratedIcon } from "../../img/timeline-integratedicon.svg";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftInput from "../SoftInput";
import SoftTypography from "../SoftTypography";
import SoftProfile from "../ProfilePictures/SoftProfile";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import borders from "../../assets/theme/base/borders";
import AddLinkModal from "./AddLinkModal";

import ViewAllLinksModal from "./ViewAllLinksModal";
import ManageLinks from "./ManageLinks";
// import "@szhsin/react-menu/dist/index.css";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MetricScoreConfig from "./MetricScoreConfig";

// Hooks
import { UseCompanyId } from "../../hooks/auth";
import { UseSelectedWorkspace } from "../../hooks/company";

// Redux
import { refreshKpis, addKpiRecord } from "../../redux/kpi/actions";

// API services
import { CREATE_LINKS } from "../../api/links";
import { GET_KPI_PREFERENCES, SET_KPI_PREFERENCES } from "../../api/preference";

// Helper scripts
import { formatNumber } from "../../helpers/formatter";

// Constants
const PREFERENCES_CATEGORY = {
  KPI_COLUMN_ORDERING: "kpiColumnOrdering",
};

const SCORE_ENTRY_MODE = {
  ALL: "All",
  TOTAL_SCORE: "TotalScore",
  INCREMENTAL: "Incremental",
};

const MODAL_METRIC_SCORE = "METRIC_SCORE";
const MODAL_MANAGE_LINKS = "MANAGE_LINKS";
const MODAL_ADD_LINK = "ADD_LINK";
const MODAL_VIEW_LINKS = "VIEW_LINKS";

function Table({ groupKpis, usersKpis, monthRef, filterIndex }) {
  const navigate = useNavigate();
  const companyId = UseCompanyId();
  const workspace = UseSelectedWorkspace();
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState([]);
  const [filterUsersData, setFilterUsersData] = useState([]);
  const [metricSettingsData, setMetricSettingsData] = useState({});
  const [rowsData, setRowsData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentKpiId, setCurrentKpiId] = useState(null);

  const [currentKpiLinksData, setKpiLinksData] = useState({
    kpiId: null,
    kpiLinks: null,
  });

  const [modalFlags, setModalFlags] = useState({
    [MODAL_METRIC_SCORE]: false,
    [MODAL_MANAGE_LINKS]: false,
    [MODAL_ADD_LINK]: false,
    [MODAL_VIEW_LINKS]: false,
  });

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const disabledColumns = ["User", "Points"];

  const TABLE_USER_COLUMN_WIDTH = "300px";
  const TABLE_COLUMN_WIDTH = "131px";

  const gridStyle = Array.from(columns).reduce((str, column) => {
    let gridFr = "1fr ";

    if (column.name === "User") {
      gridFr = "1.5fr ";
    }

    str += gridFr;
    return str;
  }, "");

  const initializeOrderedColumnHeaders = useCallback(
    (orderedKpis = [], kpiScoreEntryModes = []) => {
      const cols = [
        { name: "User", align: "center", width: "300px", _id: "user-field" },
        { name: "Points", align: "center", _id: "points-field" },
      ];

      const groupKpiIds = groupKpis.map((x) => x._id);

      // Remove orderedKPI entries that may have been deleted from the fresh groupKPIs list
      let mergedKPIs = [...orderedKpis].filter((x) => groupKpiIds.includes(x));

      // Append new groupKPIs to the last columns
      mergedKPIs = [
        ...mergedKPIs,
        ...groupKpiIds.filter((x) => !mergedKPIs.includes(x)),
      ];

      // Use the stored KPI column ordering preferences for displaying the column headers
      mergedKPIs?.forEach((kpiId) => {
        const kpiData = groupKpis.find((kpi) => kpi._id === kpiId);

        const columnContainer = {
          _id: kpiData?._id,
          kpiLinks: kpiData?.kpiLinks,
          name: kpiData?.title,
          align: "center",
          icon: kpiData?.isManual === false ? <IntegratedIcon /> : null,
        };

        cols.push(columnContainer);
      });

      // Initialize the KPI metrics preferences
      const kpiSettings = groupKpis.reduce((list, item) => {
        const entryMode =
          kpiScoreEntryModes.find((x) => x._id === item._id)?.mode ??
          SCORE_ENTRY_MODE.INCREMENTAL;

        return [
          ...list,
          {
            kpiId: item._id,
            isAdminAccess: item.isAdminAccess,
            isIncremental: [
              SCORE_ENTRY_MODE.INCREMENTAL,
              SCORE_ENTRY_MODE.ALL,
            ].includes(entryMode),
            isTotalScore: [
              SCORE_ENTRY_MODE.TOTAL_SCORE,
              SCORE_ENTRY_MODE.ALL,
            ].includes(entryMode),
          },
        ];
      }, []);

      setColumns(cols);
      setMetricSettingsData(kpiSettings);
    },
    [groupKpis],
  );

  useEffect(() => {
    GET_KPI_PREFERENCES(workspace?._id)
      .then((response) => {
        const columnOrdering = response?.data?.kpiColumns ?? [];
        const scoreEntryPreferences = response?.data?.scoreEntryModes ?? [];
        initializeOrderedColumnHeaders(columnOrdering, scoreEntryPreferences);
      })
      .catch(() => {
        initializeOrderedColumnHeaders();
      });
  }, [workspace, initializeOrderedColumnHeaders]);

  useEffect(() => {
    if (usersKpis) {
      setUsersData(usersKpis);
    }
  }, [usersKpis]);

  // Manage Modal State, Visibility and Functions
  const handleOpenModal = ({ kpiId, targetModal, kpiLinks }) => {
    if (!targetModal) return;

    setModalFlags((prev) => ({ ...prev, [targetModal]: true }));
    setCurrentKpiId((prev) => kpiId);
    setKpiLinksData((prev) => ({ ...prev, kpiId, kpiLinks }));
  };

  const handleCloseModal = (targetModal) => {
    if (!targetModal) return;
    setModalFlags((prev) => ({ ...prev, [targetModal]: false }));
  };

  // Handle Metric Score Settings Modal success Functions
  const handleMetricConfigSuccess = ({
    kpiId,
    scoreEntryMode,
    isAdminAccess,
  }) => {
    const settings = [...metricSettingsData];
    const settingIndex = settings.findIndex((x) => x.kpiId === kpiId);

    if (settingIndex === -1) return;

    settings[settingIndex].isAdminAccess = isAdminAccess;
    settings[settingIndex].isIncremental = [
      SCORE_ENTRY_MODE.INCREMENTAL,
      SCORE_ENTRY_MODE.ALL,
    ].includes(scoreEntryMode);
    settings[settingIndex].isTotalScore = [
      SCORE_ENTRY_MODE.TOTAL_SCORE,
      SCORE_ENTRY_MODE.ALL,
    ].includes(scoreEntryMode);

    setMetricSettingsData(settings);

    const payload = {
      isLoading: false,
    };

    dispatch(refreshKpis(payload));
  };

  const updateColumnPreferences = async (reorderedKpiColumns) => {
    try {
      const payload = {
        groupId: workspace._id,
        category: PREFERENCES_CATEGORY.KPI_COLUMN_ORDERING,
        kpiColumns: reorderedKpiColumns
          .filter((kpi, index) => ![0, 1].includes(index))
          .map((kpi) => kpi._id),
      };

      await SET_KPI_PREFERENCES(payload);
    } catch (error) {
      toast.error(
        "Your column preferences were not saved. Please refresh the browser and try again.",
      );
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    if (disabledColumns.includes(columns[result.destination.index].name))
      return;

    const revisedOrderColumns = [...columns];
    const eId = revisedOrderColumns.findIndex(
      (el) => el._id === result.draggableId,
    );

    // Remove col name at the selected position
    revisedOrderColumns.splice(eId, 1);

    // Re-attach removed col name to the new position
    revisedOrderColumns.splice(result.destination.index, 0, columns[eId]);

    updateColumnPreferences(revisedOrderColumns);
    setColumns(revisedOrderColumns);
  };

  // Adjust the draggable column handle styles when dragging
  const getStyle = (style, snapshot) => {
    // if (!snapshot.isDragging) return {};

    if (snapshot.isDragging) {
      return {
        ...style,
        opacity: 1,
        borderRadius: "8px",
        backgroundColor: "#f2f2f2",
        boxShadow:
          "0rem 0.25rem 0.4375rem -0.0625rem rgba(0, 0, 0, 0.11), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.07)",
        padding: "12px 8px 12px 0",
      };
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      // transitionDuration: `0.001s`
    };
  };

  const isIncrementalModeDisabled = useCallback(
    (kpiId) => {
      if (Object.keys(metricSettingsData).length === 0) return;
      const setting = metricSettingsData.find((x) => x.kpiId === kpiId);

      const disabled = !setting ? false : setting.isTotalScore;

      return disabled;
    },
    [metricSettingsData],
  );

  const handleTotalScoreSave = async (kpiId, value, origValue, playerId) => {
    if ([undefined, null].includes(value)) return;

    // Numeric table cell values may contain "$", "%" and "," symbols now as requested.
    // Remove "$", "%" and "," symbols from user input, since user input may or may not retain these symbols
    const numStrValue = value?.replace(/[$%,]/g, "");

    // Remove "$", "%" and "," symbols from the original "formatted" value
    const numOrigFormattedValue = origValue?.formatted?.replace(/[$%,]/g, "");

    /* eslint-disable no-restricted-globals */
    const isNumber = (num) => !isNaN(num);

    // Compare user input and the value displayed in the cell (original "formatted" value)
    if (!isNumber(numStrValue) || !isNumber(numOrigFormattedValue)) return;

    // Convert user input to Number for numeric computation
    const newValue = parseFloat(numStrValue);

    // Convert the raw, unformatted original value for numeric reference
    const originalValue = parseFloat(origValue?.orig);

    if (newValue === originalValue) return;

    const isPositiveAdjustment = newValue > originalValue;
    const offset =
      Math.abs(originalValue - newValue) * (isPositiveAdjustment ? 1 : -1);

    const payload = {
      amount: offset,
      isOffset: true,
    };

    dispatch(addKpiRecord(kpiId, playerId, monthRef, payload));
    toast.success("KPI Added");
  };

  const getOriginalCellValue = (user, index, kpiKind) => {
    // This is the original table cell display logic
    const value =
      user.kpis && user.kpis[index] < 1
        ? user.kpis[index]
        : Math.round(user?.kpis?.[index] || 0);

    // New: Display formatted numbers.
    // Formatted numbers can be a Number, or a String (Number/Decimal with "$", "%" and "," symbols)
    const formatted = formatNumber(value, kpiKind);

    return {
      orig: value,
      // For consistency, set Number values to String to enable Regex /$%,/g removal
      formatted: formatted?.toString(),
    };
  };

  useEffect(() => {
    if (Object.keys(metricSettingsData).length === 0) return;

    if (filterIndex === 0) {
      const sortByPoints = usersData.sort((a, b) => b.total - a.total);
      setFilterUsersData(sortByPoints);
    } else {
      const sortByName = usersData.sort((a, b) => {
        const firstA = a.info.firstName.toLowerCase();
        const firstB = b.info.firstName.toLowerCase();
        if (firstA < firstB) {
          return -1;
        }
        if (firstA > firstB) {
          return 1;
        }
        return 0;
      });
      setFilterUsersData(sortByName);
    }

    if (filterUsersData.length === 0) return;
    const rows = [];

    filterUsersData?.forEach((user, rowIndex) => {
      const rowContainer = {};

      rowContainer.User = [
        user.info.image,
        `${user.info.firstName} ${user.info.lastName}`,
        user.info.email,
        user.userId,
      ];

      rowContainer.Points = (
        <SoftTypography variant="h5" sx={{ color: "#343A40" }}>
          {Math.round(user.total)}
        </SoftTypography>
      );

      groupKpis?.forEach((kpi, index) => {
        const cellValue = getOriginalCellValue(user, index, kpi?.kind);

        rowContainer[kpi.title] = groupKpis && (
          <SoftBox key={kpi._id}>
            <KpiModal
              kpi={kpi}
              userId={user.userId}
              index={index}
              month={monthRef}
              disabled={isIncrementalModeDisabled(kpi._id)} // HERE 1
              userPoints={cellValue?.orig}
              sx={{
                width: "100%",
              }}
            >
              {isIncrementalModeDisabled(kpi._id) ? (
                <SoftInput
                  id={`cell-${rowIndex}-${index}`}
                  defaultValue={cellValue?.formatted}
                  onBlur={(e) => {
                    const { value } = e.target;
                    handleTotalScoreSave(
                      kpi._id,
                      value,
                      cellValue,
                      user.userId,
                    );
                  }}
                  onKeyDown={(e) => {
                    const { keyCode } = e;

                    // Prevent TAB button press
                    if (keyCode === 9) {
                      e.preventDefault();
                      return;
                    }

                    // Not an ENTER button press
                    if (keyCode !== 13) {
                      return;
                    }

                    if (filterUsersData.length === 1) {
                      e.target.blur();
                    } else {
                      const nextId =
                        rowIndex === filterUsersData.length - 1
                          ? `cell-0-${index}`
                          : `cell-${rowIndex + 1}-${index}`;

                      const next = document.getElementById(nextId);
                      next?.focus();
                    }
                  }}
                  sx={{
                    height: "3.25rem !important",
                    border: "2px solid #E9ECEF !important",
                    borderRadius: "4px !important",
                    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.07) !important",
                    padding: "13px 20px !important",
                    "& .MuiInputBase-input": {
                      // h5 Typography
                      fontSize: "1.25rem",
                      lineHeight: "1.375",
                      fontWeight: 500,
                      letterSpacing: "0em",
                      verticalAlign: "unset",
                    },
                    "&.Mui-focused": {
                      borderColor: (theme) =>
                        `${theme.palette.primary.main} !important`,
                      boxShadow: (theme) =>
                        `0rem 0rem 0rem 0.125rem ${theme.palette.primary.main} !important`,
                    },
                  }}
                />
              ) : (
                <SoftBox
                  sx={{
                    height: "3.25rem",
                    border: "2px solid #E9ECEF",
                    borderRadius: "4px",
                    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.07)",
                    padding: "10px 20px",
                  }}
                >
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <SoftTypography
                      variant="h5"
                      sx={{
                        display: "inline-block",
                        width: "max-content",
                        color: "#343A40",
                        overflow: "hidden",
                      }}
                    >
                      {/** Teleport here */}
                      {cellValue?.formatted}
                    </SoftTypography>

                    <SoftButton
                      sx={{
                        borderRadius: "4px",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: 0,
                        color: "#ABB6BF",
                        position: "static",
                      }}
                    >
                      <AddIcon sx={{ fontSize: "1.5rem !important" }} />
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              )}
            </KpiModal>
          </SoftBox>
        );
      });
      rows.push(rowContainer);
    });

    setRowsData(rows);
  }, [
    filterIndex,
    usersData,
    filterUsersData,
    groupKpis,
    monthRef,
    metricSettingsData,
    isIncrementalModeDisabled,
  ]);

  const triggerAddLink = async (kpiId, formData) => {
    try {
      await CREATE_LINKS(kpiId, companyId, formData);
      const payload = {
        isLoading: false,
      };

      setKpiLinksData((prev) => ({ ...prev, kpiLinks: formData.links }));
      dispatch(refreshKpis(payload));
    } catch (err) {
      console.log(err);
    }
  };

  const renderColumns = useMemo(() => {
    return columns.map(({ _id, kpiLinks, name, align, width, icon }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 3;
        pr = 3;
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      const mWidth =
        name === "User" ? TABLE_USER_COLUMN_WIDTH : TABLE_COLUMN_WIDTH;

      return (
        <Draggable
          key={_id}
          index={key}
          draggableId={_id}
          isDragDisabled={disabledColumns.includes(name)}
        >
          {(provided, snapshot) => (
            <SoftBox
              key={name}
              // Remove table, th and td since they don't play well with react-beautiful-dnd's "horizonal" drag animation
              // component="th"
              // width={width || "auto"}
              minWidth={mWidth}
              pt={1.5}
              pb={1.25}
              // pl={align === "left" ? pl : 3}
              // pr={align === "right" ? pr : 3}
              textAlign={align}
              fontSize={size.md}
              opacity={0.7}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getStyle(provided.draggableProps.style, snapshot)}
            >
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90%",
                    paddingLeft: `${icon ? "0" : "8px"}`,
                    "& h6": {
                      textAlign: "center",
                      lineHeight: "normal",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                >
                  <SoftBox>{icon}</SoftBox>
                  <SoftTypography
                    variant="h6"
                    sx={{ color: "#6C757D", fontSize: "14px", fontWeight: 500 }}
                  >
                    {name}
                  </SoftTypography>
                </SoftBox>

                {key > 1 ? (
                  <SoftBox zIndex={2}>
                    <PopupState variant="popover" popupId={_id}>
                      {(popupState) => (
                        <>
                          <SoftButton
                            {...bindTrigger(popupState)}
                            variant="text"
                            sx={{
                              minWidth: "max-content",
                              minHeight: "max-content",
                              boxShadow: "none",
                              padding: 0,
                              "& svg": {
                                fontSize: "1.5rem !important",
                                color: "rgba(108, 117, 125, 1)",
                              },
                            }}
                          >
                            <MoreVertIcon />
                          </SoftButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "#FBFDFF",
                                boxShadow:
                                  "12px 12px 24px 0px rgba(0, 0, 0, 0.12)",
                                border: "1px solid #E9ECEF",
                                borderRadius: "4px",
                              },
                            }}
                          >
                            <SoftBox
                              display="flex"
                              flexDirection="column"
                              gap="0.25rem"
                              alignItems="center"
                            >
                              <SoftButton
                                variant="text"
                                sx={{
                                  justifyContent: "start",
                                  color: "#343A40",
                                  textTransform: "none",
                                  width: "100%",
                                  "&:hover": {
                                    color: "#343A40",
                                  },
                                  "&:focus:not(:hover)": {
                                    color: "#343A40",
                                  },
                                }}
                                onClick={() =>
                                  navigate(`/scorekeeper/timeline?kpi=${_id}`)
                                }
                              >
                                <TimelineIcon
                                  style={{ marginRight: "0.25rem" }}
                                />{" "}
                                Metric Timeline
                              </SoftButton>
                              <SoftButton
                                variant="text"
                                sx={{
                                  justifyContent: "start",
                                  color: "#343A40",
                                  textTransform: "none",
                                  width: "100%",
                                  "&:hover": {
                                    color: "#343A40",
                                  },
                                  "&:focus:not(:hover)": {
                                    color: "#343A40",
                                  },
                                }}
                                onClick={() => {
                                  popupState.close();
                                  handleOpenModal({
                                    kpiId: _id,
                                    kpiLinks: kpiLinks?.links,
                                    targetModal: MODAL_MANAGE_LINKS,
                                  });
                                }}
                              >
                                <AttachLinkIcon
                                  style={{ marginRight: "0.25rem" }}
                                />
                                Manage Links
                              </SoftButton>
                              <SoftButton
                                variant="text"
                                sx={{
                                  justifyContent: "start",
                                  color: "#343A40",
                                  textTransform: "none",
                                  width: "100%",
                                  "&:hover": {
                                    color: "#343A40",
                                  },
                                  "&:focus:not(:hover)": {
                                    color: "#343A40",
                                  },
                                }}
                                onClick={() => {
                                  popupState.close();
                                  handleOpenModal({
                                    kpiId: _id,
                                    targetModal: MODAL_METRIC_SCORE,
                                  });
                                }}
                              >
                                <MetricScoreIcon
                                  style={{ marginRight: "0.25rem" }}
                                />
                                Metric Score Configuration
                              </SoftButton>
                            </SoftBox>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </SoftBox>
                ) : null}
              </SoftBox>
              {provided.placeholder}
            </SoftBox>
          )}
        </Draggable>
      );
    });
  }, [columns]);

  const renderRows = rowsData.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            p={1}
            borderBottom={
              row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
            }
            sx={{
              cursor: "pointer",
              minWidth: TABLE_USER_COLUMN_WIDTH,
            }}
            // onClick={() => navigate(`/scorecard?user=${row[name][3]}`)}
            onClick={() =>
              navigate(`/scorekeeper/timeline?user=${row[name][3]}`)
            }
          >
            <SoftBox
              display="flex"
              alignItems="center"
              py={0.5}
              px={1}
              sx={{ width: "100%" }}
            >
              <SoftBox mr={1}>
                <SoftProfile
                  src={row[name][0]}
                  name={row[name][1]}
                  size="md"
                  borderRadius="md"
                />
              </SoftBox>
              <SoftBox sx={{ width: "100%" }}>
                <SoftTypography
                  variant="h5"
                  sx={{
                    width: "100%",
                    color: "#343A40",
                    fontWeight: 500,
                    fontSize: "20px",
                  }}
                >
                  {row[name][1]}
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  sx={{
                    width: "max-content",
                    color: "#6C757D",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {row[name][2]}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            p={1}
            textAlign={align}
            borderBottom={
              row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
            }
            color="#343A40"
            minWidth={TABLE_COLUMN_WIDTH}
          >
            {row[name]}
          </SoftBox>
        );
      }

      return template;
    });

    return (
      <SoftBox
        key={rowKey}
        sx={{
          display: "grid",
          gridTemplateColumns: gridStyle,
          alignItems: "center",
          boxShadow:
            "6px 6px 6px 0px rgba(177, 176, 176, 0.07), -4px -4px 6px 0px rgba(180, 177, 177, 0.07)",
        }}
      >
        {tableRow}
      </SoftBox>
    );
  });

  return (
    <>
      {modalFlags[MODAL_ADD_LINK] && (
        <AddLinkModal
          isOpen={modalFlags[MODAL_ADD_LINK]}
          kpiId={currentKpiId}
          kpiLinksData={currentKpiLinksData}
          onModalClose={() => handleCloseModal(MODAL_ADD_LINK)}
          triggerAddLink={triggerAddLink}
          groupKpis={groupKpis}
        />
      )}

      {modalFlags[MODAL_VIEW_LINKS] && (
        <ViewAllLinksModal
          isOpen={modalFlags[MODAL_VIEW_LINKS]}
          kpiLinksData={currentKpiLinksData}
          onModalClose={() => handleCloseModal(MODAL_VIEW_LINKS)}
          triggerAddLink={triggerAddLink}
          groupKpis={groupKpis}
        />
      )}

      {modalFlags[MODAL_METRIC_SCORE] && (
        <MetricScoreConfig
          workspaceId={workspace?._id}
          kpiId={currentKpiId}
          data={metricSettingsData.find((x) => x.kpiId === currentKpiId)}
          openConfig={modalFlags[MODAL_METRIC_SCORE]}
          handleClose={() => handleCloseModal(MODAL_METRIC_SCORE)}
          handleOnSuccess={handleMetricConfigSuccess}
        />
      )}

      {modalFlags[MODAL_MANAGE_LINKS] && (
        <Dialog
          open={modalFlags[MODAL_MANAGE_LINKS]}
          onClose={() => handleCloseModal(MODAL_MANAGE_LINKS)}
          sx={{
            ".MuiPaper-root": {
              padding: { mini: "1rem", md: "1rem 1.5rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "600px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <ManageLinks
            kpiLinksData={currentKpiLinksData}
            onManageLinksClick={(params) => {
              handleOpenModal({
                kpiId: params.kpiId,
                targetModal: MODAL_VIEW_LINKS,
                kpiLinks: params.kpiLinks,
              });
            }}
            onAddLinkButtonClick={(params) => {
              handleOpenModal({
                kpiId: params.kpiId,
                targetModal: MODAL_ADD_LINK,
                kpiLinks: params.kpiLinks,
              });
            }}
            _id={currentKpiId}
          />
        </Dialog>
      )}
      {useMemo(
        () => (
          <SoftBox
            sx={{
              boxShadow: "none",
              backgroundColor: "transparent",
              borderRadius: 0,
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable
                droppableId="droppable-col-names"
                direction="horizontal"
              >
                {(provided) => (
                  <SoftBox
                    style={{
                      display: "grid",
                      gridTemplateColumns: gridStyle,
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    {renderColumns}
                  </SoftBox>
                )}
              </Droppable>
            </DragDropContext>

            {renderRows}
          </SoftBox>
        ),
        [columns, rowsData],
      )}
    </>
  );
}

export default Table;
